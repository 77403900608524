import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { SharedDataService } from '@services/shared-data.service';
import { DocumentFileService } from '@services/document-file.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Company } from '@models/company';
import { EMPTY_ANALYTICS_ITEM } from '../../../../../utils/constants';

import { AnalyticsService } from '@services/analytics.service';
import {
  PERIOD_TYPES,
  EMPTY_ANALYTICS_DATA,
  COMPANY_PAGE_VIEW_MODE,
  STD_ID_MAPPER,
  COGS_ITEMS_THAT_ARE_IN_LEGACY_REVENUE_SECTION,
} from '../../../../../../app/utils/constants'
import { AlertService } from '../../../../../services/alert.service';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { FinancialsService } from '@services/financials.service';
import { COMPANY_TABS, COMPANY_ENTITLEMENT_DATA } from '@utils/constants';
import { taxReturnCellFlagCopy } from '@components/main/review/review-editor/validation';

@Component({
  selector: 'app-borrower-overview',
  templateUrl: './borrower-overview.component.html',
  styleUrls: ['./borrower-overview.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class BorrowerOverviewComponent implements OnInit {
  subsArr$: Subscription[] = [];
  isLoading = true;
  company: Company = null;
  analyticsData = EMPTY_ANALYTICS_DATA;
  mostRecentPeriod = '';
  mostRecentStatementDate = '';
  periodTypes = PERIOD_TYPES.slice().reverse(); // non mutating reverse
  periodType: string = null;
  availablePeriodTypes: Array<any> = [];
  currencySymbol = '';
  analyticsDataForNetSales = [];
  analyticsDataForCogs = [];
  analyticsDataForGrossProfit = [];
  analyticsDataForGrossMargin = [];

  // View mode
  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS
  noAccessErrorText = "You don't have enough permissions to view this page."
  canViewOverview: boolean;
  routeData: Data;

  constructor(
    private _sharedData: SharedDataService,
    public analyticsService: AnalyticsService,
    private _alertService: AlertService,
    private _documentFileService: DocumentFileService,
    private _financialsService: FinancialsService,
    private _route: ActivatedRoute,
  ) {
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.pageViewMode = this.routeData.companyEntitlement[COMPANY_TABS.OVERVIEW][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];
    this.canViewOverview = [COMPANY_PAGE_VIEW_MODE.VIEW_ONLY, COMPANY_PAGE_VIEW_MODE.FULL].includes(this.pageViewMode);
    if (this.canViewOverview) {
      this.subsArr$.push(this._sharedData.company$.pipe(
        filter(company => company !== null))
        .subscribe((company: Company) => {
          this.company = company;
  
          this.subsArr$.push(this.analyticsService.getAvailableIntervals(this.company.id).subscribe(data => {
            this.availablePeriodTypes = data.map(data => data.reportingInterval);
  
            if (this.isThereAnyData()) {
              this.updatePeriod(this.firstAvailablePeriod());
              this.updateAnalyticsData();
            } else {
              this.isLoading = false; // no data, remove loader, show no data screen
            }
          }));
  
          this.subsArr$.push(this._documentFileService.listDocumentFiles(this.company.id).subscribe(files => {
            this.currencySymbol = this._documentFileService.currencyFromFiles(files);
          }, error => {
            this.currencySymbol = '';
          }));
        }),
      );
    }
  }

  updateAnalyticsData(): void {
    if (this.company !== null) {
      this.isLoading = true;
      this.analyticsData = EMPTY_ANALYTICS_DATA;

      this.subsArr$.push(this.analyticsService.getAnalyticsData(this.company.id, this.periodType).subscribe(data => {
        this.analyticsData = this.analyticsService.formatAnalyticsData(data);
        // the order that these are called matter
        this.setMostRecentPeriod();
        this.setMostRecentStatmentDate();
        this.setAnalyticsDataForNetSales();
        this.setAnalyticsDataForCogs();
        this.setAnalyticsDataForGrossProfit();
        this.setAnalyticsDataForGrossMargin();
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this._alertService.error(error.message);
      }));
    }
  }

  isPeriodAvailable(periodValue: string) {
    return this.availablePeriodTypes.includes(periodValue);
  }

  isThereAnyData(): boolean {
    return this.availablePeriodTypes.length > 0;
  }

  firstAvailablePeriod(): Array<string> {
    if (this.isThereAnyData()) {
      return this.availablePeriodTypes[0];
    }
  }

  updatePeriod(newPeriod): void {
    this.periodType = newPeriod;
    this.updateAnalyticsData();
  }

  setMostRecentPeriod(): void {
    const periods = this.analyticsData['periods'];
    const lastItemKey = periods.length - 1;
    this.mostRecentPeriod = periods[lastItemKey];
  }

  setMostRecentStatmentDate(): void {
    const periods = this.analyticsData['statementDates'];
    const lastItemKey = periods.length - 1;
    this.mostRecentStatementDate = periods[lastItemKey];
  }

  isDataAvailable(): boolean {
    if (this.analyticsData && this.analyticsData.periods) {
      return Object.keys(this.analyticsData.periods).length > 0;
    } else {
      return false;
    }
  }

  setAnalyticsDataForNetSales() {
    const revenueAnalyticsData = this.analyticsService.getDataByLineItem('REF_REVENUE', this.analyticsData);
    if (revenueAnalyticsData.length > 0 && revenueAnalyticsData[0] !== EMPTY_ANALYTICS_ITEM) {
      for (let i = 0; i < revenueAnalyticsData.length; i++) {
        revenueAnalyticsData[i].formattedValue = this._financialsService.formatValue(revenueAnalyticsData[i].value, 'NUMBER', { showDollarSign: false });
      }
      this.analyticsDataForNetSales = revenueAnalyticsData;
      return;
    }
    const netSalesValuesArray = Array(this.analyticsData.periods.length).fill(0);
    this.analyticsService.getDataByLineItem('REF_GROSS_PROFIT', this.analyticsData).forEach((data, i) => {
      netSalesValuesArray[i] += data.value;
    });
    for (const ref of Object.keys(COGS_ITEMS_THAT_ARE_IN_LEGACY_REVENUE_SECTION)) {
      this.analyticsService.getDataByLineItem(ref, this.analyticsData).forEach((data, i) => {
        netSalesValuesArray[i] += data.value;
      });
    }
    
    const netSalesData = [];
    for (let i = 0; i < this.analyticsData.periods.length; i++) {
      netSalesData.push({
        value: netSalesValuesArray[i],
        formattedValue: this._financialsService.formatValue(netSalesValuesArray[i], 'NUMBER', { showDollarSign: false }),
        reportingPeriod: '',
        valueType: 'NUMBER',
      });
    }
    this.analyticsDataForNetSales = netSalesData;
  }

  setAnalyticsDataForCogs() {
    const directCostsAnalyticsData = this.analyticsService.getDataByLineItem('REF_DIRECT_COSTS', this.analyticsData);
    if (directCostsAnalyticsData.length > 0 && directCostsAnalyticsData[0] !== EMPTY_ANALYTICS_ITEM) {
      for (let i = 0; i < directCostsAnalyticsData.length; i++) {
        directCostsAnalyticsData[i].formattedValue = this._financialsService.formatValue(directCostsAnalyticsData[i].value, 'NUMBER', { showDollarSign: false });
      }
      this.analyticsDataForCogs = directCostsAnalyticsData;
      return;
    }
    const cogsValuesArray = Array(this.analyticsData.periods.length).fill(0);
    for (const ref of Object.keys(COGS_ITEMS_THAT_ARE_IN_LEGACY_REVENUE_SECTION)) {
      this.analyticsService.getDataByLineItem(ref, this.analyticsData).forEach((data, i) => {
        cogsValuesArray[i] += data.value;
      });
    }
    const cogsData = [];
    for (let i = 0; i < this.analyticsData.periods.length; i++) {
      cogsData.push({
        value: cogsValuesArray[i],
        formattedValue: this._financialsService.formatValue(cogsValuesArray[i], 'NUMBER', { showDollarSign: false }),
        reportingPeriod: '',
        valueType: 'NUMBER',
      });
    }
    this.analyticsDataForCogs = cogsData;
  }

  setAnalyticsDataForGrossProfit() {
    const grossProfitAnalyticsData = this.analyticsService.getDataByLineItem('REF_GROSS_PROFIT', this.analyticsData);
    if (grossProfitAnalyticsData.length > 0 && grossProfitAnalyticsData[0] !== EMPTY_ANALYTICS_ITEM) { 
      for (let i = 0; i < grossProfitAnalyticsData.length; i++) {
        grossProfitAnalyticsData[i].formattedValue = this._financialsService.formatValue(grossProfitAnalyticsData[i].value, 'NUMBER', { showDollarSign: false });
      }
      this.analyticsDataForGrossProfit = grossProfitAnalyticsData;
      return;
    }
    const analyticsDataForGrossProfit = [];
    for (let i = 0; i < this.analyticsDataForNetSales.length; i++) {
      const grossProfit = this.analyticsDataForNetSales[i].value - this.analyticsDataForCogs[i].value;
      analyticsDataForGrossProfit.push({
        value: grossProfit,
        formattedValue: this._financialsService.formatValue(grossProfit, 'NUMBER', { showDollarSign: false }),
        reportingPeriod: '',
        valueType: 'NUMBER',
      })
    }
    this.analyticsDataForGrossProfit = analyticsDataForGrossProfit;
  }

  setAnalyticsDataForGrossMargin() {
    const grossMarginAnalyticsData = this.analyticsService.getDataByLineItem('REF_GROSS_MARGIN', this.analyticsData);
    if (grossMarginAnalyticsData.length > 0 && grossMarginAnalyticsData[0] !== EMPTY_ANALYTICS_ITEM) { 
      for (let i = 0; i < grossMarginAnalyticsData.length; i++) {
        grossMarginAnalyticsData[i].formattedValue = this._financialsService.formatValue(grossMarginAnalyticsData[i].value, 'NUMBER', { showDollarSign: false });
      }
      this.analyticsDataForGrossMargin = grossMarginAnalyticsData;
      return;
    }
    const analyticsDataForGrossMargin = [];
    for (let i = 0; i < this.analyticsDataForNetSales.length; i++) {
      const grossMargin = (this.analyticsDataForNetSales[i].value - this.analyticsDataForCogs[i].value) / this.analyticsDataForNetSales[i].value;
      analyticsDataForGrossMargin.push({
        value: grossMargin,
        formattedValue: this._financialsService.formatValue(grossMargin, 'PERCENT', { showDollarSign: false }),
        reportingPeriod: '',
        valueType: 'PERCENT',
      })
    }
    this.analyticsDataForGrossMargin = analyticsDataForGrossMargin;
  }
}
