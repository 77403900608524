import { Component, OnInit, Input } from '@angular/core';
import { DocumentFileService } from '@services/document-file.service';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@utils/constants';
import * as moment from 'moment';
import { Company } from '@models/company';
import { Router } from '@angular/router';
import { INCOME_STATEMENT, BALANCE_SHEET, CASH_FLOW_STATEMENT, ACCOUNTING_INTEGRATION } from '@utils/constants';


@Component({
  selector: 'app-accounting-integration-import',
  templateUrl: './accounting-integration-import.component.html',
  styleUrls: ['./accounting-integration-import.component.scss']
})
export class AccountingIntegrationImportComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() vendor: string;
  @Input() company: Company;

  submitted = false;
  submissionComplete = false;
  submitAttempt = false;
  showAccountingMethod = false;

  reviewQueueItemId = null;

  accountingMethod = 'cash';
  reportingInterval = 'MONTHLY';
  includeIncomeStatement = true;
  includeBalanceSheet = true;
  includeCashFlow = true;
  startDate: string = null;
  endDate: string = null;

  constructor(
    private documentFileService: DocumentFileService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    // be default assume grabbing last months data
    const lastMonth = moment().subtract(1, 'months');
    this.startDate = lastMonth.startOf('month').format(DATE_FORMAT);
    this.endDate = lastMonth.endOf('month').format(DATE_FORMAT);
    // currently only support multiple accounting methods for qbo
    this.showAccountingMethod = this.vendor === ACCOUNTING_INTEGRATION.QBO;
  }

  enterDigitization(): void {
    const link = ['/review/', this.reviewQueueItemId, 'load', this.company.uuid];
    this._router.navigate(link);
    this.popup.close();
  }

  startImport() {
    this.submitAttempt = true;
    this.submitted = true;

    const statementTypes = [];

    if (this.includeIncomeStatement) {
      statementTypes.push(INCOME_STATEMENT);
    }

    if (this.includeBalanceSheet) {
      statementTypes.push(BALANCE_SHEET);
    }

    if (this.includeCashFlow) {
      statementTypes.push(CASH_FLOW_STATEMENT);
    }

    this.documentFileService.importFromIntegration({
      company_id: this.company.id,
      vendor: this.vendor,
      start_date: moment(this.startDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      end_date: moment(this.endDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      reporting_interval: this.reportingInterval,
      accounting_method: this.accountingMethod,
      statement_types: statementTypes
    }).subscribe(
        data => {
          this.documentFileService.getReviewQueueItemForFileId(data.id).subscribe((data) => {
            this.reviewQueueItemId = data.response.objects[0].reviewQueueItem;
            this.submissionComplete = true;
          });
        },
        error => {
          console.log(error);
        },
    );
  }

  setStartDate(event): void {
    this.startDate = event.dateObject.formatted;
  }

  setEndDate(event): void {
    this.endDate = event.dateObject.formatted;
  }

}
