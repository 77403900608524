<div>
    <div class="modal_header">
        <ng-container *ngIf="mode==='edit'; else createModalHeader">
            <i class="fa fa-pencil-alt header-icon"></i>
            <p>EDIT PORTFOLIO</p>
        </ng-container>
        <ng-template #createModalHeader>
            <i class="fa fa-pencil-alt header-icon"></i>
            <p>CREATE PORTFOLIO</p>
        </ng-template>
    </div>
    <div class="modal__content">
        <form [formGroup]=portfolioForm (submit)="submit()">
            <div *ngIf="portfolioForm" class="mb-3">
                <label class="control-label" for="name">Name</label>
                <input
                    [ngClass]="{'is-invalid': (portfolioForm.touched || portfolioForm.dirty) && portfolioForm.invalid}"
                    type="text" class="form-control" id="name" formControlName="name">
                <span class="invalid-feedback"
                      *ngIf="portfolioForm.get('name').hasError('formIsUnchanged')">
                    No changes have been made
                </span>
                <span class="invalid-feedback"
                      *ngIf="portfolioForm.get('name').hasError('maxlength')">
                    Maximum of 30 characters
                </span>
                <span class="invalid-feedback"
                      *ngIf="portfolioForm.get('name').hasError('pattern')">
                    Portfolio contains invalid characters
                </span>
                <span class="invalid-feedback"
                      *ngIf="portfolioForm.get('name').hasError('required')">
                    Portfolio Name is required
                    </span>
            </div>
            <div class="error-block" *ngIf="portfolioForm.invalid && portfolioForm.hasError('formIsUnchanged')">
                No changes have been made
            </div>
            <div class="modal__footer">
                <div class="footer-action-buttons">
                    <button type="reset" class="btn btn-link" (click)="closeModal()">Cancel</button>
                    <button type="submit" class="btn btn-primary">{{this.mode === 'edit' ? 'Save' : 'Submit'}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
