<div *ngIf="isLoading">
    <div class="loader__container">
        <h2>Loading</h2>
        <div class="loader"></div>
    </div>
</div>
<div class="modal__header" *ngIf="!isLoading">
    <div class="modal__title">
        <ng-container>
            CONFIRM TAX FORM DETAILS
        </ng-container>
    </div>
</div>
<div class="modal__content" *ngIf="!isLoading">
    <ng-container>
        <div class="file-name-container">
            <div class="row">
                <div class="col-1">
                    <app-file-icon class="file-type-icon" size="30px" contentType="TAX_RETURN"></app-file-icon>
                </div>
                <div class="col-6">
                    <span class="file-name">{{file? file.originalDocumentName : ""}}</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <ng-container>
                <div id="taxFormLabel" class="title">Which tax form is in the document?</div>
                <div class="row">
                    <div class="col-8">
                        <select class="tax-select form-select" (change)="updateTaxForm($event.target.value)">
                            <option *ngFor="let item of SUPPORTED_TAX_FORMS" value="{{item}}">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div class="row"></div>
                <div id="statementDateLabel" class="title">What is the tax return year (beginning year)?</div>
                <div class="row">
                    <div class="col-8">
                        <select class="tax-select form-select" (change)="updateTaxYear($event.target.value)">
                            <option *ngFor="let item of SUPPORTED_TAX_YEARS" value="{{item}}">{{item}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<div class="modal__footer">
    <ng-container>
        <button type="submit" [disabled]="isLoading" class="btn btn-primary upload-footer" (click)="confirmDetails();">Submit</button>
    </ng-container>
</div>
