import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appNgxfocus]'
})

export class FocusDirective implements AfterViewInit {
  @Input() appNgxfocus: number;

  constructor(private el: ElementRef) {
  }

  // Focus to element: if value 0 = don't set focus, 1 = set only focus
  ngAfterViewInit() {
    if (this.appNgxfocus === 0) {
      return;
    }
    this.el.nativeElement.focus();
  }
}
