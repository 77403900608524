import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription, forkJoin} from 'rxjs';

import {PubNubFileStatusResponse} from '../../models/pubnubFileStatusesResponse';
import {DocumentDate} from '../../models/document-date';
import {AlertService} from '../../services/alert.service';
import {SharedDataService} from '../../services/shared-data.service';
import {UserService} from '../../services/user.service';
import {BorrowerService} from '../../services/borrower.service';
import {EnvironmentService} from '../../services/environment.service';
import {AutoUnsubscribe} from '../../decorators/auto-unsubscribe';
import {DocumentType} from '../../utils/enums';
import {AlgorithmsService} from '../../services/algorithms.service';
import PubNub from 'pubnub';
import Pubnub, {PubnubConfig} from 'pubnub';
import {User} from '../../models/user';
import {AlertTypeEnum} from '../../models/alert-type-enum';
import {Company} from '../../models/company';
import {CommonFunctions} from '@utils/common-functions';
import {SignedUrl} from '@models/signed-url';
import {UserSessionTimeoutService} from "@services/user-session-timeout.service";
import {PreviousRouteService} from "@services/previous-route.service";
import {AuthenticationService} from "@services/authentication.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class MainComponent implements OnInit, OnDestroy, AfterViewChecked {
  subsArr$: Subscription[] = [];
  channelName: string;
  companyName: string;
  company: Company;
  isInitialized = false;
  private pubnub: PubNub;
  constructor(
    private changeDetector: ChangeDetectorRef,
    private _alertService: AlertService,
    private _sharedService: SharedDataService,
    private _router: Router,
    private _userService: UserService,
    private _algorithmService: AlgorithmsService,
    private _companyService: BorrowerService,
    private _environmentService: EnvironmentService,
    private _sharedDataService: SharedDataService,
    private _previousRouteService: PreviousRouteService,
    private _authenticationService: AuthenticationService,
    private _userSessionTimeoutService: UserSessionTimeoutService,
  ) {
  }

  ngOnInit() {

    const environment = this._environmentService.getEnvConfig();
    if (!environment) {
      // The environment has not been fetch yet, fetch it and then initialize pubnub

      // if coming in via portal - auth token will be in querystring
      const jwt = CommonFunctions.getQueryParameterByName('jwt');
      let signedUrl = null;
      if (jwt) {
        signedUrl = new SignedUrl();
        signedUrl.jwt = jwt;
      }

      this.subsArr$.push(
        forkJoin([
          this._environmentService.loadEnvConfig(false, signedUrl),
          this._userService.getCurrentUser()
        ]).subscribe(() => {
          this.initializePubNub();
        }));
    } else {
      this.initializePubNub();
    }

    this._userSessionTimeoutService.init();
  }

  ngOnDestroy(): void {
    if (this.channelName) {
      this.pubnub.unsubscribe({channels: [this.channelName]});
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * Binding an event to Host is expensive, so we'll broadcast clicks
   * that make it all the way back to this component (meaning, w/o stopPropagation) to the shared service
   */
  clickedOnApp(evt) {
    this._sharedService.appClicks$.next();
  }

  private initializePubNub() {
      if (this._userService.user) {
        this.isInitialized = true;
        const currentUser: User = this._userService.user;
        this.channelName = 'user.' + currentUser.user_email;
        const pubNubConfig: PubnubConfig = this._environmentService.getPubNubSettings(currentUser);
        this.pubnub = new PubNub(pubNubConfig)
        this._setPubNubListeners();
        this._subscribeToChannel();
      }
  }

  private _subscribeToChannel(): void {
    this.pubnub.subscribe({ channels: [this.channelName]});
  }

  private _setPubNubListeners() {
    this.pubnub.addListener({
      status: (statusEvent) => {
        if (statusEvent.category === 'PNNetworkUpCategory') {
          this._subscribeToChannel();
        }
      },
      message: (messageEvent: Pubnub.MessageEvent) => {
        this._handlePubNubMessages(messageEvent);
      }
    });
  }

  private _handlePubNubMessages(messageEvent: Pubnub.MessageEvent): void {
      console.log(`${messageEvent.message.status}-${messageEvent.message.step}`);
      const pubNubFileStatusResponse: PubNubFileStatusResponse = messageEvent.message;
      this.subsArr$.push(
        this._companyService.getCompanyById(pubNubFileStatusResponse.companyId)
          .subscribe(data => {
            // Task #1067: chance this could not be the structure we expect, in which case it's not so important
            // and we can just ignore it.
            if (data) {
              this.companyName = data.name;
            } else {
              this.companyName = 'Unknown Company';
            }
          }));
      const fileName = pubNubFileStatusResponse.fileName;
      let textMessage = '';
      const redirectUrl = `/companies/${pubNubFileStatusResponse.companyId}/data`;

      // Note: we'll want to take this out and replace with the pizza tracker eventually.
      if (pubNubFileStatusResponse.status === 'SUCCEEDED') {
          textMessage += fileName + ' processed successfully ';
          setTimeout(() => { this._alertService.success(`${textMessage} for ${this.companyName}`); }, 5000);
          const lastAlgorithmsURLPart = pubNubFileStatusResponse.documentType === DocumentType.IncomeStatement ? 'income' : 'balance';
          const newPref = new DocumentDate();
          newPref.reportedDate = pubNubFileStatusResponse.reportedDate;
          // reset user date preference
          this.subsArr$.push(this._algorithmService.setUserPreference(pubNubFileStatusResponse.companyId, this._userService.user.id, newPref).subscribe(() => {
            if (this._router.url === redirectUrl) {
              this._sharedDataService.refreshDates$.next();
            }
          }));
      }

      if (messageEvent.message.messageType === 'REVIEW_QUEUE_ITEM_STATUS') {
        this._sharedService.documentStatusChanges$.next(messageEvent);
      }

      // add in line here to kick off call to retrieve in process documents again.
      // This should only be next() w/ a 2 second debounce.
      // the subscribe should live outside of the `getMessage`
  }

  private _alert(alertType: AlertTypeEnum, message: string): void {
    switch (alertType) {
      case AlertTypeEnum.INFO:
        this._alertService.info(message);
        break;
      case AlertTypeEnum.WARNING:
        this._alertService.warning(message);
        break;
      case AlertTypeEnum.SUCCESS:
        this._alertService.success(message);
        break;
    }
  }
}
