/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FinancialRequirementRules { 
    readonly uuid?: string;
    thresholdValue: number;
    comparer?: FinancialRequirementRules.ComparerEnum;
    startDate: string;
    endDate?: string | null;
}
export namespace FinancialRequirementRules {
    export type ComparerEnum = '==' | '!=' | '>' | '<' | '>=' | '<=';
    export const ComparerEnum = {
        EqualTo: '==' as ComparerEnum,
        NotEqual: '!=' as ComparerEnum,
        GreaterThan: '>' as ComparerEnum,
        LessThan: '<' as ComparerEnum,
        GreaterThanOrEqualTo: '>=' as ComparerEnum,
        LessThanOrEqualTo: '<=' as ComparerEnum
    };
}


