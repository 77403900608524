
<div class="sidebar-wrapper float-start">
  <div class="instructions">

    <p class="italics">{{stepHeader}}</p>

    <p *ngIf="fullGif" class="seeExample" (click)="showExample(fullGif, title)">View Tutorial Clip</p>

      <ng-container *ngFor="let step of steps; let index = index">
          <div class="card mb-4">
              <div class="card-header">
                  <div data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse_'+index">
                      {{index + 1}}. {{step?.title}}
                      <i class="fas fa-caret-down float-end"></i>
                  </div>
              </div>
              <div [attr.id]="'collapse_'+index" class="collapse">
                  <div class="card-body">
                      <div [innerHTML]="step?.body"></div>
                      <ng-container *ngIf="step.gifName">
                          <p class="seeExample" (click)="showExample(step.gifName, step.title)">View Tutorial Clip</p>
                      </ng-container>
                  </div>
              </div>
          </div>

      </ng-container>

    <div class="tips" *ngIf="tipsBody">
        <div class="tips-header" data-bs-toggle="collapse" data-bs-target="#tips">
          <p>Tips</p>
          <i class="fas fa-caret-down float-end"></i>
        </div>

        <div id="tips" class="collapse in">
            <div class="tipsText">{{tipsBody}}</div>
        </div>
    </div>
  </div>

  <div class="sidebar-row nav-toggle" (click)="toggleSidebar()">
      <a class="nav-link" title="Hide Instructions">
        <i class="fas fa-chevron-left"></i>
      </a>
      <span>Hide Instructions</span>
  </div>
</div>
