import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { LineItemNormalizationInfo, LineItemMatchingInfo, LineItemsNormalizationInfo, MatchedLineItem } from '../models/line-item-normalization';
import { Observable } from 'rxjs';
import { DataView } from '../models/dataview';
import { Company } from '../models/company';
import { DataFrameKey } from '../models/data-frame-key';
import * as moment from 'moment';
import { DataFrame } from '../models/dataframe';
import { LineItemObject } from '../models/line-item';

@Injectable()
export class LineItemService {
  constructor(private apiService: ApiService) {
  }

  getLineItems(lIds: Array<number>): Observable<LineItemsNormalizationInfo> {
    const inputLineItems = {
      filter: lIds
    };

    return this.apiService.send('Post', `/api/lineitem/all`, inputLineItems).pipe(
      map(data => {
        const result: LineItemsNormalizationInfo = new LineItemsNormalizationInfo();
        if (data.response.objects.length > 0) {
          const lsResponse: Array<any> = data.response.objects;
          for (let i = 0; i < lsResponse.length; i++) {
            const matchingInfo = Array<LineItemMatchingInfo>();
            if (lsResponse[i].possibleFincuraItems.length) {
              const possibleItems = lsResponse[i].possibleFincuraItems;
              for (let j = 0; j < possibleItems.length; j++) {
                const matchingItem = new LineItemMatchingInfo(possibleItems[j][0], possibleItems[j][1][0])
                matchingInfo.push(matchingItem);
              }
            }
            const normalizedLineItem: LineItemNormalizationInfo = new LineItemNormalizationInfo();
            normalizedLineItem.name = lsResponse[i].name;
            normalizedLineItem.id = lsResponse[i].id;
            normalizedLineItem.documentType = lsResponse[i].documentType;
            normalizedLineItem.itemType = lsResponse[i].itemType;
            const matchedLineItem: MatchedLineItem = new MatchedLineItem(
              lsResponse[i].matchedItemId,
              lsResponse[i].matchedItemName
            );
            normalizedLineItem.matchedLineItem = matchedLineItem;
            normalizedLineItem.possibleFincuraItems = matchingInfo;
            result.add(normalizedLineItem);
          }
        }

        return result;
      })
    );
  }

  updateLineItemMatch(id: number, matchedLineItem: MatchedLineItem): Observable<LineItemNormalizationInfo> {
    return this.apiService.send('Patch', `/api/lineitem/match/${id}`, matchedLineItem).pipe(
      map(data => {
        if (data.response.objects.length > 0) {
          return new LineItemNormalizationInfo().deserialize(data.response.objects[0]);
        }
        return null;
      })
    );
  }

  isPeriodAvailable(dataFrameOptions, selectedFrameOption) {
    let found = false;
    for (let i = 0; i < dataFrameOptions.length; i++) {
        if (dataFrameOptions[i].id === selectedFrameOption) {
            found = true;
            break;
        }
    }
    return found;
  }

  getDataFrameOptions(dataView: DataView) {
    const dataFrameOptions = [];
    if (dataView) {
      dataView.frames.map(frame => {
        const statementDate = moment(frame.statementDate).format('MM/DD/YYYY');
        const periodType = this.translatePeriodType(frame.reportingPeriod);
        const dataFrameKey = DataFrameKey.fromDataFrame(frame);
        const prepText =  (frame.preparationType === 'UNKNOWN') ? '' : ` (${frame.preparationType})`;
        dataFrameOptions.push({
          id: dataFrameKey.toString(),
          text: `${statementDate} - ${frame.reportingInterval.replace('_', ' ')}${prepText}`,
          additional: {
            preparationType: frame.preparationType,
            reportingInterval: frame.reportingInterval,
            statementDate: statementDate
          }
        });
      });
    }
    return dataFrameOptions;
  }

  getIndexOfDataFrameOption(dataFrameOptions, dataFrameKeyStr) {
    let index = null;
    for (let i = 0; i < dataFrameOptions.length; i++) {
        if (dataFrameOptions[i].id === dataFrameKeyStr) {
            index = i;
            break;
        }
    }
    return index;
  }

  translatePeriodType(reportingPeriod: string) {
    return reportingPeriod.split('.')[1];
  }

  /**
   * Gets all of the unique line items we need to show for this view (considers all data frames)
   * @param frames
   */
  getAllItems(frames: Array<DataFrame>, singleFrameView: boolean, selectedFrameIndex: number) {
    if (frames.length === 0) {
      return [];
    }

    if (singleFrameView) {
      if (frames.length >= (selectedFrameIndex + 1)) {
        return frames[selectedFrameIndex].items.map(item => item.lineItem);
      }
      return [];
    }

    const items = frames.map(frame => {
      // If we've already run the _normalizeFrames function, we will have some null items in frame.items. Need to account for that. This happens if an input changes for example.
      return frame.items.filter(item => !!item).map(item => item.lineItem);
    }).reduce((previous, next) => {
      return previous.concat(next);
    });

    const ids = Array.from(new Set(items.map(item => item.id)));
    const itemsById = {};
    items.forEach(item => {
      itemsById[item.id] = item;
    });

    return ids.map(id => itemsById[id]);
  }


  /**
   * Goes through each frame and ensures it has all of the line items
   * for the whole view in the correct order, so we can display it correctly
   * in a table
   */
  normalizeFrames(uniqueLineItems: Array<LineItemObject>, frames: Array<DataFrame>, singleFrameView) {
    if (singleFrameView) {
      return frames;
    }
    const itemIds = uniqueLineItems.map(item => item.id);

    return frames.map(frame => {
      return this._normalizeFrame(itemIds, frame);
    });
  }

  _normalizeFrame(ids: Array<number>, frame: DataFrame) {
    const frameItemsByLineItem = {};
    frame.items.filter(item => !!item).forEach(item => {
      frameItemsByLineItem[item.lineItem.id] = item;
    });

    frame.items = ids.map(id => {
      if (frameItemsByLineItem.hasOwnProperty(id)) {
        return frameItemsByLineItem[id];
      }
      return null;
    });
    return frame;
  }

  /**
   * Goes through the list of line items and checks if it is a header by confirming that
   * every data frame with that line item has its item marked as "isHeader". Technically
   * we could have the case with a line item called `ASSETS` for example that is a header
   * for one dataframe and has a value for another.
   *
   * This will likely be revisited once we introduce indentatino detection and actual headers
   * for formatting purposes.
   *
   * @param lineItems
   * @param normalizedFrames
   */
  identifyLineItemHeaders(lineItems: Array<LineItemObject>, normalizedFrames: Array<DataFrame>, singleFrameView, selectedFrameIndex) {
    let framesToUse = normalizedFrames;
    if (singleFrameView) {
      framesToUse = [framesToUse[selectedFrameIndex]];
    }
    return lineItems.map((item, idx) => {
      return {
        'idx': idx,
        'headerFlags': framesToUse.map(frame => frame.items[idx] ? frame.items[idx].isHeader : null)
      };
    }).filter(headerFlags => headerFlags['headerFlags'].filter(flag => flag != null).every(val => val)).map(val => val['idx']);
  }

  newItemIndices(frames, highlightNewUnspread, selectedFrameIndex: number, singleFrameView: boolean) {
    let newItemIndices;
    if (singleFrameView && highlightNewUnspread && selectedFrameIndex > 0) {
      const thisFrame = frames[selectedFrameIndex];
      const lastFrame = frames[selectedFrameIndex - 1];
      const thisSet = thisFrame.items.map(item => item.lineItem.id);
      const lastSet = lastFrame.items.map(item => item.lineItem.id);
      const difference = [...thisSet].filter(x => !lastSet.includes(x));
      newItemIndices = difference.map(d => thisSet.indexOf(d));
    } else if (selectedFrameIndex === 0) {
      newItemIndices = [];
    }
    return newItemIndices;
  }

  calculateGraphItems(selectedLineItems, dataView: DataView) {
    const graphItems = [];
    if (!dataView || !selectedLineItems) {
      return [];
    }
    Object.keys(selectedLineItems).forEach(function (key) {
      const selectedItem = selectedLineItems[key];
      const idx = selectedItem.idx;
      const itemName = selectedItem.item.name;
      const series = dataView.frames.map(frame => {
        let item = frame.items[idx];

        if (!item) {
          // @ts-ignore
          item = {
            value: 0
          };
        }
        let value = item.value;

        if (item.value == null || (!!item.error)) {
          value = 0;
        }
        return {
          name: frame.statementDate,
          value: value
        }
      });
      graphItems.push({
        name: itemName,
        series: series,
      });
    }, this);
    return graphItems;
  }

  getDownloadGrid(dataView: DataView, lineItems: Array<LineItemObject> = []) {
    let grid = []
    const headerRow = [''];
    const rows = [];

    dataView.frames.forEach(frame => {
      headerRow.push(moment(frame.statementDate).format('MM/DD/YYYY'));
    });

    lineItems.forEach((item, itemIdx) => {
      const row = [item.name];
      dataView.frames.forEach((frame) => {
        if (frame.items[itemIdx] && (frame.items[itemIdx].value || frame.items[itemIdx].value === 0)) {
          row.push(frame.items[itemIdx].value.toString());
        } else {
          row.push('');
        }

      });
      rows.push(row);
    });

    grid.push(headerRow);
    grid = grid.concat(rows);
    return grid;
  }

}
