import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { CustomUploaderService } from '../../../services/custom-uploader.service';
import { Router } from '@angular/router';

export class FileUploaderCustom extends FileUploader {

  constructor(options: FileUploaderOptions,
              private customUploaderService: CustomUploaderService,
              private router: Router) {
    super(options);
  }

  uploadAllFiles(): void {

    const xhr = new XMLHttpRequest();
    const sendable = new FormData();
    const fakeitem: FileItem = null;
    this.onBuildItemForm(fakeitem, sendable);

    for (const item of this.queue) {
      item.isReady = true;
      item.isUploading = true;
      item.isUploaded = false;
      item.isSuccess = false;
      item.isCancel = false;
      item.isError = false;
      item.progress = 0;

      if (typeof item._file.size !== 'number') {
        throw new TypeError('The file specified is no longer valid');
      }
      sendable.append('files', item._file, item.file.name);
    }

    if (this.options.additionalParameter !== undefined) {
      Object.keys(this.options.additionalParameter).forEach((key) => {
        sendable.append(key, this.options.additionalParameter[key]);
      });
    }

    xhr.onload = () => {
      const gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
      const method = 'on' + gist + 'Item';
      this[method](fakeitem, null, xhr.status, null);
      if (gist === 'Success') {
        this.customUploaderService.successSubject$.next(true);
      }
      if (gist === 'Error' && (xhr.status === 401 || xhr.status === 402)) {
        this.router.navigate(['/logout'])
      }
    };

    xhr.onerror = (err) => {
      this.customUploaderService.errorSubject$.next(true);
      this.onErrorItem(fakeitem, null, xhr.status, null);
    };

    xhr.onabort = () => {
      this.onErrorItem(fakeitem, null, xhr.status, null);
    };

    xhr.open('POST', this.options.url, true);
    xhr.withCredentials = false;
    if (this.options.headers) {
      for (let _i = 0, _a = this.options.headers; _i < _a.length; _i++) {
        const header = _a[_i];
        xhr.setRequestHeader(header.name, header.value);
      }
    }
    if (this.authToken) {
      xhr.setRequestHeader(this.authTokenHeader, this.authToken);
    }
    xhr.send(sendable);
  };

}
