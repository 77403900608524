<div class="modal__header">
    <div class="modal__title">
        <h4>Add Financial Covenant</h4>
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>



<form
[formGroup]="financialCovenantForm"
(submit)="save(financialCovenantForm.value)">
    <div class="modal__content" *ngIf="loans.length > 0; else noLoans">
        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="control-label" for="loan">Loan</label>
                    <select class="form-select"
                    formControlName="loan">
                        <option [ngValue]="null" disabled>Choose the Loan</option>
                        <option
                            *ngFor="let loan of loans; let i = index"
                            [ngValue]="loan.id"
                        >{{loan.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                Covenant
            </div>
            <div class="col-6">
                Covenant Formula
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="mb-3">
                    <input type="text" class="form-control" placeholder="Covenant Name" formControlName="name" id="name">
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <input type="text" class="form-control" placeholder="Formula" formControlName="formula" id="formula">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                Applicable Data
            </div>
        </div>

        <div class="row">
            <div class="col-4"
                *ngFor="let interval of reportingIntervals; let i = index"
                formArrayName="reportingIntervals"
            >
                <label>
                    <input type="checkbox" [formControlName]="i">
                    {{reportingIntervals[i]}}
                </label>
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                Rule
            </div>
        </div>

        <div
            formArrayName="rules"
            *ngFor="let rule of getRulesControls(financialCovenantForm); let i=index;"
        >
            <div [formGroupName]="i" class="row mb-3">
                <div class="col-4">
                    <select class="form-select" formControlName="operation">
                        <option disabled>Select Rule</option>
                        <option
                            *ngFor="let covenantRule of covenantRuleOptions; let i = index"
                            [selected]="i == 0"
                            [value]="covenantRule.value"
                        >{{covenantRule.label}}</option>
                    </select>
                </div>
                <div class="col-4">
                    <input type="number" formControlName="threshold" class="form-control" placeholder="Type Threshold">
                </div>
                <div class="col-4">
                    <app-input-calendar-component
                        fieldName="timeline"
                        [initialDate]="todaysDate"
                        formControlName="timeline"
                    >
                    </app-input-calendar-component>
                </div>


            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <i class="fas fa-plus"></i><button type="button" class="btn btn-link" (click)="addRule()">Add New Row</button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                Frequency
            </div>
        </div>


        <div class="row">
            <div class="col-10">
                <div class="mb-3">
                    <select class="form-select"
                    formControlName="frequency">
                        <option [ngValue]="null" disabled>Select Frequency</option>
                        <option
                            *ngFor="let frequencyOption of frequencyOptions; let i = index"
                            [ngValue]="frequencyOption.id"
                        >{{frequencyOption.label}}</option>
                    </select>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                Covenant Description
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <textarea id="description" class="form-control" name="description" rows="4" formControlName="description">
                </textarea>
            </div>
        </div>


    </div>

    <ng-template #noLoans>
        <div class="modal__content">
            <p><strong>Cannot create a Covenant. No loans exist for this borrower</strong></p>
        </div>
    </ng-template>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="submitted">Submit</button>
    </div>
</form>

