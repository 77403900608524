import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataFrameValue } from '@models/dataframe';

@Injectable({
  providedIn: 'root'
})
export class CellInformationService {

  cellInformation$: Observable<DataFrameValue>;
  cellInformationStatementDate$: Observable<string>;
  cellInformationTitle$: Observable<string>;
  cellInformationVisible$: Observable<boolean>;

  private cellInformation = new BehaviorSubject<DataFrameValue>(null);
  private cellInformationStatementDate = new BehaviorSubject<string>('');
  private cellInformationTitle = new BehaviorSubject<string>('');
  private cellInformationVisible = new BehaviorSubject<boolean>(false);

  constructor() {
    this.cellInformation$ = this.cellInformation.asObservable();
    this.cellInformationTitle$ = this.cellInformationTitle.asObservable();
    this.cellInformationStatementDate$ = this.cellInformationStatementDate.asObservable();
    this.cellInformationVisible$ = this.cellInformationVisible.asObservable();
  }

  hideCellInformation(): void {
    this.cellInformationVisible.next(false);
  }

  showCellInformation(
    cellInformation: DataFrameValue,
    cellInformationTitle: string,
    cellInformationStatementDate: string
  ): void {
    this.cellInformationVisible.next(false);
    this.cellInformation.next(cellInformation);
    this.cellInformationStatementDate.next(cellInformationStatementDate);
    this.cellInformationTitle.next(cellInformationTitle);
    this.cellInformationVisible.next(true);
  }
}
