import { ComponentFactoryResolver, Directive, OnInit, ViewContainerRef } from '@angular/core';
import { PopoverService } from './popover.service';
import { PopoverComponent } from './popover.component';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appPopover]'
})
@AutoUnsubscribe('subsArr$')
export class PopoverDirective implements OnInit {

  subsArr$: Subscription[] = [];

  constructor(private _viewContainerRef: ViewContainerRef,
              private _popoverService: PopoverService,
              private _componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.subsArr$.push(this._popoverService.data$.subscribe(data => {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(PopoverComponent);

        const currentComponent = this._viewContainerRef.createComponent(componentFactory);
        (<PopoverComponent>currentComponent.instance).data = {
          header: {title: data.title},
          content: data.content,
          footer:
            [
              {
                type: 'button',
                classNames: ['btn', 'btn-link', 'btn-sm'],
                content: 'Close',
                event: {type: 'click', action: () => this.close()}
              },
              {
                type: 'button',
                classNames: ['btn', 'btn-primary', 'btn-sm'],
                content: 'Upload',
                event: {type: 'click', action: () => this.upload()}
              },
            ],
          target: '#formulas__mismatch'
        };
        // kostyl
        window.setTimeout(() => {
          document.addEventListener('click', this.close);
        }, 100);
      })
    )
  }

  upload(): void {
    document.getElementById('uploadData').click();
  }

  close = (): void => {
    document.removeEventListener('click', this.close);
    this._viewContainerRef.clear();
  }
}
