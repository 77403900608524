<div class="modal__header">
    <div class="modal__title">
        ADD NEW USER
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form [formGroup]="userForm" (submit)="save(userForm.value, userForm.valid)">
    <div class="modal__content">
        <div class="form-header">
            Please, complete the fields below:
        </div>


        <div class="mb-3">
            <label class="control-label" for="name">Full Name</label>
            <input [ngClass]="{'is-invalid': isFieldInvalid('name')}" type="text" class="form-control" id="name" formControlName="name" maxlength="25">
            <span class="invalid-feedback"
                  *ngIf="isFieldInvalid('name') && userForm.get('name').hasError('required')">
                Name is required
            </span>
        </div>

        <div class="mb-3">
            <label class="control-label" for="email">Email address</label>
            <input [ngClass]="{'is-invalid': isFieldInvalid('email')}" type="text" class="form-control" formControlName="email" id="email">
            <span class="invalid-feedback" *ngIf="isFieldInvalid('email') && userForm.get('email').hasError('pattern')">
                Email is not valid
            </span>
            <span class="invalid-feedback"
                  *ngIf="isFieldInvalid('email') && userForm.get('email').hasError('required')">
                Email is required
            </span>
        </div>

        <div class="row">
            <div class="col-6 mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="admin" formControlName="admin">
                    <label class="form-check-label">Administrator</label>
                </div>
            </div>
            <div class="col-6 mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="active" formControlName="active">
                    <label class="form-check-label">Active</label>
                </div>
            </div>
        </div>
        <p>The user will be sent a temporary password</p>
    </div>

    <div class="modal__footer d-flex justify-content-end">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="submitted">Submit</button>
    </div>
</form>
