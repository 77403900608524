<div *ngIf="cellInformationVisible" class='cell-information-container'>
    <div class='card'>
        <div class='chart-header card-header cell-information-header'>
            {{ cellInformationTitle }}
            <div style="display: inline; font-weight: normal; margin-left: 10px;">
                — {{ cellInformationStatementDate }}  —  Comment
            </div>
            <div class="button-cancel" (click)="hideCellInformation()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </div>
        <div
            class="source-item-information-div"
        >
            <div *ngIf="hasComments()">
                Comments on Source Item:
                <div *ngFor="let sourceComment of cellInformation.sourceComments">
                    • {{ sourceComment.comment }}
                </div>
            </div>
        </div>
    </div>
</div>
