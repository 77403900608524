import { Component, OnInit } from '@angular/core';
import { NgxPopupComponent } from '../../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { NgxPopupService } from '../../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { NonFinancialCovenantFormComponent } from '../../../../shared/non-financial-covenant-form/non-financial-covenant-form.component';
import { FinancialCovenantFormComponent } from '../../../../shared/financial-covenant-form/financial-covenant-form.component';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { Company } from '../../../../../../app/models/company';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-list-covenants',
  templateUrl: './list-covenants.component.html',
  styleUrls: ['./list-covenants.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ListCovenantsComponent implements OnInit {

  subsArr$: Subscription[] = [];
  company: Company = null;
  analyses = [];

  constructor(
    private _popupService: NgxPopupService,
    private _sharedData: SharedDataService,
  ) { }

  ngOnInit() {
    this.subsArr$.push(this._sharedData.company$.pipe(
      first(company => company !== null))
      .subscribe((company: Company) => {
        this.company = company;
      }),
    );
  }


  addFinancialCovenant() {
    const company = this.company;

    this._popupService.open({
      componentType: FinancialCovenantFormComponent,
      cssClass: 'modal-add-financial-covenant',
      inputs: {
        company,
        formType: 'ADD',
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        // what to do on close? Probably hit get non financial covenants endpoint
      }, {once: true});
    });
  }


  addNonFinancialCovenant() {
    const company = this.company;
    this._popupService.open({
      componentType: NonFinancialCovenantFormComponent,
      cssClass: 'modal-add-non-financial-covenant',
      inputs: {
        company,
        formType: 'ADD',
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        // what to do on close? Probably hit get financial covenants endpoint
      }, {once: true});
    });
  }

}
