<div class="table-wrapper">
<div class="global-cashflow-table">
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Statement Date</strong></div>
        <ng-container *ngIf="headersMetadata?.length > 0">
            <div *ngFor="let column of headersMetadata" class="metadata-row-cell row-value-cell">
                <strong>{{column?.statementDate}}</strong>
            </div>
        </ng-container>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Scenario</strong></div>
        <ng-container *ngIf="headersMetadata?.length > 0">
            <div *ngFor="let column of headersMetadata" class="metadata-row-cell row-value-cell">
                <strong>{{ column?.scenario !== '' ? column.scenario : 'HISTORICAL'}}</strong>
            </div>
        </ng-container>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Months Covered</strong></div>
        <ng-container *ngIf="headersMetadata?.length > 0">
            <div *ngFor="let column of headersMetadata" class="metadata-row-cell row-value-cell">
                <strong>{{column?.monthsInInterval}}</strong>
            </div>
        </ng-container>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Prep Type</strong></div>
        <ng-container *ngIf="headersMetadata?.length > 0">
            <div *ngFor="let column of headersMetadata" class="metadata-row-cell row-value-cell">
                <strong>{{column?.preparationType}}</strong>
            </div>
        </ng-container>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Statement Type</strong></div>
        <ng-container *ngIf="headersMetadata?.length > 0">
            <div *ngFor="let column of headersMetadata" class="metadata-row-cell row-value-cell">
                <strong>{{column?.interval}}</strong>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="primaryEntity?.columns.length > 0">
        <ng-container *ngFor="let parentTemplateItem of templateItems; let parentTemplateIdx = index">
            <div class="body-row">
                <div class="header-row-cell">
                    <strong><span>{{parentTemplateItem?.label}}</span></strong>
                </div>
            </div>
            <ng-container *ngIf="parentTemplateItem.children.length > 0">
                <ng-container *ngIf="isSectionForEntitySpecificItems(parentTemplateItem); else cashFlowCalculationsTemplateItem">
                    <div class="body-row">
                        <div class="clickable header-row-cell" (click)="toggleEntityDisplay(parentTemplateIdx, 0)">
                            <span *ngIf="entityDrawerState[parentTemplateIdx][0]">
                                <i class="fas fa-xs fa-chevron-down"></i>
                            </span>
                            <span *ngIf="!entityDrawerState[parentTemplateIdx][0]">
                                <i class="fas fa-xs fa-chevron-right"></i>
                            </span>
                            {{primaryEntity?.entityName}}
                        </div>
                        <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                            <ng-container *ngIf="templateItemAdditionalAttributes[childElement.standardLineItemId]?.isEntitySubsection">
                                <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                    <ng-container *ngIf="!entityDrawerState[parentTemplateIdx][0]">
                                        <div app-debt-service-coverage-table-cell class="row-value-cell"
                                        [hasScopes]="primaryEntityScopes.length > 0"
                                        [rowIdx]="parentTemplateIdx"
                                        [colIdx]="colIdx"
                                        [childTemplateItemIdx]="childIdx"
                                        [isPrimaryEntity]="isPrimarySelected"
                                        [ref]="childElement.ref"
                                        [equationValue]="analysisService.evaluateFormula(getEquationsForFormulaEvaluation(childElement, primaryEntity), primaryEntityScopes[colIdx])"
                                        [scope]="primaryEntityScopes[colIdx]"
                                        [textFormat]="childElement.textFormat"
                                        ></div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="entityDrawerState[parentTemplateIdx][0]">
                        <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                            <ng-container *ngIf="!specialRefsToNotShowForPrimaryEntity.includes(childElement.ref) && !templateItemAdditionalAttributes[childElement.standardLineItemId]?.isTemplateSection">
                                <ng-container *ngIf="childElement.ref === 'REF_DSCR_USER_ENTERED_PROP_DEBT'; else notUserEnteredDebt">
                                    <div class="body-row" *ngFor="let proposedDebt of proposedDebts">
                                        <div class="header-row-cell child-element">{{proposedDebt.name}}</div>
                                        <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                            <div app-debt-service-coverage-table-cell class="row-value-cell"
                                            [hasScopes]="primaryEntityScopes.length > 0"
                                            [rowIdx]="parentTemplateIdx"
                                            [colIdx]="colIdx"
                                            [allowEdit]="proposedDebtEditable"
                                            [ref]="childElement.ref"
                                            [equationValue]="equationValueForProposedDebt(proposedDebt, column.monthsInInterval)"
                                            [textFormat]="childElement.textFormat"
                                            ></div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-template #notUserEnteredDebt>
                                    <div class="body-row" *ngIf="shouldShowRow(primaryEntity, childElement, parentTemplateItem)">
                                        <div class="child-element header-row-cell">
                                            {{childElement?.label}}
                                        </div>
                                        <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                            <ng-container *ngIf="templateItemAdditionalAttributes[childElement.standardLineItemId]?.isEntitySubsection; else nonCalculated">
                                                <div app-debt-service-coverage-table-cell class="row-value-cell"
                                                [hasScopes]="primaryEntityScopes.length > 0"
                                                [rowIdx]="parentTemplateIdx"
                                                [colIdx]="colIdx"
                                                [childTemplateItemIdx]="childIdx"
                                                [isPrimaryEntity]="isPrimarySelected"
                                                [ref]="childElement.ref"
                                                [equationValue]="analysisService.evaluateFormula(getEquationsForFormulaEvaluation(childElement, primaryEntity), primaryEntityScopes[colIdx])"
                                                [scope]="primaryEntityScopes[colIdx]"
                                                [textFormat]="childElement.textFormat"
                                                ></div>
                                            </ng-container>
                                            <ng-template #nonCalculated>
                                                <div app-debt-service-coverage-table-cell class="row-value-cell"
                                                [hasScopes]="primaryEntityScopes.length > 0"
                                                [rowIdx]="parentTemplateIdx"
                                                [colIdx]="colIdx"
                                                [childTemplateItemIdx]="childIdx"
                                                [isPrimaryEntity]="isPrimarySelected"
                                                [ref]="childElement.ref"
                                                [isEditing]="editingRow == parentTemplateIdx && editingColumn == colIdx  && editingChildTemplateItemIdx == childIdx && isEditingPrimaryEntity"
                                                [equationValue]="analysisService.evaluateFormula(getEquationsForFormulaEvaluation(childElement, primaryEntity), primaryEntityScopes[colIdx])"
                                                [originalValueBeforeOverrides]="getOriginalValueBeforeOverrides(childElement.ref, colIdx)"
                                                [scope]="primaryEntityScopes[colIdx]"
                                                [overrideValue]="displayDataOverrideValue(true, -1, childElement.ref, colIdx)"
                                                [isHighlighted]="selectedRow == parentTemplateIdx && selectedColumn == colIdx  && selectedChildTemplateItemIdx == childIdx && isPrimarySelected"
                                                [textFormat]="childElement.textFormat"
                                                (highlightCell)="selectedCell(colIdx, parentTemplateIdx, childIdx, true, -1)"
                                                (startedEditMode)="editCell(colIdx, parentTemplateIdx, childIdx, true, -1)"
                                                (doneEditing)="doneEditingCell($event)"
                                                ></div>
                                            </ng-template>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="linkedEntities.length > 0">
                        <ng-container *ngFor="let linkedEntity of linkedEntities; let linkedEntityIdx = index">
                            <div class="body-row">
                                <div class="clickable header-row-cell" (click)="toggleEntityDisplay(parentTemplateIdx, linkedEntityIdx+1)">
                                    <span *ngIf="!entityDrawerState[parentTemplateIdx][linkedEntityIdx+1]">
                                        <i class="fas fa-xs fa-chevron-right"></i>
                                    </span>
                                    <span *ngIf="entityDrawerState[parentTemplateIdx][linkedEntityIdx+1]">
                                        <i class="fas fa-xs fa-chevron-down"></i>
                                    </span>
                                    {{linkedEntity?.entityName}}
                                </div>
                                <ng-container *ngIf="!entityDrawerState[parentTemplateIdx][linkedEntityIdx+1]">
                                    <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                                        <ng-container *ngIf="templateItemAdditionalAttributes[childElement.standardLineItemId]?.isEntitySubsection">
                                            <ng-container *ngFor="let column of linkedEntity?.columns; let colIdx = index">
                                                    <div app-debt-service-coverage-table-cell class="row-value-cell"
                                                    [hasScopes]="linkedEntityScopes[linkedEntityIdx].length > 0"
                                                    [rowIdx]="parentTemplateIdx"
                                                    [colIdx]="colIdx"
                                                    [childTemplateItemIdx]="childIdx"
                                                    [linkedEntityId]="linkedEntityIdx"
                                                    [ref]="childElement.ref"
                                                    [equationValue]="analysisService.evaluateFormula(getEquationsForFormulaEvaluation(childElement, linkedEntity), linkedEntityScopes[linkedEntityIdx][colIdx])"
                                                    [scope]="linkedEntityScopes[linkedEntityIdx][colIdx]"
                                                    [textFormat]="childElement.textFormat"
                                                    ></div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="entityDrawerState[parentTemplateIdx][linkedEntityIdx+1]">
                                <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                                    <ng-container *ngIf="!specialRefsToNotShowForLinkedEntities.includes(childElement.ref) && !templateItemAdditionalAttributes[childElement.standardLineItemId]?.isTemplateSection">
                                        <ng-container *ngIf="childElement.ref === 'REF_GCF_OPEN_TRADELINES'; else notTradeline">
                                            <div class="body-row">
                                                <ng-container *ngIf="orderedEntitySeparatedOpenTradelines[linkedEntityIdx].length > 0; else noTradelinesForEntity">
                                                    <div class="clickable tradeline-dropdown-cell" (click)="toggleTradelineDisplay(linkedEntityIdx)">
                                                        <span *ngIf="!tradelineDrawerState[linkedEntityIdx]">
                                                            <i class="fas fa-xs fa-chevron-right"></i>
                                                        </span>
                                                        <span *ngIf="tradelineDrawerState[linkedEntityIdx]">
                                                            <i class="fas fa-xs fa-chevron-down"></i>
                                                        </span>
                                                        {{childElement?.label}}
                                                    </div>
                                                </ng-container>
                                                <ng-template #noTradelinesForEntity>
                                                    <div class="child-element header-row-cell">{{childElement?.label}}</div>
                                                </ng-template>
                                                <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                                    <ng-container *ngIf="!tradelineDrawerState[linkedEntityIdx]">
                                                        <div app-debt-service-coverage-table-cell class = "row-value-cell"
                                                        [hasScopes]="primaryEntityScopes.length > 0"
                                                        [rowIdx]="parentTemplateIdx"
                                                        [colIdx]="colIdx"
                                                        [allowEdit]="false"
                                                        [ref]="childElement.ref"
                                                        [equationValue]="linkedEntityScopes[linkedEntityIdx][colIdx][childElement.ref]"
                                                        [textFormat]="childElement.textFormat"
                                                        [displayNumForZero]="isTradelineSumANumber(orderedEntitySeparatedOpenTradelines[linkedEntityIdx])"
                                                        ></div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <ng-container *ngIf="tradelineDrawerState[linkedEntityIdx]">
                                                <ng-container *ngFor="let tradeline of orderedEntitySeparatedOpenTradelines[linkedEntityIdx]; let tradelineIdx = index">
                                                    <div class="body-row">
                                                        <div class="tradeline-header-row-cell">{{tradeline.displayName}}</div>
                                                        <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                                            <div app-debt-service-coverage-table-cell class="row-value-cell"
                                                            [hasScopes]="primaryEntityScopes.length > 0"
                                                            [rowIdx]="parentTemplateIdx"
                                                            [colIdx]="colIdx"
                                                            [tradelineIdx]="tradelineIdx"
                                                            [linkedEntityId]="linkedEntityIdx"
                                                            [allowEdit]="true"
                                                            [ref]="childElement.ref"
                                                            [isEditing]="editingRow == parentTemplateIdx && editingColumn == colIdx  && editingChildTemplateItemIdx == childIdx && !isEditingPrimaryEntity && relatedEntityBeingEdited == linkedEntityIdx && tradelineBeingEdited == tradelineIdx"
                                                            [equationValue]="equationValueForTradeline(tradeline, colIdx)"
                                                            [originalValueBeforeOverrides]="equationValueForTradeline(tradeline, colIdx)"
                                                            [overrideValue]="displayTradelineDataOverrideValue(linkedEntityIdx, tradelineIdx, colIdx)"
                                                            [isHighlighted]="selectedRow == parentTemplateIdx && selectedColumn == colIdx  && selectedChildTemplateItemIdx == childIdx && !isPrimarySelected && linkedEntitySelected == linkedEntityIdx && tradelineSelected == tradelineIdx"
                                                            [textFormat]="childElement.textFormat"
                                                            [displayNumForZero]="equationValueForTradeline(tradeline) == 0"
                                                            (highlightCell)="selectedCell(colIdx, parentTemplateIdx, childIdx, false, linkedEntityIdx, tradelineIdx)"
                                                            (startedEditMode)="editCell(colIdx, parentTemplateIdx, childIdx, false, linkedEntityIdx, tradelineIdx)"
                                                            (doneEditing)="doneEditingCell($event)"
                                                            ></div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="orderedEntitySeparatedOpenTradelines[linkedEntityIdx].length > 0">
                                                    <div class="body-row">
                                                        <div class="tradeline-header-row-cell">Total {{childElement?.label}}</div>
                                                        <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                                            <div app-debt-service-coverage-table-cell class = "row-value-cell"
                                                            [hasScopes]="primaryEntityScopes.length > 0"
                                                            [rowIdx]="parentTemplateIdx"
                                                            [colIdx]="colIdx"
                                                            [allowEdit]="false"
                                                            [ref]="childElement.ref"
                                                            [equationValue]="linkedEntityScopes[linkedEntityIdx][colIdx][childElement.ref]"
                                                            [textFormat]="childElement.textFormat"
                                                            [displayNumForZero]="isTradelineSumANumber(orderedEntitySeparatedOpenTradelines[linkedEntityIdx])"
                                                            ></div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #notTradeline>
                                            <div class="body-row" *ngIf="shouldShowRow(linkedEntity, childElement, parentTemplateItem)">
                                                <div class="child-element header-row-cell">{{childElement?.label}}</div>
                                                <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                                    <ng-container *ngIf="templateItemAdditionalAttributes[childElement.standardLineItemId]?.isEntitySubsection; else nonCalculatedLinked">
                                                        <div app-debt-service-coverage-table-cell class = "row-value-cell"
                                                        [hasScopes]="linkedEntityScopes[linkedEntityIdx].length > 0"
                                                        [rowIdx]="parentTemplateIdx"
                                                        [colIdx]="colIdx"
                                                        [childTemplateItemIdx]="childIdx"
                                                        [linkedEntityId]="linkedEntityIdx"
                                                        [ref]="childElement.ref"
                                                        [equationValue]="analysisService.evaluateFormula(getEquationsForFormulaEvaluation(childElement, linkedEntity), linkedEntityScopes[linkedEntityIdx][colIdx])"
                                                        [scope]="linkedEntityScopes[linkedEntityIdx][colIdx]"
                                                        [textFormat]="childElement.textFormat"
                                                        ></div>
                                                    </ng-container>
                                                    <ng-template #nonCalculatedLinked>
                                                        <div app-debt-service-coverage-table-cell class = "row-value-cell"
                                                        [hasScopes]="linkedEntityScopes[linkedEntityIdx].length > 0"
                                                        [rowIdx]="parentTemplateIdx"
                                                        [colIdx]="colIdx"
                                                        [childTemplateItemIdx]="childIdx"
                                                        [linkedEntityId]="linkedEntityIdx"
                                                        [ref]="childElement.ref"
                                                        [isEditing]="editingRow == parentTemplateIdx && editingColumn == colIdx  && editingChildTemplateItemIdx == childIdx && !isEditingPrimaryEntity && relatedEntityBeingEdited == linkedEntityIdx"
                                                        [equationValue]="analysisService.evaluateFormula(getEquationsForFormulaEvaluation(childElement, linkedEntity), linkedEntityScopes[linkedEntityIdx][colIdx])"
                                                        [originalValueBeforeOverrides]="getOriginalValueBeforeOverrides(childElement.ref, colIdx, linkedEntityIdx)"
                                                        [scope]="linkedEntityScopes[linkedEntityIdx][colIdx]"
                                                        [overrideValue]="displayDataOverrideValue(false, linkedEntityIdx, childElement.ref, colIdx)"
                                                        [isHighlighted]="selectedRow == parentTemplateIdx && selectedColumn == colIdx  && selectedChildTemplateItemIdx == childIdx && !isPrimarySelected && linkedEntitySelected == linkedEntityIdx"
                                                        [textFormat]="childElement.textFormat"
                                                        (highlightCell)="selectedCell(colIdx, parentTemplateIdx, childIdx, false, linkedEntityIdx)"
                                                        (startedEditMode)="editCell(colIdx, parentTemplateIdx, childIdx, false, linkedEntityIdx)"
                                                        (doneEditing)="doneEditingCell($event)"
                                                        ></div>
                                                    </ng-template>
                                                </ng-container>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                        <div
                            class="body-row"
                            *ngIf="templateItemAdditionalAttributes[childElement.standardLineItemId]?.isTemplateSection === true && allEntityScopes.length"
                        >
                            <div class="header-row-cell"><strong>{{childElement?.label}}</strong></div>
                            <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                <div app-debt-service-coverage-table-cell class="row-value-cell"
                                    [hasScopes]="allEntityScopes.length > 0"
                                    [rowIdx]="parentTemplateIdx"
                                    [colIdx]="colIdx"
                                    [allowEdit]="proposedDebtEditable"
                                    [ref]="childElement.ref"
                                    [equationValue]="allEntityScopes[colIdx][childElement.ref] ? allEntityScopes[colIdx][childElement.ref] : 0"
                                    [scope]="allEntityScopes[colIdx]"
                                    [textFormat]="childElement.textFormat"
                                ></div>
                            </ng-container>
                        </div>
                    </ng-container>

                </ng-container>
                <ng-template #cashFlowCalculationsTemplateItem>
                    <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                        <ng-container *ngIf="!specialRefsToNotShowForLinkedEntities.includes(childElement.ref) && !templateItemAdditionalAttributes[childElement.standardLineItemId]?.isTemplateSection">
                            <div class="body-row">
                                <div class="header-row-cell">{{childElement?.label}}</div>
                                <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                    <div app-debt-service-coverage-table-cell class="row-value-cell"
                                        [hasScopes]="allEntityScopes.length > 0"
                                        [rowIdx]="parentTemplateIdx"
                                        [colIdx]="colIdx"
                                        [allowEdit]="isGenericCalculation(childElement)"
                                        [ref]="childElement.ref"
                                        [equationValue]="analysisService.evaluateFormula(!!childElement.equationOverride ? childElement.equationOverride : childElement.originalEquation, allEntityScopes[colIdx])"
                                        [scope]="allEntityScopes[colIdx]"
                                        [textFormat]="childElement.textFormat"
                                    ></div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                        <div
                            class="body-row"
                            *ngIf="templateItemAdditionalAttributes[childElement.standardLineItemId]?.isTemplateSection === true && allEntityScopes.length"
                        >
                            <div class="header-row-cell"><strong>{{childElement?.label}}</strong></div>
                            <ng-container *ngFor="let column of primaryEntity?.columns; let colIdx = index">
                                <div app-debt-service-coverage-table-cell class="row-value-cell"
                                    [hasScopes]="allEntityScopes.length > 0"
                                    [rowIdx]="parentTemplateIdx"
                                    [colIdx]="colIdx"
                                    [allowEdit]="proposedDebtEditable"
                                    [ref]="childElement.ref"
                                    [equationValue]="allEntityScopes[colIdx][childElement.ref] ? allEntityScopes[colIdx][childElement.ref] : 0"
                                    [scope]="allEntityScopes[colIdx]"
                                    [textFormat]="childElement.textFormat"
                                ></div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
</div>