import { LineItemObject } from './line-item';
import { Deserializable } from './deserializable';

export class LineItemsNormalizationInfo {
    items: Array<LineItemNormalizationInfo> = new Array<LineItemNormalizationInfo>();

    add(item: LineItemNormalizationInfo) {
        this.items.push(item);
    }

    getItem(id: number): LineItemNormalizationInfo {
        // eslint-disable-next-line eqeqeq
        return this.items.find(i => i.id == id);
    }
}

export class LineItemNormalizationInfo extends LineItemObject {
    documentType: string;
    itemType: string;
    matchedLineItem: MatchedLineItem;
    possibleFincuraItems: Array<LineItemMatchingInfo>;


    deserialize(input: any): this {
        Object.assign(this, input);

        if (this.matchedLineItem) {
            this.matchedLineItem = new MatchedLineItem().deserialize(this.matchedLineItem);
        }
        if (this.possibleFincuraItems) {
            this.possibleFincuraItems = this.possibleFincuraItems.map(item =>
                new LineItemMatchingInfo().deserialize(item));
        }
        return this;
    }
}

export class LineItemMatchingInfo implements Deserializable {
    possibleMatchItemName: string;
    confidenceRatio: number;

    constructor(possibleMatchItemName?: string,
        confidenceRatio?: number) {
        this.possibleMatchItemName = possibleMatchItemName;
        this.confidenceRatio = confidenceRatio;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}

export class MatchedLineItem implements Deserializable {
    matchedItemId: number;
    matchedItemName: string;

    constructor(matchedItemId?: number, matchedItemName?: string) {
        this.matchedItemId = matchedItemId;
        this.matchedItemName = matchedItemName;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}
