<div class="dropdown">
    <button [disabled]="!canAddBorrowerToPortfolio"
            class="btn btn-xs btn-default dropdown-toggle portfolio-{{portfolioService.cssColorNumber(company.portfolio)}}"
            type="button" id="dropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{(!!this.company.portfolio) ? this.company.portfolio.name : 'Unassigned'}}
        <span class="caret"></span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <a class="dropdown-item" *ngFor="let portfolioOption of portfolioOptions"
           (click)="setPortfolio(portfolioOption)">{{portfolioOption.name}}</a>
    </div>
</div>
