import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  SimpleChanges,
  OnDestroy, Input
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, finalize, map, takeUntil} from "rxjs/operators";
import {BorrowerService} from "@services/borrower.service";
import {Subject, Subscription} from "rxjs";
import {Company} from "@models/company";

@Component({
  selector: 'app-dynamic-search',
  templateUrl: './dynamic-search.component.html',
  styleUrls: ['./dynamic-search.component.scss']
})
export class DynamicSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchResultsFilter?: (res: any) => boolean;
  @Output('whenSelected') _select = new EventEmitter<any>();
  searchControl: FormControl<string> = new FormControl("");
  arrowkeyLocation: number = -1;
  borrowerSearchResults: Company[] = [];
  searchInProgress: boolean = false;
  searchSubscription: Subscription;

  noResultsMessage = "No results found. Please try a different search."
  noResultsMessageIsVisible: boolean = false;

  private unsubscribe$ = new Subject();

  constructor(
    private _borrowerService: BorrowerService
  ) {
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(debounceTime(400))
      .subscribe(searchTerm => {
        this.noResultsMessageIsVisible = false;
        if (searchTerm && searchTerm.length > 0) {
          this.searchBorrower(searchTerm);
        } else {
          this.borrowerSearchResults = [];
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.items?.previousValue) {
      this.arrowkeyLocation = -1;
    }
  }

  @HostListener('document:click', ['$event']) clickedOutside($event) {
    this.resetSearch();
  }

  clickedInside($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public select(selection): void {
    this._select.emit(selection);
    this.resetSearch();
  }

  resetSearch(): void {
    this.borrowerSearchResults = [];
    this.searchControl.setValue("");
    this.noResultsMessageIsVisible = false;
  }

  keydownHandler(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // arrow up
        if (this.arrowkeyLocation > 0) {
          this.arrowkeyLocation--;
        }
        break;
      case 40: // arrow down
        if (this.arrowkeyLocation < (this.borrowerSearchResults.length - 1)) {
          this.arrowkeyLocation++;
        }
        break;
      case 13: // enter
        if (this.arrowkeyLocation > -1) {
          this.select(this.borrowerSearchResults[this.arrowkeyLocation]);
        }
        event.preventDefault(); // so we don't submit form on accident
        event.stopPropagation(); // so we don't submit form on accident
        break;
    }
  }

  searchBorrower(name) {
    this.borrowerSearchResults = [];
    if (name !== '') {

      this.searchInProgress = true;
      // cancel previous requests
      this.unsubscribe$.next(true);
      this.searchSubscription = this._borrowerService.searchBorrowersByName(name)
        .pipe(takeUntil(this.unsubscribe$))
        .pipe(map(response => response.response.objects))
        .pipe(
          // if a filter function was passed in as input, apply it to the result set
          map(results => this.searchResultsFilter ? results.filter(this.searchResultsFilter) : results)
        )
        .pipe(finalize(() => {
          this.searchInProgress = false;
        }))
        .subscribe(borrowers => {
          this.borrowerSearchResults = borrowers;
          if (!this.borrowerSearchResults?.length){
            this.noResultsMessageIsVisible = true;
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
