<div class="table-cell-container">
    <div *ngIf="!isEditing"
        [ngClass]="{
            'isHighlighted': isHighlighted,
            'notHighlighted': !isHighlighted,
            'changed': isChanged,
            'footnote': footnotes !== null,
            'insufficientValue': cell.validationFlag === 'INSUFFICIENT',
            'sufficientValue': cell.validationFlag === 'SUFFICIENT'
        }"
        (click)="startHighlightMode($event)"
        (dblclick)="startEditMode($event)"
        class="value-container"
    >
    <app-comment-present-indicator
        [sourceComments]="sourceComments"
    ></app-comment-present-indicator>
    <span *ngIf="newValue === 0 && isConsideredHeader(row)"></span>
    <span *ngIf="newValue === 0 && !isConsideredHeader(row)">-</span>

        <span *ngIf="newValue !== 0">
            <span *ngIf="showFormattedValue">{{formattedValue}}</span>
            <span *ngIf="!showFormattedValue">{{adjustedValueToDisplay() | formatDataFrameValue}}</span>
        </span>

        <div [ngClass]="{'show-on-hover': !contextMenuOpen}" class="cell-menu-toggle" (click)="toggleContextMenu($event)">
            <i class="fas fa-chevron-down"></i>
        </div>
        <ul class="dropdown-menu" *ngIf="contextMenuOpen">
            <li *ngIf="!isSourceStatement"><span (click)="insightsClicked($event)"><i class="fas fa-chart-simple"></i>View Insights</span></li>
            <li *ngIf="allowEdit && isChanged"><span (click)="revertToOriginal($event)">Revert to Original Value</span></li>
            <ng-container *ngIf="footnotes !== null">
                <li *ngFor="let footnote of footnotes; let idx = index">
                    <span (click)="enterFootnotesMode(idx, $event)"><i class="fas fa-pen-to-square"></i>Show Footnote {{idx + 1}}</span>
                </li>
            </ng-container>
            <li *ngIf="cellHasComments(cell)">
                <span (click)="openComments($event)"><i class="fas fa-comment"></i>Show Comment</span>
            </li>
            <!-- only show document list to edit/download docs if the user is not in view only mode -->
            <ng-container *ngIf="!viewOnlyMode && showSpreadingInContextMenu && !allowEdit">
              <ng-container *ngFor="let doc of contextMenuDocumentItems">
                <li class="dropdown-divider"></li>
                <li><span class="source-doc-section-header"><strong>{{doc.label}}</strong></span></li>
                <li  class="context-menu-doc-action"><span (click)="contextMenuDocumentClicked(doc, $event, 'spread')"><i class="fas fa-pencil"></i>Edit Spread</span></li>
                <li class="context-menu-doc-action"><span (click)="contextMenuDocumentClicked(doc, $event, 'download')"><i class="fas fa-download"></i>Download Document</span></li>
              </ng-container>
            </ng-container>
        </ul>
    </div>

    <input *ngIf="isEditing"
        type="text"
        #editCellTextbox
        [(ngModel)]="newValue"
        (focusout)="editComplete()"
        (keyup.enter)="editComplete()"
        (click)="stopPropagation($event)"
    />

</div>
