<div id="sidebar-wrapper">
    <div id="minimized-sidebar">

        <img src="../../../../assets/images/Numerated-2023-icon.svg" />

        <a class="nav-link" title="Home" [routerLink]="['/']" *ngIf="showBorrowerOptions"><i class="fas fa-home nav-icon"></i></a>
        <a *ngIf="shouldShowPortfolios" class="nav-link" title="Portfolios" [routerLink]="['/portfolios']"><i class="fas fa-briefcase nav-icon"></i></a>
        <a *ngIf="isAdmin" class="nav-link" title="Users" [routerLink]="['/users']"><i class="fas fa-users nav-icon"></i></a>
        <a *ngIf="shouldDisplayManualReview" class="nav-link" title="Manual Review" [routerLink]="['/review']"><i class="fas fa-file nav-icon"></i></a>
        <a *ngIf="showSpreadingTemplates" class="nav-link" title="Spreading Templates" [routerLink]="['/spreading-templates']"><i class="fas fa-th-list nav-icon"></i></a>
        <div class="dropdown">
            <a *ngIf="isInternalDeveloper" class="nav-link" data-bs-toggle="dropdown" title="Dev Toolbar">
                <i class="fas fa-wrench nav-icon"></i>
            </a>
            <div class="dropdown-menu">
                <a (click)='restCommander()' class="rest-commander dropdown-item px-2">Send REST request to API
                    provider</a>
                <a (click)='cacheCommander()' class="cache-commander dropdown-item px-2">Redis cache
                    commander</a>
                <a (click)='triggerError()' class="trigger-error dropdown-item px-2">Trigger error message</a>

                <a class="dropdown-item px-2">
                    <div (click)='toggleBypassCache()' class="form-check">
                        <input [checked]='bypassBackendCache' type="checkbox" class="form-check-input"
                               id="bypassCacheCheckbox">
                        <label class="ps-1 form-check-label" for="bypassCacheCheckbox">Bypass backend
                            cache</label>
                    </div>
                </a>
                <a class="dropdown-item px-2">
                    <div (click)='toggleProfiler()' class="form-check">
                        <input [checked]='switchOnProfiler' type="checkbox" class="form-check-input"
                               id="backendProfilerCheckbox">
                        <label class="ps-1 form-check-label" for="backendProfilerCheckbox">Backend profiler for
                            all
                            calls</label>
                    </div>
                </a>
            </div>
        </div>
        <a *ngIf="showReporting" class="nav-link" title="Reporting" [routerLink]="['/reporting']"><i class="fas fa-chart-bar nav-icon"></i></a>
        <a *ngIf="showApiKeysOption" class="nav-link" title="API Keys" [routerLink]="['/api-keys']"><i class="fas fa-key nav-icon"></i></a>
        <a class="nav-link nav-toggle" title="Open Menu" (click)="toggleSidebar()">
            <i class="fas fa-bars nav-icon"></i>
        </a>
    </div>
</div>
