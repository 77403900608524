/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialRequirementRules } from './financialRequirementRules';


export interface FinancialRequirement { 
    readonly uuid?: string;
    /**
     * Title of the FinancialRequirement. Must be unique for the Borrower
     */
    name: string;
    description?: string | null;
    readonly createdDate?: string;
    /**
     * External ID is used to uniquely identify a record from your system in our system. Must be unique across records.
     */
    externalId?: string | null;
    /**
     * UUID of the borrower for this loan. (see [Borrowers](#tag/Borrowers))
     */
    borrowerUuid: string;
    /**
     * UUID of the loan for this requirement. (see [Loans](#tag/Loans))
     */
    loanUuid?: string;
    inputEquation: string;
    frequency?: FinancialRequirement.FrequencyEnum;
    rules?: Array<FinancialRequirementRules>;
}
export namespace FinancialRequirement {
    export type FrequencyEnum = 'MONTHLY' | 'QUARTERLY' | 'SEMI_ANNUALLY' | 'ANNUALLY' | 'TTM' | 'FISCAL_YTD';
    export const FrequencyEnum = {
        Monthly: 'MONTHLY' as FrequencyEnum,
        Quarterly: 'QUARTERLY' as FrequencyEnum,
        SemiAnnually: 'SEMI_ANNUALLY' as FrequencyEnum,
        Annually: 'ANNUALLY' as FrequencyEnum,
        Ttm: 'TTM' as FrequencyEnum,
        FiscalYtd: 'FISCAL_YTD' as FrequencyEnum
    };
}


