import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { IPopupConfig } from '../services/ngx-popup.service';
import { DeferredPromise } from '../classes/DeferredPromise';
import { NgxDOMComponent, NgxDOMComponentContainer } from '../../ngx-dom-component/ngx-dom-component.class';
import { NgxDOMComponentService } from '../../ngx-dom-component/ngx-dom-component.service';


export enum NgxPopupState {
  CLOSED,
  CLOSING,
  OPENED,
  OPENING
}

@Component({
  selector: 'app-ngx-popup',
  template: `
    <div class="modal">
      <ng-template #contentContainer></ng-template>
    </div>
  `,
  styleUrls: ['./popup.component.scss']
})
export class NgxPopupComponent implements AfterViewInit, OnDestroy {

  @ViewChild('contentContainer', { read: ViewContainerRef, static: true }) contentContainer: ViewContainerRef;

  public closable = true;
  public backgroundClosable = false;
  public ngxDOMComponentContainer: NgxDOMComponentContainer;
  public ngxDOMComponent: NgxDOMComponent;
  public state: NgxPopupState = NgxPopupState.CLOSED;

  public _element: HTMLElement;
  /**
   * Returns the DOM element of the popup
   * @returns {HTMLElement}
   */
  get element(): HTMLElement {
    return this._element;
  }

  private openPromise: DeferredPromise<void>;
  private closePromise: DeferredPromise<void>;
  private closableListener: any;

  constructor(private ngxDOMComponentService: NgxDOMComponentService,
              element: ElementRef) {
    this._element = element.nativeElement;

    this.closableListener = (event: Event) => {
      if (!this.closable) {
        event.preventDefault();
      }
    };

    this.addEventListener('beforeclose', this.closableListener);
  }

  /**
   * Returns the instance of the injected component.
   */
  get contentInstance(): any {
    return this.ngxDOMComponent.instance;
  }

  ngAfterViewInit() {
    this.ngxDOMComponentContainer = this.ngxDOMComponentService.createContainer(this.contentContainer);
    requestAnimationFrame(() => { // allows css to apply without the class 'open'
      this.dispatchEvent(new CustomEvent('ready'));
    });
  }

  ngOnDestroy() {
    this.removeEventListener('beforeclose', this.closableListener);
  }

  /**
   * The following events are available
   *
   * - open : after the popup is opened (including transition or not, according to 'waitTransitionEnd').
   * - close : after the popup is closed (including transition or not, according to 'waitTransitionEnd').
   * - beforeclose : when the close method is called. Use event.preventDefault() to cancel close.
   * - cancelopen : when an open is cancelled (ex: while animating, the popup is in a 'opening' state,
   *                if you call close before animation is complete, it cancels the open).
   *
   * All of them are CustomEvent, with a detail property that you can set when calling open or close.
   */

  addEventListener(type: string, listener?: EventListenerOrEventListenerObject, useCapture?: any): void {
    return this._element.addEventListener(type, listener, useCapture);
  }

  dispatchEvent(event: Event): boolean {
    return this._element.dispatchEvent(event);
  }

  removeEventListener(type: string, listener?: EventListenerOrEventListenerObject, useCapture?: any): void {
    return this._element.removeEventListener(type, listener, useCapture);
  }


  open(config: IPopupConfig, waitTransitionEnd: boolean = true, detail?: any): Promise<void> {
    if (!this.openPromise) {
      this.openPromise = new DeferredPromise<void>(() => {
        switch (this.state) {
          case NgxPopupState.CLOSED:
          case NgxPopupState.CLOSING:
            if (!this.dispatchEvent(new CustomEvent('beforeopen', {
              detail: detail,
              bubbles: false,
              cancelable: true
            }))) {
              this.openPromise.reject(new Error('Open prevented'));
            } else {
              if (this.state === NgxPopupState.CLOSING) {
                this.dispatchEvent(new CustomEvent('cancelclose'));
                this.closePromise.reject(new Error('Close cancelled'));
              }

              this.state = NgxPopupState.OPENING;
              this.build(config);

              requestAnimationFrame(() => { // allows content to be rendered before adding 'open'
                this._element.classList.add('open');
                if (config.cssClass) {
                  config.cssClass.split(' ').forEach((cssCl) => {
                    this._element.classList.add(cssCl);
                  });
                }
                if (waitTransitionEnd) {
                  this.waitTransitionEnd().then(() => {
                    if (this.openPromise) {
                      this.openPromise.resolve();
                    }
                  });
                } else {
                  if (this.openPromise) {
                    this.openPromise.resolve();
                  }
                }
              });
            }
            break;
          default:
            this.openPromise.reject(new Error('Popup not closed'));
            break;
        }
      });

      this.openPromise
        .then(() => {
          this.state = NgxPopupState.OPENED;
          this.openPromise = null;
          this.dispatchEvent(new CustomEvent('open', {
            detail: detail
          }));
          if (this._element.ownerDocument.activeElement instanceof HTMLElement) {
            this._element.ownerDocument.activeElement.blur();
          }
        })
        .catch(() => {
          this.openPromise = null;
        });
    }

    return this.openPromise.promise;
  }

  /**
   * Close the popup.
   *
   * @param waitTransitionEnd
   * @param detail
   * @returns {Promise<void>} - promise resolved when the popup is closed
   */
  close(waitTransitionEnd: boolean = true, detail?: any): Promise<void> {
    if (!this.closePromise) {
      this.closePromise = new DeferredPromise<void>(() => {
        switch (this.state) {
          case NgxPopupState.OPENED:
          case NgxPopupState.OPENING:
            if (!this.dispatchEvent(new CustomEvent('beforeclose', {
              detail: detail,
              bubbles: false,
              cancelable: true
            }))) {
              this.closePromise.reject(new Error('Close prevented'));
            } else {
              if (this.state === NgxPopupState.OPENING) {
                this.openPromise.resolve();
                console.log('Open cancelled inside popup.component.ts'); // purposefully logging this
              }

              this.state = NgxPopupState.CLOSING;
              this._element.classList.remove('open');
              if (waitTransitionEnd) {
                this.waitTransitionEnd().then(() => {
                  if (this.closePromise) {
                    this.closePromise.resolve();
                  }
                });
              } else {
                if (this.closePromise) {
                  this.closePromise.resolve();
                }
              }
            }
            break;
          default:
            if (this.closePromise) {
              this.closePromise.reject(new Error('Popup not opened'));
            }
            break;
        }
      });

      this.closePromise
        .then(() => {
          this.state = NgxPopupState.CLOSED;
          this.closePromise = null;
          this.dispatchEvent(new CustomEvent('close', {
            detail: detail
          }));
        }).catch(() => {
        this.closePromise = null;
      });
    }

    return this.closePromise.promise;
  }


  @HostListener('click', ['$event']) onClickBackground(event: any) {
    if (this.backgroundClosable && (event.target === this._element)) {
      this.close(true, event);
    }
  }

  @HostListener('document:keyup', ['$event']) onKeyUp(evt: KeyboardEvent) {
    if (evt.key === 'Escape') {
      this.close(true, event);
    }
    if (evt.key === 'Enter') {
      const selectors = [
        'button[type="submit"]',
        'button[id="closeOnEnter"]'
      ];
      let clickableButton = null;
      selectors.some( (selector: string) => {
        clickableButton = this.ngxDOMComponent.viewContainerRef.element.nativeElement.nextSibling.querySelector(selector);
        if (clickableButton) {
          return true;
        }
      });

      if (clickableButton) {
        clickableButton.click();
      }
    }
  }

  private build(config: IPopupConfig) {
    config.inputs = config.inputs || {};
    config.inputs['popup'] = this;
    this.ngxDOMComponent = this.ngxDOMComponentContainer.create(config);
  }

  private waitTransitionEnd(): Promise<any> {
    return new Promise((resolve: any) => {
      const transitionTime: number = this.getTransitionTime(this._element);
      if ((transitionTime === null) || (transitionTime > 10)) {
        setTimeout(resolve, transitionTime || 250);
        this.addEventListener('transitionend', resolve, {once: true});
      } else {
        resolve();
      }
    });
  }

  private getTransitionTime(element: HTMLElement): number {
    const computedStyle: CSSStyleDeclaration = window.getComputedStyle(element);
    if (computedStyle.transitionDuration) {
      const timeReg = new RegExp('([\\d\\.]+)((?:s)|(?:ms))', 'g');
      const timeMatch = timeReg.exec(computedStyle.transitionDuration);
      if (timeMatch) {
        const time: number = parseFloat(timeMatch[1]);
        switch (timeMatch[2]) {
          case 's':
            return time * 1000;
          case 'ms':
            return time;
        }
      }
    }
    return null;
  }
}
