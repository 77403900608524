import { mergeMap } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { Company } from '../../../../models/company';
import { SharedDataService } from '../../../../services/shared-data.service';
import { BorrowerService } from '../../../../services/borrower.service';
import { ActionService } from '../../../../services/action.service';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { COMPANY_TABS } from '@utils/constants';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class CompanyDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper') wrapper: ElementRef;
  company: Company;
  subsArr$: Subscription[] = [];
  shouldShowTopNav: boolean;
  routeData: Data;
  entitlementDataForBorrowerNav;

  constructor(
    private _companyService: BorrowerService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _actionService: ActionService,
  ) {
    this.routeData = this._route.snapshot.data;
  }

  ngOnInit(): void {
    this.entitlementDataForBorrowerNav = this.routeData.companyEntitlement[COMPANY_TABS.BORROWER_NAV];
    this.shouldShowTopNav = this._sharedDataService.shouldShowTopNav;
    this.subsArr$.push(this._route.params.pipe(
      mergeMap(params => this._companyService.getCompanyByUuid(params['uuid'], params)))
      .subscribe((company: Company) => {
        if (!company) {
          this._router.navigate(['/404']);
          return;
        }
        this._sharedDataService.company$.next(company);
      }));

    this.subsArr$.push(this._sharedDataService.company$.subscribe((company: Company) => {
      this.company = company;
    }));

    this.subsArr$.push(this._actionService.scrollToTop$.subscribe(() => {
      this.wrapper.nativeElement.scrollTop = 0;
    }));
  }

  ngOnDestroy(): void {
    this._sharedDataService.company$.next(null);
  }
}
