<ng-template #imagePlaceholder>
    <div class="image-loading-placeholder" [style.width.px]="inputImageWidth"
         [style.height.px]="inputImageWidth*defaultHeightWidthRatio">
        <ng-container *ngIf="!imageFailedToLoad; else imageLoadError">
            <div class="loader"></div>
        </ng-container>
        <ng-template #imageLoadError>
            <i class="fa fa-circle-exclamation"></i>
        </ng-template>
    </div>
</ng-template>
<div *ngIf="loadedImage; else imagePlaceholder" >
  <div
  #canvas
  class="canvas fs-block fs-exclude"
  [ngClass]="{
    'allow-edit': allowEdit,
    'no-edit': !allowEdit,
    'active-document': showActiveBorder
  }"
  [ngStyle]="canvasStyle()"
  (mousedown)="startAction($event, null, 'addNew')"
  (mouseup)="stopAction($event)"
  (mousemove)="trackMovement($event)"
>
  <div
    class="box"
    *ngFor="let box of boxes; let idx = index"
    [ngStyle]="boxStyle(box)"
    (mousedown)="startAction($event, box, 'move')"
    (mouseup)="checkForClick($event, idx)"
    [ngClass]="{
      'active': box.active,
      'selected': box.selected
    }"
  >
    <button class="remove-box" *ngIf="allowEdit">Delete</button>
    <span class="resize resize-nw"
      (mousedown)="startAction($event, box, 'resize', 'se')"
    ></span>
    <span class="resize resize-top"
      (mousedown)="startAction($event, box, 'resize', 's')"
    ></span>
    <span class="resize resize-ne"
      (mousedown)="startAction($event, box, 'resize', 'sw')"
    ></span>
    <span class="resize resize-e"
      (mousedown)="startAction($event, box, 'resize', 'w')"
    ></span>
    <span class="resize resize-se"
      (mousedown)="startAction($event, box, 'resize', 'nw')"
    ></span>
    <span class="resize resize-s"
      (mousedown)="startAction($event, box, 'resize', 'n')"
    ></span>
    <span class="resize resize-sw"
      (mousedown)="startAction($event, box, 'resize', 'ne')"
    ></span>
    <span class="resize resize-w"
      (mousedown)="startAction($event, box, 'resize', 'e')"
    ></span>

  </div>
</div>
</div>
