import {Component, Input, OnInit} from '@angular/core';
import {DigitizationService} from '@services/shared/digitization/digitization.service';
import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {Subscription} from 'rxjs';
import {Digitization} from '@components/main/spreading/models/digitization';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-prompt-for-projection-name',
  templateUrl: './prompt-for-projection-name.component.html',
  styleUrls: ['./prompt-for-projection-name.component.scss']
})
@AutoUnsubscribe('subscriptions$')
export class PromptForProjectionNameComponent implements OnInit {
  @Input() popup: NgxPopupComponent;

  subscriptions$: Subscription[] = [];

  digitization: Digitization;
  errorMessage: string;
  hasError: boolean;
  submitAttempt = false;
  projectionNameControl: FormControl<string> = new FormControl<string>("", [Validators.required, Validators.maxLength(200)])

  constructor(
    private digitizationService: DigitizationService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions$.push(
      this.digitizationService.digitization$.subscribe((digitization: Digitization) => {
        this.digitization = digitization;
      })
    );
  }

  save(): void {
    this.submitAttempt = true;
    if (this.projectionNameControl.valid) {
      this.hasError = false;
      this.errorMessage = '';
      this.subscriptions$.push(
        this.digitizationService.updateProjectionName(
          this.digitization.id,
          this.projectionNameControl.value.trim()
        ).subscribe(_ => {
          this.popup.close(true, true);
        }, (error) => {
          this.hasError = true;
          this.errorMessage = error.message;
        })
      );
    }
  }
}
