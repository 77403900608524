import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxPopupComponent } from '../../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { NormalBalance } from '@utils/enums';

@Component({
  selector: 'app-normal-balance-selector',
  templateUrl: './normal-balance-selector.component.html',
  styleUrls: ['./normal-balance-selector.component.scss']
})
export class NormalBalanceSelectorComponent implements OnInit {
  @Input() popup: NgxPopupComponent;

  @Output() normalBalance = new EventEmitter<string>();

  subsArr$: Subscription[] = [];
  normalBalanceSelectionForm: FormGroup;
  submitAttempt = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.normalBalanceSelectionForm = this.formBuilder.group({
      normalBalance: [NormalBalance.Debit, [Validators.required]],
    });
  }

  saveSelectedNormalBalance() {
    this.normalBalance.emit(this.normalBalanceSelectionForm.value.normalBalance);
    this.popup.close();
  }

  exitSelectNormalBalanceModal() {
    this.normalBalance.emit(undefined);
    this.popup.close();
  }
}
