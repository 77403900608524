<ngx-charts-line-chart
            *ngIf="viewDimensions"
            [view]="viewDimensions"
            [scheme]="colorScheme"
            [results]="graphItems"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendPosition]="'below'"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
>
</ngx-charts-line-chart>