import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-table-empty-state',
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.scss']
})
export class TableEmptyStateComponent {
  @Input() emptyStateIcon = "";
  @Input() headerText = "";
  @Input() helpText = "";
}
