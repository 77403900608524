import { Component, EventEmitter, HostBinding, HostListener, OnChanges, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: '[app-formula-input-option]',
  templateUrl: './formula-input-option.component.html',
  styleUrls: ['./formula-input-option.component.scss'],
})
export class FormulaInputOptionComponent implements OnChanges, OnInit {
  @Input('option') public option: any;
  @Input('isSelected') public isSelected: boolean;

  @Output() whenMousedown: EventEmitter<any[]> = new EventEmitter();

  @HostBinding('class') class = 'item';
  @HostBinding('class.selected') selected: boolean;

  docTypeLabel: string;
  optionTypeLabel: string;
  docTypeBadgeClass: string;

  periodValue: any;

  constructor() {}

  onMouseDown() {
    this.whenMousedown.emit(this.option.item);
  }

  ngOnChanges(): void {
    this.selected = this.isSelected;
    this.periodValue = (this.option.item.values && this.option.item.values.length > 0) ? this.option.item.values[0].numericValue : '';
  }

  ngOnInit(): void {}
}
