<div class="nav-modal">
    <div class="row">
        <div class="col-3 left-wrapper"> <!-- hijacked for bg color -->
        </div>
        <div class="col-3 sticky-sidebar">
            <ul class="nav">
                <li
                    [ngClass]="{
                        'active': pages[currentPage] == 'deal'
                    }"
                    (click)="changeTab('deal')"
                >Loan Details</li>
                <li
                    [ngClass]="{
                        'active': pages[currentPage] == 'company'
                    }"
                    (click)="changeTab('company')"
                >Company</li>
                <li
                    [ngClass]="{
                        'active': pages[currentPage] == 'data'
                    }"
                    (click)="changeTab('data')"
                >Financial Data</li>
                <li
                    [ngClass]="{
                        'active': pages[currentPage] == 'compliance'
                    }"
                    (click)="changeTab('compliance')"
                >Compliance</li>
                <li
                    [ngClass]="{
                        'active': pages[currentPage] == 'documents'
                    }"
                    (click)="changeTab('documents')"
                >Documents</li>
<!--                <li (click)="changeTab('notes')">Notes</li> Notes commented out since next iteration -->
            </ul>
        </div>
        <div class="col-9 form-body">
            <div class="modal__close" (click)="popup.close();">
                <i class="fas fa-times"></i>
            </div>

            <form
            [formGroup]="loanForm"
            (submit)="save()">


                <div class="row">
                    <div class="col-6 offset-3">
                        <div class="loan-title-wrapper mb-3">
                            <input type="text" class="form-control giant-textbox centered" placeholder="Loan Title" formControlName="title" id="title">
                            <input type="hidden" [value]="company?.uuid" formControlName="borrowerUuid" id="borrowerUuid">
                            <div *ngIf="title.invalid"
                            class="error-text">
                                <div *ngIf="title.errors?.required">
                                    Title is required.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <ng-container *ngIf="isLoading">
                            <div class="loader__container">
                                <div class="loader"></div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="!isLoading && pages[currentPage] === 'deal'">

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Principal Amount
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <input type="number" class="form-control" formControlName="principal" id="principal" step="25000">
                                        <div *ngIf="principal.invalid"
                                        class="error-text">
                                            <div *ngIf="principal.errors?.required">
                                                Principal is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Interest Rate
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <span class="percent-sign">%</span>
                                        <input
                                        class="form-control fs-block"
                                        type="number"
                                        name="interestRate"
                                        formControlName="interestRate"
                                        step=".25"
                                        />
                                        <div *ngIf="interestRate.invalid"
                                        class="error-text">
                                            <div *ngIf="interestRate.errors?.required">
                                                Interest Rate is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Interest Type
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <select class="form-select"
                                            formControlName="interestType">
                                            <option
                                                *ngFor="let interestType of interestTypes | enumToArray; let i = index"
                                                [ngValue]="interestType"
                                            >{{loanService.displayFriendlyName(interestType)}}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Term
                                    </p>
                                </div>

                                <div class="col-6">
                                    <div class="mb-3">
                                        <input type="number" class="form-control" formControlName="termMonths" id="termMonths">
                                        <div *ngIf="termMonths.invalid"
                                        class="error-text">
                                            <div *ngIf="termMonths.errors?.required">
                                                Term months are required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <p class="form-label-valign-middle">
                                        Months
                                    </p>
                                </div>
                            </div>

                            <div class="col-4">
                                Contract Type
                            </div>

                            <div class="col-8">

                                <div class="mb-3">
                                    <select class="form-select"
                                        formControlName="contractType">
                                        <option [ngValue]="null" disabled>Choose Contract Type</option>
                                        <option
                                            *ngFor="let contractType of contractTypes | enumToArray"
                                            [ngValue]="contractType"
                                        >{{loanService.displayFriendlyPaymentTypeName(contractType)}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-4">
                                Payment Type
                            </div>

                            <div class="col-8">

                                <div class="mb-3">
                                    <select class="form-select"
                                        formControlName="paymentType">
                                        <option [ngValue]="null" disabled>Choose Payment Type</option>
                                        <option
                                            *ngFor="let paymentType of paymentTypes | enumToArray"
                                            [ngValue]="paymentType"
                                        >{{loanService.displayFriendlyPaymentTypeName(paymentType)}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-4">
                                Collateral Type
                            </div>
                            <div class="col-8">
                                <div class="mb-3">
                                    <select class="form-select"
                                        formControlName="collateralType">
                                        <option [ngValue]="null" disabled>Choose the Collateral Type</option>
                                        <option
                                            *ngFor="let collateralType of collateralTypes | enumToArray; let i = index"
                                            [ngValue]="collateralType"
                                        >{{loanService.displayFriendlyName(collateralType)}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Collateral Value
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <input type="text" class="form-control" formControlName="collateralValue" id="collateralValue">
                                        <div *ngIf="collateralValue.invalid"
                                        class="error-text">
                                            <div *ngIf="collateralValue.errors?.required">
                                                Collateral value is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                </div>
                            </div>

                            <div class="col-4">
                                Payment Status
                            </div>

                            <div class="col-8">
                                <div class="mb-3">
                                    <select class="form-select"
                                        formControlName="paymentStatus">
                                        <option [ngValue]="null" disabled>Choose the Payment Status</option>
                                        <option
                                            *ngFor="let paymentStatus of paymentStatuses | enumToArray; let i = index"
                                            [ngValue]="paymentStatus"
                                        >{{loanService.displayFriendlyName(paymentStatus)}}</option>
                                    </select>

                                </div>
                            </div>


                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Start Date
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <app-input-calendar-component
                                            fieldName="startDate"
                                            [initialDate]="defaultLoanFormValue('startDate')"
                                            formControlName="startDate"
                                            (dateEmitter)="updateStartDate($event)">
                                        </app-input-calendar-component>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Payments Remaining
                                    </p>
                                </div>
                                <div class="col-8 mb-3">
                                    Calculated Field
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Maturity Date
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <app-input-calendar-component
                                            fieldName="lastPaymentDate"
                                            formControlName="lastPaymentDate"
                                            [initialDate]="defaultLoanFormValue('lastPaymentDate')"
                                            (dateEmitter)="updateLastPaymentDate($event)">
                                        </app-input-calendar-component>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Monthly Payment
                                    </p>
                                </div>
                                <div class="col-8 mb-3">
                                    Calculated Field
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Balloon Payment
                                    </p>
                                </div>
                                <div class="col-8">
                                    <div class="mb-3">
                                        <input type="number" class="form-control" formControlName="balloonPayment" id="balloonPayment">
                                        <div *ngIf="balloonPayment.invalid"
                                        class="error-text">
                                            <div *ngIf="balloonPayment.errors?.required">
                                                Balloon Payment is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Residual Value
                                    </p>
                                </div>
                                <div class="col-8">
                                    <div class="mb-3">
                                        <input type="number" class="form-control" formControlName="residualValue" id="residualValue">
                                        <div *ngIf="residualValue.invalid"
                                        class="error-text">
                                            <div *ngIf="residualValue.errors?.required">
                                                Residual Value is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Loan to Value
                                    </p>
                                </div>
                                <div class="col-8 mb-3">
                                    Calculated Field
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        DSCR
                                    </p>
                                </div>
                                <div class="col-8 mb-3">
                                    Calculated Field
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        State of Loan
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <select class="form-select"
                                            formControlName="bookingStatus">
                                            <option [ngValue]="null" disabled>Choose State of Loan</option>
                                            <option
                                                *ngFor="let bookingStatus of bookingStatuses | enumToArray; let i = index"
                                                [ngValue]="bookingStatus"
                                            >{{loanService.displayFriendlyName(bookingStatus)}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngFor="let customAttrDefinition of customAttrDefinitions" class="mb-3">
                                <ng-container *ngIf="customAttributesValues[customAttrDefinition.key]">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            {{customAttrDefinition.name}}
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <div class="mb-3">
                                            <input type="text"
                                            name="{{customAttrDefinition.key}}"
                                            class="form-control"
                                            [value]="customAttributesValues[customAttrDefinition.key]?.value"
                                            (change)="customAttributeChange(customAttrDefinition, $event)" />
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="!isLoading && pages[currentPage] === 'company'">
                            <div formGroupName="borrowerInfo">
                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Borrower Name
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <div class="mb-3">
                                            <input type="hidden" [value]="company?.name" formControlName="name" id="name">
                                            {{company?.name}}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Borrower Email
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <div class="mb-3">
                                            <input type="text" class="form-control" formControlName="email" id="email">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Company Location
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <input type="text" class="form-control" placeholder="Country" formControlName="country" id="country">
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-8 offset-4 mb-3">
                                        <input type="text" class="form-control" placeholder="Street Address" formControlName="address" id="address">
                                    </div>

                                    <div class="col-3 offset-4 mb-3">
                                        <input type="text" class="form-control" placeholder="City" formControlName="city" id="city">
                                        <div *ngIf="city.invalid"
                                        class="error-text">
                                            <div *ngIf="city.errors?.required">
                                                City is required.
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-2 mb-3">
                                        <input type="text" class="form-control" placeholder="State" formControlName="state" id="state">
                                    </div>

                                    <div class="col-3 mb-3">
                                        <input type="text" class="form-control" placeholder="Zip" formControlName="zipcode" id="zipcode">
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Company Notes
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <div class="mb-3">
                                            <textarea id="body" class="form-control" name="response" rows="4" formControlName="companyNotes">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Industry
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <select class="form-select"
                                            formControlName="industry">
                                            <option
                                                *ngFor="let industry of industries | enumToArray; let i = index"
                                                [ngValue]="industry"
                                            >{{loanService.displayFriendlyName(industry)}}</option>
                                        </select>

                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            NAICS Code
                                        </p>
                                    </div>

                                    <div class="col-8">
                                        <div class="mb-3">
                                            <input type="text" class="form-control" formControlName="naicsCode" id="naicsCode">
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Dun & Bradstreet Score
                                        </p>
                                    </div>

                                    <div class="col-6">
                                        <div class="mb-3">
                                            <input type="text" class="form-control" formControlName="dunBradstreetScore" id="dunBradstreetScore">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-4">
                                        <p class="form-label-valign-middle">
                                            Paynet Score
                                        </p>
                                    </div>

                                    <div class="col-6">
                                        <div class="mb-3">
                                            <input type="text" class="form-control" formControlName="paynetScore" id="paynetScore">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="!isLoading && pages[currentPage] === 'notes'">
                            <h4>Notes</h4>
                        </ng-container>

                        <ng-container *ngIf="!isLoading && pages[currentPage] === 'data'">
                            <div *ngIf="!isInEditMode()" class="row">
                                <div class="col-12">
                                    <h4>Data</h4>
                                </div>

                                <div class="col-3">
                                    <p class="form-label-valign-middle">
                                        Template
                                    </p>
                                </div>

                                <div class="col-8">
                                    <div class="mb-3">
                                        <select class="form-select"
                                            formControlName="templateUuid">
                                            <option [ngValue]="">None</option>
                                            <option
                                                *ngFor="let template of templates; let i = index"
                                                [ngValue]="template.uuid"
                                            >{{template.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!isInEditMode()" class="row">
                                <div class="col-3">
                                    <p class="form-label-valign-middle">
                                        Periods to Analyze
                                    </p>
                                </div>

                                <div class="col-9">
                                    <input (change)="toggleAvailablePeriods()" type="checkbox"> Select All
                                </div>


                                <div
                                    class="col-9 offset-3"
                                    *ngFor="let order of availablePeriods; let i = index"
                                >
                                    <div class="mb-3">

                                        <label
                                            class="period-label"
                                            formArrayName="periods"
                                        >
                                            <input type="checkbox" [formControlName]="i" />
                                            {{availablePeriods[i].label}} {{availablePeriods[i].interval}} {{availablePeriods[i].preparationType}}
                                        </label>
                                    </div>

                                </div>
                                <div class="col-9 offset-3">
                                    <span class="error-text"
                                        *ngIf="isFormInvalid() && !areAnalysisSelectionsValid()">
                                        Either select no periods and no template, or select a template and at least 1 period
                                    </span>
                                </div>
                            </div>

                            <div class="col-12">
                                <h4>Credit Scores</h4>
                            </div>

                            <div
                                formArrayName="guarantors"
                                *ngFor="let guarantor of getGuarantorsControls(loanForm); let i=index;"
                            >
                                <div [formGroupName]="i" class="row mb-3">
                                    <div class="col-1">
                                        <p class="form-label-valign-middle">
                                            First
                                        </p>
                                    </div>
                                    <div class="col-2">
                                        <input type="text" formControlName="firstName" class="form-control" placeholder="">
                                        <p class="error-text" *ngIf="guarantor.get('firstName').errors?.required">
                                            First Name is required.
                                        </p>
                                    </div>

                                    <div class="col-1">
                                        <p class="form-label-valign-middle">
                                            Last
                                        </p>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" formControlName="lastName" class="form-control" placeholder="">
                                        <p class="error-text" *ngIf="guarantor.get('lastName').errors?.required">
                                            Last Name is required.
                                        </p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label-valign-middle">
                                            Credit Score
                                        </p>
                                    </div>

                                    <div class="col-2">
                                        <input type="number" formControlName="creditScore" class="form-control" placeholder="">
                                        <p class="error-text" *ngIf="guarantor.get('creditScore').errors?.required">
                                            Credit Score is required.
                                        </p>
                                    </div>

                                    <div class="col-1 clickable" (click)="removeGuarantor(i);">
                                        <i class="fas fa-times"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <i class="fas fa-plus"></i><button type="button" class="btn btn-link" (click)="addGuarantor()">Add Guarantor</button>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-4">
                                    <p class="form-label-valign-middle">
                                        Scorecard
                                    </p>
                                </div>

                                <div class="col-8">
                                    <select class="form-select"
                                        formControlName="scorecard">
                                        <option
                                            *ngFor="let scorecard of scorecards | enumToArray; let i = index"
                                            [ngValue]="scorecard"
                                        >{{loanService.displayFriendlyName(scorecard)}}</option>
                                    </select>

                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="!isLoading && pages[currentPage] === 'compliance'">
                            <h4>Compliance</h4>


                            <div formGroupName="complianceInfo">

                                <div class="row">
                                    <div class="col-6">
                                        <p class="form-label-valign-middle">
                                            OFAC Sanctions List Search
                                        </p>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="ofacSanctions"
                                            value="true" />
                                            Passed
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="ofacSanctions"
                                            value="false" />
                                            Failed
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <p class="form-label-valign-middle">
                                            PACER Case Locator for Bankruptcy History
                                        </p>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="pacerBankruptcies"
                                            value="true" />
                                        Passed
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="pacerBankruptcies"
                                            value="false" />
                                            Failed
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-6">
                                        <p class="form-label-valign-middle">State Court Claims</p>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="stateCourtClaims"
                                            value="true" />
                                            Passed
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="stateCourtClaims"
                                            value="false" />
                                            Failed
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <p class="form-label-valign-middle">Divisions of corporation report</p>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="divisionsOfCorpReport"
                                            value="true" />
                                            Passed
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <input
                                            type="radio"
                                            formControlName="divisionsOfCorpReport"
                                            value="false" />
                                            Failed
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </ng-container>
                        <ng-container *ngIf="!isLoading && pages[currentPage] === 'documents'">
                            <h4>Financial Statements</h4>

                            <div class="row">
                                <div class="col-3">
                                    <p class="form-label-valign-middle">
                                        Financial Statements
                                    </p>
                                </div>
                                <div class="col-9">
                                    Select financial statements associated with this loan
                                </div>
                                <div class="col-9 offset-3">
                                    <input (change)="toggleAllStatementFiles()" type="checkbox"> Select All
                                </div>
                                <div class="col-8 offset-4">
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-12"
                                                *ngFor="let statementFile of statementFiles; let i = index"
                                            >
                                                <label
                                                    class="document-label"
                                                    formArrayName="statementFiles"
                                                >
                                                    <input
                                                    type="checkbox"
                                                    [checked]="doesDocumentExistInLoan(statementFile)"
                                                    [formControlName]="i" />
                                                    {{statementFile.originalDocumentName}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <p class="form-label-valign-middle">
                                        Other Documents
                                    </p>
                                </div>

                                <div class="col-9">
                                    Select financial statements associated with this loan
                                </div>
                                <div class="col-9 offset-3">
                                    <input (change)="toggleAllOtherFiles()" type="checkbox"> Select All
                                </div>
                                <div class="col-8 offset-4">
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-12"
                                                *ngFor="let otherFile of otherFiles; let i = index"
                                            >
                                                <label
                                                    class="document-label"
                                                    formArrayName="otherFiles"
                                                >
                                                    <input
                                                    type="checkbox"
                                                    [checked]="doesDocumentExistInLoan(otherFile)"
                                                    [formControlName]="i"
                                                    />
                                                    {{otherFile.originalDocumentName}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngIf="!isEmptyObject(formErrors)" class="error-text">
                            <ng-container *ngFor="let error of objectEntries(formErrors)">
                                <ng-container *ngIf="error[0] === 'borrower_info'">
                                    <p *ngFor="let borrowerInfoError of objectEntries(error[1])">
                                        {{borrowerInfoError[0]}} {{borrowerInfoError[1]}}
                                    </p>
                                </ng-container>

                                <ng-container *ngIf="error[0] !== 'borrower_info'">
                                    <p *ngFor="let errorMessage of error[1]">
                                        {{error[0]}} {{errorMessage}}
                                    </p>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <a
                        *ngIf="currentPage != 0"
                        (click)="goToPreviousTab()">
                            <i class="fa fa-arrow-left"></i>
                            Previous
                        </a>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-secondary"
                        type="reset"
                        (click)="popup.close();"
                        >
                            <span class="size">Cancel</span>
                        </button>

                        <button class="btn btn-primary some-left-margin"
                        [disabled]="submitted || isFormInvalid()"
                        type="submit"
                        >
                            <span class="size">Submit</span>
                        </button>
                    </div>
                    <div class="offset-1 col-2">
                        <a
                        *ngIf="currentPage < (pages.length -1)"
                        (click)="goToNextTab()">
                            Next
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>


            </form>
        </div>
    </div>
</div>

