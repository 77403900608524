import { Deserializable } from '../../../../../models/deserializable';
import { Table } from './table';
import { Row, Column } from '../../../spreading/models/models';

export class Statement implements Deserializable {
  id: number;
  uuid: string;
  companyId: number;
  fiscalYearEnd: string;
  sourceTables: Array<Table> = [];
  statementType: string;
  rows: Array<Row> = [];
  columns: Array<Column> = [];
  documentFileId: number;
  complete = false;
  spreadingTemplateId: number;
  detectedLanguage: any;
  uniqueDasName?: string;

  deserialize(data): this {
    Object.assign(this, data);
    this.sourceTables = this.sourceTables.map(t => new Table().deserialize(t));

    this.rows.forEach(r => {
      r.cells.forEach(c => {
        // Make it easier to identify that something is not a number while keeping the same numeric type
        if (c.value === null) {
          c.value = NaN;
        }
      });
    });

    return this;
  }
}
