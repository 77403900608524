<form [formGroup]="form" (submit)="addItemToDASSection()">
<div class="modal__content">
    <div class="confirmation-popup-header">
        <i class="fas fa-sitemap"></i>
        <span> {{ title }}</span>
    </div>
    <div class="confirmation-popup-text">
        <label>Select the Dynamic Adjustment Statement Section where the item should be inserted</label>
        <select
            formControlName="selectedTargetSectionLineItemId"
            class="form-select">
            <option *ngFor="let section of dasSectionOptions" [ngValue]="section.standardLineItemId">
                {{ section.label }}
            </option>
        </select>

        <label>Select the item type</label>
        <select
            formControlName="selectedItemType"
            class="form-select">
            <option [ngValue]="'AsReported'">As Reported Item</option>
            <!--   We only support created AsReported DAS items from calculated items for now     -->
            <option [disabled]="item.className=='CalculatedItem'" [ngValue]="'AdjustableRawAddBack'">Raw Add-Back Item (ENABLE "Include" and "Weight" Controls)</option>
            <option [disabled]="item.className=='CalculatedItem'" [ngValue]="'NonAdjustableRawAddBack'">Raw Add-Back Item (DISABLE "Include" and "Weight" Controls)</option>
        </select>

        <label>Value Format</label>
        <select
            formControlName="selectedTextFormat"
            class="form-select">
            <option *ngFor="let option of textFormatOptions" [ngValue]="option">{{ option }}</option>
        </select>

        <label>Label</label>
        <input type="text" class="form-control" formControlName="label">
    </div>

</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Add</button>
</div>
</form>
