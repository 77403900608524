import { Injectable } from '@angular/core';
import { NumberFormattingService } from './number-formatting.service';
@Injectable({
  providedIn: 'root'
})
export class FinancialsService {

  constructor(
    private _numberFormattingService: NumberFormattingService,
  ) { }

  formatValue(value: number, format: string = '', options = {}): string {
    if (value === null || value === undefined) {
      return '';
    }


    let showDollarSign:any = false; // TODO remove temp to get things working as expected
    if ('showDollarSign' in options) {
      showDollarSign = options['showDollarSign'];
    }
    let numDecimals;
    if ('numDecimals' in options) {
      numDecimals = options['numDecimals'];
    } else {
      numDecimals = undefined;
    }
    let currency;
    if ('currency' in options) {
      currency = options['currency'];
    } else {
      currency = undefined;
    }

    return this._numberFormattingService.formatNumber(value, format, numDecimals, { showDollarSign: showDollarSign, isRoundedToThousands: false, currency: currency });
  }

}




