<div class="analysis-container">
    <div class="row">
        <div class="col-sm-4">
            <h2>{{name}}</h2>
            <ng-container *ngIf="embeddedMode">
              <p class="company-name-label">Analysis for: {{companyName}}</p>
            </ng-container>
        </div>
        <div class="action-button-container col-sm-4">
            <button *ngIf="!embeddedMode" (click)="downloadExcel()" class="btn btn-empty-light bottom-margin" title="Download as Excel"><i class="fas fa-file-excel"></i> Download Excel </button>
            <button *ngIf="!embeddedMode" [disabled]="!company" (click)="openPdfDownloadModal()" class="btn btn-empty-light bottom-margin" title="Download as PDF"><i class="far fa-file-pdf"></i> Download PDF </button>
            <button *ngIf="getCompanyId()" (click)="openSingleEntityAnalysisChangeHistory()" class="btn btn-empty-light bottom-margin change-log-button" title="Change Log"><i class="fa fa-book"></i> Change Log </button>
        </div>
        <div class="col-sm-2 offset-sm-2">
            <button (click)="saveChanges()" class="btn btn-primary bottom-margin save-button" [disabled]="isSaveButtonDisabled" *ngIf="showSaveButton()">Save Changes</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="row top-margin">
                <div class="col-lg-4">
                    <div>
                        <app-proposed-debt-tile
                            *ngFor="let proposedDebt of proposedDebts; let i = index"
                            [company]="company"
                            [proposedDebtId]="i"
                            [(name)]="proposedDebt.name"
                            [(principal)]="proposedDebt.principal"
                            [(interestRate)]="proposedDebt.interestRate"
                            [(termMonths)]="proposedDebt.termMonths"
                            [(amortizationMethod)]="proposedDebt.amortizationMethod"
                            (onRemoveProposedDebt)="removeProposedDebt($event)"
                            (numericalValuesChanged)="recalculate()"
                        >
                        </app-proposed-debt-tile>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <a href="javascript:void(0)" (click)="addLoan()">Add Proposed Loan</a>
                        </div>
                    </div>
                    <div>
                        <app-loan-commentary-tile
                            [commentary]="commentary"
                            (commentaryChange)="changeCommentary($event)"
                        >
                        </app-loan-commentary-tile>
                    </div>
                </div>
                <div class="col-lg-8 table-container">
                    <app-debt-service-coverage-table
                        [templateItems]="templateItems"
                        [columns]="columns"
                        [scopes]="scopes"
                        [proposedDebts]="proposedDebts"
                        [dataOverrides]="dataOverrides"
                        [allowCellEditing]="allowCellEditing"
                        [refToOverrideIdx]="refToOverrideIdx"
                        [templateHasSections]="templateHasSections"
                        [projectionScenarioTypeLabelOverride]="projectionScenarioTypeLabelOverride"
                        (doneEditing)="manuallyEditedValue($event)"
                    >
                    </app-debt-service-coverage-table>
                </div>
            </div>
        </div>
    </div>
</div>
