import { CommonModule } from '@angular/common';  
import { NgModule } from '@angular/core';
import { TableEmptyStateComponent } from './table-empty-state.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TableEmptyStateComponent,
  ],
  exports: [
    TableEmptyStateComponent,
  ]
})

export class TableEmptyStateModule {
}
