<div class="btn-group dropdown" role="toolbar">
    <button class="btn dropdown-toggle" [ngClass]="buttonClass" type="button" data-bs-toggle="dropdown" id="popover-menu-action-btn">
        <span>{{buttonText}}</span>
        <span class="caret"></span>
    </button>

    <ul class="dropdown-menu shadow"
        [ngClass]="{'dropdown-menu-end': dropdownDirection==='right', 'dropdown-menu-start': dropdownDirection==='left'}">

        <ng-container *ngFor="let item of menuItems">
            <ng-container *ngIf="item?.divider; else fullMenuItem">
                <li role="separator" class="dropdown-divider"></li>
            </ng-container>
            <ng-template #fullMenuItem>
                <li class="popover-menu-item" *ngIf="item.visible" [ngClass]="{'disabled': item.disabled}"
                    (click)="item?.action()">
                    <i *ngIf="item?.faIconClass" class="fa" [ngClass]="item.faIconClass"></i>
                    <div class="text-content-container">
                        <div class="item-title">{{item.title}}</div>
                        <div class="item-subtitle">{{item.subtitle}}</div>
                    </div>
                </li>
            </ng-template>
        </ng-container>
    </ul>
</div>
