import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import {AlertService} from '@services/alert.service';

@Component({
  selector: 'app-import-export-template',
  templateUrl: './import-export-template.component.html',
  styleUrls: ['./import-export-template.component.scss']
})
export class ImportExportTemplateComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() exportJson: string;
  @Input() templateId = null;

  @Output() onImport = new EventEmitter<any>();

  importJson = '';

  constructor(private alertService: AlertService) {

  }

  ngOnInit() {
  }

  copyExportJsonToClipboard() {
    navigator.clipboard.writeText(this.exportJson).then(() => {
      this.alertService.success('Copied to clipboard')
    }, (error) => {
      console.error('Async: Could not copy text: ', error);
    });
  }

  importTemplate() {
    this.onImport.emit(JSON.parse(this.importJson));
  }

}
