import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-document-container',
  templateUrl: './review-document-container.component.html',
  styleUrls: ['./review-document-container.component.css']
})
export class ReviewDocumentContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
