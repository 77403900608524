import {Component, OnInit} from "@angular/core";
import {SharedDataService} from "@services/shared-data.service";
import {UserService} from "@services/user.service";


@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  noPermissions: boolean;
  welcomeText = 'Welcome To Numerated Spreading'
  noPermissionsWarningText = 'You do not have permissions to access to any features, please contact your administrator.'

  constructor(
    private _sharedDataService: SharedDataService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this._sharedDataService.pageHeaderTitle$.next('');
    this.noPermissions = !this._userService.user.user_permissions.length
  }

}
