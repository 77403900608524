import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { AlertService } from '@services/alert.service';
import { EnvironmentService } from '@services/environment.service';
import { BankSettingsService } from '@services/bank-settings.service';
import { CommonFunctions } from '@utils/common-functions';
import {Location} from '@angular/common';

@Component({
  selector: 'app-accounting-integration-invite',
  templateUrl: './accounting-integration-invite.component.html',
  styleUrls: ['./accounting-integration-invite.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class AccountingIntegrationInviteComponent implements OnInit {

  companyId: number;
  bankId: string;
  customBankImg: string;
  supportEmail: string;
  primaryColor: string;
  submitting = false;
  submittingProgress: number;
  complete = false;
  subsArr$: Subscription[] = [];
  loading = true;
  title: string;
  text: string;

  vendors: Array<string> = ['qbo', 'xero'];

  constructor(
    private _alert: AlertService,
    private _environmentService: EnvironmentService,
    private _bankSettingsService: BankSettingsService) {
  }

  ngOnInit(): void {


    this.companyId = 197;
    this.bankId = 'finc2336';

    const environment = this._environmentService.getEnvConfig();
    if (!environment) {
      this.subsArr$.push(
        this._environmentService.loadEnvConfig(false).subscribe(() => {
          this._initUI();
        }, (err) => {
          this._alert.error('Sorry, an error occurred! Please try again.');
        })
      );
    }
  }

  _initUI() {
    this.loading = false;

    this.subsArr$.push(this._bankSettingsService.getPortalSettings().subscribe((data) => {
      this.setCustomSettings(data);
    }, (err) => {
      this._alert.error('Sorry, an error occurred! Please try again.');
    }));
  }

  setCustomPageTitle(title): void {
    this.title = this.bankId.charAt(0).toUpperCase() + this.bankId.slice(1);
    const appTitleElement = document.getElementById('appTitle');

    if (!appTitleElement) {
      return;
    }

    if (title) {
      appTitleElement.innerText = title;
    } else {
      appTitleElement.innerText = this.title + ' Portal';
    }
  }

  setCustomSettings(settings) {
    this.setCustomPageTitle(settings['_portalSettings']['pageTitle']);
    this.setCustomImg(settings['_bankLogos']['large']);
    this.setCustomText(settings['_portalSettings']['text']);
    this.setCustomColor(settings['_portalSettings']['color']);
    this.setCustomSupportEmail(settings['_portalSettings']['supportEmail']);
    this.setCustomFavIcon(settings['_bankLogos']['ico']);
  }

  setCustomSupportEmail(email) {
    if (email) {
      this.supportEmail = email;
    } else {
      this.supportEmail = 'support@support';
    }
  }

  setCustomColor(color) {
    if (color) {
      this.primaryColor = color;
    } else {
      this.primaryColor = '#3A5D8D';
    }
  }

  setCustomText(text) {
    if (text) {
      this.text = text;
    } else {
      this.text = 'Default text';
    }
  }

  setCustomImg(img) {
    if (img) {
      this.customBankImg = img;
    } else {
      this.customBankImg = '/assets/images/Numerated-2023-logo.svg';
    }
  }

  setCustomFavIcon(favIconUrl): void {
    if (favIconUrl) {
      const appIcon = document.getElementById('appIcon');
      if (appIcon) {
        appIcon.setAttribute('href', favIconUrl);
      }
    }
  }

  linkVendor(vendor) {
    const env = CommonFunctions.getEnvFromHost();
    (window as any).location.href = `${env.serverUrl}/api/oauth2/${vendor}/authorize`;
  }


}
