import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  PdfToolbarService
} from "@components/shared/document-viewer/pdf-toolbar/pdf-toolbar.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-image-highlighter',
  templateUrl: './image-highlighter.component.html',
  styleUrls: ['./image-highlighter.component.scss']
})
export class ImageHighlighterComponent implements OnInit, OnChanges {
  @Input() pageImgUrl = null;
  @Input() cropper = null;
  @Input() imagePlaceholderHeight: number = 1000;
  @ViewChild('highlightedImgId') imageElement: ElementRef;
  @ViewChild('cropper') cropperElement: ElementRef;
  @Output() rerenderEvent = new EventEmitter<void>();
  height = 0;
  width = 0;
  xcoord = 0;
  ycoord = 0;
  imageLoaded: boolean = false;
  imageFailedToLoad: boolean = false;
  imageHeight: number;
  imageWidth: number;
  actualImageAspectRatio: number;
  imageStyles: any;

  constructor(
    private pdfToolbarService: PdfToolbarService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.loadImage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('cropper')) {
      if (this.imageLoaded) {
        this.resetCropper();
      }
    }
  }

  loadImage() {
    const image = new Image();
    image.onload = () => {
      this.actualImageAspectRatio = image.height / image.width;
      this.setImageStyles();
      this.imageLoaded = true;
      this.rerenderEvent.emit();
      if (this.cropper) {
        setTimeout(() => {
          this.setImageDimensions();
          this.resetCropper();
          this.rerenderEvent.emit();
        });
      }
    }
    image.onerror = () => {
      this.imageFailedToLoad = true;
    }
    image.src = this.sanitizer.sanitize(SecurityContext.URL, this.pageImgUrl);
  }

  scrollCropperIntoView(){
    if (this.cropperElement) {
      setTimeout(() => {
        this.cropperElement.nativeElement.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'})
      })
    }
  }

  setImageStyles() {
    this.imageStyles = {
      'background-image': this.sanitizer.sanitize(SecurityContext.STYLE, `url('${this.pageImgUrl}')`),
      'padding-top.%': this.actualImageAspectRatio * 100
    }
  }

  setImageDimensions() {
    // set image dimensions once to avoid offset after zoom
    const imageRect = this.imageElement.nativeElement.getBoundingClientRect();
    this.imageWidth = imageRect.width;
    this.imageHeight = imageRect.height;
    if (this.pdfToolbarService.imageScale !== 1) {
      // adjust image dimensions to account for zoom scale
      this.imageWidth = this.imageWidth / this.pdfToolbarService.imageScale;
      this.imageHeight = this.imageHeight / this.pdfToolbarService.imageScale;
    }

  }

  resetCropper() {
    if (!this.cropper || !this.imageElement) {
      return
    }
    const widthRatio = this.imageWidth / this.cropper.width;
    const heightRatio = this.imageHeight / this.cropper.height;

    // if width or height is 0, it is bc we intentionally do not want to show a box so dont add padding
    this.width = this.cropper.width !== 0 ? ((this.cropper.x2 - this.cropper.x1) * widthRatio) + 8 : 0;
    this.height = this.cropper.height !== 0 ? ((this.cropper.y2 - this.cropper.y1) * heightRatio) + 8 : 0;
    this.xcoord = (this.cropper.x1 * widthRatio) - 4;
    this.ycoord = (this.cropper.y1 * heightRatio) - 4;
    this.scrollCropperIntoView();
  }
}
