import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class PortalAuthMiddleware implements CanActivate {

  constructor(private _router: Router,
              private _authService: AuthenticationService,
              private _appConfig: AppConfigService
              ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const key = route.params.portalKey;
    const bankId = route.params.bankId
    let jwt = null;

    if (key) {
      // cant exchange key until envFromHostname is set
      this._appConfig.envFromHostname.subscribe((envFromHost) => {
        this._authService.exchangeKeyForJWT(key, bankId).subscribe(data => {
          jwt = data;
          if (jwt) {
            this._router.navigate(['/portal/upload'], {queryParams: {jwt: jwt}});
          }
        });
       });
    } else {
      if (this._authService.isAuthenticatedWithSignedUrl()) {
        return true;
      } else {
        return this.accessDenied();
      }
    }
  }

  accessDenied(): boolean {
    this._router.navigate(['/denied']);
    return false;
  }
}
