import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import {AlertService} from '@services/alert.service';
import {FileItem, FileUploader} from "ng2-file-upload";
import {SpreadingTemplateService} from "@services/spreading-template.service";
import {ConfirmationPopupComponent} from "@components/shared/popups/confirmation/confirmation-popup.component";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";

@Component({
  selector: 'excel-export-reference-upload',
  templateUrl: './excel-export-reference-upload.component.html',
  styleUrls: ['./excel-export-reference-upload.component.scss']
})
export class ExcelExportReferenceUploadComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() existingExcelExportReferenceS3Key: string = '';
  @Input() spreadingTemplateUuid: string = ''
  @Input() spreadingTemplateId: number
  @ViewChild('uploadInput', { static: true }) uploadInputHtml: ElementRef;

  @Output() onImport = new EventEmitter<any>();

  fileQueuedForUpload: boolean = false
  invalidFileType: boolean = false
  uploader: FileUploader;
  existingFileName: string = ''
  fileItemForUpload: FileItem = null


  constructor(
    private alertService: AlertService,
    private spreadingTemplateService: SpreadingTemplateService,
    private popupService: NgxPopupService) {

  }

  ngOnInit() {
    this.existingFileName = this.parseFileNameFromS3Key(this.existingExcelExportReferenceS3Key)
    this.uploader = new FileUploader({
        autoUpload: false,
        method: 'PUT',
        queueLimit: 1,
        disableMultipart: true,
        isHTML5: true,
      });


    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      fileItem.withCredentials = false;
      console.log(fileItem)
      const fileChosen = document.getElementById('file-chosen')
      fileChosen.textContent = fileItem.file.name
      this.fileItemForUpload = fileItem
      this.invalidFileType = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12'
      ].includes(fileItem.file.type)
      this.fileQueuedForUpload = !this.invalidFileType
      if (this.invalidFileType) {
        this.uploader.clearQueue()
      }
    }
  }

  openFilePicker() {
    // When user cancels and chooses same file, the popup is not created, so fix by setting value to null
    // https://html.spec.whatwg.org/multipage/input.html#dom-input-value-filename
    this.uploadInputHtml.nativeElement.value = null;
    this.uploadInputHtml.nativeElement.click();
  }

  uploadDocument() {
    console.log(`${this.fileItemForUpload.file.name} ${this.fileItemForUpload.file.type} ${this.spreadingTemplateUuid}`)

    this.spreadingTemplateService.generatePreSignedUrlForExcelExportReferenceUpload(
      this.fileItemForUpload.file.name, this.fileItemForUpload.file.type, this.spreadingTemplateUuid).subscribe((data) => {
        this.uploader.setOptions({ url: data.s3UploadUrl });

        this.uploader.onErrorItem = (item: FileItem, response: string, status: number) => {
          this.uploader.clearQueue();
          this.alertService.error("An error occurred while uploading reference sheet")
        }

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          this.uploader.clearQueue()
          if (this.existingFileName) {
            this.spreadingTemplateService.deleteExcelExportReference(this.spreadingTemplateId).subscribe(result => {
              this.alertService.success(`Successfully removed ${this.existingFileName} as reference`)
              this.performTemplateUpdateForReferenceSheetInfo(data.s3Key)
            }, error => {
              this.alertService.error(error.message)
            })
          } else {
            this.performTemplateUpdateForReferenceSheetInfo(data.s3Key)
          }
        }
        this.uploader.uploadAll()
    })
  }

  performTemplateUpdateForReferenceSheetInfo(s3Key) {
    this.spreadingTemplateService.updateTemplate(this.spreadingTemplateId, {'referenceSheetKey': s3Key}).subscribe((response) => {
      //got to throw up an upload success toast and update the file name in the modal
      this.existingExcelExportReferenceS3Key = response[0].referenceSheetKey
      this.existingFileName = this.parseFileNameFromS3Key(this.existingExcelExportReferenceS3Key)
      this.alertService.success(`${this.existingFileName} successfully uploaded.`)
      this.fileQueuedForUpload = false
    })
  }

  deleteExistingReferenceSheetUpload() {
    this.popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'REMOVE REFERENCE SHEET',
        text: `Are you sure you want to remove ${this.existingFileName} from the template?`,
        confirmButtonText: 'Yes'
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.spreadingTemplateService.deleteExcelExportReference(this.spreadingTemplateId).subscribe(result => {
              this.alertService.success(`Successfully removed ${this.existingFileName} as reference`)
              this.existingFileName = ''
              this.existingExcelExportReferenceS3Key = ''
            }, error => {
              this.alertService.error(error.message)
            })
          }
        }
      },
    });
  }

  downloadReferenceSheet() {
    this.spreadingTemplateService.retrievePreSignedUrlForExcelExportReferenceDownload(this.existingFileName, this.existingExcelExportReferenceS3Key).subscribe(data => {
      console.log(data.referenceSheetUrl)
      const url = data.referenceSheetUrl;
      const link = document.createElement('a');
      link.href = url;
      link.download = this.existingFileName;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    },
    error => {
      this.alertService.error(error.message);
    })
  }

  close() {
    return this.popup.close(true, {'key':this.existingExcelExportReferenceS3Key})
  }

  parseFileNameFromS3Key(key) {
    return key.split('/').pop()
  }
}
