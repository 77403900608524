import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DataViewColumn} from '../../../../../../../models/dataview';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '../../../../../../../decorators/auto-unsubscribe';
import {AnalysisService} from '../../../../../../../services/analysis.service';
import {ProposedDebt} from '../../../../../../../models/proposed-debt';
import {TemplateItem} from '../../../../../../../models/template-item';
import {GcfEntity} from 'app/models/gcf-entity';
import {TradelineEntity} from 'app/models/tradeline-entity';
import {OpenTradeline} from 'app/models/open-tradeline';
import {CommonFunctions} from '@utils/common-functions';

@Component({
  selector: 'app-global-cashflow-table',
  templateUrl: './global-cashflow-table.component.html',
  styleUrls: ['./global-cashflow-table.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class GlobalCashflowTableComponent implements OnInit {
  subsArr$: Subscription[] = [];

  @Input() templateItems: Array<TemplateItem> = [];
  @Input() columns: Array<DataViewColumn> = [];
  @Input() primaryEntity: GcfEntity = null;
  @Input() linkedEntities: Array<GcfEntity> = [];
  @Input() headersMetadata: Array<any> = []
  @Input() primaryEntityScopes: Array<{ id: number }> = [];
  @Input() linkedEntityScopes: Array<Array<{ id: number }>> = [];
  @Input() allEntityScopes: Array<{ id: number }> = [];
  @Input() proposedDebts: Array<ProposedDebt> = [];
  @Input() entitySeparatedOpenTradelines: Array<TradelineEntity> = [];
  @Input() dataOverrides: any;
  @Input() tradelineDataOverrides: any;

  @Input() allowCellEditing = true;
  @Input() templateItemAdditionalAttributes = {};
  @Input() entitySubsectionIdToToTemplateSpecificIdMappings = {};
  @Input() templateSectionIdToBaseEntitySubsectionIdMappings = {};
  @Input() sectionToEntitySubsectionRefMap = {}

  @Output() doneEditing = new EventEmitter<any>();

  selectedRow = -1;
  selectedColumn = -1;
  editingRow = -1;
  editingColumn = -1;
  editingChildTemplateItemIdx = -1;
  isEditingPrimaryEntity = false;
  relatedEntityBeingEdited = -1;
  tradelineBeingEdited = - 1;

  proposedDebtEditable = false;
  specialRefsToNotShowForAllEntities: Array<string> = [
    'REF_GCF_TOTAL_REQUIRED_DEBT_PAYMENTS',
    'REF_GCF_TOTAL_AVAILABLE_FOR_DEBT_SERVICE',
  ];
  specialRefsToNotShowForLinkedEntities: Array<string> = [];
  specialRefsToNotShowForPrimaryEntity: Array<string> = [];
  orderedEntitySeparatedOpenTradelines: Array<Array<OpenTradeline>> = [];

  isPrimarySelected = false;
  linkedEntitySelected = -1;
  selectedChildTemplateItemIdx = -1;
  tradelineSelected = -1;

  entityDrawerState = [];
  tradelineDrawerState = [];

  constructor(
    public analysisService: AnalysisService, ) {
  }

  ngOnInit(): void {
    this.specialRefsToNotShowForLinkedEntities = [...this.specialRefsToNotShowForAllEntities];
    this.specialRefsToNotShowForLinkedEntities.push('REF_DSCR_USER_ENTERED_PROP_DEBT');
    this.specialRefsToNotShowForPrimaryEntity = [...this.specialRefsToNotShowForAllEntities];
    this.specialRefsToNotShowForPrimaryEntity.push('REF_GCF_OPEN_TRADELINES');
    this.templateItems.forEach( (element, idx) => {
      this.entityDrawerState.push([]);
      this.entityDrawerState[idx].push(true);
      this.linkedEntities.forEach( (linkedEntity, linkedEntityIdx) => {
        this.entityDrawerState[idx].push(false);
      })
    });
    this.orderTradelinesByLinkedEntityIndex();
    this.tradelineDrawerState = new Array(this.linkedEntities.length);
    this.tradelineDrawerState.fill(false);
  }

  toggleEntityDisplay(parentTemplateIdx, entityIdx): void {
    this.entityDrawerState[parentTemplateIdx][entityIdx] = !this.entityDrawerState[parentTemplateIdx][entityIdx];
  }

  toggleTradelineDisplay(linkedEntityIdx): void {
    this.tradelineDrawerState[linkedEntityIdx] = !this.tradelineDrawerState[linkedEntityIdx];
  }

  // Stop the highlighting when we click outside the element
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.selectedRow = -1;
    this.selectedColumn = -1;
  }

  selectedCell(colIdx: number, rowIdx: number, childIdx: number, isPrimaryEntity = false, linkedEntityId = -1, tradelineIdx = -1): void {
    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
    this.selectedChildTemplateItemIdx = childIdx;
    this.isPrimarySelected = isPrimaryEntity;
    this.linkedEntitySelected = linkedEntityId;
    this.tradelineSelected = tradelineIdx;
  }

  editCell(colIdx: number, rowIdx: number, childEntityIdx: number, isPrimaryEntity = false, linkedEntityId = -1, tradelineIdx = -1): void {
    this.editingRow = rowIdx;
    this.editingColumn = colIdx;
    this.editingChildTemplateItemIdx = childEntityIdx;
    this.isEditingPrimaryEntity = isPrimaryEntity;
    this.relatedEntityBeingEdited = linkedEntityId;
    this.tradelineBeingEdited = tradelineIdx;
  }

  doneEditingCell(newValueObject: any): void {
    if (newValueObject.isChanged || newValueObject.newValueMatchesOriginalValue) {
      this.doneEditing.emit(newValueObject);
    }
    this.editingRow = -1;
    this.editingColumn = -1;
    this.editingChildTemplateItemIdx = -1;
    this.isEditingPrimaryEntity = false;
    this.relatedEntityBeingEdited = -1;
    this.tradelineBeingEdited = -1;
  }

  isEditable(templateItem: TemplateItem): boolean {
    if (!this.allowCellEditing) {
      return false;
    }

    // below returns true if not a "can never edit this field" value
    // custom non editable values for DSCR templates
    return templateItem.ref !== 'REF_NET_CASH_FLOW'
    && templateItem.ref !== 'REF_DSCR_COVER_PRIOR'
    && templateItem.ref !== 'REF_DSCR_TOTAL_DEBT_SERVICE'
    && templateItem.ref !== 'REF_DSCR_CASH_SURPLUS_DEFICIT'
    && templateItem.ref !== 'REF_DSCR_ACT_DEBT_SERVICE_COV';
  }

  displayDataOverrideValue(isPrimaryEntity: boolean, linkedEntityIdx: number, ref: string, colIdx: number): number {
    let keyFormattedRef = ref;
    keyFormattedRef = CommonFunctions.snakeToCamel(ref);

    if (isPrimaryEntity) {
      if (this.dataOverrides.primaryEntity.overrides[colIdx].hasOwnProperty(keyFormattedRef)) {
        return this.dataOverrides.primaryEntity.overrides[colIdx][keyFormattedRef];
      }
    }

    if (linkedEntityIdx !== -1) {
      if (this.dataOverrides.linkedEntities[linkedEntityIdx].overrides[colIdx].hasOwnProperty(keyFormattedRef)) {
        return this.dataOverrides.linkedEntities[linkedEntityIdx].overrides[colIdx][keyFormattedRef];
      }
    }

    return null;
  }

  displayTradelineDataOverrideValue(linkedEntityIdx: number, tradelineIdx: number, colIdx: number) {
    if (this.tradelineDataOverrides.linkedEntities !== undefined) {
      return this.tradelineDataOverrides.linkedEntities[linkedEntityIdx].overrides[colIdx][tradelineIdx];
    }
    return null;
  }

  equationValueForProposedDebt(proposedDebt: ProposedDebt, monthsInInterval: number): number {
    return this.analysisService.calculateProposedDebtIntervalPayment(proposedDebt, monthsInInterval);
  }

  equationValueForTradeline(tradeline: OpenTradeline, colIdx: number = 0) {
    if (tradeline.revolvingOrInstallment === 'installment') {
      const monthsInInterval = this.primaryEntityScopes[colIdx]['CONST_MONTHS_IN_INTERVAL'];
      return tradeline.tradelineInstallmentMonthlyAmount * monthsInInterval;
    }
    return tradeline.tradelineRevolvingAmount;
  }

  orderTradelinesByLinkedEntityIndex() {
    this.linkedEntities.forEach( (linkedEntity, idx) => {
      let entityIndexFound = false;
      this.entitySeparatedOpenTradelines.forEach(tradelineEntity => {
        if (linkedEntity['entityUuid'] === tradelineEntity['entityUuid']) {
          this.orderedEntitySeparatedOpenTradelines.push(tradelineEntity.openTradelines);
          entityIndexFound = true;
        }
      })
      if (!entityIndexFound) {
        this.orderedEntitySeparatedOpenTradelines.push([]);
      }
    })
  }

  // if all of the tradelines for a particular entity are null, then the sum should be null too and we should display it as a dash, not a zero
  isTradelineSumANumber(tradelines: Array<OpenTradeline>) {
    for (const tradeline of tradelines) {
      if (typeof this.equationValueForTradeline(tradeline) === 'number') {
        return true;
      }
    }
    return false;
  }

  getOriginalValueBeforeOverrides(ref: string, colIndex: number, linkedEntityIdx: number = -1) {
    let cells = [];
    if (linkedEntityIdx === -1) {
      cells = this.columns['primaryEntity']['columns'][colIndex]['cells'];
    } else {
      cells = this.columns['linkedEntities'][linkedEntityIdx]['columns'][colIndex]['cells'];
    }
    for (const cell of cells) {
      if (cell.ref === ref) {
        return cell['calculatedValue']['value'];
      }
    }
  }

  isSectionForEntitySpecificItems(parentTemplateItem) {
    if (this.sectionToEntitySubsectionRefMap[parentTemplateItem.ref]) {
      return true
    } else {
      let countOfChildrenNotInAdditionalAttributes = 0
      parentTemplateItem.children.forEach((childItem) => {
        if (!this.templateItemAdditionalAttributes[childItem.standardLineItemId]) {
          countOfChildrenNotInAdditionalAttributes += 1
        }
      })
      return countOfChildrenNotInAdditionalAttributes === parentTemplateItem.children.length;
    }
  }

  isGenericCalculation(templateItem): boolean {
    return templateItem.className === 'GenericCalculatedItem';
  }

  shouldShowRow(entity, childElement, parentTemplateItem) {
    if (childElement.spreadingTemplateId === undefined) {
      return true;
    }
    if (this.templateItemAdditionalAttributes[childElement.standardLineItemId]?.isEntitySubsection) {
      return true;
    }
    const baseEntitySubectionId = this.templateSectionIdToBaseEntitySubsectionIdMappings[parentTemplateItem?.standardLineItemId];
    const entitySubsectionIdToToTemplateSpecificIdMapping = this.entitySubsectionIdToToTemplateSpecificIdMappings[baseEntitySubectionId];
    if (childElement?.spreadingTemplateId === -1 && (!entitySubsectionIdToToTemplateSpecificIdMapping || !entitySubsectionIdToToTemplateSpecificIdMapping.includes(entity.entitySpreadingTemplateId))) {
      return true;
    }
    if (childElement?.spreadingTemplateId === entity.entitySpreadingTemplateId && entitySubsectionIdToToTemplateSpecificIdMapping && entitySubsectionIdToToTemplateSpecificIdMapping.includes(entity.entitySpreadingTemplateId)) {
      return true;
    }
    return false;
  }

  getEquationsForFormulaEvaluation(item, entity) {
    if (item.templateSpecificEquations && item.templateSpecificEquations.hasOwnProperty(entity.entitySpreadingTemplateId)) {
      return item.templateSpecificEquations[entity.entitySpreadingTemplateId];
    } else {
      return !!item.equationOverride ? item.equationOverride : item.originalEquation;
    }
  }
}

