<div>
    <div class="modal_header">
        <i class="fa fa-filter header-icon"></i>
        <p class="header-text">{{ HEADER_TEXT }}</p>
        <p class="header-description">Select which statements you would like to see in the financial summary. The
            available statements will update as you select different filters.</p>
    </div>

    <div class="modal__content">

        <div class="reporting-interval-and-year">
            <div class="reporting-interval-dropdown">
                <p class="dropdown-label">Reporting Intervals</p>
                <app-popover-menu-multi-select
                    [multiSelectOptions]="multiSelectOptionsForReportingInterval"
                    [buttonText]="multiSelectButtonTextForReportingInterval"
                    (selectOrDeselectAll)="handleSelectOrDeselectAll(multiSelectOptionsForReportingInterval)"
                ></app-popover-menu-multi-select>
            </div>

            <div class="year-dropdown">
                <p class="dropdown-label">Years</p>
                <app-popover-menu-multi-select
                    [multiSelectOptions]="multiSelectOptionsForYears"
                    [buttonText]="multiSelectButtonTextForYears"
                    (selectOrDeselectAll)="handleSelectOrDeselectAll(multiSelectOptionsForYears)"
                ></app-popover-menu-multi-select>
            </div>

            <div class="scenarios-dropdown">
                <p class="dropdown-label">Scenarios</p>
                <app-popover-menu-multi-select
                    [multiSelectOptions]="multiSelectOptionsForScenario"
                    [buttonText]="multiSelectButtonTextForScenarios"
                    (selectOrDeselectAll)="handleSelectOrDeselectAll(multiSelectOptionsForScenario)"
                ></app-popover-menu-multi-select>
            </div>
        </div>

        <div class="available-statements-dropdown-header">
            <p class="dropdown-label">Available Statements</p>
            <p class="numberOfAvailableStatementsSelected">{{ numberOfAvailableStatementsSelectedText }}</p>
        </div>
        <div class="available-statements-dropdown-inner-container">
            <ul (click)="$event.stopPropagation()">
                <li [class.disabled]="isAvailableStatementsEmpty" class="popover-menu-item">
                    <div class="checkbox-label-container">
                        <input class="multi-select-checkbox" type="checkbox"
                               id="{{'selectDeselectAllCheckbox'}}"
                               (click)="handleSelectOrDeselectAll(multiSelectOptionsForAvailableStatements)"
                               [checked]=isAnyPeriodChecked()>
                        <label class="item-title select-deselect-title"
                               for="{{'selectDeselectAllCheckbox'}}">Select / Deselect All</label>
                    </div>
                </li>
                <div class="no-statements-label" *ngIf="multiSelectOptionsForAvailableStatements.length == 0">
                    No statements found
                </div>
                <ng-container *ngFor="let option of multiSelectOptionsForAvailableStatements; let idx = index">
                    <li *ngIf="option.hide !== true && multiSelectOptionsForAvailableStatements.length > 0"
                        class="popover-menu-item">
                        <div class="checkbox-label-container">
                            <input class="multi-select-checkbox" type="checkbox"
                                   id="{{'multiSelectCheckbox' + idx}}" (click)="option?.action()"
                                   [checked]="option.checked">
                            <div class="flexbox-title-subtitle">
                                <div>
                                    <label class="item-title"
                                           for="{{'multiSelectCheckbox' + idx}}">{{ option.title }}</label>
                                </div>
                                <div class="subtitle-container">
                                    <label class="item-subtitle"
                                           for="{{'multiSelectCheckbox' + idx}}">{{ option.subtitle1 }}</label>
                                    <div class="separator-container">
                                        <svg class="circle-seperator" width="7" height="4" viewBox="0 0 4 4"
                                             xmlns="http://www.w3.org/2000/svg" style="display: inline-block;">
                                            <rect id="Rectangle-Copy-12" fill="#CCCCCC" fill-rule="nonzero" x="0" y="0"
                                                  width="4" height="4" rx="2"></rect>
                                        </svg>
                                    </div>
                                    <label class="item-subtitle"
                                           for="{{'multiSelectCheckbox' + idx}}">{{ option.subtitle2 }}</label>
                                    <div class="separator-container">
                                        <svg class="circle-seperator" width="7" height="4" viewBox="0 0 4 4"
                                             xmlns="http://www.w3.org/2000/svg" style="display: inline-block;">
                                            <rect id="Rectangle-Copy-12" fill="#CCCCCC" fill-rule="nonzero" x="0" y="0"
                                                  width="4" height="4" rx="2"></rect>
                                        </svg>
                                    </div>
                                    <label class="item-subtitle"
                                           for="{{'multiSelectCheckbox' + idx}}">{{ option.subtitle3 }}</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="close();">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="filterStatements();">Apply
        <div *ngIf="isFiltering" class="loader button-loader"></div>
    </button>
</div>
