import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { AnalysisService } from '../../../../../../services/analysis.service';
import { AlertService } from '../../../../../../services/alert.service';
import { AutoUnsubscribe } from '../../../../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ProposedDebt } from '../../../../../../models/proposed-debt';
import { TemplateItem } from '../../../../../../models/template-item';
import { DataViewColumn } from '../../../../../../models/dataview';
import { DataOverride } from '../../../../../../models/data-override';
import { DebtServiceNewValue } from '../../../../../../models/debt-service-new-value';
import { LaunchDarklyService } from '../../../../../../services/launchdarkly.service';
import { filter } from 'rxjs/operators';
import { Company } from '@models/company';
import { SharedDataService } from '@services/shared-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Loan } from '@services/fincura-ng-client/model/loan';
import { BankSettingsService } from '../../../../../../services/bank-settings.service';
import {LoanDetailsModalComponent} from "@components/shared/loan-details-modal/loan-details-modal.component";
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {PdfDownloadModalComponent} from "@components/shared/pdf-download-modal/pdf-download-modal.component";
import {UserService} from "@services/user.service";
import {COMPANY_PAGE_VIEW_MODE, COMPANY_TABS, COMPANY_ENTITLEMENT_DATA} from "@utils/constants";
import { ChangeLogModalComponent } from '@components/shared/change-log-modal/change-log-modal.component';
import { CallTypeCategory } from '@utils/enums';

@Component({
  selector: 'app-edit-analysis',
  templateUrl: './edit-analysis.component.html',
  styleUrls: ['./edit-analysis.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class EditAnalysisComponent implements OnInit {

  subsArr$: Subscription[] = [];
  analysisUuid = null;
  analysisId = null;
  name: string;
  companyName: string;
  proposedDebts: Array<ProposedDebt> = [];
  commentary: string;
  templateItems: Array<TemplateItem> = [];
  columns: Array<DataViewColumn> = [];

  scopes: Array<{ id: number }>  = [];
  dataOverrides: Array<DataOverride> = [];
  allowCellEditing = true;
  company: Company = null;
  isNewAnalysis = false;
  embeddedMode = false;
  isSaveButtonDisabled = false;
  refToOverrideIdx = {}
  templateHasSections = false;
  projectionScenarioTypeLabelOverride = 'Projection';
  routeData: Data;
  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS;

  constructor(
    private _sharedData: SharedDataService,
    private _route: ActivatedRoute,
    private _analysisService: AnalysisService,
    private _alertService: AlertService,
    private _featureFlags: LaunchDarklyService,
    private _router: Router,
    private bankSettingsService: BankSettingsService,
    private _popupService: NgxPopupService,
    private _userService: UserService,
  ) {
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.embeddedMode = this._sharedData.embeddedMode$.value;
    this.pageViewMode = this.embeddedMode ? COMPANY_PAGE_VIEW_MODE.FULL : this.routeData.companyEntitlement[COMPANY_TABS.ANALYSES][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];

    if (this._router.url.indexOf('/new') > -1) {
      this.isNewAnalysis = true;
    }

    this._route.paramMap.subscribe(params => {

      this.analysisUuid = params.get('uuid');
      if (this.analysisUuid !== null) {
        this.subsArr$.push(this._analysisService.getAnalysisByUuid(this.analysisUuid)
        .subscribe(data => {
          if (data.length === 0) {
            this._router.navigate(['/404']);
            return;
          }
          const analysisData = data[0];
          this.analysisId = analysisData.id;
          this.proposedDebts = analysisData.proposedDebts;
          this.name = analysisData.name;
          this.companyName = analysisData.companyName;
          this.commentary = analysisData.commentary;
          if (this.proposedDebts.length === 0 && this.isNewAnalysis) {
            this.addLoan();
          }
          this.columns = analysisData.dataColumns;

          if (this.columns && this.columns.some((column) => column.projectionName !== '')) {
            this.bankSettingsService.getProjectionScenarioTypeLabelOverride().subscribe(projectionScenarioTypeLabelOverride => {
              this.projectionScenarioTypeLabelOverride = projectionScenarioTypeLabelOverride || 'Projection';
            });
          }

          this.templateItems = analysisData.templateItems;
          this.dataOverrides = analysisData.dataOverrides;
          this.templateHasSections = this.doesTemplateHaveSections()
          this.calculateRefToOverridesIndex()
          this.scopes = this._analysisService.calculateScopes(this.templateItems, this.columns, this.proposedDebts, this.dataOverrides, this.refToOverrideIdx);
          this.recalculate();
        }));
      }
    });

    this.subsArr$.push(this._sharedData.company$.pipe(
      filter(company => company !== null))
      .subscribe((company: Company) => {
        this.company = company;
      })
    );

    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.setFlags();
    }));

    this.setFlags();
  }

  setFlags() {
    this.allowCellEditing = this._featureFlags.flags['dscr-spread-values-manually-editable'];
  }

  addLoan(): void {
    const nextInt = this.proposedDebts.length + 1;
    this.proposedDebts.push({
      name: `${this.name} - Debt ${(nextInt > 1) ? nextInt : ''}`,
      principal: 0,
      interestRate: .0,
      termMonths: 12,
      amortizationMethod: Loan.PaymentTypeEnum.PrincipalAndInterest,
      amortizationOverrides: null
    });

    this.recalculate();
  };


  recalculate(): void {
    this.isSaveButtonDisabled = false;
    this.scopes = this._analysisService.calculateScopes(this.templateItems, this.columns, this.proposedDebts, this.dataOverrides, this.refToOverrideIdx);
  }

  removeProposedDebt(idToRemove): void {
    this.proposedDebts.splice(idToRemove, 1);
    this.recalculate();
  }

  manuallyEditedValue(newValueObject: DebtServiceNewValue): void {
    const overrideIdx = this.templateHasSections ? this.refToOverrideIdx[newValueObject.ref] : newValueObject.rowIndex
    let equation = newValueObject.newValue;
    if (equation === '' || equation === null) {
      equation = null;
    } else {
      equation = `=${equation}`;
    }

    if (equation === null || newValueObject.newValueMatchesOriginalValue) {
      this.dataOverrides[newValueObject.colIndex][overrideIdx] = null;
    } else {
      this.dataOverrides[newValueObject.colIndex][overrideIdx] = {
        ref: newValueObject.ref,
        equation: equation,
        comment: newValueObject.comment,
      };
    }

    this.recalculate();
  }

  saveChanges(): void {
    this.isSaveButtonDisabled = true;
    this._analysisService.saveAnalysis(this.analysisUuid, this.proposedDebts, this.commentary, this.dataOverrides).subscribe(data => {
        this._alertService.success('Data saved successfully');
        // only allow saving when there is change present or when saving failed
        this.isSaveButtonDisabled = true;
      },
      error => {
        if (error?.ignoreError) {
          this._alertService.error("Unknown server error has occurred, please check the numbers or contact support.");
        } else {
          this._alertService.error(error.message);
        }
        this.isSaveButtonDisabled = false;
      });
  }

  changeCommentary(commentary2: string) {
    this.commentary = commentary2;
    this.recalculate();
  }

  downloadExcel() {
    this.subsArr$.push(
      this._analysisService
        .downloadAnalysisAsFile(this.analysisUuid, this.proposedDebts, this.dataOverrides, 'excel', this.commentary)
        .subscribe(
          () => { },
          (errorResponse) => {
            console.error(errorResponse);
            this.alertUserOfDownloadErrorIfRequired(errorResponse);
          }
        )
    );
  }


  openPdfDownloadModal() {
    this._popupService.open({
      componentType: PdfDownloadModalComponent,
      cssClass: 'pdf-download-modal-class',
      inputs: {
        company: this.company
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  calculateRefToOverridesIndex() {
    let overrideIdx = 0
    if (this.templateHasSections) {
      this.templateItems.forEach((parentItem) => {
        parentItem.children.forEach((childItem) => {
          this.refToOverrideIdx[childItem.ref] = overrideIdx
          overrideIdx++;
        })
      })
    } else {
      this.templateItems.forEach((item) => {
        this.refToOverrideIdx[item.ref] = overrideIdx
        overrideIdx++;
      })
    }
  }

  doesTemplateHaveSections() {
    let sectionFound = false;
    this.templateItems.forEach((item) => {
      if (item.children.length > 0) {
        sectionFound = true;
      }
    })

    return sectionFound
  }

  showSaveButton() {
    return this.pageViewMode === COMPANY_PAGE_VIEW_MODE.FULL;
  }

  private alertUserOfDownloadErrorIfRequired(response: HttpErrorResponse): void {
    if (response.error?.status >= 300) {
      this._alertService.error('There was an error retrieving the download. Please try again or contact administrator.');
    }
  }

  openSingleEntityAnalysisChangeHistory() {
    this._popupService.open({
      componentType: ChangeLogModalComponent,
      cssClass: 'change-log-modal-class',
      inputs: {
        companyId: this.getCompanyId(),
        analysisId: this.analysisId,
        callTypeCategory: CallTypeCategory.SingleEntityAnalysis,
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  // in embedded flow, the company object is null, so try to pull it from user
  getCompanyId() {
    if (this.embeddedMode) {
      return this._userService.user.company_id;
    }
    if (this.company) {
      return this.company.id;
    }
  }
}
