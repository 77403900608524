<div class="row">
	<div class="col-sm-12">
		<h2>Panels</h2>
	</div>
	<div class="col-sm-2">
		<div class="cham-panel">
            <div class="cham-panel__header">
                2 Column Cham Box
            </div>
            <div class="cham-panel__content" style="height: 100px">
            </div>
        </div>
	</div>
	<div class="col-sm-4">
		<div class="cham-panel">
            <div class="cham-panel__header">
                4 Column Cham Box
            </div>
            <div class="cham-panel__content" style="height: 100px">
            </div>
        </div>
	</div>
	<div class="col-sm-6">
		<div class="cham-panel">
            <div class="cham-panel__header">
                6 Column Cham Box
            </div>
            <div class="cham-panel__content" style="height: 100px">
            </div>
        </div>
	</div>
	<div class="col-sm-12">
		<hr />
	</div>
</div>