export * from './apiKey.service';
import { ApiKeyService } from './apiKey.service';
export * from './borrowers.service';
import { BorrowersService } from './borrowers.service';
export * from './customAttributes.service';
import { CustomAttributesService } from './customAttributes.service';
export * from './dataViews.service';
import { DataViewsService } from './dataViews.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './loans.service';
import { LoansService } from './loans.service';
export * from './portfolios.service';
import { PortfoliosService } from './portfolios.service';
export * from './requirements.service';
import { RequirementsService } from './requirements.service';
export * from './tenantSettings.service';
import { TenantSettingsService } from './tenantSettings.service';
export * from './webhooks.service';
import { WebhooksService } from './webhooks.service';
export const APIS = [ApiKeyService, BorrowersService, CustomAttributesService, DataViewsService, FilesService, LoansService, PortfoliosService, RequirementsService, TenantSettingsService, WebhooksService];
