import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import { ClientSearch } from '../../../../models/client-search';
import { NgxPopupService } from '../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { BorrowerService } from '../../../../services/borrower.service';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { SharedDataService } from '../../../../services/shared-data.service';
import { PortfolioService } from '../../../../services/portfolio.service';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { ForceReloadService } from '../../../../services/force-reload.service';
import { USER_GUIDES, PLATFORM_PERMISSIONS} from '@utils/constants';
import { UserGuideService } from '@services/user-guide.service';
import {UserService} from "@services/user.service";
import {Company} from "@models/company";
import {ApiService} from "@services/api.service";
import {Select2OptionData} from "@components/shared/select2/select2.interface";
import {FormControl} from "@angular/forms";

const debounceTimeInMs = 300;

@Component({
  selector: 'app-company-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

@AutoUnsubscribe('subsArr$')
export class CompanyListComponent implements OnInit, OnDestroy {
  subsArr$: Subscription[] = [];
  companies: Company[] = [];
  portfolios: any[] = [];
  portfolioOptions: Array<Select2OptionData> = [];

  isLoading = false;

  sort: {
    field: string,
    dir: boolean,
  };

  limit = 15;
  total: number;
  page = 1;
  clientSearch = new ClientSearch();

  searchIsEmpty: boolean = true;

  previousSidebarType: 'regular' | 'borrowers' = 'regular';
  showAddBorrowerButton: boolean;

  routeData: Data
  customerIdsUtilized: boolean = false;
  customerIdLabel: string = "Customer Id"
  sicCodeOptions: any[] = []
  sicCodeDescriptionLookup: any;
  regionOptions: any[] =  [{code: " ", title: 'All'}];
  sicSelectControl: FormControl<string> = new  FormControl<string>("");
  regionSelectControl: FormControl<string> = new  FormControl<string>("");
  customerIDSearchControl: FormControl<string> = new  FormControl<string>("");
  borrowerNameSearchControl: FormControl<string> = new FormControl<string>("");

  // empty state
  noCompaniesInFilteredListIcon = 'fa-file-circle-question';
  noCompaniesInFilteredListHeaderText = 'No results found';
  noCompaniesInFilteredListHelpText = 'We couldnt find a match for that criteria. Please try another search or add a new borrower.';
  noCompaniesExistIcon = 'fa-hands-clapping';
  noCompaniesExistHeaderText = 'Welcome to Numerated!';
  noCompaniesHelpText = 'All of your borrowers will be listed in this table. Get started by adding a borrower above!';

  private unsubscribe$ = new Subject();

  constructor(private _borrowerService: BorrowerService,
              private _popupService: NgxPopupService,
              private _changeDetector: ChangeDetectorRef,
              private _sharedDataService: SharedDataService,
              public _portfolioService: PortfolioService,
              private _apiService: ApiService,
              public _route: ActivatedRoute,
              private _router: Router,
              private _forceReloadService: ForceReloadService,
              public userGuideService: UserGuideService,
              private _userService: UserService,
  ) {
    this.routeData = this._route.snapshot.data;
  }

  ngOnInit() {
    this.showAddBorrowerButton = this._userService.user.hasUserPermission(PLATFORM_PERMISSIONS.MANAGE_BORROWER)
    this._forceReloadService.refreshIfAvailable();

    // Store what the sidebar state was in when we got here so we can revert back to that when we leave.
    this.previousSidebarType = this._sharedDataService.currentSidebar;
    this._sharedDataService.currentSidebar = 'borrowers';
    this._sharedDataService.pageHeaderTitle$.next('Borrowers');


    this._setDefaultBorrowerNameSearchValueFromQueryParam();
    this._loadSearchSelectorOptions();
    this._setDefaultSort();
    this._bindForSearch();

    this.userGuideService.add(USER_GUIDES.FIND_BORROWER);
    this.customerIdsUtilized = this.routeData.customerIdRelatedSettings.customerIdsUtilized
    if (this.routeData.customerIdRelatedSettings.customerIdLabelOverride) {
      this.customerIdLabel = this.routeData.customerIdRelatedSettings.customerIdLabelOverride
    }
  }

  _setDefaultBorrowerNameSearchValueFromQueryParam() {
    const queryParamSearchValue = this._route.snapshot.queryParamMap.get('search');
    if (queryParamSearchValue) {
      this.clientSearch.search = queryParamSearchValue;
      this.borrowerNameSearchControl.setValue(queryParamSearchValue);
    }
  }

  _bindForSearch() {
    this.subsArr$.push(this._sharedDataService.portfolioFilter$.subscribe((portfolio) => {
      let filter;
      if (!portfolio) {
        filter = " ";
      } else {
        filter = portfolio.id;
      }
      this.filterCompanies('portfolio', filter);
    }));

    this.subsArr$.push(this.borrowerNameSearchControl.valueChanges
      .pipe(debounceTime(debounceTimeInMs))
      .subscribe(value => {
        this.filterCompanies('borrowerName', value)
        this._router.navigate(['companies'], {queryParams: value ? {search: value}: {}});
      })
    );

    this.subsArr$.push(this.sicSelectControl.valueChanges
      .pipe(debounceTime(debounceTimeInMs))
      .subscribe(value => {
        this.filterCompanies('sic', value);
      })
    );
    this.subsArr$.push(this.regionSelectControl.valueChanges
      .pipe(debounceTime(debounceTimeInMs))
      .subscribe(value => {
        this.filterCompanies('region', value)
      })
    );

    this.subsArr$.push(this.customerIDSearchControl.valueChanges
      .pipe(debounceTime(debounceTimeInMs))
      .subscribe(value => {
        this.filterCompanies('customerID', value)
      })
    );
  }

  _setDefaultSort() {
    this.sort = {
      field: 'name',
      dir: true,
    };
  }

  ngOnDestroy(): void {
    this.userGuideService.remove(USER_GUIDES.FIND_BORROWER);

    this._changeDetector.detach();
    this._sharedDataService.currentSidebar = this.previousSidebarType;
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  changePage(page: number) {
    this.page = page;
    this._searchBorrowers();
  }

  filterCompanies(key: string, value: string): void {
    let searchValueHasChanged = false;
    switch (key) {
      case 'reportingCompliance':
        return;
      case 'covenantCompliance':
        searchValueHasChanged = this._hasSearchValueChanged(this.clientSearch.covenantCompliance, value);
        this.clientSearch.covenantCompliance = value;
        break;
      case 'portfolio':
        searchValueHasChanged = this._hasSearchValueChanged(this.clientSearch.portfolio, value);
        this.clientSearch.portfolio = value;
        break;
      case 'sic':
        searchValueHasChanged = this._hasSearchValueChanged(this.clientSearch.sicCode, value);
        this.clientSearch.sicCode = value;
        break;
      case 'region':
        searchValueHasChanged = this._hasSearchValueChanged(this.clientSearch.region, value);
        this.clientSearch.region = value;
        break;
      case 'customerID':
        searchValueHasChanged = this._hasSearchValueChanged(this.clientSearch.customerIDSearch, value);
        this.clientSearch.customerIDSearch = value;
        break;
      case 'borrowerName':
        searchValueHasChanged = this._hasSearchValueChanged(this.clientSearch.search, value);
        this.clientSearch.search = value;
        break;
    }
    if (searchValueHasChanged) {
      this.page = 1;
      this._searchBorrowers();
    }
  }

  _hasSearchValueChanged(previousValue, newValue): boolean {
    return String(newValue).trim() !== String(previousValue).trim();
  }

  sortBy(field) {
    if (this.sort.field === field) {
      this.sort.dir = !this.sort.dir;
    } else {
      this.sort.field = field;
    }

    this.page = 1;
    this._searchBorrowers();
  }

  /**
   * handler to open popup for adding new borrower
   */
  addBorrower() {
    this._popupService.open({
      componentType: CompanyFormComponent,
      cssClass: 'modal-add-client',
      inputs: {
        company: {},
        formType: 'ADD',
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (typeof(data.detail) === 'object' && data.detail?.reOpenModal){
          this.addBorrower();
        }
      }, {once: true});
    });
  };

   /**
   * get borrowers
   */
  _searchBorrowers() {
    this.searchIsEmpty = this.clientSearch.isEmptySearch();
    this.isLoading = true;
    this.companies = [];
    this.unsubscribe$.next(true);

    this._borrowerService.searchCompanies(this.page, this.limit, this.clientSearch, this.sort)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.companies = data.response.objects;
        this.total = data.meta.total;
        this.isLoading = false;
      })
  }

  private _loadSearchSelectorOptions() {
    this._loadSicCodes();
    this._loadRegions();
    this._getPortfolios();
  }

  private _loadSicCodes() {
    this.subsArr$.push(this._apiService.getFile('/assets/files/sic_codes.json')
      .subscribe(data => {
        const temp = [
          {code: " ", title: 'All'},
        ];
        this.sicCodeOptions = temp.concat(data);
        this.sicCodeDescriptionLookup = Object.fromEntries(this.sicCodeOptions.map(pair => [pair.code, pair.title]));
      })
    );
  }

  private _loadRegions() {
    this.subsArr$.push(this._borrowerService.getAllRegions().subscribe(data => {
      const formattedData = data.map(regionCode => {
        return {code: regionCode, title: regionCode}
      });
      this.regionOptions.push(...formattedData);
    }, error => {
      console.error("failed to load available regions.", error)
    }))
  }

  /**
   * get all portfolios
   */
  private _getPortfolios(): void {
    this.subsArr$.push(this._portfolioService.getAllPortfolios().subscribe(data => {
        this.portfolios = data;
        const temp = [
          {id: " ", text: 'All'},
        ];
        data.forEach(portfolio => {
          temp.push({'id': portfolio.id, 'text': portfolio.name})
        });
        this.portfolioOptions = temp;
      }),
    );
  }


  resetSearch() {
    this.customerIDSearchControl.setValue("");
    this.sicSelectControl.setValue("");
    this.regionSelectControl.setValue("");
    this.borrowerNameSearchControl.setValue("");
    this.clientSearch = new ClientSearch();
    this.page = 1;
    this._searchBorrowers();
  }

  clearSearchField(control: FormControl<any>, searchKey: string){
    // calling filter directly here to skip the debounce time
    this.filterCompanies(searchKey, "");
    control.setValue("");
  }
}
