<div class="no-access-warning-container" *ngIf="noPermissions">
    <div class="no-access-warning-box">
        <i class="fas fa-exclamation-triangle no-access-warning-icon"></i>
        <span class="no-access-warning-text">{{ noPermissionsWarningText }}</span>
    </div>
</div>
<div class="landing-container">
    <div class="page-logo">
        <img src="../../../../assets/images/Numerated-2023-logo.svg"/>
    </div>
    <div>
        <span class="welcome-text">{{ welcomeText }}</span>
    </div>
</div>
