import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '../../../../../../../decorators/auto-unsubscribe';
import {DocumentFileService} from '@services/document-file.service';
import {DEFAULT_IMAGE_CROPPER} from '@utils/constants';
import {
  PdfToolbarService
} from "@components/shared/document-viewer/pdf-toolbar/pdf-toolbar.service";

@Component({
  selector: 'financial-summary-split-screen',
  templateUrl: './financial-summary-split-screen.component.html',
  styleUrls: ['./financial-summary-split-screen.component.scss'],
  providers: [PdfToolbarService]
})
@AutoUnsubscribe('subsArr$')
export class FinancialSummarySplitScreenComponent implements OnInit {
  @Input() activePage = 0;
  @Input() readOnlyCropper = null;
  @Input() docFileId = '';
  @Output() closeSplitscreen: EventEmitter<boolean> = new EventEmitter();

  subsArr$: Subscription[] = [];
  errorGettingPageImg = false;
  cropper = DEFAULT_IMAGE_CROPPER;

  activePageUrl: string;

  constructor(
    private _docFileService: DocumentFileService) {
  }

  ngOnInit() {
    this.subsArr$.push(this._docFileService.getDocumentPageImage(this.docFileId, this.activePage).subscribe(
      data => {
        this.activePageUrl = data.response.objects[0];
        if (!this.activePageUrl) {
          this.errorGettingPageImg = true;
        }
      },
      error => {
        console.error("error loading page image url: ", error);
        this.errorGettingPageImg = true;
      }
    ));
  }

  exitSplitscreen() {
    this.closeSplitscreen.emit();
  }

}
