import { Action } from '@ngrx/store';

export enum SpreadingActionTypes {
  DragStarted = '[Spreading] Drag Start',
  DragEnded = '[Spreading] Drag End',
}

export class DragStart implements Action {
  readonly type = SpreadingActionTypes.DragStarted;
}

export class DragEnd implements Action {
  readonly type = SpreadingActionTypes.DragEnded;
}

export type SpreadingActionsUnion = DragStart | DragEnd;
