<div class="row">
	<div class="col-sm-12">
		<h2>Color Pallette</h2>
	</div>

	<div *ngFor="let chunk of chunks" class="col-sm-3">
		<ng-container *ngFor="let color of chunk">
		<div class="pallette_chunk" [style.background-color]="color.value">
			{{color.key}}
		</div>
		</ng-container>
	</div>
	<div class="col-sm-12">
		<hr />
	</div>
</div>
