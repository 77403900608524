import { Box } from './box';
import { Deserializable } from '../../../../../models/deserializable';
import { Cell } from './cell';

export class Table implements Deserializable {
  boundingBox: Box;
  cells: Array<Array<Cell>> = [];
  boxes: Array<Box> = [];
  sourcePageNumber: number;
  columnStructure: Array<Box> = [];

  deserialize(data): this {
    Object.assign(this, data);
    this.cells = this.cells.map(row => row.map(c => new Cell().deserialize(c)));
    this.boxes = this.boxes.map(b => new Box().deserialize(b));
    this.columnStructure = this.columnStructure.map(b => new Box().deserialize(b));
    if (this.boundingBox) {
      this.boundingBox = new Box().deserialize(this.boundingBox);
    }
    return this;
  }
}
