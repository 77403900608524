import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Select2OptionData} from "@components/shared/select2/select2.interface";
import {CustomAttributeDefinition} from "@models/custom-attribute-definition";
import {
  DEFAULT_SUPPORTED_TAX_YEARS,
  SUPPORTED_TAX_YEARS_BY_FORM,
  TAX_RETURN,
  USER_FRIENDLY_UPLOAD_DOC_TYPES
} from "@utils/constants";
import {NgtFileItem} from "@components/shared/popups/upload-document-modal/upload-document-modal.component";

@Component({
  selector: '[file-item-tr]',
  templateUrl: './file-item-row.component.html',
  styleUrls: ['./file-item-row.component.scss']
})
export class FileItemRowComponent implements OnInit {
  @Input() uploadedFile: NgtFileItem;
  @Input() fileUploadProcessBegun: boolean;
  @Input() showPuertoRicoCorporate: boolean;
  @Input() showPuertoRicoIndividual: boolean;
  @Input() taxFormOptions: Select2OptionData[];
  @Input() customAttrDefinitions: CustomAttributeDefinition[];

  @Output('removeFile') _removeFile = new EventEmitter<any>();

  uploadDocumentTypeOptions: Select2OptionData[];
  taxYearOptions: Select2OptionData[];
  TAX_RETURN = TAX_RETURN;
  SUPPORTED_TAX_YEARS: Array<string> = DEFAULT_SUPPORTED_TAX_YEARS;
  nativeElement: HTMLElement;

  constructor(element: ElementRef) {
    // ref to support scrolling into view on upload
    this.nativeElement = element.nativeElement;
  }

  ngOnInit() {
    this.setAvailableDocumentTypeOptions(this.uploadedFile.fileItem.file.type);
    this.setAvailableTaxYearOptions(DEFAULT_SUPPORTED_TAX_YEARS);
  }

  removeFile() {
    this._removeFile.emit()
  }


  setAvailableDocumentTypeOptions(uploadFileType): void {
    this.uploadDocumentTypeOptions = USER_FRIENDLY_UPLOAD_DOC_TYPES.map(docType => {
      if (docType.value === TAX_RETURN) {
        return {
          id: docType.value,
          text: docType.displayName,
          disabled: uploadFileType !== 'application/pdf'
        }
      }
      return {
        id: docType.value,
        text: docType.displayName
      }
    })
  }

  setAvailableTaxYearOptions(availableYears: string[]): void {
    this.taxYearOptions = availableYears.map(year => {
      return {
        id: year,
        text: year
      }
    })
  }

  updateDocType(docTypeValue: string): void {
    this.uploadedFile.error.selectedType = false;
    this.uploadedFile.selectedType = docTypeValue;
    if (this.uploadedFile.selectedType !== TAX_RETURN) {
      this.resetConditionallyVisibleFields();
    }
  }

  resetConditionallyVisibleFields() {
    this.uploadedFile.selectedTaxForm = '';
    this.uploadedFile.selectedStatementDate = '';
    this.uploadedFile.error.selectedTaxForm = false;
    this.uploadedFile.error.selectedStatementDate = false;
  }

  updateTaxForm(formName: string): void {
    this.uploadedFile.error.selectedTaxForm = false;
    this.uploadedFile.selectedTaxForm = formName;
    // update selected year based on years supported for form
    this.SUPPORTED_TAX_YEARS = Array.from(SUPPORTED_TAX_YEARS_BY_FORM[formName]);
    this.setAvailableTaxYearOptions(this.SUPPORTED_TAX_YEARS);
    if (!this.SUPPORTED_TAX_YEARS.includes(this.uploadedFile.selectedStatementDate)) {
      this.uploadedFile.selectedStatementDate = "";
    }
  }

  updateTaxYear(taxYear: string): void {
    this.uploadedFile.error.selectedStatementDate = false;
    if (this.SUPPORTED_TAX_YEARS?.length && this.SUPPORTED_TAX_YEARS.includes(taxYear)) {
      this.uploadedFile.selectedStatementDate = taxYear;
    } else {
      throw new Error('Unsupported tax year: ' + (taxYear ? taxYear : ' '));
    }
  }

  customAttributeChange(attr, event) {
    const value = event.target.value;
    if (!!value) {
      delete this.uploadedFile.error.invalidCustomAttributeFields[attr.key];
      this.uploadedFile.customAttributesValues[attr.key] = {
        key: attr.key,
        attribute_type: attr.attributeType,
        value
      }
    } else {
      this.uploadedFile.error.invalidCustomAttributeFields[attr.key] = true;
      delete this.uploadedFile.customAttributesValues[attr.key];
    }
  }
}
