<div class="modal__header">
    <div class="modal__title">
        CREATE CUSTOM EQUATION
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form [formGroup]="genericEquationForm" (submit)="save(genericEquationForm.value, genericEquationForm.valid)">
    <div class="modal__content">
        <div class="form-header">
            This is an advanced feature.
        </div>

        <div class="row">
            <div class="col-8">
                <div class="mb-3">
                    <label class="control-label" for="label">Label</label>
                    <input [ngClass]="{'is-invalid': isFieldInvalid('label')}" type="text" class="form-control" id="label" placeholder="e.g. Some Wacky Equation" formControlName="label" maxlength="50">
                    <span class="invalid-feedback"
                          *ngIf="isFieldInvalid('label') && genericEquationForm.get('label').hasError('required')">
                        Label is required
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="mb-3">
                    <label class="control-label" for="equation">Equation</label>
                    <input [ngClass]="{'is-invalid': isFieldInvalid('equation')}" type="text" class="form-control" id="equation" placeholder="e.g. =REF_SALES" formControlName="equation">
                    <span class="invalid-feedback"
                          *ngIf="isFieldInvalid('equation') && genericEquationForm.get('equation').hasError('required')">
                        Equation is required
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="mb-3">
                    <label>Does this equation reference items in a DAS statement tab?</label>
                    <div class="input-group">
                        <select class="form-select" formControlName="equationIncludesDasRef">
                            <option [value]=false>No</option>
                            <option [value]=true>Yes</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-8">
                <div class="mb-3">
                    <label class="control-label" for="textFormat">Value Format</label>
                    <app-select2
                        [ngClass]="{'has-error': isFieldInvalid('textFormat')}"
                        [value]="textFormat"
                        placeholder="Choose..."
                        [data]="textFormatOptions"
                        (valueChanged)="textFormatChanged($event)"
                        [width]="250"
                    ></app-select2>
                </div>
            </div>
        </div>
    </div>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="submitted">Create Equation</button>
    </div>
</form>
