import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor() { }

  barWithLineChartDataStructure(lineChartLineItemData, barChartLineItemData, periods): any {
    const chartData = periods.map( (period, index) => {
      return {
        label: period,
        lineChartValue: lineChartLineItemData[index].value,
        barChartValue: barChartLineItemData[index].value,
      }
    });

    return chartData;
  }

  barChartDataStructure(barChartData, periods): any {
    const chartData = periods.map( (period, index) => {
      return {
        label: period,
        value: barChartData[index].value,
      }
    });

    return chartData;
  }

}
