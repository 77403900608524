import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpreadingMapTemplates } from '../models/standard-item-templates';
import { Row } from '../components/main/spreading/models/models';
import { CustomAttributeDefinition } from '../models/custom-attribute-definition';

@Injectable()
export class CustomAttributeDefinitionService {
  constructor(private apiService: ApiService) { }

  getCustomAttributesForModel(modelClass): Observable<any> {
    const payload = {
      filter: {
        model_class_eq: modelClass,
      }
    };

    return this.apiService.send('Post', `/api/custom-attribute-definitions/all`, payload).pipe(
      map(data => {
        return data.response.objects as Array<CustomAttributeDefinition>;
      }));
  }
}
