import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {User} from '../../../../models/user';
import {AlertService} from '../../../../services/alert.service';
import {UserService} from '../../../../services/user.service';
import {NgxPopupService} from '../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import {UserAddComponent} from '../add/add.component';
import {NgxPopupComponent} from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {SharedDataService} from '../../../../services/shared-data.service';
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";

const debounceTimeInMs = 300;

@Component({
  selector: 'app-profile',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class UsersListComponent implements OnInit, OnDestroy {
  public Object = Object;
  subsArr$: Subscription[] = [];
  users: User[] = [];
  isLoading = false;
  limit = 15;
  total: number;
  page = 1;

  searchInput: FormControl<string> = new FormControl<string>("")

  sort: {
    field: string,
    dir: boolean,
  };

  searchObject: {
    email: string,
  };

  noUsersExistIcon = "fa-users";
  noUsersExistHeaderText = "Add a new user";
  noUsersExistHelpText = "No users have been added to this platform. Select 'Add New User' to grant people access.";

  emptyStateIcon = "fa-file-circle-question";
  emptyStateHeaderText = "No results found";
  emptyStateHelpText = "We couldn't find a match for that criteria. Please try another search or add a new user.";

  private unsubscribe$ = new Subject();

  constructor(private alertService: AlertService,
              private userService: UserService,
              private popupService: NgxPopupService,
              private _sharedDataService: SharedDataService,
              private _popupService: NgxPopupService,
  ) {

  }

  ngOnInit(): void {
    this.sort = {
      field: 'email',
      dir: false,
    };
    this.searchObject = {
      email: '',
    };
    this.getUsers();
    this._sharedDataService.pageHeaderTitle$.next('Users');
    this.subsArr$.push(this.searchInput.valueChanges
      .pipe(debounceTime(debounceTimeInMs))
      .subscribe(value => {
        this.filterUsers('email', value);
      })
    );
  }

  addUser() {
    this.popupService.open({
      componentType: UserAddComponent,
      cssClass: 'modal-add-user',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (typeof (data.detail) === 'boolean' && data.detail) {
          this.getUsers();
        }
      }, {once: true});
    });
  }


  sortBy(field) {
    if (this.sort.field === field) {
      this.sort.dir = !this.sort.dir;
    } else {
      this.sort.field = field;
    }

    this.page = 1;
    this.getUsers();
  }

  filterUsers(key: string, value: string): void {
    switch (key) {
      case 'email':
        this.searchObject.email = value;
        break;
    }
    this.page = 1;
    this.getUsers();
  }

  changePage(page: number) {
    this.page = page;
    this.getUsers();
  }

  getUsers() {
    this.users = [];
    this.isLoading = true;
    this.unsubscribe$.next(true); // cancel pending requests
    this.userService.getAll(this.page, this.limit, this.searchObject, this.sort)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.users = data.response.objects;
          this.isLoading = false;
          this.total = data.meta.total;
        },
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
