<div class="container">

  <div class="steps">
    <div class="step" [ngClass]="{'incomplete': numericalProgress < 10}">
      <i class="far fa-circle" *ngIf="numericalProgress < 10"></i>
      <i class="far fa-check-circle green" *ngIf="numericalProgress >= 10"></i>
      <p>Gathering statement data...</p>
    </div>

    <div class="step" [ngClass]="{'incomplete': numericalProgress < 30}">
      <i class="far fa-circle" *ngIf="numericalProgress < 30"></i>
      <i class="far fa-check-circle green" *ngIf="numericalProgress >= 30"></i>
      <p>Calculating cash flow and trend data...</p>
    </div>

    <div class="step" [ngClass]="{'incomplete': numericalProgress < 60}">
      <i class="far fa-circle" *ngIf="numericalProgress < 60"></i>
      <i class="far fa-check-circle green" *ngIf="numericalProgress >= 60"></i>
      <p>Generating trailing twelve months...</p>
    </div>

    <div class="step" [ngClass]="{'incomplete': numericalProgress < 90}">
      <i class="far fa-circle" *ngIf="numericalProgress < 90"></i>
      <i class="far fa-check-circle green" *ngIf="numericalProgress >= 90"></i>
      <p>Interpolating missing periods...</p>
    </div>

    <div class="step" [ngClass]="{'incomplete': numericalProgress < 98}">
      <i class="far fa-circle" *ngIf="numericalProgress < 98"></i>
      <i class="far fa-check-circle green" *ngIf="numericalProgress >= 98"></i>
      <p>Checking accuracy...</p>
    </div>
  </div>

  <div class="descriptions">
    <div *ngIf="numericalProgress < 10">
      <i class="fas fa-hourglass-half bounce"></i>
      <p>Gathering statement data...</p>
    </div>

    <div *ngIf="numericalProgress >= 10 && numericalProgress < 30">
      <i class="far fa-money-bill-alt bounce"></i>
      <p>Calculating cash flow and trend data...</p>
    </div>

    <div *ngIf="numericalProgress >= 30 && numericalProgress < 60">
      <i class="far fa-calendar-check bounce"></i>
      <p>Generating trailing twelve months...</p>
    </div>

    <div *ngIf="numericalProgress >= 60 && numericalProgress < 90">
      <i class="fas fa-history bounce"></i>
      <p>Interpolating missing periods...</p>
    </div>

    <div *ngIf="numericalProgress >= 90 && numericalProgress < 99">
      <i class="fas fa-check-double bounce"></i>
      <p>Checking accuracy...</p>
    </div>

    <div *ngIf="numericalProgress == 99">
      <i class="fas fa-birthday-cake bounce"></i>
      <p>Finalizing...</p>
    </div>

    <div *ngIf="numericalProgress == 100">
      <i class="fas fa-rocket bounce"></i>
    </div>

  </div>

  <div class="progress-wrapper">
  <div class="progress" [style.width]="progressWidth"></div>
</div>
