<app-extended-side-nav
    *ngIf="isExtendedSidebar()"
    [shouldShowPortfolios]="shouldShowPortfolios"
    [shouldDisplayManualReview]="shouldDisplayManualReview()"
    [bypassBackendCache]='bypassBackendCache'
    [switchOnProfiler]='switchOnProfiler'
    [isAdmin]="userIsTenantAdmin()"
    [isInternalDeveloper]="userIsDeveloper()"
    [showBorrowerOptions]="showBorrowerOptions"
    [showSpreadingTemplates]="showSpreadingTemplates"
    [showReporting]="showReporting"
    [showApiKeysOption]="showApiKeysOption"
    (toggleSidebar)="toggleSidebar()"
    (restCommander)="restCommander()"
    (triggerError)="triggerError()"
    (cacheCommander)="cacheCommander()"
    (toggleBypassCache)="toggleBypassCache()"
    (toggleProfiler)="toggleProfiler()"
    (choosePortfolio)="choosePortfolio($event)"
    (chooseBorrower)="chooseBorrower($event)"
>
</app-extended-side-nav>

<app-minimized-side-nav
    *ngIf="isNormalSidebar()"
    [shouldShowPortfolios]="shouldShowPortfolios"
    [shouldDisplayManualReview]="shouldDisplayManualReview()"
    [bypassBackendCache]='bypassBackendCache'
    [switchOnProfiler]='switchOnProfiler'
    [showSpreadingTemplates]="showSpreadingTemplates"
    [showReporting]="showReporting"
    [showApiKeysOption]="showApiKeysOption"
    [isAdmin]="userIsTenantAdmin()"
    [isInternalDeveloper]="userIsDeveloper()"
    [showBorrowerOptions]="showBorrowerOptions"
    (triggerError)="triggerError()"
    (toggleSidebar)="toggleSidebar()"
    (toggleBypassCache)="toggleBypassCache()"
    (toggleProfiler)="toggleProfiler()"
    (restCommander)="restCommander()"
    (cacheCommander)="cacheCommander()"
>
</app-minimized-side-nav>
