import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Select2TemplateFunction, Select2OptionData } from '../../../shared/select2/select2.interface';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { NgxPopupService } from '../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { ConfirmationPopupComponent } from '../../../shared/popups/confirmation/confirmation-popup.component';
import { ReviewQueueItem } from '../../../../models/review-queue-item';
import { AlertService } from '../../../../services/alert.service';
import { ReviewQueueService } from '../../../../services/review-queue.service';
import { SharedDataService } from '../../../../services/shared-data.service';
import { UserService } from '../../../../services/user.service';
import {
  DOCUMENT_PASSWORD_ENTRY_REQUIRED,
  PLATFORM_PERMISSIONS,
  TABLE_ID_VALIDATION_STEP
} from '../../../../utils/constants';
import { BorrowerService } from '@services/borrower.service';
import { Company } from '@models/company';
import { DocumentFile } from '@models/document-file';
import { DocumentFileService } from '@services/document-file.service';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class ReviewQueueListComponent implements OnInit {

  page: number;
  total: number;
  limit: number;
  queueItemToDelete: ReviewQueueItem;
  queueItemToHide: ReviewQueueItem;
  subsArr$: Subscription[] = [];
  documentQueueItems: ReviewQueueItem[] = [];
  isProcessing = false;
  isError = false;

  // public selectOptions: Select2Options;
  public selectOptions: any;

  dateOptions = {
    startDate: [
      {id: ' ', text: 'Start date'},
    ],
    endDate: [
      {id: ' ', text: 'End date'},
    ],
  };

  // empty state
  documentQueueEmptyStateIcon = 'fa-file-circle-check';
  documentQueueEmptyStateHeaderText = 'Document queue is empty';
  documentQueueEmptyStateHelpText = 'No documents are currently being processed through our system. Navigate to a specific borrower to upload new documents.';
  documentQueueErrorIcon = 'fa-warning';
  documentQueueErrorHeaderText = 'Error retrieving queued documents';
  documentQueueErrorHelpText = 'Please contact Numerated support if this problem persists.';

  constructor(
              private _reviewQueueService: ReviewQueueService,
              private _router: Router,
              private _popupService: NgxPopupService,
              private _alertService: AlertService,
              private _sharedDataService: SharedDataService,
              private _userService: UserService,
              private _borrowerService: BorrowerService,
              private _documentFileService: DocumentFileService,
  ) {
    this.page = 1;
    this.limit = 10;
  }

  ngOnInit(): void {
    this._sharedDataService.shouldShowTopNav = true;
    this._sharedDataService.shouldShowSidebar = true;

    this.selectOptions = {
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      minimumResultsForSearch: -1
    }

    this.getQueueItems(1);
  }

  reviewQueueItem(queueItem: ReviewQueueItem) {
    this._userService.setBankIdContext(queueItem.bankId);
    this._router.navigate(['review', queueItem.id, 'manual_review']);
  }

  manualInputReviewQueueItem(queueItem: ReviewQueueItem) {
    this._userService.setBankIdContext(queueItem.bankId);
    this.subsArr$.push(this._reviewQueueService.createBlankTemplatePagesForItem(queueItem.id).subscribe(data => {
      this.reviewQueueItem(queueItem);
    }));
  }

  navigateToBorrower(companyId: number, bank_id: string) {
    this._borrowerService.getCompanyById(companyId).subscribe((company: Company) => {
      this._userService.setBankIdContext(bank_id);
      this._router.navigate(['/companies/' + company.uuid, 'financials', 'analysis']);
    });
  }

  reviewTableIdentification(queueItem: ReviewQueueItem) {
    this._userService.setBankIdContext(queueItem.bankId);
    this._router.navigate(['review', queueItem.id, 'table_identification']);
  }

  reviewTableTabulation(queueItem: ReviewQueueItem) {
    this._userService.setBankIdContext(queueItem.bankId);
    this._router.navigate(['review', queueItem.id, 'table_tabulation']);
  }

  documentPasswordEntry(queueItem: ReviewQueueItem) {
    this._userService.setBankIdContext(queueItem.bankId);
    this._router.navigate(['review', queueItem.id, 'password_entry']);
  }

  hideQueueItem(queueItem: ReviewQueueItem) {
    this.queueItemToHide = queueItem;
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Hide review item?',
        text: 'This item will no longer show up in the review queue, but it will not be deleted.',
      },
      outputs: {
        callback: (approved: boolean) => {
          this.handleHidePopupAnswer(approved, queueItem.bankId);
        }
      },
    });
  }

  deleteQueueItem(queueItem: ReviewQueueItem) {
    this.queueItemToDelete = queueItem;
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Delete review item?',
        text: 'This will remove and delete all information related to this document',
      },
      outputs: {
        callback: (approved: boolean) => {
          this.handleDeletePopupAnswer(approved, queueItem.bankId);
        }
      },
    });
  }

  handleDeletePopupAnswer(result: boolean, bank_id: string): void {
    if (result) {
      this._userService.setBankIdContext(bank_id);
      this.subsArr$.push(
        this._reviewQueueService.deleteQueueItem(this.queueItemToDelete)
          .subscribe(
            data => {
              this._alertService.success('Document deleted successfully');
              this.getQueueItems(this.page);
            },
            error => {
              this._alertService.error(error.message);
            },
          ),
      );

    }
  }

  handleHidePopupAnswer(result: boolean, bank_id: string): void {
    if (result) {
      this._userService.setBankIdContext(bank_id);
      this.subsArr$.push(
        this._reviewQueueService.hideQueueItem(this.queueItemToHide)
          .subscribe(
            data => {
              this._alertService.success('Document hidden successfully');
              this.getQueueItems(this.page);
            },
            error => {
              this._alertService.error(error.message);
            },
          ),
      );

    }
  }

  downloadOriginalQueueItem(queueItem: ReviewQueueItem): void {
    this._userService.setBankIdContext(queueItem.bankId);
    this.subsArr$.push(
      this._reviewQueueService.downloadOriginalQueueItem(queueItem)
        .subscribe(
          data => {
            const url = data.response.objects[0].originalFileUrl;
            const link = document.createElement('a');
            link.href = url;
            link.download = url.split('/').pop();
            link.target = '_blank';
            link.dispatchEvent(new MouseEvent('click'));
          },
          error => {
            this._alertService.error(error.message);
          },
        ),
    );
  }

  spreadQueueItem(queueItem: ReviewQueueItem): void {
    this._userService.setBankIdContext(queueItem.bankId);
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, queueItem.documentFileId).subscribe(files => {
      const documentFile: DocumentFile = files[0];
      this._router.navigate(['spread', documentFile.uuid]);

    }));
  };

  getOptionsForQueueItem(item: ReviewQueueItem): any {
    const actions = [];

    if (this._userService.user.hasUserPermission(PLATFORM_PERMISSIONS.EDIT_SPREADS_AND_RUN_ANALYSIS)) {
      if (item.status === 'HUMAN_INPUT_REQUIRED') {
        switch (item.step) {
          case TABLE_ID_VALIDATION_STEP:
            actions.push({
              id: 'table_id_review',
              text: 'Identify Tables'
            });
            break;
          case 'TableTabulationValidation':
            actions.push({
              id: 'table_tabulation_review',
              text: 'Adjust Columns'
            });
            break;
          case DOCUMENT_PASSWORD_ENTRY_REQUIRED:
            actions.push({
              id: 'document_password_entry',
              text: 'Enter Password'
            });
            break;
          case 'ManualReview':
            actions.push({
              id: 'review',
              text: 'Review Data'
            });
            break;
        }
      } else if (item.status === 'ERROR') {
        actions.push({
          id: 'manual_input',
          text: 'Manual Input'
        });
      } else if (['COMPLETE', 'HUMAN_INPUT_REQUIRED', 'ERROR'].includes(item.status)) {
        actions.push({
          id: 'download',
          text: 'Download'
        });
      }
      // if (['HUMAN_INPUT_REQURED', 'CHANGES_SAVED'].indexOf(item.status) !== -1) {
      //   actions.push({id: 'review', text: 'Review'});
      // }

      if (['REVIEWED'].indexOf(item.status) !== -1) {
        actions.push({id: 'spread', text: 'Categorize Data'});
      }
    }

    // if (['DOC_PROCESSED', 'CHANGES_SAVED', 'REVIEWED', 'ERROR'].indexOf(item.status) !== -1) {
    //   actions.push({id: 'download', text: 'Download'});
    // }
    actions.push({id: 'hide', text: 'Hide'});

    if (this._userService.user.hasUserPermission(PLATFORM_PERMISSIONS.DELETE_DOCUMENT)) {
      actions.push({id: 'delete', text: 'Delete'});
    }

    return actions;
  }

  queueItemActionSelected(item: ReviewQueueItem, event: any, bank_id): void {
    switch (event.value) {
      case 'table_id_review':
        this.reviewTableIdentification(item);
        break;
      case 'table_tabulation_review':
        this.reviewTableTabulation(item);
        break;
      case 'delete':
        this.deleteQueueItem(item);
        break
      case 'hide':
        this.hideQueueItem(item);
        break
      case 'review':
        this.reviewQueueItem(item);
        break;
      case 'download':
        this.downloadOriginalQueueItem(item);
        break;
      case 'spread':
        this.spreadQueueItem(item);
        break;
      case 'manual_input':
        this.manualInputReviewQueueItem(item);
        break;
      case 'document_password_entry':
        this.documentPasswordEntry(item);
        break;
      default:
        console.log('Unknown action: ' + event);
    }
  }

  getIconForAction(option: Select2OptionData): string {
    switch (option.id) {
      case 'delete':
        return '<i class="far fa-trash-alt"></i>';
      case 'review':
        return '<i class="far fa-edit"></i>';
      case 'hide':
        return '<i class="far fa-eye-slash"></i>';
      case 'download':
        return '<i class="fas fa-download"></i>';
      case 'spread':
        return '<i class="far fa-list-alt"></i>';
      default:
        return '';
    }
  }

  getActionStyle(option: Select2OptionData): string {
    switch (option.id) {
      case 'delete':
        return 'color:#d74e09';
      case 'review':
        return '';
      default:
        return '';
    }
  }

  trimFileKey(fileKey) {
    return fileKey.split('/')[0]
  }

  public templateResult: Select2TemplateFunction = (option: Select2OptionData): JQuery | string => {
    return jQuery(`<span style="${this.getActionStyle(option)}">${option.text} ${this.getIconForAction(option)}</span>`);
  }

  public templateSelection: Select2TemplateFunction = (option: Select2OptionData): JQuery | string => {
    return jQuery(`<span style="${this.getActionStyle(option)}">${option.text} ${this.getIconForAction(option)}</span>`);
  }

  private getQueueItems(page: number): void {
    this.isProcessing = true;
    this.isError = false;
    this.page = page;
    this.subsArr$.push(
      this._reviewQueueService.queueList(page, this.limit)
        .subscribe(
          data => {
            this.page = page;
            this.total = data.meta.total;
            this.documentQueueItems = <ReviewQueueItem[]>data.response.objects;
            this.isProcessing = false;
          },
          error => {
            this.isProcessing = false;
            this.isError = true;
          }
        ),
    );
  }
}
