import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SignedUrl } from '../models/signed-url';
import { ResponseTypes } from '../utils/enums';
import CriteriaBuilder from '../utils/query/criteria';


@Injectable()
export class SignedUrlService {

  signedUrl: BehaviorSubject<SignedUrl> = new BehaviorSubject(null);

  constructor(private _apiService: ApiService) {
  }

  /**
   * creates a signed url for uploading documents
   * @param company_id
   * @param state (object to be encoded with request)
   * @returns {Observable<SignedUrl>}
   */
  createPortalBorrowerUploadUrl(companyId: number, state: object): Observable<SignedUrl> {
    return this._apiService.send('Post', '/api/signed-url', {companyId, scope: 'portal-borrower-upload', state}).pipe(
      map(data => data.response.objects[0]));
  }


  validateSignedUrl(signedUrl: SignedUrl): Observable<any> {
    return this._apiService.send('Post', '/api/signed-url/validate', {key: signedUrl.key}, ResponseTypes.Json, true, signedUrl).pipe(
      map(data => data.response.objects[0]));
  }

  getJWTFromKey(signedUrl: SignedUrl, bankId: string): Observable<any> {
    return this._apiService.send('Post', '/api/signed-url/get-jwt', {key: signedUrl.key, bankId: bankId}, ResponseTypes.Json, true).pipe(
      map(data => data.response.objects[0]));
  }

  createApiKey(label: string): Observable<SignedUrl> {
    return this._apiService.send('Post', '/api/signed-url/create-api-key', {label}, ResponseTypes.Json, true).pipe(
      map(data => data.response.objects[0]));
  }

  createAccountIntegrationRequest(companyId: number): Observable<SignedUrl> {
    return this._apiService.send('Post', '/api/signed-url/create-accounting-integration-invite', {companyId}).pipe(
      map(data => data.response.objects[0]));
  }

   /**
   *
   * @param {number} page
   * @param {number} limit
   * @param filters
   * @param sort
   * @returns {Observable<any>}
   */
  getAll(page: number, limit: number, filter: any, sort: any) {
    return this._apiService.send('Post', '/api/signed-url/list', {filter}).pipe(
      map(data => data.response.objects as SignedUrl[]));
  }
}
