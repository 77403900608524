import { Algorithm } from '../models/algorithm';

export class BulkOperation {
  algorithm: Algorithm;
  documentType: string;
  lineItems: Array<any>;
  lockedEquations: Array<any>;
  operationIds: Array<number>;

  constructor(algorithm: Algorithm, documentType: string, lineItems: Array<String> = [], lockedEquations: Array<any> = [], operationIds: Array<number> = []) {
    this.algorithm = algorithm;
    this.documentType = documentType;
    this.lineItems = lineItems;
    this.operationIds = operationIds;
    this.lockedEquations = lockedEquations;
  }
}
