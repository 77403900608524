import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Self,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {DEFAULT_IMAGE_CROPPER} from '@utils/constants';
import {
  PdfToolbarService
} from "@components/shared/document-viewer/pdf-toolbar/pdf-toolbar.service";
import {Subscription} from "rxjs";

const STANDARD_PAGE_HEIGHT_WIDTH_RATIO = 11 / 8.5;
const DEFAULT_IMG_PLACEHOLDER_HEIGHT = 1000;

@Component({
  selector: 'app-document-view-split-screen',
  templateUrl: './document-view-split-screen.component.html',
  styleUrls: ['./document-view-split-screen.component.scss'],
  providers: [PdfToolbarService],
})
export class DocumentViewSplitScreenComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() viewSourcePage = null;
  @Input() viewSourceBox = null;

  @Input() pageImgUrls: string[];
  @Input() originalFileUrl: string;
  @Input() documentFileType: string;

  @Input() documentPages?: any[]  // only for csv/excel docs?

  @Output() closeSplitscreen: EventEmitter<void> = new EventEmitter();
  @Output() rerenderEvent: EventEmitter<void> = new EventEmitter();

  @ViewChildren("pageImgListItem") private pageImgListItems: QueryList<ElementRef>;
  @ViewChild("docContainer") private scrollableDocContainer: ElementRef;

  errorGettingPageImg = false;
  showTable = false;

  pageCount: number;
  cropper = DEFAULT_IMAGE_CROPPER;
  initialized = false;
  imgLoadSub: Subscription;
  showLoadingOverlay = false;

  imgPlaceholderHeight: number = DEFAULT_IMG_PLACEHOLDER_HEIGHT;

  constructor(
    private _pdfToolbarService: PdfToolbarService,
    @Self() private element: ElementRef
  ) {
  }

  ngOnInit() {
    if (this.documentFileType === 'XLSX' || this.documentFileType === 'CSV') {
      this.showTable = true;
      this.pageCount = this.documentPages.length;
    } else {
      if (!this?.pageImgUrls?.length) {
        this.errorGettingPageImg = true;
      } else {
        this.pageCount = this.pageImgUrls.length;
        this.setImagePlaceholderDimensions();
        if (this.viewSourcePage) {
          this.resetCropper();
        }
      }
    }
  }

  ngAfterViewInit() {
    this._pdfToolbarService.scrollableDocContainer = this.scrollableDocContainer;
    this._pdfToolbarService.docPageElements = this.pageImgListItems;
    this.initialized = true;
    if (this.canScrollToSourceBox()) {
      this.scrollToCurrentViewSourcePage();
    }
  }

  setImagePlaceholderDimensions() {
    // Make an educated guess at the dimensions of a rendered document page to determine the fixed dimensions of the
    // placeholder element. This is not exact, and is expected to be inaccurate for landscape oriented images, but is
    // not critical - the goal is make the image loading transition smoother.
    let docContainerElement = this.element.nativeElement;
    if (docContainerElement) {
      const containerRect = docContainerElement.getBoundingClientRect();
      const imageWidth = containerRect.width - 37;
      this.imgPlaceholderHeight = imageWidth * STANDARD_PAGE_HEIGHT_WIDTH_RATIO;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let sourceBoxChange = false;
    if (changes.hasOwnProperty('viewSourceBox')) {
      sourceBoxChange = true;
    }
    if (changes.hasOwnProperty('viewSourcePage')) {
      sourceBoxChange = true;
    }
    if (sourceBoxChange && this.initialized && this.canScrollToSourceBox()) {
      this.scrollToCurrentViewSourcePage();
    }
    this.resetCropper();
  }

  scrollToCurrentViewSourcePage() {
    const sourcePageElement = document.querySelector(`#index-${this.viewSourcePage}`);
    if (sourcePageElement) {
      sourcePageElement.scrollIntoView({behavior: 'auto', block: "center"});
    }
  }

  canScrollToSourceBox() {
    return !this.errorGettingPageImg && this.viewSourcePage !== null && !isNaN(this.viewSourcePage) &&
      this.viewSourcePage !== this._pdfToolbarService.visiblePageNumber - 1
  }

  handleScroll() {
    this._pdfToolbarService.scrollSubject$.next();
  }

  exitSplitscreen() {
    this.closeSplitscreen.emit();
  }

  resetCropper() {
    if (this.viewSourceBox !== null) {
      this.cropper = {
        x1: this.viewSourceBox.llc.x,
        y1: this.viewSourceBox.ulc.y,
        x2: this.cropper.x2 = this.viewSourceBox.lrc.x,
        y2: this.cropper.y2 = this.viewSourceBox.llc.y,
        width: this.viewSourceBox.pageSize.width,
        height: this.viewSourceBox.pageSize.height,
      };
    } else {
      this.cropper = DEFAULT_IMAGE_CROPPER;
    }
  }

  ngOnDestroy() {
    this.imgLoadSub?.unsubscribe();
  }
}
