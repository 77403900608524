import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Footnote } from '@models/footnote';
import { NumberFormattingService } from '@services/number-formatting.service';
import {DEFAULT_CURRENCY, TEXT_FORMAT_TYPES} from '@utils/constants';

@Component({
  selector: 'app-period-over-period',
  templateUrl: './period-over-period.component.html',
  styleUrls: ['./period-over-period.component.scss']
})
export class PeriodOverPeriodComponent implements OnInit {

  @Input() label = '';
  @Input() value: number = null;
  @Input() prevValue = null;
  @Input() ref = ''
  @Input() rawItem = false;
  @Input() footnotes = [];
  @Input() prevSourceBox = null;
  @Input() sourceBox = null;
  @Input() textFormat = TEXT_FORMAT_TYPES.NUMBER;
  @Output() openFootnotes = new EventEmitter<Footnote>();
  @Output() openDetail = new EventEmitter<string>();
  @Output() openSourceBox = new EventEmitter<any>();

  changeType = ''
  contextMenuOpen = false;
  formattedValue = '';

  constructor(
    private _numberFormattingService: NumberFormattingService,
    ) { }

  ngOnInit() {
    this.periodOverPeriodChangeIsPositiveNegativeOrNeutral()
    this.formatValue();
  }

  displayPeriodOverPeriodChange(value, prevValue) {
    if (value === 0 && prevValue === 0) {
      return '- %'
    }
    return (Math.abs(this.percentChange(value, prevValue) * 100)).toFixed(1).toString() + '%'
  }

  periodOverPeriodChangeIsPositiveNegativeOrNeutral() {
    if (this.value == null || this.prevValue == null || this.value === this.prevValue) {
      this.changeType = 'neutral'
    } else if (this.value >= this.prevValue) {
      this.changeType = 'positive'
    } else if (this.prevValue > this.value) {
      this.changeType = 'negative'
    }
  }

  percentChange(newValue, oldValue) {
    if (newValue == null || oldValue === null) {
      return 0;
    } else if (oldValue === 0 && newValue !== 0) {
      // if we are going from a 0 to a non-zero value, the % increase is 100%
      return 1;
    }
    return (newValue - oldValue) / oldValue
  }

  toggleContextMenu(evt) {
    this.contextMenuOpen = !this.contextMenuOpen
  }

  clickOpenFootnote(evt, footnoteIdx) {
    this.openFootnotes.emit(this.footnotes[footnoteIdx])
    this.contextMenuOpen = false;
  }

  clickOpenDetail(evt) {
    if (!this.rawItem) {
      this.openDetail.emit(this.ref);
    }
  }

  clickOpenSourceBox(evt) {
    if (this.sourceBox !== null) {
      this.openSourceBox.emit(this.sourceBox);
    }
  }

  clickOpenPreviousSourceBox(evt) {
    if (this.prevSourceBox !== null) {
      this.openSourceBox.emit(this.prevSourceBox);
    }
  }

  formatValue(): void {
    // TODO:
    // Dynamic currency changing and visual display updating is not being supported here. USD is hardcoded as the default
    // currency when generating reports and numbers/currencies will be formatted to USD. This needs to be updated so that
    // currency information can exist in this component and dynamically change/update in the future.
    const options = { isRoundedToThousands: false, showDollarSign: true, currency: DEFAULT_CURRENCY };
    if (this.textFormat === TEXT_FORMAT_TYPES.CURRENCY) { // for regression testing, want CURRENCY to not round to two decimals here
      const numDecimals = 0;
      this.formattedValue = this._numberFormattingService.formatNumber(this.value, this.textFormat, numDecimals, options);
    } else {
      this.formattedValue = this._numberFormattingService.formatNumber(this.value, this.textFormat, null, options);
    }
  }

}
