import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { NgxPopupService } from '../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { NgxPopupComponent } from '../../shared/ngx-popups/ngx-popups/ngx-popups';
import { RestComponent } from '../../developer/command/rest/rest.component';
import { Router } from '../../../../../node_modules/@angular/router';
import { Location } from '@angular/common';
import { SharedDataService } from '../../../services/shared-data.service';
import { LaunchDarklyService } from '../../../services/launchdarkly.service';
import { BorrowerService } from '../../../services/borrower.service';
import { LoggingService, Logger } from '../../../services/logging.service';
import { Company } from '../../../models/company';
import { CacheComponent } from '../../developer/command/cache/cache.component';
import {PLATFORM_PERMISSIONS} from "@utils/constants";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
})

@AutoUnsubscribe('subsArr$')
export class SideNavComponent implements OnInit {
  subsArr$: Subscription[] = [];
  bypassBackendCache = false;
  switchOnProfiler = false;
  showSpreadingTemplates = false;
  showReporting: boolean;
  showApiKeysOption: boolean;
  shouldShowPortfolios = false;
  private logger: Logger;

  showBorrowerOptions; boolean;

  constructor(
    public _featureFlags: LaunchDarklyService,
    public userService: UserService,
    private _sharedDataService: SharedDataService,
    private popupService: NgxPopupService,
    private _router: Router,
    private _location: Location,
    private _borrowerService: BorrowerService,
    private _loggingService: LoggingService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('SideNavComponent');
  }

  ngOnInit() {
    this.showReporting = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.RUN_REPORTING)
    this.showBorrowerOptions = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.SEARCH_BORROWER)
    this.showApiKeysOption = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.GENERATE_API_TOKEN)
    this.subsArr$.push(this._sharedDataService.bypassBackendCache$.subscribe(v => {
      this.bypassBackendCache = v;
    }));

    this.subsArr$.push(this._sharedDataService.switchOnProfiler$.subscribe(v => {
      this.switchOnProfiler = v;
    }));

    this.updateFlags(this._featureFlags.flags);
    this.subsArr$.push(this._featureFlags.flagChange.subscribe(flags => {
      this.updateFlags(flags);
    }));

    this.shouldShowPortfolios = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.MANAGE_PORTFOLIO)

  }

  choosePortfolio(portfolio: any): void {
    if (this._location.path() !== '/companies') {
      this._router.navigate(['companies'], {queryParamsHandling: "preserve"});
    }
    this._sharedDataService.portfolioFilter$.next(portfolio);
  }

  chooseBorrower(selectedBorrower): void {
    if (selectedBorrower && selectedBorrower.id) {
      this._borrowerService.getCompanyById(selectedBorrower.id).subscribe((company: Company) => {
        this._sharedDataService.company$.next(selectedBorrower);
        this._router.navigate(['companies' , company.uuid, 'financials', 'analysis']);
      });
    }
  }

  triggerError() {
    this.logger.error('Dev Triggered Error Message')
  }

  restCommander() {
    this.popupService.open({
      componentType: RestComponent,
      cssClass: 'modal-rest-commander',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  cacheCommander() {
    this.popupService.open({
      componentType: CacheComponent,
      cssClass: 'modal-cache-commander',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  shouldDisplayManualReview() {
    return this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.VIEW_SUBMISSION_REVIEW_QUEUE);
  }

  isExtendedSidebar(): boolean {
    return this._sharedDataService.currentSidebar === 'borrowers';
  }

  userIsTenantAdmin(): boolean {
    return this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.MANAGE_USER);
  }

  userIsDeveloper(): boolean {
    return this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.ACCESS_DEVELOPER_TOOLS);
  }

  isNormalSidebar(): boolean {
    return !this.isExtendedSidebar();
  }

  toggleSidebar(): void {
    if (this._sharedDataService.currentSidebar === 'borrowers') {
      this._sharedDataService.currentSidebar = 'regular';
    } else if (this._sharedDataService.currentSidebar === 'regular') {
      this._sharedDataService.currentSidebar = 'borrowers';
    }
  }

  toggleBypassCache(): void {
    this.userService.toggleBypassBackendCache();
  }

  toggleProfiler(): void {
    this.userService.toggleBackendProfiler();
  }

  private updateFlags(flags: object) {
    this.showSpreadingTemplates = flags['spreading-templates'];
    this.showSpreadingTemplates = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.MANAGE_TEMPLATE);
  }
}
