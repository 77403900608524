import {Injectable} from "@angular/core";
import {ApiService} from "@services/api.service";
import {ResponseTypes} from "@utils/enums";
import {forkJoin, Observable, of} from "rxjs";
import {saveAs as importedSaveAs} from "file-saver";
import {LinkedEntitiesService} from "@services/linked-entities.service";
import {map, mergeMap} from "rxjs/operators";
import {Company} from "@models/company";
import {AnalysisService} from "@services/analysis.service";
import {GlobalCashflowService} from "@services/global-cashflow.service";
import {DataViewService} from "@services/data-view.service";
import {AlertService} from "@services/alert.service";
import {mergePdfs} from "@utils/pdf-lib-functions";

export interface EntityWithDownloadableDocs {
  entityName: string,
  analyses: Document[],
  globalCashflows: Document[],
  financialDataViews: Document[]
}

export interface Document {
  selected: boolean;
  id: number;
  companyId: number;
  name: string;
  className: string;
  proposedDebts?: any;
  dataOverrides?: any;
  commentary?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadModalService {

  constructor(
    private _apiService: ApiService,
    public analysisService: AnalysisService,
    public globalCashflowService: GlobalCashflowService,
    public linkedEntitiesService: LinkedEntitiesService,
    public dataViewService: DataViewService,
    public alertService: AlertService,
  ) {
  }

  loadAvailableDocumentsForCompanyAndLinkedEntities(company: Company): Observable<EntityWithDownloadableDocs[]> {
    return this.linkedEntitiesService.getLinkedEntities(company.uuid).pipe(
      mergeMap(response => {
        const downloadableDocsByEntity$: Observable<EntityWithDownloadableDocs>[] = response.map(linkedEntity => {
          return this._loadDownloadableDocsForEntity(linkedEntity.linkedEntity)
        }) || [];
        // unshift to ensure the primary company's docs appear first in the list
        downloadableDocsByEntity$.unshift(this._loadDownloadableDocsForEntity(company))
        return forkJoin(downloadableDocsByEntity$)
      })
    )
  }

  _loadDownloadableDocsForEntity(company): Observable<EntityWithDownloadableDocs> {
    // Combines the requests to load different types of available documents into a single observable
    return forkJoin(
      {
        entityName: of(company.name),
        analyses: this.analysisService.getAnalyses(company.id),
        globalCashflows: this.globalCashflowService.getGlobalCashflowAnalyses(company.id),
        financialDataViews: this.dataViewService
          .listDataViews(company.id, company.defaultSpreadingTemplate, true)
          .pipe(
            map(views => views.filter(this.shouldShowDataView)
              .map(dv => {
                return {...dv, companyId: company.id, name: dv.title}
              })
            )
          ),
      }
    )
  }

  shouldShowDataView(dv): boolean {
    // filter available financials for consistency w/ the UI (copied from src/app/components/main/borrower/details/financials/analysis/analysis.component.ts)
    const hideDataViews = ['Normalized IS', 'Normalized BS', 'Normalized CF', 'Normalized CV', 'Normalized Cash Flow Statement', 'Normalized Balance Sheet', 'Normalized Income Statement', 'Calculations & Ratios', 'Benchmarks'];
    return hideDataViews.indexOf(dv.title) < 0;
  }

downloadCombinedPdf(docList: any[], fileName: string = 'combined'): Observable<void> {
    const downloadRequests: Observable<any>[] = docList.flatMap(doc => this.getApiRequestForDocDownload(doc));

    return forkJoin(downloadRequests).pipe(
      mergeMap(results => {
        const pdfsToMerge: ArrayBuffer[] = results.flatMap(pdf => pdf?.body || []);
        return mergePdfs(pdfsToMerge).then(resultMergedBuffer => {
          const blob = new Blob([resultMergedBuffer], {type: 'application/pdf'});
          importedSaveAs(blob, fileName);
        });
      })
    );
  }

  getApiRequestForDocDownload(doc): Observable<any> | any[] {
    let payload;
    let uri;
    switch (doc?.className) {
      case "GlobalCashFlowAnalysis":
        payload = {
          proposedDebts: doc.proposedDebts,
          dataOverrides: doc.dataOverrides,
          commentary: doc.commentary,
        };
        uri = `/api/global-cashflow-analyses/${doc.id}/download/pdf`;
        break;
      case "FinancialAnalysis":
        payload = {
          proposedDebts: doc.proposedDebts,
          dataOverrides: doc.dataOverrides,
          commentary: doc.commentary,
        };
        uri = `/api/analyses/${doc.id}/download/pdf`;
        break;
      case "DataView":
        payload = {
          'company_id': doc.companyId,
        };
        uri = `/api/v2/dataviews/${doc.id}/pdf`;
        break;
      default:
        console.error(`Unable to download document ${doc.name}. Unsupported document class: ${doc?.className}`)
        return [] // returning empty array to skip over document - this will be removed in the flatmap
    }
    return this._apiService.send('Post', uri, payload, ResponseTypes.ArrayBuffer)
  }
}
