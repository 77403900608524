import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit} from "@angular/core";
import {ElementRef} from "@angular/core";
import {Subject} from "rxjs";
import {delay, filter} from "rxjs/operators";


const debounceTime = 100;

@Directive({
  selector: "[appImgIntersectionObxList]"
})
export class IntersectionObserverListDirective implements OnInit, OnDestroy {
  @Input() forceChangeDetection: boolean = false;
  private mapping: Map<Element, Function>;
  private observer: IntersectionObserver;
  private observerSubject$ = new Subject<{ entry: IntersectionObserverEntry, callback: Function }>()

  constructor(
    private _cdr: ChangeDetectorRef
  ) {
    this.mapping = new Map();
    // add a single intersection observer on the parent (list) component, to observe/process the child elements
    this.observer = new IntersectionObserver(
      this.observerCallback.bind(this),
      {
        rootMargin: '100px',
        threshold: 0.3
      }
    );
  }

  ngOnInit() {
    // transform the events into an observable to allow debouncing. Only load images that are still visible after a
    // brief delay, so we avoid loading images that are quickly scrolled past.
    this.observerSubject$
      .pipe(delay(debounceTime), filter(Boolean))
      .subscribe(async ({entry, callback}: { entry: IntersectionObserverEntry, callback: Function }) => {
        const target = entry.target as HTMLElement;
        const isStillVisible = await this.isVisible(target);
        if (isStillVisible) {
          callback();
          if (this.forceChangeDetection) {
            this._cdr.detectChanges();
          }
        }
      })
  }

  private isVisible(element: HTMLElement) {
    // Create a new intersectionObx for the individual entry to get an up-to-date isIntersecting value
    return new Promise(resolve => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.isIntersecting);
        observer.disconnect();
      });

      observer.observe(element);
    });
  }

  public observerCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      const callback = this.mapping.get(entry.target);
      if (entry.isIntersecting && callback) {
        this.observerSubject$.next({entry, callback});
      }
    });
  }

  public add(element: HTMLElement, callback: Function): void {
    this.mapping.set(element, callback);
    this.observer.observe(element);
  }

  public remove(element: HTMLElement): void {
    this.mapping.delete(element);
    this.observer.unobserve(element);
    if (this.mapping.size === 0) {
      this.observer.disconnect();
    }
  }

  ngOnDestroy(): void {
    this.mapping.clear();
    this.observer.disconnect();
    this.observerSubject$.next();
    this.observerSubject$.complete();
  }
}


@Directive({
  selector: "[appImgIntersectionObx]",
  exportAs: "intersection"
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  public hasIntersected: boolean;
  private elementRef: ElementRef;
  private parent: IntersectionObserverListDirective;

  constructor(
    parent: IntersectionObserverListDirective,
    elementRef: ElementRef
  ) {
    this.parent = parent;
    this.elementRef = elementRef;
    this.hasIntersected = false;
  }

  ngOnInit(): void {
    this.parent.add(
      this.elementRef.nativeElement,
      this.onIntersectionCallback.bind(this)
    );
  }

  onIntersectionCallback() {
    this.hasIntersected = true;
    this.parent.remove(this.elementRef.nativeElement)
  }

  ngOnDestroy(): void {
    this.parent.remove(this.elementRef.nativeElement);
  }
}
