import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileLikeObject } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../../services/app-config.service';
import { AlertService } from '../../../../services/alert.service';
import { CustomUploaderService } from '../../../../services/custom-uploader.service';
import { UserService } from '../../../../services/user.service';
import { FileUploaderCustom } from '../../custom-file-uploader/custom-file-uploader';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';
import { IMAGE_MIME_TYPES } from '../../../../utils/constants';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { CommonFunctions } from '../../../../utils/common-functions';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ContactSupportComponent implements OnInit {
  @Input() popup: NgxPopupComponent;

  formGroup: FormGroup;
  uploader: FileUploaderCustom;
  subsArr$: Subscription[] = [];

  sendingInProcess = false;
  formSubmitAttempt = false;
  hasBaseDropZoneOver = false;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private customUploaderService: CustomUploaderService,
              private router: Router,
              private alertService: AlertService,
              private appConfigService: AppConfigService) {
  }

  ngOnInit() {
    this._subscribeForCustomUploaderService();
    this.formGroup = this.formBuilder.group({
      subject: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.required, Validators.maxLength(500)]]
    });
    this._createUploader();
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  /**
   * click handler to remove attached files from the list
   * @param {number} elementIndex
   */
  removeAttachedFile(elementIndex: number): void {
    if (this.sendingInProcess) {
      return;
    }
    this.uploader.queue.splice(elementIndex, 1);
  }

  /**
   * main handler to send an email to the server
   * @param fb
   * @param {boolean} valid
   */
  sendEmail(fb: any, valid: boolean): void {
    this.formSubmitAttempt = true;
    if (!valid || !this._validateFilesQuantity()) {
      return;
    }
    this.sendingInProcess = true;
    this.popup.closable = false;
    this.uploader.uploadAllFiles();
  }

  isFieldInvalid(field: string): boolean {
    return (
      (this.formGroup.get(field).invalid && this.formGroup.get(field).touched) ||
      (this.formGroup.get(field).invalid && this.formSubmitAttempt)
    );
  }

  /**
   * initialize new uploader with required properties
   */
  private _createUploader() {
    this.uploader = new FileUploaderCustom({
      url: CommonFunctions.getEnvFromHost().serverUrl + '/api/notifications/feedback',
      autoUpload: false,
      authTokenHeader: 'X-Authorization',
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: false,
      maxFileSize: 2621440,
      disableMultipart: true,
      allowedMimeType: IMAGE_MIME_TYPES,
    }, this.customUploaderService, this.router);
    this._setUploaderCallbacks();
  }

  /**
   * subscribe for events from
   */
  private _subscribeForCustomUploaderService() {
    this.subsArr$.push(this.customUploaderService.successSubject$.subscribe(val => {
      this.alertService.success('Thank you for your feedback');
      this.sendingInProcess = false;
      this.popup.closable = true;
      this.popup.close();
    }));

    this.subsArr$.push(this.customUploaderService.errorSubject$.subscribe(val => {
      this.alertService.error('Something went wrong during form Submit. Please try to send it again.');
      this.sendingInProcess = false;
      this.popup.closable = true;
    }));
  }

  /**
   * helper to set callback for custom uploader
   */
  private _setUploaderCallbacks() {
    this.uploader.onBuildItemForm = (item, form) => {
      form.append('subject', this.formGroup.value.subject);
      form.append('description', this.formGroup.value.description);
      form.append('email', this.userService.user.email);
    };

    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      const errorMsg = item.size > 2621440
        ? `${item.name} size is more than maximum size of 2.5MB`
        : `${item.name} is of unsupported format`;
      this.alertService.warning(errorMsg);
    };
  }

  /**
   * helper to validate quantity of files
   * @returns {boolean}
   */
  private _validateFilesQuantity(): boolean {
    if (this.uploader.queue.length > 3) {
      this.alertService.warning('Maximum of 3 files can be attached to the email');
    }
    return this.uploader.queue.length < 4;
  }
}
