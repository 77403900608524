<div class="row title-bar-container" xmlns="http://www.w3.org/1999/html">
    <div class="title-bar">
        <div class="template-name">
            <div *ngIf="!isTemplateNameBeingEdited" class="template-name-no-edit-mode">
                <strong>Template Name: </strong>
                {{ spreadingTemplate.name }}
                <i class="fas fa-pencil-alt edit-name-icon" (click)="toggleTemplateNameEditMode()"></i>
            </div>
            <div *ngIf="isTemplateNameBeingEdited">
                <strong>Template Name: </strong>
                <input [value]="spreadingTemplate.name" (input)="spreadingTemplate.name = $event.target.value" placeholder="Template Name"/>
                <a class="edit-name-icon" (click)="toggleTemplateNameEditMode()">Save Template Name</a>
            </div>
        </div>
        <div class="edit-spreading-template-buttons">
            <div>
                <label class="default-template-checkbox-label">Default Template</label>
                <input type="checkbox" class="default-template-checkbox" [(ngModel)]="spreadingTemplate.isDefault"/>
            </div>
            <a class="import-export-button" (click)="beginImportExport()">Import/Export</a>
        </div>
    </div>
</div>

<div class="container-fluid edit-template-body-container">
    <div class="row edit-template-header">
        <div class="col-6 edit-template-header-label">
            <h3>Edit Template</h3>
        </div>
        <div class="col-6 save-template-button-container">
            <button *ngIf="mode == 'create'" (click)="createTemplate()" class="btn btn-primary btn-rounded save-template-button">Create Template</button>
            <button *ngIf="mode == 'update'" (click)="updateTemplate()" class="btn btn-rounded save-template-button btn-primary">
            Save Template</button>
        </div>
    </div>
    <div class="row main-editor-container">
        <div class="col-md-4 fincura-tree-container">
            <h5>LINE ITEMS AVAILABLE FOR TEMPLATE</h5>
            <div class="vertical-scroll">
                <div *ngFor="let templateName of listOfTemplateNames; let templateNameidx = index">
                    <div class="available-items-template-name-container" (click)="toggleDrawerState(templateNameidx)">
                        <i [ngClass]="templateAvailableItemDrawerState[templateNameidx] ? 'fas fa-chevron-down':  'fas fa-chevron-right'" class="available-items-template-name-icon"></i>
                        <label class="available-items-template-name-label">
                            {{ templateName }}
                        </label>
                    </div>
                    <div *ngFor="let lineItem of availableTaxonomyItemsSeparatedByTemplate[templateName]" class="available-item-container" [ngClass]="{'available-items-for-template-hidden': !templateAvailableItemDrawerState[templateNameidx]}">
                        <div class="available-item-label-container">
                            <label class="available-item-label" [ngClass]="{'available-item-label-in-working-template': lineItem.isInWorkingTemplate}" [title]="lineItem.item.entityType === 'Individual' ? 'Individual Taxonomy Item' : ''">
                                {{ lineItem.item.label }}<i *ngIf="lineItem.item.entityType === 'Individual'" class="fa-solid fa-user"></i>
                            </label>
                            <i *ngIf="lineItem.itemFoundInMultipleTemplates" class="fas fa-xs fa-exclamation-circle item-found-in-multiple-templates-icon"
                                title="Item appears in multiple templates"
                            ></i>
                            <input type="checkbox" class="available-item-checkbox" [(ngModel)]="lineItem.isSelected" [ngModelOptions]="{standalone: true}" [disabled]="lineItem.isInWorkingTemplate" (click)="enqueueOrDequeueAvailableItem(lineItem.item, !lineItem.isSelected)">
                        </div>
                        <label class="available-item-ref-label">
                            {{lineItem.item.ref}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="add-to-template-button-container">
                <button class="btn btn-primary" (click)="addSelectedAvailableItemsToWorkingTree()" [disabled]="availableItemsQueuedForWorkingTempate.length === 0">Add To Template Structure</button>
            </div>

        </div>
        <div class="col-md-8">
            <div class="working-tree-container">
                <div class="row">
                    <div class="col-sm-12">
                        <h5>TEMPLATE STRUCTURE
                            <button class="btn btn-link add-section-button" (click)="addTemplateSection()"><i class="fas fa-plus-circle"></i><span> Add Section</span></button>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 working-tree-tile">
                        <div class="tree-container">
                            <tree-root
                                #workingTree
                                [nodes]="workingNodes"
                                [options]="workingTreeOptions"
                                (focus)="onWorkingTreeNodeFocus($event)"
                            >
                                <ng-template #treeNodeTemplate let-node let-index="index">
                                    <span [ngClass]="{'bold': node.data.displayBold, 'italicized': node.data.className == 'RollupItem', 'item-required': node.data.isRequired }" [title]="node.data.entityType === 'Individual' ? 'Individual Taxonomy Item' : ''">{{ node.data.label }}<i *ngIf="node.data.entityType === 'Individual'" class="fa-solid fa-user"></i></span>
                                </ng-template>
                            </tree-root>
                        </div>
                    </div>
                    <div class="col-sm-6 edit-template-item-container">
                        <h5 class="edit-template-item-header">Edit Template Item</h5>
                        <ng-container *ngIf="editNode; else noTemplateItemSelected">
                            <div class="cham-panel">
                                <div class="cham-panel__content">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">Template Item Label</p>
                                            <input class="node-name-input" #treeFilter (keyup)="editNode.data.label = treeFilter.value;" [value]="editNode.data.label" placeholder="Item Name" [disabled]="editNode.data.className=='GenericCalculatedItem'"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">Reference ID</p>
                                            <ng-container>
                                                {{editNode.data.ref}}
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">Rollup Behavior</p>
                                            <ng-container>
                                                <button class="btn roll-option-inactive" [ngClass]="{'rollup-option-active': editNode.data.rollupBehavior=='+'}" (click)="editNode.data.rollupBehavior = '+';" title="Rollup Additive"><i class="fas fa-plus-circle"></i><span>Add</span></button>
                                                <button class="btn roll-option-inactive" [ngClass]="{'rollup-option-active': editNode.data.rollupBehavior=='-'}" (click)="editNode.data.rollupBehavior = '-';" title="Rollup Subtractive"><i class="fas fa-minus-circle"></i><span>Subtract</span></button>
                                                <button class="btn roll-option-inactive" [ngClass]="{'rollup-option-active': editNode.data.rollupBehavior==''}" (click)="editNode.data.rollupBehavior = '';" title="Rollup Exclude"><i class="fas fa-ban"></i><span>Omit</span></button>
                                            </ng-container>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Display Options</h6>
                                            <input
                                            [checked]="editNode.data.displayBold"
                                            (change)="toggleBold()"
                                            type="checkbox" />
                                            Bold
                                        </div>
                                        <div *ngIf="editNode.data.className != 'RollupItem'" class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Number of Decimals</h6>
                                            <input [(ngModel)]="editNode.data.numDecimals" type="number" />
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="(editNode.data.className=='StandardItem' && editNode.data.children && editNode.data.children.length > 0) || editNode.data.className=='GenericStandardItem'">
                                        <div class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Include Rollup Total</h6>
                                            <input
                                            [checked]="editNode.data.hasRollup"
                                            (change)="editNode.data.hasRollup = !editNode.data.hasRollup;"
                                            type="checkbox" />
                                            Show {{editNode.data.rollupLabel}}
                                        </div>
                                    </div>
                                    <ng-container *ngIf="editNode.data.className=='CalculatedItem'">
                                      <div class="row">
                                          <div class="col-sm-12">
                                              <h6 class="edit-template-item-option-header">Default Formula</h6>
                                              <div class="original-equation-container">
                                                <p>{{editNode.data.originalEquation}}</p>
                                              </div>

                                          </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngIf="editNode.data.className=='CalculatedItem' || editNode.data.className=='GenericCalculatedItem'">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h6 class="edit-template-item-option-header">Customize Formula</h6>
                                                <input class="node-name-input" [(ngModel)]="editNode.data.equationOverride" type="text" placeholder="Enter formula if custom formula required"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <input
                                            [checked]="showItemvalidator"
                                            (change)="toggleHideShowValidator($event);"
                                            type="checkbox"
                                            />
                                            Add Validation Rule?
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="showItemvalidator">
                                        <div class="col-sm-12">
                                            If {{editNode.data.label}} is
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Comparer:{{'&#160;'}}</label> <select [(ngModel)]="editNode.data.validator.comparer">
                                                <option *ngFor="let opt of validatorComparerOptions" [value]="opt.value" [selected]="opt.value == editNode.data.validator.comparer">{{opt.label}}</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-12">
                                            <label>Equation:{{'&#160;'}}</label> <input [(ngModel)]="editNode.data.validator.thresholdEquation" type="text" placeholder="e.g. =1.25" />
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Mark it:{{'&#160;'}}</label> <select [(ngModel)]="editNode.data.validator.mark">
                                                <option *ngFor="let opt of validatorMarker" [value]="opt.value" [selected]="opt.value == editNode.data.validator.mark">{{opt.label}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Additonal Options</h6>
                                            <div *ngIf="isItemTemplateSection(editNode.data.standardLineItemId)">
                                                <ng-container *ngIf="doesEntitySubsectionExistForTemplateSection(); else noEntitySubsectionExistsYet">
                                                  <button class="btn btn-link additional-options-item" (click)="addTemplateSpecificEntitySubsection()"><i class="fas fa-sitemap"></i> Add Entity Subsection (for a specific template)</button><br>
                                                </ng-container>
                                                <ng-template #noEntitySubsectionExistsYet>
                                                  <button class="btn btn-link additional-options-item" (click)="addEntitySubSection()"><i class="fas fa-sitemap"></i> Add Entity Subsection</button><br>
                                                </ng-template>
                                                <button class="btn btn-link additional-options-item" (click)="addGenericCalculation(true)"><i class="fas fa-calculator"></i> Add Generic Calculation</button>
                                            </div>
                                            <div *ngIf="isItemEntitySubsection(editNode.data.standardLineItemId)">
                                                <button class="btn btn-link additional-options-item" (click)="addGenericCalculation(false)"><i class="fas fa-calculator"></i> Add Generic Calculation</button>
                                            </div>
                                            <div *ngIf="!editNode.data.isStatic">
                                                <button class="btn btn-link additional-options-item" (click)="removeItemFromWorkingTree(editNode)"><i class="fas fa-trash-alt"></i> Remove from template</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noTemplateItemSelected>
                            <p>Select an item to edit.</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

