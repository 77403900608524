import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '@services/shared-data.service';

@Component({
  selector: 'app-embedded-dscr-analysis',
  templateUrl: './embedded-dscr-analysis.component.html',
  styleUrls: ['./embedded-dscr-analysis.component.scss']
})
export class EmbeddedDscrAnalysisComponent implements OnInit {
  constructor(
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.sharedDataService.hideDefaultNavigation();
    this.sharedDataService.embeddedMode$.next(true);
  }

}
