<div class="tile-container dscr-commentary-tile-container">
  <h4 class='commentary-tile-header-container'>Commentary</h4>
  <textarea
        class="form-control fs-block"
        placeholder="Add Commentary…"
        rows="4"
        [(value)]="commentary"
        (blur)="updateCommentary($event.target.value)"
        maxlength="1000"
        rows="7"
        cols="40"
  >
  </textarea>
</div>
