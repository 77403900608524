import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import { AlertService } from '../../../../services/alert.service';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { EMAIL_PATTERN, NAME_PATTERN, PASSWORD_PATTERN } from '../../../../utils/constants';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';

@Component({
  selector: 'app-user-add',
  templateUrl: 'add.component.html',
  styleUrls: ['add.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class UserAddComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY

  userForm: FormGroup;
  submitted = false;
  submitAttempt = false;
  subsArr$: Subscription[] = [];
  image: any;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private alertService: AlertService,
  ) {

  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      admin: false,
      active: true,
    });
  }

  /**
   *
   * @param {string} field
   * @returns {boolean}
   */
  isFieldInvalid(field: string): boolean {
    return (
      (this.userForm.get(field).invalid && this.userForm.get(field).dirty) ||
      (this.userForm.get(field).invalid && this.submitAttempt)
    );
  }

  /**
   *
   * @param user
   * @param {boolean} isValid
   */
  save(user: any, isValid: boolean) {
    this.submitAttempt = true;
    if (!isValid || this.submitted) {
      return;
    }
    this.submitted = true;
    user.email = user.email.trim();
    user.name = user.name.trim();
    user.id = null;

    this.subsArr$.push(this.userService.addUser(user)
      .subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, true);
          this.alertService.success('User added successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }
}
