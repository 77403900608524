import { NgModule } from '@angular/core';
import { CurrencyComponent } from './currency.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    CurrencyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    HttpClientModule,
    PipesModule,
  ],
  exports: [CurrencyComponent],
  providers: [],
})
export class CurrencyModule {
}
