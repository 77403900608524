<div class="modal__header">
    <div class="modal__title">
        Processing Status
    </div>
</div>

<div class="modal__content">
    <div *ngIf="reviewQueueItem">
        <div class="row">
            <div class="col-sm-3 document-icon">
                <app-file-icon class="file-type-icon" size="75px"
                               fileSuffix="{{reviewQueueItem.fileSuffix}}"></app-file-icon>
            </div>
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-sm-12">
                        <p *ngIf="!error && !isTakingAWhile" class="alert alert-info"><b>This may take a few
                            moments.</b> Our algorithm is crunching away. You will automatically be taken to the next
                            step once it's done.</p>
                        <div *ngIf="error">
                            <div class="alert alert-danger">
                                <h4>Whoops! Something went wrong.</h4>
                                {{error}}
                            </div>
                        </div>
                        <div *ngIf="isTakingAWhile">
                            <div class="alert alert-info">
                                <h4>This might take a minute...</h4>
                                This document is a larger file than most. It may take a minute or two to crunch the
                                numbers. Go back to the company page and access your document through the tracker in the
                                top right.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="!isTakingAWhile" class="col-sm-9">
                        <strong>Status:</strong> {{reviewQueueItem.status}}<br/>
                        <strong>Current Step:</strong> {{reviewQueueItem.step || 'Processing'}}
                    </div>
                    <div class="col-sm-3">
                        <div *ngIf="loading" class="loader"></div>
                    </div>
                    <div *ngIf="companyUuid !== null" class="col-sm-12">
                        <div class="top-padding">
                            <a class="btn btn-default" (click)="redirectToCompanyPage()">Back To Company Page</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal__footer">
</div>
