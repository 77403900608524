import {PDFDocument, PDFPage} from "pdf-lib";

export async function mergePdfs(pdfsToMerge: ArrayBuffer[]): Promise<ArrayBufferLike> {
  // https://stackoverflow.com/questions/60234692/how-to-merge-two-pdf-files-using-jspdf
  const mergedPdf: PDFDocument = await PDFDocument.create();

  const pdfsAsPageArrays: PDFPage[][] = await Promise.all(
    pdfsToMerge.map(async (arrayBuffer) => {
      const pdf: PDFDocument = await PDFDocument.load(arrayBuffer);
      return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    }));

  pdfsAsPageArrays.forEach((pageArray: PDFPage[]) => {
    pageArray.forEach((page: PDFPage) => {
      mergedPdf.addPage(page);
    });
  });

  return (await mergedPdf.save()).buffer;
}
