<div class="background">
    <div class="content">
        <div class="custom-card">
            <div class="custom-card-header">
                <div class="title float-start">
                    LOGIN
                </div>
                <div class="float-end logo">
                    <img src="../../../../assets/images/Numerated-2023-logo.svg"/>
                </div>
            </div>
        </div>
    </div>
</div>
