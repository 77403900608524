import { Pipe, PipeTransform } from '@angular/core';
import { QUARTERLY, MONTHLY, ANNUALLY, SEMI_ANNUALLY, TTM, PERIOD_TYPES_STRING, FISCAL_YTD } from '../utils/constants';

@Pipe({name: 'reportingPeriod'})
export class ReportingPeriodPipe implements PipeTransform {
  /**
   *
   * @param {string} value
   * @returns {any}
   */
  transform(value: string): any {
    if (!value) {
      return '';
    }

    const periodType = value.trim();

    let result = '';
    if (periodType === MONTHLY) {
          result = PERIOD_TYPES_STRING[MONTHLY];
    } else if (periodType === QUARTERLY) {
          result = PERIOD_TYPES_STRING[QUARTERLY];
    } else if (periodType === SEMI_ANNUALLY) {
          result = PERIOD_TYPES_STRING[SEMI_ANNUALLY];
    } else if (periodType === ANNUALLY) {
          result = PERIOD_TYPES_STRING[ANNUALLY];
    } else if (periodType === FISCAL_YTD) {
          result = PERIOD_TYPES_STRING[FISCAL_YTD];
    } else if (periodType === TTM) {
      result = PERIOD_TYPES_STRING[TTM];
    } else {
      result = '';
    }

    return result;
  }
}
