import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PageImages} from "@models/document-file";
import {Box} from "@components/main/review/human-validation/models/box";

const zoomIncrement = 0.2;
const minimumImgScale = 0.3;

@Component({
  selector: 'app-table-drawing-page',
  templateUrl: './table-drawing-page.component.html',
  styleUrls: ['./table-drawing-page.component.scss']
})
export class TableDrawingPageComponent implements OnInit, OnChanges {
  @Input() allowOnlyOneBox: boolean
  @Input() pageImages: Array<PageImages>;

  // the currentBoxesByPage structure will always include an entry for each image in the document, therefore NOT always
  // matching the length of the possibly filtered pageImages array.
  @Input() currentBoxesByPage?: Array<Array<Box>> = [];
  @Input() showBoxDrawing?: boolean = true;
  @Output() showBoxDrawingChange = new EventEmitter<boolean>();
  @Output() boxesChanged = new EventEmitter<{ boxes: Array<Box>, activePageIndex: number }>();

  // we need to maintain two separate indexes to identify the active page because the pageImages array can be filtered
  // to include only visible images in certain scenarios:
  activePageTrueIndex: number = 0; // maintains the true page number (position in the complete list of images)
  activePageIndexInCurrentPageImagesArray: number = 0; // index in the current (filtered) page images array
  boxDrawingWidth: any;
  imgScale = 1;
  MATH = Math;

  ngOnInit() {
    this.setBoxDrawingWidth();
    if (this.pageImages) {
      this.activatePage(isNaN(this.pageImages[0]?.pageIndex) ? this.pageImages[0].pageIndex : 0)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('pageImages')) {
      if (!changes.pageImages.firstChange && changes.pageImages.currentValue?.length !== changes.pageImages.previousValue?.length) {
        // the image list has been filtered, adjust the currently selected box drawing page if necessary
        this.setActivePageIndexBasedOnTrueIndex();
      }
    }
  }

  setBoxDrawingWidth() {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.boxDrawingWidth = (windowWidth / 2) - 45 // offset for column padding
  }

  activatePage(pageIndex) {
    this.activePageTrueIndex = pageIndex;
    this.setActivePageIndexBasedOnTrueIndex();
    this.showBoxDrawing = true;
    this.showBoxDrawingChange.emit(true);
  }

  setActivePageIndexBasedOnTrueIndex() {
    const trueIndex = this.pageImages.findIndex(pi => pi.pageIndex == this.activePageTrueIndex);
    if (trueIndex > -1) {
      this.activePageIndexInCurrentPageImagesArray = trueIndex;
    } else {
      // default to first visible thumbnail
      this.activePageTrueIndex = this.pageImages[0].pageIndex
      this.activePageIndexInCurrentPageImagesArray = 0;
    }
  }

  zoomIn() {
    this.imgScale += zoomIncrement;
  }

  zoomOut() {
    if (this.imgScale > minimumImgScale) {
      this.imgScale -= zoomIncrement;
    }
  }

  trackByFn(_: number, item: PageImages): number {
    return item.pageIndex
  }
}
