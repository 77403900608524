import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-style-guide',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app-style-guide.component.html',
  styleUrls: ['./app-style-guide.component.scss']
})
export class AppStyleGuideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
