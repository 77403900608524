<input #uploadInput class="hide" type="file" ng2FileSelect
       [uploader]="uploader"
       accept="image/x-png,image/gif,image/jpeg,.pdf,.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"/>

<button class="btn btn-{{style}}"(click)="openFilePicker()" [ngStyle]="{'background-color': buttonColor,
    'border-color': buttonColor,
    'border-radius': btnBorderRadius,
    'padding-right': btnBorderRadius === 0 ? '0px' : '40px',
    'padding-left': btnBorderRadius === 0 ? '0px' : '40px',
    'color': textColor !== null ? textColor : '',
    'font-weight': 'bold'
}">
    {{text}}
</button>
