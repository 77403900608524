/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentFileCreateStatements } from './documentFileCreateStatements';


export interface DocumentFileCreate { 
    readonly uuid?: string;
    readonly status?: string;
    readonly createdDate?: string;
    /**
     * UUID of the borrower for this file. (see [Borrowers](#tag/Borrowers))
     */
    borrowerUuid: string;
    /**
     * [MIME type](https://developer.mozilla.org/en-US/docs/Glossary/MIME_type) of the file
     */
    mediaType: DocumentFileCreate.MediaTypeEnum;
    /**
     * Used to reference the original filename. Defaults to `document.{MEDIA TYPE FILE EXTENSION}` e.g. fye_2020.pdf
     */
    filename?: string;
    statements?: Array<DocumentFileCreateStatements>;
    readonly uploadUrl?: string;
    /**
     * External ID is used to uniquely identify a record from your system in our system. Must be unique across records.
     */
    externalId?: string;
    customAttributes?: object;
}
export namespace DocumentFileCreate {
    export type MediaTypeEnum = 'application/pdf' | 'application/x-pdf' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'image/png' | 'image/gif' | 'image/jpeg';
    export const MediaTypeEnum = {
        ApplicationPdf: 'application/pdf' as MediaTypeEnum,
        ApplicationXPdf: 'application/x-pdf' as MediaTypeEnum,
        ApplicationVndMsExcel: 'application/vnd.ms-excel' as MediaTypeEnum,
        ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as MediaTypeEnum,
        ImagePng: 'image/png' as MediaTypeEnum,
        ImageGif: 'image/gif' as MediaTypeEnum,
        ImageJpeg: 'image/jpeg' as MediaTypeEnum
    };
}


