import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';


@Component({
  selector: 'app-add-available-items-to-working-template-popover',
  templateUrl: './add-available-items-to-working-template-popover.component.html',
  styleUrls: ['./add-available-items-to-working-template-popover.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class AddAvailableItemsToWorkingTemplatePopoverComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() sectionAvailable = [];
  @Output() callback = new EventEmitter<number[]>();

  subsArr$: Subscription[] = [];
  confirmButtonText = 'Add To Working Template';
  cancelButtonText = 'Cancel';
  title = 'ADD TO WORKING TEMPLATE'
  description = 'Select the section to add selected line items under'
  selectedSections = [];

  ngOnInit() {
  }

  emitSelectedSections() {
    this.callback.emit(this.selectedSections);
    this.popup.close();
  }

  addOrRemoveItemToSelectedSections(item, isSelected) {
    if (!isSelected) {
      this.selectedSections.push(item.standardLineItemId);
    } else {
      this.selectedSections = this.selectedSections.filter(id => id !== item.standardLineItemId);
    }
  }
}
