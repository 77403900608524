import {map} from 'rxjs/operators';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {DocumentFileService} from '../../../../services/document-file.service';
import {ReviewQueueService} from '../../../../services/review-queue.service';
import {DocumentFile} from '../../../../models/document-file';
import {SpreadingState} from '../state/manager';
import {ReviewQueueItem} from '../../../../models/review-queue-item';
import {Cell, Row} from '../models/models';
import {Statement} from '../../review/human-validation/models/statement';
import {StatementService} from '../../../../services/statement.service';
import {SpreadingTemplateService} from '../../../../services/spreading-template.service';
import {SpreadingMapTemplates} from '../../../../models/standard-item-templates';
import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  DYNAMIC_ADJUSTMENT_STATEMENT,
  INCOME_STATEMENT
} from '../../../../utils/constants';
import {StatementDataCache} from './cache';

@AutoUnsubscribe('subsArr$')
@Injectable()
export class StatementDataService {
  subsArr$: Subscription[] = [];

  constructor(
    private _documentFileService: DocumentFileService,

    // NOTE: the functionality from here should be moved to a new service
    // called "TemplateService" or something.
    private _reviewQueueService: ReviewQueueService,
    private _statementService: StatementService,
    private _spreadingTemplateService: SpreadingTemplateService,

  ) { }

  loadState(statement: Statement, cache: StatementDataCache = null): Promise<SpreadingState> {
    return Promise.all([
      this.loadTemplates(cache, statement.spreadingTemplateId),
      this.loadDocumentFile(statement.documentFileId, cache),
      this.loadReviewQueueItem(statement.documentFileId, cache),
    ]).then(results => {
      return this.loadStateSuccess(results, statement, cache);
    }).catch((err) => {
      console.log('err', err)
      throw err
    });
  }

  loadStateSuccess(results: any, statement: Statement, cache: StatementDataCache): SpreadingState {
      let state: SpreadingState;
      let template: Array<Row>;

      if (statement.statementType === INCOME_STATEMENT && !!results[0].incomeStatementTmpl) {
        template = results[0].incomeStatementTmpl;
      } else if (statement.statementType === BALANCE_SHEET && !!results[0].balanceSheetTmpl) {
        template = results[0].balanceSheetTmpl;
      } else if (statement.statementType === CASH_FLOW_STATEMENT && !!results[0].cashFlowStatementTmpl) {
        template = results[0].cashFlowStatementTmpl;
      }
      else {
        // Empty template. "Cannot spread"
        template = [];
      }
      state = SpreadingState.newFromStatement(statement, template);
      state.reviewQueueItem = results[2];
      state.documentFile = results[1];

      if (cache) {
        cache.spreadingTemplatesBySpreadingTemplateId.set(statement.spreadingTemplateId, results[0]);
        cache.reviewQueueItemIDsByFileId.set(state.documentFile.id, state.reviewQueueItem.id);
      }
      return state;

  }

  loadDocumentFile(fileId: number, cache: StatementDataCache = null): Promise<DocumentFile> {
    if (cache && cache.documentFilesById.has(fileId)) {
      return Promise.resolve(cache.documentFilesById.get(fileId));
    }
    return this._documentFileService.listDocumentFiles(null, fileId, true).pipe(map(data => {
      return data[0];
    })).toPromise();
  }

  loadStatementsForFile(fileId: number, cache: StatementDataCache = null): Promise<Array<Statement>> {
    if (cache && cache.relatedStatementsByFileId.has(fileId)) {
      return Promise.resolve(cache.relatedStatementsByFileId.get(fileId));
    }
    return this._documentFileService.loadStatements(fileId).toPromise().then(statements => {
      if (cache) {
        cache.relatedStatementsByFileId.set(fileId, statements);
      }

      return statements;
    });
  }

  loadStatementById(statementId): Promise<Statement> {
    return this._statementService.loadStatement(statementId).toPromise();
  }

  loadStatementByUuid(statementUuid): Promise<Statement> {
    return this._statementService.loadStatementByUuid(statementUuid).toPromise();
  }

  /**
   * WARNING!!! We will need to change this to actually be by borrower once we
   * implement custom templates`
   */
  loadTemplates(cache: StatementDataCache = null, spreadingTemplateId: number): Promise<SpreadingMapTemplates> {
    if (cache && cache.spreadingTemplatesBySpreadingTemplateId.has(spreadingTemplateId)) {
      return Promise.resolve(cache.spreadingTemplatesBySpreadingTemplateId.get(spreadingTemplateId));
    }
    return this._spreadingTemplateService.getSpreadingTaxonomyById(spreadingTemplateId).toPromise();
  }

  loadReviewQueueItem(fileId: number, cache: StatementDataCache = null): Promise<ReviewQueueItem> {
    let retrieveReviewQueueItemPromise: Promise<string>;

    if (cache && cache.reviewQueueItemIDsByFileId.has(fileId)) {
      retrieveReviewQueueItemPromise = Promise.resolve(cache.reviewQueueItemIDsByFileId.get(fileId));
    } else {
      retrieveReviewQueueItemPromise = this._documentFileService.getReviewQueueItemForFileId(fileId).toPromise().then(data => data.response.objects[0].reviewQueueItem);
    }
    return retrieveReviewQueueItemPromise.then((reviewQueueItemId: string) => {
      return this._reviewQueueService.retrieveAndLock(reviewQueueItemId).toPromise();
    });
  }


  save(state: SpreadingState, complete = false, forceSync = false, initialTemplateSelectionHasBeenMade = false, reAutonorm = false, allowDeletingInsertedRows = false): Promise<Statement> {
    state.statement.complete = complete;
    return this._statementService.saveStatement(
      state.statement,
      state.spreadingTemplateId,
      forceSync,
      initialTemplateSelectionHasBeenMade,
      reAutonorm,
      allowDeletingInsertedRows
    );
  }

  changeCellValue(cell: Cell, lineItemId: number, newValue: number) {
    return Promise.resolve(true);
  }

  saveGoalSeekData(state: SpreadingState, row: Row, colIdx: number, documentFileId, solution) {
    return this._statementService.saveGoalSeekData(state, row, colIdx, documentFileId, solution).toPromise();
  }
}
