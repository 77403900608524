<div class="btn-group dropdown multi-select-button-container" role="toolbar">
  <div class="multi-select-button" data-bs-toggle="dropdown" [ngClass]="{'is-disabled': noOptionsToDisplay(), 'clickable': !noOptionsToDisplay()}">
      <span class="button-text">{{buttonText}}</span>
      <span><i class="fa fa-chevron-down"></i></span>
  </div>
  <ul class="dropdown-menu" (click)="$event.stopPropagation()" *ngIf="!noOptionsToDisplay()">
    <li class="popover-menu-item">
      <div class="checkbox-label-container">
        <input class="multi-select-checkbox" type="checkbox" id="{{'selectDeselectAllCheckbox' + checkboxUuid}}" (click)="triggerSelectOrDeselectAll()" [checked]="isSelectDeselectChecked()">
        <label class="item-title select-deselect-title" for="{{'selectDeselectAllCheckbox' + checkboxUuid}}">Select / Deselect All</label>
      </div>
    </li>
    <ng-container *ngFor="let option of multiSelectOptions; let idx = index">
      <li *ngIf="option.hide !== true" class="popover-menu-item">
          <div class="checkbox-label-container">
              <input class="multi-select-checkbox" type="checkbox" id="{{'multiSelectCheckbox' + checkboxUuid + idx}}" (click)="option?.action()" [checked]="option.checked">
              <label class="item-title" for="{{'multiSelectCheckbox' + checkboxUuid + idx}}">{{option.title}}</label>
          </div>
      </li>
    </ng-container>
  </ul>
</div>
