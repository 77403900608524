<div
    *ngIf="isEbitdaGraphDataAvailable()"
    class="tile-container extra-padding"
>
    <div class="row">
        <div class="col-6">
            <h4>EBITDA</h4>
        </div>
        <div class="col-6">
            <div class="align-right">
                <h3>{{currencySymbol}} {{value}}</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            {{periodType}} | {{mostRecentStatementDate}}
        </div>
        <div class="col-6">
            <div class="align-right">
                <!-- {{percentChange}} YOY -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-bar-with-line-chart
                lineStyle='dash'
                [data]="ebitdaGraphData"
                [currencySymbol]="currencySymbol"
            ></app-bar-with-line-chart>
        </div>
    </div>
</div>
