import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { PortfolioService} from '@services/portfolio.service';
import {Portfolio} from "@components/main/portfolio-management/model";

@Component({
  selector: 'app-extended-side-nav',
  templateUrl: './extended-side-nav.component.html',
  styleUrls: ['./extended-side-nav.component.scss']
})
export class ExtendedSideNavComponent implements OnInit {
  @Input() shouldShowPortfolios = false;
  @Input() shouldDisplayManualReview = false;
  @Input() isAdmin = false;
  @Input() isInternalDeveloper = false;
  @Input() bypassBackendCache;
  @Input() switchOnProfiler;
  @Input() showBorrowerOptions;
  @Input() showSpreadingTemplates;
  @Input() showReporting;
  @Input() showApiKeysOption;
  @Output('toggleSidebar') _toggleSidebar = new EventEmitter();
  @Output('restCommander') _restCommander = new EventEmitter<any>();
  @Output('triggerError') _triggerError = new EventEmitter<any>();
  @Output('cacheCommander') _cacheCommander = new EventEmitter<any>();
  @Output('choosePortfolio') _choosePortfolio = new EventEmitter<any>();
  @Output('chooseBorrower') _chooseBorrower = new EventEmitter<any>();
  @Output('toggleBypassCache') _toggleCachingForDev = new EventEmitter<any>();
  @Output('toggleProfiler') _toggleProfilerForDev = new EventEmitter<any>();

  portfolios: Portfolio[] = [];

  portfolioListIsExpanded = false;

  totalPortfolioCount: number;

  constructor(
    private portfolioService: PortfolioService
  ) {}

  ngOnInit() {
    this._loadPortfolios(10);
  }

  private _loadPortfolios(limit?: number){
    this.portfolioService.listPortfolios(limit).subscribe(data => {
      this.totalPortfolioCount = data?.meta?.total;
      this.portfolios = data?.response?.objects;
    })
  }

  public chooseBorrower(name): void {
    this._chooseBorrower.emit(name);
  }

  public toggleSidebar(): void {
    this._toggleSidebar.emit();
  }

  public restCommander(): void {
    this._restCommander.emit();
  }

  public triggerError(): void {
    this._triggerError.emit();
  }

  public cacheCommander(): void {
    this._cacheCommander.emit();
  }

  public choosePortfolio(portfolio): void {
    this._choosePortfolio.emit(portfolio);
  }

  public toggleBypassCache(): void {
    this._toggleCachingForDev.emit()
  }

  public toggleProfiler(): void {
    this._toggleProfilerForDev.emit()
  }

  public expandPortfolioList(): void {
    this.portfolioListIsExpanded = true;
    this._loadPortfolios();
  }

  public collapsePortfolioList(): void {
    this.portfolioListIsExpanded = false;
    this.portfolios = this.portfolios.slice(0, 10);
  }
}
