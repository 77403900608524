import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, ReplaySubject } from 'rxjs';
import { Company } from '../models/company';
import { Algorithm } from '../models/algorithm';
import { Document } from '../models/document';
import { LineItemObject } from '../models/line-item';
import { DataView } from '../models/dataview';
import { EnvFromHostname } from '../models/env-from-host';
import { BYPASS_CACHE, SWITCH_ON_PROFILER } from '../utils/constants';
import { CommonFunctions } from '@utils/common-functions';

@Injectable()
export class SharedDataService {
  /**
   * stores selected elements to be dragged
   * @type {Array}
   */
  selectedDraggable = [];
  draggingData = [];
  documentType: DocumentType;
  differenceDocumentItems$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  currentDocument$: BehaviorSubject<Document> = new BehaviorSubject(null);
  formulaOptions$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  /**
   * @deprecated
   */
  currencyDigitsToTrim$: BehaviorSubject<any> = new BehaviorSubject(0);
  roundToThousands$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currency$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  roundToThousandsDefaultBeenSet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  company$: BehaviorSubject<Company> = new BehaviorSubject(null);
  bankId$: BehaviorSubject<string> = new BehaviorSubject(null);
  currentAlgorithm$: Subject<Algorithm> = new Subject();
  bypassBackendCache$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(CommonFunctions.tryGetLocalStorageItem(BYPASS_CACHE) === 'true');
  switchOnProfiler$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(CommonFunctions.tryGetLocalStorageItem(SWITCH_ON_PROFILER) === 'true');

  /* subject that is used to trigger data updates in Files component when status of file changes */
  fileStatusSubject$: Subject<string> = new Subject();
  refreshToken$: Subject<boolean> = new Subject();

  refreshDates$: Subject<void> = new Subject<void>();
  portfolioFilter$: BehaviorSubject<any> = new BehaviorSubject<any>(' ');
  numParsingTrackerDocuments$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  isValidationMode = false;
  selectedRawValidation: LineItemObject;
  dataView: DataView;
  currentSidebar: 'regular' | 'borrowers' = 'regular';

  shouldShowTopNav = true;
  shouldShowSidebar = true;

  calculateSelectedReportingPeriod$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  documentStatusChanges$: BehaviorSubject<any> = new BehaviorSubject(null);
  pageHeaderTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  shouldShowManualReviewSidebar = false;
  isSentryInitialized = false;
  appReturnUrl: BehaviorSubject<string> = new BehaviorSubject('customers');

  // Allows child components to be notified when there's any click that gets bubbled up to the main app component
  appClicks$: Subject<void> = new Subject<void>();

  embeddedMode$ = new BehaviorSubject(false);
  embeddedWorkflow$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {
  }


  hideDefaultNavigation(): void {
    this.setTopAndSideNavDisplay(false);
  }

  showDefaultNavigation(): void {
    this.setTopAndSideNavDisplay(true);
  }

  // shows/hides the default top navigation for non digitization pages
  setTopAndSideNavDisplay(value: boolean) {
    this.shouldShowTopNav = value;
    this.shouldShowSidebar = value;
  }

  setHILExitUrl(previousUrl: string) {
    // if we have a previousUrl and we are not already in "review mode"
    // TODO: create some consistency around the urls that are part of the HIL pipeline
    if (previousUrl && !['/review/', '/statements/'].some((pathPrefix) => previousUrl.startsWith(pathPrefix))) {
      this.appReturnUrl.next(previousUrl);
    }
  }

}
