import { Deserializable } from './deserializable';
import { DocumentFile } from './document-file';

export interface ReviewQueueItemInterface {
  id: string;
  status: string;
  step: string;
  bankId: string;
  companyId: number;
  document: any;
  taskToken: string;
  jobPayload: any;
  documentFileId: number;
  reviewed: boolean;
  suffix: string;
  fileSuffix?: string;
  originalFileUrl?: string;
  fileKey?: string;
  exceptionClass?: string;
  exceptionMessage?: string;
  userError?: boolean;
  lastLockedDate?: string;
  processingJobPayload?: any;
  currency: string;
  roundedToThousands: boolean;
  roundedToMillions: boolean;

  // Sometimes present on the returned payload
  lockKey?: string;
  lastLockedBy?: string;
  lockAquired?: boolean;
  embeddedWorkflow?: any;
}

export interface DocumentModelInterface extends ReviewQueueItemInterface {
  pageImgUrls?: string[];
}

export class ReviewQueueItem implements Deserializable  {
  id: string;
  status: string;
  step: string;
  bankId: string;
  companyId: number;
  document: any;
  taskToken: string;
  jobPayload: any;
  documentFileId: number;
  reviewed: boolean;
  suffix: string;
  fileSuffix?: string;
  originalFileUrl?: string;
  fileKey?: string;
  exceptionClass?: string;
  exceptionMessage?: string;
  userError?: boolean;
  lastLockedDate?: string;
  processingJobPayload?: any;
  pageCount?: number;

  // Sometimes present on the returned payload
  lockKey?: string;
  lastLockedBy?: string;
  lockAquired?: boolean;
  embeddedWorkflow?: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export enum DocumentParsingStepStatus {
  Queued = 'queued',
  Active = 'active',
  Done = 'done',
}

export class DocumentParsingStep {
  beforeLabel: string;
  afterLabel: string;
  status: DocumentParsingStepStatus;
  showReview: boolean;
  end: boolean

  constructor(beforeLabel: string, afterLabel: string = '', showReview = false, end = false) {
    this.beforeLabel = beforeLabel;
    this.afterLabel = afterLabel;
    this.showReview = showReview;
    this.end = end;
    this.status = DocumentParsingStepStatus.Queued;
  }
}
