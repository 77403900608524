import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges, HostListener, IterableDiffers, DoCheck, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DataViewColumn, DataViewRow, DataViewRowFormattingOptions } from '@models/dataview';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import * as _ from 'underscore';
import { Footnote } from '@models/footnote';
import { DocumentFileService } from '@services/document-file.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { Router } from '@angular/router';
import { LaunchDarklyService } from '@services/launchdarkly.service';
import { BuilderFrame, STATEMENT_BUILDER_OPERATORS } from '../model';
import { NumberFormattingService } from '@services/number-formatting.service';
import { ProjectionHelperService } from '@services/projection-helper.service';
import {DEFAULT_CURRENCY} from "@utils/constants";

@Component({
  selector: 'app-builder-financials-table',
  templateUrl: './builder-financials-table.component.html',
  styleUrls: ['./builder-financials-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe('subsArr$')
export class BuilderFinancialsTableComponent implements OnInit {
  subsArr$: Subscription[] = [];
  @Input() roundToThousands: boolean;
  @Input() rows: Array<DataViewRow> = [];
  @Input() columns: Array<DataViewColumn> = [];
  @Input() hiddenRowIndices: Set<number>;
  @Input() hiddenColumnIndices: Set<string>;
  @Output() columnHidden = new EventEmitter<string>();
  @Input() statementType: string;
  @Input() dollarSign: boolean;

  @Input() includedColumns: {[key: string]: string} = {};
  @Input() includedFrames: Array<BuilderFrame>;
  @Input() projectionScenarioTypeLabelOverride = 'Projection';
  @Output() includeColumn = new EventEmitter<DataViewColumn>();

  selectedRow = -1;
  selectedColumn = -1;
  displayRows: Array<DataViewRow> = [];
  iterableDiffer: any;
  showToolTip;
  toolTipPixelOffset = 120;
  isSourceStatement = false;
  allowHideZeros = false;
  visibleColumns: Array<DataViewColumn> = [];

  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private _documentFileService: DocumentFileService,
    private _iterableDiffers: IterableDiffers,
    private _router: Router,
    private _changeDetector: ChangeDetectorRef,
    private _featureFlags: LaunchDarklyService,
    private _numberFormattingService: NumberFormattingService,
    public _projectionHelperService: ProjectionHelperService,
  ) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  // Stop the highlighting when we click outside the element
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.selectedRow = -1;
    this.selectedColumn = -1;
  }

  ngOnInit(): void {
    this.isSourceStatement = this.isASourceStatement();

    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.setFlags();
    }));

    this.setFlags();
  }

  scrollTableRight() {
    setTimeout(() => {
      const columns = document.querySelectorAll('.statement-header:last-child');
      if (columns) { columns[columns.length - 1].scrollIntoView(); }
    }, 1);
  }

  getBuilderFrameForColum(column: DataViewColumn) {
    const column_key = column.toKeyString();
    return this.includedFrames.find((bf) => bf.column.toKeyString() === column_key);
  }

  getSelectLabel(column: DataViewColumn) {
    const currentBuilderFrame = this.getBuilderFrameForColum(column);
    switch ((currentBuilderFrame) ? currentBuilderFrame.operator : '') {
      case STATEMENT_BUILDER_OPERATORS.add:
        return '(+)'
      case STATEMENT_BUILDER_OPERATORS.subtract:
        return '(-)'
      default:
        return 'Select'
    }
  }

  hideTip() {
    this.showToolTip = false;
  }

  setHiddenColumns() {
    for (let j = 0; j < this.columns.length; j++) {
      if (this.hiddenColumnIndices.has(this.columns[j].toKeyString())) {
        this.columns[j].isHidden = true;
      } else {
        this.columns[j].isHidden = false;
      }
    }
  }

  /**
   * Add attributes the cells, rows, columns, etc so we don't have to figure them out every time we render the view
   */
  prerenderData() {
    this.visibleColumns = [];
    if (this.allowHideZeros) {
      this.runHiddenRowRules();
    }
    this.columns.forEach((col, colIdx) => {
      // col.statementOptions = this.statementOptionsForColumn(col);
      if ((col.cellFormat.textFormat === 'PERCENT') ||
        (this.hiddenColumnIndices.has(col.toKeyString()))) {
        col.isHidden = true;
      } else {
        col.isHidden = false;
      }
      if (!col.isHidden) {
        this.visibleColumns.push(col);
      }

      col.cells.forEach((cell, cellIdx) => {
        const row = this.rows[cellIdx];
        cell.formattedValue = this.formatItem(row, col, cellIdx, true);

        if ((col.cellFormat.textFormat === 'PERCENT') ||
          (this.hiddenColumnIndices.has(col.toKeyString()))) {
          cell.isHidden = true;
        } else {
          cell.isHidden = false;
        }
      })
    });
  }

  formatItem(row: DataViewRow, column: DataViewColumn, idx: number, showOriginal = false): string {
    const value = column.cells[idx].calculatedValue;
    if (row.rowFormat.header) {
      return '';
    }
    if (value) {

      if (!!value.error) {
        switch (value.error) {
          case '#REF':
            if (row.cellFormat.replaceRef) {
              return row.cellFormat.replaceRef;
            } else if (column.cellFormat.replaceRef) {
              return column.cellFormat.replaceRef;
            }
            return '#REF';
          case '#DIV/0':
            if (row.cellFormat.replaceDivzero) {
              return row.cellFormat.replaceDivzero;
            } else if (column.cellFormat.replaceDivzero) {
              return column.cellFormat.replaceDivzero;
            }
            return '#DIV/0';
          default:
            return '#ERR';
        }
      }

      let textFormat = column.cellFormat.textFormat;

      const numDecimals = row.rowFormat.numDecimals;

      if (!textFormat) {
        textFormat = row.cellFormat.textFormat;
      }

      // TODO:
      // Dynamic currency changing and visual display updating is not being supported here. USD is hardcoded as the default
      // currency when generating reports and numbers/currencies will be formatted to USD. This may need to be updated so that
      // currency information can exist in this component and dynamically change/update in the future if this component is not deprecated.
      const options = { showDollarSign: this.dollarSign, isRoundedToThousands: this.roundToThousands, row: this.rows, currency: DEFAULT_CURRENCY };
      return this._numberFormattingService.formatNumber(value.value, textFormat, numDecimals, options);

    }
    return '-';
  }

  getPercent(column: DataViewColumn, idx: number, isHeader: boolean, cellPropertyName: string) {
    if (isHeader) {
      return '';
    }
    const cell = column.cells[idx];
    if (cell[cellPropertyName] && cell[cellPropertyName].value !== null && cell[cellPropertyName].value !== undefined) {
      return (cell[cellPropertyName].value * 100).toFixed(1).toString() + '%';
    } else {
      return '-';
    }
  }

  getTrend(column: DataViewColumn, idx: number, isHeader: boolean) {
    return this.getPercent(column, idx, isHeader, 'trendValue');
  }

  getCommonSize(column: DataViewColumn, idx: number, isHeader: boolean) {
    return this.getPercent(column, idx, isHeader, 'commonSizedValue');
  }

  runHiddenRowRules(): void {
    this.rows.forEach((row, rowIdx) => {
      let wasNonZeroFound = false;
      this.columns.forEach((col) => {
        if (col.cells[rowIdx].calculatedValue && col.cells[rowIdx].calculatedValue.value !== 0) {
          wasNonZeroFound = true;
        }
      });

      if (!wasNonZeroFound && row.rowFormat && !row.rowFormat.header && !row.rowFormat.bold && (row.rowFormat.valueSource !== 'CalculatedItem') ) { // if all zeros and not bold and not a calculation, hide due to zeros
        this.rows[rowIdx].rowFormat['isHiddenDueToZeros'] = true;
      } else {
        this.rows[rowIdx].rowFormat['isHiddenDueToZeros'] = false;
      }
    });
  }

  // making sure item has expected value to not blow up
  isItemAvailable(column: DataViewColumn, idx: number): boolean {
    if (idx >= 0 && column && column.cells &&
      column.cells[idx] && column.cells[idx].calculatedValue) {
      return true;
    }
    return false;
  }

  itemFootnote(column: DataViewColumn, idx: number) {
    const value = null;
    return value;
  }

  itemValue(column: DataViewColumn, idx: number) {
    let value;
    if (this.isItemAvailable(column, idx)) {
      value = column.cells[idx].calculatedValue.value;
    } else {
      value = 0;
    }
    return value;
  }

  originalItemValue(column: DataViewColumn, idx: number) {
    let value;
    if (this.isItemAvailable(column, idx)) {
      value = column.cells[idx].calculatedValue.originalValue;
    } else {
      value = 0;
    }
    return value;
  }

  selectedCell(rowIdx: number, colIdx: number) {
    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
  }

  getRowFormatClass(rowFormat: DataViewRowFormattingOptions, idx: number): string {
    if (rowFormat.isHiddenDueToZeros) {
      return 'hidden';
    }

    if (this.hiddenRowIndices.has(idx)) {
      return 'hidden';
    }

    if (!rowFormat) {
      return '';
    }

    if (rowFormat.header) {
      return 'header';
    }

    if (rowFormat.bold) {
      return 'bold'
    }

    return '';
  }

  toggleRow(evt = null, idx: number) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  hideRow(evt = null, idx: number) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  hideColumn(evt = null, idx: number) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    const idxStr = this.columns[idx].toKeyString();
    this.columnHidden.emit(idxStr);
  }

  selectCell(row: DataViewRow, column: DataViewColumn, rowIdx: number, colIdx: number) {
    if (this.selectedRow === rowIdx && this.selectedColumn === colIdx) {
      this.selectedRow = -1;
      this.selectedColumn = -1;
      return;
    }

    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
    return;
  }

  isASourceStatement(): boolean {
    return this.statementType === 'Source Income Statement' || this.statementType === 'Source Balance Sheet' || this.statementType === 'Source Cashflow Statement';
  }

  setFlags() {
    this.allowHideZeros = this._featureFlags.flags['hide-zeros-in-financials'];
    this.prerenderData();
  }

  trackRow(index, row) {
    return index;
  }

  trackColumn(index, column) {
    return index;
  }

  onColumnSelectClick(column) {
    this.includeColumn.emit(column);
  }
}

