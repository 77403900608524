import { Deserializable } from '../../../../../models/deserializable';

export class Box implements Deserializable {
  x1: number;
  y1: number;
  x2: number;
  y2: number;

  /**
   * Keeps track of the size of the page upon which this
   * box was drawn so we can determine proportions for rendering
   */
  pageWidth: number;
  pageHeight: number;

  // For UI to keep track of whether the box is being edited
  active = false;

  selected = false;

  pageNumber: number;

  deserialize(data): this {
    // From the API format
    if (data.hasOwnProperty('ulc')) {
      this.x1 = data.ulc.x;
      this.y1 = data.ulc.y;
      this.x2 = data.urc.x;
      this.y2 = data.llc.y;
      this.pageHeight = data.pageSize.height;
      this.pageWidth = data.pageSize.width;
      this.pageNumber = data.pageNumber;
    } else {
      Object.assign(this, data);
    }

    return this;
  }

  copy(): Box {
    return new Box().deserialize(this);
  }

  width(): number {
    return this.x2 - this.x1;
  }

  height(): number {
    return this.y2 - this.y1;
  }

  resize(pageWidth: number, pageHeight: number) {
    if (pageWidth === null || pageHeight === null) {
      return;
    }
    const widthProportion = pageWidth / this.pageWidth;
    const heightProportion = pageHeight / this.pageHeight;
    this.x1 = this.x1 * widthProportion;
    this.x2 = this.x2 * widthProportion;
    this.y1 = this.y1 * heightProportion;
    this.y2 = this.y2 * heightProportion;
    this.pageWidth = pageWidth;
    this.pageHeight = pageHeight;
  }

  /**
   * Serializes the box so it can be consumed by the back end
   */
  serialize() {
    return {
      'ulc': {
        'x': this.x1,
        'y': this.y1,
      },
      'urc': {
        'x': this.x2,
        'y': this.y1,
      },
      'llc': {
        'x': this.x1,
        'y': this.y2,
      },
      'lrc': {
        'x': this.x2,
        'y': this.y2
      },
      'pageSize': {
        'width': this.pageWidth,
        'height': this.pageHeight,
      },
      'pageNumber': this.pageNumber,
    }
  }
}
