import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SharedDataService } from '../../../../services/shared-data.service';
import { ReportingService, Item } from '../../../../services/reporting.service';
import { ReportColumn, Report } from '../../../../models/report';
import { AlertService } from '../../../../services/alert.service';
import { LaunchDarklyService } from '../../../../services/launchdarkly.service';
import { PortfolioService } from '../../../../services/portfolio.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonFunctions } from '../../../../utils/common-functions';
import {USER_GUIDES, TEXT_FORMAT_TYPES, DEFAULT_CURRENCY} from '@utils/constants';
import { UserGuideService } from '@services/user-guide.service';
import { NumberFormattingService } from '@services/number-formatting.service';
import { NgSelectComponent } from '@ng-select/ng-select';

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss']
})

@AutoUnsubscribe('subsArr$')
export class GenerateReportComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  subsArr$: Subscription[] = [];
  availableColumns: Item[] = [];
  reportColumns: ReportColumn[] = [];
  newColumnItem: Item = null;
  groupBy = '';
  onlyLatestFinancials = true;
  preparationTypes: string[] = ['UNQUALIFIED', 'QUALIFIED', 'TAX_RETURN', 'REVIEWED', 'CPA_PREP', 'NTR', 'MANAGEMENT', 'UNKNOWN', 'CALCULATED'];
  reportingIntervals: string[] = ['MONTHLY', 'QUARTERLY', 'ANNUALLY'];
  loading = true;
  loadingReport = false;

  reportHeader: Array<string> = [];
  reportData: Array<Array<string>> = [];
  reportDataFormat: Array<string> = [];
  noDataReturned = false;

  enableAdvancedReporting = false;

  portfolioOptions: any[] = [];
  filteredPortfolios = new Set();
  showAllPortfolios = true;
  reportLink = '';
  page: number = 1;
  total: number;
  limit: number = 100; // Limit of reports displayed per page

  constructor(
    public userGuideService: UserGuideService,
    private _sharedService: SharedDataService,
    private _reportingService: ReportingService,
    private _alertService: AlertService,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private _portfolioService: PortfolioService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private _numberFormattingService: NumberFormattingService,
  ) {}


  ngOnInit() {
    this._sharedService.showDefaultNavigation();
    this._sharedService.pageHeaderTitle$.next('Reporting');
    this.loadColumns();

    this.loadQueryStringReportConfig(true);

    this.subsArr$.push(this._portfolioService.getAllPortfolios().subscribe(portfolios => {
      this.portfolioOptions = portfolios;
    }));
  }

  ngOnDestroy(): void {
    this.userGuideService.remove(USER_GUIDES.ABOUT_PORTFOLIO_REPORTS);
  }

  changePage(page: number) {
    this.page = page;
  }

  columnValueFormatter(item: Item): string {
    return item.value;
  }

  loadColumns() {
    this.loading = true;
    this._reportingService.getAvailableColumns().then((columns: Item[]) => {
      this.availableColumns = columns;
      this.loading = false;
    }).catch(err => {
      console.error(err);
      this._alertService.error(err.message);
    });
  }

  addReportColumn(itemId: number|string, defaultLabel: string) {
    if (typeof(itemId) === 'string' && itemId.startsWith('ru')) {
      itemId = parseInt(itemId.slice(2), 10);
    }
    this.reportColumns.push(new ReportColumn().deserialize({
      itemId: itemId,
      label: defaultLabel,
    }));
  }

  removeColumn(evt, idx) {
    this.reportColumns.splice(idx, 1);
  }

  itemChosenInAutocomplete(item: Item) {
    if (item && item.id) {
      this.addReportColumn(item.id, item.value);
      this.ngSelectComponent.clearModel(); // clears value
    }
  }


  generateUserFriendlyErrorMessage(errorMessage) {
    return errorMessage;
  }

  formatValue(value: string, format: string) {
    if (format === '') {
      return value
    }
    if (!value) {
      return ''
    }

    // TODO:
    // Dynamic currency changing and visual display updating is not being supported here. USD is hardcoded as the default
    // currency when generating reports and numbers/currencies will be formatted to USD. This needs to be updated so that
    // currency information can exist in this component and dynamically change/update in the future.
    const options = { showDollarSign: true, isRoundedToThousands: false, currency: DEFAULT_CURRENCY };
    if (format === TEXT_FORMAT_TYPES.CURRENCY) {
      options['negativeShownAsParentheses'] = false;
    } else {
      options['negativeShownAsParentheses'] = true;
    }

    if (typeof value === 'string') {
      // currently, overrides are coming in as strings
      const numericalValue = parseFloat(value);
      return this._numberFormattingService.formatNumber(numericalValue, format, null, options);
    } else {
      return this._numberFormattingService.formatNumber(value, format, null, options);
    }
  }


  formatReportData() {
    this.reportData.forEach(row => {
      for (let i = 0; i < row.length; i++) {
        row[i] = this.formatValue(row[i], this.reportDataFormat[i])
      };
    });
  }

  generateReport(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.loadingReport = true;
    const report = new Report();
    report.columns = this.reportColumns;
    report.preparationTypes = this.preparationTypes;
    report.reportingIntervals = this.reportingIntervals;
    report.latestFinancialsForBorrower = this.onlyLatestFinancials;
    report.groupBy = this.groupBy;
    // @ts-ignore
    // not sure if there is a way to add element type to sets
    report.portfolioIds = Array.from(this.filteredPortfolios);

    this._reportingService.runReport(report).then((data) => {
      this.loadingReport = false;
      this.reportHeader = data['grid'].shift();
      this.reportDataFormat = data['format'];
      this.reportData = data['grid'];
      this.formatReportData();

      this.saveReportConfigToUrl(report);
      this.total = data.grid.length

      this.userGuideService.add(USER_GUIDES.ABOUT_PORTFOLIO_REPORTS);
    }).catch(err => {
      console.log(err);
      this._alertService.error(this.generateUserFriendlyErrorMessage(err.message));
    });
  }

  processCsvRow(row) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
        };
        let result = innerValue.replace(/"/g, '""');
        const quoteCommaNewlineRegex = /("|,|\n)/g;
        if (result.search(quoteCommaNewlineRegex) >= 0)
            result = '"' + result + '"';
        if (j > 0)
            finalVal += ',';
        finalVal += result;
    }
    return finalVal + '\n';
  }

  setSortOnColumn(evt, col: ReportColumn) {
    evt.preventDefault();
    const direction = evt.target.value;

    this.reportColumns.forEach(c => {
      if (c !== col || direction === '') {
        c.useForSorting = false;
      } else {
        c.useForSorting = true;
        c.sortAsc = (direction === 'ASC');
      }
    })
  }

  exportToCsv(evt) {
    evt.preventDefault();
    const filename = 'report.csv';
    const rows = [this.reportHeader].concat(this.reportData);

    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
        csvFile += this.processCsvRow(rows[i]);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  toggleAllPortfoliosFilter(evt) {
    if (evt) {
      // evt.preventDefault();
      evt.stopPropagation();
    }

    if (this.filteredPortfolios.size > 0) {
      this.filteredPortfolios.clear();
    }
    this.showAllPortfolios = this.filteredPortfolios.size === 0;
  }

  togglePortfolioFilter(evt, portfolio) {
    if (evt) {
      // evt.preventDefault();
      evt.stopPropagation();
    }

    const id = (portfolio == null) ? -1 : portfolio.id;
    if (this.filteredPortfolios.has(id)) {
      this.filteredPortfolios.delete(id);
    } else {
      this.filteredPortfolios.add(id);
    }

    this.showAllPortfolios = this.filteredPortfolios.size === 0;
  }

  saveReportConfigToUrl(report: Report) {
    const encodedReport = btoa(JSON.stringify(report));
    const url = this.router.createUrlTree([], {relativeTo: this.activatedRoute, queryParams: {q: encodedReport}}).toString();
    this.reportLink = `${window.location.origin}${url}`;
    this.location.go(url);
  }

  loadQueryStringReportConfig(execute = false) {
    const base64_report = CommonFunctions.getParameterByName('q');
    if (!base64_report) {
      return;
    }

    const report_config = JSON.parse(atob(base64_report));
    const report = <Report> report_config;
    this.reportColumns = report.columns;
    this.preparationTypes = report.preparationTypes;
    this.reportingIntervals = report.reportingIntervals;
    this.onlyLatestFinancials = report.latestFinancialsForBorrower;
    this.filteredPortfolios = new Set(report.portfolioIds);
    this.showAllPortfolios = this.filteredPortfolios.size === 0;
    this.groupBy = this.groupBy;
    if (execute) {
      this.generateReport(null);
    }
  }

  reset() {
    this.loadQueryStringReportConfig(false);
  }

  copyReportLinkToClipboard() {
    navigator.clipboard.writeText(this.reportLink).then(() => {
      this._alertService.success(`Copied Report Link to clipboard`);
    }, (error) => {
      console.error('Could not copy report link text: ', error)
    })
  }

  columnHasOptions(header) {
    return true;
  }

}
