import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerDirective } from './drawer.directive';
import { DrawerService } from './drawer.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DrawerDirective],
  exports: [DrawerDirective],
  providers: [DrawerService]
})
export class DrawerModule { }
