<div class="modal__content">
    <div class="confirmation-popup-header">
        <i class="fas fa-plus-circle"></i>
        <span> {{title}}</span>
    </div>
    <div class="confirmation-popup-text">
      <span>{{description}}</span><br><br>
      <span class="input-label">Section</span><br>
      <div class="section-selection-container">
        <div *ngFor="let item of sectionAvailable;" class="section-container">
          <div class="section-label-container">
              <input type="checkbox" class="section-checkbox" [(ngModel)]="item.isSelected" [ngModelOptions]="{standalone: true}" (click)="addOrRemoveItemToSelectedSections(item, item.isSelected)">
              <label class="section-label">{{ item.label }}</label>
          </div>
        </div>
      </div>
    </div>

</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="popup.close();">{{cancelButtonText}}</button>
    <button type="button" class="btn btn-primary"
     (click)="emitSelectedSections()" [disabled]="!selectedSections.length">{{confirmButtonText}}</button>
</div>
