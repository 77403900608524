import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { CommonFunctions } from '../../../../utils/common-functions';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { ApiService } from '../../../../services/api.service';
import { SignedUrl } from '../../../../models/signed-url';
import { DeveloperService } from '../../developer.service';

@Component({
  selector: 'app-rest-component',
  templateUrl: './rest.component.html',
  styleUrls: ['./rest.component.css']
})
@AutoUnsubscribe('subsArr$')
export class RestComponent implements OnInit {
  @Input() popup: NgxPopupComponent;

  restForm: FormGroup;
  subsArr$: Subscription[] = [];
  apiUrl = [];
  verb = [];
  apiKey: SignedUrl;
  public loading = false;
  public loadMessage = '';
  private readonly urlPreprendToRemove = 'api/';
  // See api for more details about the profiler query string params
  private profilerTimeQsParam = 'pr';
  private profilerCallinfoQsParam = 'prci';
  private profilerNamespaceQsParam = 'prns';


  constructor(private formBuilder: FormBuilder,
    private developerService: DeveloperService,
    private apiService: ApiService
  ) {

  }

  ngOnInit(): void {
    this.setInitialForm();
  }

  /**
   * Construct form
   */
  setInitialForm(): void {
    this.restForm = this.formBuilder.group({
      endpointurl: [''],
      apiUrl: [],
      verb: [],
      progress: [''],
      response: [''],
      body: [''],
      profileTime: [],
      profileCaller: [],
      namespaceFilter: []
    });

    this.setInitialValues();
    this.loading = false;
  }

  showProfiling(): boolean {
    return CommonFunctions.isLocalEnv();
  }

  /**
   * Populate form
   */
  setInitialValues(): void {
    // Get url endpoint and populate select dropdown
    this.subsArr$.push(
      this.developerService.getUrlList()
        .subscribe(
          data => {
            const urls = [];
            data.response.objects.forEach((url) => {
              url = url.replace(/'/g, '');
              if (url.startsWith(this.urlPreprendToRemove)) {
                url = url.substring(this.urlPreprendToRemove.length);
              }
              if (url) {
                urls.push(url);
              }
            });
            urls.sort();
            this.apiUrl = urls;
          },
          error => {
            console.log(error);
          }
        ),
    );
    // Does not work Set url based on first chosen select,
    // is ok user can choose
    this.updateValues();
  }

  /**
   * Refresh values based on user choice
   */
  updateValues() {
    this.loadMessage = '';
    this.restForm.get('response').setValue('');
    const selected = this.restForm.get('apiUrl').value;
    let setBody = false;
    if (selected) {
      const verb = this.restForm.get('verb').value;
      const endpoint =  `/api/${selected}`;
      if (endpoint === '/api/bank-settings' && verb === 'Patch') {
        this.restForm.get('endpointurl').setValue(endpoint);
        // eslint-disable-next-line
        this.restForm.get('body').setValue('{\r\n\"_portalSettings\": {\r\n\"text\": \"\",\r\n\"color\": \"\",\r\n\"pageTitle\": \"\",\r\n\"supportEmail\": \"\",\r\n\"contactSupportEmail\": \"\"\r\n},\r\n\"_bankLogos\": {\r\n\"ico\": \"\",\r\n\"large\": \"\",\r\n\"small\": \"\",\r\n\"medium\": \"\"\r\n},\r\n\"cname\": \"\",\r\n\"historicalScenarioTypeLabelOverride\": \"\",\r\n\"projectionScenarioTypeLabelOverride\": \"\"}');
        setBody = true;
      } else if (endpoint === '/api/users/cross_tenant_user_role_mapper' && verb === 'Post') {
        this.restForm.get('endpointurl').setValue(endpoint);
        this.restForm.get('body').setValue('{\r\n\"email\": \"\",\r\n\"tenant_name\": \"\",\r\n\"roles\": []\n}');
        setBody = true;
      } else if (endpoint && verb) {
        this.restForm.get('endpointurl').setValue(endpoint);
        this.restForm.get('body').setValue('{}');
        setBody = true;
      }
    }

    if (!setBody) {
      this.restForm.get('body').setValue('');
    }
  }

  addQueryParam(condition: boolean, url: string, param: string): string {
    if (condition) {
      if (url.split('?').length === 2) {
        url += '&';
      } else {
        url += '?';
      }
      url += param;
    }

    return url;
  }

  checkForHerokuDeployCreds(url: string, verb: string, data: any) {
    if (['/api/signed-url/create-demo-api-key', '/api/signed-url/create-api-key'].some((pathPrefix) => url.startsWith(pathPrefix)) && verb === 'Post') {
      const signedUrl = data['response']['objects'][0] as SignedUrl;
      this.apiKey = signedUrl;
    }
  }

  /**
   * Send the http request based on user choice
   * Delegate to apiService to get headers and so on for free
   *
   * @param devactors
   * @param {boolean} isValid
   */
  send(restForm: any, isValid: boolean) {
    this.loading = true;
    this.apiKey = null;

    this.restForm.get('response').setValue('');
    const verb = this.restForm.get('verb').value;
    const body = this.restForm.get('body').value;
    let url = this.restForm.get('endpointurl').value;
    // Remove host and port from url
    url = url.replace( /^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+/, '' );
    const profileByTime = this.restForm.get('profileTime').value;
    const profileByCaller = this.restForm.get('profileCaller').value;
    const namespace = this.restForm.get('namespaceFilter').value;

    url = this.addQueryParam(profileByTime, url, this.profilerTimeQsParam);
    url = this.addQueryParam(profileByCaller, url, this.profilerCallinfoQsParam);
    if (profileByTime || profileByCaller) {
      if (namespace && namespace.trim()) {
        url += '&' + this.profilerNamespaceQsParam + '=' + namespace.trim();
      }
    }

    this.subsArr$.push.apply(this.apiService.send(verb, url, body)
      .subscribe(
        data => {
          this.checkForHerokuDeployCreds(url, verb, data);
          this.restForm.get('response').setValue(JSON.stringify(data));
          this.loadMessage = '';
        },
        error => {
          if (error) {
            const errJson = JSON.stringify(error);
            console.log(errJson);
            this.loadMessage = errJson;
          }
        },
        () => {
        }
      ));
  }
}
