import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  transform(enumObj: Object) {
    const convertedArray: Array<string> = Object.values(enumObj).filter(key => isNaN(+key));
    return convertedArray;
  }

}
