export enum UserPreferenceName {
  INCOME_REPORTED_DATE = 'INCOME_REPORTED_DATE',
  BALANCE_REPORTED_DATE = 'BALANCE_REPORTED_DATE',
}

export enum DocumentType {
  IncomeStatement = 'INCOME_STATEMENT',
  BalanceSheet = 'BALANCE_SHEET',
  Calculation = 'CALCULATION',
  CashFlowStatement = 'CASH_FLOW_STATEMENT',
  TaxReturn = 'TAX_RETURN',
  Other = 'OTHER'
}

export enum SpreadingState {
  Creating = 'CREATING',
  Editing = 'EDITING',
  Validating = 'VALIDATING',
  Deleting = 'DELETING',
}

export enum DocumentFileCreateRequestState {
  Init = 'INIT',
  FileChoosen = 'FILE_CHOOSEN',
  FileUploading = 'FILE_UPLOADING',
  FileUploadError = 'FILE_UPLOAD_ERROR',
  FileUploaded = 'FILE_UPLOADED',
  DocumentFileCreated = 'DOCUMENT_FILE_CREATED',
}


export enum DocumentTypeLabel {
  INCOME_STATEMENT = 'IS',
  BALANCE_SHEET = 'BS',
  CASH_FLOW_STATEMENT = 'CF',
  CALCULATION = 'CV',
}

export enum DataViewRuleType {
  COVENANT = 'COVENANT',
  VALIDATION = 'VALIDATION'
}

export enum FormulaOptionTypeLabel {
  DocumentItem = 'Raw',
  Formula = 'Norm',
  FormulaItem = 'Norm'
}

export enum ResponseTypes {
  Text = 'text',
  Json = 'json',
  Blob = 'blob',
  ArrayBuffer = 'arraybuffer'
}

export enum NormalBalance {
  Credit = 'Credit',
  Debit = 'Debit'
}

export enum CallTypeCategory {
  Spread = 'spread',
  SingleEntityAnalysis = 'single-entity-analysis',
  MultiEntityAnalysis = 'multi-entity-analysis',
}

export enum StatementBuilderMode {
  Options = 'options',
  Consolidate = 'consolidate',
  CalcMissingPeriod = 'calc-missing-period',
  GenerateForecast = 'generate-forecast',
  OriginalStatementBuilder = 'original-statement-builder',
}

export enum GrowthType {
  Fixed = "FIXED",
  Factor = "FACTOR",
  ExactValue = "EXACT_VALUE",
}
