import { ComponentFactoryResolver, Directive, OnInit, ViewContainerRef } from '@angular/core';
import { DrawerService } from './drawer.service';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Drawer } from './drawer';

@Directive({
  selector: '[appDrawer]'
})
@AutoUnsubscribe('subsArr$')
export class DrawerDirective implements OnInit {

  subsArr$: Subscription[] = [];

  constructor(private _viewContainerRef: ViewContainerRef,
              private _drawerService: DrawerService,
              private _componentFactoryResolver: ComponentFactoryResolver) {

  }

  ngOnInit(): void {
    this.subsArr$.push(this._drawerService.data$.subscribe(data => {
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(data.component);

      const currentComponent = this._viewContainerRef.createComponent(componentFactory);

      console.log(currentComponent);
      console.log(currentComponent.instance);
      console.log(<Drawer>currentComponent.instance);
      console.log((<Drawer>currentComponent.instance).data);

      (<Drawer>currentComponent.instance).data = {
        close: () => this.close()
      }
    }));


    this.subsArr$.push(this._drawerService.close$.subscribe(() => {
      this.close();
    }));
  }

  close(): void {
    this._viewContainerRef.clear();
  }
}
