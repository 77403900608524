<div class="popover-wrapper">
    <div class="header" (click)="openAllDocMode()">
        <div class="title">
            <div *ngIf="!isSingleDocMode()">
                <span class="number-circle">{{numDocuments}}</span>
                Pending Documents
            </div>
            <div *ngIf="isSingleDocMode()" class="clickable">
                <span>
                    <i class="fas fa-chevron-left"></i>
                </span>
                Back to Pending Documents
            </div>
        </div>
    </div>
    <div class="content">
        <div *ngIf="numDocuments == 0" class="row">
            <div class="col-12">
                All uploaded documents have been processed!
            </div>
        </div>
        <div *ngIf="!isSingleDocMode() && hasDocuments()">
            <div *ngFor="let documentQueueItem of documentQueueItems; let idx = index">
                <app-parsing-tracker-item
                    [fileName]="documentQueueItem.processingJobPayload.documentFile?.originalDocumentName"
                    [fileSuffix]="documentQueueItem.fileSuffix"
                    [isError]="documentQueueItem.status == 'ERROR'"
                    [step]="documentQueueItem.step"
                    [status]="documentQueueItem.status"
                    [borrowerName]="documentQueueItem.processingJobPayload.company.name"
                    [documentId]="documentQueueItem.id"
                    (openSingleDocMode)="openSingleDocMode(idx)"
                >
                </app-parsing-tracker-item>
            </div>
        </div>

        <div *ngIf="isSingleDocMode() && numDocuments > 0">
            <div class="row">
                <div class="col-12">
                    <app-parsing-tracker-status
                        [error]="currentDocument?.status == 'ERROR'"
                        [errorType]="currentDocument?.exceptionClass"
                        [errorDescription]="currentDocument?.exceptionMessage"
                        [userError]="currentDocument?.userError"
                        [steps]="steps"
                        [fileType]="currentDocument?.fileSuffix"
                        [fileName]="currentDocument?.processingJobPayload.documentFile?.originalDocumentName"
                        [borrowerName]="currentDocument?.processingJobPayload?.company?.name"
                        [step]="currentDocument?.step"
                        (goToDocumentPasswordEntry)="goToDocumentPasswordEntry()"
                    >
                    </app-parsing-tracker-status>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isSingleDocMode()" class="footer">
        <div class="d-flex" *ngIf="showLinkButtons()">
            <ng-container *ngIf="documentIsEligibleForTableDrawing">
              <button class="btn btn-primary" *ngIf="isManualReviewer" (click)="goToTableIdentification()" [disabled]="!isInTableIdentification()" >Identify Tables</button>
              <button class="btn btn-primary" *ngIf="isManualReviewer" (click)="goToTableTabulation()" [disabled]="!isInTableTabulation()" >Adjust Columns</button>
            </ng-container>
            <button class="btn btn-primary" *ngIf="isManualReviewer" (click)="goToManualReview()" [disabled]="!isInReview()" >Review Data</button>
            <button class="btn btn-primary" *ngIf="isSpreader" (click)="goToSpreading()" [disabled]="!isReadyForSpreading()" >Categorize Data</button>
        </div>
    </div>
</div>
