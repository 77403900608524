<div>
    <div class="tile-container proposed-debt-tile-container">
        <div class="row remove-debt-row">
            <div class="col-sm-1 offset-sm-11">
                <i class="fas fa-times clickable" (click)="removeProposedDebt()" title="Remove Proposed Debt"></i>
            </div>
        </div>
        <div class="row">
            <label for="name" class="col-sm-6 control-label">Name</label>
            <div class="col-sm-6">
                <input
                    class="form-control fs-block"
                    type="text"
                    placeholder="Name of this loan"
                    [(value)]="name"
                    (blur)="changeName($event.target.value)"
                />
            </div>
        </div>
        <div class="row">
            <label for="principal" class="col-sm-6 control-label">Principal</label>
            <div class="col-sm-6">
                <input
                    class="form-control fs-block"
                    type="number"
                    [(value)]="principal"
                    (keyup)="changePrincipal($event.target.value)"
                    step="25000"
                />
            </div>

        </div>
        <div class="row">
            <label for="interestRate" class="col-sm-6 control-label">Interest</label>
            <div class="col-sm-6 input-group interest-input-group">
                <input
                    class="form-control fs-block"
                    type="number"
                    [value]="displayInterest | number:'1.0-2'"
                    (valueChange)="displayInterest=$event"
                    (blur)="changeInterest($event.target.value)"
                    (keyup)="changeInterest($event.target.value)"
                    step="0.25"
                />
                <span class="input-group-text">%</span>
            </div>

        </div>
        <div class="row">
            <label for="termMonths" class="col-sm-6 control-label">Amortization (In Months)</label>
            <div class="col-sm-6">
                <input
                    class="form-control fs-block"
                    type="number"
                    min="1"
                    [(value)]="termMonths"
                    (blur)="changeTerm($event.target.value)"
                />
            </div>
        </div>
        <div class="row">
            <label for="amortizationMethod" class="col-sm-6 control-label">Payment Type</label>
            <div class="col-sm-6">
                <select class="form-select"
                        (change)="changeAmortizationMethod($event.target.value)"
                >
                    <option
                        *ngFor="let paymentType of paymentTypes | enumToArray"
                        [selected]="amortizationMethod == paymentType"
                        [value]="paymentType"
                    >{{loanService.displayFriendlyPaymentTypeName(paymentType)}}</option>
                </select>
            </div>
        </div>
        <div *ngIf="!isTermIntAndGreaterThan0()" class="row">
            <div class="col-sm-5">
                <span class="error-text">Term must be number greater than 0</span>
            </div>
        </div>

        <div class="row" *ngIf="isLoanObjectTurnedOn">
            <div class="col-sm-4 offset-sm-8">
                <a (click)="bookLoan()">Book this Loan</a>
            </div>
        </div>
    </div>
</div>
