import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-gauge-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})

export class GaugeChartComponent implements OnChanges, OnInit {
  @Input() min = 0;
  @Input() max = 120;
  @Input() value = 30;
  gaugemap: any = {};

  @ViewChild('chart', { static: true })
  private chartContainer: ElementRef;

  constructor() { }

  ngOnChanges(): void {
    if (!this.value) { return; }

    this.createChart();
  }

  ngOnInit(): void {
    this.createChart();
  }

  onResize() {
    this.createChart();
  }

  private createChart() {
    if (this.value === null) {
      return;
    }
    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll('svg').remove();

    const config = {
      size: 300,
      arcInset: 150,
      arcWidth: 60,

      pointerWidth: 8,
      pointerOffset: 0,
      pointerHeadLengthPercent: 0.9,

      minValue: this.min,
      maxValue: this.max,

      minAngle: -90,
      maxAngle: 90,

      transitionMs: 750,

      currentLabelFontSize: 36,
      currentLabelInset: 20,
      labelFormat: (numberToFormat) => {
        return numberToFormat;
      },

    }

    function configure(configuration) {
      const prop = undefined;
      for (const prop of Object.keys(configuration)) {
         config[prop] = configuration[prop];
      }
    }
    configure(config);

    let foreground, arc, svg, current;

    const oR = config.size - config.arcInset;
    const iR = config.size - oR - config.arcWidth;

    function deg2rad(deg) {
      return deg * Math.PI / 180
    }

    function render(value) {
      current = value;

      const width = 300;
      const height = 150;

      const numPi = deg2rad(Math.floor(value * 180 / config.maxValue - 90));

      // Arc Defaults
      arc = d3.arc()
        .innerRadius(iR)
        .outerRadius(oR)
        .startAngle(deg2rad(-90))


      svg = d3.select(element)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .classed('svg-content-responsive', true)
        .append('g')
        .attr('transform', 'translate(' + config.size / 2 + ',' + config.size / 2 + ')');

      // Append background arc to svg
      const background = svg.append('path')
        .datum({
          endAngle: deg2rad(90)
        })
        .attr('class', 'gaugeBackground')
        .attr('d', arc)

      // Append foreground arc to svg
      foreground = svg.append('path')
        .datum({
          endAngle: numPi // 8 is angule on scale of -90 to 90 in what we're looking at.
        })
        // @ts-ignore
        .style('fill', '30B2B9')
        .attr('d', arc);

      // Display Current value
      current = svg.append('text')
        .attr('transform', 'translate(0,' + -(-config.currentLabelInset + iR / 4) + ')') // Push up from center 1/4 of innerRadius
        .attr('text-anchor', 'middle')
        .style('font-size', config.currentLabelFontSize)
        .text(config.labelFormat(current.toFixed(0)))
    }

    render(this.value);
  }

}

