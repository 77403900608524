import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import { UserService } from '@services/user.service';

@Injectable()
export class CompanyEntitlementResolver implements Resolve<Observable<any>> {
  constructor(
    private _userService: UserService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any>  {
    const companyUuid = route.params.uuid;
    if (this._userService.user.scope.includes('embedded')) {
      return of({'financials': {'page_view_mode': 'full'}})
    } else {
      return this._userService.getUserEntitlementForCompany(companyUuid);
    }
  }
}
