<div *ngIf="isLoading">
    <div class="loader__container">
        <h2>Loading</h2>
        <div class="loader"></div>
    </div>
</div>
<app-locked-takeover
    *ngIf="documentIsLocked"
    [lastLockedBy]="reviewQueueItem.lastLockedBy"
></app-locked-takeover>

<div class="full-container" *ngIf="!isLoading">

    <app-digitization-top-nav
        [document]="reviewQueueItem"
        (saveMethod)="save()"
        (nextMethod)="next()"
    >
        <div>
            <div class="toggler">
                <span>View All Pages</span>
                <i class="fas" (click)="togglePagesShown()"
                   [ngClass]="{ 'fa-toggle-off': !areAllPagesShown, 'fa-toggle-on': areAllPagesShown}"
                ></i>
            </div>
        </div>
        <div>
            <div class="toggler">
                <span>View Selected Page</span>
                <i class="fas" (click)="toggleBoxDrawing()"
                   [ngClass]="{'fa-toggle-off': !isBoxDrawingShown, 'fa-toggle-on': isBoxDrawingShown}"></i>
            </div>
        </div>
        <div>
            <div class="d-flex flex-row align-items-center flex-wrap">
                <label class="currency d-flex align-items-center">Set Currency </label>
                <select class="form-select w-auto" (change)="changeCurrency($event.target.value)">
                    <option *ngFor="let currency of supportedCurrencies" [value]="currency.key"
                            [selected]="currency.key === this.documentFile.currency">
                        {{ currency.key }} ({{ currency.symbol }})
                    </option>
                </select>
            </div>
        </div>
    </app-digitization-top-nav>

    <div class="main-wrapper" #wrapper>
        <ng-container *ngIf="_sharedDataService.shouldShowManualReviewSidebar">
            <app-maximized-sidebar
                [stepHeader]="stepHeader"
                [steps]="steps"
                [tipsBody]="tipsBody"
                [fullGif]="fullGif"
                [title]="'Table Identification'"
                (toggleSidebar)="toggleStepsToSuccess()">
            </app-maximized-sidebar>
        </ng-container>
        <app-table-drawing-page
            [allowOnlyOneBox]="false"
            [pageImages]="pagesToShow"
            [currentBoxesByPage]="tablesByPage"
            [(showBoxDrawing)]="isBoxDrawingShown"
            (boxesChanged)="boxesChanged($event)"
        ></app-table-drawing-page>
    </div>
</div>
