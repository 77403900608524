import {Component, Input, Output, EventEmitter} from "@angular/core";
import {Portfolio, PortfolioActionMenuItems} from "../../model"
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {
  CreateOrEditPortfolioModalComponent
} from "@components/main/portfolio-management/components/create-or-edit-portfolio-modal/create-or-edit-portfolio-modal.component";
import {ConfirmationPopupComponent} from "@components/shared/popups/confirmation/confirmation-popup.component";
import {PortfolioService} from "@services/portfolio.service";
import {AlertService} from "@services/alert.service";

@Component({
  selector: "[portfolio-tr]",
  styleUrls: ["portfolio-table-row.component.scss"],
  templateUrl: "portfolio-table-row.component.html",
})
export class PortfolioTableRowComponent {
  @Input() portfolio: Portfolio;
  @Output() refreshPortfolioList = new EventEmitter<boolean>()
  actionMenuIsOpen = false;
  readonly PortfolioActionMenuItems = PortfolioActionMenuItems;

  constructor(
    private _popupService: NgxPopupService,
    private alertService: AlertService,
    private portfolioService: PortfolioService,
  ) {
  }

  toggleActionMenu(event) {
    this.actionMenuIsOpen = !this.actionMenuIsOpen;
  }

  actionItemClick(portfolio, event, action) {
    switch (action) {
      case PortfolioActionMenuItems.EDIT:
        this.openEditModal(portfolio);
        break;
      case PortfolioActionMenuItems.ADD_BORROWER:
        break;
      case PortfolioActionMenuItems.EXPORT_DATA:
        break;
      case PortfolioActionMenuItems.DELETE:
        this.openDeleteConfirmationModal(portfolio)
    }
    this.actionMenuIsOpen = false;
  }


  openEditModal(portfolio) {
    this._popupService.open({
      componentType: CreateOrEditPortfolioModalComponent,
      inputs: {
        portfolio
      },
      outputs: {
        callback: (wasModalSubmitted: boolean) => {
          if (wasModalSubmitted) {
            this.refreshPortfolioList.emit(true);
          }
        }
      }
    });
  }

  openDeleteConfirmationModal(portfolio){
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: "Are you sure you want to delete this portfolio?",
        text: portfolio.name,
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved){
            this.portfolioService.deletePortfolio(portfolio).subscribe(res => {
              this.alertService.success('Portfolio deleted.');
              this.refreshPortfolioList.emit(true);
            }, error => {
              this.alertService.error("Failed to delete portfolio. Please try again or contact administrator.")
              console.error(error);
            })
          }
        }
      }
    })
  }
}
