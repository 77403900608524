import { Company } from './company';
import { Role } from './role';

export interface User {
  id?: string;
  username?: string;
  bank_id?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  photoUrl?: string;
  companies?: Company[];
  active?: boolean;
  createdDate?: string;
  lastLogin?: string;
  roles?: Role[];
  name?: string;
  admin?: boolean;
  user_email?: string;
  'user_name'?: string;
  user_sub?: string;
  user_tenants?: {};
  img?: any;
  photoFileName?: any;
  internalAdmin?: boolean;
  tenant_name?: string;
  user_permissions: Array<string>;
  scope?: string;
  company_id?: number;
  thirdPartyStatus?: string;
  redisSessionIsExpired?: boolean;

  hasUserPermission(permissionName): boolean
}

export class AppUser implements User  {
  user_permissions: Array<string> = [];
  scope?: string

  constructor(decoded: any = {}) {
    Object.assign(this, decoded);
  }

  hasUserPermission(permissionName): boolean {
    return this.user_permissions.includes(permissionName);
  }
}

export class Authenticate {
  username: string;
  password: string;

  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}
