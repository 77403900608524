export class Period {
  label: string;
  value: string;
  year: number;
  interval: string;
  interval_index: number;
  preparationType: string;
  fiscalYearEnd: string;
  scenario: string;

  deserialize(data: any): this {
    Object.assign(this, data);
    return this;
  }
}

