<div class="modal__content">
    <div class="confirmation-popup-header">
        <i class="fas fa-sitemap"></i>
        <span> {{title}}</span>
    </div>
    <div class="confirmation-popup-text">
        <span>{{description}}</span><br><br>
        <div>
            <span class="input-label">Income Statement</span><br>
            <select (change)="setOverrideForStatementType(true, $event.target.value)" class="select-dropdown-box">
                <option
                    *ngFor="let taxonomyItem of incomeStatementMappingOptions"
                    [value]="taxonomyItem.ref"
                    [selected]="incomeStatementCommonSizeDenominatorRef == taxonomyItem.ref">
                    {{ taxonomyItem.label }}
                </option>
            </select>
        </div>
        <div>
            <span class="input-label">Balance Sheet</span><br>
            <select (change)="setOverrideForStatementType(false, $event.target.value)" class="select-dropdown-box">
                <option
                    *ngFor="let taxonomyItem of balanceSheetMappingOptions"
                    [value]="taxonomyItem.ref"
                    [selected]="balanceSheetCommonSizeDenominatorRef == taxonomyItem.ref">
                    {{ taxonomyItem.label }}
                </option>
            </select>
        </div>
    </div>

</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="popup.close();">{{cancelButtonText}}</button>
    <button type="button" class="btn btn-primary" (click)="submit()"
    [disabled]="incomeStatementCommonSizeDenominatorRef === incomeStatementCommonSizeDenominatorRefInput && balanceSheetCommonSizeDenominatorRef === balanceSheetCommonSizeDenominatorRefInput"
    >{{confirmButtonText}}</button>
</div>
