import { Injectable } from '@angular/core';
import { UserGuide } from '@models/user-guide';

@Injectable({
  providedIn: 'root'
})
export class UserGuideService {

  userGuides: Array<UserGuide> = [];

  constructor() { }


  public add(walkthrough: UserGuide): void {
    if (!this.userGuides.includes(walkthrough)) {
      this.userGuides.push(walkthrough);
    }
  }

  public remove(walkthrough: UserGuide): void {
    this.userGuides = this.userGuides.filter(element => element.url !== walkthrough.url);
  }

  public count(): number {
    return this.userGuides.length;
  }

  public clear(): void {
    this.userGuides = [];
  }

}
