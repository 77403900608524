<div class="modal__content">
  <div class="confirmation-popup-header">
      <i class="fas fa-calculator"></i>
      <span>{{BUILD_UCA_EQUATION_MODAL_HEADER}}</span>
  </div>
  <div class="confirmation-popup-text">
      <span>{{(BUILD_UCA_EQUATION_MODAL_HELPER_TEXT) + node.data.label + '.'}}</span>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="item-selection-container">
        <div class="header-text">{{AVAILABLE_TEMPLATE_ITEMS_HEADER}}</div>
        <div class="item-selection">
          <ng-container *ngFor="let item of flattenedTemplateItems">
            <ng-container *ngIf="shouldDisplayItem(item)">
              <div
                [ngClass]="{
                  'indent-0': item.indentLevel === 0,
                  'indent-1': item.indentLevel === 1,
                  'indent-2': item.indentLevel === 2,
                  'indent-3': item.indentLevel === 3,
                  'indent-4': item.indentLevel === 4,
                  'indent-5': ![0, 1, 2, 3, 4].includes(item.indentLevel),
                  'margin-top': item.children && item.children.length !== 0,
                  'item-in-other-uca-equations': item.matchingUcaItemLabel
                }"
                class="available-items-template-name-container"
                [title]="item.matchingUcaItemLabel ? ('Ref already in equation for ' + item.matchingUcaItemLabel): ''"
                (click)="toggleDrawerState(item)"
              >
                <div class="available-item-label-container">
                  <ng-container *ngIf="item.children && item.children.length !== 0">
                    <i class="fas" [ngClass]="{'fa-chevron-down': !item.closedDrawerState, 'fa-chevron-right': item.closedDrawerState }"></i>
                  </ng-container>
                  <label class="available-item-label">
                    <span [ngClass]="{'bold': item.children && item.children.length !== 0, 'italicized': item.className == 'RollupItem' }">{{item.label}}</span>
                  </label>
                  <ng-container *ngIf="item.children && item.children.length === 0 && item.className && ['StandardItem', 'SubItem', 'GenericStandardItem'].includes(item.className)">
                    <input
                      type="checkbox"
                      class="available-item-checkbox"
                      (click)="toggleIsItemInUcaEquation(item)"
                      [checked]="item.isIncludedInUcaEquation"
                      [disabled]="item.matchingUcaItemLabel"
                    >
                  </ng-container>
                </div>
                <ng-container *ngIf="item.children && item.children.length === 0 && item.className && ['StandardItem', 'SubItem', 'GenericStandardItem'].includes(item.className)">
                  <label class="available-item-ref-label">
                    {{item.ref}}
                  </label>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="equation-preview-container">
        <div class="header-text">{{FORMULA_PREVIEW_HEADER_TEXT}}</div>
        <div class="equation-preview">
          {{ ucaEquationPreview === '' ? EQUATION_PREVIEW_BLANK_TEXT : ucaEquationPreview }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal__footer">
  <button type="reset" class="btn btn-link" (click)="popup.close()">{{CANCEL_TEXT}}</button>
  <button type="button" class="btn btn-primary" (click)="saveGeneratedUcaEquation()" [disabled]="ucaEquationPreview === ''">{{SELECT_TEXT}}</button>
</div>
