<div class="content-container row mx-0">
    <div class="col-6 scrollable-column"
         [ngClass]="{
            'col-6': showBoxDrawing,
            'col-12': !showBoxDrawing
         }"
    >
        <div class="section-header">
            <span class="panel-label">Select page</span>
        </div>
        <div class="row" appImgIntersectionObxList>
            <div *ngFor="let page of pageImages; trackBy: trackByFn"
                 class="fs-block fs-exclude thumbnail-div col-3"
                 appImgIntersectionObx
                 #intersection="intersection"
                 [class.active]="page.pageIndex === activePageTrueIndex"
            >
                <app-document-thumbnail
                    *ngIf="intersection.hasIntersected; else imagePlaceholder"
                    [imageWidth]="150"
                    [sourceImageUrl]="page.thumbnail.signedUrl"
                    [page]="page"
                    [boxes]="currentBoxesByPage[page.pageIndex]"
                    (onImageClickEvent)="activatePage($event)"
                >
                </app-document-thumbnail>
                <ng-template #imagePlaceholder>
                    <div class="image-placeholder">
                        ...
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-6 col-container" *ngIf="showBoxDrawing">
        <div class="section-header d-flex justify-content-between">
            <span class="panel-label">Draw box</span>
            <div class="zoom-controls">
                <i class="fas fa-search-plus hover icon" (click)="zoomIn()"></i>
                <i class="fas fa-search-minus hover" (click)="zoomOut()"></i>
                <div class="zoom-percentage">{{ MATH.floor(imgScale * 100) }}%</div>
            </div>

        </div>
        <div class="box-drawer-container" id="box-drawer-scroll-container">
            <app-box-drawer
                *ngIf="pageImages[activePageIndexInCurrentPageImagesArray]?.normalRes?.signedUrl"
                id="selected-page-image"
                [boxes]="currentBoxesByPage[activePageTrueIndex]"
                [sourceImageUrl]="pageImages[activePageIndexInCurrentPageImagesArray].normalRes.signedUrl"
                [allowEdit]="true"
                [inputImageWidth]="boxDrawingWidth * imgScale"
                [onlyAllowOneBox]="allowOnlyOneBox"
                (boxesChanged)="boxesChanged.emit({boxes:$event, activePageIndex: activePageTrueIndex})"
                scrollingContainerElementId="box-drawer-scroll-container"
            >
            </app-box-drawer>
        </div>
    </div>

</div>
