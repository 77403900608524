import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { AnalysisService } from '../../../../../../services/analysis.service';
import { AlertService } from '../../../../../../services/alert.service';
import { AutoUnsubscribe } from '../../../../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ProposedDebt } from '../../../../../../models/proposed-debt';
import { TemplateItem } from '../../../../../../models/template-item';
import { DataViewColumn } from '../../../../../../models/dataview';
import { DebtServiceNewValue } from '../../../../../../models/debt-service-new-value';
import { LaunchDarklyService } from '../../../../../../services/launchdarkly.service';
import { filter } from 'rxjs/operators';
import { Company } from '@models/company';
import { SharedDataService } from '@services/shared-data.service';
import {COMPANY_PAGE_VIEW_MODE, COMPANY_TABS, COMPANY_ENTITLEMENT_DATA} from '@utils/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Loan } from '@services/fincura-ng-client/model/loan';
import { GlobalCashflowService } from '@services/global-cashflow.service';
import { GcfEntity } from 'app/models/gcf-entity';
import { TradelineEntity } from 'app/models/tradeline-entity';
import { CommonFunctions } from '@utils/common-functions';
import {SpreadingTemplateService} from '@services/spreading-template.service';
import {PdfDownloadModalComponent} from "@components/shared/pdf-download-modal/pdf-download-modal.component";
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {UserService} from "@services/user.service";
import { ChangeLogModalComponent } from '@components/shared/change-log-modal/change-log-modal.component';
import { CallTypeCategory } from '@utils/enums';

@Component({
  selector: 'app-edit-global-cashflow-analysis',
  templateUrl: './edit-global-cashflow-analysis.component.html',
  styleUrls: ['./edit-global-cashflow-analysis.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class EditGlobalCashflowAnalysisComponent implements OnInit {

  subsArr$: Subscription[] = [];
  analysisId = null;
  analysisUuid = null;
  name: string;
  proposedDebts: Array<ProposedDebt> = [];
  commentary: string;
  templateItems: Array<TemplateItem> = [];
  columns: Array<DataViewColumn> = [];
  primaryEntity: GcfEntity = null;
  linkedEntities: Array<GcfEntity> = [];
  headersMetadata: Array<any> = [];
  primaryEntityScopes: Array<{ id: number }>  = [];
  linkedEntityScopes: Array<Array<{ id: number }>> = [];
  allEntityScopes: Array<{ id: number }> = [];
  dataOverrides = null;
  tradelineDataOverrides = null;
  entitySeperatedOpenTradelines: Array<TradelineEntity> = [];
  isSaveButtonDisabled = false;

  allowCellEditing = true;
  company: Company = null;
  isNewAnalysis = false;
  embeddedMode = false;
  templateItemAdditionalAttributes = {};
  sectionToEntitySubsectionRefMap = {};
  entitySubsectionIdToTemplateSpecificIdMappings = {};
  templateSectionIdToBaseEntitySubsectionIdMappings = {};

  nonEntitySubsectionItemsForAllEntityScopeCalculations = [];
  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS;
  routeData: Data;

  constructor(
    private _sharedData: SharedDataService,
    private _route: ActivatedRoute,
    private globalCashflowService: GlobalCashflowService,
    private analysisService: AnalysisService,
    private _alertService: AlertService,
    private _featureFlags: LaunchDarklyService,
    private _router: Router,
    private spreadingTemplateService: SpreadingTemplateService,
    private _popupService: NgxPopupService,
    private _userService: UserService
  ) {
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.embeddedMode = this._sharedData.embeddedMode$.value;
    this.pageViewMode = this.embeddedMode ? COMPANY_PAGE_VIEW_MODE.FULL : this.routeData.companyEntitlement[COMPANY_TABS.ANALYSES][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];

    if (this._router.url.indexOf('/new') > -1) {
      this.isNewAnalysis = true;
    }

    this._route.paramMap.subscribe(params => {
      this.analysisUuid = params.get('uuid');
      if (this.analysisUuid !== null) {
        this.subsArr$.push(this.globalCashflowService.getGlobalCashflowAnalysisByUuid(this.analysisUuid)
        .subscribe(data => {
          if (data.length === 0) {
            this._router.navigate(['/404']);
            return;
          }
          const analysisData = data[0];
          this.analysisId = analysisData.id;
          this.proposedDebts = analysisData.proposedDebts;
          this.name = analysisData.name;
          this.commentary = analysisData.commentary;
          if (this.proposedDebts.length === 0 && this.isNewAnalysis) {
            this.addLoan();
          }
          this.columns = analysisData.dataColumns;
          this.primaryEntity = analysisData.dataColumns['primaryEntity'];
          this.linkedEntities = analysisData.dataColumns['linkedEntities'];
          this.headersMetadata = analysisData.dataColumns['headersMetadata']
          this.templateItems = analysisData.templateItems;
          this.dataOverrides = analysisData.dataOverrides;
          this.tradelineDataOverrides = analysisData.tradelineDataOverrides;
          this.entitySeperatedOpenTradelines = analysisData.entitySeparatedOpenTradelines;
          this.entitySubsectionIdToTemplateSpecificIdMappings = analysisData.entitySubsectionIdToTemplateSpecificIdMappings;
          this.templateSectionIdToBaseEntitySubsectionIdMappings = analysisData.templateSectionIdToBaseEntitySubsectionIdMappings;
          this.primaryEntityScopes = this.analysisService.calculateGlobalCashflowScopes(this.templateItems, this.primaryEntity.columns, this.proposedDebts,
            this.entitySeperatedOpenTradelines, this.dataOverrides.primaryEntity.overrides, this.primaryEntity['entitySpreadingTemplateId']);
          this.linkedEntities.forEach( (linkedEntity: GcfEntity, index: number) => {
            this.linkedEntityScopes.push(this.analysisService.calculateGlobalCashflowScopes(this.templateItems, linkedEntity.columns, this.proposedDebts,
              this.entitySeperatedOpenTradelines, this.dataOverrides.linkedEntities[index].overrides, linkedEntity['entitySpreadingTemplateId'], linkedEntity['entityUuid'],
              this.tradelineDataOverrides.linkedEntities !== undefined ? this.tradelineDataOverrides.linkedEntities[index].overrides : null));
          });
          const analysisTemplateId = analysisData.analysisTemplateId
          this.spreadingTemplateService.getTemplate(analysisTemplateId).subscribe(data => {
            this.templateItemAdditionalAttributes = data.additionalAttributes
            this.determineItemMappingsRequiredForAllEntityScopes(this.templateItems)
            this.recalculate();
          })
          this.recalculate();
        }));
      }
    });

    this.subsArr$.push(this._sharedData.company$.pipe(
      filter(company => company !== null))
      .subscribe((company: Company) => {
        this.company = company;
      })
    );

    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.setFlags();
    }));

    this.setFlags();
  }

  setFlags() {
    this.allowCellEditing = this._featureFlags.flags['dscr-spread-values-manually-editable'];
  }

  addLoan(): void {
    const nextInt = this.proposedDebts.length + 1;
    this.proposedDebts.push({
      name: `${this.name} - Debt ${(nextInt > 1) ? nextInt : ''}`,
      principal: 0,
      interestRate: .0,
      termMonths: 12,
      amortizationMethod: Loan.PaymentTypeEnum.PrincipalAndInterest,
      amortizationOverrides: null
    });

    this.recalculate();
  };

  recalculate(): void {
    this.isSaveButtonDisabled = false;
    if (!this.primaryEntity || this.linkedEntities.length === 0) {
      return;
    }
    this.primaryEntityScopes = this.analysisService.calculateGlobalCashflowScopes(this.templateItems, this.primaryEntity.columns, this.proposedDebts,
      this.entitySeperatedOpenTradelines, this.dataOverrides.primaryEntity.overrides, this.primaryEntity['entitySpreadingTemplateId']);
    this.linkedEntityScopes = []; // reset before we start repushing
    this.linkedEntities.forEach( (linkedEntity: GcfEntity, index: number) => {
      this.linkedEntityScopes.push(this.analysisService.calculateGlobalCashflowScopes(this.templateItems, linkedEntity.columns, this.proposedDebts,
        this.entitySeperatedOpenTradelines, this.dataOverrides.linkedEntities[index].overrides, linkedEntity['entitySpreadingTemplateId'], linkedEntity['entityUuid'],
        this.tradelineDataOverrides.linkedEntities !== undefined ? this.tradelineDataOverrides.linkedEntities[index].overrides : null));
    });

    this.allEntityScopes = this.analysisService.calculateAllEntityScopes(this.primaryEntityScopes, this.linkedEntityScopes, this.sectionToEntitySubsectionRefMap, this.nonEntitySubsectionItemsForAllEntityScopeCalculations);
  }

  removeProposedDebt(idToRemove): void {
    this.proposedDebts.splice(idToRemove, 1);
    this.recalculate();
  }

  manuallyEditedValue(newValueObject: DebtServiceNewValue): void {
    const keyFormattedRef = CommonFunctions.snakeToCamel(newValueObject.ref);
    let equation = newValueObject.newValue;
    if (equation === '' || equation === null) {
      equation = null;
    } else {
      equation = `=${equation}`;
    }
    // need to add logic here to also delete the override if the given override value is equal to the OG value before any overrides were given
    if (equation === null || newValueObject.newValueMatchesOriginalValue) {
      if (newValueObject.isPrimaryEntity) {
        // if there is a record for that ref...
        // then we remove that from the object.
        if (this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex].hasOwnProperty(newValueObject.ref)) {
          delete this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex][newValueObject.ref];
        }
        if (this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex].hasOwnProperty(keyFormattedRef)) {
          delete this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex][keyFormattedRef];
        }
      } else { // is linked entity
        if (newValueObject.tradelineIdx !== -1 ) {
          if (this.tradelineDataOverrides.linkedEntities.length > newValueObject.linkedEntityId) {
            this.tradelineDataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex][newValueObject.tradelineIdx] = null;
          }
        } else {
          if (this.dataOverrides.linkedEntities.length > newValueObject.linkedEntityId) {
            if (this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex].hasOwnProperty(newValueObject.ref)) {
              delete this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex][newValueObject.ref];
            }
            if (this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex].hasOwnProperty(keyFormattedRef)) {
              delete this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex][keyFormattedRef];
            }
          }
        }
      }
    } else {
      if (newValueObject.isPrimaryEntity) {
        // if there is a record for that ref...
        // then we remove that from the object.
        if (this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex].hasOwnProperty(keyFormattedRef)) {
          this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex][keyFormattedRef] = {
            equation: '=' + newValueObject.newValue,
            ref: newValueObject.ref,
          }
        } else {
          this.dataOverrides.primaryEntity.overrides[newValueObject.colIndex][newValueObject.ref] = {
            equation: '=' + newValueObject.newValue,
            ref: newValueObject.ref,
          }
        }
      } else {
        if (newValueObject.tradelineIdx !== -1) {
          this.tradelineDataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex][newValueObject.tradelineIdx]  = {
            equation: '=' + newValueObject.newValue,
            ref: newValueObject.ref,
          }
        } else {
          if (this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex].hasOwnProperty(keyFormattedRef)) {
            this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex][keyFormattedRef]  = {
              equation: '=' + newValueObject.newValue,
              ref: newValueObject.ref,
            }
          } else {
            this.dataOverrides.linkedEntities[newValueObject.linkedEntityId].overrides[newValueObject.colIndex][newValueObject.ref]  = {
              equation: '=' + newValueObject.newValue,
              ref: newValueObject.ref,
            }
          }
        }
      }
    }
    this.recalculate();
  }

  saveChanges(): void {
    let dataOverridesForSave = structuredClone(this.dataOverrides)
    this.formatDataOverridesToHandleDoubleUnderscored(dataOverridesForSave)

    this.isSaveButtonDisabled = true;
    this.globalCashflowService.saveGlobalCashflowAnalysis(this.analysisId, this.proposedDebts, this.commentary, dataOverridesForSave, this.tradelineDataOverrides).subscribe(data => {
        this._alertService.success('Data saved successfully');
        // only allow saving when there is change present
        this.isSaveButtonDisabled = true;
      },
      error => {
        this._alertService.error(error.message);
        this.isSaveButtonDisabled = false;
      });
  }

  changeCommentary(commentary2: string) {
    this.commentary = commentary2;
    this.recalculate();
  }

  downloadExcel() {
    this.subsArr$.push(
      this.analysisService
        .downloadGlobalCashflowAsFile(this.analysisId, this.proposedDebts, this.dataOverrides, 'excel', this.commentary)
        .subscribe(
          () => { },
          (errorResponse) => {
            console.error(errorResponse);
            this.alertUserOfDownloadErrorIfRequired(errorResponse);
          }
        )
    );
  }

  openPdfDownloadModal() {
    this._popupService.open({
      componentType: PdfDownloadModalComponent,
      cssClass: 'pdf-download-modal-class',
      inputs: {
        company: this.company
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  determineItemMappingsRequiredForAllEntityScopes(globalCashflowItemTree) {
    /*
      This performs 2 functions:
      1) finds the section to entity subsection mappings that exist for a given analysis structure
      2) aggregates any line items that need to be calculated at the 'all entities' scope which is just the way to
         refer to values that need to be calculated across the entire analysis such as section rollups and template
         calculations
    */
    const templateSectionToEntitySubsectionMapping = {}
    const nonEntitySubsectionItemsForAllEntityScopeCalculations = []
    const legacyHeaderRefs = ['REF_GCF_AVAILABLE_AMOUNT_FOR_DEBT_SERVICE', 'REF_GCF_ANNUAL_DEBT_SERVICE_REQUIREMENTS']
    globalCashflowItemTree.forEach((parent) => {
      const isParentTemplateSection = this.templateItemAdditionalAttributes[parent.standardLineItemId]?.isTemplateSection || legacyHeaderRefs.includes(parent.ref)

      if (isParentTemplateSection) {
        let addParentToAllEntityScopes = false
        for (let idx = 0; idx < parent.children.length; idx++) {
          if (this.templateItemAdditionalAttributes[parent.children[idx].standardLineItemId]?.isEntitySubsection) {
            // when a section to entity subsection relationship is found, add entry to dictionary and exit
            templateSectionToEntitySubsectionMapping[parent.ref] = parent.children[idx].ref
            break;
          } else if (this.templateItemAdditionalAttributes[parent.children[idx].standardLineItemId]?.isTemplateCalculation) {
            // when we have a template calculation, we add it to the list of items requiring scope calcs across all entities
            nonEntitySubsectionItemsForAllEntityScopeCalculations.push(parent.children[idx])
            addParentToAllEntityScopes = true
          }
        }
        if (addParentToAllEntityScopes) {
          // the parent of a template calculation item needs to be included in all entity scope calculations
          const parentNoChildren = { ...parent }
          parentNoChildren.children = []
          nonEntitySubsectionItemsForAllEntityScopeCalculations.push(parentNoChildren)
        }
      }
    })
    this.sectionToEntitySubsectionRefMap = templateSectionToEntitySubsectionMapping
    this.nonEntitySubsectionItemsForAllEntityScopeCalculations = nonEntitySubsectionItemsForAllEntityScopeCalculations
  }

  showSaveButton() {
    return this.embeddedMode ? true : this.pageViewMode === COMPANY_PAGE_VIEW_MODE.FULL;
  }

  private alertUserOfDownloadErrorIfRequired(response: HttpErrorResponse): void {
    if (response.error?.status >= 300) {
      this._alertService.error('There was an error retrieving the download. Please try again or contact administrator.');
    }
  }

  openMultiEntityAnalysisChangeHistory() {
    this._popupService.open({
      componentType: ChangeLogModalComponent,
      cssClass: 'change-log-modal-class',
      inputs: {
        companyId: this.getCompanyId(),
        analysisId: this.analysisId,
        callTypeCategory: CallTypeCategory.MultiEntityAnalysis,
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  // in embedded flow, the company object is null, so try to pull it from user
  getCompanyId() {
    if (this.embeddedMode) {
      return this._userService.user.company_id;
    }
    if (this.company) {
      return this.company.id;
    }
  }

  formatDataOverridesToHandleDoubleUnderscored(dataOverridesForSave) {
    function performKeySwaps(entity) {
      entity.overrides.forEach(dataColumn => {
        for (const [key, value] of Object.entries(dataColumn)) {
          // @ts-ignore
            if (value.ref.includes('__') && !key.includes('__')) {
            const newKey = key.split('_').join('__')
            delete dataColumn[key]
            dataColumn[newKey] = value
          }
        }
      })
    }
    performKeySwaps(dataOverridesForSave.primaryEntity)
    dataOverridesForSave.linkedEntities.forEach(entity => {
      performKeySwaps(entity)
    })
  }
}
