<div class="container">
<div class="row">
	<div class="col-sm-12">
		<h1><img src="https://manual-public-web-static-resources.s3.amazonaws.com/public-api/Numerated-2023-icon.svg" /> Welcome to the kitchen sink</h1>
		<h2>This is a h2 header</h2>
		<h3>This is a h3 header</h3>
		<h4>This is a h4 header</h4>
		<h5>This is a h5 header</h5>
	</div>
</div>
<app-color-pallette></app-color-pallette>
<app-buttons></app-buttons>
<app-panels></app-panels>
<app-loaders></app-loaders>
</div>
