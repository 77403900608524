export enum RowSelectionActionType {
  INCLUDE_NEXT = 'INCLUDE_NEXT',
  INCLUDE_PREVIOUS = 'INCLUDE_PREVIOUS',
  SELECT_NEXT = 'SELECT_NEXT',
  SELECT_PREVIOUS = 'SELECT_PREVIOUS',
  DESELECT_LAST = 'DESELECT_LAST',
  DESELECT_FIRST = 'DESELECT_FIRST',
  INCLUDE_UNTIL = 'INCLUDE_UNTIL',
  INCLUDE_ADDITIONAL = 'INCLUDE_ADDITIONAL',
  SELECT_SINGLE = 'SELECT_SINGLE',
  DESELECT_SINGLE = 'DESELECT_SINGLE',
  SELECT_COLUMN = 'SELECT_COLUMN',
  DESELECT_ALL = 'DESELECT_ALL',
}

export enum AdjustmentActionType {
  CHANGE_VALUE = 'CHANGE_VALUE',
}
