<div class="tile-container extra-padding">
    <div class="row header tile-header">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <h3>Net Worth</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 gray-text">
                    {{mostRecentStatementDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="graph-container">
                        <app-no-axis-bar-chart
                            [dataToGraph]="netWorthGraphData"
                            barColor="gray"
                            size="medium"
                        >
                        </app-no-axis-bar-chart>
                    </div>
                </div>
                <div class="col-md-6">
                    <span class="gray-text">Net Worth</span> <br />
                    <h3>{{currencySymbol}}&nbsp;{{analyticsService.formattedValue('REF_NET_WORTH_ACTUAL', analyticsData)}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row line-item bold">
        <div class="col-7">
            <span class="">
                Non-Current Assets
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_TOTAL_NON_CURRENT_ASSETS', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Property, Plant, & Equipment
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_PROP_PLANT_EQUIP', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Goodwill
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_GOODWILL', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Intangibles
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_INTANGIBLES', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Other Non-Current Assets
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_OTH_NON_CURRENT_ASSETS', analyticsData)}}
        </div>
    </div>


    <div class="row line-item bold">
        <div class="col-7">
            <span class="">
                Non-Current Liabilities
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_TOTAL_NON_CURRENT_LIABILITIES', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Long Term Debt (Loans)
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_LONG_TERM_DEBT_LOANS_LT', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Long Term Debt (Leases)
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_LONG_TERM_DEBT_CAP_LEASE_LT', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="">
                Other Non-Current Liabilities
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_OTH_NON_CURRENT_LIABILITIES', analyticsData)}}
        </div>
    </div>
    <div class="full-width-line"></div>
    <div class="final-row">
        <div class="final-row-side">
            Current Ratio
            <h3>{{analyticsService.formattedValue('REF_CURRENT_RATIO', analyticsData)}}</h3>
        </div>
        <div class="final-row-side">
            Quick Ratio
            <h3>{{analyticsService.formattedValue('REF_QUICK_RATIO', analyticsData)}}</h3>
        </div>
    </div>
</div>
