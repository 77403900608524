import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApiKeyService } from './api/apiKey.service';
import { BorrowersService } from './api/borrowers.service';
import { CustomAttributesService } from './api/customAttributes.service';
import { DataViewsService } from './api/dataViews.service';
import { FilesService } from './api/files.service';
import { LoansService } from './api/loans.service';
import { PortfoliosService } from './api/portfolios.service';
import { RequirementsService } from './api/requirements.service';
import { TenantSettingsService } from './api/tenantSettings.service';
import { WebhooksService } from './api/webhooks.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class FincuraApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<FincuraApiModule> {
        return {
            ngModule: FincuraApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FincuraApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FincuraApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
