import {Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, Type} from '@angular/core';
import { Row, ItemClicked } from '../../models/models';

@Component({
  selector: 'app-spreading-template-row',
  templateUrl: './template-row.component.html',
  styleUrls: ['./template-row.component.scss']
})
export class TemplateRowComponent implements OnInit, OnChanges {

  @Input() row: Row;
  @Input() selectedColumn: number;
  @Input() enableGoalSeek: boolean;
  @Input() columnCheckOk: boolean;
  @Input() viewInNativeLanguage: boolean;
  @Input() goalSeekLineItem: number;
  @Input() visibleSuggestionsOriginId?: number;
  @Input() goalSeekCount?: number;
  @Output() clearedCategorization = new EventEmitter<Row>();
  @Output() goalSeek = new EventEmitter<Row>();
  @Output() hideSuggestions = new EventEmitter<any>();

  childrenCollapsable = false;

  sourceChildren: Row[] = []; // rows of source mapped items
  subChildren: Row[] = []; // other rows/subitems

  isGoalSeekToolTipVisible: boolean = false;
  isRowHidden = false;

  constructor() { }

  ngOnInit() {
    if (this.row?.isDynamicAdjustmentStatement){
      this.isRowHidden = true;
    }
    this.childrenCollapsable = !this.row.header && this.row.hasOwnProperty('children');
    if (this.row.children) {
      this.sourceChildren = this.row.children.filter(child => !child.template);
      this.subChildren = this.row.children.filter(child => !!child.template);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.row.children) {
      this.sourceChildren = this.row.children.filter(child => !child.template);
      this.subChildren = this.row.children.filter(child => !!child.template);
    }
  }

  removeCategorization(evt, row: Row) {
    evt.preventDefault();
    this.clearedCategorization.emit(row);
  }

  hideGoalSeek(evt) {
    evt.preventDefault();
    this.hideSuggestions.emit();
    this.isGoalSeekToolTipVisible = false;
  }

  startGoalSeek(evt, row: Row) {
    evt.preventDefault();
    this.goalSeek.emit(row);
    this.isGoalSeekToolTipVisible = true;
  }

  removeCategorizationChild(row: Row) {
    this.clearedCategorization.emit(row);
  }

  rowIsValid(row) {
    return row.cells.every(c => {
      return !c.flag;
    });
  }

  errors(): Array<string> {
    return this.row.validationRow.cells.filter(c => c.flag).map(c => c.flagReason);
  }

  getSourceItems() {
    if (!this.row.children) {
      return [];
    }
    return this.row.children.filter(child => !child.template);
  }
}
