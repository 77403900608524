import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {
  PdfToolbarService
} from "@components/shared/document-viewer/pdf-toolbar/pdf-toolbar.service";


@Component({
  selector: 'app-pdf-paginator',
  templateUrl: './pdf-paginator.component.html',
  styleUrls: ['./pdf-paginator.component.scss'],
})
export class PdfPaginatorComponent {
  @Input() pageCount: number;
  visiblePageNum$: Observable<number>;

  constructor(
    private pdfToolbarService: PdfToolbarService
  ) {
    this.visiblePageNum$ = this.pdfToolbarService.visiblePageNumber$;
  }

  pageIncrease() {
    this.pdfToolbarService.incrementVisiblePageNumber();
    this.pdfToolbarService.scrollPageIntoView();
  }

  pageDecrease() {
    this.pdfToolbarService.decrementVisiblePageNumber();
    this.pdfToolbarService.scrollPageIntoView();
  }

  pageNumberChanged(event) {
    this.pdfToolbarService.visiblePageNumber = this.pdfToolbarService.validateNextPageValue(parseInt(event.target.value), this.pageCount);
    setTimeout(() => {
      this.pdfToolbarService.scrollPageIntoView('auto');
    }, 0)
  }


}
