import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentFileCreateRequest } from '../../../../models/document-file-create-request';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {
  @Input() customBankImg: string;
  @Input() primaryColor: string;
  @Input() documentSubmissions: DocumentFileCreateRequest[] = [];
  @Input() complete: boolean;
  @Output() onFileChoosen: EventEmitter<object> = new EventEmitter();
  @Output() cancelSubmission: EventEmitter<object> = new EventEmitter();
  @Output() submitDocuments: EventEmitter<object> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitfileChoosen(event: any): void {
    this.onFileChoosen.emit(event);
  }

  emitCancelSubmission(event: any): void {
    this.cancelSubmission.emit(event);
  }

  emitSubmitDocuments(event: any): void {
    this.submitDocuments.emit(event);
  }

}
