import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';


@Component({
  selector: 'app-select-spreading-template-modal',
  templateUrl: './select-spreading-template-modal.component.html',
  styleUrls: ['./select-spreading-template-modal.component.scss']
})
export class SelectSpreadingTemplateModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() availableTemplates: any;
  @Input() defaultTemplateId: string;
  @Input() initialTemplateSelection: boolean;

  @Output() selectedTemplateId = new EventEmitter<string>();

  selectedTemplate = '';
  templatePreviewItems = [];

  SELECT_SPREADING_TEMPLATE_MODAL_HEADER = 'SELECT A TEMPLATE';
  SELECT_SPREADING_TEMPLATE_MODAL_HELPER_TEXT = 'Select a spreading template for this borrower. Templates cannot be modified for this borrower after the spread is complete.';
  BACK_TO_DATA_REVIEW_TEXT = 'Go Back to Data Review';
  CANCEL_TEXT = 'Cancel';
  SELECT_TEXT = 'Select';

  ngOnInit() {
    this.selectedTemplate = this.defaultTemplateId;
    const template = this.getTemplateWithMatchingId(this.selectedTemplate);
    if (template) {
      this.templatePreviewItems = this.flattenTemplateItemsForTemplatePreview(template.items, 0);
    }
  }

  /**
   * helper to close popup by emitting answer to parent component
   */
  answer(a: boolean) {
    this.selectedTemplateId.emit(a ? this.selectedTemplate : '');
    this.popup.close();
  }

  chooseTemplate(templateIdChosen: string) {
    this.selectedTemplate = templateIdChosen;
    const template = this.getTemplateWithMatchingId(Number(this.selectedTemplate));
    if (template) {
      this.templatePreviewItems = this.flattenTemplateItemsForTemplatePreview(template.items, 0);
    } else {
      this.templatePreviewItems = [];
    }
  }

  flattenTemplateItemsForTemplatePreview(templateItems, indentLevel: number) {
    let templatePreviewItems = [];
    for (const item of templateItems) {
      templatePreviewItems.push({
        label: item.label,
        indentLevel: indentLevel,
      })
      if (item.children && item.children.length > 0) {
        templatePreviewItems = templatePreviewItems.concat(this.flattenTemplateItemsForTemplatePreview(item.children, indentLevel + 1));
      }
    }
    return templatePreviewItems;
  }

  getTemplateWithMatchingId(templateId) {
    for (const template of this.availableTemplates) {
      if (template.id === templateId) {
        return template;
      }
    }
  }
}
