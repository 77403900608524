<div class="inner-content">
  <div class="row">
    <div class="col-sm-3">
      <div class="card mb-4 border-bottom-0">
        <div class="card-header">
          <h5 class="mb-0">Financial Metrics</h5>
        </div>
        <div class="card-body">
            <p>Select Financial Metrics:</p>
            <div class="col-sm-6">
                <ng-select
                  id="column-selector"
                  class="custom"
                  [items]="availableColumns"
                  bindLabel="value"
                  bindValue="id"
                  autofocus
                  (change)="itemChosenInAutocomplete($event)"
                  minTermLength="1"
                  >
                </ng-select>
            </div>
        </div>
        <table class="table table-bordered mb-0 border-start-0 border-end-0">
          <thead class="border-start-0 border-end-0">
            <tr class="border-start-0 border-end-0">
              <th class="border-start-0">Metric</th>
              <th><i class="fas fa-sort-numeric-down"></i> Sort Results (Optional)</th>
              <th *ngIf="groupBy">Aggregation</th>
              <th class="border-end-0"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-start-0 border-end-0" *ngFor="let c of reportColumns; let idx = index">
              <td class="border-start-0">{{c.label}}</td>
              <td>
                <select class="form-select" (change)="setSortOnColumn($event, c)">
                  <option value="" [selected]="!c.useForSorting">-</option>
                  <option value="DESC" [selected]="c.useForSorting && !c.sortAsc">9&rarr;0</option>
                  <option value="ASC" [selected]="c.useForSorting && c.sortAsc">0&rarr;9</option>
                </select>
              </td>
              <td *ngIf="groupBy">
                  <select class="form-select" [(ngModel)]="c.aggregation">
                    <option value="AVG">Average</option>
                    <option value="MAX">Maximum</option>
                    <option value="MIN">Minimum</option>
                    <option value="STD_DEV">Standard Deviation</option>
                  </select>
                </td>
              <td class="border-end-0">
                <button class="btn btn-sm remove-btn" (click)="removeColumn($event, idx)">&times;</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="enableAdvancedReporting" class="card mb-4">
        <div class="card-header">
          Report Type
        </div>
        <div class="card-body">
          <div class="mb-3">
            <label>Aggregate By</label>
            <br />
            <select class="form-select" [(ngModel)]="groupBy">
              <option value="">None</option>
              <option value="BORROWER">Borrower</option>
              <option value="PORTFOLIO">Portfolio</option>
            </select>
          </div>
          <div class="mb-3">
            <label>
              <input type="checkbox" [(ngModel)]="onlyLatestFinancials"/> Use Latest Matching Financials per Borrower

            </label>
          </div>
          <div class="mb-3">
            <label>Include Reporting Intervals</label>
            <br />
            <select class="form-select" multiple="true" [(ngModel)]="reportingIntervals">
              <option value="MONTHLY">MONTHLY</option>
              <option value="QUARTERLY">QUARTERLY</option>
              <option value="ANNUALLY">ANNUAL</option>
            </select>
          </div>
          <div class="mb-3">
            <label>Include Preparation Types</label>
            <br />
            <select class="form-select" multiple="true" [(ngModel)]="preparationTypes">
              <option value="UNQUALIFIED">UNQUALIFIED</option>
              <option value="QUALIFIED">QUALIFIED</option>
              <option value="TAX_RETURN">TAX_RETURN</option>
              <option value="REVIEWED">REVIEWED</option>
              <option value="CPA_PREP">CPA_PREP</option>
              <option value="NTR">NTR</option>
              <option value="MANAGEMENT">MANAGEMENT</option>
              <option value="UNKNOWN">UNKNOWN</option>
              <option value="CALCULATED">CALCULATED</option>
            </select>
          </div>
        </div>
      </div>

      <button class="btn btn-primary" (click)="generateReport($event)">GENERATE</button>

    </div>
    <div class="col-sm-9">
      <div *ngIf="loadingReport">
        <i class="fas fa-circle-notch fa-spin"></i> Generating Report
      </div>
      <div *ngIf="!loadingReport">
        <h3 class="mb-3">
          <div class="float-end" *ngIf="reportData.length">
            <button class="btn btn-primary btn-rounded btn-sm" (click)="copyReportLinkToClipboard()">
              <i class="fas fa-external-link-alt"></i> Copy Link
            </button>{{'&#160;'}}
            <button class="btn btn-primary btn-rounded btn-sm" (click)="exportToCsv($event)"><i class="fas fa-download"></i> Download</button>
          </div>
          Report Results

        </h3>
        <p *ngIf="!reportData.length">
          No results!
        </p>
        <table class="table table-bordered" *ngIf="reportData.length">
          <thead>
            <tr>
              <th *ngFor="let h of reportHeader">
                {{h}}

                  <div *ngIf="h === 'Portfolio'" class="column-filter-dropdown">
                      <div class="dropdown">
                          <button class="btn btn-default btn-sm dropdown-toggle" type="button" id="dropdownMenu1"
                                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><span
                              class="caret"></span></button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
                              <div class="options-wrapper" (click)="$event.stopPropagation();">
                                  <div class="mb-3">
                                      <div class="form-check">
                                          <input type="checkbox" id="toggleAllCheckbox" class="form-check-input"
                                                 [checked]="showAllPortfolios" [disabled]="showAllPortfolios"
                                                 (click)="toggleAllPortfoliosFilter($event)">
                                          <label class="form-check-label" for="toggleAllCheckbox">All Portfolios</label>
                                      </div>
                                  </div>
                                  <div class="dropdown-divider"></div>
                                  <div class="mb-3">
                                      <div class="form-check">
                                          <input type="checkbox" id="unassignedCheck" value="unassigned"
                                                 class="form-check-input"
                                                 [checked]="filteredPortfolios.has(-1)"
                                                 (click)="togglePortfolioFilter($event, null)">
                                          <label class="form-check-label" for="unassignedCheck">Unassigned</label>
                                      </div>
                                  </div>
                                  <div *ngFor="let portfolio of portfolioOptions; let i=index" class="mb-3">
                                      <div class="form-check">
                                          <input class="form-check-input" type="checkbox" [id]="'portfolio-'+i"
                                                 [checked]="filteredPortfolios.has(portfolio.id)"
                                                 (click)="togglePortfolioFilter($event, portfolio)"
                                                 value="{{portfolio.id}}">
                                          <label [for]="'portfolio-'+i"
                                                 class="form-check-label">{{portfolio.name}}</label>
                                      </div>
                                  </div>
                              </div>
                              <div class="dropdown-divider"></div>
                              <div class="d-flex justify-content-center">
                                  <button class="btn btn-default" type="button" (click)="reset()">Cancel</button>
                                  <button class="btn btn-primary" type="button" (click)="generateReport($event)">Ok
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of reportData | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }">
              <td *ngFor="let d of r">
                {{d}}
              </td>
            </tr>
          </tbody>
        </table>
            <div *ngIf="total > limit">
                <pagination-template #p="paginationApi" (pageChange)="changePage($event)">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [ngClass]="{'disabled': p.isFirstPage()}">
                                <a class="page-link" (click)="!p.isFirstPage() ? p.previous() : return" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            <li class="page-item" *ngFor="let page of p.pages" [ngClass]="{'active': p.getCurrent() === page.value}">
                                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    {{ page.label }}
                                </a>
                                <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}
                                    <span class="visually-hidden">(current)</span>
                            </span>
                            </li>

                            <li class="page-item" [ngClass]="{'disabled': p.isLastPage()}">
                                <a class="page-link" (click)="!p.isLastPage() ? p.next() : return" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </pagination-template>
            </div>
      </div>

    </div>
  </div>
</div>
