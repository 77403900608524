<div class="spreading-templates-container">
    <div class="row spreading-template-nav-container">
        <div class="spreading-template-nav">
            <a href="javascript:void(0)"
               (click)="changeType('STANDARD')"
               class="template-type-label"
               [ngClass]="{'bold': templateType === 'STANDARD'}"
            >
                Spreading Templates
            </a>
            <a href="javascript:void(0)"
               (click)="changeType('DSCR')"
               class="template-type-label"
               [ngClass]="{'bold': templateType === 'DSCR'}"
            >
                Debt Service Templates
            </a>
            <a href="javascript:void(0)"
               (click)="changeType('GLOBAL_CASHFLOW')"
               class="template-type-label"
               [ngClass]="{'bold': templateType === 'GLOBAL_CASHFLOW'}"
            >
                Global Cash Flow Templates
            </a>
        </div>
    </div>

    <div class="row edit-template-header">
        <div class="col-6 edit-template-header-label ps-0">
            <h3>
                Edit {{ templateType === 'STANDARD' ? 'Spreading' : templateType === 'DSCR' ? 'Debt Service' : 'Global Cashflow' }}
                Templates
            </h3>
        </div>
        <div class="col-6 add-new-template-button-container">
            <div class="add-new-template-button-group">
                <button class="btn btn-link show-disabled-template-button"
                        (click)="toggleShowDisabledTemplates()"
                        [ngClass]="{'bold': showDisabledTemplates}"
                ><i *ngIf="!showDisabledTemplates" class="far fa-eye"></i>
                 <i *ngIf="showDisabledTemplates" class="far fa-eye-slash"></i>
                 {{!showDisabledTemplates ? " Show Disabled Templates" : " Hide Disabled Templates"}}
                </button>
                <button class="btn btn-primary btn-rounded add-new-template-button"
                        (click)="navigateToTemplateEditor()"><i class="fas fa-plus"></i> Add New
                    Template
                </button>
            </div>

        </div>
    </div>
    <div class="row edit-template-tiles">
        <ng-container *ngIf="templates && templates.length > 0; else noTemplates">
            <div *ngFor="let template of templates" class="col-12 col-sm-6 col-md-3 edit-template-tile">
                <app-template-list-item *ngIf="template.active || showDisabledTemplates"
                    [name]="template.name"
                    [type]="templateType"
                    [id]="template?.id"
                    [uuid]="template?.uuid"
                    [createdAt]="template?.createdDate"
                    [numBorrowers]="template?.numBorrowers"
                    [isDefault]="template?.isDefault"
                    [active]="template?.active"
                    (templateItemEnabledOrDisabled)="refreshTemplates()"
                >
                </app-template-list-item>
            </div>
        </ng-container>
        <ng-template #noTemplates>
            <div class="col-12 col-sm-6 col-md-3">
                No {{ templateType === 'STANDARD' ? 'Spreading' : templateType === 'DSCR' ? 'debt service' : 'global cashflow' }}
                templates exist yet.
            </div>
        </ng-template>
    </div>
</div>
