import { LineItemObject} from './line-item'
import { Deserializable } from './deserializable';
import { Footnote } from './footnote';
import { SourceComment } from '@components/main/spreading/models/source-comment';

export class DataFrameValue implements Deserializable {
  sourceComments?: Array<SourceComment>;
  lineItem: LineItemObject
  value: number;
  originalValue: number;
  error?: string;
  errorMessage?: string;
  isHeader: boolean;
  requiredValue: number;
  footnotes?: Array<Footnote>;
  sourceStatementId?: number;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (this.lineItem) {
      this.lineItem = new LineItemObject().deserialize(this.lineItem);
    }
    return this;
  }
}

export class DataFrame implements Deserializable {
  id: number;
  company: number;
  generator: number;
  type: string;
  statementDate: string;
  commentary: string;
  reportingPeriod: string;
  changedBy: string;
  changedDate: string;
  reportingInterval: string;
  preparationType: string;
  items: Array<DataFrameValue>

  deserialize(input: any): this {
    Object.assign(this, input);

    if (this.items) {
      this.items = this.items.map(item => new DataFrameValue().deserialize(item));
    }
    return this;
  }
}
