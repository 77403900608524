import { Component, OnInit } from '@angular/core';
import { CellInformationService } from '@services/shared/cell-information/cell-information.service';
import { AutoUnsubscribe } from '@decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { DataFrameValue } from '@models/dataframe';

@Component({
  selector: 'app-cell-information',
  templateUrl: './cell-information.component.html',
  styleUrls: ['./cell-information.component.scss']
})
@AutoUnsubscribe('subscriptionArray$')
export class CellInformationComponent implements OnInit {

  cellInformation: DataFrameValue;
  cellInformationTitle: string;
  cellInformationStatementDate: string;
  cellInformationVisible: boolean;
  subscriptionArray$: Subscription[] = [];

  constructor(
    private cellInformationService: CellInformationService
  ) { }

  ngOnInit(): void {
    this.subscriptionArray$.push(
      this.cellInformationService.cellInformationVisible$
        .subscribe(cellInformationVisible => this.cellInformationVisible = cellInformationVisible)
    );

    this.subscriptionArray$.push(
      this.cellInformationService.cellInformationTitle$
        .subscribe(cellInformationTitle => this.cellInformationTitle = cellInformationTitle)
    );

    this.subscriptionArray$.push(
      this.cellInformationService.cellInformation$
        .subscribe(cellInformation => this.cellInformation = cellInformation)
    );

    this.subscriptionArray$.push(
      this.cellInformationService.cellInformationStatementDate$
        .subscribe(cellInformationStatementDate =>
          this.cellInformationStatementDate = cellInformationStatementDate
        )
    );
  }

  hideCellInformation(): void {
    this.cellInformationService.hideCellInformation();
  }

  hasComments(): boolean {
    return !!this.cellInformation?.sourceComments?.length;
  }
}
