<div class="tile-container extra-padding">
    <div class="row header tile-header">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <h3>Income Statement</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 gray-text">
                    {{mostRecentStatementDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="graph-container">
                        <app-no-axis-bar-chart
                            [dataToGraph]="graphData"
                            barColor="gray"
                            size="medium"
                        >
                        </app-no-axis-bar-chart>
                    </div>
                </div>
                <div class="col-md-6">
                    <span class="gray-text">Total Revenue</span> <br />
                    <h3>{{currencySymbol}}&nbsp;{{formattedNetSales}}</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="row line-item bold">
        <div class="col-12">
            INCOME STATEMENT
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Revenue (net)
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{formattedNetSales}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                COGS
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{formattedCogs}}
        </div>
    </div>

    <hr>

    <div class="row line-item bold">
        <div class="col-7">
            <span class="indent-1">
                GROSS PROFIT
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{formattedGrossProfit}}
        </div>
    </div>
    <div class="row line-item bold">
        <div class="col-7">
            <span class="indent-1">
                OPEX
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_TOTAL_OPERATING_EXPENSES', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                SG&A
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_SGA', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                Personnel
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_PERSONNEL', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                Officer Compensation
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_OFF_COMP', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                Research & Development
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_RND', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                Depreciation & Amortization
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_DEPREC_AMORT', analyticsData)}}
        </div>
    </div>

    <hr>

    <div class="row line-item bold">
        <div class="col-7">
            <span class="indent-1">
                OPERATING PROFIT (EBIT)
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_EBIT', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                Interest
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_INT_EXP', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-2">
                Tax
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_INCOME_TAXES', analyticsData)}}
        </div>
    </div>

    <div class="row line-item bold">
        <div class="col-7">
            <span class="indent-1">
                NET INCOME
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_NET_INCOME', analyticsData)}}
        </div>
    </div>

</div>
