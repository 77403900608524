import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { Statement } from '../components/main/review/human-validation/models/statement';
import { Row } from '../components/main/spreading/models/models';
import { SpreadingState } from '../components/main/spreading/state/manager';
import { Box } from '@components/main/review/human-validation/models/box';

@AutoUnsubscribe('subsArr$')
@Injectable()
export class StatementService {
  subsArr$: Subscription[] = [];

  constructor(
    private _apiService: ApiService,
  ) {
  }


  saveStatement(
    statement: Statement,
    spreadingTemplateId: number,
    forceSync = false,
    initialTemplateSelectionHasBeenMade = false,
    reAutonorm = false,
    allowDeletingInsertedRows = false,
  ) {
    statement.spreadingTemplateId = spreadingTemplateId;
    return this._apiService.send('Patch', `/api/statements/${statement.id}`, Object.assign({}, statement, {
      'forceIntegrationSync': forceSync,
      'initialTemplateSelectionHasBeenMade': initialTemplateSelectionHasBeenMade,
      'reAutonorm': reAutonorm,
      'allowDeletingInsertedRows': allowDeletingInsertedRows,
    })).toPromise().then(data => new Statement().deserialize(data.response.objects[0]));
  }

  saveFootnotesOnRow(statementUuid: string, rowUuid: string, serializedFootnotes: any): Observable<any> {
    return this._apiService.send('Patch', `/api/statements/${statementUuid}/rows/${rowUuid}/footnotes`, {
      'footnotes': serializedFootnotes
    })
  }

  loadStatement(id: number): Observable<Statement> {
    return this._apiService.send('Post', '/api/statements/all', {
      'filter': {
        'id_eq': id,
      },
    }).pipe(map(data => {
      return new Statement().deserialize(data.response.objects[0]);
    }));
  }

  loadStatementByUuid(uuid: string): Observable<Statement> {
    return this._apiService.send('Post', '/api/statements/all', {
      'filter': {
        'uuid_eq': uuid,
      },
    }).pipe(map(data => {
      return new Statement().deserialize(data.response.objects[0]);
    }));
  }

  loadStatementsByDocFileId(docFileId: number): Observable<Statement> {
    return this._apiService.send('Post', '/api/statements/all', {
      'filter': {
        'document_file_id_eq': docFileId,
      },
    }).pipe(map(data => {
      return new Statement().deserialize(data.response.objects[0]);
    }));
  }


  saveGoalSeekData(state: SpreadingState, row: Row, colIdx: number, documentFileId: any, solution: any): Observable<any> {
    return this._apiService.send('Post', '/api/statements/goalseek', {
      'spreadingState': state,
      'row': row,
      'colIdx': colIdx,
      'documentFileId': documentFileId,
      'solution': solution
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  startMergeTableProcess(documentFileId: number, box: Box, sourcePageNumber: number): Observable<any> {
    return this._apiService.send('Post', '/api/json-blobs/merged-table', {
      'document_file_id': documentFileId,
      'bounding_box': box,
      'source_page_number': sourcePageNumber,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  saveMergeTableAdjustments(documentFileId: number, tableId: number, columnStructure: Array<Box>, box: Box, sourcePageNumber: number): Observable<any> {
    return this._apiService.send('Patch', `/api/json-blobs/merged-table/${tableId}`, {
      'document_file_id': documentFileId,
      'column_structure': columnStructure,
      'bounding_box': box,
      'source_page_number': sourcePageNumber,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  getManualReviewData(documentFileId: number, tableId: number, box: Box, sourcePageNumber: number): Observable<any> {
    return this._apiService.send('Post', `/api/json-blobs/table/${tableId}/as_old_page`, {
      'document_file_id': documentFileId,
      'source_page_number': sourcePageNumber,
      'bounding_box': box,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));

  }

  completeTableMerge(statementId: number, insertedAtLineItemId: number, oldPage: { cells: any }): Observable<any> {
    return this._apiService.send('Patch', `/api/statements/${statementId}`, {
      'old_page': oldPage,
      'rounded_to_millions': false,
      'rounded_to_thousands': false,
      'insert_id': insertedAtLineItemId,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  otherStatementHasProjections(
    companyId: number,
    documentFileId: number,
    statementIdToOmitFromSearch: number
  ): Observable<boolean> {

    return this._apiService.send(
      'Post',
      `/api/statements/projections-present-on-related-statements/${documentFileId}/${statementIdToOmitFromSearch}`,
      {
        'company_id': companyId
      }).pipe(map(data => {
        return data.response.objects[0];
    }));
  }

  respreadCompaniesAssociatedWithSpreadingTemplate(
    spreadingTemplateId: number,
  ): Observable<any> {
    return this._apiService.send('Post', '/api/statements/respread', {
      'spreading_template_id': spreadingTemplateId
    }).pipe(map(data => data.response.objects));
  }

  loadDASStatementSnapshot(uuid): Observable<any> {
    return this._apiService.send('Post', '/api/statements/das-snapshot', {
      'filter': {
        'uuid_eq': uuid,
      },
    }).pipe(map(data => {
      return data.response.objects;
    }));
  }


}
