import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {

  constructor(private _toastrService: ToastrService) {
    /*_toastrService.onClickToast()
      .subscribe(toast => {
        _toastrService.dismissToast(toast);
      });*/
  }

  /**
   *
   * @param {string} message
   * @param {string} title
   * @param {number} timeOutInSeconds
   */
  success(message: string, title = 'Success!', timeOutInSeconds=5) {
    this._toastrService.success(message, title, {
      timeOut: timeOutInSeconds * 1000,
      enableHtml: true,
      closeButton: false,
    });
  }

  /**
   *
   * @param {string} message
   * @param {string} title
   * @param {number} timeOutInSeconds
   */
  error(message: string, title: string = 'Error', timeOutInSeconds=10) {
    this._toastrService.error(message, title, {
      closeButton: false,
      timeOut: timeOutInSeconds * 1000,
      enableHtml: true
    });
  }

  /**
   *
   * @param {string} message
   * @param {string} title
   * @param {number} timeOutInSeconds
   */
  info(message: string, title = 'Information', timeOutInSeconds=10) {
    this._toastrService.info(message, title, {
      closeButton: false,
      timeOut: timeOutInSeconds * 1000,
      enableHtml: true
    });
  }

  /**
   *
   * @param {string} message
   * @param {string} title
   * @param {number} timeOutInSeconds
   */
  warning(message: string, title = 'Warning', timeOutInSeconds=10) {
    this._toastrService.warning(message, title, {
      closeButton: false,
      timeOut: timeOutInSeconds * 1000,
      enableHtml: true
    });
  }
}
