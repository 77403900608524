<div>
    <div class="modal_header">
        <i class="fa fa-file-pdf header-icon"></i>
        <p>DOWNLOAD PDF</p>
        <div class="modal__close" (click)="closeModal();">
         </div>
    </div>
    <div class="modal__download">
        <div class="doc-name-input-container mb-3">
            <label class="control-label" for="downloadDocInput">File Name:</label>
            <div class="input-group has-feedback">
                <input id="downloadDocInput" type="text" [formControl]="downloadFileName" class="form-control"
                       maxlength="100" [ngClass]="{'is-invalid': downloadFileName.invalid}">
                <span class="input-group-text">.pdf</span>
                <span class="invalid-feedback" *ngIf="downloadFileName.invalid && downloadFileName.hasError('required')">
                    Download File Name is required
                </span>
            </div>
        </div>
    </div>
    <div class="modal__content">
        <div *ngIf="showSpinner; else modalContent" class="loader__container">
            <div class="loader loader-for-modal-content"></div>
        </div>
        <ng-template #modalContent>
            <div class="column-headers">
                <div class="panel-header">
                    <h4 class="column-title mt-2">
                        Available Documents
                    </h4>
                    <p class="column-sub-title">Select documents to add it to the download list.</p>
                </div>
                <div class="panel-header">
                    <h4 class="column-title mt-2">Download List</h4>
                    <p class="column-sub-title">Drag and drop to reorder this list.</p>
                </div>
            </div>
            <div class="column-content">
                <div class="entity-list-container panel-container">
                    <div *ngFor="let entity of entitiesWithDownloadableDocs">
                        <div>
                            <p class="entity-name">{{entity.entityName}}</p>
                            <div *ngIf="entity?.financialDataViews?.length" class="entity-container">
                                <p class="doc-category-title">Financials</p>
                                <div class="doc-list">
                                    <div
                                        [ngClass]="{
                                    'doc-item': true,
                                    'selected': dataView?.selected
                                }"
                                        (click)="selectDoc(dataView)"
                                        *ngFor="let dataView of entity.financialDataViews"
                                    >
                                        {{dataView.name}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="entity?.analyses?.length" class="entity-container">
                                <p class="doc-category-title">Analyses</p>
                                <div
                                    [ngClass]="{
                                    'doc-item': true,
                                    'selected': analysis?.selected
                                }"
                                    (click)="selectDoc(analysis)"
                                    *ngFor="let analysis of entity.analyses"
                                >
                                    {{analysis.name}}
                                </div>
                            </div>
                            <div *ngIf="entity?.globalCashflows?.length" class="entity-container">
                                <p class="doc-category-title">Global Cashflow</p>
                                <div
                                    [ngClass]="{
                                    'doc-item': true,
                                    'selected': globalCashflow?.selected
                                }"
                                    (click)="selectDoc(globalCashflow)"
                                    *ngFor="let globalCashflow of entity.globalCashflows"
                                >
                                    {{globalCashflow.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="download-list-container panel-container">
                    <div *ngIf="documentsToBeDownloaded && documentsToBeDownloaded?.length" cdkDropList
                         class="drag-drop-list"
                         (cdkDropListDropped)="drop($event)">
                        <div class="drag-drop-box" *ngFor="let doc of documentsToBeDownloaded" cdkDrag>
                            <span class="document-name">{{doc.name}}</span>
                            <span (click)="_removeDocumentToDownloadList(doc)"><i
                                class="far fa-times-circle remove-icon"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class = "modal__footer">
                <div class="footer-action-container">
                    <button type="reset" class="btn btn-empty-primary upload-footer"
                     (click)="closeModal();">{{fileDownloadProcessBegun ? 'Close' : 'Cancel'}}</button>
                    <button type="submit" class="btn btn-primary upload-footer"
                        [disabled]="downloadFileName.invalid || !documentsToBeDownloaded?.length || fileDownloadProcessBegun"
                        (click)="download()">Download
                         <div *ngIf="fileDownloadProcessBegun" class="loader button-loader"></div>
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
