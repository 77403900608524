<div class="modal__content">
    <div class="confirmation-popup-header">
        <i class="fas fa-lock"></i>
        <span> Enter Document Password</span>
    </div>
    <div class="confirmation-popup-text">
        <span>A password is required for the document '{{documentName}}'</span><br><br>
        <span>To spread this document, please enter the password here or upload a new document without password protection.</span><br><br>
        <span class="password-input-label">Enter Password</span><br>
        <input type="password" class="password-input-box" id="doc-password-input" placeholder="Enter Password" autocomplete="false" [(ngModel)]="inputtedPassword" [disabled]="isDocumentLocked">
        <div *ngIf="showAttemptsRemainingMessage || isDocumentLocked" class="incorrect-password-warning">
            <p *ngIf="!isDocumentLocked">Incorrect Password. {{attemptsRemaining}} attempts remaining.</p>
            <p *ngIf="isDocumentLocked">Incorrect password. You have been locked out due to too many failed attempts. Please upload a new document without password protection.</p>
        </div>
    </div>

</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" *ngIf="!reviewQueueItem.embeddedWorkflow" (click)="navigateBackToDocumentsPage();">Cancel</button>
    <button type="button" class="btn btn-primary"
     (click)="tryInputtedPassword()"[disabled]="!inputtedPassword">Submit</button>
</div>

