import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import '@datadog/browser-logs/bundle/datadog-logs-us'
import { Subscription } from 'rxjs';
import { LaunchDarklyService } from './launchdarkly.service' ;
// @ts-ignore
import packageJson from '../../../package.json';

// A new (more full formed) model for logging
// in the front end. The logging service
// class is simply a wrapper to hold a Logger object which does
// the heavy lifting.
@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  subsArr$: Subscription[] = [];
  rootLogger: Logger = new Logger('root');

  // a global flag to enable or disable the logging library
  // this is intended to be used for launchdarkly
  globalLoggingEnabledFlag = true;

  constructor (
    private _launchDarklyService: LaunchDarklyService,
  ) {
    this.rootLogger.libraries = <LogLibraries> {
      sentry: Sentry,
      // @ts-ignore
      datadog: window.DD_LOGS,  // this is an ugly hack to enable support for typescript less than v3
      fullstory: null,  // to be filled in later (if fullstory is active) when fullstory loads in
    }
    this.rootLogger.myLoggingService = this;
    this.rootLogger.metadata = {};
    this.rootLogger.metadata._userdata = {};
    this.rootLogger.metadata._environmentdata = {};
    this.rootLogger.metadata._sourceStamp = 'frontendLoggingService';
    try {
      this.rootLogger.metadata._appVersion = packageJson.version;
    } catch (err) {
      console.log('Unable to get appVersion', err)
    }

    const rootLogger = this.rootLogger;  // allow it to be accessed within the callback
    window['_fs_ready'] = function() {
      // @ts-ignore
      // the window.FS variable does not exist at complication time, but it does exist when fullstory loads in
      rootLogger.libraries.fullstory = window.FS;
    }
  }
}

// a construct to help pass log data around internally
// to the Logger class.
// reserved attributes (those with enforced checks) start with '_'
// the receiveLoggingEvent function in Logger is responsible for enforcing that.
interface LogMetadata {
  [key: string]: any
}
export interface LogEntry {
  loggerName?: string,
  logLevel?: string,
  logLevelInt?: number,
  message?: string,
  metadata?: LogMetadata,
  stackByErrorObj?: any, // hopefully temporary, but an error object to force the collection of the stack prior to a setTimeout call in receiveLoggingEvent
}
// an interface to expose the dependencies of the
// logger system
export interface LogLibraries {
  sentry?: any,
  datadog?: any,
}

export interface LogConfiguration {
  enable: boolean;  // turns off recieve functionality which effectively turns everything else off _for just that one logger instance_
  enableOutputSentryErrors: boolean;
  enableOutputSentryInfos: boolean;
  enableDatadog: boolean;
  enableOutputConsole: boolean;
}

// some defaults for loglevel
export enum LogLevel {
  VERBOSE = 100,
  INFO = 200,
  WARNING = 300,
  ERROR = 400
}

// the logger class that does all the heavy lifting
export class Logger {
  // configuration
  // if it is undefined, the setting will be inherited from the parent
  // it is an error if the rootLogger leaves any of these as undefined
  configuration: LogConfiguration = <LogConfiguration> {
    enable: true,
    enableOutputSentryErrors: true,
    enableOutputSentryInfos: false,
    enableDatadog: true,
    enableOutputConsole: true,
  }

  // track the children of this logger class so we have
  // a bidirectional way to traverse the tree
  childLoggers: Array<Logger> = [];

  // whether or not this logger is operating in asyncronous fashion
  isInAsyncMode = true;

  // to prevent us from making external depedencies
  // we use an object with references to library items
  // this allows us to be injected into without needing to leverage
  // TestBed or other injectable parents
  libraries: LogLibraries = {};

  // common metadata for this logger and all child loggers
  // this logger and all child loggers will use this set of metadata (aka tags)
  // for all log entries. the order of priority from least to most important:
  // 3) parent metadata items
  // 2) this metadata items
  // 1) metadata added to the log invocation
  // for example, if the parent metadata was {"user": "me"} and then the child metadata
  // were {"attribute": "test"}, the result metadata in the log entry would be {"user": "me", "attribute": "test"}
  // If any keys in the object are identical, the priority order above is used to determine
  // which value will be passed through.
  metadata: LogMetadata = {};  // this need to be an object but typescript doesn't like objects used as dictionaries apparently.

  // a mapping from this Logger class back to the LoggingService is it a part of
  // note that this will only be defined for the rootLogger
  myLoggingService: LoggingService = undefined;

  constructor(
    public loggerName: string,
    public parentLogger?: Logger
  ) {}

  getLibrary(name: string): any {

    // first, try to use a library defined directly on this object
    // second, if it isn't defined, use it from an ancester
    // third, (if no ancester), return undefined
    if (this.libraries !== undefined && this.libraries !== null) {
      if (this.libraries[name] !== undefined) {  // see if we have the library
        return this.libraries[name];
      } else {
        return this.parentLogger.getLibrary(name); // try to get it from parent(s)
      }

    } else {
      if (this.parentLogger !== undefined) {
        return this.parentLogger.getLibrary(name); // try to get it from parent(s)
      } else {
        return undefined;  // the buck stops here, if no parent and we don't have it, it doesn't exist
      }
    }
  }

  isGloballyEnabled(logger: Logger): boolean {
    // for a given logger, look to see if we are the rootLogger (based on whether or not we have
    // the myLoggingService attribute defined).  If we are, look at the boolean in the LoggingService
    // to see if logging has been globally disabled or not.
    if (logger.myLoggingService === undefined) {
      if (logger.parentLogger === undefined) {
        return true;  // by default we'll be enabled if everything is undefined
      } else {
        return this.isGloballyEnabled(logger.parentLogger);
      }
    } else {
      return logger.myLoggingService.globalLoggingEnabledFlag;
    }
  }

  // configuration for various sublibraries. to start with, we see datadog:
  // this function needs to be invoked by the environment service who will have this key
  initDatadog = function(clientToken: string) {
    try {
      this.getLibrary('datadog').init({
        clientToken: clientToken,
        datacenter: 'us',
        forwardErrorsToLogs: false,
        sampleRate: 100
      });
    } catch (err) {
      this.error('Unable to init datadog', {'errorObject': err});
    }
  }

  calculateMetadata(callerMetadata?: object): object {
    let finalMetadata: object = {};

    // start first by calculating the parent's metadata
    if (this.parentLogger !== undefined) {
      finalMetadata = this.parentLogger.calculateMetadata()
    }

    // then merge in this classes' metadata
    finalMetadata = {...finalMetadata, ...this.metadata}

    // and finally, any metaata passed in
    if (callerMetadata !== undefined) {
      finalMetadata = {...finalMetadata, ...callerMetadata}
    }

    return finalMetadata;
  }

  // create a new logger as a 'child' of this
  // object. Laying the foundation for future abilities
  // of formattting and silencing of logs by class
  newLogger(newLoggerName: string) {
    const child: Logger = new Logger(this.loggerName + '.' + newLoggerName, this);
    this.childLoggers.push(child);
    return child;
  }

  // MAIN INBOUND LOGGING FUNCTIONS
  info(message: string, callerMetadata?: object): void {
    this.receiveLoggingEvent({message: message, logLevelInt: LogLevel.INFO, metadata: callerMetadata, stackByErrorObj: new Error(`(LogTrace) ${message}`)});
  }

  error(message: string, callerMetadata?: object): void {
    let errorObject;
    if (callerMetadata && callerMetadata['errorObject']) {
      if (callerMetadata['errorObject'] instanceof Error) {
        errorObject = callerMetadata['errorObject'];
      } else {
        errorObject = new Error(message);
      }
    } else {
      errorObject = new Error(message);
    }
    this.receiveLoggingEvent({message: message, logLevelInt: LogLevel.ERROR, metadata: callerMetadata, stackByErrorObj: errorObject });
  }

  errorObj(errorObject: Error, callerMetadata?: object): void {
    let localMetadata: LogMetadata = callerMetadata;
    if (localMetadata === undefined) {
      localMetadata = {}
    }
    localMetadata._errorObject = errorObject;
    this.receiveLoggingEvent({message: errorObject.message, logLevelInt: LogLevel.ERROR, metadata: localMetadata, stackByErrorObj: new Error(`(LogTrace) ${errorObject.message}`)})
  }

  // the main point of handling logs. it receives new logging events from callers,
  // then passes it back out to destinations.
  async receiveLoggingEvent(logEntry: LogEntry): Promise<void> {
    if (!this.configuration.enable || !this.isGloballyEnabled(this)) {
      return; // disabled
    }

    if (logEntry.message === undefined) {
      this.error('Invalid Log Invocation, no message was set.', {'errorObject': logEntry});
      return;
    }

    if (logEntry.loggerName === undefined) {
      logEntry.loggerName = this.loggerName
    }

    if (logEntry.logLevel === undefined) {
      if (logEntry.logLevelInt === undefined) {  // if there is also no Int level set, we got an incomplete invocation
        this.error('Invalid Log Invocation, no logLevelInt was set.', {'errorObject': logEntry});
        return;
      }
      logEntry.logLevel = LogLevel[logEntry.logLevelInt]
    }

    // compute the metadata we need and apply it to the LogEntry
    logEntry.metadata = this.calculateMetadata(logEntry.metadata);

    // check metadata for any reserved attributes
    if (logEntry.metadata._errorObject !== undefined) {
      if (!(logEntry.metadata._errorObject instanceof Error)) {
        this.error('Saw a reserved attribute _errorObject in the log metadata, but it wasnt an Error object.', {'errorObject': logEntry});
        return;
      }
    }
    if (logEntry.metadata._sourceStamp !== undefined) {
      if (logEntry.metadata._sourceStamp !== 'frontendLoggingService') {
        this.error('Saw reserved attribute _sourceStamp defined. This key is reserved for the logging service.', {'errorObject': logEntry});
        return;
      }
    } else {
      logEntry.metadata._sourceStamp = 'frontendLoggingService';
    }

    if (logEntry.metadata._fullstory_link !== undefined) {
      this.error('Saw reserved attribute _fullstory_link. This key is reserved for the logging service. It will be added dynamically.', {'errorObject': logEntry});
      return;
    }

    // if fullstory is enabled, log the current URL
    if (this.getLibrary('fullstory') != null) {
        logEntry.metadata._fullstory_link = this.getLibrary('fullstory').getCurrentSessionURL(true)
    } else {
        logEntry.metadata._fullstory_link = null
    }

    if (this.isInAsyncMode) {
      setTimeout(() => this.syncEmitLog(logEntry), 0);
    } else {
      this.syncEmitLog(logEntry);
    }
  }

  // low level function (not intended for direct use) to emit logs
  // in a syncronous fashion.
  syncEmitLog(logEntry: LogEntry): void {
    let logMessage = '';
    logMessage += '[' + logEntry.logLevel + ']' + ' ' + logEntry.loggerName + ' -> '
    logMessage += `${logEntry.message} `;

    // create a shallow(!) copy of the logEntry without the error object
    // it isn't necessary (and is verbose) for most cases
    const logEntryNoStackFromObj = Object.assign({}, logEntry)
    delete logEntryNoStackFromObj.stackByErrorObj;

    // and finally output
    if (this.configuration.enableOutputConsole === true) {
      console.log(logMessage)
      console.log(logEntryNoStackFromObj)
    }

    // put fullstory into sentry as a breadcrumb
    this.getLibrary('sentry').addBreadcrumb({
      category: 'fullStoryUrl',
      message: logEntry.metadata['_fullstory_link'],
    });

    // output info messages to sentry
    if (logEntry.logLevel === LogLevel[LogLevel.INFO] && this.configuration.enableOutputSentryInfos === true) {
      this.getLibrary('sentry').captureMessage(logEntry.message);
    }

    // output to sentry
    if (logEntry.logLevel === LogLevel[LogLevel.ERROR] && this.configuration.enableOutputSentryErrors === true) {
      let exceptionStringified = '';
      if (logEntry.metadata['_errorObject'] !== undefined) {
        exceptionStringified = JSON.stringify(logEntry.metadata['_errorObject']);
      }

      // put full trace into sentry as a breadcrumb
      this.getLibrary('sentry').addBreadcrumb({
        category: 'fullJsonError',
        message: exceptionStringified,
        level: this.getLibrary('sentry').Severity.Error
      });

      const err = logEntry.stackByErrorObj;
      this.getLibrary('sentry').withScope( (scope) => {
        // group errors together in sentry based on 3 attributes below (if they exist)
        if (err && err.error_type && err.message && err.trace) {
          scope.setFingerprint([logEntry.message, err.error_type, err.message, err.trace]);
        }
        this.getLibrary('sentry').captureException(err);
      });
    }

    // output to datadog
    if (this.configuration.enableDatadog) {
      this.getLibrary('datadog').logger.info(logEntry.message, logEntryNoStackFromObj);
    }
  }
}

