<div class="button-container">
    <button *ngIf="hasIntegration('qbo')" (click)="startIntegrationImport('qbo')"
            class="btn btn-light" type="button">
        <span>Import from QBO</span>
    </button>
    <button *ngIf="hasIntegration('xero')" (click)="startIntegrationImport('xero')"
            class="btn btn-light" type="button">
        <span>Import from Xero</span>
    </button>
    <button *ngIf="hasManageDocumentPermission" (click)="openUploadDocumentModal()"
            class="btn btn-light-primary mb-1" type="button">
        <i class="fa-solid fa-arrow-up-from-bracket"></i>
        <span>Upload Documents</span>
    </button>
</div>


