import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormatCurrencyPipe } from './format-currency.pipe';
import { MapToIterablePipe } from './map-to-iterable.pipe';
import { FromUpperToTitlePipe } from './from-upper-to-title.pipe';
import { PeriodTypePipe } from './period-type.pipe';
import { FormatDatePipe } from './format-date';
import { DateToPeriodPipe } from './date-to-period.pipe';
import { ToNonBreaking } from './to-nb';
import { DocTypePipe, DocTypeLabelPipe, FormulaOptionTypeLabelPipe } from './doc-type';
import { DataFrameValueFormatterPipe } from './data-frame-value-formatters';
import { DocIconPipe } from './doc-icon';
import { MathIconPipe } from './math-icon.pipe';
import { UnderscorelessPipe } from './underscoreless';
import { ReportingPeriodToPeriodType } from './reporting-period-to-period-type';
import { ReportingPeriodPipe } from './reporting-period.pipe';
import { EnumToArrayPipe } from './enum-to-array-pipe';
import { FormatPrepTypePipe } from './format-prep-type.pipe';

@NgModule({
  declarations: [
    FormatCurrencyPipe,
    MapToIterablePipe,
    FromUpperToTitlePipe, 
    PeriodTypePipe,
    FormatDatePipe,
    DateToPeriodPipe,
    ToNonBreaking,
    DocTypePipe,
    DataFrameValueFormatterPipe,
    DocTypeLabelPipe,
    FormulaOptionTypeLabelPipe,
    DocIconPipe,
    MathIconPipe,
    UnderscorelessPipe,
    ReportingPeriodToPeriodType,
    ReportingPeriodPipe,
    EnumToArrayPipe,
    FormatPrepTypePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FormatCurrencyPipe,
    MapToIterablePipe,
    FromUpperToTitlePipe,
    PeriodTypePipe,
    FormatDatePipe,
    DateToPeriodPipe,
    ToNonBreaking,
    DocTypePipe,
    DataFrameValueFormatterPipe,
    DocTypeLabelPipe,
    FormulaOptionTypeLabelPipe,
    DocIconPipe,
    MathIconPipe,
    UnderscorelessPipe,
    ReportingPeriodToPeriodType,
    ReportingPeriodPipe,
    EnumToArrayPipe,
    FormatPrepTypePipe
  ]
})
export class PipesModule {
}
