import {Component, OnInit, Input, ChangeDetectorRef, ViewChild} from '@angular/core';
import {SpreadingTemplateService} from '../../../../../services/spreading-template.service';
import {TaxMappingLineItem} from '@models/tax-mapping-line-item';
import {INCOME_STATEMENT, DISPLAY_NAME_1065} from '@utils/constants';
import {AlertService} from '../../../../../services/alert.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ConfirmationPopupComponent} from '@components/shared/popups/confirmation/confirmation-popup.component';
import {NgxPopupService} from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';

@Component({
  selector: 'app-edit-template-mappings',
  templateUrl: './edit-template-mappings.component.html',
  styleUrls: ['./edit-template-mappings.component.scss']
})
export class EditTemplateMappingsComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() templateId = null;
  @Input() templateName = '';
  @Input() incomeStatementTaxonomyItems = null;
  @Input() balanceSheetTaxonomyItems = null;
  saveButtonDisabled = true;
  selectedTaxFormItems = [];
  selectedTaxFormType = DISPLAY_NAME_1065;
  savedTaxFormMappings: { [taxFormType: string]: Array<TaxMappingLineItem>; } = {};
  newOverrides: { [taxFormType: string]: Array<TaxMappingLineItem>; } = {};
  readonly nullTaxonomyItemOption = {
    label: 'Unmapped',
    standardLineItemId: 0,
    descriptionEditMode: false,
    originalNoteValue: null
  };
  readonly INCOME_STATEMENT = INCOME_STATEMENT;

  constructor(
    private _alertService: AlertService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private _popupService: NgxPopupService
  ) {
  }

  ngOnInit(): void {
    this._spreadingTemplateService.getTaxFormMappingsForSpreadingTemplate(this.templateId).subscribe(data => {
      this.savedTaxFormMappings = data;
      Object.entries(this.savedTaxFormMappings).forEach(([taxFormType, taxFormItems]) => {
        for (const lineItem of taxFormItems) {
          const mappedTaxonomyItem = this.retrieveTaxonomyItemById(lineItem);
          if (mappedTaxonomyItem !== undefined) {
            lineItem.mappedTaxonomyItemLabel = mappedTaxonomyItem.label;
            lineItem.mappingOptions = lineItem.defaultTaxonomyItemId === 0 ? [this.nullTaxonomyItemOption] : [];
            lineItem.mappingOptions.push(mappedTaxonomyItem);
          } else {
            lineItem.mappingOptions = [this.nullTaxonomyItemOption];
          }
          lineItem.currentStateOfOverrideIdToBeSaved = lineItem.taxonomyItemIdOverride;
          lineItem.descriptionEditMode = false;
          lineItem.originalNoteValue = lineItem.note;
        }
      });
      this.changeTaxForm(this.selectedTaxFormType);
      this.newOverrides = {};
    });
  }

  retrieveTaxonomyItemById(lineItem: TaxMappingLineItem) {
    for (const taxonomyItem of lineItem.statementType === INCOME_STATEMENT ? this.incomeStatementTaxonomyItems : this.balanceSheetTaxonomyItems) {
      if (lineItem.taxonomyItemIdOverride === null && taxonomyItem.standardLineItemId === lineItem.defaultTaxonomyItemId) {
        return taxonomyItem;
      } else if (lineItem.taxonomyItemIdOverride !== null && taxonomyItem.standardLineItemId === lineItem.taxonomyItemIdOverride) {
        return taxonomyItem;
      }
    }
  }

  changeTaxForm(selectedTaxFormType: string) {
    this.selectedTaxFormType = selectedTaxFormType;
    this.selectedTaxFormItems = this.savedTaxFormMappings[this.selectedTaxFormType];
  }

  setMappingOptionsForLineItem(lineItem: TaxMappingLineItem) {
    const initialMappingOptions = [this.nullTaxonomyItemOption];
    return lineItem.statementType === INCOME_STATEMENT ? initialMappingOptions.concat(this.incomeStatementTaxonomyItems) : initialMappingOptions.concat(this.balanceSheetTaxonomyItems);
  }

  setOverrideForTaxLineItem(selectedOverrideId: any, selectedLineItem: TaxMappingLineItem) {
    selectedOverrideId = selectedOverrideId === null || selectedOverrideId === 0 || Number(selectedOverrideId) === selectedLineItem.defaultTaxonomyItemId ? null : selectedOverrideId = Number(selectedOverrideId);
    this.savedTaxFormMappings[this.selectedTaxFormType].forEach((item) => {
      if (item.lineItemName === selectedLineItem.lineItemName) {
        const itemFromNewOverrides = this.retrieveLineItemFromNewOverrides(item.lineItemName);
        if (itemFromNewOverrides === undefined) {
          if (selectedOverrideId !== item.taxonomyItemIdOverride) {
            this.addItemToNewOverrides(item, selectedOverrideId)
          }
        } else if (selectedOverrideId !== item.taxonomyItemIdOverride && selectedOverrideId !== itemFromNewOverrides.taxonomyItemIdOverride) {
          this.updateOverrideIdForItemInNewOverrides(selectedOverrideId, item, itemFromNewOverrides);
        } else if (selectedOverrideId === item.taxonomyItemIdOverride) {
          if (selectedLineItem.note === selectedLineItem.originalNoteValue) {
            this.removeItemFromNewOverrides(item);
          }
        }
      }
    });
  }

  retrieveLineItemFromNewOverrides(lineItemName) {
    if (this.selectedTaxFormType in this.newOverrides) {
      for (const lineItem of Array.from(this.newOverrides[this.selectedTaxFormType])) {
        if (lineItem.lineItemName === lineItemName) {
          return lineItem;
        }
      }
    }
  }

  addItemToNewOverrides(item: TaxMappingLineItem, selectedOverrideId: any) {
    if (!(this.selectedTaxFormType in this.newOverrides)) {
      this.newOverrides[this.selectedTaxFormType] = [];
    }
    const newOverrideItem = JSON.parse(JSON.stringify(item)) as TaxMappingLineItem;
    newOverrideItem.taxonomyItemIdOverride = selectedOverrideId;
    newOverrideItem.mappedTaxonomyItemLabel = undefined;
    newOverrideItem.currentStateOfOverrideIdToBeSaved = undefined;
    newOverrideItem.mappingOptions = undefined;
    newOverrideItem.defaultTaxonomyItemId = undefined;
    newOverrideItem.originalNoteValue = undefined;
    newOverrideItem.descriptionEditMode = undefined;
    this.newOverrides[this.selectedTaxFormType].push(newOverrideItem);
    this.saveButtonDisabled = false;
    item.currentStateOfOverrideIdToBeSaved = selectedOverrideId;
  }

  updateOverrideIdForItemInNewOverrides(selectedOverrideId: any, item: TaxMappingLineItem, itemFromNewOverrides: TaxMappingLineItem) {
    itemFromNewOverrides.taxonomyItemIdOverride = selectedOverrideId;
    item.currentStateOfOverrideIdToBeSaved = selectedOverrideId;
    this.saveButtonDisabled = false;
  }

  removeItemFromNewOverrides(item: TaxMappingLineItem) {
    this.newOverrides[this.selectedTaxFormType] = Array.from(this.newOverrides[this.selectedTaxFormType]).filter(function (overrideItem) {
      return overrideItem.lineItemName !== item.lineItemName;
    });
    item.currentStateOfOverrideIdToBeSaved = null;
    if (this.newOverrides[this.selectedTaxFormType].length === 0) {
      delete this.newOverrides[this.selectedTaxFormType];
      if (Object.keys(this.newOverrides).length === 0) {
        this.saveButtonDisabled = true;
      }
    }
  }

  saveTaxFormMappingOverrides() {
    const data = {
      'taxFormMappingOverrides': this.newOverrides,
    };
    this._spreadingTemplateService.updateTaxFormMappingOverrides(this.templateId, data).subscribe(result => {
      this._alertService.success('Saved Successfully! It may take a moment for your data to update');
      this.saveButtonDisabled = true;
      this.ngOnInit();
    }, error => {
      this._alertService.error(error.message);
    });
  }

  downloadTaxFormMappingsToExcel() {
    this._spreadingTemplateService.downloadTaxFormMappingsToExcel(this.templateId, this.templateName)
  }

  launchRestoreAllMappingsModal() {
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'RESTORE ALL DEFAULTS?',
        text: 'Are you sure you want to restore all mappings to their default values? This action cannot be undone.',
        confirmButtonText: 'Yes'
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.updateAllTaxFormMappingsToDefaultForTaxForm()
          }
        }
      },
    });
  }

  updateAllTaxFormMappingsToDefaultForTaxForm() {
    this.savedTaxFormMappings[this.selectedTaxFormType].forEach((item) => {
      // 2 places where the items can occur, in saved mappings or new overrides, or both
      const itemFromNewOverrides = this.retrieveLineItemFromNewOverrides(item.lineItemName);
      if (item.taxonomyItemIdOverride && itemFromNewOverrides === undefined) {
        // item came from db and has not been edited, need to add it to overrides to save
        this.addItemToNewOverrides(item, null)
        this.setMappingOptionsForLineItem(item);
        return
      }
      if (itemFromNewOverrides === undefined) {
        // if the mapping doesn't exit in the new overrides line item doesn't have any associated overrides
        return
      }
      if (item.taxonomyItemIdOverride && itemFromNewOverrides.taxonomyItemIdOverride !== null) {
        // item came from the db and has been edited to a non-default mapping but not saved
        this.updateOverrideIdForItemInNewOverrides(null, item, itemFromNewOverrides);
      } else if (itemFromNewOverrides.taxonomyItemIdOverride !== null) {
        // value has been only edited and not saved, so it only exists in new overrides
        this.removeItemFromNewOverrides(item);
      }
      this.setMappingOptionsForLineItem(item);
    });
  }

  switchLineItemNoteEditMode(item: TaxMappingLineItem) {
    item.descriptionEditMode = !item.descriptionEditMode
  }

  setNewNoteTaxLineItem(selectedLineItem: TaxMappingLineItem) {
    if (selectedLineItem.note.trim() === '') {
      selectedLineItem.note = null
    }

    const itemFromNewOverrides = this.retrieveLineItemFromNewOverrides(selectedLineItem.lineItemName);
    const newNoteValue = selectedLineItem.note
    if (itemFromNewOverrides === undefined) {
      if (newNoteValue !== selectedLineItem.originalNoteValue) {
        this.addItemToNewOverrides(selectedLineItem, null)
      }
    } else if (itemFromNewOverrides.taxonomyItemIdOverride) {
      itemFromNewOverrides.note = newNoteValue;
      this.saveButtonDisabled = false;
    } else if (!itemFromNewOverrides.taxonomyItemIdOverride) {
      if (!newNoteValue || (selectedLineItem.originalNoteValue === newNoteValue)) {
        this.removeItemFromNewOverrides(selectedLineItem)
      } else {
        itemFromNewOverrides.note = newNoteValue;
        this.saveButtonDisabled = false;
      }
    }
  }
}
