import { Deserializable } from './deserializable';

export class LineItemObject implements Deserializable {
  name: string;
  id: number;
  uniqueKey: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    // Convert strings to number IDs
    if (typeof(this.id) === 'string') {
      this.id = parseInt(this.id, 10);
    }
    return this;
  }
}


export class LineItemMatch {
  incomeStatement: Array<number>;
  balanceSheet: Array<number>;

  constructor(is, bs) {
    this.incomeStatement = is;
    this.balanceSheet = bs;
    return this;
  }
}
