<div class="background">
    <div class="content">
        <div class="custom-card">
            <div class="custom-card-header">
                <div class="title float-start">
                    Switch Tenant
                </div>
                <div class="float-end logo">
                    <img src="../../../../assets/images/Numerated-2023-logo.svg"/>
                </div>
            </div>
            <div class="card-content">
                    <div class="form-header">
                        <p>Please, select a Tenant Name:</p>
                    </div>

                    <div class="mb-3">
                        <select id="bankIds" name="banks" class="form-select" [(ngModel)]="selectedBankId">
                            <option *ngFor="let tenantName of tenants">{{ tenantName }}</option>
                        </select>
                    </div>

                    <button type="submit" (click)="setTenantName(selectedBankId)" class="btn btn-primary">
                        Submit
                    </button>

                    <p class="customId">
                        <a class="link-primary" id="customId" (click)="inputCustomId()">
                            Custom
                        </a>
                    </p>
            </div>
        </div>
    </div>
</div>
