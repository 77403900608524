import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../../../services/shared-data.service';
import { SpreadingTemplateService } from '../../../../services/spreading-template.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-list-spreading-templates',
  templateUrl: './list-spreading-templates.component.html',
  styleUrls: ['./list-spreading-templates.component.scss']
})
export class ListSpreadingTemplatesComponent implements OnInit {
  templates = [];
  templateType = 'STANDARD';
  showDisabledTemplates = false

  constructor(
    private _sharedDataService: SharedDataService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this._sharedDataService.pageHeaderTitle$.next('Templates');
    this.getTemplates();
  }

  getTemplates(): void {
    this._spreadingTemplateService.getTemplates(this.templateType).subscribe((data) => {
      data.sort((a, b) => (a.active < b.active) ? 1 : ((b.active < a.active) ? -1 : 0))
      this.templates = data;
    });
  }

  changeType(templateType: string): void {
    this.templateType = templateType;
    this.getTemplates();
  }

  toggleShowDisabledTemplates(): void {
    this.showDisabledTemplates = !this.showDisabledTemplates
  }

  refreshTemplates() {
    this.getTemplates();
  }

  navigateToTemplateEditor() {
    if (this.templateType === 'GLOBAL_CASHFLOW') {
      this.router.navigate(['/spreading-templates/', 'multi-entity', 'new'])
    } else {
      this.router.navigate(['/spreading-templates/', 'new', this.templateType])
    }
  }
}
