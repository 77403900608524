import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '@models/company';
import { DataViewColumn, DataViewRow } from '@models/dataview';
import { TemplateItem } from '@models/template-item';
import { NON_SPREADABLE_COLUMN_PREP_TYPES } from '@utils/constants';
import { StatementBuilderMode } from '@utils/enums';

export interface StatmentBuilderOption {
  title: string;
  subtitle: string;
  mode: StatementBuilderMode;
  disabled: boolean;
}

@Component({
  selector: 'app-statement-builder-flyout',
  templateUrl: './statement-builder-flyout.component.html',
  styleUrls: ['./statement-builder-flyout.component.scss']
})
export class StatementBuilderFlyoutComponent implements OnInit {
  @Input() company: Company;
  @Input() embeddedMode = false;
  @Input() columns: DataViewColumn[];
  @Input() rows: DataViewRow[];
  @Input() spreadingTemplateItems: any[];
  @Input() statementBuilderEditMode?: StatementBuilderMode;
  @Input() frameToBeEditedId?: any;
  @Output() closeStatementBuilderFlyout = new EventEmitter<any>();

  mode: StatementBuilderMode;
  statementBuilderHeaderText = '';
  statementBuilderOptions: StatmentBuilderOption[] = [
    {
      title: 'Consolidate Periods',
      subtitle: 'Consolidate multiple statements into one consolidated statement',
      mode: StatementBuilderMode.Consolidate,
      disabled: false
    },
    {
      title: 'Calculate A Missing Period',
      subtitle: 'Calculate missing data such as a monthly or quarterly period',
      mode: StatementBuilderMode.CalcMissingPeriod,
      disabled: false
    },
    {
      title: 'Forecast a Period',
      // TODO: what should this text be?
      subtitle: 'Generate a forecast from a reference period',
      mode: StatementBuilderMode.GenerateForecast,
      disabled: false
    },
  ]
  columnsForConsolidationAndMissingPeriodCalcs: DataViewColumn[] = [];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.mode = !!this.statementBuilderEditMode ? this.statementBuilderEditMode : StatementBuilderMode.Options;
    this.setStatementBuilderHeaderText();

    // if we are in embedded mode, do not show original statement builder as option
    if (!this.embeddedMode) {
      this.statementBuilderOptions.push({
        title: 'Original Statement Builder',
        subtitle: 'Utilize original statement builder tool to construct a statement',
        mode: StatementBuilderMode.OriginalStatementBuilder,
        disabled: false
      });
    }

    // filter out combined frames and imported from being used in statement builder (may change this in future)
    this.columnsForConsolidationAndMissingPeriodCalcs = this.columns.filter(c => !NON_SPREADABLE_COLUMN_PREP_TYPES.includes(c.preparationType));
    this.setDisabledFlagForStatementBuilderOptions();
  }

  close(newStatementCreated=false) {
    this.closeStatementBuilderFlyout.emit(newStatementCreated);
  }

  setDisabledFlagForStatementBuilderOptions() {
    const numColumns = this.columns.length;
    this.statementBuilderOptions.forEach(option => option.disabled = numColumns === 0);
  }

  setStatementBuilderHeaderText() {
    switch(this.mode) {
      case StatementBuilderMode.Consolidate: {
         this.statementBuilderHeaderText = 'Consolidate Periods';
         break;
      }
      case StatementBuilderMode.CalcMissingPeriod: {
         this.statementBuilderHeaderText = 'Calculate a Missing Period';
         break;
      }
      case StatementBuilderMode.GenerateForecast: {
        this.statementBuilderHeaderText = 'Forecast a Period';
        break;
     }
      default: {
         this.statementBuilderHeaderText = 'Statement Builder'
         break;
      }
   }
  }

  selectStatementBuilderOption(mode) {
    this.mode = mode;
    this.setStatementBuilderHeaderText();

    if (this.mode === 'original-statement-builder') {
      this.router.navigate(['/companies/', this.company.uuid, 'financials', 'build'])
    }
  }
}
