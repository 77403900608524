<div>
    <div id="main">
        <div class="row" *ngIf="user">
            <div class="col-sm-12 col-md-8 offset-md-2">
                <div class="profile">

                    <div *ngIf="!isEditing" class="row profile__header">
                        <div class="col-sm-2 photo">
                            <i *ngIf="!user.img" class="fas fa-user-circle no-photo"></i>
                            <img *ngIf="user.img" [src]="sanitizer.bypassSecurityTrustResourceUrl(user.img)"
                                 alt="">
                        </div>
                        <div class="col-sm-6 margin-top">
                            <div class="username">
                                {{user.firstName}} {{user.lastName}}
                            </div>
                            <div class="sub-title">
                                {{getUserRole(user)}}&nbsp;&nbsp;|&nbsp;&nbsp;{{user.email}}
                            </div>
                        </div>
                        <div class="col-sm-2 offset-sm-2">
                            <!-- <button class="btn btn-default pull-right" (click)="toggleForm()">Edit</button> -->
                        </div>
                    </div>

                    <div *ngIf="isEditing" class="row profile__header">
                        <form [formGroup]="userForm" (submit)="save(userForm.value, userForm.valid)">
                            <div class="col-sm-2 photo">
                                <i *ngIf="!user.img" class="fas fa-user-circle no-photo"></i>
                                <img *ngIf="user.img"
                                     [src]="sanitizer.bypassSecurityTrustResourceUrl(user.img)">
                                <div class="upload-btn">
                                    <button type="button" class="btn btn-primary"
                                            onclick="document.getElementById('file').click();">
                                        <i class="fas fa-camera"></i>Upload
                                    </button>
                                    <input id="file" style="display: none;" type="file" ng2FileSelect
                                           [uploader]="uploader" accept="image/*"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="mb-3"
                                             [ngClass]="{'has-error': isFieldInvalid('name')}">
                                            <input type="text" class="form-control" id="name"
                                                   formControlName="name" maxlength="25" placeholder="Full Name">

                                            <span class="help-block"
                                                  *ngIf="isFieldInvalid('name') && userForm.get('name').hasError('required')">
                                                Name is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="userService.isAdmin()" class="mb-3">
                                    <div class="input-group" formGroupName="roles">
                                        <span class="input-group-addon"><i class="fas fa-user"></i></span>
                                        <label class="control-label"> </label>
                                        <div class="checkbox">
                                            <label>
                                                <input type="checkbox" name="admin" formControlName="admin">
                                                <span style="vertical-align: sub">Administrator</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3" [ngClass]="{'has-error': isFieldInvalid('email')}">
                                    <div class="input-group">
                                        <span class="input-group-addon"><i class="fas fa-envelope"></i></span>
                                        <input class="form-control" type="text" formControlName="email">
                                    </div>
                                    <span class="help-block"
                                          *ngIf="isFieldInvalid('email') && userForm.get('email').hasError('pattern')">
                                        Email is not valid
                                    </span>
                                    <span class="help-block"
                                          *ngIf="isFieldInvalid('email') && userForm.get('email').hasError('required')">
                                        Email is required
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="float-end">
                                    <button class="btn btn-link" type="reset" (click)="toggleForm(true)">Cancel</button>
                                    <button class="btn btn-primary" type="submit" [disabled]="submitted">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="cham-panel">
                        <div class="cham-panel__header">
                            Account Information
                        </div>
                        <div class="cham-panel__content">
                            <div class="row">
                                <div class="col-3 title">
                                    Status
                                </div>
                                <div class="col-6 text">
                                    <i *ngIf="user.active" class="fas fa-check-square blue"></i>
                                    <i *ngIf="!user.active" class="fas fa-times-circle red"></i>
                                    &nbsp;
                                    <a *ngIf="!myProfile" href="javascript:void(0)" (click)="changeUserStatus()">
                                        {{user.active ? 'Active' : 'Inactive'}}
                                    </a>
                                    <span *ngIf="myProfile">
                                        {{user.active ? 'Active' : 'Inactive'}}
                                    </span>
                                </div>
                            </div>

                            <!-- <div *ngIf="!isEditingPassword" class="row">
                                <div class="col-xs-3 title">
                                    Password
                                </div>
                                <div class="col-xs-6 text">
                                    <a href="javascript:void(0)" (click)="togglePasswordForm(true)">Change</a>
                                </div>
                            </div>

                            <form *ngIf="isEditingPassword" [formGroup]="passwordForm" class="form-horizontal"
                                  (submit)="updatePassword(passwordForm.value, passwordForm.valid)">
                                <div *ngIf="myProfile" class="mb-3"
                                     [ngClass]="{'has-error': isPasswordFieldInvalid('oldPassword') || apiErrorMessage}">
                                    <label for="oldPassword" class="col-sm-3 control-label">Old Password</label>
                                    <div class="col-sm-6">
                                        <input id="oldPassword" class="form-control" formControlName="oldPassword"
                                               type="password">
                                        <span class="help-block"
                                              *ngIf="isPasswordFieldInvalid('oldPassword') &&
                                              passwordForm.get('oldPassword').hasError('required')">
                                            Old Password is required
                                        </span>
                                        <span class="help-block" *ngIf="apiErrorMessage">
                                            {{apiErrorMessage}}
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3"
                                     [ngClass]="{'has-error': isPasswordFieldInvalid('newPassword')}">
                                    <label for="newPassword" class="col-sm-3 control-label">New Password</label>
                                    <div class="col-sm-6">
                                        <input id="newPassword" class="form-control" formControlName="newPassword"
                                               type="password" appFocus [activateFocus]="focusPass"
                                               validateEqual="passwordConfirm" reverse="true">
                                        <span class="help-block"
                                              *ngIf="isPasswordFieldInvalid('newPassword') &&
                                              passwordForm.get('newPassword').hasError('pattern')">
                                            Password must be at least 8 characters long <br>and contain a digit, an uppercase letter,<br>
                                            and a lowercase letter.
                                        </span>
                                        <span class="help-block"
                                              *ngIf="isPasswordFieldInvalid('newPassword') &&
                                              passwordForm.get('newPassword').hasError('required')">
                                            Password is required
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3"
                                     [ngClass]="{'has-error': isPasswordFieldInvalid('newPasswordConfirm')}">
                                    <label for="newPasswordConfirm" class="col-sm-3 control-label">Confirm
                                        Password</label>
                                    <div class="col-sm-6">
                                        <input id="newPasswordConfirm" class="form-control"
                                               formControlName="newPasswordConfirm" type="password"
                                               #passwordConfirm validateEqual="newPassword" reverse="false">
                                        <span class="help-block"
                                              *ngIf="isPasswordFieldInvalid('newPasswordConfirm') &&
                                              passwordForm.get('newPasswordConfirm').hasError('required')">
                                            Confirm Password is required
                                        </span>
                                        <span class="help-block"
                                              *ngIf="isPasswordFieldInvalid('newPasswordConfirm') &&
                                              passwordForm.get('newPasswordConfirm').hasError('validateEqual')">
                                            Password mismatch
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-sm-6 col-sm-offset-3">
                                        <div class="pull-right">
                                            <button class="btn btn-link" type="reset" (click)="togglePasswordForm()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit"
                                                    [disabled]="submittedPassword">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
