import { Component, OnInit, Input } from '@angular/core';
import { GraphService } from '../../../../../../services/graph.service';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-ccc-graph-tile',
  templateUrl: './ccc-graph-tile.component.html',
  styleUrls: ['./ccc-graph-tile.component.scss']
})
export class CccGraphTileComponent implements OnInit {
  percentChange = '0.60%';
  value = '';
  cccGraphData = [];
  @Input() mostRecentStatementDate = '';
  @Input() analyticsData;
  @Input() periodType = '';

  constructor(
    private _graphService: GraphService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.cccGraphData = this._graphService.barChartDataStructure(
      this.analyticsService.getDataByLineItem('REF_CASH_CONVERSION_CYCLE', this.analyticsData),
      this.analyticsData['periods'],
    );

    if (this.isCccGraphDataAvailable()) {
      this.value = this.analyticsService.formattedValue('REF_CASH_CONVERSION_CYCLE', this.analyticsData);
    }
  }

  isCccGraphDataAvailable(): boolean {
    const filteredValues = this.cccGraphData.map( (data) => data.value).filter( value => value !== null);
    return filteredValues.length > 0;
  }

}
