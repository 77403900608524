import { mergeMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { Subscription ,  of } from 'rxjs';
import { User } from '../../../../models/user';
import { AlertService } from '../../../../services/alert.service';
import { ApiService } from '../../../../services/api.service';
import { UserService } from '../../../../services/user.service';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { mergeObject } from '../../../../utils/functions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class UserProfileComponent implements OnInit {
  user: User;
  subsArr$: Subscription[] = [];
  isEditing: boolean;
  isEditingPassword: boolean;
  submitAttempt = false;
  submitPasswordAttempt = false;
  submitted = false;
  submittedPassword = false;
  userForm: FormGroup;
  passwordForm: FormGroup;
  uploader: FileUploader;
  myProfile: boolean;
  focusPass = false;
  apiErrorMessage = '';

  constructor(public userService: UserService,
              private alertService: AlertService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) {
    this.isEditing = this.isEditingPassword = false;
    this.myProfile = true;
  }

  ngOnInit() {
    this.subsArr$.push(this.activatedRoute.params.pipe(mergeMap((params: Params) => {
        const userId = +params['id'] || false;
        if (userId) {
          return this.userService.getUser(userId);
        }
        return this.userService.getCurrentUser();
      }), mergeMap(user => {
        this.user = user;
        this.myProfile = this.user.id === this.userService.user.id;
        return of(true);
      }), ).subscribe()
    );
  }

  toggleForm(reset: boolean = false): void {
    this.isEditing = !this.isEditing;
    if (reset) {
      this.userForm.reset(
        {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          active: this.user.active,
          password: this.user.password,
        }
      );
    }
  }

  isFieldInvalid(field: string): boolean {
    return (
      (this.userForm.get(field).invalid && this.userForm.get(field).dirty) ||
      (this.userForm.get(field).invalid && this.submitAttempt)
    );
  }

  isPasswordFieldInvalid(field: string): boolean {
    return (
      (this.passwordForm.get(field).invalid && this.passwordForm.get(field).dirty) ||
      (this.passwordForm.get(field).invalid && this.submitPasswordAttempt)
    );
  }

  togglePasswordForm(focusPasswordInput: boolean = false): void {
    this.isEditingPassword = !this.isEditingPassword;
    if (this.isEditingPassword) {
      this.focusPass = focusPasswordInput;
    } else {
      this.passwordForm.reset();
      this.apiErrorMessage = '';
      this.submitPasswordAttempt = false;
      this.submittedPassword = false;
    }
  }

  getUserRole(user: User): string {
    if (user.admin) {
        return 'Administrator';
    }
    return 'User';
  }

  save(userValue: any, valid: boolean): void {
    this.submitAttempt = true;
    if (!valid || this.submitted) {
      return;
    }
    this.submitted = true;

    const r = userValue.roles;
    let roleChanged = false;
    if (r.role !== this.user.roles[0].role) {
      roleChanged = true;
    }
    if (roleChanged && !Array.isArray(userValue.roles)) {
      userValue.roles = [];
      userValue.roles.push(r);
    } else {
      delete userValue['roles'];
    }
    mergeObject(userValue, this.user);
    this.subsArr$.push(this.userService.updateUser(this.user)
      .subscribe(
        data => {
          if (data.length) {
            mergeObject(data.pop(), this.user);
            if (this.user.id === this.userService.user.id) {
              this.userService.user = this.user;
              if (roleChanged) {
                this.subsArr$.push(this.apiService.refreshToken().subscribe(
                  () => {
                    this.userService.decodeUserFromJwt();
                  }
                ));
              }
            }
            this.toggleForm();
          }
          this.alertService.success('User profile updated');
          this.submitted = false;
        },
        error => {
          this.alertService.error(error.message);
          this.submitted = false;
        }
      )
    );
  }

  deleteUser() {
    if (!confirm('Are you sure delete user?')) {
      return false;
    }
    this.subsArr$.push(this.userService.deleteUser(this.user.id)
      .subscribe(
        () => {
          this.alertService.success('User deleted successfully');
          this.router.navigate(['/users']);
        },
        error => {
          this.alertService.error(error.message);
        }
      )
    );
    return false;
  }

  updatePassword(passwords: any, valid: boolean): void {
    this.submitPasswordAttempt = true;
    if (!valid || this.submittedPassword) {
      return;
    }
    this.apiErrorMessage = '';
    this.submittedPassword = true;
    this.subsArr$.push(this.userService.updatePassword(passwords, this.user.id)
      .subscribe(
        data => {
          this.togglePasswordForm();
          this.alertService.success('User password updated');
          this.submittedPassword = false;
          this.submitPasswordAttempt = false;
        },
        error => {
          this.apiErrorMessage = error.message;
          this.submittedPassword = false;
          this.submitPasswordAttempt = false;
        }
      )
    );
  }

  changeUserStatus() {
    this.user.active = !this.user.active;
    this.subsArr$.push(this.userService.updateUser(this.user)
      .subscribe(
        data => {
          this.user = mergeObject(data.pop(), this.user);
          this.alertService.success('User status updated');
        },
        error => {
          this.alertService.error(error.message);
        }
      )
    );
  }
}
