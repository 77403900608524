import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of as observableOf, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SharedDataService } from './shared-data.service';
import { AppConfigService } from './app-config.service';
import { SignedUrl } from '../models/signed-url';
import { ResponseTypes } from '../utils/enums';
import { CommonFunctions } from '../utils/common-functions';
import { BYPASS_CACHE, ACCEPTABLE_ERROR_MESSAGES, BAD_REQUEST, DONT_RAISE_API_ERROR_STATUS_CODES } from '../utils/constants';
// @ts-ignore
import packageJson from '../../../package.json';
import { LoggingService, Logger } from './logging.service';
import { AlertService } from '@services/alert.service';

@Injectable()
export class ApiService {
  logger: Logger;

  private serverUrl: string;

  // Stores the bank ID for admin usage to swap between tenants
  private currentTenantName = '';

  private HttpStatusCode = {
    UnsupportedMediaType: 415
  };

  constructor(
    private alertService: AlertService,
    private _http: HttpClient,
    private _router: Router,
    private _appConfigService: AppConfigService,
    private _sharedService: SharedDataService,
    private _loggingService: LoggingService,
  ) {

    this._appConfigService.envFromHostname.subscribe((envFromHost) => {
      this.serverUrl = envFromHost.serverUrl;
    });
    this.logger = this._loggingService.rootLogger.newLogger('ApiService');
  }

  setTenantName(tenantName: string) {
    localStorage.setItem('bank_id', tenantName);
    this._sharedService.bankId$.next(tenantName);
    this.currentTenantName = tenantName;
  }

  getBankId(): string {
    return CommonFunctions.tryGetLocalStorageItem('bank_id');
  }

  resetBankId() {
    localStorage.removeItem('bank_id');
  }

  /**
   * main handler for sending all requests
   * @param method
   * @param uri
   * @param data
   * @param responseType
   * @param returnBody
   * @param signedUrl
   * @returns {Observable<any>}
   */

  send(
    method: string,
    uri: string,
    data = {},
    responseType = ResponseTypes.Json,
    returnBody = true,
    signedUrl: SignedUrl = null,
    isInternalAdmin: boolean = false
  ): Observable<any> {
    const args = {
      method: method,
      uri: uri,
      data: data,
      responseType: responseType,
      returnBody: returnBody,
      isInternalAdmin: isInternalAdmin
    };

    return this.sendRequest(args, false, signedUrl);
  }

  _getApiUrl() {
    return this.serverUrl;
  }

  _logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    this.resetBankId();
    this._router.navigate(['logout'], { queryParams: { returnUrl: this._router.url } });
  }

  /**
   * @param args
   * @returns {Observable<any>}
   * @private
   */
  sendRequest(args, nested = false, signedUrl: SignedUrl = null): Observable<any> {
    let apiUrl = this._getApiUrl();
    if (apiUrl === undefined) {
      const env = CommonFunctions.getEnvFromHost();
      apiUrl = env.serverUrl;
    }

    const headers = CommonFunctions.getHeaders(packageJson.version, (signedUrl ? signedUrl.jwt : null), args.isInternalAdmin, this.getBankId());

    let url = apiUrl + args.uri;

    // cache busting
    if ((typeof args.data) !== 'string' && this._sharedService.bypassBackendCache$.value === true) {
      args.data[BYPASS_CACHE] = true;
    }

    // Global backend profiler
    if ((typeof args.data) !== 'string' && this._sharedService.switchOnProfiler$.value === true) {
      url = CommonFunctions.addQueryString(url, 'pr');
    }

    const options = {
      headers: headers,
      params: null,
      body: null,
      withCredentials: true,
      responseType: args.responseType,
      observe: 'response' as 'response'
    };

    if (args.method === 'Get') {
      options.params = args.data;
    } else {
      const bodyStr = (typeof args.data) === 'string' ? args.data : JSON.stringify(args.data);
      options.body = bodyStr;
      if (url.endsWith('spreadsheet') || url.endsWith('spreading-template-associated-excel-export')) {
        options.responseType = 'arraybuffer';
      }
    }

    return this._http
      .request(args.method, url, options)
      .pipe(
        map((data: HttpResponse<any>) => {
          if (args.returnBody && data.headers.get('Content-Type') && data.headers.get('Content-Type').startsWith('application/json')) {
            return data.body;
          }
          return data;
        }), catchError(reason => {
          return this.handleError(reason, args, nested, url);
        })
      );
  }

  /**
   * handler to handle all errors from send method
   * @param error
   * @param args
   * @returns {any}
   */
  handleError(error: HttpErrorResponse, args: any, nested: boolean, url: string): Observable<any> {
    const actualErrorObject: Error = error.error;

    if (actualErrorObject == null) {
      const actualErrorObject = {};
      actualErrorObject['ignoreError'] = false;
    }

    if (error.status === this.HttpStatusCode.UnsupportedMediaType) {
      this.alertService.error(error.error.message);
    }

    if (error.status === BAD_REQUEST) { // 400
      if (ACCEPTABLE_ERROR_MESSAGES.includes(actualErrorObject.message)) {
        // if error message indicates no bank id chosen, logout user
        this._logout();
        return of();
      }
      // if else condition, we should raise and alert error (which we do at end of method)
    } else if (DONT_RAISE_API_ERROR_STATUS_CODES.includes(error.status)) {
      // 0 = network issue (or cors issue), should raise on the backend.
      // 422's should never raise
      // 404's should never raise
      // 500's are already getting raised on the backend.
      // but we'll at least log the issue
      this.logger.info('Notice from ApiService: received status code of: ' + error.status + ' from the following endpoint: ' + url, {'errorObject': actualErrorObject});
      actualErrorObject['ignoreError'] = true;
      return observableThrowError(actualErrorObject);
    }

    // if we got to this point, we should raise an error.
    // either because it's an error code that should raise, or a scenario we don't expect from
    // a non error raising code and did not yet return
    this.logger.error('Notice from ApiService: received status code of: ' + error.status + ' from the following endpoint: ' + url, {'errorObject': actualErrorObject});

    return observableThrowError(actualErrorObject);
  }

  /**
   * handler to refresh token whenever it expires
   * @returns {Observable<R>}
   */
  refreshToken(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = {
      headers: headers,
      withCredentials: true,
    };
    return this._http.post(CommonFunctions.getEnvFromHost().serverUrl + '/api/oauth2/refresh', {
      refresh_token: localStorage.getItem('refreshToken'),
    }, options).pipe(
      mergeMap((data: any) => {
        localStorage.setItem('token', data.id_token);
        return observableOf([]);
      }));

  }

  /**
   *
   * @param {string} fileUrl
   * @returns {Observable<any>}
   */
  public getFile(fileUrl: string): Observable<any> {
    return this._http.get(fileUrl);
  }

    /**
   *
   * @param {string} url
   * @param {any} callback
   * @returns {Observable<any>}
   */
  public readAsDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}
