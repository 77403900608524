<div
class="each-document row clickable"
(click)="openSingleDocMode()">
    <div class="col-1">
        <app-file-icon class="file-type-icon" size="30px" fileSuffix="{{fileSuffix}}"></app-file-icon>
    </div>
    <div class="col-9">
        <p class="borrower-name fs-block fs-exclude">{{borrowerName}}</p>
        <p class="filename">{{fileName}}</p>
        <p class="upload-status" [className]="'upload-status upload-status-' + status.toLowerCase()">{{formattedStatus}}</p>
        <p class="parsing-step" *ngIf="status == 'DOC_PROCESSING' || status == 'HUMAN_INPUT_REQUIRED'">{{step}}</p>
    </div>
    <div class="col-1 right-arrow">
        <i class="fas fa-chevron-right"></i>
    </div>
</div>
