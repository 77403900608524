<div>
    <div>
        <table class="table table-hover">
            <thead>
            <tr class="sorting-header">
                <th class="col-2 filename-cell">
                    Bank
                </th>
                <th class="col-2">
                    Borrower
                </th>
                <th class="col-2">
                    Document Name
                </th>
                <th class="col-1">
                    Status
                </th>
                <th class="col-1">
                    Step
                </th>
                <th class="col-4">
                    Info
                </th>
                <th class="col-2">
                    Actions
                </th>
            </tr>
            </thead>
            <tbody *ngIf="documentQueueItems.length; else noDocumentQueueItems">
              <tr *ngFor="let queueItem of documentQueueItems | paginate: { itemsPerPage: 10, currentPage: page, totalItems: total }"
                  [ngClass]="{selected: queueItem.selected}">
                  <td class="filename-cell">
                      <span class="file-icon" [ngClass]="{'file-icon--danger': queueItem.status !== 'PROCESSED'}">
                          <i *ngIf="queueItem.status === 'PROCESSED'"
                              class="fas fa-{{queueItem?.file?.name | docIcon}}"></i>
                          <i *ngIf="queueItem.status === 'PENDING'" class="fas fa-exclamation-circle"></i>
                      </span>
                      <i class="fa fa-university"></i> {{queueItem.bankId}}
                  </td>
                  <td>
                      <button class="btn btn-empty-primary ps-0" (click)="navigateToBorrower(queueItem.companyId, queueItem.bankId)">{{queueItem.processingJobPayload.company?.name || queueItem.companyId}}</button>
                      <p class="mb-0">Id: {{queueItem.companyId}}</p>
                  </td>
                  <td>
                      <p>{{queueItem.processingJobPayload.documentFile.originalDocumentName}}</p>
                      <p class="mb-0">Id: {{trimFileKey(queueItem.fileKey)}}</p>
                  </td>
                  <td>
                      {{queueItem.status }}
                  </td>
                  <td>
                      {{queueItem.step }}
                  </td>
                  <td>
                      <ul class="mb-0">
                          <li>Uploaded: {{queueItem.uploadedDate | amFromUtc | amLocal | amDateFormat: 'MM/DD/YYYY HH:mm' }}</li>
                          <li>Uploaded By: {{queueItem.processingJobPayload.actor?.email}}</li>
                          <li *ngIf="queueItem.lastLockedDate">Last Locked: {{queueItem.lastLockedDate | amFromUtc | amLocal | amDateFormat: 'MM/DD/YYYY HH:mm' }}</li>
                          <li *ngIf="queueItem.lastLockedDate">Last Locked By: {{queueItem.lastLockedBy}}</li>
                          <li *ngIf="queueItem.lastSavedDate">Last Saved: {{queueItem.lastSavedDate | amFromUtc | amLocal | amDateFormat: 'MM/DD/YYYY HH:mm' }}</li>
                          <li *ngIf="queueItem.lastSavedDate">Last Saved By: {{queueItem.lastSavedBy}}</li>
                          <li *ngIf="queueItem.exceptionClass">Error Type: {{queueItem.exceptionClass}}</li>
                          <li *ngIf="queueItem.exceptionMessage">Error: {{queueItem.exceptionMessage}}</li>
                      </ul>
                  </td>
                  <td>
                      <button *ngIf="queueItem.locked == true" disabled class="btn btn-danger" title="in review by {{queueItem.lastLockedBy}}">
                          Locked
                      </button>
                      <app-select2
                          *ngIf="queueItem.locked != true"
                          [value]="''"
                          placeholder="Choose..."
                          [data]="getOptionsForQueueItem(queueItem)"
                          [width]="150"
                          (valueChanged)="queueItemActionSelected(queueItem, $event)"
                          [options]="selectOptions"
                      ></app-select2>
                  </td>
              </tr>
            </tbody>
            <ng-template #noDocumentQueueItems>
              <tr>
                <td colspan="7" class="no-rqis-container">
                  <div *ngIf="!isProcessing && !isError">
                    <app-table-empty-state
                      [emptyStateIcon]="documentQueueEmptyStateIcon"
                      [headerText]="documentQueueEmptyStateHeaderText"
                      [helpText]="documentQueueEmptyStateHelpText"
                    ></app-table-empty-state>
                  </div>
                  <div *ngIf="isError">
                    <app-table-empty-state
                      [emptyStateIcon]="documentQueueErrorIcon"
                      [headerText]="documentQueueErrorHeaderText "
                      [helpText]="documentQueueErrorHelpText"
                    ></app-table-empty-state>
                  </div>
                  <div *ngIf="isProcessing" class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                </td>
              </tr>
            </ng-template>
        </table>
        <div *ngIf="total > limit">
            <pagination-template #p="paginationApi" (pageChange)="getQueueItems($event)">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center mt-4">
                        <li class="page-item" [ngClass]="{'disabled': p.isFirstPage()}">
                            <a class="page-link" (click)="!p.isFirstPage() ? p.previous() : return" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>

                        <li class="page-item" *ngFor="let page of p.pages" [ngClass]="{'active': p.getCurrent() === page.value}">
                            <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                {{ page.label }}
                            </a>
                            <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}
                                <span class="visually-hidden">(current)</span>
                            </span>
                        </li>

                        <li class="page-item" [ngClass]="{'disabled': p.isLastPage()}">
                            <a class="page-link" (click)="!p.isLastPage() ? p.next() : return" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </pagination-template>
        </div>
    </div>
</div>
