export enum PortfolioActionMenuItems {
  EDIT = 'EDIT',
  ADD_BORROWER = 'ADD_BORROWER',
  EXPORT_DATA = 'EXPORT_DATA',
  DELETE = 'DELETE',
}

export interface Portfolio {
  name: string,
  uuid: string,
  id: number,
  changedDate: string,
  createdDate: string,
}
