import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent implements OnInit {
  constructor() {}

  ngOnInit() {

    let count = 0;
    const numberOfTries = 100;

  }
}
