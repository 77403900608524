<div class="modal__header">
    <div class="modal__title">
        SUPPORT
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form [formGroup]="formGroup" (submit)="sendEmail(formGroup.value, formGroup.valid)"
      id="contact-form" enctype="multipart/form-data">
    <div class="modal__content">
        <div class="form-title">
            <div class="form-header">
                Please, complete the fields below:
            </div>
        </div>
        <div class="mb-3"
             [ngClass]="{'has-error': isFieldInvalid('subject')}">
            <label class="control-label" for="subject">Subject</label>
            <input type="text" class="form-control" id="subject" formControlName="subject" maxlength="100">
            <span class="help-block"
                  *ngIf="formGroup.get('subject').hasError('required') && isFieldInvalid('subject')">
                Subject is required
            </span>
            <span class="help-block"
                  *ngIf="isFieldInvalid('subject') && formGroup.get('subject').hasError('maxlength')">
                Maximum of 100 characters
            </span>
        </div>

        <div class="mb-3"
             [ngClass]="{'has-error': isFieldInvalid('description')}">
            <label class="control-label" for="description">Description</label>
            <textarea id="description" class="form-control" name="description" rows="4" formControlName="description">
            </textarea>
            <span class="help-block"
                  *ngIf="formGroup.get('description').hasError('required') && isFieldInvalid('description')">
                Description is required
            </span>
            <span class="help-block"
                  *ngIf="isFieldInvalid('description') && formGroup.get('description').hasError('maxlength')">
                Maximum of 500 characters
            </span>
        </div>

        <div class="mb-3">
            <div class="form-input">
                <div ng2FileDrop
                     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                     (fileOver)="fileOverBase($event)"
                     [uploader]="uploader"
                     class="upload-drop-zone">

                    <div *ngIf="!uploader.queue.length" class="attach-tip">
                        You can drop files here <br>or <br> click Attach Files button to open selection window
                    </div>

                    <table class="table table-hover" *ngIf="uploader.queue.length">
                        <tr>
                            <th>Name</th>
                            <th>Size</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let item of uploader.queue">
                            <td>{{item?.file?.name}}</td>
                            <td>{{ item?.file?.size/1024/1024 | number:'.1' }} MB</td>
                            <td><i class="fa fa-trash-alt" (click)="removeAttachedFile(i)"></i></td>
                        </tr>
                    </table>
                    <input id="attachments" style="display: none;" type="file"
                           ng2FileSelect [uploader]="uploader" multiple/>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <button class="btn btn-default" type="button"
                    onclick="document.getElementById('attachments').click();">
                Attach Files
            </button>
        </div>
    </div>
    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="sendingInProcess">
            Send
        </button>
    </div>
</form>
