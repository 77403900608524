import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subscription ,  forkJoin ,  Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileItem } from 'ng2-file-upload';
import { DocumentFileCreateRequest } from '../models/document-file-create-request';
import { SignedUrl } from '../models/signed-url';
import { DocumentFileCreateRequestState, ResponseTypes } from '../utils/enums';
import { DocumentFile } from '../models/document-file';
import { Table } from '../components/main/review/human-validation/models/table';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { SUPPORTED_CURRENCIES } from '../utils/constants';
import { Statement } from '../components/main/review/human-validation/models/statement';
import { TrackingService } from './tracking.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { IntegrationImportRequest } from '@models/integration-import-request';

@AutoUnsubscribe('subsArr$')
@Injectable()
export class DocumentFileService {
  subsArr$: Subscription[] = [];

  triggerFilePicker: EventEmitter<any> = new EventEmitter();

  constructor(
    private _apiService: ApiService,
    private _trackingService: TrackingService,
    private _jwtService: JwtHelperService,
    private _userService: UserService
  ) {
  }

  /**
   * creates a signed url for uploading documents
   * @param filename
   * @returns {Observable<any>}
   */
  createUploadFileKey(filename: string, fileType: string, signedUrl?: SignedUrl): Observable<any> {
    return this._apiService.send('Post', '/api/document-files/file-key', {filename, fileType}, ResponseTypes.Json, true, signedUrl).pipe(
      map(data => data.response.objects[0]));
  }

   /**
   * creates a signed url for uploading documents
   * @param DocumentFileCreateRequest
   * @returns {Observable<any>}
   */
  createDocumentFile(createRequest: DocumentFileCreateRequest, signedUrl?: SignedUrl): Observable<any> {
    return this._apiService.send('Post', '/api/document-files', createRequest, ResponseTypes.Json, true, signedUrl).pipe(
      map(data => {
        createRequest.state = DocumentFileCreateRequestState.DocumentFileCreated;
        return data.response.objects[0];
      }));
  }

  /**
   * creates a signed url for uploading documents
   * @param DocumentFileCreateRequest[]
   * @returns {Observable<any>}
   */
  createDocumentFiles(createRequestList: DocumentFileCreateRequest[], signedUrl?: SignedUrl): Observable<any> {
    const observableProgress = new Subject<number>();
    const observables: Observable<any>[] = createRequestList.map(request => this.createDocumentFile(request, signedUrl));
    this.subsArr$.push(forkJoin(...observables).subscribe((documentFile) => {
      // pass
    }, (err) => {
      observableProgress.error(err);
    }, () => {
      observableProgress.complete();
    }));

    return observableProgress;
  }

  updateDocumentFile(documentFileId, body): Observable<any> {
    return this._apiService.send('Patch', '/api/document-files/' + documentFileId, body).pipe(map(data => data.response.objects[0]));
  }

  uploadDocumentFile(request: DocumentFileCreateRequest, signedUrl?: SignedUrl): Observable<any> {
    const observableProgress = new Subject<number>();
    this.subsArr$.push(
      this.createUploadFileKey(request.fileItem.file.name, request.fileItem.file.type, signedUrl)
        .subscribe(uploadFilekey => {
          request.s3FileKey = uploadFilekey.fileKey;
          request.uploader.setOptions({ url: uploadFilekey.s3UploadUrl });
          request.uploader.onErrorItem = (item: FileItem, response: string, status: number) => {
            request.state = DocumentFileCreateRequestState.FileUploadError;
            observableProgress.error('Upload Error');
          };
          request.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
            request.uploadProgress = progress;
            observableProgress.next(progress);
          };

          let trackingBankId: string
          let trackingCompanyId: number

          // we need to figure out the bankid here. it could be in the user service
          // if the user went through the normal cognito login.  however, it could also
          // be in the signedUrl if the upload here is happening via the borrower-portal by
          // an enduser who authenticated via a signed JWT they were given by their lender
          if (signedUrl !== undefined) {
            const decodedJwt = this._jwtService.decodeToken(signedUrl.jwt);
            trackingBankId = decodedJwt.bank_id;
            trackingCompanyId = decodedJwt.company_id;
          } else {
            trackingBankId = this._userService.getBankId();
            trackingCompanyId = request.companyId;
          };

          request.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            // upload to s3 complete
            if (request.state !== DocumentFileCreateRequestState.FileUploadError) {
              request.state = DocumentFileCreateRequestState.FileUploaded;
            }
            request.uploader.removeFromQueue(request.fileItem);

            // track that we have finished the file uplooad
            const documentId = request.s3FileKey.split('/')[0];
            this._trackingService.trackBackgroundEvent({
              type: 'End',
              eventName: 'Document Upload',
              companyId: trackingCompanyId,
              tenantId: trackingBankId,
              additionalTags: {
                documentId: documentId,
              },
            });

            observableProgress.complete();
          };
          request.state = DocumentFileCreateRequestState.FileUploading;

          // track that we are starting a file uplooad
          this._trackingService.trackBackgroundEvent({
            type: 'Start',
            eventName: 'Document Upload',
            companyId: trackingCompanyId,
            tenantId: trackingBankId,
            additionalTags: {
              documentId: request.s3FileKey.split('/')[0]
            },
          });

          request.uploader.uploadItem(request.fileItem);
        })
    );
    return observableProgress;
  }


  /**
   * Retrieve a list of all document files for a particular company
   * @param companyId
   */
  listDocumentFiles(companyId: number = null, fileId: number = null, signPageUrls = false, fileUuid: string = null, getAssociatedStatements: boolean = false): Observable<Array<DocumentFile>> {
    const filter = {};

    if (companyId != null) {
      filter['company_id_eq'] = companyId;
    }

    if (fileId != null) {
      filter['id_eq'] = fileId;
    }

    if (fileUuid != null) {
      filter['uuid_eq'] = fileUuid;
    }

    return this._apiService.send('Post', '/api/document-files/all', {
      'filter': filter,
      'sign_page_urls': signPageUrls,
      'get_associated_statements': getAssociatedStatements
    }).pipe(map((data: any) => {
      return data.response.objects.map(obj => new DocumentFile().deserialize(obj));
    }));
  }

  deleteDocumentFile(fileId: number): Observable<any> {
    return this._apiService.send('Delete', `/api/document-files/${fileId}`);
  }

  getReviewQueueItemForFileId(fileId: number): Observable<any> {
    return this._apiService.send('Post', `/api/document-files/${fileId}/review-queue-item`);
  }

  getDocumentPageImage(docFileId: string, pageId: number): Observable<any> {
    return this._apiService.send('Post', `/api/get-doc-page-img`, {
      doc_file_id: docFileId,
      page_id: pageId
    });
  }

  getDownloadUrlForOriginalDocument(fileId: number): Observable<any> {
    return this._apiService.send('Get', `/api/document-files/${fileId}/get-download-url`, {}).pipe(map((data: any) => {
      return data.response.objects[0];
    }));
  }

  loadTables(id: number, boundsOnly = false): Observable<Array<Table>> {
    return this._apiService.send('Post', '/api/document-files/' + id + '/tables', {
      'bounds_only': boundsOnly,
    }).pipe(map(data => {
      return data.response.objects.map(o => new Table().deserialize(o));
    }));
  }

  currencyFromFiles(documentFiles: Array<DocumentFile>): string {
    if (!documentFiles) {
      return '';
    }
    const documentFileWithCurrency = documentFiles.find( (documentFile) => {
      return documentFile.currency !== '' && !!(SUPPORTED_CURRENCIES.find( (currency) => currency.key === documentFile.currency));
    });

    if (!documentFileWithCurrency) {
      return '';
    }

    const foundCurrency = SUPPORTED_CURRENCIES.find( (currency) => currency.key === documentFileWithCurrency.currency);

    if (!foundCurrency) {
      return '';
    }

    return foundCurrency.symbol;
  }

  loadStatements(id: number): Observable<Array<Statement>> {
    return this._apiService.send('Post', '/api/statements/all', {
      'filter': {
        'document_file_id_eq': id,
      },
      'exclude_table': true,
    }).pipe(map(data => {
      return data.response.objects.map(o => new Statement().deserialize(o));
    }));
  }


  cleanDocumentOfNones(pages): Object {
    pages.forEach(page => {
      page.cells.forEach(row => {
        row.forEach(col => {
          if (col.raw_text === null || col.raw_text === 'None') {
            col.raw_text = '';
          }
          if (col.text === null || col.text === 'None') {
            col.text = '';
          }
          if (col.translated_raw_text === null || col.translated_raw_text === 'None') {
            col.translated_raw_text = '';
          }
        });
      });
    });
    return pages;
  }

  importFromIntegration(importRequest: IntegrationImportRequest): Observable<any> {
    return this._apiService.send('Post', '/api/accounting-integration/import', importRequest).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  validateHumanEnteredDocumentPassword(id: number, filePassword: string, lockDocumentOnFailedUnlock: boolean = false): Observable<any> {
    return this._apiService.send('Post', '/api/document-files/' + id + '/validate-human-entered-document-password', {
      file_password: filePassword,
      lock_document_on_failed_unlock: lockDocumentOnFailedUnlock
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }
}
