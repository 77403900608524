import { DataViewColumn, DataView } from '@models/dataview';

export enum STATEMENT_BUILDER_OPERATORS {
    'add' = 'ADD',
    'subtract' = 'SUBTRACT'
}

export class BuilderFrame {
    column: DataViewColumn;
    operator: STATEMENT_BUILDER_OPERATORS;
}
