import { Component, OnInit, Input } from '@angular/core';
import { SignedUrl } from '@models/signed-url';
import { AppConfigService } from '@services/app-config.service';

const GETTING_STARTED_REPO = 'https://github.com/fincura/fincura-api-quickstart-python';
const NODE_RED_CHAMELEON = 'https://github.com/fincura/node-red-chameleon';

@Component({
  selector: 'app-deploy-api-key-button',
  templateUrl: './deploy-api-key-button.component.html',
  styleUrls: ['./deploy-api-key-button.component.css']
})
export class DeployApiKeyButtonComponent implements OnInit {
  @Input() apiKey: SignedUrl;

  deployToHerokuUrl: string;

  constructor(private _appConfig: AppConfigService) { }

  ngOnInit() {
    this.setHerokuDeployUrl();
  }

  setHerokuDeployUrl() {
      this._appConfig.envFromHostname.subscribe((envFromHost) => {
        // eslint-disable-next-line
        const deployUrl = `https://heroku.com/deploy?template=${encodeURIComponent(GETTING_STARTED_REPO)}&env[FINCURA_API_REFRESH_TOKEN]=${this.apiKey.refreshToken}&env[FINCURA_ENV]=${envFromHost.envName}&env[FINCURA_TENANT_ID]=${localStorage.getItem('bank_id')}`;
        this.deployToHerokuUrl = deployUrl;
      });
  }

}
