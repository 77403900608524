import { Component, OnInit, Input } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import { NgxPopupService } from '@components/shared/ngx-popups/ngx-popups/ngx-popups';
import { Company } from '@models/company';
import { SignedUrlService } from '@services/signed-url.service';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-create-invite-link',
  templateUrl: './create-invite-link.component.html',
  styleUrls: ['./create-invite-link.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class CreateInviteLinkComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company;

  submitted = false;
  submissionComplete = false;
  submitAttempt = false;

  subsArr$: Subscription[] = [];

  inviteLink: string;

  constructor(
    private _signedUrlService: SignedUrlService,
    private _popupService: NgxPopupService,
    private _alertService: AlertService
  ) { }

  ngOnInit(): void {

  }

  createLink() {
    this.submitAttempt = true;
    this.subsArr$.push(this._signedUrlService.createAccountIntegrationRequest(this.company.id).subscribe(data => {
      this.inviteLink = data['url'];
      this.submissionComplete = true;
    }));
  }

  copyToClipboard() {
    const inviteLink = document.getElementById('inviteLink') as HTMLInputElement;
    if (inviteLink) {
        inviteLink.select();
        document.execCommand('copy');
        this._alertService.success('Copied to clipboard!');
    }
  }
}
