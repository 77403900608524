export class RowOrCol {
  isRow: boolean;
  isHide: boolean;
  hiddenIdxs: Array<string>;

  constructor(isRow: boolean, isHide: boolean, hiddenIdxs: Array<string>) {
    this.isRow = isRow;
    this.isHide = isHide;
    this.hiddenIdxs = hiddenIdxs;
  }

  column(): string {
    return this.hiddenIdxs[0];
  }

  rows(): Array<number> {
    const outRows = new Array<number>();
    this.hiddenIdxs.forEach(element => {
      outRows.push(Number(element));
    });
    return outRows;
  }
}

export class HideRowColStack {
  // Beyond max stack size, the hidden indices are just not stored.
  maxStackSize: number;
  hiddenItemStack: Array<RowOrCol>;

  constructor(maxStackSize: number = 400) {
    this.maxStackSize = maxStackSize;
    this.clear();
  }

  clear() {
    this.hiddenItemStack = new Array<RowOrCol>();
  }

  pushRows(isHide: boolean, idxs: Array<number>) {
    const strIdxs = idxs.map(function (idx) {
      return idx.toString();
    });
    if (this.hiddenItemStack.length < this.maxStackSize) {
      this.hiddenItemStack.push(new RowOrCol(true, isHide, strIdxs));
    }
  }

  pushColumn(isHide: boolean, idx: string) {
    if (this.hiddenItemStack.length < this.maxStackSize && idx !== '') {
      this.hiddenItemStack.push(new RowOrCol(false, isHide, new Array(idx)))
    }
  }

  // Return null to indicate empty stack
  pop(): RowOrCol {
    if (this.hiddenItemStack.length > 0) {
      return this.hiddenItemStack.pop();
    } else {
      return null;
    }
  }

  isEmpty() {
    return this.hiddenItemStack.length === 0;
  }
}
