import { Deserializable } from '../../../../../models/deserializable';

export class Cell implements Deserializable {
  text = '';
  rawText = '';

  deserialize(data): this {
    Object.assign(this, data);
    return this;
  }
}
