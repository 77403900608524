import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentFile, PageImages} from "@models/document-file";
import {Box} from "@components/main/review/human-validation/models/box";
import {NavigationStep} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-stepper.component";

@Component({
  selector: 'app-table-drawing',
  templateUrl: './table-drawing.component.html',
  styleUrls: ['./table-drawing.component.scss','../shared-table-merging-header-styles.scss'],
})
export class TableDrawingComponent implements OnInit {
  @Input() documentFile: DocumentFile;
  @Input() navigationSteps: NavigationStep[];
  @Output() exitClicked = new EventEmitter<void>();
  @Output() saveClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();
  @Output() tableMergeSelection = new EventEmitter<{ box: Box, pageIndex: number, pageImage: PageImages }>();
  currentBoxesByPage: Array<Array<Box>> = [];

  ngOnInit() {
    this.resetCurrentBoxesByPage();
  }

  boxesChanged({boxes, activePageIndex}: { boxes: Array<Box>, activePageIndex: number }): void {
    this.resetCurrentBoxesByPage();
    this.currentBoxesByPage[activePageIndex] = boxes;
    this.tableMergeSelection.emit({
      box: boxes[0],
      pageIndex: activePageIndex,
      pageImage: this.documentFile.pageImages[activePageIndex]
    })
  }

  resetCurrentBoxesByPage() {
    this.currentBoxesByPage = [];
    this.documentFile?.pageImages.forEach(_ => {
      this.currentBoxesByPage.push([])
    })
  }

}
