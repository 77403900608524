import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Company } from '../../../models/company';
import { LoanService } from '../../../services/loan.service';
import { AlertService } from '../../../services/alert.service';
import { DATE_FORMAT, NON_OTHER_DOCUMENT_TYPES, SERVER_DATE_FORMAT, LOAN_FORM, LOAN_FORM_OBJECT_FAMILIES } from '../../../utils/constants';
import * as moment from 'moment';
import { DataFrameService } from '../../../services/dataframes.service';
import { SpreadingTemplateService } from '../../../services/spreading-template.service';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { DocumentFileService } from '../../../services/document-file.service';
import { DocumentFile } from '../../../models/document-file';

import { LoansService } from '@services/fincura-ng-client/api/loans.service';
import { Period } from '@models/period';
import { Loan } from '@services/fincura-ng-client/model/loan';
import { EnumToArrayPipe } from '../../../pipes/enum-to-array-pipe';
import { serverDate, displayDate } from '@utils/functions';
import { CustomAttributeDefinition } from '@models/custom-attribute-definition';
import { CustomAttributeDefinitionService } from '@services/custom-attribute-definition.service';
import { CommonFunctions } from '@utils/common-functions';
import { LoanBorrowerInfo } from '@services/fincura-ng-client/model/loanBorrowerInfo';


type LoanFormModalTab = 'deal' | 'company' | 'notes' | 'data' | 'compliance' | 'documents';

@Component({
  selector: 'app-loan-form',
  templateUrl: './loan-form.component.html',
  styleUrls: ['./loan-form.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class LoanFormComponent implements OnInit {
  @Input() loan: Loan = null;
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company = null;
  @Input() formType = '';
  @Input() createFromDscrAnalysis = false;
  @Input() dscrAnalysisValues = {};
  @Input() isCreatedFromDscr = false;

  @Input() loanNameValue = ''
  @Input() principalValue = '';
  @Input() interestRateValue = '';
  @Input() termMonthsValue = '';
  @Input() paymentTypeValue = Loan.PaymentTypeEnum.PrincipalAndInterest;

  loanForm: FormGroup;
  submitted = false;
  templates = [];

  paymentTypes = {
    PrincipalAndInterest: 'PRINCIPAL_AND_INTEREST',
    PrincipalPlusInterest: 'PRINCIPAL_PLUS_INTEREST',
  };
  // temporarily while we don't want IO_PERIOD here
  // will be   paymentTypes = Loan.PaymentTypeEnum; when we support Interest Only as well


  interestTypes = Loan.InterestTypeEnum;
  bookingStatuses = Loan.StateEnum;
  paymentStatuses = Loan.PaymentStatusEnum;
  collateralTypes = Loan.CollateralTypeEnum;
  industries = LoanBorrowerInfo.IndustryEnum;
  scorecards = Loan.ScorecardEnum;
  contractTypes = Loan.ContractTypeEnum;

  todaysDate = '';

  isLoading = false;
  availablePeriods: Array<any> = [];

  // @ts-ignore
  guarantors: FormArray = [];

  subsArr$: Subscription[] = [];
  statementFiles: Array<DocumentFile> = [];
  otherFiles: Array<DocumentFile> = [];

  selectAllStatementFilesToggler = false;
  selectAllOtherFilesToggler = false;
  selectAllPeriodsToggler = false;

  pages: Array<LoanFormModalTab> = ['deal', 'company', 'data', 'compliance', 'documents'];
  currentPage = 0;

  formErrors = {};

  customAttrDefinitions: CustomAttributeDefinition[] = [];
  customAttributesValues = {};
  invalidFields = {};

  constructor(
    public loanService: LoanService,
    private formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _router: Router,
    private _dataFrameService: DataFrameService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private _documentFileService: DocumentFileService,
    private _loansApiService: LoansService,
    private _customAttributeDefinitionService: CustomAttributeDefinitionService,
  ) {}

  ngOnInit(): void {
    this.setForm();
    this._setInitialValues();

    if (!this.company) {
      // error 404 redirect here
      return;
    }

    this._dataFrameService.getPeriodsWithData(this.company.id).subscribe( data => {
      data.forEach(period => {
        this.availablePeriods.push(period);
      });

      this.addPeriodsCheckboxes();
    });

    this._spreadingTemplateService.getTemplates('DSCR', true).subscribe( (data) => {
      this.templates = data;
      if (data.length > 0) {
      } else {
        // what do we do if none available?
      }
    });

    this.subsArr$.push(this._documentFileService.listDocumentFiles(this.company.id).subscribe(files => {
      this.statementFiles = files.filter(file => NON_OTHER_DOCUMENT_TYPES.includes(file.type));
      this.otherFiles = files.filter(file => !NON_OTHER_DOCUMENT_TYPES.includes(file.type));

      this.addDocumentCheckboxes();
      // this.retrieveFilesError = false;
    }, error => {
      // this.retrieveFilesError = true;
    }));

    this.subsArr$.push(this._customAttributeDefinitionService.getCustomAttributesForModel('Loan').subscribe(customAttributeDefinitions => {
      this.customAttrDefinitions = customAttributeDefinitions;
      if (this.loan !== null) {
        this.customAttrDefinitions.forEach( (definition) => {
          this.customAttributesValues[CommonFunctions.camelToSnake(definition.key)] = this.loan.customAttributes[CommonFunctions.snakeToCamel(definition.key)]; // tweak to actual val when we allow editing
        });
      };
    }));
  }

  setForm(): void {
    this.loanForm = this.formBuilder.group({
      borrowerUuid: [this.defaultLoanFormValue(LOAN_FORM.UUID), []],
      title: [this.defaultLoanFormValue(LOAN_FORM.TITLE), [Validators.required]],
      principal: [this.defaultLoanFormValue(LOAN_FORM.PRINCIPAL), [Validators.required]],
      description: [this.defaultLoanFormValue(LOAN_FORM.DESCRIPTION), []],
      contractType: [this.defaultLoanFormValue(LOAN_FORM.CONTRACT_TYPE), []],
      scorecard: [this.defaultLoanFormValue(LOAN_FORM.SCORECARD), []],
      templateUuid: [this.defaultLoanFormValue(LOAN_FORM.TEMPLATE_UUID), []],
      guarantors: this.formBuilder.array([]),
      interestRate: [this.defaultLoanFormValue(LOAN_FORM.INTEREST_RATE), [Validators.required]],
      termMonths: [this.defaultLoanFormValue(LOAN_FORM.TERM_MONTHS), [Validators.required]],
      collateralType: [this.defaultLoanFormValue(LOAN_FORM.COLLATERAL_TYPE), []],
      collateralValue: [this.defaultLoanFormValue(LOAN_FORM.COLLATERAL_VALUE), []],
      paymentStatus: [this.defaultLoanFormValue(LOAN_FORM.PAYMENT_STATUS), []],
      paymentType: [this.defaultLoanFormValue(LOAN_FORM.PAYMENT_TYPE), []],
      startDate: [this.defaultLoanFormValue(LOAN_FORM.START_DATE), []],
      lastPaymentDate: [this.defaultLoanFormValue(LOAN_FORM.LAST_PAYMENT_DATE), []],
      balloonPayment: [this.defaultLoanFormValue(LOAN_FORM.BALLOON_PAYMENT), []],
      residualValue: [this.defaultLoanFormValue(LOAN_FORM.RESIDUAL_VALUE), []],
      bookingStatus: [this.defaultLoanFormValue(LOAN_FORM.BOOKING_STATUS), []],
      interestType: [this.defaultLoanFormValue(LOAN_FORM.INTEREST_TYPE), []],
      borrowerInfo: this.formBuilder.group({
        name: [this.company.name, [Validators.required]],
        email: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.EMAIL, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        country: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.COUNTRY, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        address: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.ADDRESS, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        city: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.CITY, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        state: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.STATE, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        zipcode: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.ZIPCODE, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        industry: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.INDUSTRY, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        naicsCode: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.NAICS_CODE, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        companyNotes: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.COMPANY_NOTES, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        paynetScore: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.PAYNET_SCORE, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
        dunBradstreetScore: [this.defaultLoanFormValue(LOAN_FORM.BORROWER_INFO.DUN_BRADSTREET_SCORE, LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO), []],
      }),
      complianceInfo: this.formBuilder.group({
        ofacSanctions: [this.defaultLoanFormValue(LOAN_FORM.COMPLIANCE_INFO.OFAC_SANCTIONS, LOAN_FORM_OBJECT_FAMILIES.COMPLIANCE_INFO), []],
        pacerBankruptcies: [this.defaultLoanFormValue(LOAN_FORM.COMPLIANCE_INFO.PACER_BANKRUPTCIES, LOAN_FORM_OBJECT_FAMILIES.COMPLIANCE_INFO), []],
        stateCourtClaims: [this.defaultLoanFormValue(LOAN_FORM.COMPLIANCE_INFO.STATE_COURT_CLAIMS, LOAN_FORM_OBJECT_FAMILIES.COMPLIANCE_INFO), []],
        divisionsOfCorpReport: [this.defaultLoanFormValue(LOAN_FORM.COMPLIANCE_INFO.DIVISIONS_OF_CORP_REPORT, LOAN_FORM_OBJECT_FAMILIES.COMPLIANCE_INFO), []],
      }),
      periods: new FormArray([], []),
      statementFiles: new FormArray([], []),
      otherFiles: new FormArray([], []),
      documents: new FormArray([], []),
    });

    this.setGuarantors();
    this.initializeDates();
  }

  get title() { return this.loanForm.get(LOAN_FORM.TITLE); }

  get principal() { return this.loanForm.get(LOAN_FORM.PRINCIPAL); }

  get interestRate() { return this.loanForm.get(LOAN_FORM.INTEREST_RATE); }

  get residualValue() { return this.loanForm.get(LOAN_FORM.RESIDUAL_VALUE); }

  get balloonPayment() { return this.loanForm.get(LOAN_FORM.BALLOON_PAYMENT); }

  get lastPaymentDate() { return this.loanForm.get(LOAN_FORM.LAST_PAYMENT_DATE); }

  get startDate() { return this.loanForm.get(LOAN_FORM.START_DATE); }

  get termMonths() { return this.loanForm.get(LOAN_FORM.TERM_MONTHS); }

  get templateUuid() { return this.loanForm.get(LOAN_FORM.TEMPLATE_UUID); }

  get collateralValue() { return this.loanForm.get(LOAN_FORM.COLLATERAL_VALUE); }

  get dunBradstreetScore() { return this.loanForm.get('borrowerInfo.dunBradstreetScore'); };

  get industry() { return this.loanForm.get('borrowerInfo.industry'); };

  get borrowerName() { return this.loanForm.get('borrowerInfo.name'); };

  get paynetScore() { return this.loanForm.get('borrowerInfo.paynetScore'); };

  get city() { return this.loanForm.get('borrowerInfo.city'); };

  get state() { return this.loanForm.get('borrowerInfo.state'); };

  get zipcode() { return this.loanForm.get('borrowerInfo.zipcode'); };

  get theGuarantors(): FormArray {
    return this.loanForm.get('guarantors') as FormArray;
  }


  addGuarantor(guarantor = {firstName: '', lastName: '', creditScore: ''}): void {
    this.guarantors = this.loanForm.get('guarantors') as FormArray;
    this.guarantors.push(this.createGuarantor(guarantor));
  }

  removeGuarantor(key: number): void {
    this.guarantors.removeAt(key);
  }

  createGuarantor(guarantor = {firstName: '', lastName: '', creditScore: ''}): FormGroup {
    return this.formBuilder.group({
      firstName: [guarantor.firstName, [Validators.required]],
      lastName: [guarantor.lastName, [Validators.required]],
      creditScore: [guarantor.creditScore, [Validators.required]],
    });
  }


  save() {
    this.submitted = true;

    const checkedDocuments = [];
    const checkedPeriods = [];

    // put checked statement documents into uuid array
    (this.loanForm.controls.statementFiles as FormArray).controls.forEach((eachControl, index) => {
      if (eachControl.value) {
        checkedDocuments.push(this.statementFiles[index].uuid);
      }
    });

    (this.loanForm.controls.otherFiles as FormArray).controls.forEach((eachControl, index) => {
      if (eachControl.value) {
        checkedDocuments.push(this.otherFiles[index].uuid);
      }
    });

    (this.loanForm.controls.periods as FormArray).controls.forEach((eachControl, index) => {
      if (eachControl.value) {
        checkedPeriods.push(this.availablePeriods[index]);
      }
    });



    // next two code groups remove existing array controls & add net new so we avoid key errors w/ reactive forms
    const periodsControl = <FormArray>this.loanForm.controls['periods'];
    for (let i = periodsControl.length - 1; i >= 0; i--) {
      periodsControl.removeAt(i);
    }

    checkedPeriods.forEach((period: Period, i) => {
      const control = new FormControl( {
        reporting_period: period.value,
        preparation_type: period.preparationType,
      }); // sets all checked to true
      (this.loanForm.controls.periods as FormArray).push(control);
    });

    // next two code groups remove existing array controls & add net new so we avoid key errors w/ reactive forms
    const documentsControl = <FormArray>this.loanForm.controls['documents'];
    for (let i = documentsControl.length - 1; i >= 0; i--) {
      documentsControl.removeAt(i);
    }

    checkedDocuments.forEach((document: string, i) => {
      const control = new FormControl( {
        uuid: document,
      }); // sets all checked to true
      (this.loanForm.controls.documents as FormArray).push(control);
    });

    const loanForm = this.loanForm.value;

    loanForm.customAttributes = this.customAttributesValues;

    if (loanForm.name) {
      loanForm.name = loanForm.name.trim();
    }

    if (loanForm.lastPaymentDate) {
      if (loanForm.lastPaymentDate.formatted === undefined) {
        loanForm.lastPaymentDate = serverDate(loanForm.lastPaymentDate);
      } else {
        loanForm.lastPaymentDate = serverDate(loanForm.lastPaymentDate.formatted);
      }
    }

    if (loanForm.startDate) {
      if (loanForm.startDate.formatted === undefined) {
        loanForm.startDate = serverDate(loanForm.startDate);
      } else {
        loanForm.startDate = serverDate(loanForm.startDate.formatted);
      }
    }

    loanForm.borrowerUuid = this.company.uuid;

    // remove non required numerical values
    if (loanForm.residualValue === '') {
      delete loanForm.residualValue;
    }
    if (loanForm.collateralValue === '') {
      delete loanForm.collateralValue;
    }
    if (loanForm.balloonPayment === '') {
      delete loanForm.balloonPayment;
    }
    if (loanForm.startDate === '') {
      delete loanForm.startDate;
    }
    if (loanForm.lastPaymentDate === '') {
      delete loanForm.lastPaymentDate;
    }

    if (loanForm.templateUuid === '') {
      delete loanForm.templateUuid;
    }

    if (loanForm.periods.length === 0) {
      delete loanForm.periods;
    }

    if (loanForm.borrowerInfo.dunBradstreetScore === '') {
      delete loanForm.borrowerInfo.dunBradstreetScore;
    }

    if (loanForm.borrowerInfo.naicsCode === '') {
      delete loanForm.borrowerInfo.naicsCode;
    }


    if (!this.isFormInvalid()) {
      if (this.isInEditMode()) {
        this._loansApiService.partialUpdateLoan(this.loan.uuid, this.company.uuid, null, loanForm).subscribe(
          data => {
            this.submitted = false;
            this.popup.close(true, true);
            this.formErrors = {};
          }, error => {
            if (error.status === 400) {
              this.formErrors = error.error;
            }

            this._alertService.error(error.message);
            this.submitted = false;
          }
        );
      } else {
        this._loansApiService.createLoan(loanForm).subscribe(
          data => {
            this.submitted = false;
            this.popup.close(true, true);
            this.formErrors = {};
          }, error => {
            if (error.status === 400) {
              this.formErrors = error.error;
            }

            this._alertService.error(error.message);
            this.submitted = false;
          }
        );
      }
    } else {
      this.submitted = false;
    }
  }

  initializeDates(): void {
    if (!this.isInEditMode()) {
      return;
    }

    if (this.loan[LOAN_FORM.START_DATE]) {
      this.loanForm.value.startDate = serverDate(this.loan[LOAN_FORM.START_DATE].toString());
    }

    if (this.loan[LOAN_FORM.LAST_PAYMENT_DATE]) {
      this.loanForm.value.lastPaymentDate = serverDate(this.loan[LOAN_FORM.LAST_PAYMENT_DATE].toString());
    }

  }

  updateStartDate(event) {
    this.loanForm.value.startDate = event.dateObject.formatted;
  }

  updateLastPaymentDate(event) {
    this.loanForm.value.lastPaymentDate = event.dateObject.formatted;
  }

  changeTab(tabName: LoanFormModalTab) {
    this.currentPage = this.pages.indexOf(tabName);
  }

  isFormInvalid(): boolean {
    return !this.areAnalysisSelectionsValid() || !this.loanForm.valid;
  }

  customAttributeChange(attr, event) {
    const value = event.target.value;
    if (!!value) {
      delete this.invalidFields[attr.key];
      this.customAttributesValues[attr.key] = {
        key: attr.key,
        attribute_type: attr.attributeType,
        value
      }
    } else {
      this.invalidFields[attr.key] = true;
      delete this.customAttributesValues[attr.key];
    }
  }

  areAnalysisSelectionsValid(): boolean {
    if ( (this.checkedPeriods().length > 0 && (this.loanForm.value.templateUuid === '' || this.loanForm.value.templateUuid === 'None' || this.loanForm.value.templateUuid === undefined) ) ||
      (this.checkedPeriods().length === 0 && (this.loanForm.value.templateUuid !== '' && this.loanForm.value.templateUuid !== 'None' && this.loanForm.value.templateUuid !== undefined) )
    ) {
      return false;
    } else {
      return true;
    }
  }

  getGuarantorsControls(loanForm) {
    return loanForm.get('guarantors').controls;
  }

  toggleAllStatementFiles(): void {
    this.selectAllStatementFilesToggler = !this.selectAllStatementFilesToggler;

    (this.loanForm.controls.statementFiles as FormArray).controls.forEach((eachControl, i) => {
      eachControl.setValue(this.selectAllStatementFilesToggler);
    });
  }

  toggleAvailablePeriods(): void {
    this.selectAllPeriodsToggler = !this.selectAllPeriodsToggler;

    (this.loanForm.controls.periods as FormArray).controls.forEach((eachControl, i) => {
      eachControl.setValue(this.selectAllPeriodsToggler);
    });
  }

  toggleAllOtherFiles(): void {
    this.selectAllOtherFilesToggler = !this.selectAllOtherFilesToggler;

    (this.loanForm.controls.otherFiles as FormArray).controls.forEach((eachControl, i) => {
      eachControl.setValue(this.selectAllOtherFilesToggler);
    });
  }

  isEmptyObject(obj): boolean {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
    }
      return true;
  }

  objectEntries(obj) {
    return Object.entries(obj);
  }

  goToPreviousTab(): void {
    if (this.currentPage === 0) {
      return;
    }
    this.currentPage--;
  }

  isInEditMode(): boolean {
    if (this.loan === null) {
      return false;
    } else {
      return true;
    }
  }

  doesDocumentExistInLoan(documentFile: DocumentFile): boolean {
    if (this.loan === null) {
      return false;
    }

    let wasFound = false;

    this.loan.documents.forEach(document => {
      if (document.uuid === documentFile.uuid) {
        wasFound = true;
      }
    });

    return wasFound;
  }

  // gets default values for forms if we're in edit
  defaultLoanFormValue(valueName: string, objectFamily: string = null) {
    if (objectFamily === null) {
      if (this.loan !== null && valueName in this.loan) {
        if (typeof(this.loan[valueName]) === 'boolean') {
          return this.loan[valueName].toString(); // boolean value must be returned as string
        } else if (valueName === LOAN_FORM.START_DATE || valueName === LOAN_FORM.LAST_PAYMENT_DATE) {
          return displayDate(this.loan[valueName]);
        } else {
          return this.loan[valueName];
        }
      } else {
        // now look up specific defaults if they exist on certain fields.
        if (valueName === LOAN_FORM.INTEREST_RATE) {
          return this.interestRateValue;
        } else if (valueName === LOAN_FORM.TERM_MONTHS) {
          return this.termMonthsValue;
        } else if (valueName === LOAN_FORM.TITLE) {
          return this.loanNameValue;
        } else if (valueName === LOAN_FORM.PRINCIPAL) {
          return this.principalValue;
        } else if (valueName === LOAN_FORM.SCORECARD) {
          return Loan.ScorecardEnum.Passed;
        } else if (valueName === LOAN_FORM.COLLATERAL_TYPE) {
          return Loan.CollateralTypeEnum.None;
        } else if (valueName === LOAN_FORM.PAYMENT_STATUS) {
          return Loan.PaymentStatusEnum.Current;
        } else if (valueName === LOAN_FORM.PAYMENT_TYPE) {
          return this.paymentTypeValue;
        } else if (valueName === LOAN_FORM.START_DATE) {
          return this.todaysDate;
        } else if (valueName === LOAN_FORM.LAST_PAYMENT_DATE) {
          return this.todaysDate;
        } else if (valueName === LOAN_FORM.BOOKING_STATUS) {
          return Loan.StateEnum.Proposed;
        } else if (valueName === LOAN_FORM.INTEREST_TYPE) {
          return Loan.InterestTypeEnum.Fixed;
        } else if (valueName === LOAN_FORM.CONTRACT_TYPE) {
          return Loan.ContractTypeEnum.NotApplicable;
        }
        return '';
      }
    } else if (objectFamily === LOAN_FORM_OBJECT_FAMILIES.BORROWER_INFO) {
      if (this.loan !== null && valueName in this.loan.borrowerInfo) {
        return this.loan.borrowerInfo[valueName];
      } else {
        if (valueName === LOAN_FORM.BORROWER_INFO.COUNTRY) {
          return 'United States';
        } else if (valueName === LOAN_FORM.BORROWER_INFO.INDUSTRY) {
          return LoanBorrowerInfo.IndustryEnum.Unknown;
        } else {
          return '';
        }
      }
    } else if (objectFamily === LOAN_FORM_OBJECT_FAMILIES.COMPLIANCE_INFO) {
      if (this.loan !== null && valueName in this.loan.complianceInfo) {
        return this.loan.complianceInfo[valueName].toString(); // all booleans - need to display default as string for html
      } else {
        return 'true';
      }
    }

    return '';
  }

  setGuarantors(): void {
    if (this.loan === null) {
      return;
    }

    this.loan.guarantors.forEach( guarantor => {
      // @ts-ignore
      this.addGuarantor(guarantor);
    });
  }

  goToNextTab(): void {
    if (this.currentPage >= (this.pages.length - 1)) {
      return;
    }
    this.currentPage++;
  }

  checkedPeriods(): Array<string> {
    if (this.loanForm.value.periods === undefined) {
      return [];
    }

    return this.loanForm.value.periods
      .map((v, i) => v ? this.availablePeriods[i] : null)
      .filter(v => v !== null);
  }

  private _setInitialValues(): void {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '/' + today.getDate();
    this.todaysDate = moment(date).format(DATE_FORMAT);
  }

  private addPeriodsCheckboxes() {
    this.availablePeriods.forEach((o, i) => {
      const control = new FormControl(false); // sets all checked to false
      (this.loanForm.controls.periods as FormArray).push(control);
    });
  }

  private addDocumentCheckboxes() {
    this.statementFiles.forEach((o, i) => {
      const control = new FormControl(false); // sets all checked to true
      (this.loanForm.controls.statementFiles as FormArray).push(control);
    });

    this.otherFiles.forEach((o, i) => {
      const control = new FormControl(false); // sets all checked to true
      (this.loanForm.controls.otherFiles as FormArray).push(control);
    });

  }

}
