import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';


@Component({
  selector: 'set-common-size-denominator',
  templateUrl: './set-common-size-denominator.component.html',
  styleUrls: ['./set-common-size-denominator.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class SetCommonSizeDenominatorComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() title = 'SET COMMON SIZE DENOMINATOR';
  @Input() description = 'Change common size denominator from system defaults'
  @Input() confirmButtonText = 'Change defaults';
  @Input() cancelButtonText = 'Cancel';
  @Input() incomeStatementItems= []
  @Input() balanceSheetItems= []
  @Input() incomeStatementCommonSizeDenominatorRefInput = ''
  @Input() balanceSheetCommonSizeDenominatorRefInput = ''
  @Output() callback = new EventEmitter<any>();

  subsArr$: Subscription[] = [];
  submitted = false;
  readonly nullTaxonomyItemOption = {
    label: '--Use System Default--',
    ref: ''
  };
  incomeStatementCommonSizeDenominatorRef
  balanceSheetCommonSizeDenominatorRef
  incomeStatementMappingOptions = []
  balanceSheetMappingOptions = []

  constructor(
  ) {}

  ngOnInit() {
    this.incomeStatementCommonSizeDenominatorRef = this.incomeStatementCommonSizeDenominatorRefInput
    this.balanceSheetCommonSizeDenominatorRef = this.balanceSheetCommonSizeDenominatorRefInput
    this.incomeStatementMappingOptions = this.setMappingOptionsForLineItem(true)
    this.balanceSheetMappingOptions = this.setMappingOptionsForLineItem(false)
  }

  setMappingOptionsForLineItem(isIncomeStatement=true) {
    const initialMappingOptions = [this.nullTaxonomyItemOption];
    return isIncomeStatement ? initialMappingOptions.concat(this.incomeStatementItems) : initialMappingOptions.concat(this.balanceSheetItems);
  }

  setOverrideForStatementType(isIncomeStatement, selectedItem){
    if (isIncomeStatement) {
      this.incomeStatementCommonSizeDenominatorRef = selectedItem
    } else {
      this.balanceSheetCommonSizeDenominatorRef = selectedItem
    }
  }

  submit() {
    this.callback.emit({
      incomeStatement: this.incomeStatementCommonSizeDenominatorRef,
      balanceSheet: this.balanceSheetCommonSizeDenominatorRef
    });
    this.popup.close();
  }

}
