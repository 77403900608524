<div *ngIf="isInitialized" id="main-component-wrapper" (click)="clickedOnApp($event)">
    <app-header *ngIf="_sharedService.shouldShowTopNav"></app-header>
    <div id="wrapper" [ngClass]="{'has-header' : _sharedService.shouldShowTopNav, 'has-sidenav' : _sharedService.shouldShowSidebar}">
        <app-side-nav *ngIf="_sharedService.shouldShowSidebar"></app-side-nav>
        <div id="main-wrapper" class="container-fluid" #wrapper>
            <router-outlet></router-outlet>
        </div>
        <app-ngx-popups></app-ngx-popups>
        <ng-template appPopover></ng-template>
        <ng-template appDrawer></ng-template>
    </div>
</div>
