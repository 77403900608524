import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

const notNumbersWithDecimalsRegex = /[^\d.-]/g;
const USDFormatted = ['USD', 'CAD', 'AUD', 'HKD', 'JPY', 'MXN', 'GBP', 'SGD', 'Other'];

@Injectable()
export class CurrencyFormattingService {

  constructor(private _decimalPipe: DecimalPipe) { }

  /**
   * Function that formats the value of a cell based on the given currency type
   *
   * Formats:
   * USD , CAD, EUR, AUD, HKD, MXN, GBP, SGD: 100,000.00
   * JPY, KRW: 100,000
   * INR: 1,00,00.00
   * RUB, EUR: 100.00,00
   * CHF: 100'000.00
   *
   * @param number
   * @param currencyType
   */
  formatCurrency(number: string, currencyType: string) {
    if (USDFormatted.includes(currencyType)) {
      return this._decimalPipe.transform(number.replace(notNumbersWithDecimalsRegex, ''), '1.0-2');
    } else {
      return (new Intl.NumberFormat(this.getLocaleBasedOnType(currencyType)).format(parseFloat(number)));
    }
  }

  getDecimalSeparator(currencyType: string) {
    if (USDFormatted.includes(currencyType)) {
      return '.'
    } else {
      const numberWithDecimalSeparator = 1.1;
      return numberWithDecimalSeparator.toLocaleString(this.getLocaleBasedOnType(currencyType)).substring(1, 2);
    }
  }

  /**
   * Function that maps currency types (USD, EUR, JPY etc) to locale with currenct formatting
   *
   * @param currencyType
   */
  private getLocaleBasedOnType(currencyType: string) {
    switch (currencyType) {
      case 'EUR':
      case 'RUB':
        return 'de-DE';
      case 'JPY':
        return 'ja-JP';
      case 'KRW':
        return 'ko-KR';
      case 'INR':
        return 'en-IN';
      case 'CHF':
        return 'de-CH';
    }
  }
}
