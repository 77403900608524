<div class="modal__header">
    <div class="modal__title">
        CREATE SUB-ITEM
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form [formGroup]="subItemForm" (submit)="save(subItemForm.value, subItemForm.valid)">
    <div class="modal__content">
        <div class="form-header">
            SubItems allow for breaking down mapped items into finer details.
        </div>

        <div class="row">
            <div class="col-8">
                <div class="mb-3">
                    <label class="control-label" for="label">Label</label>
                    <input [ngClass]="{'is-invalid': isFieldInvalid('label')}" type="text" class="form-control" id="label" placeholder="e.g. Online Sales" formControlName="label" maxlength="50">
                    <span class="invalid-feedback"
                          *ngIf="isFieldInvalid('label') && subItemForm.get('label').hasError('required')">
                        Label is required
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="submitted">Create SubItem</button>
    </div>
</form>
