import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {NgxPopupComponent} from '../../ngx-popups/ngx-popups/components/popup.component';
import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {Company} from "@models/company";
import {FileItem, FileUploader, ParsedResponseHeaders} from "ng2-file-upload";
import {BehaviorSubject, Subscription} from "rxjs";
import {AlertService} from "@services/alert.service";
import {Router} from "@angular/router";
import {DocumentFileCreateRequest} from "@models/document-file-create-request";
import {DocumentFileCreateRequestState} from "@utils/enums";
import {DocumentFileService} from "@services/document-file.service";
import {ReviewQueueService} from "@services/review-queue.service";
import {SUPPORTED_TAX_FORMS, TAX_RETURN} from "@utils/constants";
import {CustomAttributeDefinition} from "@models/custom-attribute-definition";
import {CustomAttributeDefinitionService} from "@services/custom-attribute-definition.service";
import {LaunchDarklyService} from "@services/launchdarkly.service";
import {Select2OptionData} from "@components/shared/select2/select2.interface";
import {finalize} from "rxjs/operators";
import {
  FileItemRowComponent
} from "@components/shared/popups/upload-document-modal/file-item-row/file-item-row.component";

const FILE_UPLOAD_LIMIT = 10;

const allowedMimeTypes = [
  'image/x-png',
  'image/png',
  'image/gif',
  'image/jpeg',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

export class NgtFileItem {
  // Wrapper for the uploader FileItem type, allows us to attach user-inputted info about the upload and form error
  // state to the file object.
  fileItem: FileItem;
  selectedType: string = '';
  selectedTaxForm: string = '';
  selectedStatementDate: string = '';
  customAttributesValues: any = {};
  status?: 'uploaded' | 'submitting' | 'submission_complete' | 'error' = 'uploaded'
  error = {
    'selectedType': false,
    'selectedTaxForm': false,
    'selectedStatementDate': false,
    'invalidCustomAttributeFields': {}
  }

  constructor(fileItem) {
    this.fileItem = fileItem;
  }
}


@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class UploadDocumentModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() company: Company;
  @ViewChild('uploadInput', {static: true}) uploadInputHtml: ElementRef;
  @ViewChildren('uploadedDocumentRows') uploadedDocumentRows: QueryList<FileItemRowComponent>;

  subsArr$: Subscription[] = [];
  uploader: FileUploader;
  uploadedFiles: NgtFileItem[] = [];
  fileDragOver = false;
  fileUploadProcessBegun = false;
  FILE_UPLOAD_LIMIT = FILE_UPLOAD_LIMIT;

  customAttrDefinitions: CustomAttributeDefinition[] = [];
  SUPPORTED_TAX_FORMS: Array<string> = Array.from(SUPPORTED_TAX_FORMS);
  showPuertoRicoCorporate: Boolean = false;
  showPuertoRicoIndividual: Boolean = false;
  taxFormOptions: Select2OptionData[];

  fileNotSelectedError = false;
  uploadInputAccept = allowedMimeTypes.join();

  constructor(
    private _alertService: AlertService,
    private _router: Router,
    private documentFileService: DocumentFileService,
    private _reviewQueueService: ReviewQueueService,
    private _customAttributeDefinitionService: CustomAttributeDefinitionService,
    private _featureFlags: LaunchDarklyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.buildUploader();
    this.updateFlags();
    this.updateSupportedForms();
    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.updateFlags();
      this.updateSupportedForms();
    }));

    this.subsArr$.push(this._customAttributeDefinitionService.getCustomAttributesForModel('DocumentFile').subscribe(customAttributeDefinitions => {
      this.customAttrDefinitions = customAttributeDefinitions;
    }));
  }

  buildUploader() {
    this.uploader = new FileUploader({
      autoUpload: false,
      method: 'PUT',
      queueLimit: 10,
      disableMultipart: true,
      isHTML5: true,
      allowedMimeType: allowedMimeTypes
    });

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      fileItem.withCredentials = false;
      this.fileNotSelectedError = false;
      this.uploadedFiles.push(new NgtFileItem(fileItem));
      this.changeDetectorRef.detectChanges();  // ensure the viewChildren ref is updated with most recently added file
      this.scrollToBottomOfDocumentsList();
    }

    this.uploader.onWhenAddingFileFailed = (item) => {
      let errorMsg = 'Invalid File Type';
      if (this.uploader.queue.length === FILE_UPLOAD_LIMIT){
        errorMsg = `Unable to add document. Only ${FILE_UPLOAD_LIMIT} can be uploaded at a time.`
      }
      this._alertService.error(errorMsg);
    }


    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploader.clearQueue();
      this.uploadInputHtml.nativeElement.value = '';
      const parsedResponse = JSON.parse(response);
      if (status === 401 || status === 403) {
        this._router.navigate(['/logout'], {queryParams: {returnUrl: this._router.url}});
        return;
      }
      this._alertService.error(parsedResponse.errors ? parsedResponse.message : 'Upload Error');
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploadInputHtml.nativeElement.value = '';
      if (status === 401 || status === 403) {
        this._router.navigate(['/logout'], {queryParams: {returnUrl: this._router.url}});
      }
    };
  }


  openFilePicker() {
    // When user cancels and chooses same file, the popup is not created, so fix by setting value to null
    // https://html.spec.whatwg.org/multipage/input.html#dom-input-value-filename
    this.uploadInputHtml.nativeElement.value = null;
    this.uploadInputHtml.nativeElement.click();
  }

  removeFile(indexOfFileToRemove, fileItem) {
    this.uploadedFiles.splice(indexOfFileToRemove, 1);
    this.uploader.removeFromQueue(fileItem);
    this.fileUploadProcessBegun = false;
  }


  fileOverDropZone(event) {
    if (this.fileDragOver !== event) {
      this.fileDragOver = event;
    }
  }

  async submitUploadedFiles() {
    this.fileUploadProcessBegun = true;
    const formIsValid = this.validateForm();
    if (formIsValid) {
      this.uploadedFiles.forEach(f => f.status = 'submitting');
      for (let uploadedFile of this.uploadedFiles) {
        // need to submit these synchronously so the FileUploader can process each file individually
        try {
          await this.submitFile(uploadedFile)
          uploadedFile.status ='submission_complete'
        } catch (e) {
          uploadedFile.status ='error'
        }
      }
      this._alertService.info('The documents have been uploaded and are now processing through our system.');
      if (!this._router.url.endsWith('data')) {
        this.navigateToDocumentsTab();
      }
      this.close();
    } else {
      this.fileUploadProcessBegun = false;
    }
  }

  async submitFile(uploadedFile) {
    return new Promise((resolve, reject) => {
      const docRequest = this.createDocFileRequest(uploadedFile)
      this.subsArr$.push(this.documentFileService.uploadDocumentFile(docRequest).subscribe(
        _ => {},
        e => {
          this._alertService.error('Error uploading document');
          console.error('document upload error: ', e);
          reject();
        },
        () => {
          // file successfully uploaded
          const createFilePayload = this._buildCreateFilePayload(docRequest);
          this.documentFileService.createDocumentFile(createFilePayload)
            .pipe(
              // allow the loop to continue regardless of individual file failures.
              finalize(() => resolve(true))
            )
            .subscribe(_ => {
            }, e => {
              // show error toast and proceed for individual upload failures
              this._alertService.error('Error creating document');
              console.error('document create error: ', e);
              reject();
            });
        }
      ));
    })
  }

  createDocFileRequest(uploadedFile: NgtFileItem) {
    const docFileCreateRequest = new DocumentFileCreateRequest();
    docFileCreateRequest.state = DocumentFileCreateRequestState.FileChoosen;
    docFileCreateRequest.companyId = this.company.id;
    docFileCreateRequest.originalDocumentName = uploadedFile.fileItem.file.name;
    docFileCreateRequest.documentType = uploadedFile.selectedType;
    docFileCreateRequest.customAttributes = uploadedFile.customAttributesValues;
    docFileCreateRequest.statementDate = uploadedFile.selectedStatementDate !== '' ? uploadedFile.selectedStatementDate : undefined;
    docFileCreateRequest.taxFormType = uploadedFile.selectedTaxForm;
    docFileCreateRequest.roundedToThousands = this.company.settings['roundToThousands'];
    docFileCreateRequest.currency = this.company.settings['currency'];
    docFileCreateRequest.uploader = this.uploader;
    docFileCreateRequest.additionalInformation = '';
    docFileCreateRequest.requestedPeriods = 'all'
    docFileCreateRequest.fileItem = uploadedFile.fileItem;
    return docFileCreateRequest
  }

  _buildCreateFilePayload(createFileRequest) {
    // clear unused/problematic circular reference from payload in multi-upload scenario
    return {
      ...createFileRequest,
      uploader: null,
      fileItem: {...createFileRequest.fileItem, uploader: null}
    }
  }

  updateSupportedForms() {
    this.SUPPORTED_TAX_FORMS = Array.from(SUPPORTED_TAX_FORMS);
    this.SUPPORTED_TAX_FORMS.push('1040 Schedule C');
    if (this.showPuertoRicoCorporate) {
      this.SUPPORTED_TAX_FORMS.push('Puerto Rico Corporate')
    }
    if (this.showPuertoRicoIndividual) {
      this.SUPPORTED_TAX_FORMS.push('Puerto Rico Individual')
    }
    this.SUPPORTED_TAX_FORMS.push('Schedule E')
    this.SUPPORTED_TAX_FORMS.push('Schedule F')

    this.taxFormOptions = this.SUPPORTED_TAX_FORMS.map(form => {
      return {
        id: form,
        text: form
      }
    })
  }

  validateForm() {
    if (!this.uploadedFiles?.length) {
      this.fileNotSelectedError = true;
      return false;
    }
    let formValid = true;

    for (let uploadedFile of this.uploadedFiles) {
      if (!uploadedFile.selectedType || uploadedFile.selectedType.length === 0) {
        uploadedFile.error.selectedType = true;
        formValid = false;
      }
      if (uploadedFile.selectedType === TAX_RETURN) {
        if (!uploadedFile.selectedTaxForm) {
          uploadedFile.error.selectedTaxForm = true;
          formValid = false;
        }
        if (!uploadedFile.selectedStatementDate) {
          uploadedFile.error.selectedStatementDate = true;
          formValid = false;
        }
      }
      this.customAttrDefinitions.forEach((attrDefinition) => {
        if (attrDefinition.required) {
          if (!uploadedFile.customAttributesValues.hasOwnProperty(attrDefinition.key) ||
            !uploadedFile.customAttributesValues[attrDefinition.key]) {
            // mark input error
            uploadedFile.error.invalidCustomAttributeFields[attrDefinition.key] = true;
            formValid = false;
          }
        }
      });
    }
    return formValid;
  }

  updateFlags(): void {
    this.showPuertoRicoCorporate = this._featureFlags.flags['puerto-rico-corporate'];
    this.showPuertoRicoIndividual = this._featureFlags.flags['puerto-rico-individual'];
  }

  close(): void {
    this.popup.close();
  }

  uploadAnother(): void {
    this.popup.close(true, {reOpenModal: true});
  }

  navigateToDocumentsTab(): void {
    this._router.navigate(['/companies/', this.company.uuid, 'data']);
    this.close();
  }

  scrollToBottomOfDocumentsList(){
    const docRowElements = this.uploadedDocumentRows.toArray();
    if (docRowElements.length < 2){
      return
    }
    const lastElement = docRowElements[docRowElements.length - 1];
    lastElement.nativeElement.scrollIntoView({behavior: 'smooth'});

  }
}
