import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class DeveloperService {
  constructor(private apiService: ApiService) { }

  getUrlList(): Observable<any> {
    return this.apiService.send('Get', `/api/developer/urls`).pipe(
      map(data => data));
  }
}
