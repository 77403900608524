
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessingListComponent } from './processing-list.component';
import { ProcessingItemComponent } from './processing-item/processing-item.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { PopupsModule } from '../popups/popups.module';

@NgModule({
  declarations: [
    ProcessingListComponent,
    ProcessingItemComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    PopupsModule
  ],
  exports: [ProcessingListComponent],
  providers: [],
})
export class ProcesslingListModule {
}

