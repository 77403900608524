import { fromEvent as observableFromEvent, Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';

@Component({
  selector: 'app-saving-popup',
  templateUrl: './saving-popup.component.html',
  styleUrls: ['./saving-popup.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class SavingPopupComponent implements OnInit {
  @Input() popup: NgxPopupComponent;

  @Output() callback = new EventEmitter<boolean>();
  enterPressObs$ = observableFromEvent(document, 'keydown').pipe(
    filter((keyEv: KeyboardEvent) => keyEv.keyCode === 13 || keyEv.keyCode === 27));
  subsArr$: Subscription[] = [];
  emitted = false;

  ngOnInit() {
  }
}
