export class ContextMenuDocument {
  label: string;
  id: number;
  associatedStatements?: number[]
  action?: string;

  constructor(label, id, associatedStatements = []) {
    this.label = label;
    this.id = id;
    this.associatedStatements = associatedStatements;
  }
}
