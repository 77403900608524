import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SignedUrlService } from '../../../../services/signed-url.service';
import { AlertService } from '../../../../services/alert.service';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { SignedUrl } from '../../../../models/signed-url';

@Component({
  selector: 'app-api-keys-add',
  templateUrl: './api-keys-add.component.html',
  styleUrls: ['./api-keys-add.component.scss']
})
export class ApiKeysAddComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY

  apiKeyForm: FormGroup;
  submitted = false;
  created = false;
  submitAttempt = false;
  showDeployButton = false;
  subsArr$: Subscription[] = [];
  image: any;

  demoAppOptions = [
    {
      id: 'getting_started_python',
      text: 'Fincura integration using Python'
    }
  ];

  // public selectOptions: Select2Options;
  public selectOptions: any;

  createdApiKey: SignedUrl;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private signedUrlService: SignedUrlService,
              private alertService: AlertService,
  ) {

  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.apiKeyForm = this.formBuilder.group({
      label: ['', [Validators.required]]
    });
  }

  /**
   *
   * @param {string} field
   * @returns {boolean}
   */
  isFieldInvalid(field: string): boolean {
    return (
      (this.apiKeyForm.get(field).invalid && this.apiKeyForm.get(field).dirty) ||
      (this.apiKeyForm.get(field).invalid && this.submitAttempt)
    );
  }

  copyToClipboard(htmlId, itemName: string = '') {
    const copyText = document.getElementById(htmlId);
    // @ts-ignore
    copyText.select();
    /* Copy the text inside the text field */
    document.execCommand('copy');
    this.alertService.success(`Copied${' ' + itemName} to clipboard`);
  }

  /**
   *
   * @param user
   * @param {boolean} isValid
   */
  save(apiKey: any, isValid: boolean) {
    this.submitAttempt = true;
    if (!isValid || this.submitted) {
      return;
    }
    this.submitted = true;

    this.subsArr$.push(this.signedUrlService.createApiKey(apiKey.label.trim())
      .subscribe(
        data => {
          this.createdApiKey = data;
          this.submitted = false;
          this.created = true;
          // this.popup.close(true, true);
          this.alertService.success('Api Key created successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }

  useInSwagger() {
    localStorage.setItem('tmp_api_key', this.createdApiKey.jwt);
    this.router.navigate(['/api-docs/swagger-ui']);
    this.popup.close(true);
  }

}
