import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipesModule } from '../../../../pipes/pipes.module';

import { CurrencyModule } from '../../currency/currency.module';

import { FormulaInputPickerComponent } from './formula-input-picker.component';
import { FormulaInputPickerDirective } from './formula-input-picker.directive';
import { FormulaInputPicker } from './formula-input-picker';
import { FormulaInputOptionComponent } from './formula-input-option/formula-input-option.component';

@NgModule({
    imports: [CommonModule, FormsModule, PipesModule, CurrencyModule],
    declarations: [FormulaInputPickerComponent, FormulaInputOptionComponent, FormulaInputPickerDirective],
    exports: [FormulaInputPickerComponent, FormulaInputPickerDirective],
    entryComponents: [FormulaInputPickerComponent]
})
export class FormulaInputPickerModule {
    public static forRoot(): ModuleWithProviders<FormulaInputPickerModule> {
    return {
        ngModule: FormulaInputPickerModule,
        providers: [FormulaInputPicker]
    };
}
}
