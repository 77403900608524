/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Borrower { 
    readonly uuid?: string;
    /**
     * Name of the Borrower. Must be unique across Borrower records
     */
    name: string;
    readonly createdDate?: string;
    /**
     * UUID of the Portfolio for this Borrower. (see [Portfolios](#tag/Portfolios))
     */
    portfolioUuid?: string;
    /**
     * External ID is used to uniquely identify a record from your system in our system. Must be unique across records.
     */
    externalId?: string | null;
    /**
     * Borrower contact email
     */
    contactEmail?: string;
    /**
     * Borrower contact Full Name
     */
    contactPerson?: string | null;
    /**
     * Borrower contact Phone Number
     */
    contactPhone?: string | null;
    sicCode?: string | null;
    naicsCode?: string | null;
    sicIndustry?: Borrower.SicIndustryEnum;
    customAttributes?: object;
}
export namespace Borrower {
    export type SicIndustryEnum = 'UNKNOWN' | 'AGRICULTURE_FORESTRY_FISHING' | 'CONSTRUCTION' | 'MINING' | 'TRANSPORTATION_AND_PUBLIC_UTILITIES' | 'WHOLESALE_TRADE' | 'RETAIL_TRADE' | 'MANUFACTURING' | 'FINANCE_INSURANCE_REAL_ESTATE' | 'SERVICES' | 'PUBLIC_ADMINISTRATION';
    export const SicIndustryEnum = {
        Unknown: 'UNKNOWN' as SicIndustryEnum,
        AgricultureForestryFishing: 'AGRICULTURE_FORESTRY_FISHING' as SicIndustryEnum,
        Construction: 'CONSTRUCTION' as SicIndustryEnum,
        Mining: 'MINING' as SicIndustryEnum,
        TransportationAndPublicUtilities: 'TRANSPORTATION_AND_PUBLIC_UTILITIES' as SicIndustryEnum,
        WholesaleTrade: 'WHOLESALE_TRADE' as SicIndustryEnum,
        RetailTrade: 'RETAIL_TRADE' as SicIndustryEnum,
        Manufacturing: 'MANUFACTURING' as SicIndustryEnum,
        FinanceInsuranceRealEstate: 'FINANCE_INSURANCE_REAL_ESTATE' as SicIndustryEnum,
        Services: 'SERVICES' as SicIndustryEnum,
        PublicAdministration: 'PUBLIC_ADMINISTRATION' as SicIndustryEnum
    };
}


