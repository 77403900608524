/**
 * @module Criteria
 */

import Query from './query';

/**
 * Class for Criteria Instance
 */
export class Criteria {

  id: number;
  type: string;
  val: string | Array<any>;
  unionType: string;

  /**
   * Constructor
   * @param id {String}
   * @param type {String}
   * @param val {String|Array}
   * @param unionType {String}
   */
  constructor(id, type, val, unionType = 'and') {
    this.id = id;
    this.type = type;
    this.val = val instanceof Array ? val : [val];
    this.unionType = unionType;
  }
}

/**
 * Class for Criteria - used to generate criteria for back end
 */
class CriteriaBuilder {

  /**
   * Operator Map
   * @type {{EQUAL: string, NOT_EQUAL: string, IN: string, NOT_IN: string, LIKE: string,
      * LESS: string, NOT_LESS: string, MORE: string, NOT_MORE: string, IS_NULL: string, IS_NOT_NULL: string}}
   */
  static TYPE = {
    EQUAL: 'equal',
    NOT_EQUAL: 'not_equal',
    IN: 'in',
    NOT_IN: 'not_in',
    LIKE: 'like',
    LESS: 'less',
    NOT_LESS: 'not_less',
    MORE: 'more',
    NOT_MORE: 'not_more',
    IS_NULL: 'is_null',
    IS_NOT_NULL: 'is_not_null'
  };

  /**
   * Equal (=)
   * @param path {String}
   * @param value
   * @return {Criteria}
   */
  static equal(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.EQUAL, value);
  }

  /**
   * Not Equal (<>)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static notEqual(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.NOT_EQUAL, value);
  }

  /**
   * Contains (in)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static contains(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.IN, value);
  }

  /**
   * Not Contains (not in)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static notContains(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.NOT_IN, value);
  }

  /**
   * Like (like '%${value}%')
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static like(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.LIKE, value);
  }

  /**
   * Less (<)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static less(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.LESS, value);
  }

  /**
   * Not Less (>=)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static notLess(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.NOT_LESS, value);
  }

  /**
   * More (>)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static more(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.MORE, value);
  }

  /**
   * Not More (<=)
   * @param path {String}
   * @param value
   * @returns {Criteria}
   */
  static notMore(path, value) {
    return new Criteria(path, CriteriaBuilder.TYPE.NOT_MORE, value);
  }

  /**
   * Is Null (is null)
   * @param path {String}
   * @returns {Criteria}
   */
  static isNull(path) {
    return new Criteria(path, CriteriaBuilder.TYPE.IS_NULL, CriteriaBuilder.TYPE.IS_NULL);
  }

  /**
   * Is Not Null (not is null)
   * @param path {String}
   * @returns {Criteria}
   */
  static isNotNull(path) {
    return new Criteria(path, CriteriaBuilder.TYPE.IS_NOT_NULL, CriteriaBuilder.TYPE.IS_NOT_NULL);
  }

  /**
   * Create new Query
   * @return {Query}
   */
  static newQuery() {
    return new Query();
  }
}

export default CriteriaBuilder;
