import { ChangeDetectorRef, Component, ElementRef, OnDestroy, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  IMyCalendarDay,
  IMyCalendarMonth,
  IMyCalendarYear,
  IMyDate,
  IMyMonth,
  IMyOptions,
  IMySelectorPosition,
  IMyWeek,
} from './interfaces';
import { UtilService } from './services/ngx-my-date-picker.util.service';
import { KeyCode, MonthId } from './enums';

@Component({
  selector: 'app-ngx-my-date-picker',
  styleUrls: ['ngx-my-date-picker.component.scss'],
  templateUrl: 'ngx-my-date-picker.component.html',
  providers: [UtilService],
  encapsulation: ViewEncapsulation.None,
})

export class NgxMyDatePickerComponent implements OnDestroy {
  @ViewChild('selectorEl', { static: true }) selectorEl: any;
  opts: IMyOptions;
  visibleMonth: IMyMonth = {monthTxt: '', monthNbr: 0, year: 0};
  selectedMonth: IMyMonth = {monthTxt: '', monthNbr: 0, year: 0};
  selectedDate: IMyDate = {year: 0, month: 0, day: 0};
  weekDays: Array<string> = [];
  dates: Array<IMyWeek> = [];
  months: Array<Array<IMyCalendarMonth>> = [];
  years: Array<Array<IMyCalendarYear>> = [];
  disableTodayBtn = false;
  dayIdx = 0;
  weekDayOpts: Array<string> = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

  selectMonth = false;
  selectYear = false;

  dateChanged: Function;
  calendarViewChanged: Function;
  closedByEsc: Function;
  selectorPos: IMySelectorPosition = null;

  prevMonthDisabled = false;
  nextMonthDisabled = false;
  prevYearDisabled = false;
  nextYearDisabled = false;
  prevYearsDisabled = false;
  nextYearsDisabled = false;

  prevMonthId: number = MonthId.prev;
  currMonthId: number = MonthId.curr;
  nextMonthId: number = MonthId.next;

  clickListener: Function;

  constructor(public elem: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef, private utilService: UtilService) {
    this.clickListener = renderer.listen(elem.nativeElement, 'click', (evt: MouseEvent) => {
      if ((this.opts.monthSelector || this.opts.yearSelector) && evt.target) {
        this.resetMonthYearSelect();
      }
    });
  }

  ngOnDestroy(): void {
    this.clickListener();
  }

  initialize(opts: IMyOptions, defaultMonth: string, selectorPos: IMySelectorPosition, inputValue: string,
             dc: Function, cvc: Function, cbe: Function): void {
    this.opts = opts;
    this.selectorPos = selectorPos;
    this.weekDays.length = 0;

    this.isTodayDisabled();
    this.dayIdx = this.weekDayOpts.indexOf(this.opts.firstDayOfWeek);
    if (this.dayIdx !== -1) {
      let idx: number = this.dayIdx;
      for (let i = 0; i < this.weekDayOpts.length; i++) {
        this.weekDays.push(this.opts.dayLabels[this.weekDayOpts[idx]]);
        idx = this.weekDayOpts[idx] === 'sa' ? 0 : idx + 1;
      }
    }

    const date: IMyDate = this.utilService.isDateValid(inputValue, this.opts.dateFormat, this.opts.minYear,
      this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends,
      this.opts.disableDates, this.opts.disableDateRanges, this.opts.monthLabels, this.opts.enableDates);
    if (date.day !== 0 && date.month !== 0 && date.year !== 0) {
      this.selectedDate = date;
    } else {
      if (defaultMonth !== null && defaultMonth !== undefined && defaultMonth !== '') {
        this.selectedMonth = this.utilService.parseDefaultMonth(defaultMonth);
      }
    }

    this.dateChanged = dc;
    this.calendarViewChanged = cvc;
    this.closedByEsc = cbe;

    this.setVisibleMonth();
  }

  setCalendarView(date: IMyDate): void {
    this.selectedDate = date;
    this.setVisibleMonth();
  }

  resetMonthYearSelect(): void {
    this.selectMonth = false;
    this.selectYear = false;
  }

  onSelectMonthClicked(event: any): void {
    event.stopPropagation();
    this.selectMonth = !this.selectMonth;
    this.selectYear = false;
    this.cdr.detectChanges();
    if (this.selectMonth) {
      const today: IMyDate = this.getToday();
      this.months.length = 0;
      for (let i = 1; i <= 12; i += 3) {
        const row: Array<IMyCalendarMonth> = [];
        for (let j = i; j < i + 3; j++) {
          const disabled: boolean = this.utilService.isMonthDisabledByDisableUntil({
              year: this.visibleMonth.year,
              month: j,
              day: this.daysInMonth(j, this.visibleMonth.year)
            }, this.opts.disableUntil)
            || this.utilService.isMonthDisabledByDisableSince({
              year: this.visibleMonth.year,
              month: j,
              day: 1
            }, this.opts.disableSince);
          row.push({
            nbr: j,
            name: this.opts.monthLabels[j],
            currMonth: j === today.month && this.visibleMonth.year === today.year,
            selected: j === this.visibleMonth.monthNbr,
            disabled: disabled
          });
        }
        this.months.push(row);
      }
    }
  }

  onMonthCellClicked(cell: IMyCalendarMonth): void {
    const mc: boolean = cell.nbr !== this.visibleMonth.monthNbr;
    this.visibleMonth = {
      monthTxt: this.opts.monthFullLabels[cell.nbr],
      monthNbr: cell.nbr,
      year: this.visibleMonth.year
    };
    this.generateCalendar(cell.nbr, this.visibleMonth.year, mc);
    this.selectMonth = false;
    this.selectorEl.nativeElement.focus();
  }

  onMonthCellKeyDown(event: any, cell: IMyCalendarMonth) {
    if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
      event.preventDefault();
      this.onMonthCellClicked(cell);
    }
  }

  onSelectYearClicked(event: any): void {
    event.stopPropagation();
    this.selectYear = !this.selectYear;
    this.selectMonth = false;
    this.cdr.detectChanges();
    if (this.selectYear) {
      this.generateYears(this.visibleMonth.year);
    }
  }

  onYearCellClicked(cell: IMyCalendarYear): void {
    const yc: boolean = cell.year !== this.visibleMonth.year;
    this.visibleMonth = {
      monthTxt: this.visibleMonth.monthTxt,
      monthNbr: this.visibleMonth.monthNbr,
      year: cell.year
    };
    this.generateCalendar(this.visibleMonth.monthNbr, cell.year, yc);
    this.selectYear = false;
    this.selectorEl.nativeElement.focus();
  }

  onPrevYears(event: any, year: number): void {
    event.stopPropagation();
    this.generateYears(year - 25);
  }

  onNextYears(event: any, year: number): void {
    event.stopPropagation();
    this.generateYears(year + 25);
  }

  generateYears(year: number): void {
    this.years.length = 0;
    const today: IMyDate = this.getToday();
    for (let i = year; i <= 20 + year; i += 5) {
      const row: Array<IMyCalendarYear> = [];
      for (let j = i; j < i + 5; j++) {
        const disabled: boolean = this.utilService.isMonthDisabledByDisableUntil({
            year: j,
            month: this.visibleMonth.monthNbr,
            day: this.daysInMonth(this.visibleMonth.monthNbr, j)
          }, this.opts.disableUntil)
          || this.utilService.isMonthDisabledByDisableSince({
            year: j,
            month: this.visibleMonth.monthNbr,
            day: 1
          }, this.opts.disableSince);
        const minMax: boolean = j < this.opts.minYear || j > this.opts.maxYear;
        row.push({
          year: j,
          currYear: j === today.year,
          selected: j === this.visibleMonth.year,
          disabled: disabled || minMax
        });
      }
      this.years.push(row);
    }
    this.prevYearsDisabled = this.years[0][0].year <= this.opts.minYear || this.utilService.isMonthDisabledByDisableUntil({
      year: this.years[0][0].year - 1,
      month: this.visibleMonth.monthNbr,
      day: this.daysInMonth(this.visibleMonth.monthNbr, this.years[0][0].year - 1)
    }, this.opts.disableUntil);
    this.nextYearsDisabled = this.years[4][4].year >= this.opts.maxYear || this.utilService.isMonthDisabledByDisableSince({
      year: this.years[4][4].year + 1,
      month: this.visibleMonth.monthNbr,
      day: 1
    }, this.opts.disableSince);
  }

  onYearCellKeyDown(event: any, cell: IMyCalendarYear) {
    if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
      event.preventDefault();
      this.onYearCellClicked(cell);
    }
  }

  isTodayDisabled(): void {
    this.disableTodayBtn = this.utilService.isDisabledDate(this.getToday(), this.opts.minYear, this.opts.maxYear,
      this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends, this.opts.disableDates,
      this.opts.disableDateRanges, this.opts.enableDates);
  }

  setVisibleMonth(): void {
    // Sets visible month of calendar
    let y = 0, m = 0;
    if (this.selectedDate.year === 0 && this.selectedDate.month === 0 && this.selectedDate.day === 0) {
      if (this.selectedMonth.year === 0 && this.selectedMonth.monthNbr === 0) {
        const today: IMyDate = this.getToday();
        y = today.year;
        m = today.month;
      } else {
        y = this.selectedMonth.year;
        m = this.selectedMonth.monthNbr;
      }
    } else {
      y = this.selectedDate.year;
      m = this.selectedDate.month;
    }
    this.visibleMonth = {monthTxt: this.opts.monthFullLabels[m], monthNbr: m, year: y};

    // Create current month
    this.generateCalendar(m, y, true);
  }

  onPrevMonth(): void {
    // Previous month from calendar
    const d: Date = this.getDate(this.visibleMonth.year, this.visibleMonth.monthNbr, 1);
    d.setMonth(d.getMonth() - 1);

    const y: number = d.getFullYear();
    const m: number = d.getMonth() + 1;

    this.visibleMonth = {monthTxt: this.opts.monthFullLabels[m], monthNbr: m, year: y};
    this.generateCalendar(m, y, true);
  }

  onNextMonth(): void {
    // Next month from calendar
    const d: Date = this.getDate(this.visibleMonth.year, this.visibleMonth.monthNbr, 1);
    d.setMonth(d.getMonth() + 1);

    const y: number = d.getFullYear();
    const m: number = d.getMonth() + 1;

    this.visibleMonth = {monthTxt: this.opts.monthFullLabels[m], monthNbr: m, year: y};
    this.generateCalendar(m, y, true);
  }

  onPrevYear(): void {
    // Previous year from calendar
    this.visibleMonth.year--;
    this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, true);
  }

  onNextYear(): void {
    // Next year from calendar
    this.visibleMonth.year++;
    this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, true);
  }

  onCloseSelector(event: any): void {
    if (event.keyCode === KeyCode.esc) {
      this.closedByEsc();
    }
  }

  onTodayClicked(): void {
    // Today button clicked
    const today: IMyDate = this.getToday();
    this.selectDate(today);
    if (!this.opts.closeSelectorOnDateSelect) {
      this.setVisibleMonth();
    }
  }

  onCellClicked(cell: any): void {
    // Cell clicked on the calendar
    if (cell.cmo === this.prevMonthId) {
      // Previous month of day
      this.onPrevMonth();
      if (!this.opts.allowSelectionOnlyInCurrentMonth) {
        this.selectDate(cell.dateObj);
      }
    } else if (cell.cmo === this.currMonthId) {
      // Current month of day
      this.selectDate(cell.dateObj);
    } else if (cell.cmo === this.nextMonthId) {
      // Next month of day
      this.onNextMonth();
      if (!this.opts.allowSelectionOnlyInCurrentMonth) {
        this.selectDate(cell.dateObj);
      }
    }
    this.resetMonthYearSelect();
  }

  onCellKeyDown(event: any, cell: any) {
    // Cell keyboard handling
    if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
      event.preventDefault();
      this.onCellClicked(cell);
    }
  }

  selectDate(date: IMyDate): void {
    // Notifies parent using callback
    this.selectedDate = date;
    this.dateChanged(this.utilService.getDateModel(date, this.opts.dateFormat, this.opts.monthLabels),
      this.opts.closeSelectorOnDateSelect);
  }

  monthStartIdx(y: number, m: number): number {
    // Month start index
    const d = new Date();
    d.setDate(1);
    d.setMonth(m - 1);
    d.setFullYear(y);
    const idx = d.getDay() + this.sundayIdx();
    return idx >= 7 ? idx - 7 : idx;
  }

  daysInMonth(m: number, y: number): number {
    // Return number of days of current month
    return new Date(y, m, 0).getDate();
  }

  daysInPrevMonth(m: number, y: number): number {
    // Return number of days of the previous month
    const d: Date = this.getDate(y, m, 1);
    d.setMonth(d.getMonth() - 1);
    return this.daysInMonth(d.getMonth() + 1, d.getFullYear());
  }

  isCurrDay(d: number, m: number, y: number, cmo: number, today: IMyDate): boolean {
    // Check is a given date the today
    return d === today.day && m === today.month && y === today.year && cmo === this.currMonthId;
  }

  getToday(): IMyDate {
    const date: Date = new Date();
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  getDayNumber(date: IMyDate): number {
    // Get day number: su=0, mo=1, tu=2, we=3 ...
    const d: Date = this.getDate(date.year, date.month, date.day);
    return d.getDay();
  }

  getWeekday(date: IMyDate): string {
    // Get weekday: su, mo, tu, we ...
    return this.weekDayOpts[this.getDayNumber(date)];
  }

  getDate(year: number, month: number, day: number): Date {
    // Creates a date object from given year, month and day
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  }

  sundayIdx(): number {
    // Index of Sunday day
    return this.dayIdx > 0 ? 7 - this.dayIdx : 0;
  }

  generateCalendar(m: number, y: number, notifyChange: boolean): void {
    this.dates.length = 0;
    const today: IMyDate = this.getToday();
    const monthStart: number = this.monthStartIdx(y, m);
    const dInThisM: number = this.daysInMonth(m, y);
    const dInPrevM: number = this.daysInPrevMonth(m, y);

    let dayNbr = 1;
    let cmo: number = this.prevMonthId;
    for (let i = 1; i < 7; i++) {
      const week: Array<IMyCalendarDay> = [];
      if (i === 1) {
        // First week
        const pm = dInPrevM - monthStart + 1;
        // Previous month
        for (let j = pm; j <= dInPrevM; j++) {
          const date: IMyDate = {year: m === 1 ? y - 1 : y, month: m === 1 ? 12 : m - 1, day: j};
          week.push({
            dateObj: date,
            cmo: cmo,
            currDay: this.isCurrDay(j, m, y, cmo, today),
            disabled: this.utilService.isDisabledDate(date, this.opts.minYear, this.opts.maxYear,
              this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends,
              this.opts.disableDates, this.opts.disableDateRanges, this.opts.enableDates),
            markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
            highlight: this.utilService.isHighlightedDate(date, this.opts.sunHighlight,
              this.opts.satHighlight, this.opts.highlightDates)
          });
        }

        cmo = this.currMonthId;
        // Current month
        const daysLeft: number = 7 - week.length;
        for (let j = 0; j < daysLeft; j++) {
          const date: IMyDate = {year: y, month: m, day: dayNbr};
          week.push({
            dateObj: date,
            cmo: cmo,
            currDay: this.isCurrDay(dayNbr, m, y, cmo, today),
            disabled: this.utilService.isDisabledDate(date, this.opts.minYear, this.opts.maxYear,
              this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends,
              this.opts.disableDates, this.opts.disableDateRanges, this.opts.enableDates),
            markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
            highlight: this.utilService.isHighlightedDate(date, this.opts.sunHighlight,
              this.opts.satHighlight, this.opts.highlightDates)
          });
          dayNbr++;
        }
      } else {
        // Rest of the weeks
        for (let j = 1; j < 8; j++) {
          if (dayNbr > dInThisM) {
            // Next month
            dayNbr = 1;
            cmo = this.nextMonthId;
          }
          const date: IMyDate = {
            year: cmo === this.nextMonthId && m === 12 ? y + 1 : y,
            month: cmo === this.currMonthId ? m : cmo === this.nextMonthId && m < 12 ? m + 1 : 1,
            day: dayNbr
          };
          week.push({
            dateObj: date,
            cmo: cmo,
            currDay: this.isCurrDay(dayNbr, m, y, cmo, today),
            disabled: this.utilService.isDisabledDate(date, this.opts.minYear, this.opts.maxYear,
              this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends,
              this.opts.disableDates, this.opts.disableDateRanges, this.opts.enableDates),
            markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
            highlight: this.utilService.isHighlightedDate(date, this.opts.sunHighlight,
              this.opts.satHighlight, this.opts.highlightDates)
          });
          dayNbr++;
        }
      }
      const weekNbr: number = this.opts.showWeekNumbers && this.opts.firstDayOfWeek === 'mo' ?
        this.utilService.getWeekNumber(week[0].dateObj) : 0;
      this.dates.push({week: week, weekNbr: weekNbr});
    }

    this.setHeaderBtnDisabledState(m, y);

    if (notifyChange) {
      // Notify parent
      this.calendarViewChanged({
        year: y,
        month: m,
        first: {number: 1, weekday: this.getWeekday({year: y, month: m, day: 1})},
        last: {number: dInThisM, weekday: this.getWeekday({year: y, month: m, day: dInThisM})}
      });
    }
  }

  setHeaderBtnDisabledState(m: number, y: number): void {
    let dpm = false;
    let dpy = false;
    let dnm = false;
    let dny = false;
    if (this.opts.disableHeaderButtons) {
      dpm = this.utilService.isMonthDisabledByDisableUntil({
        year: m === 1 ? y - 1 : y,
        month: m === 1 ? 12 : m - 1,
        day: this.daysInMonth(m === 1 ? 12 : m - 1, m === 1 ? y - 1 : y)
      }, this.opts.disableUntil);
      dpy = this.utilService.isMonthDisabledByDisableUntil({
        year: y - 1,
        month: m,
        day: this.daysInMonth(m, y - 1)
      }, this.opts.disableUntil);
      dnm = this.utilService.isMonthDisabledByDisableSince({
        year: m === 12 ? y + 1 : y,
        month: m === 12 ? 1 : m + 1,
        day: 1
      }, this.opts.disableSince);
      dny = this.utilService.isMonthDisabledByDisableSince({
        year: y + 1,
        month: m,
        day: 1
      }, this.opts.disableSince);
    }
    this.prevMonthDisabled = m === 1 && y === this.opts.minYear || dpm;
    this.prevYearDisabled = y - 1 < this.opts.minYear || dpy;
    this.nextMonthDisabled = m === 12 && y === this.opts.maxYear || dnm;
    this.nextYearDisabled = y + 1 > this.opts.maxYear || dny;
  }
}
