<div class="modal__header">
    <div class="modal__title">{{title}}</div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
    
<div class="modal__content">
    <div class="description" [innerHTML]="description"></div>
    <img *ngIf="gif" class="gif" src="../../../../../assets/images/sidebar-instruction-gifs/{{gif}}" alt="Example gif">
    <p *ngIf="!gif">Error loading gif</p>
</div>

<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="popup.close();">Close</button>
</div>
    