import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-component',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  constructor(
    private _toastrService: ToastrService
  ) { }

  ngOnInit() {
    this._toastrService.overlayContainer = this.toastContainer;

    if (this.isIE()) {
      alert('You are using an unsupported browser. Please switch to Chrome, Firefox or Edge.');
    }
  }

  isIE() {
    return window.navigator.userAgent.match(/(MSIE|Trident)/);
  }
}
