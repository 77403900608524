import { Routes } from '@angular/router';
import { AuthMiddleware } from '../middlewares/auth.middleware';
import { PortalAuthMiddleware } from '../middlewares/portal-auth.middleware';
import { CompanyDetailsComponent } from './main/borrower/details/details.component';
import { CompanyListComponent } from './main/borrower/list/list.component';
import { MainComponent } from './main/main.component';
import { UserProfileComponent } from './main/users/profile/profile.component';
import { UserLoginComponent } from './user/login/login.component';
import { UserLogoutComponent } from './user/logout/logout.component';
import { SwitchTenantComponent } from './user/switch-tenant/switch-tenant.component';
import { UsersListComponent } from './main/users/list/list.component';
import { ApiKeysListComponent } from './main/api-key/api-keys-list/api-keys-list.component';
import { CompanyProfileComponent } from './main/borrower/details/profile/company-profile.component';
import { StatementBuilderComponent } from './main/borrower/statement-builder/statement-builder.component';
import { CompanyComplianceComponent } from './main/borrower/details/compliance/compliance.component';
import { PortalComponent } from './portal/portal.component';
import { AccessDeniedComponent } from './main/denied/access-denied.component';
import { AccessExpiredComponent } from './main/expired/access-expired/access-expired.component';
import { BorrowerUploadComponent } from './portal/borrower-upload/borrower-upload.component';
import { ReviewComponent } from './main/review/review.component';
import { NotFoundComponent } from './main/not-found/not-found.component';
import { ReviewEditorComponent } from './main/review/review-editor/review-editor.component';
import { FullScreenComponent } from '@components/shared/document-viewer/full-screen/full-screen.component';
import { BorrowerOverviewComponent } from './main/borrower/details/borrower-overview/borrower-overview.component';
import { ReviewTableIdentificationComponent } from './main/review/human-validation/review-table-identification/review-table-identification.component';
import { ReviewDocumentContainerComponent } from './main/review/review-document-container/review-document-container.component';
import { ReviewTableTabulationComponent } from './main/review/human-validation/review-table-tabulation/review-table-tabulation.component';
import { AnalysisComponent } from './main/borrower/details/financials/analysis/analysis.component';
import { SpreadingComponent } from './main/spreading/spreading.component';
import { SpreadFileRedirectComponent } from './main/spreading/spread-file-redirect/spread-file-redirect.component';
import { ListSpreadingTemplatesComponent } from './main/spreading-template/list-spreading-templates/list-spreading-templates.component';
import { EditSpreadingTemplateComponent } from './main/spreading-template/edit-spreading-template/edit-spreading-template.component';
import { GenerateReportComponent } from './main/reporting/generate-report/generate-report.component';
import { AppStatusComponent } from './main/app-status/app-status.component';
import { ListAnalysesComponent } from './main/borrower/details/list-analyses/list-analyses.component';
import { EditAnalysisComponent } from './main/borrower/details/list-analyses/edit-analysis/edit-analysis.component';
import { ApiSwaggerUiComponent } from './main/api-key/docs/api-swagger-ui/api-swagger-ui.component';
import { ApiRedocComponent } from './main/api-key/docs/api-redoc/api-redoc.component';
import { ListCovenantsComponent } from './main/borrower/details/list-covenants/list-covenants.component';
import { AppStyleGuideComponent } from './developer/app-style-guide/app-style-guide.component';
import { WaitForReviewComponent } from './main/review/human-validation/wait-for-review/wait-for-review.component';
import { AccountingIntegrationInviteComponent } from './portal/accounting-integration-invite/accounting-integration-invite.component';
import { EmbeddedComponentContainerComponent } from '@components/main/embedded/embedded-component-container/embedded-component-container.component';
import { EmbeddedFinancialsComponent } from '@components/main/embedded/embedded-financials/embedded-financials.component';
import { EmbeddedDscrAnalysisComponent } from './main/embedded/embedded-dscr-analysis/embedded-dscr-analysis.component';
import { ConfirmDetailsComponent } from '@components/main/review/human-validation/confirm-details/confirm-details.component';
import { EditGlobalCashflowAnalysisComponent } from './main/borrower/details/list-analyses/edit-global-cashflow-analysis/edit-global-cashflow-analysis.component';
import { EmbeddedGlobalCashflowAnalysisComponent} from './main/embedded/embedded-global-cashflow-analysis/embedded-global-cashflow-analysis.component'
import {
  DocumentPasswordEntryComponent
} from '@components/main/review/human-validation/document-password-entry/document-password-entry.component';
import {
  EditMultiEntitySpreadingTemplateComponent
} from '@components/main/spreading-template/multi-entity/edit-multi-entity-spreading-template.component';
import {ManageUserMiddleware} from "@app/middlewares/manageUser.middleware";
import {ManageSpreadingTemplateMiddleware} from "@app/middlewares/manageSpreadingTemplate.middleware";
import {ReportingMiddleware} from "@app/middlewares/reporting.middleware";
import {EnterHILStepMiddleware} from "@app/middlewares/enterHILStep.middleware";
import {ViewOrEditSpreadAndAnalysisMiddleware} from "@app/middlewares/viewOrEditSpreadAndAnalysis.middleware";
import {SubmissionReviewQueueMiddleware} from "@app/middlewares/submissionReviewQueue.middleware";
import {DefaultLandingPageMiddleware} from "@app/middlewares/defaultLandingPage.middleware";
import {LandingComponent} from "@components/main/landing/landing.component";
import {SearchBorrowerMiddleware} from "@app/middlewares/searchBorrower.middleware";
import {PortfolioManagementComponent} from "@components/main/portfolio-management/portfolio-management.component";
import {PortfolioManagementMiddleware} from "@app/middlewares/portfolio-management.middleware";
import {GenerateApiTokenMiddleware} from "@app/middlewares/generateApiToken.middleware";
import { DeveloperMiddleware } from 'app/middlewares/developer.middleware';
import { CompanyEntitlementResolver } from 'app/resolvers/company-entitlement.resolver';
import {BorrowerListResolver} from "@app/resolvers/borrower-list-resolver";
import {StatementDataResolver} from "@app/resolvers/statement-data.resolver";
import {TableMergingComponent} from "@components/main/spreading/table-merging/table-merging.component";
import {FootnoteManagerComponent} from "@components/main/spreading/footnote-manager/footnote-manager.component";


const appRoutes: Routes = [
  {
    path: 'login',
    component: UserLoginComponent,
  },
  {
    path: 'logout',
    component: UserLogoutComponent,
  },
  {
    path: 'denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'app-token-expired',
    component: AccessExpiredComponent
  },
  {
    path: 'portal',
    component: PortalComponent,
    children: [
      {
        path: 'upload',
        component: BorrowerUploadComponent,
        canActivate: [PortalAuthMiddleware],
      },
      {
        path: ':bankId/:portalKey',
        component: BorrowerUploadComponent,
        canActivate: [PortalAuthMiddleware]
      }
    ]
  },
  {
    path: 'accounting-integration-invite/:companyId',
    component: AccountingIntegrationInviteComponent,
  },
  {
    path: 'switch-tenant',
    component: SwitchTenantComponent,
    canActivate: [AuthMiddleware]
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthMiddleware],
    children: [
      {
        path: '404',
        component: NotFoundComponent,
        pathMatch: 'full',
        canActivate: [AuthMiddleware]
      },
      {
        path: 'status',
        component: AppStatusComponent,
        canActivate: [AuthMiddleware],
      },
      {
        // defining the component here is required to circumvent an edge case with angular routing where
        // it's required to redefine the component when using a guard to control routing
        path: '',
        component: MainComponent,
        canActivate: [DefaultLandingPageMiddleware],
        pathMatch: 'full',
      },
      {
        path: 'companies',
        component: CompanyListComponent,
        canActivate: [AuthMiddleware, SearchBorrowerMiddleware],
        resolve: {
          customerIdRelatedSettings: BorrowerListResolver,
        }
      },
      {
        path: 'companies/:uuid',
        component: CompanyDetailsComponent,
        canActivate: [AuthMiddleware],
        resolve: {
          companyEntitlement: CompanyEntitlementResolver,
        },
        children: [
          {
            path: '',
            redirectTo: 'financials/analysis',
            pathMatch: 'full',
          },
          {
            path: 'covenants',
            canActivate: [AuthMiddleware, ViewOrEditSpreadAndAnalysisMiddleware],
            component: ListCovenantsComponent,
          },
          {
            path: 'analyses',
            canActivate: [AuthMiddleware],
            component: ListAnalysesComponent,
          },
          {
            path: 'analyses/:uuid/new',
            canActivate: [AuthMiddleware, ViewOrEditSpreadAndAnalysisMiddleware],
            component: EditAnalysisComponent,
          },
          {
            path: 'analyses/:uuid',
            canActivate: [AuthMiddleware, ViewOrEditSpreadAndAnalysisMiddleware],
            component: EditAnalysisComponent,
          },
          {
            path: 'global-cashflow-analyses/:uuid/new',
            canActivate: [AuthMiddleware, ViewOrEditSpreadAndAnalysisMiddleware],
            component: EditGlobalCashflowAnalysisComponent,
          },
          {
            path: 'global-cashflow-analyses/:uuid',
            canActivate: [AuthMiddleware, ViewOrEditSpreadAndAnalysisMiddleware],
            component: EditGlobalCashflowAnalysisComponent,
          },
          {
            path: 'financials',
            redirectTo: 'financials/analysis',
            pathMatch: 'full',
          },
          {
            path: 'financials/build',
            canActivate: [AuthMiddleware],
            component: StatementBuilderComponent,
          },
          {
            path: 'financials/analysis',
            canActivate: [AuthMiddleware],
            component: AnalysisComponent,
          },
          {
            path: 'financials/analysis/:id', // Leaving this as id for now since nested in a company uuid
            canActivate: [AuthMiddleware],
            component: AnalysisComponent,
          },
          {
            path: 'financials/calculation/raw',
            redirectTo: 'financials/calculation/normalized',
          },
          {
            path: 'financials/:documentType/:viewScreen/:dataFrameKey',
            redirectTo: 'financials/analysis',
            pathMatch: 'full',
          },
          {
            path: 'financials/:documentType/:viewScreen',
            redirectTo: 'financials/analysis',
            pathMatch: 'full',
          },
          {
            path: 'overview',
            component: BorrowerOverviewComponent,
            canActivate: [AuthMiddleware],
          },
          {
            path: 'profile',
            component: CompanyProfileComponent,
            canActivate: [AuthMiddleware],
          },
          {
            path: 'data',
            loadChildren: () => import('app/components/main/borrower/details/data/data.module').then(m => m.DataModule),
            canActivate: [AuthMiddleware],
          }
        ],
      },
      {
        path: 'users/profile',
        component: UserProfileComponent,
        canActivate: [AuthMiddleware],
      },
      {
        path: 'users/:id', // TODO, not used currently, but can be used to control user permissions later
        component: UserProfileComponent,
        canActivate: [AuthMiddleware, ManageUserMiddleware],
      },
      {
        path: 'users',
        component: UsersListComponent,
        canActivate: [AuthMiddleware, ManageUserMiddleware],
      },
      {
        path: 'api-keys',
        component: ApiKeysListComponent,
        canActivate: [AuthMiddleware, GenerateApiTokenMiddleware],
      },
      {
        path: 'api-docs/swagger-ui',
        component: ApiSwaggerUiComponent,
        canActivate: [AuthMiddleware],
      },
      {
        path: 'api-docs/redoc',
        component: ApiRedocComponent,
        canActivate: [AuthMiddleware],
      },
      {
        path: 'review',
        component: ReviewComponent,
        canActivate: [AuthMiddleware, SubmissionReviewQueueMiddleware],
      },
      {
        path: 'review/:uuid',
        component: ReviewDocumentContainerComponent,
        canActivate: [AuthMiddleware, EnterHILStepMiddleware],
        children: [
          {
            path: 'load/:companyUuid',
            component: WaitForReviewComponent,
          },
          {
            path: 'load',
            component: WaitForReviewComponent,
          },
          {
            path: 'table_identification',
            component: ReviewTableIdentificationComponent,
          },
          {
            path: 'table_tabulation',
            component: ReviewTableTabulationComponent,
          },
          {
            path: 'manual_review',
            component: ReviewEditorComponent,
          },
          {
            path: 'confirm_details',
            component: ConfirmDetailsComponent,
          },
          {
            path: 'password_entry',
            component: DocumentPasswordEntryComponent,
          },
        ]
      },
      {
        path: 'embedded',
        component: EmbeddedComponentContainerComponent,
        canActivate: [AuthMiddleware],
        children: [
          {
            path: 'financials/:companyId',
            component: EmbeddedFinancialsComponent,
            canActivate: [AuthMiddleware],
          },
          {
            path: 'dscr-analysis/:uuid',
            component: EmbeddedDscrAnalysisComponent,
            canActivate: [AuthMiddleware],
          },
          {
            path: 'global-cashflow-analysis/:uuid',
            component: EmbeddedGlobalCashflowAnalysisComponent,
            canActivate: [AuthMiddleware],
          }
        ]
      },
      {
        path: 'review/:uuid/fullscreen',
        component: FullScreenComponent,
        canActivate: [AuthMiddleware, EnterHILStepMiddleware]
      },
      {
        path: 'developer',
        loadChildren: () => import('app/components/developer/developer.module').then(m => m.DeveloperModule),
        canActivate: [AuthMiddleware, DeveloperMiddleware],
      },
      {
        path: 'statements/:statement_uuid',
        canActivate: [AuthMiddleware, EnterHILStepMiddleware],
        children: [
          {
            path: 'edit',
            component: SpreadingComponent,
          },
          {
            path: 'merge-table',
            component: TableMergingComponent,
            resolve: {
              tableMergingPageData: StatementDataResolver,
            }
          },
          {
            path: 'manage-footnotes',
            component: FootnoteManagerComponent,
            resolve: {
              tableMergingPageData: StatementDataResolver,
            }
          },
        ]
      },
      {
        path: 'spreading-templates',
        component: ListSpreadingTemplatesComponent,
        canActivate: [AuthMiddleware, ManageSpreadingTemplateMiddleware],
      },
      {
        path: 'spreading-templates/new-from/:original_uuid',
        component: EditSpreadingTemplateComponent,
        canActivate: [AuthMiddleware, ManageSpreadingTemplateMiddleware],
      },
      {
        path: 'spreading-templates/new/multi-entity',
        component: EditMultiEntitySpreadingTemplateComponent,
        canActivate: [AuthMiddleware, ManageSpreadingTemplateMiddleware],
      },
      {
        path: 'spreading-templates/new/:template_type',
        component: EditSpreadingTemplateComponent,
        canActivate: [AuthMiddleware, ManageSpreadingTemplateMiddleware],
      },
      {
        path: 'spreading-templates/multi-entity/:template_uuid',
        component: EditMultiEntitySpreadingTemplateComponent,
        canActivate: [AuthMiddleware, ManageSpreadingTemplateMiddleware],
      },
      {
        path: 'spreading-templates/:template_uuid/:template_type',
        component: EditSpreadingTemplateComponent,
        canActivate: [AuthMiddleware, ManageSpreadingTemplateMiddleware],
      },
      {
        path: 'reporting',
        component: GenerateReportComponent,
        canActivate: [AuthMiddleware, ReportingMiddleware],
      },
      {
        path: 'home',
        component: LandingComponent,
        canActivate: [AuthMiddleware]
      },
      {
        path: 'portfolios',
        component: PortfolioManagementComponent,
        canActivate: [AuthMiddleware, PortfolioManagementMiddleware],
      }
    ],
  },
  {
    path: 'spread/:file_uuid',
    component: SpreadFileRedirectComponent,
    canActivate: [AuthMiddleware],
  },
  {
    path: 'app-style-guide',
    component: AppStyleGuideComponent,
    canActivate: [AuthMiddleware]
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const routes = appRoutes;
