import {Component, OnInit, Input} from "@angular/core";

@Component({
  selector: 'borrower-alert',
  templateUrl: './borrower-alert.component.html',
  styleUrls: ['./borrower-alert.component.scss'],
})
export class BorrowerAlertComponent implements OnInit {
  @Input() alertText: string;

  constructor() {}

  ngOnInit() {}
}
