import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { FileUploadModule } from 'ng2-file-upload';
import { PipesModule } from '../../../pipes/pipes.module';
import { CustomUploaderService } from '../../../services/custom-uploader.service';
import { InputCalendarModule } from '../calendars/input-calendar/input-calendar.module';
import { CurrencyModule } from '../currency/currency.module';
import { ShowTransactionIdPopupComponent} from './show-transaction-id/show-transaction-id.component';
import { ConfirmationPopupComponent } from './confirmation/confirmation-popup.component';
import { UploadingPopupComponent } from './uploading/uploading-popup.component';
import { ResolveConflictPopupComponent } from './resolve-conflict/resolve-conflict.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { RequestStatementsComponent } from './request-statements/request-statements.component';
import { ShareLinkComponent } from './request-statements/share-link/share-link.component';
import { InstructionsGifPopupComponent } from './instructions-gif-popup/instructions-gif-popup.component';
import { FileIconComponent } from '../file-icon/file-icon.component';
import { DocumentFileSelectorButtonComponent } from '../document-file-selector-button/document-file-selector-button.component';
import { LoadTemplatesComponent } from './templates/load-templates.component';
import { SavingPopupComponent } from './saving/saving-popup.component';
import { AddFyeChangeComponent } from './add-fye-change/add-fye-change.component';
import { TaxFormTypeAndYearConfirmationComponent } from '@components/shared/popups/tax-form-type-and-year-confirmation/tax-form-type-and-year-confirmation.component';
import {
  DocumentPasswordEntryModalComponent
} from '@components/shared/popups/document-password-entry-modal/document-password-entry-modal.component';
import {
  UploadDocumentModalComponent
} from "@components/shared/popups/upload-document-modal/upload-document-modal.component";
import {Select2Module} from "@components/shared/select2/select2.module";
import { FileItemRowComponent } from './upload-document-modal/file-item-row/file-item-row.component';
import {
  ContactSupportEmailModalComponent
} from "@components/shared/popups/contact-support-email-modal/contact-support-email-modal.component";

@NgModule({
  declarations: [
    ShowTransactionIdPopupComponent,
    ConfirmationPopupComponent,
    UploadingPopupComponent,
    ResolveConflictPopupComponent,
    ContactSupportComponent,
    ContactSupportEmailModalComponent,
    UploadDocumentModalComponent,
    RequestStatementsComponent,
    ShareLinkComponent,
    InstructionsGifPopupComponent,
    FileIconComponent,
    DocumentFileSelectorButtonComponent,
    LoadTemplatesComponent,
    SavingPopupComponent,
    AddFyeChangeComponent,
    TaxFormTypeAndYearConfirmationComponent,
    DocumentPasswordEntryModalComponent,
    FileItemRowComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    HttpClientModule,
    PipesModule,
    CurrencyModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    InputCalendarModule,
    Select2Module,
  ],
  exports: [
    ShowTransactionIdPopupComponent,
    ConfirmationPopupComponent,
    UploadingPopupComponent,
    ResolveConflictPopupComponent,
    ContactSupportComponent,
    ContactSupportEmailModalComponent,
    UploadDocumentModalComponent,
    RequestStatementsComponent,
    ShareLinkComponent,
    FileIconComponent,
    DocumentFileSelectorButtonComponent,
    LoadTemplatesComponent,
    SavingPopupComponent,
    TaxFormTypeAndYearConfirmationComponent,
    DocumentPasswordEntryModalComponent,
  ],
  entryComponents: [
    LoadTemplatesComponent,
  ],
  providers: [CustomUploaderService],
})
export class PopupsModule {
}
