import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Subscription} from 'rxjs';

import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {ApiService} from '@services/api.service';
import {SharedDataService} from '@services/shared-data.service';
import {DocumentModelInterface} from "@models/review-queue-item";
import {
  PdfToolbarService
} from "@components/shared/document-viewer/pdf-toolbar/pdf-toolbar.service";

@Component({
  selector: 'app-review-full-screen',
  templateUrl: './full-screen.component.html',
  styleUrls: ['./full-screen.component.scss'],
  providers: [PdfToolbarService]
})
@AutoUnsubscribe('subsArr$')
export class FullScreenComponent implements OnInit {
  model: DocumentModelInterface;
  subsArr$: Subscription[] = [];
  type: string;
  reviewQueueItemId = '';
  isLoading = true;
  errorGettingPageImg = false;
  showTable = false;
  safePageImgUrls: SafeResourceUrl[];
  pageCount: number = 0;
  @ViewChildren("pageImgListItem") pageImgListItems: QueryList<ElementRef>;
  @ViewChild("docContainer") scrollableDocContainer: ElementRef;

  constructor(private _sanitizer: DomSanitizer,
              private _apiService: ApiService,
              private _route: ActivatedRoute,
              public _pdfToolbarService: PdfToolbarService,
              private _sharedDataService: SharedDataService,
              private _changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this._sharedDataService.shouldShowSidebar = false;
    this.subsArr$.push(this._route.params.subscribe((params => {
      this.reviewQueueItemId = params['uuid'];
      this.retrieveReviewQueueItem();
    })));
  }

  prepareDocumentImages() {
    if (!this.model?.pageImgUrls?.length) {
      this.errorGettingPageImg = true;
      return
    }
    this.safePageImgUrls = this.model.pageImgUrls.map(url => this._sanitizer.bypassSecurityTrustResourceUrl(url));
    this.pageCount = this.safePageImgUrls.length;
  }

  retrieveReviewQueueItem() {
    this._apiService.send('Post', `/api/review-queue-items/${this.reviewQueueItemId}/retrieve-for-review`).subscribe(data => {
      this.model = data.response.objects[0];
      this.isLoading = false;
      this.type = this.model.document.type;
      if (this.type === 'XLSX' || this.type === 'CSV') {
        this.showTable = true;
        this.pageCount = this.model.document?.pages?.length;
      } else {
        this.prepareDocumentImages();
      }

      this._changeDetector.detectChanges();
      this.initPaginator();
    }, err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  initPaginator() {
    this._pdfToolbarService.scrollableDocContainer = this.scrollableDocContainer;
    this._pdfToolbarService.docPageElements = this.pageImgListItems;
  }

  handleScroll() {
    this._pdfToolbarService.scrollSubject$.next();
  }


  closeWindow() {
    self.close();
  }
}
