import * as moment from 'moment';

import { SERVER_DATE_FORMAT, DATE_FORMAT } from './constants';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 *
 * @param {Object} obj
 * @returns {any}
 */
export function deepCopy(obj: Object): any {
  return JSON.parse(JSON.stringify(obj));
}

/**
 *
 * @param {string[]} target
 * @param {Object} source
 * @returns {Object}
 */
export function getObjProp(target: string[], source: Object) {
  return target.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, source);
}

/**
 * transform MM/DD/YYYY -> server date
 * @param {string} value
 * @returns {string}
 */
export function serverDate(value: string): string {
  return moment(new Date(value)).format(SERVER_DATE_FORMAT);
}

/**
 * transform YYYY-MM-DD-> MM/DD/YYYY
 * @param {string} value
 * @returns {string}
 */
export function displayDate(value: string): string {
  return moment(new Date(value)).format(DATE_FORMAT);
}

/**
 * loops over array to change booleans property to false for each element
 * @param {any[]} arrayToModify
 * @param {string} propertyName
 */
export function setArrProp(source: any[] = [], propertyName: string, value: any) {
  source.forEach(item => item[propertyName] = value);
}


/**
 * clears array from subscriptions
 * @param {Array<Subscription>} arr
 */
export function unsubArr(arr: Array<Subscription>) {
  arr.forEach(item => item.unsubscribe());
}

/**
 * check environment with url
 * @param {string} source
 * @returns {boolean}
 */
export function isEnvironmentHost(source: string): boolean {
  const reg = new RegExp('^https?://' + location.hostname);
  return reg.test(source);
}

/**
 * helper to move the cursor to the end of required value label
 * @param {Element} element
 */
export function selectAndMoveCursor(element: Element) {
  // Create a range (a range is a like the selection but invisible)
  const range = document.createRange();
  // Select the entire contents of the element with the range
  range.selectNodeContents(element);
  // collapse the range to the end point. false means collapse to end rather than the start
  // range.collapse(false);
  // get the selection object (allows you to change selection)
  const selection = window.getSelection();
  // remove any selections already made
  selection.removeAllRanges();
  // make the range you have just created the visible selection}
  selection.addRange(range);
}

/**
 * helper to merge Form group properties with the object
 * @param form
 * @param objToMerge
 * @returns {any}
 */
export function mergeObject(form: any, objToMerge: any): any {
  Object.keys(form).map((key) => {
    objToMerge[key] = form[key];
  });
  return objToMerge;
}

/**
 * helper to swap elements inside array
 * @param {any[]} arr
 * @param {number} firstIndex
 * @param {number} secondIndex
 * @returns {any[]}
 */
export function changeElementPlaceInArray(arr: any[], firstIndex: number, secondIndex: number): any[] {
  if (arr.length === 1) {
    return arr;
  }
  const firstElement = arr[firstIndex];
  arr.splice(firstIndex, 1);
  arr.splice(secondIndex, 0, firstElement);
  return arr;
}

/**
 * truncated strings by given length
 * @param {string} str
 * @param {number} maxCharLength
 * @returns {string}
 */
export function truncateString(str: string, maxCharLength: number): string {
  if (str.length < maxCharLength) {
    return str;
  } else {
    return str.substring(0, maxCharLength) + '...';
  }
}

/**
 * safely sets obj prop
 * @param objectToSet
 * @param {string} propertyName
 * @param {boolean} boolValue
 */
export function setProp(target: any, property: string, value: any) {
  if (target) target[property] = value;
}

/**
 * shallow compare objects
 * @param {Object} a
 * @param {Object} b
 * @returns {boolean}
 */

/*export function shallowCompare(a: Object, b: Object) {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

/!**
 * deep compare multiple object use with .call
 * @returns {boolean}
 *!/
export function deepCompare () {
  let i, l, leftChain, rightChain;

  function compare2Objects (x, y) {
    let p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if ((typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      }
      else if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof (x[p])) {
        case 'object':
        case 'function':

          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects (x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true;
  }

  for (i = 1, l = arguments.length; i < l; i++) {

    leftChain = [];
    rightChain = [];

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
}*/

/**
 * fast check equality of objects
 * @param {Object} a
 * @param {Object} b
 * @returns {boolean}
 */
export function fastCompare(a: Object, b: Object) {
  return JSON.stringify(a) === JSON.stringify(b);
}

/**
 *
 * @param {number} x
 * @param {number} min
 * @param {number} max
 * @returns {boolean}
 */
export function isInRange(x: number, min: number, max: number): boolean {
  return x >= min && x <= max;
}

/**
 * pushes to observable array
 * @param {Subject<any[]> | BehaviorSubject<any[]>} target$
 * @param {any[]} data
 */
export function pushToArr$(target$: Subject<any[]> | BehaviorSubject<any[]>, data: any[]): void {
  target$.pipe(
    take(1),
  ).subscribe(current => {
    current.push(...data);
    target$.next(current);
  });
}
