<div class="list-covenants-container">
    <div class="row bottom-margin">
        <div class="offset-1 col-3">
            <h3>Non Financial Covenants</h3>
        </div>
        <div class="col-8">
            <button type="button" class="btn btn-primary btn-rounded" (click)="addNonFinancialCovenant()"><i class="fas fa-plus"></i> Create New Non Financial Covenant</button>
        </div>
    </div>
    <div class="row">
        <div class="col-10 offset-1">
            <table class="table table-bordered header-title">
                <tr>
                    <th>Loan</th>
                    <th>Covenant</th>
                    <th>Status</th>
                    <th>Missing Items</th>
                    <th>Next Due</th>
                    <th>Actions</th>
                </tr>
                <tr>
                    <td>Equipment</td>
                    <td>Quarterly Interims</td>
                    <td><span class="dot green"></span>Sample pass</td>
                    <td></td>
                    <td>
                        <button class="btn btn-pill btn-default" type="button">
                            <span _ngcontent-c19=""
                            class="size">07/15/2020</span>
                        </button>
                    </td>
                    <td>
                        <i class="fas fa-pencil-alt"></i>
                        <i class="fas fa-bell"></i>
                        <i class="fas fa-trash"></i>
                    </td>
                </tr>
                <tr>
                    <td>Equipment</td>
                    <td>Quarterly Interims</td>
                    <td><span class="dot red"></span>Sample Fail</td>
                    <td></td>
                    <td>
                        <button class="btn btn-pill btn-default" type="button">
                            <span _ngcontent-c19=""
                            class="size">07/15/2020</span>
                        </button>
                    </td>
                    <td>
                        <i class="fas fa-pencil-alt"></i>
                        <i class="fas fa-bell"></i>
                        <i class="fas fa-trash"></i>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="row bottom-margin">
        <div class="offset-1 col-3">
            <h3>Financial Covenants</h3>
        </div>
        <div class="col-8">
            <button type="button" class="btn btn-primary btn-rounded" (click)="addFinancialCovenant()"><i class="fas fa-plus"></i> Create New Financial Covenant</button>
        </div>
    </div>
    <div class="row">
        <div class="col-10 offset-1">
            <table class="table table-bordered header-title">
                <tr>
                    <th>Loan</th>
                    <th>Covenant</th>
                    <th>12/31/2017</th>
                    <th>03/31/2018</th>
                    <th>06/30/2018</th>
                    <th>09/30/2018</th>
                    <th>Actions</th>
                </tr>
                <tr>
                    <td>That</td>
                    <td>There</td>
                    <td>That</td>
                    <td>There</td>
                    <td>That</td>
                    <td>There</td>
                    <td>
                        <i class="fas fa-pencil-alt"></i>
                        <i class="fas fa-bell"></i>
                        <i class="fas fa-trash"></i>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
