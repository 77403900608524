import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as uuid from 'uuid/v4';

export interface MultiSelectItem {
  id: string;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  action: () => any;
  checked: boolean,
  hide?: boolean,
}

@Component({
  selector: 'app-popover-menu-multi-select',
  templateUrl: './popover-menu-multi-select.component.html',
  styleUrls: ['./popover-menu-multi-select.component.scss']
})
export class PopoverMenuMultiSelectComponent implements OnInit {
  @Input() multiSelectOptions: MultiSelectItem[];
  @Input() buttonText: string;
  @Output() selectOrDeselectAll = new EventEmitter<any>();

  checkboxUuid: uuid;

  ngOnInit(): void {
    this.checkboxUuid = uuid();
  }

  triggerSelectOrDeselectAll() {
    this.selectOrDeselectAll.emit();
  }

  isSelectDeselectChecked() {
    return this.multiSelectOptions.some(option => option.checked === true);
  }

  noOptionsToDisplay() {
    return !this.multiSelectOptions.length || this.multiSelectOptions.every(option => option.hide === true);
  }

  toggleChecked(option) {
    option.checked = !option.checked
  }
}
