import { Document } from './document';
import { Deserializable } from './deserializable';

export class PageImage implements Deserializable {
  signedUrl: string

  deserialize(data): this {
    Object.assign(this, data);
    return this;
  }
}

export class PageImages implements Deserializable {
  thumbnail: PageImage;
  normalRes: PageImage;
  pageIndex: number;

  deserialize(data): this {
    Object.assign(this, data);
    this.thumbnail = new PageImage().deserialize(this.thumbnail);
    this.normalRes = new PageImage().deserialize(this.normalRes);
    return this;
  }

}

export class DocumentFile implements Deserializable {
  approved: boolean;
  changedBy?: number;
  changedDate?: string;
  className?: string;
  createdBy?: number;
  createdDate: string;
  company: number;
  companyName?: string;
  documents?: Array<Document>;
  id?: number;
  pkid?: number;
  name: string;
  originalDocumentName: string;
  priority: number;
  status: string;
  step?: string;
  type: string;
  period: string;
  statementDate: string;
  reportedDate: string;
  roundedToThousands: boolean;
  selected: boolean;
  signedDownloadLink: string;
  pageImages: Array<PageImages>;
  currency: string;
  fileType: string;
  uuid: string;
  reviewQueueItemStatus?: string;
  initialTemplateSelectionHasBeenMade?: boolean;
  reviewQueueItemExceptionMessage?: string;
  associatedStatements?: number[];
  pageImageUrls: string[];

  deserialize(data): this {
    Object.assign(this, data);

    if (data.hasOwnProperty('pageImages')) {
      this.pageImages = this.pageImages.map(pi => new PageImages().deserialize(pi));
      this.pageImageUrls = this.pageImages.map(pi_object => pi_object.normalRes.signedUrl);
    }
    return this;
  }
}
