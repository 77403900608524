import { Injectable } from '@angular/core';
import { Portfolio } from '../models/portfolio';
import CriteriaBuilder from '../utils/query/criteria';
import { ApiService } from './api.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';

@AutoUnsubscribe('subsArr$')
@Injectable()
export class PortfolioService {
  portfolios: any[] = [];
  subsArr$: Subscription[] = [];

  constructor(
    private _apiService: ApiService,
  ) { }

  getAllPortfolios(): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.setSort('name.asc');
    return this._apiService.send('Post', '/api/portfolios/all', query.generate()).pipe(map(data => data.response.objects));
  }

  listPortfolios(limit?: number, page?: number): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.setSort('name.asc');
    if (limit) {
      query.setLimit(limit);
    }
    if (page) {
      query.setPage(page);
    }
    return this._apiService.send('Post', '/api/portfolios/all', query.generate());
  }


  deletePortfolio(portfolio): Observable<any> {
    return this._apiService.send('Delete', `/api/portfolios/${portfolio.id}`);
  }


  cssColorNumber(portfolio: Portfolio): number {
    let matchedIndex = -1; // -1 means no match
    if (!portfolio) { return matchedIndex; }
    const portfolioColorResetNumber = 9; // after 8, reset back to 0
    this.portfolios.forEach( (element, index) => {
      if (portfolio.id === element.id) {
        matchedIndex = index;
      }
    });

    if (matchedIndex === -1) {
      return matchedIndex;
    } else {
      return matchedIndex % portfolioColorResetNumber + 1;
    }
  }
}
