import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AnalyticsItem } from '../../../../../../models/analytics-item';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-gauge-tile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './gauge-tile.component.html',
  styleUrls: ['./gauge-tile.component.scss']
})
export class GaugeTileComponent implements OnInit {
  @Input() analyticsItemValues: Array<AnalyticsItem> = [];
  @Input() title = '';
  @Input() statementDate = '';
  @Input() periodType = '';
  showTile = false;

  constructor(public analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.showTile = this.analyticsService.hasMostRecentPeriodItem(this.analyticsItemValues);
  }
}
