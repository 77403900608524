import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Company } from '../../../models/company';
import { Loan } from '../../../models/loan';
import { LoanService } from '../../../services/loan.service';
import { AlertService } from '../../../services/alert.service';
import * as moment from 'moment';
import { DATE_FORMAT, COVENANT_FREQUENCY_OPTIONS } from '../../../utils/constants';

@Component({
  selector: 'app-financial-covenant-form',
  templateUrl: './financial-covenant-form.component.html',
  styleUrls: ['./financial-covenant-form.component.css']
})
export class FinancialCovenantFormComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company = null;
  @Input() formType = '';

  // @ts-ignore
  loans: Array<Loan>  = [ { id: 0, name: 'First Loan'} ];

  reportingIntervals = ['Annual', 'Quarterly', 'Monthly', 'YTD', 'TTM'];

  financialCovenantForm: FormGroup;
  submitted = false;
  todaysDate = '';
  isLoaded = false;

  covenantRuleOptions = [
    { label: '>', value: 'greater than' },
    { label: '>=', value: 'greater or equal than' },
    { label: '<', value: 'greater than' },
    { label: '<=', value: 'greater than or equal to' },
    { label: '=', value: 'equal to' },
  ];

  frequencyOptions = COVENANT_FREQUENCY_OPTIONS;

  // @ts-ignore
  rules: FormArray = [];

  constructor(
    private formBuilder: FormBuilder,
    private _loanService: LoanService,
    private _alertService: AlertService,
    private _router: Router,
  ) {
    this.setForm();
   }

  ngOnInit(): void {
    this.addCheckboxes();
    this._setInitialValues();

    this.isLoaded = true;

    if (!this.company) {
      // error 404 redirect here
      return;
    }
    this._loanService.getLoans(this.company.id).subscribe( (data) => {
      this.loans = data;
    });
  }

  setForm(): void {
    this.financialCovenantForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      reportingIntervals: new FormArray([]),
      loan: ['', [Validators.required]],
      formula: ['', []],
      description: ['', []],
      rules: this.formBuilder.array([ this.createRule() ]),
      frequency: ['', []],
    });


  }

  addRule(): void {
    this.rules = this.financialCovenantForm.get('rules') as FormArray;
    this.rules.push(this.createRule());
  }

  createRule(): FormGroup {
    return this.formBuilder.group({
      operation: ['', []],
      threshold: ['', []],
      timeline: ['', []],
    });
  }

  save(financialCovenantForm: any) {
    this.submitted = true;


    if (financialCovenantForm.name) {
      financialCovenantForm.name = financialCovenantForm.name.trim();
    }

    const covenantFormWithSelectedReportingIntervals = Object.assign({}, financialCovenantForm, {
      reportingIntervals: financialCovenantForm.reportingIntervals.map((selected, i) => {
        if (selected) {
          return this.reportingIntervals[i];
        } else {
          return false;
        }
      }).filter ( value => value !== false)
    });

    financialCovenantForm = covenantFormWithSelectedReportingIntervals;

    if (!this.isFormInvalid()) {
      this._loanService.createFinancialCovenant(financialCovenantForm).subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, true);
        }, error => {
          this._alertService.error(error.message);
          this.submitted = false;
        }
      );
    } else {
      this.submitted = false;
    }
  }

  isFormInvalid(): boolean {
    return false;
  }

  getRulesControls(financialCovenantForm) {
    return financialCovenantForm.get('rules').controls;
  }

  private addCheckboxes() {
    this.reportingIntervals.forEach((o, i) => {
      const control = new FormControl(i === 0); // if first item set to true, else false
      (this.financialCovenantForm.controls.reportingIntervals as FormArray).push(control);
    });
  }

  private _setInitialValues(): void {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '/' + today.getDate();
    this.todaysDate = moment(date).format(DATE_FORMAT);
  }

}
