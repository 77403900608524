<div class="modal__header">
  <i class="fa fa-square-poll-vertical header-icon"></i>
  <span>CREATE ANALYSIS</span>
</div>

<form
  [formGroup]="analysisForm"
  (submit)="save(analysisForm.value)"
>
  <ng-container *ngIf="availablePeriods.length > 0; else noAvailablePeriods">
    <div class="modal__content" *ngIf="templates.length > 0; else noTemplates">
      <div class="mb-3">
        <label class="control-label" for="name">Analysis Name</label>
        <input type="text" class="form-control" [ngClass]="{'danger-border': submitted && isFormInvalid() && analysisForm.get('name').hasError('required')}" formControlName="name" id="name" placeholder="Analysis Name">
        <span class="help-block"
            *ngIf="submitted && isFormInvalid() && analysisForm.get('name').hasError('required')">
            An analysis name is required
        </span>
      </div>
      <div class="mb-3">
        <label class="control-label" for="template">Select Template</label>
        <select class="form-select"
        (change)="selectTemplate($event.target.value)">
          <option
            *ngFor="let template of templates; let i = index"
            [selected]="i == 0"
            [value]="template.id"
          >
            {{template.name}}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label class="control-label" for="periods">Select Periods</label>
        <div class="period-selection-container" [ngClass]="{'danger-border': submitted && isFormInvalid() && !arePeriodsValid()}">
          <div class="period-selection-option">
            <label class="period-label">
              <input type="checkbox" (click)="selectOrDeselectAllPeriods()" [checked]="isAnyPeriodChecked()"/>
              <span class="period-label-text select-deselect-all-checkbox-text">Select / Deselect All</span>
            </label>
          </div>
          <div *ngFor="let order of availablePeriods; let i = index" class="period-selection-option">
            <label class="period-label" formArrayName="periods">
              <input type="checkbox" [formControlName]="i" [checked]="analysisForm.value.periods[i]"/>
              <span class="period-label-text">{{availablePeriods[i].label}} {{availablePeriods[i].interval}} {{availablePeriods[i].preparationType}} ({{availablePeriods[i].scenario}}{{(availablePeriods[i].scenario === 'PROJECTION' && availablePeriods[i].projectionName !== '') ? (' - ' + availablePeriods[i].projectionName) : ''}})</span>
            </label>
          </div>
        </div>
        <span class="help-block"
          *ngIf="submitted && isFormInvalid() && !arePeriodsValid()">
          At least 1 period is required
        </span>
      </div>
    </div>
  </ng-container>
  <ng-template #noTemplates>
    <div class="modal__content">
      <p><strong>Cannot create an analysis. No templates exist for debt service coverage ratio analysis.</strong></p>
    </div>
  </ng-template>
  <ng-template #noAvailablePeriods>
    <div class="modal__content">
      <p><strong>Cannot create an analysis. There are no spread financials available to analyze.</strong></p>
    </div>
  </ng-template>
  <div class="modal__footer">
    <div class="footer-action-buttons">
      <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="loading || availablePeriods.length === 0 || templates.length === 0">
        <span class="create-analysis-btn-label">Create Analysis</span>
        <div *ngIf="loading" class="loader button-loader build-statement-btn-icon"></div>
      </button>
    </div>
  </div>
</form>

