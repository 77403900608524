<div class="ngxmdp">
    <div class="selector" #selectorEl [appNgxfocus]="0"
         [ngStyle]="{'width': opts.selectorWidth, 'height' : opts.selectorHeight, 'top': selectorPos.top, 'left': selectorPos.left, 'right': selectorPos.right}"
         (keyup)="onCloseSelector($event)" tabindex="0">
        <div class="header">
            <div class="switcher">
                <div class="headerbtncell">
                    <button type="button" [attr.aria-label]="opts.ariaLabelPrevYear"
                            class="headerbtn" (click)="onPrevYear()"
                            [disabled]="prevYearDisabled"
                            [ngClass]="{'headerbtnenabled': !prevYearDisabled, 'headerbtndisabled': prevYearDisabled}">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="headeryeartxt">
                    <button class="headerlabelbtn"
                            [ngClass]="{'yearlabel': opts.yearSelector, 'headerlabelbtnnotedit': !opts.yearSelector}"
                            type="button" (click)="opts.yearSelector && onSelectYearClicked($event)"
                            tabindex="{{opts.yearSelector?'0':'-1'}}">{{visibleMonth.year}}
                    </button>
                </div>
                <div class="headerbtncell">
                    <button type="button" [attr.aria-label]="opts.ariaLabelNextYear"
                            class="headerbtn" (click)="onNextYear()"
                            [disabled]="nextYearDisabled"
                            [ngClass]="{'headerbtnenabled': !nextYearDisabled, 'headerbtndisabled': nextYearDisabled}">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="switcher">
                <div class="headerbtncell">
                    <button type="button" [attr.aria-label]="opts.ariaLabelPrevMonth"
                            class="headerbtn" (click)="onPrevMonth()"
                            [disabled]="prevMonthDisabled"
                            [ngClass]="{'headerbtnenabled': !prevMonthDisabled, 'headerbtndisabled': prevMonthDisabled}">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="headermonthtxt">
                    <button class="headerlabelbtn"
                            [ngClass]="{'monthlabel': opts.monthSelector, 'headerlabelbtnnotedit': !opts.monthSelector}"
                            type="button" (click)="opts.monthSelector&&onSelectMonthClicked($event)"
                            tabindex="{{opts.monthSelector?'0':'-1'}}">{{visibleMonth.monthTxt}}
                    </button>
                </div>
                <div class="headerbtncell">
                    <button type="button" [attr.aria-label]="opts.ariaLabelNextMonth"
                            class="headerbtn" (click)="onNextMonth()"
                            [disabled]="nextMonthDisabled"
                            [ngClass]="{'headerbtnenabled': !nextMonthDisabled, 'headerbtndisabled': nextMonthDisabled}">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
        <table class="caltable" *ngIf="!selectMonth && !selectYear">
            <tbody>
            <tr *ngFor="let w of dates">
                <td class="daycell daycellweeknbr" *ngIf="opts.showWeekNumbers&&opts.firstDayOfWeek==='mo'">
                    {{w.weekNbr}}
                </td>
                <td class="daycell" *ngFor="let d of w.week"
                    [ngClass]="{'currmonth':d.cmo===currMonthId&&!d.disabled, 'selectedday':selectedDate.day===d.dateObj.day && selectedDate.month===d.dateObj.month && selectedDate.year===d.dateObj.year && d.cmo===currMonthId, 'disabled': d.disabled, 'tablesingleday':(!opts.allowSelectionOnlyInCurrentMonth||d.cmo===currMonthId&&opts.allowSelectionOnlyInCurrentMonth)&&!d.disabled}"
                    (click)="!d.disabled&&onCellClicked(d);$event.stopPropagation()"
                    (keydown)="onCellKeyDown($event, d)" tabindex="0">
                    <div *ngIf="d.markedDate.marked" class="markdate"
                         [ngStyle]="{'background-color': d.markedDate.color}"></div>
                    <div class="datevalue"
                         [ngClass]="{'prevmonth':d.cmo===prevMonthId,'currmonth':d.cmo===currMonthId,'nextmonth':d.cmo===nextMonthId}">
                        <span
                            [ngClass]="{'markcurrday':d.currDay&&opts.markCurrentDay}">
                            {{d.dateObj.day}}
                        </span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <table class="monthtable" *ngIf="selectMonth">
            <tbody>
            <tr *ngFor="let mr of months">
                <td class="monthcell tablesinglemonth" [ngClass]="{'selectedmonth': m.selected, 'disabled': m.disabled}"
                    *ngFor="let m of mr" (click)="!m.disabled&&onMonthCellClicked(m);$event.stopPropagation()"
                    (keydown)="onMonthCellKeyDown($event, m)" tabindex="0">
                    <div class="monthvalue" [ngClass]="{'markcurrmonth':m.currMonth&&opts.markCurrentMonth}">
                        {{m.name}}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <table class="yeartable" *ngIf="selectYear">
            <tbody>
            <tr>
                <td colspan="5" class="yearchangebtncell" (click)="$event.stopPropagation()">
                    <button type="button" class="yearchangebtn"
                            (click)="onPrevYears($event, years[0][0].year)" [disabled]="prevYearsDisabled"
                            [ngClass]="{'yearchangebtnenabled': !prevYearsDisabled, 'yearchangebtndisabled': prevYearsDisabled}">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                    </button>
                </td>
            </tr>
            <tr *ngFor="let yr of years">
                <td class="yearcell tablesingleyear" [ngClass]="{'selectedyear': y.selected, 'disabled': y.disabled}"
                    *ngFor="let y of yr" (click)="!y.disabled&&onYearCellClicked(y);$event.stopPropagation()"
                    (keydown)="onYearCellKeyDown($event, y)" tabindex="0">
                    <div class="yearvalue" [ngClass]="{'markcurryear':y.currYear&&opts.markCurrentYear}">{{y.year}}
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="5" class="yearchangebtncell" (click)="$event.stopPropagation()">
                    <button type="button" class="yearchangebtn"
                            (click)="onNextYears($event, years[0][0].year)" [disabled]="nextYearsDisabled"
                            [ngClass]="{'yearchangebtnenabled': !nextYearsDisabled, 'yearchangebtndisabled': nextYearsDisabled}">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
