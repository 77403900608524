import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Footnote } from '@models/footnote';
import { PeriodOverPeriodAnalysis } from '@models/period-over-period-analysis';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {

  @Input() insights: PeriodOverPeriodAnalysis = null;
  @Output() detailSelected = new EventEmitter<string>();
  @Output() detailClosed = new EventEmitter<any>();
  @Output() footnoteSelected = new EventEmitter<Footnote>();
  @Output() sourceBoxSelected = new EventEmitter<any>();

  selectedItem = -1

  constructor() { }

  ngOnInit() {
  }

  insightDetailSelected(itemRef: string, itemIdx: number) {
    if (itemIdx > 0) {
      if (this.selectedItem !== itemIdx) {
        this.selectedItem = itemIdx;
        this.detailSelected.emit(itemRef);
      } else {
        this.selectedItem = -1;
        this.detailClosed.emit();
      }

    }
  }

  lineItemFootnoteSelected(footnote: Footnote) {
    this.footnoteSelected.emit(footnote);
  }

  lineItemSourceBoxSelected(sourceBox: Footnote) {
    this.sourceBoxSelected.emit(sourceBox);
  }

}
