import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { CallTypeCategory} from '@utils/enums';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {

  constructor(
    public _apiService: ApiService,
  ) { }

  loadSpreadDataChangeLogForCompany(companyId: number) {
    const data = {
      callTypeCategory: CallTypeCategory.Spread,
      companyId: companyId,
    };
    return this._apiService.send('POST', `/api/call-log/all`, data).pipe(
      map(data => data.response.objects));
  }

  loadSingleEntityAnalysisChangeLogForCompany(companyId: number, analysisId: number) {
    const data = {
      callTypeCategory: CallTypeCategory.SingleEntityAnalysis,
      companyId: companyId,
      analysisId: analysisId,
    };
    return this._apiService.send('POST', `/api/call-log/all`, data).pipe(
      map(data => data.response.objects));
  }

  loadMultiEntityAnalysisChangeLogForCompany(companyId: number, analysisId: number) {
    const data = {
      callTypeCategory: CallTypeCategory.MultiEntityAnalysis,
      companyId: companyId,
      analysisId: analysisId,
    };
    return this._apiService.send('POST', `/api/call-log/all`, data).pipe(
      map(data => data.response.objects));
  }
}
