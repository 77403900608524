import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { SpreadingMap } from '../models/spreading-map';
import { DocumentFile } from '../models/document-file';
import { map } from 'rxjs/operators';
import { SpreadingMapTemplates } from '../models/standard-item-templates';
import { Row } from '../components/main/spreading/models/models';


@Injectable()
export class SpreadingMapsService {
  constructor(private apiService: ApiService) { }

  getSpreadingMaps(fileId: number): Observable<Array<SpreadingMap>> {
    return this.apiService.send('Post', '/api/spreadingmaps/all', {
      'filter': {
        'document_file_id_eq': fileId,
      }
    }).pipe(map(data => {
      return data.response.objects.map(o => new SpreadingMap().deserialize(o));
    }));
  }

  getSimilarMaps(documentFile: DocumentFile): Observable<Array<SpreadingMap>> {
    return this.apiService.send('Post', '/api/spreadingmaps/get_similar', {
      document_file_id: documentFile.id,
      company_id: documentFile.company,

    }).pipe(map(data => {
      return data.response.objects.map(o => new SpreadingMap().deserialize(o));
    }));
  }

  saveSpreadingMap(sm: SpreadingMap): Observable<SpreadingMap> {
    let observable: Observable<any>;
    if (sm.id > 0) {
      observable = this.apiService.send('Patch', `/api/spreadingmaps/${sm.id}`, sm);
    } else {
      observable = this.apiService.send('Post', '/api/spreadingmaps', sm);
    }

    return observable.pipe(map(data => {
      return new SpreadingMap().deserialize(data.response.objects[0]);
    }));
  }

  saveSpreadingMaps(documentFile: DocumentFile, maps: Array<SpreadingMap>, complete: boolean, forceIntegrationSync: boolean): Observable<Array<SpreadingMap>> {
    return this.apiService.send('Patch', `/api/spreadingmaps/bulk/${documentFile.id}`, {
        spreading_maps: maps,
        company_id: documentFile.company,
        complete: complete,
        forceIntegrationSync: forceIntegrationSync
      }).pipe(map(data => {
      return data.response.objects as Array<SpreadingMap>;
    }));
  }


  getSpreadingMapTemplates(): Observable<SpreadingMapTemplates> {
    return this.apiService.send('Get', `/api/spreadingmaps/templates`).pipe(
      map(data => {
        const tmpl = new SpreadingMapTemplates();
        tmpl.incomeStatementTmpl = data.response.objects[0].incomeStatementTemplate.children as Array<Row>;
        tmpl.balanceSheetTmpl = data.response.objects[0].balanceSheetTemplate.children as Array<Row>;
        return tmpl;
      })
    );
  }
}
