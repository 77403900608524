import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { ApiService } from './api.service';
import { Report } from '../models/report';
import { LineItemObject } from '../models/line-item';
import { map } from 'rxjs/operators';
import { SpreadingTemplateService } from './spreading-template.service';

export interface Item {
  id: number;
  value: string;
}

@AutoUnsubscribe('subsArr$')
@Injectable()
export class ReportingService {
  subsArr$: Subscription[] = [];

  constructor(
    private _apiService: ApiService,
    private _spreadingTemplateService: SpreadingTemplateService,
  ) {}

  flatten(item): any[] {
    let returnVal: any[] = [];
    returnVal.push(item);

    if (item.children) {
      returnVal = returnVal.concat(item.children.reduce((a, b) => a.concat(this.flatten(b)), []));
    }
    return returnVal;
  }

  correctLineItemId(itemId) {
    // some rollup taxonomy items (rollups) have ids of the form
    // "ruXXX" where XXX is an integer
    // we want to strip the leading "ru" and convert the remaining
    // string to an integer
    if (typeof(itemId) === 'string' && itemId.startsWith('ru')) {
      itemId = parseInt(itemId.slice(2), 10);
    }
    // if the input is not a string, we just return the integer id
    return itemId;
  }

  filterAndPrettifyItems(items: any[]): Item[] {
    const returnVal: Item[] = [];
    // filter out duplicate IDs and unnecessary items
    items.forEach(item => {
      const newItem = {
        id: this.correctLineItemId(item.standardLineItemId),
        value: item.label,
      };
      let push = true;
      for (let i = 0; i < returnVal.length; i++) {
        if (returnVal[i].id === newItem.id) {
          push = false;
          // we found a duplicate ID, and we want to use the label
          // associated with the rollup / calculation
          if (item.className === 'RollupItem' || item.className === 'CalculatedItem') {
            returnVal[i].value = newItem.value;
          }
          break;
        }
      }
      if (push) {
        returnVal.push(newItem);
      }
    })

    return returnVal;
  }

  getAvailableColumns(): Promise<Array<Item>> {
    return this._spreadingTemplateService.getFincuraTaxonomy().toPromise().then((data: any) => {
      return this.filterAndPrettifyItems(data.reduce((a, b) => a.concat(this.flatten(b)), []));
    })
  }

  runReport(report: Report): Promise<any> {
    return this._apiService.send('Post', '/api/reporting/generate', {
      'report_config': report,
    }).pipe(
      map(data => data.response.objects[0])
    ).toPromise();
  }
}
