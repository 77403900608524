<div (mouseleave)="contextMenuOpen=false">
  <div class='value-container'>
      <div *ngIf="footnotes.length > 0 || sourceBox !== null || prevSourceBox !== null">
        <div class="cell-menu-toggle" (click)="toggleContextMenu($event)">
            <i class="fas fa-caret-up pointer" *ngIf="contextMenuOpen"></i>
            <i class="fas fa-caret-down pointer" *ngIf="!contextMenuOpen"></i>
        </div>
        <ul class="dropdown-menu" *ngIf="contextMenuOpen">
            <li *ngIf="footnotes.length > 0" class="header-item"><span >Footnotes</span></li>
              <li *ngFor="let footnote of footnotes; let idx = index">
                  <span (click)="clickOpenFootnote($event, idx)">Show Footnote {{idx + 1}}</span>
              </li>
            <li *ngIf="sourceBox !== null || prevSourceBox !== null" class="header-item"><span >Source Document</span></li>
            <li *ngIf="sourceBox !== null"><span (click)="clickOpenSourceBox($event)">Show Source</span></li>
            <li *ngIf="prevSourceBox !== null"><span (click)="clickOpenPreviousSourceBox($event)">Show Source (Previous Period)</span></li>
        </ul>
      </div>
      <div
        [ngClass]="{
          'raw-line-item-label': rawItem,
          'line-item-label': !rawItem,
          'has-source-box-label': sourceBox !== null || prevSourceBox !== null
        }"
      (click)="clickOpenDetail($event)"
      >
        {{ label }}
      </div>
  </div>
  <div [ngClass]="{
    'line-item-value-positive': changeType === 'positive',
    'line-item-value-negative': changeType === 'negative',
    'line-item-value-neutral': changeType === 'neutral'
  }" (click)="clickOpenDetail($event)">
    {{ formattedValue }}
  </div>
  <div [ngClass]="{
    'change-positive': changeType === 'positive',
    'change-negative': changeType === 'negative',
    'change-neutral': changeType === 'neutral'
  }" (click)="clickOpenDetail($event)">
    <i *ngIf="changeType !== 'negative' && prevValue !== null && !(prevValue === 0 && value === 0)" class="fas fa-caret-up"></i>
    <i *ngIf="changeType === 'negative' && prevValue !== null" class="fas fa-caret-down"></i>
    {{ displayPeriodOverPeriodChange(value, prevValue) }}
  </div>
</div>
