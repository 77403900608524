import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';
import { SpreadingTemplateService } from '../../../../services/spreading-template.service';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import {DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES} from "@utils/constants";

@Component({
  selector: 'app-create-generic-equation',
  templateUrl: './create-generic-equation.component.html',
  styleUrls: ['./create-generic-equation.component.css']
})
@AutoUnsubscribe('subsArr$')
export class CreateGenericEquationComponent implements OnInit {
    @Input() popup: NgxPopupComponent; // MANDATORY
    @Input() isDASItem: boolean = false;

    subsArr$: Subscription[] = [];
    genericEquationForm: FormGroup;
    textFormat = 'NUMBER';
    submitted = false;
    submitAttempt = false;
    textFormatOptions = [
        {
          id: 'NUMBER',
          text: 'NUMBER'
        },
        {
          id: 'RATIO',
          text: 'RATIO'
        },
        {
          id: 'PERCENT',
          text: 'PERCENT'
        },
        {
          id: 'DAYS',
          text: 'DAYS'
        }
      ];

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private spreadingTemplateService: SpreadingTemplateService
  ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.genericEquationForm = this.formBuilder.group({
      label: ['', [Validators.required]],
      equation: ['', [Validators.required]],
      textFormat: ['NUMBER', [Validators.required]],
      equationIncludesDasRef: [false, [Validators.required]]
    });
  }

  /**
   *
   * @param {string} field
   * @returns {boolean}
   */
  isFieldInvalid(field: string): boolean {
    return (
      (this.genericEquationForm.get(field).invalid && this.genericEquationForm.get(field).dirty) ||
      (this.genericEquationForm.get(field).invalid && this.submitAttempt)
    );
  }

  textFormatChanged(event: any) {
      this.textFormat = event.value;
  }

    /**
   *
   * @param user
   * @param {boolean} isValid
   */
  save(genericEquation: any, isValid: boolean) {
    this.submitAttempt = true;
    if (!isValid || this.submitted) {
      return;
    }
    this.submitted = true;
    genericEquation.label = genericEquation.label.trim();
    genericEquation.equation = genericEquation.equation.trim();
    genericEquation.textFormat = this.textFormat;
    genericEquation.dasItemType = this.isDASItem ? DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.CUSTOM_CALCULATION : '';

    this.subsArr$.push(this.spreadingTemplateService.generateGenericEquation(genericEquation, this.isDASItem)
      .subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, data);
          this.alertService.success('Equation created successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }

}
