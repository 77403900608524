import { DataFrame } from './dataframe';
import { LineItemObject } from './line-item';
import { DataFrameFormula } from './dataframe-formula';
import { Deserializable } from './deserializable';
export class DataFrameGenerator implements Deserializable {
  id: number;
  company: number;
  uniqueKey: string;
  documentType: string;
  raw: boolean;
  formulas: Array<DataFrameFormula>

  constructor() {


  }

  /**
   * Makes it easy to create an instance of a DataFrameGenerator from incoming dictionary data
   * @param input
   */
  deserialize(input: any): this {
    Object.assign(this, input);

    if (this.formulas) {
      this.formulas = this.formulas.map(formula => new DataFrameFormula().deserialize(formula));
    }
    return this;
  }

  getExistingItemNames(): Array<string> {
    return this.formulas.map(formula => formula.lineItem.name);
  }
}
