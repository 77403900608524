import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DataFrameService } from '../../../../../../../../../services/dataframes.service';
import { ContextMenuDocument } from './context-menu';
import { Footnote } from '../../../../../../../../../models/footnote';
import { DataViewCell, DataViewRow } from '@models/dataview';
import { CellInformationService } from '@services/shared/cell-information/cell-information.service';
import { SourceComment } from '@components/main/spreading/models/source-comment';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
})
export class TableCellComponent implements OnInit, OnChanges {
  @Input() public set cell(cell: DataViewCell) {
    this._cell = cell;

    if (!!this._cell.calculatedValue?.sourceComments) {
      this.hasSourceComments = !!this._cell.calculatedValue?.sourceComments?.length;
    }
  }
  public get cell() {
    return this._cell;
  }
  @Input() sourceComments: Array<SourceComment>;
  @Input() idx = -1;
  @Input() value = null;
  @Input() isHighlighted = false;
  @Input() lineItemId: number;
  @Input() frameId = null;
  @Input() originalValue = null;
  @Input() isRoundedToThousands = false;
  @Input() formattedValue = null;
  @Input() isEditing = false;
  @Input() footnoteCoordinates = null;
  @Input() showSpreadingInContextMenu = true;
  @Input() statementDate: string;
  @Input() row: DataViewRow = null;
  @Input() isHeader = false;
  @Input() isBold = false;
  @Input() contextMenuDocumentItems: Array<ContextMenuDocument> = [];
  // Show subtext indicating a warning or other info
  @Input() subtext = '';
  // Defines whether double-clicking allows the user to edit the contents of this cell
  @Input() allowEdit = true;
  // Defines whether the top-right corner is flagged when there is a mismatch between original value and formatted value
  @Input() flagOnMismatch = true;
  @Input() isSourceStatement = false;
  @Input() isTemplated = false;
  @Input() viewOnlyMode: boolean;

  @Output() highlightCell = new EventEmitter<any>();
  @Output() openFootnotes = new EventEmitter<Footnote>();
  // Will need to change this when we have additional items in the context menu
  @Output() contextMenuItemSelected = new EventEmitter<ContextMenuDocument>();
  @Output() startedEditMode = new EventEmitter<boolean>();
  @Output() doneEditing = new EventEmitter<number>();
  // Emits when the interface would have changed so if there's any parent component that is fully controlling the render cycle it can know to re-render itself.
  @Output() rerenderEvent = new EventEmitter<boolean>();
  @Output() insightsSelected = new EventEmitter<any>();

  hasSourceComments = false;
  newValue = null;
  contextMenuOpen = false;
  isChanged = false;
  footnotes: Footnote[] = null;
  showFormattedValue;

  @ViewChild('editCellTextbox') editCellTextbox: ElementRef;

  private _cell: DataViewCell = null;

  constructor(
      public dataFrameService: DataFrameService,
      private cellInformationService: CellInformationService,
  ) { }

  ngOnInit() {
    this.newValue = this.value;
    this.setIsChanged();
    if (this._cell && this._cell.calculatedValue ) {
      if (this._cell.calculatedValue.footnotes && this._cell.calculatedValue.footnotes.length >= 1) {
        this.footnotes = this._cell.calculatedValue.footnotes;
      }
    }
    this.showFormattedValue = this.shouldShowFormattedValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('isHighlighted')) {
      // Close the context menu if we change cells
      this.contextMenuOpen = false;
    }

    if (changes.hasOwnProperty('isEditing') && this.isEditing) {
      if (this.isRoundedToThousands && this.newValue === this.value) {
        this.newValue = this.newValue / 1000; // get ngmodel editable newValue into expected rounded to thousands state
      }

      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.focusOnTextbox();
      }, 0);
    }

    if (changes.hasOwnProperty('value')) {
      this.newValue = this.value;
    }

    if (changes.hasOwnProperty('flagOnMismatch') || changes.hasOwnProperty('value') || changes.hasOwnProperty('originalValue')) {
      this.setIsChanged();
    }
  }

  stopPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  startHighlightMode(evt = null): void {
    if (evt) {
      this.stopPropagation(evt);
    }

    this.highlightCell.emit({ frameId: this.frameId, lineItemId: this.lineItemId });
    this.isEditing = false;
    this.contextMenuOpen = false;
  }

  startEditMode(evt = null): void {
    if (evt) {
      this.stopPropagation(evt);
    }
    if (!this.allowEdit) {
      return;
    }
    this.contextMenuOpen = false;
    this.startedEditMode.emit(true);
  }

  focusOnTextbox(): void {
    if (this.editCellTextbox) {
      this.editCellTextbox.nativeElement.focus();
    }
  }

  editComplete() {
    if (isNaN(this.newValue)) {
      alert('Change not saved. We only accept numerical values.');
      this.newValue = this.value;
      return;
    }
    this.doneEditing.emit(this.newValue);
  }

  setIsChanged() {
    this.isChanged = this.flagOnMismatch && parseFloat(this.newValue).toFixed(2) !== parseFloat(this.originalValue).toFixed(2);
  }

  adjustedValueToDisplay() {
    if (this.isRoundedToThousands) {
      return this.newValue / 1000;
    } else {
      return this.newValue;
    }
  }

  shouldShowFormattedValue(): boolean {
    return this.formattedValue && this.formattedValue !== '' && this.newValue === this.value;
  }

  toggleContextMenu(evt) {
    this.stopPropagation(evt);
    this.contextMenuOpen = !this.contextMenuOpen;
    this.rerenderEvent.emit(true);
  }

  insightsClicked(evt) {
    evt.preventDefault();
    this.contextMenuOpen = false;
    this.insightsSelected.emit(evt);
  }

  contextMenuDocumentClicked(doc: ContextMenuDocument, evt, action) {
    doc.action = action;
    evt.preventDefault();
    this.contextMenuOpen = false;
    this.contextMenuItemSelected.emit(doc);
  }

  revertToOriginal(evt = null) {
    if (evt) {
      this.stopPropagation(evt);
    }

    this.newValue = this.originalValue;
    this.editComplete();
  }

  enterFootnotesMode(idx): void {
    this.openFootnotes.emit(this.footnotes[idx]);
  }

  openComments(event): void {
    const cellInformationTitle = this.row.lineItem.name;
    const cellInformationDate = this.statementDate;
    const cellInformation = this._cell.calculatedValue;
    this.cellInformationService.showCellInformation(
      cellInformation,
      cellInformationTitle,
      cellInformationDate
    );
  }

  cellHasComments(cell: DataViewCell): boolean {
    return !!cell.calculatedValue?.sourceComments?.length;
  }

  isConsideredHeader(row: DataViewRow): boolean {
    // if bolded or a "header", we treat this as a header for formatting purposes
    return row.rowFormat.bold || row.rowFormat.header;
  }
}
