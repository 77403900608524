import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-with-line-chart',
  templateUrl: './bar-with-line-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./bar-with-line-chart.component.scss']
})
export class BarWithLineChartComponent implements OnInit, OnChanges {
  @Input() data = [];
  @Input() lineStyle: 'solid'|'dash' = 'solid';
  @Input() lineType: 'straight'|'curved' = 'straight';
  @Input() unitsType: 'days'|'currency' = 'currency';
  @Input() currencySymbol = '';

  lineClass = 'line';
  lineCurve = d3.curveLinear;
  minimumSpaceForNumberOfGraphs = 6;

  @ViewChild('legend', { static: true }) legendContainer: ElementRef;
  @ViewChild('chart', { static: true })
  private chartContainer: ElementRef;

  constructor() { }

  ngOnChanges(): void {
    if (!this.data) { return; }
    this.setupValues();
    this.createChart();
  }

  ngOnInit(): void {
    this.setupValues();
    this.createChart();
  }

  setupValues(): void {
    if (this.lineStyle === 'solid') {
      this.lineClass = 'line';
    } else if (this.lineStyle === 'dash') {
      this.lineClass = 'dotted-line';
    }

    if (this.lineType === 'straight') {
      this.lineCurve = d3.curveLinear;
    } else if (this.lineType === 'curved') {
      this.lineCurve = d3.curveMonotoneX;
    }
  }

  lowestLineChartValue(): number {
    const lineChartValues = this.data.map( item => item.lineChartValue)
    return Math.min(...lineChartValues);
  }

  lowestBarChartValue(): number {
    const barChartValues = this.data.map( item => item.barChartValue )
    return Math.min(...barChartValues);
  }

  areAllBarChartValuesPositive(): boolean {
    const barChartValues = this.data.map( item => item.barChartValue )
    const minBarChartValue = Math.min(...barChartValues);
    return minBarChartValue >= 0;
  }

  areAlBarChartValuesNegative(): boolean {
    const values = this.data.map( item => {
      return item.barChartValue
    })
    const maxValue = Math.max(...values);
    return maxValue < 0;
  }

  areAnyValuesNegative(): boolean {
    return this.lowestBarChartValue() < 0 || this.lowestLineChartValue() < 0;
  }

  originalLength(): number {
    return this.data.length;
  }

  dataToGraph(): Array<any> {
    const graphLengthToForce = this.minimumSpaceForNumberOfGraphs;
    if (this.data === null || this.data === undefined ) {
      return [];
    }

    if (this.data.length === 0) {
      return [];
    }

    const data = [...this.data];

    while (data.length <= graphLengthToForce) {
      data.push({label: data.length.toString(), barChartValue: (data.length * .01), lineChartValue: (data.length * .01)});
    }

    return data;
  }

  calculateXTickValues(dataset): Array<string> {
    let xTickValues = [];
    if (this.originalLength() > this.minimumSpaceForNumberOfGraphs) {
      xTickValues = [
        dataset[0].label,
        dataset[dataset.length - 1].label
      ]
    } else if (dataset.length > 1) {
      xTickValues = this.data.map( (item) => item.label);
    } else if (dataset.length === 1) {
      xTickValues = [dataset[0].label];
    }

    return xTickValues;
  }

  private createChart(): void {

    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll('svg').remove();

    const dataset = this.dataToGraph();

    const leftAlignAllowSpaceForAxisLabels = 125;
    const rightAlignAllowSpaceForAxisLabels = 60;
    const standardMargin = 30;
    const margin = {
      top: standardMargin,
      right: rightAlignAllowSpaceForAxisLabels,
      bottom: standardMargin,
      left: leftAlignAllowSpaceForAxisLabels
    };

    const highestAreaTooltipCanStart = 280; // for negative bars, 280 is equivalent to the start of hte top of the tooltip box from top down coordinates

    const tooltipWidth = 200;
    const tooltipHeight = 100;

    const width = 860;
    const height = 350;

    const xScale = d3.scaleBand()
                  .rangeRound([0, width])
                  .padding(0.1)
                  .domain(dataset.map(function(d) {
                    return d.label;
                  }));

    const yScaleDomainMin = () => {
      if (this.areAllBarChartValuesPositive()) {
        return 0
      } else {
        return d3.min(dataset, (d) => {
          return d.barChartValue;
        })
      }
    }

    const yScaleDomainMax = () => {
      if (this.areAlBarChartValuesNegative()) {
        return 0
      } else {
        return d3.max(dataset, (d) => d.barChartValue )
      }
    }

    const yScale = d3.scaleLinear()
                  .rangeRound([height, 0])
                  .domain([
                    yScaleDomainMin(),
                    yScaleDomainMax(),
                  ]);

    const yScaleLineGraph = d3.scaleLinear()
      .rangeRound([height, 0])
      .domain([
        d3.min(dataset, (function (d) {
          if (d.lineChartValue > 0) {
            return 0;
          } else {
            return d.lineChartValue;
          }
        })),
        1
      ]);


    const widthWithMargins = width + margin.left + margin.right;
    const heightWithMargins = height + margin.top + margin.bottom;

    const svg = d3.select(element)
              .append('svg')
              .attr('width', '100%')
              .attr('preserveAspectRatio', 'xMinYMin meet')
              .attr('viewBox', `0 0 ${widthWithMargins} ${heightWithMargins}`)
              .classed('svg-content-responsive', true);

    const g = svg.append('g')
              .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const xTickValues = this.calculateXTickValues(dataset);

    // axis-x
    g.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(xScale)
          .tickValues(xTickValues)
        )


    const formatTick = (d) => {
      if (d === null) {
        return '';
      }
      const thousandsCommaSeparated = d.toLocaleString();
      return `${this.currencySymbol} ${thousandsCommaSeparated}`;
    }

      // axis-y
    g.append('g')
        .attr('class', 'axis axis--y')
        .classed('axisTeal', true)
        .call(d3.axisLeft(yScale)
          .ticks(5)
          .tickFormat(formatTick)
        );

    // right side line graph axis-y
    g.append('g')
        .attr('class', 'axis axis--y axisRoyalBlue')
        .attr('transform', 'translate( ' + width + ', 0 )')
        .classed('axisRoyalBlue', true)
        .call(d3.axisRight(yScaleLineGraph)
        .ticks(5, '%')
      );

    const isTooltipHovered = () => {
      const allHovered = d3.select(element).selectAll('.tooltip-related:hover');
      const all = d3.select(element).selectAll('.tooltip-related');

      return allHovered.size() > 0;
    }

    const bar = g.selectAll('rect')
      .data(dataset)
      .enter().append('g');

      const graphOnMouseOver = (_, i) => {
        d3.select(element).select('.tooltip-' + i).attr('display', 'block');
        d3.select(element).select('.tooltip-text-' + i).attr('display', 'block');

        d3.select(element).selectAll('.bar').classed('bar-in-the-background', (backgroundData, backgroundIndex, nodes) => {
          return i !== backgroundIndex; // turn on class for every .bar except hovered bar
        });
      }

      const graphOnMouseOut = (_, i) => {
        if (isTooltipHovered()) { // don't hide the graph if hoving over tooltip elements
          return;
        }

        d3.select(element).select('.tooltip-' + i).attr('display', 'none');
        d3.select(element).select('.tooltip-text-' + i).attr('display', 'none');

        d3.select(element).selectAll('.bar').classed('bar-in-the-background', (backgroundData, backgroundIndex, nodes) => {
          return false; // make every .bar not have this bar-in-the-background class
        });
    }

    const tooltipOnMouseOut = (_, i) => {
      if (isTooltipHovered()) { // don't hide elements if hovering over tooltip elements themselves
        return;
      }

      d3.select(element).select('.tooltip-' + i).attr('display', 'none');
      d3.select(element).select('.tooltip-text-' + i).attr('display', 'none');

      d3.select(element).selectAll('.bar').classed('bar-in-the-background', (backgroundData, backgroundIndex, nodes) => {
        return false; // make every .bar not have this bar-in-the-background class
      });
  }

    bar.append('rect')
      .attr('x', function(d) { return xScale(d.label); })
      .attr('y', function(d) {
        if (d.barChartValue < 0) {
          return yScale(0);
        }
        return yScale(d.barChartValue);
      })
      .attr('width', xScale.bandwidth())
      .attr('height', function(d) {
        if (d.barChartValue > 0) {
          d3.min(dataset, ( (d) => d.barChartValue ))
          return yScale(0) - yScale(d.barChartValue);
        } else {
          return yScale(d.barChartValue) - yScale(0);
        }
      })
      .classed('bar', true)
      .on('mouseover', graphOnMouseOver)
      .on('mouseout', graphOnMouseOut);

    const line = d3.line()
        .x(function(d, i) {
          // @ts-ignore
          return xScale(d[0]) + xScale.bandwidth() / 2; })
        .y(function(d) { return yScaleLineGraph(d[1]); })
        .curve(this.lineCurve);

    const datasetArray = dataset.filter( (item, i) => {
      if (this.originalLength() < i + 1) {
        return false;
      } else {
        return true;
      }
    }).map( (item, i) => {
      return[item.label, item.lineChartValue, item.barChartValue]
    });


    bar.append('path')
      .attr('class', this.lineClass)
      // @ts-ignore
      .attr('d', line(datasetArray));

    bar.append('circle') // Uses the enter().append() method
        .attr('class', 'dot')
        .attr('cx', (d, i) => {
          if (this.originalLength() < i + 1) return;
          return xScale(d.label) + xScale.bandwidth() / 2;
        })
        .attr('cy', (d, i) => {
          if (this.originalLength() < i + 1) return;
          return yScaleLineGraph(d.lineChartValue);
        })
        .attr('r', 5);

    const tooltipGroup = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .selectAll('rect')
      .data(dataset)
      .enter()
      .append('g');

    const tooltipXAttribute = (d, i) => {
      const endingRightSideOfTooltip = xScale(d.label) + tooltipWidth;
      if (endingRightSideOfTooltip > width) { // if tooltip is going off the svg
        return width - tooltipWidth; // make tip start where it will not end off the svg;
      } else {
        return xScale(d.label);
      }
    }

    const tooltipYAttribute = (d, i) => {
      if (d.barChartValue > 0) {
        return 0; // if positive, display at the top.
      } else {
        if (yScale(d.barChartValue) > highestAreaTooltipCanStart) {
          return highestAreaTooltipCanStart; // if very negative, don't go off screen.
        }
        return yScale(d.barChartValue); // if negative, display at bottom.
      }
    }

    tooltipGroup.append('rect')
      .attr('display', 'none')
      .attr('class', (_, i) => 'tooltip-' + i )
      .classed('svg-tooltip', true)
      .classed('tooltip-related', true)
      .attr('x', tooltipXAttribute)
      .attr('y', tooltipYAttribute)
      .attr('width', tooltipWidth)
      .attr('height', tooltipHeight)
      .on('mouseout', tooltipOnMouseOut);

    const tooltipElement = tooltipGroup.append('text')
      .attr('display', 'none')
      .attr('class', (_, i) => 'tooltip-text-' + i )
      .classed('svg-tooltip-text', true)
      .classed('tooltip-related', true)
      .attr('x', tooltipXAttribute)
      .attr('y', (d, i) => tooltipYAttribute(d, i) + 20)
      .attr('width', tooltipWidth)
      .attr('height', tooltipHeight)
      .on('mouseout', tooltipOnMouseOut);

    tooltipElement.append('tspan')
      .attr('x', tooltipXAttribute)
      .attr('y', (d, i) => tooltipYAttribute(d, i) + 30)
      .attr('dx', 10)
      .classed('svg-tspan', true)
      .classed('tooltip-related', true)
      .text( (d) => {
        let roundedDollarAmount = '';
        if (d.barChartValue === null) {
          roundedDollarAmount = '';
        } else {
          roundedDollarAmount = `${this.currencySymbol} ${d.barChartValue.toLocaleString()}`;
        }

        return roundedDollarAmount;
      });

    tooltipElement.append('tspan')
      .attr('x', tooltipXAttribute)
      .attr('y', (d, i) => tooltipYAttribute(d, i) + 55)
      .attr('dx', 10)
      .classed('svg-tooltip-subtext', true)
      .classed('tooltip-related', true)
      .text( (d) => {
        let numberInPercentForm;
        if (d.lineChartValue === null) {
          numberInPercentForm = '';
        } else {
          numberInPercentForm = Math.floor(d.lineChartValue * 100);
        }

        return 'Margin: ' + numberInPercentForm + '%';
      });

      tooltipElement.append('tspan')
        .attr('x', tooltipXAttribute)
        .attr('y', (d, i) => tooltipYAttribute(d, i) + 80)
        .attr('dx', 10)
        .classed('svg-tooltip-subtext', true)
        .classed('tooltip-related', true)
        .text( (d, i) => {
          return d.label;
        });

    this.drawLegend();
  }

  private drawLegend(): void {
    const element = this.legendContainer.nativeElement;
    d3.select(element).selectAll('.svg-legend').remove();

    const margin = {top: 20, right: 20, bottom: 30, left: 40},
        width = 400,
        height = 15;

    const widthWithMargins = width + margin.left + margin.right;
    const heightWithMargins = height + margin.top + margin.bottom;

    const data = ['EBITDA', 'EBITDA MARGIN'];

    const svg = d3.select(element)
              .append('svg')
              .attr('width', width)
              .attr('height', height)
              .attr('preserveAspectRatio', 'xMinYMin meet')
              .attr('viewBox', `0 0 ${width} ${height}`)
              .classed('svg-content-responsive', true)
              .classed('svg-legend', true);

    const legendRectSize = 18;
    const legendSpacing = 4;

    const legend = svg.selectAll('.bar-with-line-chart-legend')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'bar-with-line-chart-legend')
    .attr('transform', function(d, i) {
      const vert = 0;
      const horz = margin.left + 100 * (i);
      return 'translate(' + horz + ',' + vert + ')';
    });

    legend.append('rect')
      .attr('width', legendRectSize)
      .attr('height', legendRectSize)
      .style('fill', function(d, i) {
        if (i === 0) {
          return '#30B2B9';
        } else {
          return 'royalblue';
        }
      })
      .style('stroke', function(d, i) {
        if (i === 0) {
          return '#30B2B9';
        } else {
          return 'royalblue';
        }
      });

    legend.append('text')
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize - legendSpacing)
      .text(function(d) { return d; });
  }
}
