import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';

@Component({
  selector: 'app-keyboard-shortcuts',
  templateUrl: './keyboard-shortcuts.component.html',
  styleUrls: ['./keyboard-shortcuts.component.scss']
})
export class KeyboardShortcutsComponent implements OnInit {
  @Input() popup: NgxPopupComponent;

  constructor() { }

  ngOnInit() {
  }

}
