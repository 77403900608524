import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AutoUnsubscribe} from '../../../../../decorators/auto-unsubscribe';
import {Company} from '@models/company';
import {Subscription} from 'rxjs/Subscription';
import {NgxPopupService} from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import {ApiService} from '@services/api.service';
import {TAX_RETURN} from '@utils/constants';
import {ReviewQueueItem} from '@models/review-queue-item';
import {ReviewQueueService} from '@services/review-queue.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedDataService} from '@services/shared-data.service';
import {TrackingService} from '@services/tracking.service';
import {AlertService} from '@services/alert.service';
import {Logger, LoggingService} from '@services/logging.service';
import {DocumentFileService} from '@services/document-file.service';
import {DocumentFile} from '@models/document-file';
import {
  StatusModalComponent
} from '@components/main/review/human-validation/shared/status-modal/status-modal.component';
import {
  DocumentPasswordEntryModalComponent
} from '@components/shared/popups/document-password-entry-modal/document-password-entry-modal.component';
import {BorrowerService} from "@services/borrower.service";


@Component({
  selector: 'app-document-password-entry',
  templateUrl: './document-password-entry.component.html',
  styleUrls: ['./document-password-entry.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class DocumentPasswordEntryComponent implements OnInit, OnDestroy {
  subsArr$: Subscription[] = [];
  isLoading = true;
  reviewQueueItemId = '';
  reviewQueueItem: ReviewQueueItem = null;
  logger: Logger;
  TAX_RETURN = TAX_RETURN;
  documentFile: DocumentFile = null;
  documentFileId: string;
  companyUuid: string;


  constructor(
    private _apiService: ApiService,
    private _popupService: NgxPopupService,
    private _changeDetector: ChangeDetectorRef,
    private _reviewQueueService: ReviewQueueService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _trackingService: TrackingService,
    private _alertService: AlertService,
    private _loggingService: LoggingService,
    private _documentFileService: DocumentFileService,
    private _borrowerService: BorrowerService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('UserService');
  }

  ngOnInit() {
    this._sharedDataService.hideDefaultNavigation();
    this.subsArr$.push(this._route.parent.paramMap.subscribe((params => {
      this.reviewQueueItemId = params.get('uuid');
      if (!this._reviewQueueService.isValidUUID(this.reviewQueueItemId)) {
        this._router.navigate(['/404']);
        return;
      }
      this.loadData();
    })));
  }

  loadPasswordEntryModal() {
    this._popupService.open({
      componentType: DocumentPasswordEntryModalComponent,
      cssClass: 'modal-document-password-entry',
      inputs: {
        companyUuid: this.companyUuid,
        reviewQueueItem: this.reviewQueueItem
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.showStatusPopup();
          }
        }
      }
    })
  }

  ngOnDestroy() {
    this._changeDetector.detach();
    this._sharedDataService.showDefaultNavigation();
  }

  loadData(): void {
    this.subsArr$.push(this._reviewQueueService.retrieveAndLock(this.reviewQueueItemId).subscribe((item: ReviewQueueItem) => {
      this.reviewQueueItem = item;

      this.retrieveDocumentFile();
      const companyId = this.reviewQueueItem.processingJobPayload.company.id;
      this._borrowerService.getCompanyById(companyId).subscribe((company: Company) => {
        this.companyUuid = company.uuid
        this.loadPasswordEntryModal();
      });
      try {
        this._trackingService.trackHumanInLoop({
          type: 'Start',
          step: 'Document Password Entry',
          documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
          documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
          documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
        });
      } catch (err) {
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
      }

    }, err => {
      this._alertService.error(err);
    }));

  }

  showStatusPopup() {
    try {
      this._trackingService.trackHumanInLoop({
        type: 'End',
        step: 'Document Password Entry',
        documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
        documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
        documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
      });
    } catch (err) {
      this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
    }

    this._popupService.open({
      componentType: StatusModalComponent,
      cssClass: 'modal-pipeline-processing',
      inputs: {
        reviewQueueItemId: this.reviewQueueItemId,
        companyUuid: this.companyUuid,
        closable: false,
      },
    });
  }

  private retrieveDocumentFile() {
    // Set the bank ID to the one from the queue item
    this._apiService.setTenantName(this.reviewQueueItem.bankId);
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.reviewQueueItem.documentFileId, true).subscribe(docs => {
      this.documentFile = docs[0];
    }, err => {
      this._alertService.error(err);
    }));
  }
}
