import { Injectable } from '@angular/core';
import {
  SPREAD_ACTION_TOGGLE_ROW,
  SPREAD_ACTION_TOGGLE_COLUMN,
} from '../utils/constants';
import { AlertService } from './alert.service';

export const uninitializedSpreadingHistoryState = {
  stack: null,
  actions: null,
  pointer: null,
  length: null,
};


@Injectable({
  providedIn: 'root'
})
export class SpreadingHistoryService {

  spreadingHistory: {
    stack: Array<any>,
    actions: Array<string>,
    pointer: number,
    length: number,
  } = uninitializedSpreadingHistoryState;

  constructor(
    private _alertService: AlertService,
  ) {
    this.spreadingHistory = uninitializedSpreadingHistoryState;
  }

  initializeNewHistory(initialState: any = {}): void {
    this.spreadingHistory = {
      stack: [JSON.parse(JSON.stringify(initialState))],
      actions: ['initial'],
      pointer: 0,
      length: 1,
    }
  }

  shouldThisBeInitialized(): boolean {
    if (this.spreadingHistory && (this.spreadingHistory.length === null || this.spreadingHistory.pointer === null || this.spreadingHistory.stack === null ) ) { // initialized & has a value in it
      return true;
    }
    return false;
  }

  addNewState(state: any, action = ''): boolean {
    const ignoredActions = [SPREAD_ACTION_TOGGLE_ROW, SPREAD_ACTION_TOGGLE_COLUMN];
    if (ignoredActions.includes(action)) {
      return false;
    }
    if (!state) {
      return false; // I saw this in testing in devgamma - not sure why this is undefined, but this should handle
      // that scenario nicely
    }

    if (this.spreadingHistory.pointer + 1 !== this.spreadingHistory.length) { // if did some undos, kill stack after pointer
      const slicedSpreadingStack = this.spreadingHistory.stack.slice(0, this.spreadingHistory.pointer + 1); // 0 is first index to slice, second param is length
      this.spreadingHistory.stack = JSON.parse(JSON.stringify(slicedSpreadingStack));
      this.spreadingHistory.length = this.spreadingHistory.stack.length;
    }

    const clonedState = JSON.parse(JSON.stringify(state));
    this.spreadingHistory.stack.push(clonedState);
    this.spreadingHistory.actions.push(action);
    this.spreadingHistory.pointer++;
    this.spreadingHistory.length++;
    return true;
  }

  undo(): any {
    if (this.spreadingHistory.pointer <= 0) {
      this._alertService.warning('No actions to undo');
      return;
    }
    this.spreadingHistory.pointer--;
    this.spreadingHistory.actions.push('undo');
    return {
      'newStateValues': JSON.parse(JSON.stringify(this.spreadingHistory.stack[this.spreadingHistory.pointer])),
      'noMoreUndos': this.spreadingHistory.pointer <= 0,
      'noMoreRedos': this.spreadingHistory.pointer + 1 >= this.spreadingHistory.stack.length,
    }
  }

  redo(): any {
    if (this.spreadingHistory.pointer + 1 >= this.spreadingHistory.stack.length) {
      this._alertService.warning('No actions to redo');
      return;
    }
    this.spreadingHistory.pointer++;
    this.spreadingHistory.actions.push('redo');
    return {
      'newStateValues': JSON.parse(JSON.stringify(this.spreadingHistory.stack[this.spreadingHistory.pointer])),
      'noMoreUndos': this.spreadingHistory.pointer <= 0,
      'noMoreRedos': this.spreadingHistory.pointer + 1 >= this.spreadingHistory.stack.length,
    }
  }

  clear(): void {
    this.spreadingHistory = uninitializedSpreadingHistoryState;
  }
}
