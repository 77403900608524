import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SharedDataService } from '@services/shared-data.service';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { Company } from '@models/company';
import { BorrowerService } from '@services/borrower.service';

@Component({
  selector: 'app-embedded-financials',
  templateUrl: './embedded-financials.component.html',
  styleUrls: ['./embedded-financials.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class EmbeddedFinancialsComponent implements OnInit {
  subsArr$: Subscription[] = [];

  constructor(
    private _sharedDataService: SharedDataService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _companyService: BorrowerService,
  ) { }

  ngOnInit(): void {
    this._sharedDataService.hideDefaultNavigation();
    this._sharedDataService.embeddedMode$.next(true);
    this.subsArr$.push(this._route.params.pipe(
      mergeMap(params => this._companyService.getCompanyById(+params['companyId'])))
      .subscribe((company: Company) => {
        if (!company) {
          this._router.navigate(['/404']);
          return;
        }
        this._sharedDataService.company$.next(company);
      }));
  }

}
