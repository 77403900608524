<div>
    <div id="main" class="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1">
        <h2>404 - Page not found</h2>
        <ul>
            <li routerLinkActive="active">
                <a [routerLink]="['/companies']">Borrowers</a>
            </li>
            <li *ngIf="userIsAdmin()" routerLinkActive="active">
                <a [routerLink]="['/users']">Users</a>
            </li>
            <li *ngIf="userIsDeveloper() && isEnviroment" routerLinkActive="active">
                <a [routerLink]="['/settings']">Settings</a>
            </li>
            <li *ngIf="shouldDisplayManualReview()" class="manual-review-link" routerLinkActive="active">
                <a [routerLink]="['/review']">Manual Review</a>
            </li>
        </ul>
    </div>
</div>
