import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {DownloadService} from '../../../../../services/download.service';
import {SpreadingTemplateService} from '@services/spreading-template.service';
import {AlertService} from '@services/alert.service';
import {Router} from '@angular/router';
import {NgxPopupService} from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import {ConfirmationPopupComponent} from '@components/shared/popups/confirmation/confirmation-popup.component';


@Component({
  selector: 'app-template-list-item',
  templateUrl: './template-list-item.component.html',
  styleUrls: ['./template-list-item.component.scss']
})
export class TemplateListItemComponent implements OnInit {
  @Input() name = '';
  @Input() type = '';
  @Input() id = 0;
  @Input() uuid = '';
  @Input() numBorrowers = 0;
  @Input() createdAt = '';
  @Input() isDefault = false;
  @Input() active = true
  @Output() templateItemEnabledOrDisabled = new EventEmitter<boolean>();

  handler;

  constructor(private apiService: ApiService,
              private downloadService: DownloadService,
              private spreadingTemplateService: SpreadingTemplateService,
              private alertService: AlertService,
              private router: Router,
              private _popupService: NgxPopupService
  ) {
  }

  ngOnInit() {
  }

  dictToQueryString(dict) {
    return Object.keys(dict).map(k => `${k}=${encodeURIComponent(dict[k])}`).join('&');
  }

  downloadCsv() {
    this.downloadService.downloadFromBackend(`/api/spreadingtemplates/${this.id}/csv`);
  }

  duplicateTemplateUsingTemplateId() {
    const confirmed = confirm(
      `Are you sure you want to duplicate template  '${this.name}'`
    );
    if (confirmed) {
      this.spreadingTemplateService.duplicateSpreadingTemplate(this.id).subscribe(response => {
        console.log(response)
        const newTemplateName = response.newTemplateName
        const newTemplateUuid = response.newTemplateUuid
        this.alertService.success(`Template '${newTemplateName}' successfully duplicated from '${this.name}'`)
        if (this.type === 'GLOBAL_CASHFLOW') {
          this.router.navigate(['/spreading-templates/', 'multi-entity', newTemplateUuid])
        } else {
          this.router.navigate(['/spreading-templates', newTemplateUuid, this.type])
        }

      }, error => {
        if (error.error_type === 'ValidationError' && error.message.includes('duplicate')) {
          this.alertService.error(`Error: ${this.name} (copy) already exists`)
        } else {
          this.alertService.error(`Unexpected error occurred while attempting to duplicate template '${this.name}'`)
        }
      })
    }
  }

  disableOrRestoreAnalysisTemplate() {
    let modalQuestion: string
    let modalText: string
    let successMessage: string
    let errorMessage: string
    if (this.active) {
      modalQuestion = 'DISABLE TEMPLATE?'
      modalText = `Are you sure you want to disable \'${this.name}\'`
      successMessage = `Template \'${this.name}\' successfully disabled`
      errorMessage = `Unexpected error when disabling template \'${this.name}\'`
    } else {
      modalQuestion = 'RESTORE TEMPLATE?'
      modalText = `Are you sure you want to restore \'${this.name}\'`
      successMessage = `Template \'${this.name}\' successfully restored`
      errorMessage = `Unexpected error when restoring template \'${this.name}\'`
    }
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: modalQuestion,
        text: modalText,
        confirmButtonText: 'Yes'
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.spreadingTemplateService.updateTemplate(this.id, {'active': !this.active}).subscribe(
              response => {
                this.alertService.success(successMessage)
                this.templateItemEnabledOrDisabled.emit(true)
              }, error => {
                this.alertService.error(errorMessage)
              }
            )
          }
        }
      },
    });
  }

  navigateToTemplateEditor() {
    if (this.active) {
      if (this.type === 'GLOBAL_CASHFLOW') {
        this.router.navigate(['/spreading-templates/', 'multi-entity', this.uuid])
      } else {
        this.router.navigate(['/spreading-templates/', this.uuid, this.type])
      }
    }
  }
}
