import { Component, OnInit, Input } from '@angular/core';
import {Subscription} from 'rxjs';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import {Select2OptionData} from "@components/shared/select2/select2.interface";
import { SpreadingTemplateService } from '@services/spreading-template.service';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-add-entity-subsection-for-specific-template',
  templateUrl: './add-entity-subsection-for-specific-template.component.html',
  styleUrls: ['./add-entity-subsection-for-specific-template.component.scss']
})
export class AddEntitySubsectionForSpecificTemplateComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() standardTemplateOptions: Array<Select2OptionData> = [];
  @Input() nameOfBaseEntitySubsection = "";

  subsArr$: Subscription[] = [];
  selectedSpreadingTemplateId = '';
  selectedSpreadingTemplateName = '';
  submitted = false;

  // text constants
  TITLE = 'ADD ENTITY SUBSECTION FOR SPECIFIC TEMPLATE';
  DESCRIPTION = 'The items included in this section will replace any entity level items in this subsection for the specified standard template.'
  CONFIRM_BUTTON_TEXT = 'Add Section';
  CANCEL_BUTTON_TEXT = 'Cancel';
  INPUT_LABEL = 'Standard Template';
  NO_SPREADING_TEMPLATE_OPTIONS_TEXT = "You've already created entity level subsections for all available standard spreading templates.";

  constructor(
    private spreadingTemplateService: SpreadingTemplateService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    if (this.standardTemplateOptions.length) {
      this.selectedSpreadingTemplateId = this.standardTemplateOptions[0]['id'];
      this.selectedSpreadingTemplateName = this.standardTemplateOptions[0]['text'];
    }
  }

  createSectionItem() {
    this.subsArr$.push(this.spreadingTemplateService.generateGenericStandardItem('CALCULATION', this.nameOfBaseEntitySubsection, this.selectedSpreadingTemplateName)
      .subscribe(
        data => {
          data.standardSpreadingTemplateThatEntitySubsectionAppliesTo = Number(this.selectedSpreadingTemplateId);
          this.submitted = false;
          this.popup.close(true, data);
          this.alertService.success('Section created successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }

  updateSelectedSpreadingTemplate(selectedSpreadingTemplate) {
    if (!selectedSpreadingTemplate.data || !selectedSpreadingTemplate.data.length) {
      this.alertService.error('Error while updating selected spreading template');
      return;
    }
    this.selectedSpreadingTemplateId = selectedSpreadingTemplate.value;
    this.selectedSpreadingTemplateName = selectedSpreadingTemplate.data[0].text;
  }
}
