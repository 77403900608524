import {Observable, Subscription} from 'rxjs';
import {Component, Input} from '@angular/core';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import {SpreadingTemplateService} from '@services/spreading-template.service';
import {AlertService} from '@services/alert.service';


@Component({
  selector: 'app-add-custom-item-to-template-popup',
  templateUrl: './add-custom-item-to-template-popup.component.html',
  styleUrls: ['./add-custom-item-to-template-popup.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class AddCustomItemToTemplatePopupComponent {
  @Input() popup: NgxPopupComponent;
  @Input() title = 'ADD SECTION';
  @Input() description = 'Add a new section to the template structure.'
  @Input() confirmButtonText = 'Add Section';
  @Input() cancelButtonText = 'Cancel';
  @Input() inputBoxLabel = 'Section Name';
  @Input() hasRollup = true;
  @Input() documentType = 'CALCULATION'
  @Input() itemType?: 'DAS_HEADER' | 'DAS_SECTION' | 'DAS_MANUAL_INPUT' | 'CUSTOM' = 'CUSTOM'
  subsArr$: Subscription[] = [];
  sectionName: string;
  submitted = false;


  constructor(
    private spreadingTemplateService: SpreadingTemplateService,
    private alertService: AlertService,
  ) {
  }

  createSectionItem() {
    let submitRequest: Observable<any>;
    switch (this.itemType) {
      case("DAS_HEADER"):
        submitRequest = this.spreadingTemplateService.generateGenericHeaderItemForDASSection(this.sectionName, this.documentType);
        break
      case("DAS_SECTION"):
        submitRequest = this.spreadingTemplateService.generateDASSection(this.sectionName);
        break
      case("DAS_MANUAL_INPUT"):
        submitRequest = this.spreadingTemplateService.generateDASManualInputItem(this.sectionName);
        break
      default:
        submitRequest = this.spreadingTemplateService.generateGenericStandardItem(this.documentType, this.sectionName, '', this.hasRollup)
        break
    }
    this.subsArr$.push(submitRequest
      .subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, data);
          this.alertService.success('Section created successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }

}
