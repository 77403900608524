<div *ngIf="showToolTip" id="tip" class="interpolatedPeriodToolTip">Interpolated Period</div>
<table class="table table-sticky">
    <thead>
        <tr>
            <th class="type-label">Statement Date</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed': showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column),
                        'historical-period': !columnIsProjection(column)
                    }"
                >
                  <div class="column-menu-toggle" [ngClass]="{'show-when-open': idx === contextMenuOpenColumnIdx}" (click)="toggleColumnContextMenu(idx)">
                    <i class="column-menu-toggle-icon fas fa-chevron-down fa-xs"></i>
                  </div>
                  <ul *ngIf="idx === contextMenuOpenColumnIdx" class="dropdown-menu mt-1">
                    <li (click)="hideColumn($event, idx)"><span><i class="fas" [ngClass]="(showHidden && (column.isHidden || !showNumbers)) ? 'fa-eye' : 'fa-eye-slash'"></i>{{(showHidden && (column.isHidden || !showNumbers)) ? 'Unhide' : 'Hide'}} Column</span></li>
                    <li *ngIf="!viewOnlyMode && column?.forecastedFrameId">
                        <span (click)="editPeriodColumn(column)"><i class="fas fa-pencil"></i>Edit Forecasted Period</span>
                    </li>
                    <li *ngIf="!viewOnlyMode && !column?.forecastedFrameId && column?.combinedFrameId && column?.combinedColumnStatementBuilderOriginType">
                        <span (click)="editPeriodColumn(column)"><i class="fas fa-pencil"></i>
                            {{column?.combinedColumnStatementBuilderOriginType === 'CALCULATED' ? 'Edit Calculated Period' : 'Edit Consolidated Period'}}
                        </span>
                    </li>
                    <li *ngIf="column.isDeletable() && !viewOnlyMode"><span (click)="deletePeriodColumn(column)"><i class="fas fa-trash"></i>Delete Statement</span></li>
                    <ng-container *ngIf="!viewOnlyMode && showSpreadingInContextMenu && !embeddedMode">
                      <ng-container *ngFor="let doc of column.documentOptions">
                        <li class="dropdown-divider"></li>
                        <li><span class="source-doc-section-header"><strong>{{doc.label}}</strong></span></li>
                        <li><span (click)="columnLevelContextMenuDocumentClicked(doc, $event, 'spread')"><i class="fas fa-pencil"></i>Edit Spread</span></li>
                        <li><span (click)="columnLevelContextMenuDocumentClicked(doc, $event, 'download')"><i class="fas fa-download"></i>Download Document</span></li>
                      </ng-container>
                    </ng-container>
                  </ul>
                  {{column.statementDate}}

                </th>
                <th [ngClass]="{'hidden': (column.isHidden || (column.trendIsHidden && column.commonSizeIsHidden) || column.cellFormat.textFormat !== '')}"
                    class="percent">
                    {{column.statementDate}}
                </th>
            </ng-container>
        </tr>
        <tr>
            <th class="type-label">Scenario</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed': showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column),
                        'historical-period': !columnIsProjection(column)
                    }"
                >
                    {{column.scenario}}
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || (column.trendIsHidden && column.commonSizeIsHidden) || column.cellFormat.textFormat !== '')}"
                    class="percent">
                    {{column.scenario}}
                </th>
            </ng-container>
        </tr>
        <ng-container *ngIf="_projectionHelperService.atLeastOneProjectionColumnExists(columns)">
          <tr>
            <th class="type-label">{{ (projectionScenarioTypeLabelOverride + ' Name') }}</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed': showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column),
                        'historical-period': !columnIsProjection(column)
                    }"
                    title="{{column.projectionName}}"

                >
                    {{_projectionHelperService.getCondensedProjectionName(column.projectionName)}}
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || (column.trendIsHidden && column.commonSizeIsHidden) || column.cellFormat.textFormat !== '')}"
                    class="percent">
                    {{_projectionHelperService.getCondensedProjectionName(column.projectionName)}}
                </th>
            </ng-container>
          </tr>
        </ng-container>
        <tr>
            <th class="type-label">Months Covered</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed': showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column),
                        'historical-period': !columnIsProjection(column)
                    }"
                >
                    {{column.monthsInInterval}}
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || (column.trendIsHidden && column.commonSizeIsHidden) || column.cellFormat.textFormat !== '')}"
                    class="percent">
                    {{column.monthsInInterval}}
                </th>
            </ng-container>
        </tr>
        <tr>
            <th class="type-label">Prep Type</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed': showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column),
                        'historical-period': !columnIsProjection(column)
                    }"
                >
                    {{ column.preparationType | formatPrepType }}
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || (column.trendIsHidden && column.commonSizeIsHidden) ||  column.cellFormat.textFormat !== '')}"
                    class="percent">
                    {{ column.preparationType | formatPrepType }}
                </th>
            </ng-container>
        </tr>
        <tr>
            <th [ngClass]="{'show-bottom-border': !dollarSign}"  class="type-label">Statement Type</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                  [ngClass]="{
                    'hidden': !showHidden && (column.isHidden || !showNumbers),
                    'grayed': showHidden && (column.isHidden || !showNumbers),
                    'from-projection': columnIsProjection(column),
                    'historical-period': !columnIsProjection(column),
                    'show-bottom-border': !dollarSign
                  }"
                >
                    {{ column.reportingInterval | reportingPeriod}}
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || column.trendIsHidden || column.cellFormat.textFormat !== ''), 'show-bottom-border': !dollarSign}"
                    class="percent">
                    %
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || column.commonSizeIsHidden || column.cellFormat.textFormat !== ''), 'show-bottom-border': !dollarSign}"
                    class="percent">
                    CS
                </th>
            </ng-container>
        </tr>
        <tr *ngIf="dollarSign">
            <th class="type-label show-bottom-border">Currency</th>
            <ng-container *ngFor="let column of columns; let idx=index;trackBy: trackColumn">
                <th
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed': showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column),
                        'historical-period': !columnIsProjection(column)
                    }"
                    class="show-bottom-border"
                >
                    {{ column.currencyFormat }}
                </th>
                <th [ngClass]="{'hidden': (column.isHidden || (column.trendIsHidden && column.commonSizeIsHidden) ||  column.cellFormat.textFormat !== '')}"
                    class="percent show-bottom-border">
                    {{ column.currencyFormat }}
                </th>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of rows; let idx = index">
            <tr
                [className]="getRowFormatClass(row.rowFormat, idx)"
            >
            <td
              *ngIf="row.rowFormat && row.rowFormat.header"
              class="line-item"
              (click)="toggleSectionDrawerState(row.lineItem.id)"
              style="cursor:pointer"
            >
              <i
                [ngClass]="getClassForSectionToggle(row.lineItem.id)"
              ></i>
              {{row.label}}
              <i
                class="fas fa hide-row header-eye-icon"
                [ngClass]="(showHidden && hiddenRowIndices.has(idx)) ? 'fa-eye' : 'fa-eye-slash'"
                (click)="hideRow($event, idx)"
              ></i>
            </td>
            <td
              *ngIf="isSourceStatement || (row.rowFormat && !row.rowFormat.header && sectionDrawerState[lineItemIdToParentSectionIdMapping[row.lineItem.id]])"
              class="line-item"
            >
                <i class="graph-check" (click)="toggleRow($event, idx)" [ngClass]="{
                    'fas fa-chart-line deselected': !row.selected,
                    'far fa-check-square selected': row.selected
                }"></i>
                <ng-container *ngIf="!parentItemIsHidden(row)">
                  <i class="fas hide-row"
                    [ngClass]="(showHidden && hiddenRowIndices.has(idx)) ? 'fa-eye' : 'fa-eye-slash'"
                    (click)="hideRow($event, idx)"></i>
                </ng-container>
                <span [className]="'indent indent-' + row.indentation.toString()"></span>
                <span class="line-item-toggle" title="{{row.label}}"
                    [ngClass]="{
                        'fs-block fs-exclude': isSourceStatement
                    }"
                >
                    {{row.label}}
                </span>
            </td>
            <ng-container *ngIf="isSourceStatement || sectionDrawerState[lineItemIdToParentSectionIdMapping[row.lineItem.id]]; else sectionDrawerClosed">
              <ng-container *ngFor="let column of columns; let colIdx = index;trackBy: trackColumn">
                <td *ngIf="!(!showHidden && (column.isHidden || !showNumbers))"
                    class="value-cell no-padding"
                    [ngClass]="{
                        'hidden': !showHidden && (column.isHidden || !showNumbers),
                        'grayed' : showHidden && (column.isHidden || !showNumbers),
                        'from-projection': columnIsProjection(column)
                    }">
                    <app-table-cell *ngIf="!row.rowFormat.header && (!column.cells[idx].isHidden || showNumbers) && !row.rowFormat.isParent"
                        [cell]="column.cells[idx]"
                        [sourceComments]="column.cells[idx]?.calculatedValue?.sourceComments"
                        [statementDate]="column.statementDate"
                        [idx]="idx"
                        [value]="column?.cells[idx]?.calculatedValue?.value ? column.cells[idx].calculatedValue.value : 0"
                        [allowEdit]="false"
                        [isEditing]="false"
                        [flagOnMismatch]="false"
                        [row]="row"
                        [isHeader]="row.rowFormat.header"
                        [isBold]="row.rowFormat.bold"
                        [lineItemId]="row.lineItem.id"
                        [originalValue]="column?.cells[idx]?.calculatedValue?.originalValue ? column.cells[idx].calculatedValue.originalValue : 0"
                        [formattedValue]="column.cells[idx].formattedValue"
                        [isHighlighted]="selectedRow == idx && selectedColumn == colIdx"
                        [isRoundedToThousands]="roundToThousands"
                        [contextMenuDocumentItems]="column.cells[idx].documentOptions"
                        (contextMenuItemSelected)="contextMenuItemSelected($event)"
                        [showSpreadingInContextMenu]="showSpreadingInContextMenu"
                        [isSourceStatement]="isSourceStatement"
                        [isTemplated]="templated"
                        (highlightCell)="selectedCell(idx, colIdx)"
                        (openFootnotes)="enterFootnotesMode($event)"
                        (insightsSelected)="insightsSelected($event)"
                        [viewOnlyMode]="viewOnlyMode"
                    >
                    </app-table-cell>
                </td>

                <td *ngIf="!(column.isHidden || column.trendIsHidden || column.cellFormat.textFormat)"
                    class="value-cell no-padding percent"
                    [ngClass]="{'hidden': (column.isHidden || column.trendIsHidden || column.cellFormat.textFormat !== '')}">
                    {{getTrend(column, idx, row.rowFormat.header)}}
                </td>
                <td *ngIf="!(column.isHidden || column.commonSizeIsHidden || column.cellFormat.textFormat)"
                    class="value-cell no-padding percent"
                    [ngClass]="{'hidden': (column.isHidden || column.commonSizeIsHidden || column.cellFormat.textFormat !== '')}">
                    {{getCommonSize(column, idx, row.rowFormat.header)}}
                </td>
              </ng-container>
            </ng-container>
            <!-- when section drawer is closed we need these dummy table cells so that the gray highlight appears when you hover over header -->
            <ng-template #sectionDrawerClosed>
              <ng-container *ngFor="let column of columns; let colIdx = index;trackBy: trackColumn">
                <td *ngIf="row.rowFormat && row.rowFormat.header"></td>
              </ng-container>
            </ng-template>
            </tr>
        </ng-container>
</table>
