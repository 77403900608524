export const MONTHS = ['January', 'February', 'March', 'April', 'May',
  'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const MONTHS_PERIOD = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const QUARTER_PERIOD = [1, 2, 3, 4];
export const SEMI_ANNUAL_PERIOD = [1, 2];
export const ANNUAL_PERIOD = [1];
export const REPORTED_TYPE_COEFFICIENTS = [1, 3, 6, 12];

export const MONTHS_BY_PERIOD = {
  MONTHLY: [
    [1],
    [2],
    [3],
    [4],
    [5],
    [6],
    [7],
    [8],
    [9],
    [10],
    [11],
    [12]
  ],
  QUARTERLY: [
    [1],
    [4],
    [7],
    [10]
  ],
  SEMI_ANNUALLY: [
    [1],
    [7]
  ],
  ANNUALLY: [
    [1]
  ]
};
