<div class="modal__header">
  <i class="fa fa-square-poll-vertical header-icon"></i>
  <span>CREATE GLOBAL CASH FLOW</span>
</div>

<form
  [formGroup]="analysisForm"
  (submit)="save(analysisForm.value, analysisForm.valid)"
>
  <ng-container *ngIf="entities().length > 1 && primaryEntityHasPeriods(); else noAvailablePeriods">
    <div class="modal__content" *ngIf="templates.length > 0; else noTemplates">
      <div class="mb-3">
        <label class="control-label" for="name">Analysis Name</label>
        <input type="text" class="form-control name-input" [ngClass]="{'danger-border': submitted && isFormInvalid() && analysisForm.get('name').hasError('required')}" formControlName="name" id="name" placeholder="Analysis Name">
        <span class="help-block"
          *ngIf="submitted && isFormInvalid() && analysisForm.get('name').hasError('required')">
          An analysis name is required
        </span>
      </div>
      <div class="mb-3">
          <label class="control-label" for="template">Select Template</label>
          <select class="form-select" formControlName="templateId">
            <option
              *ngFor="let template of templates; let i = index"
              [selected]="i == 0"
              [value]="template.id"
            >
              {{template.name}}
            </option>
          </select>
          <span class="help-block"
            *ngIf="isFormInvalid() && analysisForm.get('templateId').hasError('required')">
            A global cash flow template is required
          </span>
      </div>
      <div class="mb-3">
        <label class="control-label" for="periods">Select Periods</label>
        <div class="period-selection-container">
          <div class="select-deselect-all-checkbox">
            <input type="checkbox" (click)="selectOrDeselectAllPeriods()" [checked]="isAnyPeriodChecked()" >
            <label class="select-deselect-all-checkbox-label">Select all periods for all entities</label>
          </div>
          <div *ngFor="let entity of entities().controls; let entityIndex=index" class="entity-checkbox-section">
            <div class="entity-label" *ngIf="!entityExpanded(entityIndex)">
              <a (click)="toggleExpandOn(entityIndex)">
                <span class="fa fa-chevron-right fa-lg"></span>
                <span class="entity-label-text">{{entity.value.entityName}}</span>
              </a>
            </div>
            <div class="entity-label" *ngIf="entityExpanded(entityIndex)">
              <a (click)="toggleExpandOff(entityIndex)">
                <span class="fa fa-chevron-down fa-lg"></span>
                <span class="entity-label-text">{{entity.value.entityName}}</span>
              </a>
            </div>
            <ng-container *ngIf="entityExpanded(entityIndex)">
              <div *ngIf="entityIndex > 0" class="period-selection-option">
                <ng-container [formGroup]="entity">
                  <input type="checkbox" formControlName="isBlankEntity" (change)="isBlankEntityChecked(entityIndex)"/>
                  <label class="period-checkbox-label">Add {{entity.value.entityName}} without any data</label>
                </ng-container>
              </div>
              <div *ngFor="let period of entityPeriods(entityIndex).controls; let periodIndex=index" class="period-selection-option">
                <ng-container [formGroup]="period">
                  <input type="checkbox" formControlName="isSelected" />
                  <label class="period-checkbox-label">{{period.value.period.label}} {{period.value.period.interval}} {{period.value.period.preparationType}} ({{period.value.period.scenario}}{{(period.value.period.scenario === 'PROJECTION' && period.value.period.projectionName !== '') ? (' - ' + period.value.period.projectionName) : ''}})</label>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noTemplates>
    <div class="modal__content">
      <p><strong>Cannot create an analysis. No Global Cashflow templates.</strong></p>
    </div>
  </ng-template>

  <ng-template #noAvailablePeriods>
    <div class="modal__content">
      <p><strong>{{noAvailablePeriodMessageToDisplay()}}</strong></p>
    </div>
  </ng-template>

  <div class="modal__footer">
    <div class="footer-action-buttons">
      <button type="button" class="btn btn-link" (click)="popup.close();">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="loading || !(entities().length > 1 && primaryEntityHasPeriods())">
        <span class="create-analysis-btn-label">Create Analysis</span>
        <div *ngIf="loading" class="loader button-loader build-statement-btn-icon"></div>
      </button>
    </div>
  </div>
</form>
