<td class="file-name-cell">{{uploadedFile.fileItem?.file.name}}</td>
<td>
    <div class="form-input-container">
        <app-select2
            id="docTypeSelect"
            placeholder="Select Statement Type"
            [disabled]="fileUploadProcessBegun"
            [data]="uploadDocumentTypeOptions"
            [options]="{minimumResultsForSearch: -1}"
            [value]="uploadedFile.selectedType"
            (valueChanged)="updateDocType($event.value)"
            [width]="'100%'"
            [ngClass]="{'has-error': uploadedFile.error.selectedType}"
        >
        </app-select2>
        <span class="help-block error-text"
              *ngIf="uploadedFile.error.selectedType">Please select the statement type</span>

        <ng-container *ngIf="uploadedFile.selectedType === TAX_RETURN">
            <div class="row additional-tax-form-inputs">
                <div class="col-6 sm-right-padding">

                    <app-select2
                        id="taxFormSelect"
                        placeholder="Select Tax Form"
                        [disabled]="fileUploadProcessBegun"
                        [data]="taxFormOptions"
                        [options]="{minimumResultsForSearch: -1}"
                        [value]="uploadedFile.selectedTaxForm"
                        (valueChanged)="updateTaxForm($event.value)"
                        [width]="'100%'"
                        [ngClass]="{'has-error': uploadedFile.error.selectedTaxForm}"
                    >
                    </app-select2>
                    <span class="help-block error-text" *ngIf="uploadedFile.error.selectedTaxForm">Please select a tax form</span>

                </div>
                <div class="col-6 sm-left-padding">
                    <ng-container>

                        <app-select2
                            id="taxYearSelect"
                            placeholder="Tax Return Year"
                            [disabled]="fileUploadProcessBegun"
                            [data]="taxYearOptions"
                            [options]="{minimumResultsForSearch: -1}"
                            [value]="uploadedFile.selectedStatementDate"
                            (valueChanged)="updateTaxYear($event.value)"
                            [width]="'100%'"
                            [ngClass]="{'has-error': uploadedFile.error.selectedStatementDate}"
                        >
                        </app-select2>
                        <span class="help-block error-text" *ngIf="uploadedFile.error.selectedStatementDate">Please select when the tax return year began</span>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <div *ngFor="let customAttrDefinition of customAttrDefinitions" class="row">
            <div class="col-12 small-top-margin">
                <div class="title">{{customAttrDefinition.name}}</div>
                <input type="text" name="{{customAttrDefinition.key}}" class="form-control"
                       [disabled]="fileUploadProcessBegun"
                       [ngClass]="{'has-error': uploadedFile.error.invalidCustomAttributeFields.hasOwnProperty(customAttrDefinition.key)}"
                       (change)="customAttributeChange(customAttrDefinition, $event)"/>
                <span class="help-block error-text"
                      *ngIf="uploadedFile.error.invalidCustomAttributeFields.hasOwnProperty(customAttrDefinition.key)">
                    {{customAttrDefinition.name}} is required
                </span>
            </div>
        </div>
    </div>


</td>
<td class="icon-cell">
    <ng-container [ngSwitch]="uploadedFile.status">
        <i *ngSwitchCase="'uploaded'" class="fa fa-trash-alt" [ngClass]="{'disabled': fileUploadProcessBegun}"
           (click)="!fileUploadProcessBegun ? removeFile() : null"></i>
        <div *ngSwitchCase="'submitting'" class="loader"></div>
        <i *ngSwitchCase="'submission_complete'" class="fa fa-check"></i>
        <i *ngSwitchCase="'error'" class="fa fa-xmark"></i>
    </ng-container>
</td>
