<div *ngIf="company && !isSpreading()">

    <nav class="navbar navbar-expand-md navbar-light navbar-secondary p-0">
      <button class="navbar-toggler nav-expand-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link px-3" [routerLink]="['/companies', company.uuid, 'overview']"
                       routerLinkActive="active">Overview</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link px-3" [routerLink]="['/companies', company.uuid, 'financials', 'analysis']"
                       routerLinkActive="active">Financials</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showAnalysisTab">
                    <a class="nav-link px-3" [routerLink]="['/companies', company.uuid, 'analyses']"
                       routerLinkActive="active">Analysis</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="isCovenantsPageShown">
                    <a class="nav-link px-3" [routerLink]="['/companies', company.uuid, 'covenants']"
                       routerLinkActive="active">Covenants</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link px-3" [routerLink]="['/companies', company.uuid, 'data']" routerLinkActive="active">Documents</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link px-3" [routerLink]="['/companies', company.uuid, 'profile']"
                       routerLinkActive="active">Borrower Profile</a>
                </li>
            </ul>
        </div>

        <div class="upload ms-auto" *ngIf="showAddStatementButton">
            <app-upload-statement
                [company]="company"
                [entitlementData]="entitlementData"
            >
            </app-upload-statement>
        </div>
    </nav>
</div>
