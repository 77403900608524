<div class="modal__header">
    <div class="modal__title">
        Keyboard Shortcuts
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<div class="modal__content">
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>shift</kbd><kbd>down</kbd>
        </div>
        <div class="col-md-8">
            In addition to current item, also select next item
        </div>
    </div>
    <div class="row shortcut-row-no-top-space">
        <div class="col-md-4 text-start">
            <kbd>shift</kbd><kbd>up</kbd>
        </div>
        <div class="col-md-8">
            Also select previous item
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>left</kbd>
        </div>
        <div class="col-md-8">
            Select previous column for template sidebar calculations
        </div>
    </div>
    <div class="row shortcut-row-no-top-space">
        <div class="col-md-4 text-start">
            <kbd>right</kbd>
        </div>
        <div class="col-md-8">
            Select next column for template sidebar calculations
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>c</kbd>
        </div>
        <div class="col-md-8">
            Categorize selected row(s) as “components”
        </div>
    </div>
    <div class="row shortcut-row-no-top-space">
        <div class="col-md-4 text-start">
            <kbd>v</kbd>
        </div>
        <div class="col-md-8">
            Categorize selected row as “validation”
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>f2</kbd>
        </div>
        <div class="col-md-8">
            Edit a highlighted cell
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>shift</kbd><kbd>enter</kbd>
        </div>
        <div class="col-md-8">
            Select previous item
        </div>
    </div>
    <div class="row shortcut-row-no-top-space">
        <div class="col-md-4 text-start">
            <kbd>enter</kbd>
        </div>
        <div class="col-md-8">
            Select next item
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>escape</kbd>
        </div>
        <div class="col-md-8">
            Deselect all
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            Windows<br />
            <kbd>alt</kbd> <kbd>i</kbd>
            <br />
            Mac<br />
            <kbd>option</kbd><kbd>i</kbd>
        </div>
        <div class="col-md-8">
            Invert (change sign) selected row(s)
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>u</kbd>
        </div>
        <div class="col-md-8">
            Uncategorize selected row(s)
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            Windows<br />
            <kbd>alt</kbd> <kbd>a</kbd><br />
            Mac<br />
            <kbd>option</kbd> <kbd>a</kbd><br />
        </div>
        <div class="col-md-8">
            Adds an adjustment for the selected line item
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            Windows<br />
            <kbd>alt</kbd> <kbd>backspace</kbd><br />
            Mac<br />
            <kbd>option</kbd> <kbd>backspace</kbd><br />
        </div>
        <div class="col-md-8">
            Removes an adjusted item
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            double click on cell
        </div>
        <div class="col-md-8">
            Change value (edited)
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>ctrl</kbd> <kbd>+</kbd><br />
        </div>
        <div class="col-md-8">
            Insert row below (selected row)
        </div>
    </div>
    <div class="row shortcut-row-no-top-space">
        <div class="col-md-4 text-start">
            <kbd>ctrl</kbd> <kbd>+</kbd><br />
        </div>
        <div class="col-md-8">
            Insert column right (when no row selected)
        </div>
    </div>

    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            Windows<br />
            <kbd>ctrl</kbd> <kbd>z</kbd>
            <br />
            Mac<br />
            <kbd>command</kbd><kbd>z</kbd>
        </div>
        <div class="col-md-8">
            Undo last change
        </div>
    </div>

    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            Windows<br />
            <kbd>ctrl</kbd><kbd>shift</kbd><kbd>z</kbd>
            <br />
            Mac<br />
            <kbd>command</kbd><kbd>shift</kbd><kbd>z</kbd>
        </div>
        <div class="col-md-8">
            Redo last undo
        </div>
    </div>

    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>ctrl</kbd> <kbd>s</kbd><br />
        </div>
        <div class="col-md-8">
            Save progress
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>ctrl</kbd> <kbd>x</kbd><br />
        </div>
        <div class="col-md-8">
            Cut
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>ctrl</kbd> <kbd>c</kbd><br />
        </div>
        <div class="col-md-8">
            Copy
        </div>
    </div>
    <div class="row shortcut-row">
        <div class="col-md-4 text-start">
            <kbd>ctrl</kbd> <kbd>v</kbd><br />
        </div>
        <div class="col-md-8">
            Paste
        </div>
    </div>
</div>

<div class="modal__footer">
    <button class="btn btn-link" (click)="popup.close();">Close</button>
</div>
