import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomUploaderService {
  successSubject$: Subject<boolean> = new Subject();
  errorSubject$: Subject<boolean> = new Subject();

  constructor() {
  }
}
