import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserService } from '@services/user.service';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { AppConfigService } from '@services/app-config.service';
import { EnvironmentService } from '@services/environment.service';
import { CommonFunctions } from '@utils/common-functions';
import { User } from '@models/user';
import { ApiService } from '@services/api.service';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class UserLoginComponent implements OnInit {

  environment: any;
  returnUrl: string;
  selectedBankId = '';
  subsArr$: Subscription[] = [];
  tenants = [];

  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    private userService: UserService,
    private environmentService: EnvironmentService,
    private location: Location,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.returnUrl = CommonFunctions.getParameterByName('returnUrl');
    const isAuthReturn = CommonFunctions.getParameterByName('authReturn') === '1';
    const connectionId = CommonFunctions.getParameterByName('connection_id');

    if (connectionId) {
      setTimeout(() => { this.cacheLogoutDestinationForConnection(connectionId) }, 0);
    }

    if (isAuthReturn) {
      this.location.replaceState('/login')
      this.subsArr$.push(
        this.userService
          .getCurrentUser(true)
          .subscribe((user: User) => {
            this.subsArr$.push(
              this.environmentService
                .loadEnvConfig()
                .subscribe(() => {
                  this.environment = this.environmentService.getEnvConfig();
                  this.navigateToApp();
                })
            );
          })
      );
    } else {
      this.initiateLoginProcess();
    }
  }

  private cacheLogoutDestinationForConnection(connectionId: string) {
    this.apiService.send('get', `/api/identity/connection/${connectionId}`).subscribe(data => {
      const logout_destination = data.connection_details.logout_destination
      if (logout_destination) {
        localStorage.setItem(`sso_logout_destination`, logout_destination)
      }
    }, error => {
      console.warn(error)
    })
  }

  private navigateToApp() {
    this.router.navigate((!!this.returnUrl) ? [this.returnUrl]  : ['/']);
  }

  private initiateLoginProcess(): void {
    this.subsArr$.push(
      this.appConfigService.envFromHostname.subscribe((envFromHost) => {
        const redirectUrl = envFromHost.serverUrl + '/app/login?client_id=fincura-app';
        window.location.href = redirectUrl;
      })
    );
  }
}
