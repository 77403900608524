import { Component, OnInit, Input } from '@angular/core';
import { GraphService } from '../../../../../../services/graph.service';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-ebitda-graph-tile',
  templateUrl: './ebitda-graph-tile.component.html',
  styleUrls: ['./ebitda-graph-tile.component.scss']
})
export class EbitdaGraphTileComponent implements OnInit {
  percentChange = ''
  value = '';
  ebitdaGraphData = [];

  @Input() mostRecentStatementDate = '';
  @Input() analyticsData;
  @Input() periodType = '';
  @Input() currencySymbol = '';

  constructor(
    private _graphService: GraphService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit() {
    this.ebitdaGraphData = this._graphService.barWithLineChartDataStructure(
      this.analyticsService.getDataByLineItem('REF_EBITDA_MARGIN', this.analyticsData),
      this.analyticsService.getDataByLineItem('REF_EBITDA', this.analyticsData),
      this.analyticsData['periods'],
    );


    if (this.isEbitdaGraphDataAvailable()) {
      this.value = this.analyticsService.formattedValue('REF_EBITDA', this.analyticsData);
    }
  }

  isEbitdaGraphDataAvailable(): boolean {
    const filteredValues = this.ebitdaGraphData.map( (data) => data.value).filter( value => value !== null);
    return filteredValues.length > 0;
  }


}
