import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core'
import { IMyDateModel, INgxMyDpOptions } from '../../ngx-my-date-picker/interfaces';
import { createAutoCorrectedDatePipe } from 'text-mask-addons/dist/textMaskAddons';
import { DATE_LOWER, DATE_MASK } from '../../../../utils/constants';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-calendar-component',
  templateUrl: 'input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCalendarComponent),
      multi: true,
    }
  ]
})
export class InputCalendarComponent implements OnInit, ControlValueAccessor {
  @Input() initialDate: string;
  @Input() fieldName: string;
  @Input() disabled = false;
  @Output() dateEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('dp', { static: true }) dp;

  dateMask = DATE_MASK;
  autoCorrectedDatePipe = createAutoCorrectedDatePipe(DATE_LOWER);
  myOptions: INgxMyDpOptions;
  edit: boolean;
  value: any;

  constructor() {
    this.edit = false;
  }

  ngOnInit() {
    this.myOptions = {
      dateFormat: DATE_LOWER,
      selectorWidth: '165px',
      selectorHeight: '160px',
      appendSelectorToBody: true
    };
  }

  /**
   *
   * @param {IMyDateModel} event
   */
  onDateChanged(event: IMyDateModel): void {
    this.value = event;
    this.dateEmitter.emit({dateObject: this.value});
    this.edit = !this.edit;
    this.writeValue(this.value);
  }

  // Function to call when the rating changes.
  onChange = (event: IMyDateModel) => {};
  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  /**
   *
   * @param keyEvent
   */
  sendDate(keyEvent): void {
    if (keyEvent.keyCode === 13) {
      this.dateEmitter.emit({dateObject: this.value});
      this.edit = !this.edit;
    }
  }

  toggleCal(): void {
    this.edit = !this.edit;
    const that = this;
    setTimeout(function () {
      that.dp.toggleCalendar();
      that.dp.elem.nativeElement.select();
    }, 10);
  }

  checkCalendar(): void {
    const that = this;
    setTimeout(function () {
      if (that.dp.cRef === null) {
        that.edit = !that.edit;
      }
    }, 10);
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(value: any): void {
    this.onChange(value)
  }
  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
