import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Company } from '@models/company';

@Injectable({
  providedIn: 'root'
})
export class LinkedEntitiesService {

  constructor(
    private apiService: ApiService,
  ) {}

  createLinkedEntity(primaryEntityUuid: string, linkedEntityUuid: string): Observable<any> {
    return this.apiService.send('Post', '/api/linked_entities', {
      primaryEntityUuid: primaryEntityUuid,
      linkedEntityUuid: linkedEntityUuid,
    }).pipe(map(data => <Company>data.response.objects.pop()));
  }

  deleteLinkedEntity(primaryEntityUuid: string, linkedEntityUuid: string): Observable<any> {
    return this.apiService.send('Delete', `/api/linked_entities/${primaryEntityUuid}/${linkedEntityUuid}`).pipe(map(data => <Company>data.response.objects.pop()));
  }

  getLinkedEntities(primaryEntityUuid: string): Observable<any> {
    const data = {
      filter: {
        'primary_entity_id_eq': primaryEntityUuid,
      }
    };

    return this.apiService.send('POST', '/api/linked_entities/all', data).pipe(
      map(data => data.response.objects));
  }

}
