import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-current-working-capital-table-tile',
  templateUrl: './current-working-capital-table-tile.component.html',
  styleUrls: ['./current-working-capital-table-tile.component.scss']
})
export class CurrentWorkingCapitalTableTileComponent implements OnInit {
  @Input() analyticsData = [];
  @Input() mostRecentStatementDate = '';
  @Input() currencySymbol = '';
  graphData: Array<number> = [];

  constructor(
    public analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.getGraphData();
  }

  getGraphData(): void {
    this.graphData = this.analyticsService.arrayOfValues('REF_WORKING_CAPITAL', this.analyticsData);
    return;
  }

}
