<div
    *ngIf="isCccGraphDataAvailable()"
    class="tile-container extra-padding"
>
    <div class="row">
        <div class="col-6">
            <h4>Cash Conversion Cycle</h4>
        </div>
        <div class="col-6">
            <div class="align-right">
                <h3>{{value}}</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 small-gray">
            {{periodType}} | {{mostRecentStatementDate}}
        </div>
        <div class="col-6">
            <div class="align-right small-gray">
                <!-- {{percentChange}} YOY -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-bar-chart
                [legendOptions]="['CCC']"
                [data]="cccGraphData"
                unitsType="days"
            ></app-bar-chart>
        </div>
    </div>
</div>
