import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../utils/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-add-fye-change',
  templateUrl: './add-fye-change.component.html',
  styleUrls: ['./add-fye-change.component.css']
})
export class AddFyeChangeComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Output() callback = new EventEmitter<string>();
  selectedFyeDate: string;
  initialDate: string;

  constructor() { }

  ngOnInit() {
    this._setInitialValues();
  }

  updateFyeDate(event): void {
      this.selectedFyeDate = event.dateObject.formatted;
  }

  addFye() {
    const fyeDate = moment(this.selectedFyeDate, DATE_FORMAT).format(SERVER_DATE_FORMAT);
    this.callback.emit(fyeDate);
    this.popup.close();
  }

  private _setInitialValues(): void {
    const today = new Date();
    this.selectedFyeDate = moment(today).format(DATE_FORMAT);
  }

}
