import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DocumentFileCreateRequest } from '../../../../models/document-file-create-request';
import { DocumentFileCreateRequestState, DocumentType } from '../../../../utils/enums';
import { SUPPORTED_TAX_FORMS, SUPPORTED_TAX_YEARS } from '../../../../utils/constants';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-portal-borrower-document-submission',
  templateUrl: './document-submission.component.html',
  styleUrls: ['./document-submission.component.scss'],
})
export class DocumentSubmissionComponent implements OnChanges {
  @Input() submission: DocumentFileCreateRequest;
  @Output() onCancelSubmission: EventEmitter<DocumentFileCreateRequest> = new EventEmitter();

  showUploadProgress = true;
  showDocumentForm = false;
  selectedTypes = [];
  isTaxReturnSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isTaxReturnSelectedObs: Observable<boolean> = this.isTaxReturnSelected.asObservable();
  DocumentType = DocumentType;
  SUPPORTED_TAX_FORMS = SUPPORTED_TAX_FORMS;
  SUPPORTED_TAX_YEARS = SUPPORTED_TAX_YEARS;

  public states = DocumentFileCreateRequestState;
  constructor(
  ) {
  }

  ngOnChanges(): void {
    this.showUploadProgress = true;
    this.showDocumentForm = this.submission.state === DocumentFileCreateRequestState.FileUploaded;
  }

  setStatementType(type) {
    this.isTaxReturnSelected.next(false);
    if (type === DocumentType.TaxReturn) {
      if (this.selectedTypes.includes(DocumentType.TaxReturn)) {
        this.selectedTypes = [];
        this.isTaxReturnSelected.next(false);
      } else {
        this.selectedTypes = [];
        this.selectedTypes.push(DocumentType.TaxReturn);
        this.submission.taxFormType = this.SUPPORTED_TAX_FORMS[0];
        this.submission.statementDate = this.SUPPORTED_TAX_YEARS[0];
        this.isTaxReturnSelected.next(true);
      }
    } else if (type === DocumentType.Other) {
      if (this.selectedTypes.includes(DocumentType.Other)) {
        this.selectedTypes = [];
      } else {
        this.selectedTypes = [];
        this.selectedTypes.push(DocumentType.Other);
      }
    } else {
      if (this.selectedTypes.includes(DocumentType.Other)) {
        this.selectedTypes.splice( this.selectedTypes.indexOf(DocumentType.Other), 1 );
      }
      if (this.selectedTypes.includes(DocumentType.TaxReturn)) {
        this.selectedTypes.splice( this.selectedTypes.indexOf(DocumentType.TaxReturn), 1 );
      }
      if (this.selectedTypes.includes(type)) {
        this.selectedTypes.splice( this.selectedTypes.indexOf(type), 1 );
      } else {
        this.selectedTypes.push(type);
      }
    }
    this.submission.documentType = this.selectedTypes.join(',');
    this.submission.roundedToThousands = false;
  }

  updateTaxForm(taxFormName: string): void {
    this.submission.taxFormType = taxFormName;
  }

  updateTaxYear(taxYear: string): void {
    this.submission.statementDate = taxYear;
  }

  cancelSubmission(): void {
    try {
      this.submission.fileItem.cancel();
    } catch (ex) {/* pass */}

    this.onCancelSubmission.emit(this.submission);
    }
}
