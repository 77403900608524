import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopoverComponent implements AfterViewInit {
  @Input() data: any;
  @ViewChild('popover', { static: true }) popover: ElementRef;
  // @HostBinding('style.top.px') top = '10';
  // @HostBinding('style.left.px') left = '100';
  top: number;
  left: number;
  style = {'top': '0', 'left': '0', 'visibility': 'hidden'};

  constructor() {
  }

  ngAfterViewInit(): void {
    const bounding: DOMRectInit = document.querySelector(this.data.target).getBoundingClientRect();
    this.popover.nativeElement.style.left = bounding.x + bounding.width + 10 + 'px';
    this.popover.nativeElement.style.top = bounding.y + bounding.height / 2 - this.popover.nativeElement.offsetHeight / 2 + 'px';
    this.popover.nativeElement.style.visibility = 'visible';
  }

  onPopoverClick = () => {
    event.stopPropagation();
  }
}

