<div>
  <div class="modal_header">
      <i class="fa fa-book header-icon"></i>
      <p>{{HEADER_TEXT}}</p>
  </div>
  <div class="modal__content">
      <div *ngIf="showSpinner; else modalContent" class="loader__container">
          <div class="loader"></div>
      </div>
      <ng-template #modalContent>
          <div class="panel-header">
            <p>{{helperText}}</p>
          </div>
          <div class="table-container">
            <table class="table table-hover">
              <thead>
                <tr class="sorting-header">
                    <th class="col-md-6">EVENT</th>
                    <th class="col-md-3">USER</th>
                    <th class="col-md-3">DATETIME</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of changeLogs">
                    <td>{{log.callDescription}}</td>
                    <td>{{log.userLabel ? log.userLabel : '-'}}</td>
                    <td>{{log.changedDate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
      </ng-template>
  </div>
</div>
<div class="modal__footer">
      <button type="reset" class="btn btn-link" (click)="closeModal();">Cancel</button>
</div>
