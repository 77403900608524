import { Deserializable } from './deserializable';

export class ReportColumn implements Deserializable {
  itemId: number;
  label: string;
  aggregation = '';
  useForSorting = false;
  sortAsc = true;

  deserialize(data): this {
    Object.assign(this, data);
    return this;
  }
}

export class Report implements Deserializable {
  companyIds: number[] = [];
  portfolioIds: number[] = [];
  dateStart: Date = null;
  dateEnd: Date = null;
  reportingIntervals: string[] = [];
  preparationTypes: string[] = [];
  columns: ReportColumn[] = [];
  groupBy = '';
  latestFinancialsForBorrower = true;
  deserialize(data): this {
    Object.assign(this, data);
    this.columns = this.columns.map(c => new ReportColumn().deserialize(c));
    return this;
  }
}
