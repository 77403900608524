import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { User, AppUser } from '../models/user';
import { ApiService } from './api.service';
import { LaunchDarklyService } from '@services/launchdarkly.service';
import CriteriaBuilder from '../utils/query/criteria';
import { TrackingService } from './tracking.service';
import { SharedDataService } from '@services/shared-data.service';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { BYPASS_CACHE, SWITCH_ON_PROFILER } from '../utils/constants';
import { LoggingService, Logger } from './logging.service';
import * as Sentry from '@sentry/angular-ivy'

@AutoUnsubscribe('_subsArr$')
@Injectable()
export class UserService {

  /**
   * current logged in user
   */
  user: User;
  sentry = Sentry;
  logger: Logger;
  private _subsArr$: Subscription[] = [];

  constructor(
    private _apiService: ApiService,
    private _jwtService: JwtHelperService,
    private trackingService: TrackingService,
    private _sharedDataService: SharedDataService,
    private _launchDarklyService: LaunchDarklyService,
    public loggingService: LoggingService
  ) {
    this.logger = this.loggingService.rootLogger.newLogger('UserService');
  }

  public getCurrentUser(force: boolean = false): Observable<any> {
    if (this.user && !force) {
      return of(this.user);
    }

    return this._apiService
      .send('Post', '/api/users/me', {})
      .pipe(
        map(data => {

          const decoded = data['user'];
          if (!decoded) {
            this.user = new AppUser({});
          } else {
            if (decoded.hasOwnProperty('suk')) {
              decoded['username'] = null;
              decoded['bank_id'] = decoded['tenant_name']
              decoded['id'] = decoded['suk'];
              decoded['name'] = null;
            } else {
              decoded['username'] = decoded['user_name'];
              decoded['bank_id'] = decoded['tenant_name']
              decoded['id'] = decoded['user_id'];
              decoded['name'] = decoded['user_name'];
            }
            decoded['internalAdmin'] = false;
            this.user = new AppUser(decoded);

            const identifyAttrs = {
              name: this.user.name,
              email: this.user.email,
              bank_id: this.user.bank_id,
              username: this.user.username
            };

            if (decoded['state'] && decoded['state']['eui']) {
              identifyAttrs['external_user_id'] = decoded['state']['eui'];
            }

            if (decoded['state'] && decoded['state']['eul']) {
              identifyAttrs['external_user_label'] = decoded['state']['eul'];
            }

            this.trackingService.identify(this.user.id, identifyAttrs);

            if (!!this.user.user_sub) {
              this._launchDarklyService.setUser(this.user);
            }
          }
          this.user.redisSessionIsExpired = data['session_is_expired'];

          this.setLoggingServiceTags();
          return of(this.user);
      }));
  }

  /**
   *
   * @param {boolean} force
   * @returns {User}
   */
  decodeUserFromJwt(force = false): User {
    if (this.user && !force) {
      return this.user;
    }
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const decoded = this._jwtService.decodeToken(token);
    if (!decoded) {
      this.user = new AppUser({});
    } else {
      decoded['username'] = decoded['user_name'];
      decoded['bank_id'] = decoded['tenant_name']
      decoded['id'] = decoded['user_id'];
      decoded['name'] = decoded['user_name'];
      decoded['internalAdmin'] = false;
      this.user = decoded;

      this.trackingService.identify(this.user.id, {
        name: this.user.name,
        email: this.user.email,
        bank_id: this.user.bank_id,
        username: this.user.username,
      })
      this._launchDarklyService.setUser(this.user);
    }

    this.setLoggingServiceTags();
    return this.user;
  }


  setLoggingServiceTags(): void {
    if (!this.user) {
      return;
    }

    this.loggingService.rootLogger.metadata._userdata = {
      user: this.user.username,
      user_id: this.user.id,
      email: this.user.email,
      id: this.user.id,
      bank: this.getBankId(),
    };

    this.sentry.configureScope((scope) => {
      const user = {
        user: this.user.username,
        user_id: this.user.id,
        email: this.user.email,
        id: this.user.id,
        bank: this.getBankId(),
      };

      scope.setUser(user);

      // user related tags
      scope.setTag('user', this.user.username);
      scope.setTag('user_id', this.user.id);
      scope.setTag('email', this.user.email);
      scope.setTag('id', this.user.id);
      scope.setTag('bank', this.getBankId());
    });
  }

  getBankId() {
    if (this.user && this.user.bank_id) {
      return this.user.bank_id;
    }
    console.error('no bank id yet!');
  }

  getUserEntitlementForCompany(companyUuid: string) {
    const payload = {
      companyUuid: companyUuid,
    }
    return this._apiService.send('Post', '/api/user-company-entitlement/get_user_entitlement_for_company', payload).pipe(
      map(data => data.response.objects[0]));
  }

  getEntitlementDataBasedOnReviewQueueItem(rqiUuid: string, statementUuid: string) {
    const payload = {
      rqiUuid: rqiUuid,
      statementUuid: statementUuid,
    }
    return this._apiService.send('Post', '/api/user-company-entitlement/get_entitlement_data_based_on_review_queue_item', payload).pipe(
      map(data => data.response.objects[0]));
  }

  /**
   *
   * @param email
   * @returns {Observable<any>}
   */
  getUser(email): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.contains('email', email));
    return this._apiService.send('Post', '/api/users/all', query.generate()).pipe(
      map(data => data.response.objects.pop()));
  }

  /**
   *
   * @param {number[]} ids
   * @returns {Observable<any>}
   */
  getUsers(ids: number[]): Observable<any> {
    return this._apiService.send('Post', '/api/users/names', { userIds: ids }).pipe(
      map(data => data.response.objects));
  }

  /**
   *
   * @param {User} user
   * @returns {Observable<any>}
   */
  updateUser(user: User) {
    return this._apiService.send('Put', `/api/users/${user.id}`, user).pipe(
      map(data => data.response.objects));
  }

  /**
     *
     * @param {User} user
     * @returns {Observable<any>}
     */
  setUserStatus(user: User) {
    return this._apiService.send('Patch', `/api/users/status/${user.id}`, user).pipe(
      map(data => data.response.objects));
  }

  forcePasswordReset(user: User) {
    return this._apiService.send('Post', `/api/users/force_password_reset`, {userEmail: user.email}).pipe(
      map(data => data.response.objects));
  }


  /**
  *
  * @param {User} user
  * @returns {Observable<any>}
  */
  addUser(user: User) {
    return this._apiService.send('Post', '/api/users', user).pipe(map(data => data.response.objects.pop()));
  }

  /**
   *
   * @param id
   * @returns {Observable<any>}
   */
  deleteUser(id) {
    return this._apiService.send('Delete', '/api/users/' + id);
  }

  updateUserActiveTenant(tenantName: string): Observable<any> {
    return this._apiService.send('Put', `/api/users/me/active_tenant/${tenantName}`);
  }

  /**
   *
   * @param passwords
   * @param {number} user_id
   * @returns {Observable<any>}
   */
  updatePassword(passwords, user_id: string) {
    return this._apiService.send('Post', `/api/users/${user_id}/password`, passwords);
  }

  hasMultipleTenants(): boolean {
    if (!this.userLoaded() || !this.userTenantsPopulated())
      return false;
    return Object.keys(this.user.user_tenants).length > 1;
  }

  toggleDevToolSetting(item: string,
    behaviorSubj: BehaviorSubject<boolean>): void {
    const currentValue = localStorage.getItem(item);
    let newValue = 'false';
    let result = false;
    if (currentValue !== 'true') {
      newValue = 'true';
      result = true;
    } else {
      newValue = 'false';
      result = false;
    }
    localStorage.setItem(item, newValue.toString());
    behaviorSubj.next(result);
  }

  toggleBypassBackendCache(): void {
    this.toggleDevToolSetting(BYPASS_CACHE,
      this._sharedDataService.bypassBackendCache$);
  }

  toggleBackendProfiler(): void {
    this.toggleDevToolSetting(SWITCH_ON_PROFILER,
      this._sharedDataService.switchOnProfiler$);
  }

  /*
   * @deprecated - These values are set to true in LaunchDarkly so we can simply return true here to show their
   * content to all users while we remove all references.
   */
  isManualReviewer(): boolean {
    return !!this.user;
  }

  /*
   * @deprecated - These values are set to true in LaunchDarkly so we can simply return true here to show their
   * content to all users while we remove all references.
   */
  isSpreader(): boolean {
    return !!this.user;
  }

  /**
   *
   * @param {number} page
   * @param {number} limit
   * @param searchObject
   * @param sort
   * @returns {Observable<any>}
   */
  getAll(page: number, limit: number, searchObject: any, sort: any) {
    return this._apiService.send('Post', '/api/users/all', this.getFilters(page, limit, searchObject, sort));
  }

  getFilters(page: number, limit: number, searchObject: any, sort: any) {
    // This logic is currently more complicated than is necessary to optionally add a single email filter to the request.
    // I'm leaving it here since we expect to add additional search fields soon. (this logic is based on the borrower
    // search service).
    sort = sort.field + '.' + (sort.dir ? 'asc' : 'desc')
    let filters = {
      filter: {
        'email_icontains': searchObject.email
      },
      page: page,
      limit: limit,
      sort: sort,
      ignoreLoading: false
    };

    // Removes all filters not being used for this query
    for (const item in filters.filter) {
      if (item && (filters.filter[item] === '' || filters.filter[item] === ' ')) {
        delete filters.filter[item];
      }
    }

    return filters;
  }

  setBankIdContext(bank_id) {
    localStorage.setItem('bank_id', bank_id);
    this._sharedDataService.bankId$.next(bank_id);
  }

  private userLoaded(): boolean {
    return !!this.user;
  }

  private userTenantsPopulated(): boolean {
    return !!this.user.user_tenants;
  }
}
