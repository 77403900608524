import { Component, OnInit, Input } from '@angular/core';
import { Company } from '../../../models/company';
import { SharedDataService } from '../../../services/shared-data.service';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { COMPANY_ENTITLEMENT_DATA } from '@utils/constants';
import { Router } from '@angular/router';
import {LaunchDarklyService} from "@services/launchdarkly.service";

@Component({
  selector: 'app-borrower-nav',
  templateUrl: './borrower-nav.component.html',
  styleUrls: ['./borrower-nav.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class BorrowerNavComponent implements OnInit {
  @Input() entitlementData;
  subsArr$: Subscription[] = [];
  company: Company;
  isCovenantsPageShown = false;
  showAddStatementButton: boolean;
  showAnalysisTab = true;

  constructor(private _sharedDataService: SharedDataService,
              private _router: Router,
              private _launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.showAddStatementButton = this.entitlementData[COMPANY_ENTITLEMENT_DATA.SHOW_ADD_STATEMENT_BUTTON];

    this.subsArr$.push(
      this._sharedDataService.company$.subscribe(company => {
        this.company = company;
      })
    );

    this._launchDarklyService.flagChange.subscribe(() => {
      this.showAnalysisTab = this._launchDarklyService.flags['display-analysis-tab']
    })
    this.showAnalysisTab = this._launchDarklyService.flags['display-analysis-tab']
  }

  isSpreading(): boolean {
    return window.location.href.includes('/spread')
  }
}
