import { Injectable } from '@angular/core';
// @ts-ignore
import versionJson from '../../assets/files/version.json';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CommonFunctions } from '../utils/common-functions';

@Injectable()
export class ForceReloadService {
  constructor(
    private _apiService: ApiService
  ) {
  }

  getNewestAppVersion(): Observable<any> {
    const versionFileLocation = './assets/files/version.json?ts=' + Date.now();
    return this._apiService.getFile(versionFileLocation);
  }

  isNewVersionAvailable(packageVersion: string, newestVersion: string): boolean {
    if (!packageVersion || !newestVersion) { // maybe null or undefined or blank for some reason
      return false;
    }

    const trimmedPackageVersion = packageVersion.trim();
    const trimmedNewestVersion = newestVersion.trim();

    if (trimmedPackageVersion === '0.0.0' || trimmedNewestVersion === '0.0.0') { // possible broken or odd behvaior in build process
      return false;
    }

    if (trimmedPackageVersion === trimmedNewestVersion) {
      return false;
    }

    return true;
  }

  refreshBrowser(myLocation = location): void {
    console.log('refreshBrowser called');
    myLocation.reload();
    // alternative is set window.location to the correct location
    // and add a get varible version= so we can make sure we don't double reload
    // if something goes wrong
    // for now, going to go this way w/ internal testing and turning on for
    // only a few select fincura employees.
  }

  refreshIfAvailable(): void {
    this.getNewestAppVersion().subscribe( data => {
      // @ts-ignore
      const newestVersion = data.version;
      if (this.isNewVersionAvailable(versionJson.version, newestVersion)) {
        console.log('isNewVersionAvailable true. About to refresh browser');
        this.refreshBrowser();
      }
    }, error => {
      console.log('error found');
      console.log(error);
    });
  }

}
