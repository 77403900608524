import {Subscription} from 'rxjs';
import {Component, Input, OnInit} from '@angular/core';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {NgxPopupComponent} from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import {SpreadingTemplateService} from '@services/spreading-template.service';
import {AlertService} from '@services/alert.service';
import {TemplateItem} from "@models/template-item";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export type TargetSectionOption = {
  standardLineItemId: number,
  label: string
}

@Component({
  selector: 'app-item-to-das-section-popup',
  templateUrl: './add-item-to-das-section-popup.component.html',
  styleUrls: ['./add-item-to-das-section-popup.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class AddItemToDASSectionPopupComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() item: TemplateItem;
  @Input() dasSectionOptions: TargetSectionOption[];
  subsArr$: Subscription[] = [];
  title = 'ADD ITEM TO DAS TEMPLATE SECTION';
  textFormatOptions = ['NUMBER', 'RATIO', 'PERCENT', 'DAYS'];
  form: FormGroup;

  constructor(
    private spreadingTemplateService: SpreadingTemplateService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = new FormGroup<any>({
      selectedTargetSectionLineItemId: new FormControl<number>(null, [Validators.required]),
      selectedItemType: new FormControl<'NonAdjustableRawAddBack' | 'AdjustableRawAddBack' | 'AsReported'>(null, [Validators.required]),
      selectedTextFormat: new FormControl<string>('NUMBER', [Validators.required]),
      label: new FormControl<string>(this.item.label, [Validators.required]), // set the default to match the field that's being cloned
    });
  }

  addItemToDASSection() {
    const selectedItemType = this.form.controls.selectedItemType.value;
    let getItemApiCall;
    if (selectedItemType === 'AdjustableRawAddBack') {
      getItemApiCall = this.spreadingTemplateService.generateAdjustableRawAddBackDASItem(this.item.ref, this.form.controls.label.value, this.form.controls.selectedTextFormat.value)
    } else if (selectedItemType === 'NonAdjustableRawAddBack') {
      getItemApiCall = this.spreadingTemplateService.generateNonAdjustableRawAddBackDASItem(this.item.ref, this.form.controls.label.value, this.form.controls.selectedTextFormat.value)
    } else if (selectedItemType === 'AsReported') {
      getItemApiCall = this.spreadingTemplateService.generateAsReportedDASItem(this.item.ref, this.form.controls.label.value, this.form.controls.selectedTextFormat.value)
    } else {
      // We should never get here
      throw `Unsupported Item type: ${selectedItemType}`;
    }
    this.subsArr$.push(getItemApiCall
      .subscribe(
        item => {
          const data = {
            item: item,
            targetSectionStandardLineItemId: this.form.controls.selectedTargetSectionLineItemId.value
          }
          this.popup.close(true, data);
          this.alertService.success('Section created successfully');
        },
        error => {
          this.alertService.error(error.message);
        },
      ));
  }
}
