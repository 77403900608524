import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { Company } from '../../../models/company';
import { Loan } from '../../../models/loan';
import { LoanService } from '../../../services/loan.service';
import { AlertService } from '../../../services/alert.service';
import { DATE_FORMAT, COVENANT_FREQUENCY_OPTIONS } from '../../../utils/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-non-financial-covenant-form',
  templateUrl: './non-financial-covenant-form.component.html',
  styleUrls: ['./non-financial-covenant-form.component.css']
})
export class NonFinancialCovenantFormComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company = null;
  @Input() formType = '';

  // @ts-ignore
  loans: Array<Loan>  = [ { id: 0, name: 'First Loan'} ];
  covenantRules = [];

  nonFinancialCovenantForm: FormGroup;
  submitted = false;
  frequencyOptions = COVENANT_FREQUENCY_OPTIONS;
  isOtherPeriodsDatepickerShown = false;
  todaysDate = '';

  rules = [
    { id: 'RECEIVED', label: 'Received' },
    { id: 'COMPLETED', label: 'Completed' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private _loanService: LoanService,
    private _alertService: AlertService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.setForm();

    if (!this.company) {
      // error 404 redirect here
      return;
    }

    this._loanService.getLoans(this.company.id).subscribe( (data) => {
      this.loans = data;
    });

    this._setInitialValues();
  }

  setForm(): void {
    this.nonFinancialCovenantForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      daysToReport: ['', []],
      loan: ['', []],
      rule: ['', []],
      timeline: ['', []],
      description: ['', []],
      frequency: ['', []],
    });
  }

  save(nonFinancialCovenantForm: any) {
    this.submitted = true;

    if (nonFinancialCovenantForm.name) {
      nonFinancialCovenantForm.name = nonFinancialCovenantForm.name.trim();
    }

    if (this.isOtherPeriodsDatepickerShown) {
      nonFinancialCovenantForm.timeline = nonFinancialCovenantForm.timeline.formatted;
    } else {
      nonFinancialCovenantForm.timeline = 'LIFE_OF_LOAN';
    }

    this._loanService.createNonFinancialCovenant(nonFinancialCovenantForm).subscribe(
      data => {
        this.submitted = false;
        this.popup.close(true, true);
      }, error => {
        this._alertService.error(error.message);
        this.submitted = false;
      }
    );
  }

  showOtherPeriodsTextbox(shouldShow: boolean): void {
    this.isOtherPeriodsDatepickerShown = shouldShow;
  }

  private _setInitialValues(): void {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '/' + today.getDate();
    this.todaysDate = moment(date).format(DATE_FORMAT);
  }


}
