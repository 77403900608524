import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SourceComment } from '@components/main/spreading/models/source-comment';
import { ApiService } from '@services/api.service';
import { ApiResponse } from '@models/api-response';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  shouldShowCommentComponent: Observable<boolean>;
  private shouldShowCommentComponent$: Subject<boolean> = new Subject();

  constructor(private apiService: ApiService) {
    this.shouldShowCommentComponent = this.shouldShowCommentComponent$.asObservable();
  }

  commentIsPopulated(sourceComment: string): boolean {
    return sourceComment !== undefined && sourceComment !== null;
  }

  showCommentComponent(): void {
    this.shouldShowCommentComponent$.next(true);
  }

  hideCommentComponent(): void {
    this.shouldShowCommentComponent$.next(false);
  }

  createComment(
    documentFileId: number,
    sourceComment: SourceComment
  ): Observable<ApiResponse<SourceComment>> {
    return this.apiService
        .send('POST', `/api/document-file/${documentFileId}/source-comment`, sourceComment);
  }

  updateComment(
    documentFileId: number,
    sourceComment: SourceComment
  ): Observable<ApiResponse<SourceComment>> {
    return this.apiService
      .send('PUT', `/api/document-file/${documentFileId}/source-comment/${sourceComment.id}`, sourceComment);
  }
}
