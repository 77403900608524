import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonFunctions } from '../../../utils/common-functions';
import { DocumentFileService } from '../../../services/document-file.service';
import { AlertService } from '../../../services/alert.service';
import { EnvironmentService } from '../../../services/environment.service';
import { DocumentFileCreateRequest } from '../../../models/document-file-create-request';
import { SignedUrl } from '../../../models/signed-url';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { BankSettingsService } from '../../../services/bank-settings.service';

@Component({
  selector: 'app-portal-borrower-upload',
  templateUrl: './borrower-upload.component.html',
  styleUrls: ['./borrower-upload.component.scss'],
})

@AutoUnsubscribe('subsArr$')
export class BorrowerUploadComponent implements OnInit {
  signedUrl: SignedUrl = new SignedUrl();
  documentSubmissions: DocumentFileCreateRequest[] = [];
  companyId: number;
  bankId: string;
  text: string;
  documentRequestOptions: object;
  periodType: string;
  periodDisplayName: string;
  requestedStatementDates: string;
  statementDate: string;
  customBankImg: string;
  supportEmail: string;
  primaryColor: string;
  submitting = false;
  submittingProgress: number;
  complete = false;
  subsArr$: Subscription[] = [];
  loading = true;
  title: string;

  constructor(private _jwtService: JwtHelperService,
    private _alert: AlertService,
    private _documentFileService: DocumentFileService,
    private _environmentService: EnvironmentService,
    private _bankSettingsService: BankSettingsService) {
  }

  ngOnInit(): void {
    this.signedUrl.jwt = CommonFunctions.getQueryParameterByName('jwt');
    const decodedJwt = this._jwtService.decodeToken(this.signedUrl.jwt);
    this.companyId = decodedJwt.company_id;
    this.bankId = decodedJwt.bank_id;
    this.documentRequestOptions = decodedJwt.state;
    this.periodType = this.documentRequestOptions['selected_period_type'];
    this.formatPeriodType(this.periodType);

    // The logic here will change in a followering iteration with changes planned for the request statements modal
    if (this.documentRequestOptions['multi_month']) {
      this.requestedStatementDates = this.documentRequestOptions['selected_statement_date_from'] + '-' + this.documentRequestOptions['selected_statement_date_to'];
      this.statementDate = this.documentRequestOptions['selected_statement_date_from'];
    } else {
      this.requestedStatementDates = this.documentRequestOptions['selected_statement_date'];
      this.statementDate = this.documentRequestOptions['selected_statement_date'];
    }

    const environment = this._environmentService.getEnvConfig();
    if (!environment) {
      this.subsArr$.push(
        this._environmentService.loadEnvConfig(false, this.signedUrl, true).subscribe(() => {
          this._initUI();
        }, (err) => {
          this._alert.error('Sorry, an error occurred! Please try again.');
        })
      );
    }
  }

  _initUI() {
    this.loading = false;

    this.subsArr$.push(this._bankSettingsService.getPortalSettings(this.signedUrl).subscribe((data) => {
      this.setCustomSettings(data);
    }, (err) => {
      this._alert.error('Sorry, an error occurred! Please try again.');
    }));
  }

  setCustomPageTitle(title) {
    this.title = this.bankId.charAt(0).toUpperCase() + this.bankId.slice(1);
    if (title) {
      document.getElementById('appTitle').innerText = title;
    } else {
      document.getElementById('appTitle').innerText = this.title + ' Portal';
    }
  }

  formatPeriodType(period) {
    if (period === 'SEMI_ANNUALLY') {
      this.periodDisplayName = 'SEMIANNUAL';
    } else if (period === 'ANNUALLY') {
      this.periodDisplayName = 'ANNUAL';
    } else {
      this.periodDisplayName = period;
    }
  }

  setCustomSettings(settings) {
    this.setCustomPageTitle(settings['_portalSettings']['pageTitle']);
    this.setCustomImg(settings['_bankLogos']['large']);
    this.setCustomText(settings['_portalSettings']['text']);
    this.setCustomColor(settings['_portalSettings']['color']);
    this.setCustomSupportEmail(settings['_portalSettings']['supportEmail']);
    this.setCustomFavIcon(settings['_bankLogos']['ico']);
  }

  setCustomSupportEmail(email) {
    if (email) {
      this.supportEmail = email;
    } else {
      this.supportEmail = 'support@support';
    }
  }

  setCustomColor(color) {
    if (color) {
      this.primaryColor = color;
    } else {
      this.primaryColor = '#3A5D8D';
    }
  }

  setCustomText(text) {
    if (text) {
      this.text = text;
    } else {
      this.text = 'Default text';
    }
  }

  setCustomImg(img) {
    if (img) {
      this.customBankImg = img;
    } else {
      this.customBankImg = '/assets/images/Numerated-2023-logo.svg';
    }
  }

  setCustomFavIcon(favIconUrl) {
    if (favIconUrl) {
      document.getElementById('appIcon').setAttribute('href', favIconUrl);
    }
  }

  onFileChoosen(event: any): void {
    const docFileCreateRequest = new DocumentFileCreateRequest();
    docFileCreateRequest.originalDocumentName = event.fileItem.file.name;
    docFileCreateRequest.companyId = this.companyId;
    docFileCreateRequest.statementDate = this.statementDate;
    docFileCreateRequest.periodType = this.periodType;
    docFileCreateRequest.uploadProgress = 0;
    docFileCreateRequest.uploader = event.uploader;
    docFileCreateRequest.fileItem = event.fileItem;
    docFileCreateRequest.documentType = '';
    this.documentSubmissions.push(docFileCreateRequest);
    this.subsArr$.push(this._documentFileService.uploadDocumentFile(docFileCreateRequest, this.signedUrl).subscribe(
      progress => { },
      e => {
        throw Error('Error uploading file via portal. Most likely CORS failure. Error: ' + e);
      },
      () => { }
    ));
  }

  cancelSubmission(submission: DocumentFileCreateRequest) {
    for (let i = 0; i < this.documentSubmissions.length; i++) {
      if (this.documentSubmissions[i].s3FileKey === submission.s3FileKey) {
        this.documentSubmissions.splice(i, 1);
        break;
      }
    }
  }

  validForm(): boolean {
    let error = false;

    this.documentSubmissions.forEach(docFile => {
      if (docFile.documentType.length === 0) {
        error = true;
        this._alert.error('Missing document type for file ' + docFile.originalDocumentName);
      }
    });

    if (error) {
      return false;
    } else {
      return true;
    }
  }

  submitDocuments() {
    if (this.validForm()) {
      this.submitting = true;
      this.submittingProgress = 0;
      this.subsArr$.push(this._documentFileService.createDocumentFiles(this.documentSubmissions, this.signedUrl).subscribe(
        progress => {
          this.submittingProgress = progress;
        },
        e => {
          this._alert.error('Error saving documents');
          this.submitting = false;
        },
        () => {
          this.submitting = false;
          this.complete = true;
        }
      ));
    }
  }
}
