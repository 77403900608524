import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Cell, Row } from '@components/main/spreading/models/models';
import { CommentService } from '@services/shared/comment/comment.service';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';
import { SourceComment } from '@components/main/spreading/models/source-comment';
import { SpreadingState } from '@components/main/spreading/state/manager';
import { ApiResponse } from '@models/api-response';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {
  cellWithComment: Cell;
  rowWithCell: Row;
  shouldBeDisplayed: Observable<boolean>;

  @Input() spreadingState: SpreadingState;

  @Input()
  public set row(row: Row) {
    this.rowWithCell = row;
  }

  @Input()
  public set cell(cell: Cell) {
    this.cellWithComment = cell;
    if (!!this.cellWithComment?.sourceComments?.length) {
      this.backupSourceComment(this.cellWithComment.sourceComments[0]);
    }
  }
  public get cell() {
    return this.cellWithComment;
  }

  @Output() saveComment: EventEmitter<any> = new EventEmitter<any>();

  private copyOfSourceCommentBeforeSave: SourceComment;

  constructor(
    private apiService: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private commentService: CommentService
  ) {
    this.shouldBeDisplayed = this.commentService.shouldShowCommentComponent;
  }

  cancel(): void {
    if (
      this.copyOfSourceCommentBeforeSave.comment === '' &&
      this.copyOfSourceCommentBeforeSave.uuid === null &&
      this.copyOfSourceCommentBeforeSave.id === null
    ) {
      this.cellWithComment.sourceComments.length = 0; // Empty the array.
    }
    this.commentService.hideCommentComponent();
  }

  saveSpreadingComment(): void {
    this.cellWithComment.sourceComments[0].reviewQueueItemId = this.spreadingState.reviewQueueItem.id;
    if (!!this.cellWithComment.sourceComments[0].uuid) {
      // The sourceComment.uuid is set which means we are updating an existing comment.
      this.commentService.updateComment(
        this.spreadingState.statement.documentFileId,
        this.cellWithComment.sourceComments[0]
      )
        .pipe(
          tap(_ => {
            // This is a hack to get the comment component to hide due to the way we are manually running change
            // detection throughout the application.
            this.commentService.hideCommentComponent();
            this.changeDetectorRef.detectChanges();
          })
        )
        .subscribe((_: ApiResponse<SourceComment>) => {
          this.backupSourceComment(this.cellWithComment.sourceComments[0]);
          this.saveComment.emit();
        });
      return;
    }

    this.populateSourceComment(this.cellWithComment.sourceComments[0], this.spreadingState);

    // The sourceComment.uuid is not set which means we are creating a new comment.
    this.commentService.createComment(
      this.spreadingState.statement.documentFileId,
      this.cellWithComment.sourceComments[0]
    )
      .pipe(
        tap(_ => {
          // This is a hack to get the comment component to hide due to the way we are manually running change
          // detection throughout the application.
          this.commentService.hideCommentComponent();
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((apiResponse: ApiResponse<SourceComment>) => {
        this.cellWithComment.sourceComments[0].id = apiResponse.response.objects[0].id;
        this.cellWithComment.sourceComments[0].uuid = apiResponse.response.objects[0].uuid;
        this.backupSourceComment(this.cellWithComment.sourceComments[0]);
        this.saveComment.emit();
      });
  }

  private populateSourceComment(sourceComment: SourceComment, spreadingState: SpreadingState): void {
    sourceComment.sourcePageNumber = this.cellWithComment.sourceBox.pageNumber;

    sourceComment.sourceBoxUlcX = this.cellWithComment.sourceBox.ulc.x;
    sourceComment.sourceBoxUlcY = this.cellWithComment.sourceBox.ulc.y;
    sourceComment.sourceBoxUrcX = this.cellWithComment.sourceBox.urc.x;
    sourceComment.sourceBoxUrcY = this.cellWithComment.sourceBox.urc.y;
    sourceComment.sourceBoxLrcX = this.cellWithComment.sourceBox.lrc.x;
    sourceComment.sourceBoxLrcY = this.cellWithComment.sourceBox.lrc.y;
    sourceComment.sourceBoxLlcX = this.cellWithComment.sourceBox.llc.x;
    sourceComment.sourceBoxLlcY = this.cellWithComment.sourceBox.llc.y;

    sourceComment.statementId = spreadingState.statement.id;
  }

  private backupSourceComment(sourceComment: SourceComment): void {
      this.copyOfSourceCommentBeforeSave = {
        comment: sourceComment?.comment,
        id: sourceComment?.id,
        uuid: sourceComment?.uuid,
        reviewQueueItemId: sourceComment?.reviewQueueItemId,
        sourcePageNumber: sourceComment?.sourcePageNumber,
        sourceBoxUlcX: sourceComment?.sourceBoxUlcX,
        sourceBoxUlcY: sourceComment?.sourceBoxUlcY,
        sourceBoxUrcX: sourceComment?.sourceBoxUrcX,
        sourceBoxUrcY: sourceComment?.sourceBoxUrcY,
        sourceBoxLrcX: sourceComment?.sourceBoxLrcX,
        sourceBoxLrcY: sourceComment?.sourceBoxLrcY,
        sourceBoxLlcX: sourceComment?.sourceBoxLlcX,
        sourceBoxLlcY: sourceComment?.sourceBoxLlcY,
        statementId: sourceComment?.statementId
      };
  }
}
