import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataFrameGenerator } from '../models/dataframe-generator';
import { Template } from '../models/template';
import { DataFrameFormula } from '../models/dataframe-formula';
import { Period } from '../models/period';
import { DataFrame } from '../models/dataframe';
import { LineItemMatch } from '../models/line-item';
import {strings} from "@angular-devkit/core";

/**
 * The DataFrameService manages all data operations associated with
 * data frames, data views, and data frame generators
 */
@Injectable()
export class DataFrameService {
  constructor(private apiService: ApiService) {
  }

  /**
   * Loads a DataView (+ its data) according to the given parameters
   *
   * @param documentType
   * @param isRaw
   * @param companyId
   */
  getDataView(documentType: string, isRaw: boolean, companyId: number, reportingPeriod: string = null , preparationType: string = null): Observable<any> {
    const payload = {
      filter: {
        company_id_eq: companyId,
        generator__document_type_eq: documentType.toUpperCase(),
        generator__raw_eq: isRaw
      }
    }

    if (reportingPeriod) {
      payload['frame_reporting_period_eq'] = reportingPeriod;
    }

    if (preparationType) {
      payload['frame_preparation_type_eq'] = preparationType;
    }

    return this.apiService.send('Post', '/api/dataviews/all', payload).pipe(
      map(data => {
        if (data.response.objects.length > 0) {
          return data.response.objects[0]
        }
        return null;
      })
    );
  }

  getDataFrameGenerator(documentType: string, isRaw: boolean, companyId: number): Observable<DataFrameGenerator> {
    const payload = {
      filter: {
        company_id_eq: companyId,
        document_type_eq: documentType.toUpperCase(),
        raw_eq: isRaw,
      }
    }

    return this.apiService.send('Post', '/api/dataframegenerators/all', payload).pipe(map(data => {
      if (data.response.objects.length > 0) {
        return new DataFrameGenerator().deserialize(data.response.objects[0]);
      }
      return null;
    }));
  }

  saveDataFrameGenerator(dfg: DataFrameGenerator) {
    return this.apiService.send('Patch', '/api/dataframegenerators/' + dfg.id, dfg).pipe(map(data => {
      if (data.response.objects.length > 0) {
        return new DataFrameGenerator().deserialize(data.response.objects[0]);
      }
      return null;
    }));
  }

  applyTemplateToGenerator(dfg: DataFrameGenerator, template: Template, save: boolean = true) {
    const existingNames = dfg.getExistingItemNames();
    dfg.formulas = dfg.formulas.concat(template.operations.filter(operation => existingNames.indexOf(operation.name) === -1).map(operation => {
      return new DataFrameFormula().deserialize({
        lineItem: {
          'name': operation.name,
        },
        'equation': operation.hasOwnProperty('equation') ? operation.equation : '=0',
        'isPrebuiltEquation': operation.hasOwnProperty('equation'),
      })
    }));

    if (save) {
      return this.saveDataFrameGenerator(dfg);
    }
  }

  getAllFormulaVariables(companyId: number) {

    return this.apiService.send('Post', `/api/dataframes/options`, {
      'company_id': companyId,
    }).pipe(map((data: any) => data.response.objects));

  }

  deleteDataFrame(dataFrameId): Observable<any> {
    return this.apiService.send('Delete', `/api/dataframes/delete/${dataFrameId}`);
  }

  updateDataFrame(dataFrameId, lineItemId, value): Observable<any> {
    const payload = {
      'line_item_id': lineItemId,
      'value': value,
    };
    return this.apiService.send('Patch', `/api/dataframes/${dataFrameId}`, payload);
  }


  redoManualReview(dataFrameId): Observable<any> {
    return this.apiService.send('Patch', `/api/dataframes/${dataFrameId}/redo`, {});
  }

  /**
   * Responds with an array of strings, each one a reporting period like 2018.annually.1
   *
   * Presorted in descending order
   */
  getPeriodsWithData(companyId: number) {
    return this.apiService.send('Post', '/api/dataframes/periods', {
      'company_id': companyId,
    }).pipe(map((data: any) => data.response.objects.map(o => new Period().deserialize(o))));
  }

  getCommentary(companyId: number) {
    return this.apiService.send('Post', '/api/dataframes/commentary', {
      'company_id': companyId,
    }).pipe(map((data: any) => data.response.objects));
  }

  readFrames(companyId: number = null, fileId: number = null, type: string = null) {
    const filter = {};

    if (!!companyId) {
      filter['company_id_eq'] = companyId;
    }

    if (!!fileId) {
      filter['file_id_eq'] = fileId;
    }

    if (!!type) {
      filter['type_eq'] = type;
    }

    return this.apiService.send('Post', '/api/dataframes/all', {
      'filter': filter,
    }).pipe(map((data: any) => data.response.objects.map(o => new DataFrame().deserialize(o))));
  }


  /**
   * Get the proposed taxonomy matches (line item ID) for the list of
   * source items
   */
  getMatches(isItems: Array<string>, bsItems: Array<string>, type: string = ''): Observable<LineItemMatch> {
    return this.apiService.send('Post', '/api/lineitem/matches', {
      'is_items': isItems,
      'bs_items': bsItems,
      'file_type': type,
    }).pipe(map((data: any) => new LineItemMatch(data.response.objects[0].incomeStatement, data.response.objects[0].balanceSheet)));
  }

  adjustFrames(documentFileId: number, statementType: string, newLineItemLabel: string, sourceLineItemId: number, deleteAdjustment = false) {
    let mode = 'ADD';
    if (deleteAdjustment) {
      mode = 'DELETE';
    }
    return this.apiService.send('Post', '/api/dataframes/adjust', {
      'document_file_id': documentFileId,
      'statement_type': statementType,
      'new_line_item_label': newLineItemLabel,
      'source_line_item_id': sourceLineItemId,
      'mode': mode,
    }).pipe(map((data: any) => data.response.objects.map(o => new DataFrame().deserialize(o))));
  }

  createCombinedFrame(companyId: number, statementDate: string, reportingInterval: string, incomeStatementFrames: Array<any>, balanceSheetFrame: any, beginningCashFrame: any, endingCashFrame: any, currency: string, formSnapshot?: any, statementBuilderOriginType?: 'CONSOLIDATED'|'CALCULATED'): Observable<any> {
    return this.apiService.send('Post', `/api/combined-frame`, {
      'company_id': companyId,
      'statement_date': statementDate,
      'reporting_interval': reportingInterval,
      'income_statement_frames': incomeStatementFrames,
      'balance_sheet_frame': balanceSheetFrame,
      'beginning_cash_frame': beginningCashFrame,
      'ending_cash_frame': endingCashFrame,
      'currency': currency,
      'statement_builder_raw_form_input': formSnapshot,
      'statement_builder_origin_type': statementBuilderOriginType,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  generateForecast(companyId: number, statementDate: string, reportingInterval: string, projectionName: string, basePeriodFrame: any, taxonomyItemIdToGrowthRateMappings: any, currency: string, formSnapshot?: any): Observable<any> {
    return this.apiService.send('Post', `/api/forecasted-frame`, {
      'company_id': companyId,
      'statement_date': statementDate,
      'reporting_interval': reportingInterval,
      'projection_name': projectionName,
      'base_period_frame': basePeriodFrame,
      'taxonomyItemIdToGrowthRateMappings': taxonomyItemIdToGrowthRateMappings,
      'currency': currency,
      'statement_builder_raw_form_input': formSnapshot,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  updateForecast(forecastedFrameId: number, companyId: number, statementDate: string, reportingInterval: string, projectionName: string, basePeriodFrame: any, taxonomyItemIdToGrowthRateMappings: any, currency: string, formSnapshot?: any){
    return this.apiService.send('Put', `/api/forecasted-frame/${forecastedFrameId}`, {
      'company_id': companyId,
      'statement_date': statementDate,
      'reporting_interval': reportingInterval,
      'projection_name': projectionName,
      'base_period_frame': basePeriodFrame,
      'taxonomyItemIdToGrowthRateMappings': taxonomyItemIdToGrowthRateMappings,
      'currency': currency,
      'statement_builder_raw_form_input': formSnapshot,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  updateCombinedFrame(combinedFrameId: number, companyId: number, statementDate: string, reportingInterval: string, incomeStatementFrames: Array<any>, balanceSheetFrame: any, beginningCashFrame: any, endingCashFrame: any, currency: string, formSnapshot?: any, statementBuilderOriginType?: 'CONSOLIDATED'|'CALCULATED'){
    return this.apiService.send('Put', `/api/combined-frame/${combinedFrameId}`, {
      'company_id': companyId,
      'statement_date': statementDate,
      'reporting_interval': reportingInterval,
      'income_statement_frames': incomeStatementFrames,
      'balance_sheet_frame': balanceSheetFrame,
      'beginning_cash_frame': beginningCashFrame,
      'ending_cash_frame': endingCashFrame,
      'currency': currency,
      'statement_builder_raw_form_input': formSnapshot,
      'statement_builder_origin_type': statementBuilderOriginType,
    }).pipe(map(data => {
      return data.response.objects[0];
    }));
  }

  loadPrefillDataForForecastedFrame(forecastedFrameId: number) {
    return this.apiService.send('Get', `/api/forecasted-frame/${forecastedFrameId}/prefill-data`)
      .pipe(map(data => {
        return data?.response?.objects[0];
      }));
  }

  loadPrefillDataForCombinedFrame(combinedFrameId: number) {
    return this.apiService.send('Get', `/api/combined-frame/${combinedFrameId}/prefill-data`)
      .pipe(map(data => {
        return data?.response?.objects[0];
      }));
  }

  deleteCombinedFrame(combinedFrameId: number): Observable<any> {
    return this.apiService.send('Delete', `/api/combined-frame/${combinedFrameId}`);
  }

  deleteImportedFrame(combinedFrameId: number): Observable<any> {
    return this.apiService.send('Delete', `/api/imported-frame/${combinedFrameId}`);
  }

  deleteForecastedFrame(forecastedFrameId: number): Observable<any> {
    return this.apiService.send('Delete', `/api/forecasted-frame/${forecastedFrameId}`);
  }
}
