import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopoverMenuComponent} from './popover-menu.component';


@NgModule({
  imports: [CommonModule],
  declarations: [PopoverMenuComponent],
  exports: [PopoverMenuComponent],
  entryComponents: [PopoverMenuComponent]
})
export class PopoverMenuModule {
}
