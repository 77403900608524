import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { FileItem } from 'ng2-file-upload';
import { Company } from '../../../../models/company';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';
import {
  TAX_RETURN,
  SUPPORTED_TAX_FORMS,
  SUPPORTED_TAX_YEARS_BY_FORM,
  ADDITIONAL_DETAILS_NEEDED_STEP,
} from '../../../../utils/constants';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { LaunchDarklyService } from '../../../../services/launchdarkly.service';
import { DocumentFileService } from '../../../../services/document-file.service';
import { CustomAttributeDefinitionService } from '../../../../services/custom-attribute-definition.service';
import { ReviewQueueService } from '@services/review-queue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewQueueItem } from '@models/review-queue-item';
import { ApiService } from '@services/api.service';
import { NgxPopupService } from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { SharedDataService } from '@services/shared-data.service';
import { TrackingService } from '@services/tracking.service';
import { AlertService } from '@services/alert.service';
import { Logger, LoggingService } from '@services/logging.service';
import { DocumentFile } from '@models/document-file';

@Component({
  selector: 'app-tax-form-type-and-year-confirmation',
  templateUrl: './tax-form-type-and-year-confirmation.component.html',
  styleUrls: ['./tax-form-type-and-year-confirmation.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class TaxFormTypeAndYearConfirmationComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() file: any; // workaround so this will build and work for html template code
  @Input() company: Company;
  @Input() reviewQueueItemId: '';
  @Input() reviewQueueItem: ReviewQueueItem;
  progressWidth = '0%';
  @Output() callback = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();

  subsArr$: Subscription[] = [];
  // Redeclare consts so template html file can read
  TAX_RETURN = TAX_RETURN;
  SUPPORTED_TAX_FORMS: Array<string> = Array.from(SUPPORTED_TAX_FORMS);
  selectedTaxForm: string = this.SUPPORTED_TAX_FORMS[0];
  SUPPORTED_TAX_YEARS: Array<string> = Array.from(SUPPORTED_TAX_YEARS_BY_FORM[this.selectedTaxForm]);
  selectedStatementDate: string = this.SUPPORTED_TAX_YEARS[0];
  showPuertoRicoCorporate: Boolean = false;
  showPuertoRicoIndividual: Boolean = false;

  detailsConfirmed: Boolean = false;
  isLoading: Boolean = false;
  documentFile: DocumentFile = null;
  logger: Logger;


  constructor(
    private _featureFlags: LaunchDarklyService,
    private _documentFileService: DocumentFileService,
    private _customAttributeDefinitionService: CustomAttributeDefinitionService,
    private _reviewQueueService: ReviewQueueService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _apiService: ApiService,
    private _popupService: NgxPopupService,
    private _changeDetector: ChangeDetectorRef,
    private _sharedDataService: SharedDataService,
    private _trackingService: TrackingService,
    private _alertService: AlertService,
    private _loggingService: LoggingService,
    ) {
    this.logger = this._loggingService.rootLogger.newLogger('UserService');
  }

  ngOnInit(): void {
    this.isLoading = false;

    if (!this.company) {
      return;
    }

    this.updateFlags();
    this.updateSupportedForms();
    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.updateFlags();
      this.updateSupportedForms();
    }));

    this.retrieveDocumentFile();
  }

  updateSupportedForms() {
    this.SUPPORTED_TAX_FORMS = Array.from(SUPPORTED_TAX_FORMS);
    this.SUPPORTED_TAX_FORMS.push('1040 Schedule C');
    if (this.showPuertoRicoCorporate) {
      this.SUPPORTED_TAX_FORMS.push('Puerto Rico Corporate')
    }
    if (this.showPuertoRicoIndividual) {
      this.SUPPORTED_TAX_FORMS.push('Puerto Rico Individual')
    }
    this.SUPPORTED_TAX_FORMS.push('Schedule E')
    this.SUPPORTED_TAX_FORMS.push('Schedule F')
  }

  validForm() {
    if (this.selectedStatementDate === '' || this.selectedTaxForm === '') {
      return false;
    }
    return true;
  }

  confirmDetails(): void {
    if (this.validForm()) {
      this.nextStep(true);
    }
  }

  updateTaxForm(formName: string): void {
    if (this.SUPPORTED_TAX_FORMS.includes(formName)) {
      this.selectedTaxForm = formName;
      // update selected year based on years supported for form
      this.SUPPORTED_TAX_YEARS = Array.from(SUPPORTED_TAX_YEARS_BY_FORM[formName]);
      if (!this.SUPPORTED_TAX_YEARS.includes(this.selectedStatementDate)) {
        this.selectedStatementDate = this.SUPPORTED_TAX_YEARS[0];
      }
    } else {
      throw new Error('Unknown tax form chosen: ' + (formName ? formName : ' '));
    }
  }

  updateTaxYear(taxYear: string): void {
    if (this.SUPPORTED_TAX_YEARS.includes(taxYear)) {
      this.selectedStatementDate = taxYear;
    } else {
      throw new Error('Unsupported tax year: ' + (taxYear ? taxYear : ' '));
    }
  }

  updateFlags(): void {
    this.showPuertoRicoCorporate = this._featureFlags.flags['puerto-rico-corporate'];
    this.showPuertoRicoIndividual = this._featureFlags.flags['puerto-rico-individual'];
  }

  close(): void {
    this.popup.close();
    this.onClose.emit(true);
  }

  nextStep(next: boolean): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.subsArr$.push(this._apiService.send('Post', '/api/document-files/' + this.documentFile.id + '/hil-data', {
      'hil_data': this.getSelectedData(),
      'current_step': ADDITIONAL_DETAILS_NEEDED_STEP,
      'lock_key': this.reviewQueueItem.lockKey,
      'next': true,
    }).subscribe(data => {
      this.isLoading = false;
      this.detailsConfirmed = true;
      this.close();
      this._alertService.success('Tax Return details confirmed.');
    }, err => {
      this.isLoading = false;
      this._alertService.error(err);
    }));
  }

  getSelectedData() {
    return {
      tax_form_type: this.selectedTaxForm,
      tax_form_year: this.selectedStatementDate,
    }
  }

  private retrieveDocumentFile() {
    // Set the bank ID to the one from the queue item
    this._apiService.setTenantName(this.reviewQueueItem.bankId);
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.reviewQueueItem.documentFileId, true).subscribe(docs => {
      this.documentFile = docs[0];
    }, err => {
      this.isLoading = false;
      this._alertService.error(err);
    }));
  }
}
