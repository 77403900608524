<div class="modal__header">
  <div class="modal__title">
      CHOOSE NORMAL BALANCE
  </div>
  <div class="modal__close" (click)="popup.close();">
      <i class="fas fa-times"></i>
  </div>
</div>
<form [formGroup]="normalBalanceSelectionForm">
  <div class="modal__content">
      <div class="form-header">
          Unable to determine what the normal balance of taxonomy item is. Please select it manually.
      </div>
      <div class="row">
          <div class="col-8">
              <label class="debit-label">
                <input type="radio" value="Debit" formControlName="normalBalance">Debit
              </label>
              <label>
                <input type="radio" value="Credit" formControlName="normalBalance">Credit
              </label>
          </div>
      </div>
  </div>

  <div class="modal__footer">
      <button type="reset" class="btn btn-link" (click)="exitSelectNormalBalanceModal()">Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="saveSelectedNormalBalance()">Select</button>
  </div>
</form>
