import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {MultiSelectItem} from "@components/shared/popover-menu-multi-select/popover-menu-multi-select.component";
import {DataViewColumn} from "@models/dataview";
import {AutoUnsubscribe} from "@decorators/auto-unsubscribe";
import {Subscription} from "rxjs";
import {HideRowColStack} from "@models/hide-row-col";

@Component({
  selector: 'app-filter-statements',
  templateUrl: './filter-statements.component.html',
  styleUrls: ['./filter-statements.component.scss']
})

@AutoUnsubscribe('subsArr$')
export class FilterStatementsComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() columns: DataViewColumn[] = [];
  @Input() hiddenColumnIndices: Set<string>
  @Input() showHiddenDisabled: boolean
  @Input() removeHiddenDisabled: boolean
  @Input() clearUndoDisabled: boolean
  @Input() multiSelectOptionsForReportingInterval: MultiSelectItem[]
  @Input() multiSelectOptionsForYears: MultiSelectItem[]
  @Input() multiSelectOptionsForScenario: MultiSelectItem[]
  @Input() hideRowColUndoStack: HideRowColStack
  @Input() shouldResetEverything: boolean
  @Output() callback = new EventEmitter<any>();
  subsArr$: Subscription[] = [];

  HEADER_TEXT: string = "FILTER STATEMENTS";

  multiSelectButtonTextForReportingInterval: string
  multiSelectButtonTextForYears: string
  multiSelectButtonTextForScenarios: string
  reportingIntervalOptions: string[] = ["Annual", "Semi-Annual", "Monthly", "Quarterly", "Fiscal YTD"];
  scenarioOptions: string[] = ["Projection", "Historical"]
  allAvailableOptions: MultiSelectItem[] = [];
  isAvailableStatementsEmpty: boolean = true
  numberOfAvailableStatementsSelectedText: string
  isFiltering: boolean
  multiSelectOptionsForAvailableStatements: MultiSelectItem[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.isFiltering = false

    if (!this.multiSelectOptionsForScenario) {
      this.multiSelectOptionsForScenario = []
    }

    if (!this.multiSelectOptionsForYears) {
      this.multiSelectOptionsForYears = []
    }

    if (!this.multiSelectOptionsForReportingInterval) {
      this.multiSelectOptionsForReportingInterval = []
    }

    if (this.multiSelectOptionsForReportingInterval && this.multiSelectOptionsForReportingInterval.length == 0) {
      this.reportingIntervalOptions.forEach((reportingInterval, idx) => {
        this.multiSelectOptionsForReportingInterval.push({
          id: String(idx),
          title: reportingInterval,
          action: () => this.toggleCheckedStateReportingInterval(this.multiSelectOptionsForReportingInterval[idx]),
          checked: false,
        });
      });
    } else {
      this.multiSelectOptionsForReportingInterval.forEach((option, idx) => {
        option.action = () => this.toggleCheckedStateReportingInterval(this.multiSelectOptionsForReportingInterval[idx]);
      });
    }

    let idx = 0;
    if (this.multiSelectOptionsForYears && this.multiSelectOptionsForYears.length == 0) {
      this.columns.forEach(column => {
        const periodLabel = column.statementDate;
        const title = periodLabel.substring(periodLabel.length - 4);
        if (!this.multiSelectOptionsForYears.some(option => option.title === title)) {
          ((idx) => {
            this.multiSelectOptionsForYears.push({
              id: String(idx),
              title: title,
              action: () => this.toggleCheckedStateYears(this.multiSelectOptionsForYears[idx]),
              checked: false,
            });
          })(idx);
          idx++;
        }
      });
    } else {
      this.multiSelectOptionsForYears.forEach((option, idx) => {
        option.action = () => this.toggleCheckedStateYears(this.multiSelectOptionsForYears[idx]);
      });
    }

    if (this.multiSelectOptionsForScenario && this.multiSelectOptionsForScenario.length == 0) {
      this.scenarioOptions.forEach((scenarioOption, idx) => {
        this.multiSelectOptionsForScenario.push({
          id: String(idx),
          title: scenarioOption,
          action: () => this.toggleCheckedStateReportingScenario(this.multiSelectOptionsForScenario[idx]),
          checked: false,
        });
      });
    } else {
      this.multiSelectOptionsForScenario.forEach((option, idx) => {
        option.action = () => this.toggleCheckedStateReportingScenario(this.multiSelectOptionsForScenario[idx]);
      });
    }

    this.initializeAllAvailableOptions()
    this.filterAvailableStatements()

    this.multiSelectOptionsForAvailableStatements.forEach(option => {
      this.columns.forEach((column) => {
        let reportingInterval = this.getEquivalentParsed(column.reportingInterval)

        if (column.statementDate.toLowerCase() === option.title.toLowerCase() && column.scenario.toLowerCase() === option.subtitle1.toLowerCase()
          && column.preparationType.toLowerCase() === option.subtitle2.toLowerCase() && reportingInterval.toLowerCase() === option.subtitle3.toLowerCase()) { // means we are currently on the right column
          option.checked = !column.isHidden;
        }
      })
    })
    if (this.shouldResetEverything) {
      this.resetFilters()
    }

    this.isAvailableStatementsEmpty = this.multiSelectOptionsForAvailableStatements.length <= 0;
    this.updateMultiSelectButtonTextForReportingInterval()
    this.updateMultiSelectButtonTextForYears()
    this.updateMultiSelectButtonTextForScenario()
    this.updateNumberOfAvailableStatementsSelected()
  }

  initializeAllAvailableOptions() {
    this.columns.forEach((column, idx) => {
      const periodLabel = column.statementDate;
      const words: string[] = column.reportingInterval.toLowerCase().split("_");
      if (words.length > 1) {
        words[1] = words[1].charAt(0).toUpperCase() + words[1].slice(1);
      }
      let capitalizedReportingInterval: string = words.join("-");
      capitalizedReportingInterval = capitalizedReportingInterval.charAt(0).toUpperCase() + capitalizedReportingInterval.slice(1);


      let correctedPreperationType = this.getEquivalentParsed(column.preparationType)
      let scenario = column.scenario.charAt(0).toUpperCase() + column.scenario.slice(1).toLowerCase()
      if (column.scenario.toLowerCase() === "projection") {
        scenario += " (" + column.projectionName + ")"
      }
      this.allAvailableOptions.push({
        id: String(idx),
        title: periodLabel,
        subtitle1: scenario,
        subtitle2: correctedPreperationType,
        subtitle3: capitalizedReportingInterval,
        action: () => this.toggleCheckedStateAvailableStatements(this.allAvailableOptions[idx]),
        checked: !column.isHidden,
      });
    });
  }

  resetFilters() {
    this.resetReportingIntervalFilter()
    this.resetYearsFilter()
    this.resetScenarioFilter()

    this.updateMultiSelectButtonTextForScenario()
    this.updateMultiSelectButtonTextForReportingInterval()
    this.updateMultiSelectButtonTextForYears()

    this.multiSelectOptionsForAvailableStatements = this.allAvailableOptions
    this.updateNumberOfAvailableStatementsSelected()
  }

  resetReportingIntervalFilter() {
    this.multiSelectOptionsForReportingInterval.forEach(option => {
      option.checked = false
    })
  }

  resetYearsFilter() {
    this.multiSelectOptionsForYears.forEach(option => {
      option.checked = false
    })
  }

  resetScenarioFilter() {
    this.multiSelectOptionsForScenario.forEach(option => {
      option.checked = false
    })

  }

  filterAvailableStatements() {
    const reportingIntervalFilter = [];
    const yearFilter = [];
    const scenarioFilter = [];

    this.multiSelectOptionsForAvailableStatements = this.allAvailableOptions

    // iterate over all the multiselectOptions
    this.multiSelectOptionsForReportingInterval.forEach(option => {
      if (option.checked) {
        reportingIntervalFilter.push(option.title)
      }
    })

    this.multiSelectOptionsForYears.forEach(option => {
      if (option.checked) {
        yearFilter.push(option.title)
      }
    })

    this.multiSelectOptionsForScenario.forEach(option => {
      if (option.checked) {
        scenarioFilter.push(option.title)
      }
    })

    // Filter out options that don't meet the condition of year
    this.multiSelectOptionsForAvailableStatements = this.multiSelectOptionsForAvailableStatements.filter(option => {
      const existsInFilter = yearFilter.includes(option.title.substring(option.title.length - 4));
      // Keep the option if it exists in the yearFilter or if the filter is empty
      return existsInFilter || yearFilter.length === 0;
    });

    // Filter out options that don't meet the condition of reporting interval
    this.multiSelectOptionsForAvailableStatements = this.multiSelectOptionsForAvailableStatements.filter(option => {
      const existsInFilter = reportingIntervalFilter.includes(this.getEquivalentParsed(option.subtitle3));
      // Keep the option if it exists in the reportingIntervalFilter or if the filter is empty
      return existsInFilter || reportingIntervalFilter.length === 0;
    });

    // Filter out options that don't meet the condition of scenario
    this.multiSelectOptionsForAvailableStatements = this.multiSelectOptionsForAvailableStatements.filter(option => {
      const existsInFilter = scenarioFilter.includes(option.subtitle1.split(" ")[0]);
      // Keep the option if it exists in the scenarioFilter or if the filter is empty
      return existsInFilter || scenarioFilter.length === 0;
    });

    this.updateNumberOfAvailableStatementsSelected()
    this.isAvailableStatementsEmpty = this.multiSelectOptionsForAvailableStatements.length <= 0;
  }

  close() {
    if (this.hiddenColumnIndices.size > 0) {
      this.showHiddenDisabled = false
      this.removeHiddenDisabled = false
      this.clearUndoDisabled = false
      this.hiddenColumnIndices.forEach(column => {
        this.hideRowColUndoStack.pushColumn(true, column)
      })
    } else {
      this.showHiddenDisabled = true
      this.removeHiddenDisabled = true
      this.clearUndoDisabled = true

    }
    this.callback.emit({
      hiddenColumnsPassedIn: this.hiddenColumnIndices,
      reportingIntervalFilters: this.multiSelectOptionsForReportingInterval,
      yearsFilter: this.multiSelectOptionsForYears,
      scenarioFilter: this.multiSelectOptionsForScenario,
      showHiddenDisabled: this.showHiddenDisabled,
      removeHiddenDisabled: this.removeHiddenDisabled,
      clearUndoDisabled: this.clearUndoDisabled,
      hideRowColUndoStack: this.hideRowColUndoStack
    });
    this.popup.close();
  }

  toggleCheckedStateAvailableStatements(option) {
    option.checked = !option.checked
    this.filterAvailableStatements()
  }

  toggleCheckedStateYears(option) {
    option.checked = !option.checked
    this.filterAvailableStatements()
    this.updateMultiSelectButtonTextForYears()
  }

  toggleCheckedStateReportingInterval(option) {
    option.checked = !option.checked
    this.filterAvailableStatements()
    this.updateMultiSelectButtonTextForReportingInterval()
  }

  toggleCheckedStateReportingScenario(option) {
    option.checked = !option.checked
    this.filterAvailableStatements()
    this.updateMultiSelectButtonTextForScenario()
  }

  handleSelectOrDeselectAll(values: any[]) {
    if (values.some(option => option.checked === true)) {
      values.forEach(option => option.checked = false);
    } else {
      values.forEach(option => option.checked = true);
    }
    this.filterAvailableStatements()
    this.updateMultiSelectButtonTextForReportingInterval()
    this.updateMultiSelectButtonTextForYears()
    this.updateMultiSelectButtonTextForScenario()
    this.updateNumberOfAvailableStatementsSelected()
  }

  isAnyPeriodChecked() {
    return this.multiSelectOptionsForAvailableStatements.some(item => item.checked === true);
  }

  getEquivalentParsed(reportingInterval) {
    const words: string[] = reportingInterval.toLowerCase().split("_");
    if (words.length > 1) {
      words[1] = words[1].charAt(0).toUpperCase() + words[1].slice(1);
    }
    let capitalizedReportingInterval: string = words.join("-");
    capitalizedReportingInterval = capitalizedReportingInterval.charAt(0).toUpperCase() + capitalizedReportingInterval.slice(1);

    if (capitalizedReportingInterval === ("Annually")) {
      capitalizedReportingInterval = "Annual"
    } else if (capitalizedReportingInterval.toLowerCase().includes("annually")) {
      capitalizedReportingInterval = "Semi-Annual"
    } else if (capitalizedReportingInterval.toLowerCase().includes("fiscal")) {
      capitalizedReportingInterval = "Fiscal YTD"
    }
    return capitalizedReportingInterval
  }

  filterStatements() {
    this.isFiltering = true

    this.columns.forEach(column => {
      let isFoundInAvailableStatements = false;
      const strIdx = column.toKeyString()
      this.multiSelectOptionsForAvailableStatements.forEach((option) => {

        let scenario = column.scenario.charAt(0).toUpperCase() + column.scenario.slice(1).toLowerCase()
        if (column.scenario.toLowerCase() === "projection") {
          scenario += " (" + column.projectionName + ")"
        }

        if (column.statementDate.toLowerCase() === option.title.toLowerCase() && scenario === option.subtitle1
          && column.preparationType.replace("_", "-").toLowerCase() === option.subtitle2.toLowerCase() && column.reportingInterval.replace("_", "-").toLowerCase() === option.subtitle3.toLowerCase()) { // means we are currently on the right column
          isFoundInAvailableStatements = true;
          if (option.checked) {
            if (this.hiddenColumnIndices.has(strIdx)) {
              this.hiddenColumnIndices.delete(strIdx);
              column.isHidden = false
            }
          } else {
            if (!this.hiddenColumnIndices.has(strIdx)) {
              this.hiddenColumnIndices.add(strIdx);
              column.isHidden = true
            }
          }
        }
      })
      if (!isFoundInAvailableStatements) { // If the column is currently not within filter constraints
        if (!this.hiddenColumnIndices.has(strIdx)) {
          this.hiddenColumnIndices.add(strIdx)
        }
      }
    })
    this.close();
  }

  updateMultiSelectButtonTextForReportingInterval() {
    const countSelectedPeriods = this.multiSelectOptionsForReportingInterval.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);
    if (countSelectedPeriods < 1) {
      this.multiSelectButtonTextForReportingInterval = 'Select'
    } else if (countSelectedPeriods === 1) {
      this.multiSelectButtonTextForReportingInterval = "1 interval selected";
    } else {
      this.multiSelectButtonTextForReportingInterval = String(countSelectedPeriods) + " intervals selected";
    }
  }

  updateMultiSelectButtonTextForYears() {
    const countSelectedPeriods = this.multiSelectOptionsForYears.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);

    if (countSelectedPeriods < 1) {
      this.multiSelectButtonTextForYears = 'Select'
    } else if (countSelectedPeriods === 1) {
      this.multiSelectButtonTextForYears = "1 year selected";
    } else {
      this.multiSelectButtonTextForYears = String(countSelectedPeriods) + " years selected";
    }
  }

  updateMultiSelectButtonTextForScenario() {
    const countSelectedPeriods = this.multiSelectOptionsForScenario.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);
    if (countSelectedPeriods < 1) {
      this.multiSelectButtonTextForScenarios = 'Select'
    } else if (countSelectedPeriods === 1) {
      this.multiSelectButtonTextForScenarios = "1 scenario selected";
    } else {
      this.multiSelectButtonTextForScenarios = String(countSelectedPeriods) + " scenarios selected";
    }
  }

  updateNumberOfAvailableStatementsSelected() {
    const countSelectedPeriods = this.multiSelectOptionsForAvailableStatements.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);
    if (countSelectedPeriods < 1) {
      this.numberOfAvailableStatementsSelectedText = '0 statements selected'
    } else if (countSelectedPeriods === 1) {
      this.numberOfAvailableStatementsSelectedText = "1 statement selected";
    } else {
      this.numberOfAvailableStatementsSelectedText = String(countSelectedPeriods) + " statements selected";
    }
  }
}

