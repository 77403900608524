import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {Observable, timer} from "rxjs";
import {map, takeWhile} from "rxjs/operators";

@Component({
  selector: 'app-session-timeout-warning-modal',
  templateUrl: './session-timeout-warning-modal.component.html',
  styleUrls: ['./session-timeout-warning-modal.component.scss']
})
export class SessionTimeoutWarningModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() timerLifetimeInMS: number;
  @Output() callback = new EventEmitter<boolean>();
  timeRemaining$: Observable<any>

  constructor() {
  }

  ngOnInit() {
    const timerLifetimeInSeconds = Math.round(this.timerLifetimeInMS / 1000);
    this.timeRemaining$ = timer(0, 1000).pipe(
      map(n => (timerLifetimeInSeconds - n) * 1000),
      takeWhile(n => n >= 0),
    );
  }

  continueSession() {
    this.callback.emit(true);
    this.popup.close();
  }

  logout() {
    this.callback.emit(false);
    this.popup.close();
  }

}
