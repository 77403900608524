import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DocumentFile} from "@models/document-file";
import {StatementService} from "@services/statement.service";
import {AlertService} from "@services/alert.service";
import {UserService} from "@services/user.service";
import {SharedDataService} from "@services/shared-data.service";
import {Box} from "@components/main/review/human-validation/models/box";
import {Row} from "@components/main/spreading/models/models";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-footnote-manager',
  templateUrl: './footnote-manager.component.html',
  styleUrls: ['./footnote-manager.component.scss', '../table-merging/shared-table-merging-header-styles.scss']
})
export class FootnoteManagerComponent implements OnInit {
  spreadRows: Row[];
  targetRow: Row;
  documentFile: DocumentFile
  insertAtLineItemId = -1
  statementUuid: string;
  currentBoxesByPage: Array<Array<Box>> = [];
  isLoading: boolean = false;

  constructor(
    private _statementService: StatementService,
    private _alertService: AlertService,
    private _userService: UserService,
    private _sharedDataService: SharedDataService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
    const tableMergingPageData = this._route.snapshot.data.tableMergingPageData;
    // parse loadStatementResponse
    this.spreadRows = tableMergingPageData.statementData.rows;
    // parse loadDocument response
    this.documentFile = tableMergingPageData.documentData
  }

  ngOnInit() {
    if (!this._userService.isSpreader()) {
      this._router.navigate(['companies']);
    }
    this._sharedDataService.hideDefaultNavigation();
    this.insertAtLineItemId = parseInt(this._route.snapshot.queryParamMap.get('targetLineItem'));
    this.statementUuid = this._route.snapshot.paramMap.get('statement_uuid');
    this.targetRow = this.spreadRows.find(row => row.lineItemId == this.insertAtLineItemId);
    this.setInitialBoxesByPage();
  }

  setInitialBoxesByPage() {
    // reset boxes
    this.currentBoxesByPage = [];
    this.documentFile?.pageImages.forEach(_ => {
      this.currentBoxesByPage.push([])
    })
    if (this.targetRow?.footnotes?.length) {
      this.targetRow.footnotes.forEach(footnote => {
        const deserializedFootnote = new Box().deserialize(footnote);
        this.currentBoxesByPage[deserializedFootnote.pageNumber].push(deserializedFootnote);
      })
    }
  }

  boxesChanged({boxes, activePageIndex}: { boxes: Array<Box>, activePageIndex: number }): void {
    this.currentBoxesByPage[activePageIndex] = boxes;
  }

  handleSave() {
    this.isLoading = true;
    const serializedFootnotes = this.flattenAndSerializeFootnotes();
    this._statementService.saveFootnotesOnRow(this.statementUuid, this.targetRow.uuid, serializedFootnotes)
      .pipe(finalize(() => {this.isLoading = false}))
      .subscribe(res => {
        this._alertService.success('Footnotes successfully saved')
        this.redirectBackToCategorization();
      }, err => {
        this._alertService.error("Failed to save footnotes")
        console.log('err: ', err)
      })
  }

  flattenAndSerializeFootnotes() {
    this.currentBoxesByPage.forEach((pg: Array<Box>, pgIdx: number) => {
      pg.forEach((b: Box) => {
        b.pageNumber = pgIdx;
      });
    });
    const flattened = this.currentBoxesByPage.reduce((a, b) => a.concat(b), []);
    return flattened.map(f => f.serialize())
  }

  handleExit() {
    this.redirectBackToCategorization();
  }

  redirectBackToCategorization() {
    this._router.navigate(['../edit'], {relativeTo: this._route})
  }
}
