import * as _ from 'lodash';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SharedDataService} from '../../../../services/shared-data.service';
import {forkJoin, Subscription} from 'rxjs';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {Select2OptionData} from "@components/shared/select2/select2.interface";
import {AlertService} from '../../../../services/alert.service';
import {SpreadingTemplateService} from '../../../../services/spreading-template.service';
import {NgxPopupService} from '../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import {NgxPopupComponent} from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import {KEYS, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateGenericEquationComponent} from '../create-generic-equation/create-generic-equation.component';
import {ImportExportTemplateComponent} from '../import-export-template/import-export-template.component';
import {ConfirmationPopupComponent} from '@components/shared/popups/confirmation/confirmation-popup.component';
import {StatementService} from '@services/statement.service';
import {
  AddCustomItemToTemplatePopupComponent
} from '@components/main/spreading-template/add-custom-item-to-template-popup/add-custom-item-to-template-popup.component';
import {
  AddEntitySubsectionForSpecificTemplateComponent
} from '@components/main/spreading-template/multi-entity/add-entity-subsection-for-specific-template/add-entity-subsection-for-specific-template.component';
import {
  AddAvailableItemsToWorkingTemplatePopoverComponent
} from '@components/main/spreading-template/multi-entity/add-available-items-to-working-template-popover/add-available-items-to-working-template-popover.component';
import {
  buildAndValidateUpdatePayload,
  TemplatePageData
} from "@components/main/spreading-template/spreading-template-shared-functions";

@Component({
  selector: 'app-edit-multi-entity-spreading-template',
  templateUrl: './edit-multi-entity-spreading-template.component.html',
  styleUrls: ['./edit-multi-entity-spreading-template.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class EditMultiEntitySpreadingTemplateComponent implements OnInit {
  subsArr$: Subscription[] = [];
  templateType = 'GLOBAL_CASHFLOW';
  dragStartTree;

  workingNodes;
  workingTreeOptions = {
    idField: 'standardLineItemId',
    displayField: 'label',
    allowDrag: this.workingTreeAllowDrag,
    useVirtualScroll: true,
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          this.editNode = node;
          node.focus();
        },
        dragStart: () => {
          this.dragStartTree = this.workingTree
        },
        drop: (tree: TreeModel, node: TreeNode, $event: any, {from, to}) => {
          if (from?.data?.className === "GenericCalculatedItem" && to?.dropOnNode) {
            to.index = to.parent?.data?.children?.length || 0;
          }
          TREE_ACTIONS.MOVE_NODE(tree, node, $event, {from, to});
        },
      },
      keys: {
        [KEYS.UP]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.DOWN]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.RIGHT]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        },
        [KEYS.LEFT]: (tree, node, $event) => {
          $event.preventDefault();
          return false;
        }
      }
    }
  }

  editNode;
  showFormulaOverride = false;
  showItemvalidator = false;
  mode: 'create' | 'update' = 'create';
  templateId = null;
  isTemplateNameBeingEdited = false;
  initialPageData: TemplatePageData;
  submissionPayload: any;

  validatorComparerOptions = [
    {value: '', label: 'Choose Comparer'},
    {value: '==', label: 'Equal to'},
    {value: '!=', label: 'not Equal to'},
    {value: '>', label: 'Greater than'},
    {value: '<', label: 'Less than'},
    {value: '>=', label: 'Greater than or equal'},
    {value: '<=', label: 'Less than or equal'}
  ];

  validatorMarker = [
    {value: 'INSUFFICIENT', label: 'INSUFFICIENT'},
    {value: 'SUFFICIENT', label: 'SUFFICIENT'}
  ];

  spreadingTemplate = {
    name: 'New Template',
    isDefault: false
  }

  hotkeys: Array<Hotkey> = [
    new Hotkey('shift+down', (evt: KeyboardEvent, combo: string) => {
      this.workingTree.treeModel.focusNextNode();
      return false;
    }),
    new Hotkey('shift+up', (evt: KeyboardEvent, combo: string) => {
      this.workingTree.treeModel.focusPreviousNode();
      return false;
    }),
  ];

  @ViewChild('workingTree', {static: true})
  workingTree: TreeComponent;

  allLineItemIdsInWorkingTree = []
  templateItemAdditionalAttributes = {}
  availableTaxonomyItemsSeparatedByTemplate: { [templateName: string]: Array<any>; }= {}
  listOfTemplateNames = []
  templateNameToIdMapping = {}
  templateAvailableItemDrawerState = []
  availableItemsQueuedForWorkingTempate = []

  constructor(
    private _alertService: AlertService,
    private _hotkeysService: HotkeysService,
    private _sharedDataService: SharedDataService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private popupService: NgxPopupService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _statementService: StatementService,
  ) {}

  ngOnInit() {
    this._sharedDataService.pageHeaderTitle$.next('Templates');
    if (this._route.toString().includes('new')) {
      this.mode = 'create';
    } else {
      this.mode = 'update';
    }

    this.subsArr$.push(this._route.paramMap.subscribe((params) => {
        this.templateType = 'GLOBAL_CASHFLOW';
        let templateUuid = params.get('template_uuid') || params.get('original_uuid');
        templateUuid = templateUuid === 'new' ? null : templateUuid
        this._spreadingTemplateService.getTemplateByUuid(templateUuid).subscribe(template => {
          let numericalTemplateId = null;

          if (template !== undefined) {
            numericalTemplateId = template.id;
          }

          if (typeof numericalTemplateId !== 'number') {
            this.templateId = null;
            this.isTemplateNameBeingEdited = true;
          } else {
            this.templateId = numericalTemplateId;
          }
          this.loadData();
        });
      },
      (error) => {
        this._alertService.error('an error has occurred');
        console.log(error);
      }));
  }

  setInitialPageData() {
    try {
      this.initialPageData = {
        metaData: {
          'name': this.spreadingTemplate.name,
          'isDefault': this.spreadingTemplate.isDefault,
        },
        templateData: {
          'items': structuredClone(this.workingNodes),
          'additionalAttributes': structuredClone(this.templateItemAdditionalAttributes)
        }
      }
    } catch (e) {
      console.error(
        'Failed to create clone of page data for comparison on submission, submissions with no changes will be allowed.'
        , e);
    }
  }

  loadData() {
    if (this.templateId === null) { // no template id
      this.subsArr$.push(forkJoin(
        this._spreadingTemplateService.getAvailableTaxonomyItemsForMultiEntityAnalysis(),
        this._spreadingTemplateService.getEmptyTemplate(this.templateType),
      ).subscribe(results => {
          this.displayTemplates(results);
        },
        error => {
          console.log('error', error);
          console.log(error.message);
        }));
    } else {
      this.subsArr$.push(forkJoin(
        this._spreadingTemplateService.getAvailableTaxonomyItemsForMultiEntityAnalysis(),
        this._spreadingTemplateService.getTemplate(this.templateId),
      ).subscribe(results => {
          this.displayTemplates(results);
        },
        error => {
          console.log('error', error);
        }));
    }
  }

  displayTemplates(results: any): void {
    const workingNodeResult = results[1];
    this.workingNodes = workingNodeResult.items;
    this.templateItemAdditionalAttributes = workingNodeResult.additionalAttributes;
    this.spreadingTemplate.name = workingNodeResult.name;
    this.spreadingTemplate.isDefault = workingNodeResult.isDefault;
    const flattenedNodes = this.flattenTaxonomyItems(this.workingNodes)
    flattenedNodes.forEach((item) => {
      this.allLineItemIdsInWorkingTree.push(item.standardLineItemId)
    })
    setTimeout(() => {
      const templateSeparatedAvailableItemsResponse = results[0]
      const availItemsGroupedByTemplate: any[] = templateSeparatedAvailableItemsResponse['availableItemsSplitByTemplate']
      const itemRefsFoundInMultipleTemplates: Array<string> = templateSeparatedAvailableItemsResponse['itemRefsFoundInMultipleTemplates']
      availItemsGroupedByTemplate.forEach((itemGrouping) => {
        const itemObjsForTemplate = []
        const templateName = itemGrouping["templateName"]
        const templateId = itemGrouping["templateId"]
        const availableItems = itemGrouping["availableItems"]
        availableItems.forEach((item) => {
          const inLoadedWorkingTemplate = this.allLineItemIdsInWorkingTree.includes(item.standardLineItemId)
          itemObjsForTemplate.push({
            item: item,
            standardLineItemId: item.standardLineItemId,
            isInWorkingTemplate: inLoadedWorkingTemplate,
            isSelected: inLoadedWorkingTemplate,
            itemFoundInMultipleTemplates: itemRefsFoundInMultipleTemplates.includes(item.ref)
          })
        })
        this.availableTaxonomyItemsSeparatedByTemplate[templateName] = itemObjsForTemplate
        this.listOfTemplateNames.push(templateName)
        if (templateId) {
          this.templateNameToIdMapping[templateName] = templateId;
        }
        this.templateAvailableItemDrawerState.push(false)

      })

      setTimeout(() => {
        this.workingTree.treeModel.expandAll();
        if (this.mode == 'update') {
          this.setInitialPageData();
        }
      }, 1);
      this.hotkeys.forEach(hk => {
        this._hotkeysService.add(hk);
      });
    }, 1);
  }

  addSelectedAvailableItemsToWorkingTree() {
    const flattenedWorkingItems = this.flattenTaxonomyItems(this.workingNodes)
    const entitySubsections = []
    flattenedWorkingItems.forEach((item) => {
      if (this.isItemEntitySubsection(item.standardLineItemId)) {
        entitySubsections.push({
          label: item.label,
          standardLineItemId: item.standardLineItemId,
          isSelected: false,
        })
      }
    })

    this.popupService.open({
        componentType: AddAvailableItemsToWorkingTemplatePopoverComponent,
        cssClass: 'modal-lg',
        inputs: {
          sectionAvailable: entitySubsections
        },
        outputs: {
          callback: (selectedSectionIds) => {
            this.pushSelectedItemsToTargetedWorkingNodes(selectedSectionIds)
          }
        },
      })
  }

  pushSelectedItemsToTargetedWorkingNodes(selectedSectionIds) {
    for (let i = 0; i < selectedSectionIds.length; i++) {
      this.pushSelectedItemsToTargetedWorkingNode(selectedSectionIds[i],  i === (selectedSectionIds.length - 1));
    }
  }

  pushSelectedItemsToTargetedWorkingNode(selectedSectionLineItemId, clearQueue=true) {
    const nodeToAddSelectedItemsTo = this.findNodeWithStandardLineItemId(selectedSectionLineItemId)

    let standardLineItemIdToAddToWorkingNode = []
    this.availableItemsQueuedForWorkingTempate.forEach((queuedItem) => {
      standardLineItemIdToAddToWorkingNode.push(queuedItem.standardLineItemId)
      this.allLineItemIdsInWorkingTree.push(queuedItem.standardLineItemId)
      if (nodeToAddSelectedItemsTo) {
          nodeToAddSelectedItemsTo.children.splice(nodeToAddSelectedItemsTo.children.length - 1, 0, queuedItem);
          this.workingTree.treeModel.update();
        } else {
          this.workingNodes = [...this.workingNodes, queuedItem];
        }
    })
    Object.entries(this.availableTaxonomyItemsSeparatedByTemplate).forEach(([templateName, items]) => {
      items.forEach((item) => {
        if (standardLineItemIdToAddToWorkingNode.includes(item.standardLineItemId)) {
          item.isInWorkingTemplate = true
        }
      })
    })
    if (clearQueue) {
      this.availableItemsQueuedForWorkingTempate = [];
    }
  }

  findNodeWithStandardLineItemId(item_id, workingNodes = null) {
    if (!workingNodes) {
      workingNodes = this.workingNodes;
    }
    for (const workingNode of workingNodes) {
      if (item_id === workingNode.standardLineItemId) {
        return workingNode;
      }
      if (workingNode.children !== undefined && workingNode.children.length > 0) {
        const nodeFromChild = this.findNodeWithStandardLineItemId(item_id, workingNode.children)
        if (nodeFromChild) {
          return nodeFromChild
        }
      }
    }
    return null;
  }

  createTemplate() {
    const data = {
      'name': this.spreadingTemplate.name,
      'items': this.workingNodes,
      'templateType': this.templateType,
      'isDefault': this.spreadingTemplate.isDefault,
      'additionalAttributes': this.templateItemAdditionalAttributes
    };

    this._spreadingTemplateService.createTemplate(data).subscribe(result => {
        this._alertService.success('Saved Successfully!');
        this._router.navigate(['spreading-templates']);
      },
      error => {
        if (error.message === '[\'Record with same fields (company, title) already exists\']' ||
          error.message === '[\'Record with same fields (name, template_type) already exists\']') {
          this._alertService.error(`We're sorry. Another template is already called "${this.spreadingTemplate.name}". Can you try a different name please?`);
        } else {
          this._alertService.error(error.message);
        }
      });
  }

  updateTemplate(): void {
    const submissionData: TemplatePageData = {
      metaData: {
        name: this.spreadingTemplate.name,
        isDefault: this.spreadingTemplate.isDefault,
      },
      templateData: {
        items: this.workingNodes,
        additionalAttributes: this.templateItemAdditionalAttributes
      }
    };

    this.submissionPayload = buildAndValidateUpdatePayload(this.initialPageData, submissionData);
    if (_.isEmpty(this.submissionPayload.payload)){
        this._alertService.warning("No changes detected. Please edit template items or metadata before saving.");
        return
    }
    this.popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'SAVE TEMPLATE',
        text: 'Are you sure you want to save this template? This cannot be undone.',
        confirmButtonText: 'Yes'
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this._spreadingTemplateService.updateTemplate(this.templateId, this.submissionPayload.payload).subscribe(result => {
              this._alertService.success('Saved Successfully! It may take a moment for your data to update');
              this._router.navigate(['spreading-templates']);
            }, error => {
              this._alertService.error(error.message);
            });
          }
        }
      },
    });
  };

  onWorkingTreeNodeFocus(event) {
    this.showFormulaOverride = this.editNode.data.equationOverride !== null && this.editNode.data.equationOverride !== '';

    const node = event.node;
    this.editNode = node;
    this.showItemvalidator = !!this.editNode.data.validator && this.editNode.data.validator.comparer !== '';
  }

  removeItemFromWorkingTree(node: TreeNode) {
    this.editNode = null;
    this.removeNodesFromWorkingTreeLineItemTrackers(node.data)
    this.deleteNode(node, this.workingTree);
  }

  removeNodesFromWorkingTreeLineItemTrackers(node) {
      const nodeStandardLineItemId = node.standardLineItemId
      const index = this.allLineItemIdsInWorkingTree.indexOf(nodeStandardLineItemId);
      if (index > -1) { // only splice array when item is found
        this.allLineItemIdsInWorkingTree.splice(index, 1); // 2nd parameter means remove one item only
      }

      Object.entries(this.availableTaxonomyItemsSeparatedByTemplate).forEach(([templateName, items]) => {
        items.forEach((item) => {
          if (item.standardLineItemId === nodeStandardLineItemId && !this.allLineItemIdsInWorkingTree.includes(nodeStandardLineItemId)) {
            item.isInWorkingTemplate = false
            item.isSelected = false
            return
          }
        })
      })

      delete this.templateItemAdditionalAttributes[nodeStandardLineItemId]
      if (!node.children) {
        return
      }
      node.children.forEach((child) => {
        this.removeNodesFromWorkingTreeLineItemTrackers(child)
      })
  }

  workingTreeAllowDrag(element) {
    return !element.data.isStatic;
  }

  deleteNode(node, tree) {
    const parentNode = node.realParent ? node.realParent : node.treeModel.virtualRoot;
    _.remove(parentNode.data.children, function (child) {
      return child === node.data;
    });
    tree.treeModel.update();
    if (parentNode.data.children.length === 0) {
      parentNode.data.hasChildren = false;
    }
  }

  toggleHideShowValidator(event): void {
    this.showItemvalidator = event.target.checked;
    if (this.showItemvalidator && !this.editNode.data.validator) {
      this.editNode.data.validator = {
        comparer: '',
        thresholdEquation: '=1.25',
        mark: 'INSUFFICIENT'
      }
    } else {
      this.editNode.data.validator = {
        comparer: '',
        thresholdEquation: '',
        mark: ''
      }
    }
  }

  toggleBold(): void {
    this.editNode.data.displayBold = !this.editNode.data.displayBold;
  }

  addTemplateSection(): void {
    this.popupService.open({
      componentType: AddCustomItemToTemplatePopupComponent,
      inputs: {
        hasRollup: true
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          // withing the landscape of a GCF, there is a top level REF that wraps the template so we add the new
          // section as a child of this root node
          this.pushNewTemplateSectionToWorkingTree(data.detail)
        }
      }, {once: true});
    });
  }

  pushNewTemplateSectionToWorkingTree(templateSectionNode) {
    this.templateItemAdditionalAttributes[templateSectionNode.standardLineItemId] = {
      isEntitySubsection: false,
      isTemplateCalculation: false,
      isTemplateSection: true,
    }

    this.workingNodes[0].children.push(templateSectionNode)
    this.workingTree.treeModel.update()
  }

  addEntitySubSection(): void {
    this.popupService.open({
      componentType: AddCustomItemToTemplatePopupComponent,
      inputs: {
        title: 'ADD ENTITY SUBSECTION',
        description: 'Add a new entity subsection section to the template structure.',
        hasRollup: true
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          this.pushNewEntitySubSectionToWorkingTree(data.detail)
        }
      }, {once: true});
    });
  }

  retrieveStandardTemplateOptionsForTemplateSpecificEntitySubsectionModal(idOfBaseEntitySubsection) {
    const standardTemplateIdsToNotIncludeInOptions = [];
    for (const additionalAttributeData of Object.values(this.templateItemAdditionalAttributes)) {
      if (
        additionalAttributeData.hasOwnProperty('idOfBaseEntitySubsection') &&
        additionalAttributeData['idOfBaseEntitySubsection'] === idOfBaseEntitySubsection &&
        additionalAttributeData.hasOwnProperty('standardSpreadingTemplateThatEntitySubsectionAppliesTo') &&
        !standardTemplateIdsToNotIncludeInOptions.includes(additionalAttributeData['standardSpreadingTemplateThatEntitySubsectionAppliesTo'])
      ) {
        standardTemplateIdsToNotIncludeInOptions.push(additionalAttributeData['standardSpreadingTemplateThatEntitySubsectionAppliesTo'])
      }
    }
    const standardTemplateOptions: Array<Select2OptionData> = [];
    for (const [standardTemplateName, standardTemplateId] of Object.entries(this.templateNameToIdMapping)) {
      if (!standardTemplateIdsToNotIncludeInOptions.includes(standardTemplateId)) {
        standardTemplateOptions.push({
          'id': String(standardTemplateId),
          'text': standardTemplateName,
        })
      }
    }
    return standardTemplateOptions;
  }

  addTemplateSpecificEntitySubsection() {
    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    const baseEntitySubsection = focusedWorkingNode.data.children.find(item => ['GenericStandardItem', 'StandardItem'].includes(item['className']));
    if (!baseEntitySubsection) {
      this._alertService.error("Could not create enity subsection for a specific template.")
    }

    const nameOfBaseEntitySubsection = baseEntitySubsection.label;
    const idOfBaseEntitySubsection = baseEntitySubsection.standardLineItemId;
    this.popupService.open({
      componentType: AddEntitySubsectionForSpecificTemplateComponent,
      inputs: {
        standardTemplateOptions: this.retrieveStandardTemplateOptionsForTemplateSpecificEntitySubsectionModal(idOfBaseEntitySubsection),
        nameOfBaseEntitySubsection: nameOfBaseEntitySubsection,
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          this.pushNewTemplateSpecificEntitySubSectionToWorkingTree(data.detail, idOfBaseEntitySubsection)
        }
      }, {once: true});
    });
  }

  pushNewEntitySubSectionToWorkingTree(entitySubsectionNode) {
    this.templateItemAdditionalAttributes[entitySubsectionNode.standardLineItemId] = {
      isEntitySubsection: true,
      isTemplateCalculation: false,
      isTemplateSection: false,
    }

    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    focusedWorkingNode.data.children.splice(focusedWorkingNode.data.children.length - 1, 0, entitySubsectionNode);
    this.workingTree.treeModel.update();
  }

  pushNewTemplateSpecificEntitySubSectionToWorkingTree(entitySubsectionNode, idOfBaseEntitySubsection) {
    this.templateItemAdditionalAttributes[entitySubsectionNode.standardLineItemId] = {
      isEntitySubsection: true,
      isTemplateCalculation: false,
      isTemplateSection: false,
      idOfBaseEntitySubsection: idOfBaseEntitySubsection,
      standardSpreadingTemplateThatEntitySubsectionAppliesTo: entitySubsectionNode.standardSpreadingTemplateThatEntitySubsectionAppliesTo,
    }

    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    focusedWorkingNode.data.children.splice(focusedWorkingNode.data.children.length - 1, 0, entitySubsectionNode);
    this.workingTree.treeModel.update();
  }

  doesEntitySubsectionExistForTemplateSection() {
    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    return focusedWorkingNode.data.children.find(item => ['GenericStandardItem', 'StandardItem'].includes(item['className'])) !== undefined;
  }

  addGenericCalculation(isTemplateCalculation = false): void {
    this.popupService.open({
      componentType: CreateGenericEquationComponent,
      cssClass: 'modal-add-user',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          this.pushGenericCalculationToWorkingTree(data.detail, isTemplateCalculation)
        }
      }, {once: true});
    });
  }

  pushGenericCalculationToWorkingTree(genericCalculationNode, isTemplateCalculation = false) {
    if (isTemplateCalculation) {
        this.templateItemAdditionalAttributes[genericCalculationNode.standardLineItemId] = {
          isEntitySubsection: false,
          isTemplateCalculation: true,
          isTemplateSection: false,
        }
      }

    const focusedWorkingNode = this.workingTree.treeModel.getFocusedNode();
    focusedWorkingNode.data.children.splice(focusedWorkingNode.data.children.length - 1, 0, genericCalculationNode);
    this.workingTree.treeModel.update();
  }

  beginImportExport() {
    const data = {
      'items': this.workingNodes,
      'templateType': this.templateType,
      'additionalAttributes': this.templateItemAdditionalAttributes
    }
    this.popupService.open({
      componentType: ImportExportTemplateComponent,
      cssClass: 'modal-add-api-key',
      inputs: {
        exportJson: JSON.stringify(data),
        templateId: this.templateId
      },
      outputs: {
        onImport: (data: any) => {
          if (data.templateType !== this.templateType) {
            this._alertService.error('Can not import template of different type');
            return;
          }
          this.workingNodes = data.items;
          this.templateItemAdditionalAttributes = data.additionalAttributes
          this.workingTree.treeModel.update();

          this.popupService.closeAll();
          this._alertService.success('Imported Template');
        }
      }
    }).then((popup: NgxPopupComponent) => {
      // do nothing
    });
  }

  toggleTemplateNameEditMode() {
    if (this.isTemplateNameBeingEdited && this.spreadingTemplate.name === '') {
      this._alertService.error('Spreading template name cannot be empty');
    } else {
      this.isTemplateNameBeingEdited = !this.isTemplateNameBeingEdited;
    }
  }

  isItemTemplateSection(standardLineItemId) {
    if (!(standardLineItemId in this.templateItemAdditionalAttributes)) {
      return false
    } else {
      return this.templateItemAdditionalAttributes[standardLineItemId].isTemplateSection
    }
  }

  isItemEntitySubsection(standardLineItemId) {
    if (!(standardLineItemId in this.templateItemAdditionalAttributes)) {
      return false
    } else {
      return this.templateItemAdditionalAttributes[standardLineItemId].isEntitySubsection
    }
  }

  flattenTaxonomyItems(taxonomyItems) {
    let flattenedTaxonomyItems = [];
    for (const taxonomyItem of taxonomyItems) {
      // filters out the rollup taxonomy items because they have string ids
      if (typeof taxonomyItem.standardLineItemId !== 'string') {
        flattenedTaxonomyItems.push(taxonomyItem);
      }
      if (taxonomyItem.children && taxonomyItem.children.length > 0) {
        flattenedTaxonomyItems = flattenedTaxonomyItems.concat(this.flattenTaxonomyItems(taxonomyItem.children));
      }
    }
    return flattenedTaxonomyItems;
  }

  toggleDrawerState(templateIdx) {
    this.templateAvailableItemDrawerState[templateIdx] = !this.templateAvailableItemDrawerState[templateIdx]
  }

  enqueueOrDequeueAvailableItem(taxonomyItem, checkboxValue) {
    if (checkboxValue) {
      this.addAvailableItemToQueue(taxonomyItem)
    } else {
      this.removeAvailableItemFromQueue(taxonomyItem)
    }
  }

  addAvailableItemToQueue(taxonomyItem) {
    this.availableItemsQueuedForWorkingTempate.push(taxonomyItem)
    const standardLineItemIdForAddedItem = taxonomyItem.standardLineItemId
    Object.entries(this.availableTaxonomyItemsSeparatedByTemplate).forEach(([templateName, items]) => {
      items.forEach((item) => {
        if (item.standardLineItemId === standardLineItemIdForAddedItem) {
          item.isSelected = true
        }
      })
    })
  }

  removeAvailableItemFromQueue(taxonomyItem) {
    for (let idx = 0; idx < this.availableItemsQueuedForWorkingTempate.length; idx++) {
      if (this.availableItemsQueuedForWorkingTempate[idx].standardLineItemId === taxonomyItem.standardLineItemId) {
        this.availableItemsQueuedForWorkingTempate.splice(idx, 1)
        break
      }
    }
    const standardLineItemIdForAddedItem = taxonomyItem.standardLineItemId
    Object.entries(this.availableTaxonomyItemsSeparatedByTemplate).forEach(([templateName, items]) => {
      items.forEach((item) => {
        if (item.standardLineItemId === standardLineItemIdForAddedItem) {
          item.isSelected = false
        }
      })
    })
  }
}
