import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input() progress = 0;

  progressWidth = '0%';

  constructor() {}

  ngOnChanges() {
      this.progressWidth = `${this.progress}%`;
  }
}
