<div class="doc-type-select-container">
	<div class="row doc-name-row">
		<div class="col-xs-12">
			<div class="pull-left">
				<app-file-icon size="30px" contentType="{{submission.fileItem.file.type}}"></app-file-icon>
				<span class="doc-name">{{submission.originalDocumentName}}</span>
			</div>

			<div class="pull-right">
				<i class="fas fa-times cancel-submission" (click)="cancelSubmission()"></i>
			</div>
		</div>
	</div>

	<div *ngIf="submission.state == states.FileUploadError">
		<h3>There was an error attempting to upload your file. Please try again and if this error persists click
			"Contact Us" on the left hand side.</h3>
	</div>

	<div *ngIf="submission.state == states.FileUploaded">
		<form class="form-horizontal">
			<div class="row">
				<div class="col-xs-12">
					<label class="form-label">
						What information is in this document?
					</label>
				</div>
			</div>

			<div class="mb-3 no-margin-bottom">
				<div class="row">
					<div class="col-xs-6">
						<input class="statements" type="checkbox" id="INCOME_STATEMENT"
							(change)="setStatementType('INCOME_STATEMENT')"
							[checked]="selectedTypes.includes('INCOME_STATEMENT')">
						<label class="control-label" for="INCOME_STATEMENT">Income Statement</label>
					</div>
					<div class="col-xs-6">
						<input class="statements" type="checkbox" id="CASH_FLOW_STATEMENT"
							(change)="setStatementType('CASH_FLOW_STATEMENT')"
							[checked]="selectedTypes.includes('CASH_FLOW_STATEMENT')">
						<label class="control-label" for="CASH_FLOW_STATEMENT">Cash Flow</label>
					</div>
				</div>

				<div class="row">
					<div class="col-xs-6">
						<input class="statements" type="checkbox" id="BALANCE_SHEET"
							(change)="setStatementType('BALANCE_SHEET')"
							[checked]="selectedTypes.includes('BALANCE_SHEET')">
						<label class="control-label" for="BALANCE_SHEET">Balance Sheet</label>
					</div>
					<div class="col-xs-6">
						<input class="statements" type="checkbox" id="TAX_RETURN"
							(change)="setStatementType('TAX_RETURN')" [checked]="selectedTypes.includes('TAX_RETURN')">
						<label class="control-label" for="TAX_RETURN">Tax Return</label>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-6">
						<input class="statements" type="checkbox" id="OTHER" (change)="setStatementType('OTHER')"
							[checked]="selectedTypes.includes('OTHER')">
						<label class="control-label" for="OTHER">Other</label>
					</div>
					<div class="col-xs-6">

					</div>
				</div>
			</div>
			<ng-container *ngIf="isTaxReturnSelectedObs | async">
				<div class="row">
					<div class="col-xs-12">
						<label class="form-label">
							What tax form is in the document?
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<select class="tax-select form-select" (change)=updateTaxForm($event.target.value)>
							<option *ngFor="let item of SUPPORTED_TAX_FORMS" value="{{item}}">{{item}}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<label class="form-label">
							What is the tax return year?
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<select class="tax-select form-select" (change)=updateTaxYear($event.target.value)>
							<option *ngFor="let item of SUPPORTED_TAX_YEARS" value="{{item}}">{{item}}</option>
						</select>
					</div>
				</div>
			</ng-container>

		</form>
	</div>

	<div id="uploader"
		*ngIf="submission.state == states.Init || submission.state == states.FileChoosen || submission.state == states.FileUploading">
		<app-progress-bar [progress]="submission.uploadProgress"></app-progress-bar>
	</div>

</div>
