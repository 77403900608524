import { CurrencyPipe, DecimalPipe } from '../../../node_modules/@angular/common';
import { Injectable } from '@angular/core';
import {TEXT_FORMAT_TYPES, SUPPORTED_CURRENCIES, DEFAULT_CURRENCY} from '@utils/constants';
import { DataViewRow } from '@models/dataview';
import {strings} from "@angular-devkit/core";

interface NumberFormatOptions {
  isRoundedToThousands?: boolean;
  showDollarSign?: boolean;
  currency?: string;
  negativeShownAsParentheses?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NumberFormattingService {

  constructor(
    private _decimalPipe: DecimalPipe,
  ) { }

  shouldWeFallbackToDefaults(rows: Array<DataViewRow>): boolean {
    return rows.map(row => row.rowFormat.numDecimals === 0).every(value => value);
  }

  formatNumber(value: number, textFormat, numDecimals: number = null, options: NumberFormatOptions = { isRoundedToThousands: false, showDollarSign: false, currency: DEFAULT_CURRENCY, negativeShownAsParentheses: true }): string {
    if (value === null) {
      return '-';
    }

    if ((value === null || value === undefined) && (textFormat === null || textFormat === undefined)) {
      return '-';
    }

    // add checks to make sure whatever is provided for options, we still have isRoundedToThousands and showDollarSign accounted for
    let isRoundedToThousands = false; // some methods assume round to occurs by default
    let showDollarSign = false;
    let negativeShownAsParentheses = true;
    let currency = '';
    let currencySymbol = '';
    let useDefaultFallbacks = false;

    if ('isRoundedToThousands' in options && options['isRoundedToThousands'] !== undefined) {
      isRoundedToThousands = options.isRoundedToThousands;
    }

    if ('showDollarSign' in options && options['showDollarSign'] !== undefined) {
      showDollarSign = options.showDollarSign;
    }

    if (options?.currency) {
      currency = options.currency;
      currencySymbol = SUPPORTED_CURRENCIES.find(tmpCurrency => tmpCurrency.key === currency).symbol;
    }

    if ('negativeShownAsParentheses' in options && options['negativeShownAsParentheses'] !== undefined) {
      negativeShownAsParentheses = options.negativeShownAsParentheses;
    }

    if ('rows' in options && options['rows'] !== undefined) {
      // @ts-ignore
      useDefaultFallbacks = this.shouldWeFallbackToDefaults(options['rows']);
    }

    if (numDecimals === undefined || numDecimals === null || useDefaultFallbacks) {
      switch (textFormat) {
        case TEXT_FORMAT_TYPES.PERCENT:
          numDecimals = 1;
          break;
        case TEXT_FORMAT_TYPES.RATIO:
          numDecimals = 2;
          break;
        case TEXT_FORMAT_TYPES.CURRENCY:
          numDecimals = 2;
          break;
        default:
          numDecimals = 0;
          break;
      }
    }

    switch (textFormat) {
      case TEXT_FORMAT_TYPES.PERCENT:
        return (value * 100).toFixed(numDecimals).toString() + '%';
      case TEXT_FORMAT_TYPES.CURRENCY:
      return this._currencyDisplay(this._decimalPipe.transform(this._roundToThousands(value, isRoundedToThousands).toFixed(numDecimals), `1.${numDecimals}-${numDecimals}`), showDollarSign, currencySymbol, negativeShownAsParentheses);
      case TEXT_FORMAT_TYPES.RATIO:
        return value.toFixed(numDecimals).toString() + 'x';
      case TEXT_FORMAT_TYPES.DAYS:
        const days = value.toFixed(numDecimals).toString()
        const dayStr = (days === '1') ? ' day' : ' days'
        return days + dayStr;
      default:
        const digitsInfo = `1.${numDecimals}-${numDecimals}`;
        return this._currencyDisplay(this._decimalPipe.transform(this._roundToThousands(value, isRoundedToThousands).toFixed(numDecimals), digitsInfo), showDollarSign, currencySymbol, negativeShownAsParentheses);
    }

  }

  _currencyDisplay(val: string, showDollarSign = true, currencySymbol: string, negativeShownAsParentheses = true) {
    if (val.startsWith('-') && negativeShownAsParentheses) {
      val = `(${val.substr(1)})`;
    } else if (val.startsWith('-')) {
      if (showDollarSign) {
        return `-` + currencySymbol + `${val.substr(1)}`;
      }
    }

    if (showDollarSign) {
      val = currencySymbol + val;
    }

    return val;
  }

  _roundToThousands(val: number, isRoundedToThousands: boolean) {
    if (isRoundedToThousands) {
      val = val / 1000;
    }

    return val;
  }

}
