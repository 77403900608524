/*CORE*/
import { NgModule, ErrorHandler, APP_INITIALIZER, Injectable } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Router } from '@angular/router';
import { Observable ,  throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
/*MODULES*/
import { MomentModule } from 'ngx-moment';
import { TextMaskModule } from 'angular2-text-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { JwtModule } from '@auth0/angular-jwt';
import { LocalStorageModule } from './shared/local-storage/local-storage.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TreeModule } from '@circlon/angular-tree-component';

/*MIDDLEWARE*/
import { AuthMiddleware } from '../middlewares/auth.middleware';
import { PortalAuthMiddleware } from '../middlewares/portal-auth.middleware';
import { PipesModule } from '../pipes/pipes.module';
import { AlertService } from '../services/alert.service';
/*SERVICES*/
import { FincuraApiModule } from '@services/fincura-ng-client';
import { AuthenticationService } from '@services/authentication.service';
import { DocumentFileService } from '@services/document-file.service';
import { ReviewQueueService } from '@services/review-queue.service';
import { SharedDataModule } from '@services/shared-data.module';
import { UserService } from '@services/user.service';
import { LaunchDarklyService } from '@services/launchdarkly.service';
import { FormulaService } from '@services/formula.service';
import { DataViewService } from '@services/data-view.service';
import { LineItemService } from '@services/lineitem.service';
import { PreviousRouteService } from '@services/previous-route.service';
import { SpreadingHistoryService } from '@services/spreading-history.service';
import { CustomAttributeDefinitionService } from '@services/custom-attribute-definition.service';
import { InsightsService } from '@services/insights.service';

import { AppComponent } from './app.component';
/*COMPONENTS*/
import { CompanyFormComponent } from './main/borrower/list/company-form/company-form.component';
import { BorrowerService } from '../services/borrower.service';
import { CompanyDetailsComponent } from './main/borrower/details/details.component';
import { CompanyListComponent } from './main/borrower/list/list.component';
import { HeaderComponent } from './main/header/header.component';
import { MainComponent } from './main/main.component';
import { UserProfileComponent } from './main/users/profile/profile.component';
import { UserAddComponent } from './main/users/add/add.component';
import { UsersListComponent } from './main/users/list/list.component';
import { CurrencyFormatSwitcherModule } from './shared/currency-format-switcher/currency-format-switcher.module';
import { CurrencyModule } from './shared/currency/currency.module';
import { DirectiveModule } from './shared/directives/directives.module';
import { EditSumInputComponent } from './shared/formula/edit-sum-input.component';
import { NgxMyDatePickerModule } from './shared/ngx-my-date-picker';
import { NgxDOMComponentModule } from './shared/ngx-popups/ngx-dom-component/ngx-dom-component.module';
import { NgxPopupModule } from './shared/ngx-popups/ngx-popups/ngx-popups.module';
import { NotesModule } from './shared/notes/notes.module';
import { ConfirmationPopupComponent } from './shared/popups/confirmation/confirmation-popup.component';
import { UploadingPopupComponent } from './shared/popups/uploading/uploading-popup.component';
import { SavingPopupComponent } from './shared/popups/saving/saving-popup.component';
import { AddFyeChangeComponent } from './shared/popups/add-fye-change/add-fye-change.component';
import { ShowTransactionIdPopupComponent } from './shared/popups/show-transaction-id/show-transaction-id.component';
import { ResolveConflictPopupComponent } from './shared/popups/resolve-conflict/resolve-conflict.component';
import { ContactSupportComponent } from './shared/popups/contact-support/contact-support.component';
import { PopupsModule } from './shared/popups/popups.module';
import { UserLoginComponent } from './user/login/login.component';
import { SwitchTenantComponent } from './user/switch-tenant/switch-tenant.component';
import { UserLogoutComponent } from './user/logout/logout.component';
import { HttpClientModule, HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { DataService } from './main/borrower/details/data/data.service';
import { SideNavComponent } from './main/side-nav/side-nav.component';
import { Select2Module } from './shared/select2/select2.module';
import { CompanyProfileComponent } from './main/borrower/details/profile/company-profile.component';
import { FormulaInputPickerModule } from './shared/formula/formula-input-picker';
import { CompanyComplianceComponent } from './main/borrower/details/compliance/compliance.component';
import { ComplianceCovenantTableComponent } from './main/borrower/details/compliance/covenant-table/covenant-table.component';
import { PopoverModule } from './shared/popover/popover.module';
import { DrawerModule } from './shared/drawer/drawer.module';
import { ActionService } from '../services/action.service';
import { PortalComponent } from './portal/portal.component';
import { AccessDeniedComponent } from './main/denied/access-denied.component';
import { BorrowerUploadComponent } from './portal/borrower-upload/borrower-upload.component';
import { DocumentSubmissionComponent } from './portal/borrower-upload/document-submission/document-submission.component';
import { ProgressBarComponent } from './shared/progress-bar/progress-bar.component';
import { FileIconComponent } from './shared/file-icon/file-icon.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { ReviewComponent } from './main/review/review.component';
import { ReviewEditorComponent } from './main/review/review-editor/review-editor.component';
import { ReviewQueueListComponent } from './main/review/review-list/review-list.component';
import { RequestStatementsComponent } from './shared/popups/request-statements/request-statements.component';
import { InstructionsGifPopupComponent } from './shared/popups/instructions-gif-popup/instructions-gif-popup.component';
import { BorrowerNavComponent } from './main/borrower-nav/borrower-nav.component';
import { MaximizedSidebarComponent } from './main/review/maximized-sidebar/maximized-sidebar.component';

import { metaReducers, reducers } from '../store/reducers';
import { CustomRouterStateSerializer } from '../utils/router-state-serializer';
import { routes } from './app.routes';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HotTableModule } from '@handsontable/angular';
import { SegmentModule } from 'ngx-segment-analytics';
import { ENV_CONVENTION } from '../utils/constants';
import { CommonFunctions } from '../utils/common-functions';

// @ts-ignore
import packageJson from '../../../package.json';

import { ShareLinkComponent } from './shared/popups/request-statements/share-link/share-link.component';
import { SignedUrlService } from '../services/signed-url.service';
import { TrackingService } from '../services/tracking.service';
import { DownloadService } from '../services/download.service';
import { NotFoundComponent } from './main/not-found/not-found.component';
import { DeveloperModule } from './developer/developer.module';
import { AppConfigService } from '../services/app-config.service';
import { DataFrameService } from '../services/dataframes.service';
import { EnvironmentService } from '../services/environment.service';
import { PortfolioService } from '../services/portfolio.service';
import { DataViewGraphComponent } from './shared/graph/graph.component';
import { DecimalPipe, PercentPipe, CurrencyPipe } from '@angular/common';
import { UploadStatementButtonComponent } from './shared/upload-statement/upload-statement.component';
import { ImageHighlighterComponent } from '@components/shared/document-viewer/image-highlighter/image-highlighter.component';
import { FullScreenComponent } from '@components/shared/document-viewer/full-screen/full-screen.component';
import { ExtendedSideNavComponent } from './main/side-nav/extended-side-nav/extended-side-nav.component';
import { MinimizedSideNavComponent } from './main/side-nav/minimized-side-nav/minimized-side-nav.component';
import { PortfolioTagComponent } from './main/borrower/list/portfolio-tag/portfolio-tag.component';
import { UploadStatusPopoverComponent } from './main/header/upload-status-popover/upload-status-popover.component';
import { ParsingTrackerStatusComponent } from './main/header/upload-status-popover/parsing-tracker-status/parsing-tracker-status.component';
import { ParsingTrackerItemComponent } from './main/header/upload-status-popover/parsing-tracker-item/parsing-tracker-item.component';
import { ProcesslingListModule } from './shared/processing-list/processing-list.module';
import { BarChartComponent } from './shared/bar-chart/bar-chart.component';
import { GaugeChartComponent } from './shared/gauge-chart/gauge-chart.component';
import { BarWithLineChartComponent } from './shared/bar-with-line-chart/bar-with-line-chart.component';
import { GroupedBarChartComponent } from './shared/grouped-bar-chart/grouped-bar-chart.component';
import { NoAxisBarChartComponent } from './shared/no-axis-bar-chart/no-axis-bar-chart.component';
import { BorrowerOverviewComponent } from './main/borrower/details/borrower-overview/borrower-overview.component';
import { LineItemTileComponent } from './main/borrower/details/borrower-overview/line-item-tile/line-item-tile.component';
import { IncomeStatementTableTileComponent } from './main/borrower/details/borrower-overview/income-statement-table-tile/income-statement-table-tile.component';
import { EbitdaGraphTileComponent } from './main/borrower/details/borrower-overview/ebitda-graph-tile/ebitda-graph-tile.component';
import { OpexGraphTileComponent } from './main/borrower/details/borrower-overview/opex-graph-tile/opex-graph-tile.component';
import { CccGraphTileComponent } from './main/borrower/details/borrower-overview/ccc-graph-tile/ccc-graph-tile.component';
import { CurrentWorkingCapitalTableTileComponent } from './main/borrower/details/borrower-overview/current-working-capital-table-tile/current-working-capital-table-tile.component';
import { GaugeTileComponent } from './main/borrower/details/borrower-overview/gauge-tile/gauge-tile.component';
import { NetWorthTableTileComponent } from './main/borrower/details/borrower-overview/net-worth-table-tile/net-worth-table-tile.component';
import { NetWorthGraphTileComponent } from './main/borrower/details/borrower-overview/net-worth-graph-tile/net-worth-graph-tile.component';
import { BankSettingsService } from '../services/bank-settings.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { TemplateRowComponent } from './main/spreading/rows/template-row/template-row.component';
import { SpreadingAlertComponent } from './main/spreading/modals/spreading-alert/spreading-alert.component';
import { DataFrameValueFormatterPipe } from '../pipes/data-frame-value-formatters';
import { SpreadingMapsService } from '../services/spreading-map.service';
import { SpreadingTemplateService } from '../services/spreading-template.service';
import { SidebarComponent } from './portal/borrower-upload/sidebar/sidebar.component';
import { MainContentComponent } from './portal/borrower-upload/main-content/main-content.component';
// import { TreeMapComponent } from './shared/tree-map/tree-map.component';
import { DonutChartComponent } from './shared/donut-chart/donut-chart.component';
import { KeyboardShortcutsComponent } from './shared/keyboard-shortcuts/keyboard-shortcuts.component';
import { ReviewTableIdentificationComponent } from './main/review/human-validation/review-table-identification/review-table-identification.component';
import { ReviewDocumentContainerComponent } from './main/review/review-document-container/review-document-container.component';
import { ReviewTableTabulationComponent } from './main/review/human-validation/review-table-tabulation/review-table-tabulation.component';
import { BoxDrawerComponent } from './main/review/human-validation/shared/box-drawer/box-drawer.component';
import { StatusModalComponent } from './main/review/human-validation/shared/status-modal/status-modal.component';
import { DigitizationTopNavComponent } from './shared/digitization-top-nav/digitization-top-nav.component';
import { CurrencyFormattingService } from '../services/currency-formatting.service';
import { NumberFormattingService } from '../services/number-formatting.service';
import { LockedTakeoverComponent } from './shared/locked-takeover/locked-takeover.component';
import { AnalysisComponent } from './main/borrower/details/financials/analysis/analysis.component';
import { FinancialsTableComponent } from './main/borrower/details/financials/analysis/view-templated/table/financials-table.component';
import { TableCellComponent } from './main/borrower/details/financials/analysis/view-templated/table/cell/table-cell.component';
import { StatementDataService } from './main/spreading/data/statement-data.service';
import { StatementService } from '../services/statement.service';
import { ThirdPartyErrorHandlerService } from '../services/third-party-error-handler.service';
import { DoubleClickToEditComponent } from './shared/double-click-to-edit/double-click-to-edit.component';
import { SpreadingComponent } from './main/spreading/spreading.component';
import { SpreadFileRedirectComponent } from './main/spreading/spread-file-redirect/spread-file-redirect.component';
import { StatementTableCellComponent } from './main/spreading/statement-table-cell/statement-table-cell.component';
import { DynamicSearchComponent } from './main/side-nav/extended-side-nav/dynamic-search/dynamic-search.component';
import { CalculatingComponent } from './main/borrower/details/financials/analysis/calculating/calculating.component';
import { ListSpreadingTemplatesComponent } from './main/spreading-template/list-spreading-templates/list-spreading-templates.component';
import { EditSpreadingTemplateComponent } from './main/spreading-template/edit-spreading-template/edit-spreading-template.component';
import { TemplateListItemComponent } from './main/spreading-template/list-spreading-templates/template-list-item/template-list-item.component';
import { GenerateReportComponent } from './main/reporting/generate-report/generate-report.component';
import { ReportingService } from '../services/reporting.service';
import { ForceReloadService } from '../services/force-reload.service';
import { AppStatusComponent } from './main/app-status/app-status.component';
import { ListAnalysesComponent } from './main/borrower/details/list-analyses/list-analyses.component';
import { AnalysisListItemComponent } from './main/borrower/details/list-analyses/analysis-list-item/analysis-list-item.component';
import { AnalysisFormComponent } from './shared/analysis-form/analysis-form.component';
import { EditAnalysisComponent } from './main/borrower/details/list-analyses/edit-analysis/edit-analysis.component';
import { CreateGenericEquationComponent } from './main/spreading-template/create-generic-equation/create-generic-equation.component';
import { ProposedDebtTileComponent } from './main/borrower/details/list-analyses/edit-analysis/proposed-debt-tile/proposed-debt-tile.component';
import { DebtServiceCoverageTableComponent } from './main/borrower/details/list-analyses/edit-analysis/debt-service-coverage-table/debt-service-coverage-table.component';
import { DebtServiceCoverageTableCellComponent } from './main/borrower/details/list-analyses/edit-analysis/debt-service-coverage-table/debt-service-coverage-table-cell/debt-service-coverage-table-cell.component';
import { ApiKeysListComponent } from './main/api-key/api-keys-list/api-keys-list.component';
import { ApiKeysAddComponent } from './main/api-key/api-keys-add/api-keys-add.component';
import { ApiKeyRevokeComponent } from './main/api-key/api-key-revoke/api-key-revoke.component';
import { ApiSwaggerUiComponent } from './main/api-key/docs/api-swagger-ui/api-swagger-ui.component';
import { ApiRedocComponent } from './main/api-key/docs/api-redoc/api-redoc.component';
import { ListCovenantsComponent } from './main/borrower/details/list-covenants/list-covenants.component';
import { NonFinancialCovenantFormComponent } from './shared/non-financial-covenant-form/non-financial-covenant-form.component';
import { FinancialCovenantFormComponent } from './shared/financial-covenant-form/financial-covenant-form.component';
import { LoanFormComponent } from './shared/loan-form/loan-form.component';
import { InputCalendarModule } from './shared/calendars/input-calendar/input-calendar.module';
import { LoanDetailsModalComponent } from './shared/loan-details-modal/loan-details-modal.component';
import { CreateSubItemComponent } from './main/spreading-template/create-sub-item/create-sub-item.component';
import { LoanCommentaryTileComponent } from './main/borrower/details/list-analyses/edit-analysis/loan-commentary-tile/loan-commentary-tile.component';
import { DocumentThumbnailComponent } from './main/review/human-validation/shared/document-thumbnail/document-thumbnail.component';
import { ImportExportTemplateComponent } from './main/spreading-template/import-export-template/import-export-template.component';
import { WaitForReviewComponent } from './main/review/human-validation/wait-for-review/wait-for-review.component';
import { InsightsComponent } from './shared/insights/insights.component';
import { PeriodOverPeriodComponent } from './shared/insights/period-over-period/period-over-period.component';
import { AccessExpiredComponent } from './main/expired/access-expired/access-expired.component';
import { AccountingIntegrationImportComponent } from '@components/main/borrower/accounting-integration/accounting-integration-import/accounting-integration-import.component';
import { AccountingIntegrationLogoComponent } from '@components/main/borrower/accounting-integration/accounting-integration-logo/accounting-integration-logo.component';
import { CreateInviteLinkComponent } from './main/borrower/accounting-integration/create-invite-link/create-invite-link.component';
import { AccountingIntegrationInviteComponent } from './portal/accounting-integration-invite/accounting-integration-invite.component';
import { StatementBuilderComponent } from './main/borrower/statement-builder/statement-builder.component';
import { BuilderFinancialsTableComponent } from './main/borrower/statement-builder/builder-financials-table/builder-financials-table.component';
import { EmbeddedFinancialsComponent } from './main/embedded/embedded-financials/embedded-financials.component';
import { EmbeddedComponentContainerComponent } from './main/embedded/embedded-component-container/embedded-component-container.component';
import { WindowService } from '@services/window.service';
import { CommentPresentIndicatorComponent } from './shared/comment-present-indicator/comment-present-indicator.component';
import { CommentComponent } from './shared/comment/comment.component';
import { CellInformationComponent } from './main/borrower/details/financials/analysis/view-templated/table/cell/cell-information/cell-information.component';
import { EmbeddedDscrAnalysisComponent } from './main/embedded/embedded-dscr-analysis/embedded-dscr-analysis.component';
import { PromptForProjectionNameComponent } from './main/spreading/popups/prompt-for-projection-name/prompt-for-projection-name.component';
import { ConfirmDetailsComponent } from '@components/main/review/human-validation/confirm-details/confirm-details.component';
import { TaxFormTypeAndYearConfirmationComponent } from '@components/shared/popups/tax-form-type-and-year-confirmation/tax-form-type-and-year-confirmation.component';
import { GlobalCashflowAnalysisFormComponent } from './shared/global-cashflow-analysis-form/global-cashflow-analysis-form.component';
import { EditGlobalCashflowAnalysisComponent } from './main/borrower/details/list-analyses/edit-global-cashflow-analysis/edit-global-cashflow-analysis.component';
import { GlobalCashflowTableComponent } from './main/borrower/details/list-analyses/edit-global-cashflow-analysis/global-cashflow-table/global-cashflow-table.component';
import { EmbeddedGlobalCashflowAnalysisComponent} from './main/embedded/embedded-global-cashflow-analysis/embedded-global-cashflow-analysis.component';
import { EditTemplateMappingsComponent } from './main/spreading-template/edit-spreading-template/edit-template-mappings/edit-template-mappings.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import {
  DocumentPasswordEntryComponent
} from '@components/main/review/human-validation/document-password-entry/document-password-entry.component';
import {
  DocumentPasswordEntryModalComponent
} from '@components/shared/popups/document-password-entry-modal/document-password-entry-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  EditMultiEntitySpreadingTemplateComponent
} from '@components/main/spreading-template/multi-entity/edit-multi-entity-spreading-template.component';
import {
  AddCustomItemToTemplatePopupComponent
} from '@components/main/spreading-template/add-custom-item-to-template-popup/add-custom-item-to-template-popup.component';
import {
  AddAvailableItemsToWorkingTemplatePopoverComponent
} from '@components/main/spreading-template/multi-entity/add-available-items-to-working-template-popover/add-available-items-to-working-template-popover.component';
import * as Sentry from "@sentry/angular";
import { TraceModule } from '@sentry/angular';
import { SelectSpreadingTemplateModalComponent } from './main/spreading/modals/select-spreading-template-modal/select-spreading-template-modal.component';
import { PdfDownloadModalComponent } from './shared/pdf-download-modal/pdf-download-modal.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { BuildUcaEquationComponent } from './main/spreading-template/build-uca-equation/build-uca-equation.component';
import { NormalBalanceSelectorComponent } from './main/spreading-template/build-uca-equation/normal-balance-selector/normal-balance-selector.component';
import {ManageUserMiddleware} from "@app/middlewares/manageUser.middleware";
import {ManageSpreadingTemplateMiddleware} from "@app/middlewares/manageSpreadingTemplate.middleware";
import {ReportingMiddleware} from "@app/middlewares/reporting.middleware";
import {EnterHILStepMiddleware} from "@app/middlewares/enterHILStep.middleware";
import {ViewOrEditSpreadAndAnalysisMiddleware} from "@app/middlewares/viewOrEditSpreadAndAnalysis.middleware";
import {BorrowerAlertModule} from "@components/main/borrower/borrower-alert/borrower-alert.module";
import { TableEmptyStateModule } from './shared/table-empty-state/table-empty-state.module';
import {SubmissionReviewQueueMiddleware} from "@app/middlewares/submissionReviewQueue.middleware";
import {DefaultLandingPageMiddleware} from "@app/middlewares/defaultLandingPage.middleware";
import {LandingComponent} from "@components/main/landing/landing.component";
import {SearchBorrowerMiddleware} from "@app/middlewares/searchBorrower.middleware";
import { ChangeLogModalComponent } from './shared/change-log-modal/change-log-modal.component';
import { PortfolioManagementComponent } from './main/portfolio-management/portfolio-management.component';
import {
  PortfolioTableRowComponent
} from "@components/main/portfolio-management/components/portfolio-table-row/portfolio-table-row.component";
import {PortfolioManagementMiddleware} from "@app/middlewares/portfolio-management.middleware";
import {
  CreateOrEditPortfolioModalComponent
} from "@components/main/portfolio-management/components/create-or-edit-portfolio-modal/create-or-edit-portfolio-modal.component";
import {GenerateApiTokenMiddleware} from "@app/middlewares/generateApiToken.middleware";
import { CompanyEntitlementResolver } from 'app/resolvers/company-entitlement.resolver';
import {BorrowerListResolver} from "@app/resolvers/borrower-list-resolver";
import { DeveloperMiddleware } from 'app/middlewares/developer.middleware';
import {ListTableRowComponent} from "@components/main/users/list/list-table-row.component";
import { AddEntitySubsectionForSpecificTemplateComponent } from './main/spreading-template/multi-entity/add-entity-subsection-for-specific-template/add-entity-subsection-for-specific-template.component';
import {
  NavigationStepperComponent
} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-stepper.component";
import {
  NavigationStepComponent
} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-step/navigation-step.component";
import {
  UploadDocumentModalComponent
} from "@components/shared/popups/upload-document-modal/upload-document-modal.component";
import {
  ExcelExportReferenceUploadComponent
} from "@components/main/spreading-template/edit-spreading-template/excel-export-reference-upload/excel-export-reference-upload.component";
import {PopoverMenuModule} from "@components/shared/popover-menu/popover-menu.module";
import { StatementBuilderFlyoutComponent } from './main/borrower/details/financials/analysis/statement-builder-flyout/statement-builder-flyout.component';
import { ConsolidatePeriodsFormComponent } from './main/borrower/details/financials/analysis/statement-builder-flyout/consolidate-periods-form/consolidate-periods-form.component';
import { CalculateMissingPeriodFormComponent } from './main/borrower/details/financials/analysis/statement-builder-flyout/calculate-missing-period-form/calculate-missing-period-form.component';
import { PopoverMenuMultiSelectComponent } from './shared/popover-menu-multi-select/popover-menu-multi-select.component';
import { GenerateForecastFormComponent } from './main/borrower/details/financials/analysis/statement-builder-flyout/generate-forecast-form/generate-forecast-form.component';
import {
  SessionTimeoutWarningModalComponent
} from "@components/shared/session-timeout-warning-modal/session-timeout-warning-modal.component";
import {
  ContactSupportEmailModalComponent
} from "@components/shared/popups/contact-support-email-modal/contact-support-email-modal.component";
import {DocumentViewSplitScreenComponent} from "@components/shared/document-viewer/split-screen/document-view-split-screen.component";
import {PdfPaginatorComponent} from "@components/shared/document-viewer/pdf-toolbar/pdf-paginator/pdf-paginator.component";
import {PdfZoomComponent} from "@components/shared/document-viewer/pdf-toolbar/pdf-zoom/pdf-zoom.component";
import {
  FinancialSummarySplitScreenComponent
} from "@components/main/borrower/details/financials/analysis/financial-summary-split-screen/financial-summary-split-screen.component";
import {TableDrawingComponent} from "@components/main/spreading/table-merging/table-drawing/table-drawing.component";
import {
  TableTabulationComponent
} from "@components/main/spreading/table-merging/table-tabulation/table-tabulation.component";
import {FinalReviewComponent} from "@components/main/spreading/table-merging/final-review/final-review.component";
import {TableMergingComponent} from "@components/main/spreading/table-merging/table-merging.component";
import { FootnoteManagerComponent } from '@components/main/spreading/footnote-manager/footnote-manager.component';
import { TableDrawingPageComponent } from './shared/table-drawing-page/table-drawing-page.component';
import { FilterStatementsComponent } from "@components/shared/filter-statements/filter-statements.component";
import {
  AddItemToDASSectionPopupComponent
} from "@components/main/spreading-template/add-item-to-das-section-popup/add-item-to-das-section-popup.component";
import {
  SetCommonSizeDenominatorComponent
} from "@components/main/spreading-template/set-common-size-denominator/set-common-size-denominator.component";
import {
  TextInsightsComponent
} from "@components/main/borrower/details/financials/analysis/text-insights/text-insights.component";
import {TextInsightsService} from "@services/text-insights.service";
import {MarkdownModule} from "ngx-markdown";


@Injectable()
export class AuthFailureInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => this.handleError(err)));
    }

    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            // handle 401 or 403
            try {
              // We try to do the most useful thing first: taking the user
              // to a logout page most specific to their situation.
              if (localStorage.getItem(`sso_logout_destination`)) {
                const sso_logout_destination = localStorage.getItem(`sso_logout_destination`)
                localStorage.removeItem(`sso_logout_destination`)
                window.location.href = sso_logout_destination
              } else if (['app_token'].includes(err.error.auth_type)) {
                this.router.navigate([`/app-token-expired`]);
              } else {
                this.router.navigate([`/login`]);
              }
            } catch (ex) {}
            return of(err.message);
        }
        // otherwise rethrow
        return throwError(err);
    }
}

const appInit = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppconfig().subscribe();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserLoginComponent,
    UserLogoutComponent,
    SwitchTenantComponent,
    UserProfileComponent,
    UsersListComponent,
    CompanyFormComponent,
    CompanyListComponent,
    CompanyDetailsComponent,
    MainComponent,
    PortalComponent,
    AccessDeniedComponent,
    BorrowerUploadComponent,
    DocumentSubmissionComponent,
    ProgressBarComponent,
    UserAddComponent,
    SideNavComponent,
    CompanyProfileComponent,
    CompanyComplianceComponent,
    ComplianceCovenantTableComponent,
    EditSumInputComponent,
    ReviewComponent,
    NotFoundComponent,
    ReviewEditorComponent,
    FinancialSummarySplitScreenComponent,
    DocumentViewSplitScreenComponent,
    PdfPaginatorComponent,
    PdfZoomComponent,
    SwitchTenantComponent,
    FullScreenComponent,
    ReviewQueueListComponent,
    BorrowerNavComponent,
    DataViewGraphComponent,
    UploadStatementButtonComponent,
    ExtendedSideNavComponent,
    MinimizedSideNavComponent,
    PortfolioTagComponent,
    UploadStatusPopoverComponent,
    ParsingTrackerItemComponent,
    ParsingTrackerStatusComponent,
    AnalysisComponent,
    FinancialsTableComponent,
    TableCellComponent,
    BarChartComponent,
    GaugeChartComponent,
    // LineChartComponent,
    // StackedBarChartComponent,
    BarWithLineChartComponent,
    GroupedBarChartComponent,
    NoAxisBarChartComponent,
    BorrowerOverviewComponent,
    LineItemTileComponent,
    IncomeStatementTableTileComponent,
    EbitdaGraphTileComponent,
    OpexGraphTileComponent,
    CccGraphTileComponent,
    CurrentWorkingCapitalTableTileComponent,
    GaugeTileComponent,
    NetWorthTableTileComponent,
    NetWorthGraphTileComponent,
    SpreadingComponent,
    TemplateRowComponent,
    SpreadingAlertComponent,
    SidebarComponent,
    MainContentComponent,
    ImageHighlighterComponent,
    // TreeMapComponent,
    DonutChartComponent,
    KeyboardShortcutsComponent,
    MaximizedSidebarComponent,
    ReviewTableIdentificationComponent,
    ReviewDocumentContainerComponent,
    ReviewTableTabulationComponent,
    BoxDrawerComponent,
    StatusModalComponent,
    NavigationStepComponent,
    NavigationStepperComponent,
    DigitizationTopNavComponent,
    LockedTakeoverComponent,
    DoubleClickToEditComponent,
    SpreadFileRedirectComponent,
    StatementTableCellComponent,
    DynamicSearchComponent,
    CalculatingComponent,
    ListSpreadingTemplatesComponent,
    EditSpreadingTemplateComponent,
    TemplateListItemComponent,
    GenerateReportComponent,
    AppStatusComponent,
    ListAnalysesComponent,
    AnalysisListItemComponent,
    AnalysisFormComponent,
    EditAnalysisComponent,
    CreateGenericEquationComponent,
    ProposedDebtTileComponent,
    DebtServiceCoverageTableComponent,
    DebtServiceCoverageTableCellComponent,
    ApiKeysListComponent,
    ApiKeysAddComponent,
    ApiKeyRevokeComponent,
    ApiSwaggerUiComponent,
    ApiRedocComponent,
    ListCovenantsComponent,
    NonFinancialCovenantFormComponent,
    FinancialCovenantFormComponent,
    LoanFormComponent,
    LoanDetailsModalComponent,
    CreateSubItemComponent,
    LoanCommentaryTileComponent,
    DocumentThumbnailComponent,
    ImportExportTemplateComponent,
    WaitForReviewComponent,
    InsightsComponent,
    PeriodOverPeriodComponent,
    AccessExpiredComponent,
    TableMergingComponent,
    AccountingIntegrationImportComponent,
    AccountingIntegrationLogoComponent,
    CreateInviteLinkComponent,
    AccountingIntegrationInviteComponent,
    StatementBuilderComponent,
    BuilderFinancialsTableComponent,
    EmbeddedFinancialsComponent,
    EmbeddedComponentContainerComponent,
    CommentPresentIndicatorComponent,
    CommentComponent,
    CellInformationComponent,
    EmbeddedDscrAnalysisComponent,
    PromptForProjectionNameComponent,
    ConfirmDetailsComponent,
    GlobalCashflowAnalysisFormComponent,
    EditGlobalCashflowAnalysisComponent,
    GlobalCashflowTableComponent,
    EmbeddedGlobalCashflowAnalysisComponent,
    EditTemplateMappingsComponent,
    DocumentPasswordEntryComponent,
    EditMultiEntitySpreadingTemplateComponent,
    AddCustomItemToTemplatePopupComponent,
    AddItemToDASSectionPopupComponent,
    AddAvailableItemsToWorkingTemplatePopoverComponent,
    SelectSpreadingTemplateModalComponent,
    PdfDownloadModalComponent,
    SessionTimeoutWarningModalComponent,
    SelectSpreadingTemplateModalComponent,
    BuildUcaEquationComponent,
    NormalBalanceSelectorComponent,
    NormalBalanceSelectorComponent,
    LandingComponent,
    ChangeLogModalComponent,
    PortfolioManagementComponent,
    PortfolioTableRowComponent,
    CreateOrEditPortfolioModalComponent,
    ListTableRowComponent,
    AddEntitySubsectionForSpecificTemplateComponent,
    ExcelExportReferenceUploadComponent,
    StatementBuilderFlyoutComponent,
    ConsolidatePeriodsFormComponent,
    CalculateMissingPeriodFormComponent,
    PopoverMenuMultiSelectComponent,
    GenerateForecastFormComponent,
    TableDrawingComponent,
    TableTabulationComponent,
    FinalReviewComponent,
    FootnoteManagerComponent,
    TableDrawingPageComponent,
    FilterStatementsComponent,
    SetCommonSizeDenominatorComponent,
    TextInsightsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DeveloperModule,
    DragDropModule,
    ReactiveFormsModule,
    NgSelectModule,
    TraceModule,
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    NgxMyDatePickerModule.forRoot(),
    HotkeyModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: CommonFunctions.tokenGetter,
      },
    }),

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {metaReducers}),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
      stateKey: 'router',
    }),

    /**
     * Instrumentation must be imported after importing StoreModule (config is optional)
     *
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: (window.location.hostname.toLowerCase() === ENV_CONVENTION.PROD_HOST_MARKER), // Restrict extension to log-only mode
    }),

    FincuraApiModule.forRoot(AppConfigService.getApiConfiguration),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    NotesModule,

    /**
     * `provideDB` sets up @ngrx/db with the provided schema and makes the Database
     * service available.
     */
    MomentModule,
    PipesModule,
    DirectiveModule,
    SharedDataModule.forRoot(),
    CurrencyFormatSwitcherModule,
    CurrencyModule,
    FileUploadModule,
    TextMaskModule,
    NgxPaginationModule,
    FormulaInputPickerModule,
    PopupsModule,
    PopoverMenuModule,
    NgxPopupModule,
    NgxDOMComponentModule,
    Select2Module,
    PopoverModule,
    DrawerModule,
    LocalStorageModule,
    HotTableModule,
    NgxChartsModule,
    SatPopoverModule,
    SegmentModule.forRoot({debug: true, loadOnInitialization: false}),
    ProcesslingListModule,
    ImageCropperModule,
    InputCalendarModule,
    TreeModule,
    TextFieldModule,
    BorrowerAlertModule,
    TableEmptyStateModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    AuthMiddleware,
    PortalAuthMiddleware,
    AuthenticationService,
    AlertService,
    DocumentFileService,
    FormBuilder,
    UserService,
    BorrowerService,
    DataService,
    ActionService,
    AppConfigService,
    {provide: APP_INITIALIZER, useFactory: appInit, multi: true, deps: [AppConfigService]},
    EnvironmentService,
    LaunchDarklyService,
    FormulaService,
    SignedUrlService,
    ReviewQueueService,
    TrackingService,
    DownloadService,
    DataFrameService,
    DataViewService,
    DecimalPipe,
    PercentPipe,
    DataFrameValueFormatterPipe,
    CurrencyPipe,
    PortfolioService,
    LineItemService,
    BankSettingsService,
    SpreadingMapsService,
    SpreadingTemplateService,
    CurrencyFormattingService,
    NumberFormattingService,
    StatementDataService,
    StatementService,
    PreviousRouteService,
    ReportingService,
    SpreadingHistoryService,
    ForceReloadService,
    CustomAttributeDefinitionService,
    InsightsService,
    WindowService,
    TextInsightsService,
    /* Auth 401/303 interceptor */
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router) {
        return new AuthFailureInterceptor(router)
      },
      multi: true,
      deps: [Router]
    },
    ManageUserMiddleware,
    ManageSpreadingTemplateMiddleware,
    ReportingMiddleware,
    EnterHILStepMiddleware,
    ViewOrEditSpreadAndAnalysisMiddleware,
    SubmissionReviewQueueMiddleware,
    DefaultLandingPageMiddleware,
    SearchBorrowerMiddleware,
    PortfolioManagementMiddleware,
    GenerateApiTokenMiddleware,
    DeveloperMiddleware,

    // resolvers
    CompanyEntitlementResolver,

    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    /**
     * The SentryErrorHandler allows us to use Sentry as our central logging system for all front
     * end related errors. This will allow us to easily find errors from all of our users in one
     * place moving forward.
     */
    {provide: ErrorHandler, useClass: ThirdPartyErrorHandlerService},
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    BorrowerListResolver,
  ],
  entryComponents: [
    CompanyFormComponent,
    AnalysisFormComponent,
    NonFinancialCovenantFormComponent,
    FinancialCovenantFormComponent,
    LoanFormComponent,
    UserAddComponent,
    ContactSupportComponent,
    ContactSupportEmailModalComponent,
    ConfirmationPopupComponent,
    UploadingPopupComponent,
    ShowTransactionIdPopupComponent,
    ResolveConflictPopupComponent,
    UploadDocumentModalComponent,
    RequestStatementsComponent,
    ShareLinkComponent,
    InstructionsGifPopupComponent,
    FileIconComponent,
    SpreadingAlertComponent,
    SavingPopupComponent,
    KeyboardShortcutsComponent,
    StatusModalComponent,
    AddFyeChangeComponent,
    CreateGenericEquationComponent,
    ApiKeysAddComponent,
    ApiKeyRevokeComponent,
    LoanDetailsModalComponent,
    CreateSubItemComponent,
    ImportExportTemplateComponent,
    CreateInviteLinkComponent,
    AccountingIntegrationImportComponent,
    PromptForProjectionNameComponent,
    TaxFormTypeAndYearConfirmationComponent,
    GlobalCashflowAnalysisFormComponent,
    DocumentPasswordEntryModalComponent,
    AddCustomItemToTemplatePopupComponent,
    AddItemToDASSectionPopupComponent,
    AddAvailableItemsToWorkingTemplatePopoverComponent,
    SelectSpreadingTemplateModalComponent,
    PdfDownloadModalComponent,
    SessionTimeoutWarningModalComponent,
    CreateOrEditPortfolioModalComponent,
    ExcelExportReferenceUploadComponent,
    SetCommonSizeDenominatorComponent
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
