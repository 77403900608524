import {Component, Input} from '@angular/core';
import {NgxPopupComponent} from '../../ngx-popups/ngx-popups/components/popup.component';
import {AutoUnsubscribe} from '../../../../decorators/auto-unsubscribe';
import {Clipboard} from '@angular/cdk/clipboard';
import {AlertService} from "@services/alert.service";

@Component({
  selector: 'app-contact-support-email-modal',
  templateUrl: './contact-support-email-modal.component.html',
  styleUrls: ['./contact-support-email-modal.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ContactSupportEmailModalComponent {
  @Input() popup: NgxPopupComponent;
  @Input() supportEmail: string;

  constructor(
    private clipboard: Clipboard,
    private _alertService: AlertService
  ) {
  }

  copyToClipboard() {
    this.clipboard.copy(this.supportEmail);
    this._alertService.success('Copied to clipboard!');

  }

  copyEmail() {
    window.open(`mailto:${this.supportEmail}`);
  }


}
