import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';


import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
import { Observable } from 'rxjs';
import { Note } from '../../../models/note';
import CriteriaBuilder from '../../../utils/query/criteria';

@Injectable()
export class NotesService {

  constructor(private _apiService: ApiService,
              private _userService: UserService,
  ) {

  }

  getNotes(companyId: number, section: string): Observable<Note[]> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('company.id', companyId));
    query.add(CriteriaBuilder.equal('section', section));
    query.add(CriteriaBuilder.isNull('parent.id'));
    const url = '/api/notes/all';
    return this._apiService.send('Post', url, query.generate()).pipe(map(data => data.response.objects));
  }

  postNote(company_id: number, section: string, text: string, parent_id = null): Observable<any> {
    const data = {
      parent: parent_id !== null ? {id: parent_id} : null,
      company: {id: company_id},
      section: section,
      description: text,
      user: {id: this._userService.user.id},
    };
    return this._apiService.send('Post', '/api/notes', data).pipe(map(data => data.response.objects.pop()));
  }
}
