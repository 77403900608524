import { Component, ElementRef, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { AlertService } from '../../../services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-file-selector-button',
  templateUrl: './document-file-selector-button.component.html',
  styleUrls: ['./document-file-selector-button.component.scss']
})
export class DocumentFileSelectorButtonComponent implements OnInit {
  @Input() style = 'success';
  @Input() text = 'Choose file';
  @Input() buttonColor = null;
  @Input() btnBorderRadius = 0;
  @Input() textColor = null;
  @Output() fileChoosen: EventEmitter<object> = new EventEmitter();
  @ViewChild('uploadInput', { static: true }) uploadInputHtml: ElementRef;

  uploader: FileUploader;
  filePickerIsOpen = false;

  constructor(private _alertService: AlertService,
              private router: Router) {}

  ngOnInit() {
    this.uploader = new FileUploader({
      autoUpload: false,
      method: 'PUT',
      queueLimit: 1,
      disableMultipart: true,
      isHTML5: true,
    });

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      this.fileChoosen.emit({fileItem, uploader: this.uploader});
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploader.clearQueue();
      this.uploadInputHtml.nativeElement.value = '';
      const parsedResponse = JSON.parse(response);
      if (status === 401 || status === 403) {
        this.router.navigate(['/logout'], {queryParams: {returnUrl: this.router.url}});
        return;
      }
      this._alertService.error(parsedResponse.errors ? parsedResponse.message : 'Upload Error');
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

      this.uploadInputHtml.nativeElement.value = '';
      if (status === 401 || status === 403) {
        this.router.navigate(['/logout'], {queryParams: {returnUrl: this.router.url}});
      }
    };
  }

  /**
   * open native File selection window
   */
  openFilePicker() {
    // When user cancels and chooses same file, the popup is not created, so fix by setting value to null
    // https://html.spec.whatwg.org/multipage/input.html#dom-input-value-filename
    this.uploadInputHtml.nativeElement.value = null;
    this.uploadInputHtml.nativeElement.click();
  }
}
