import * as moment from 'moment';
import { REPORTED_TYPE_COEFFICIENTS } from '../models/periods';
import { PERIOD_ARR, PERIOD_CONTENT, PERIOD_TYPES, ENV_CONVENTION, DEV_ENV_HOST_MARKERS, LOCAL_ENV_MARKERS } from './constants';
import { EnvFromHostname } from '../models/env-from-host';
import { DocumentType } from './enums';
import { HttpHeaders } from '@angular/common/http';

export class CommonFunctions {
  static getParameterByName(name, url = null) {
    if (!url) url = window.location.href;
    const decodedUrl = decodeURIComponent(url);
    const wellFormedUrl = decodedUrl.split('?').join('?&'); // url is formatted incorrectly in how it is delimiting the url parameters
    const params = (new URL(wellFormedUrl)).searchParams;
    return params.get(name);
  }

  static tryGetLocalStorageItem(item) {
    try {
      return localStorage.getItem(item);
    } catch (ex) {
      return null;
    }
  }

  /**
   *
   * @returns {string | null}
   */
  static tokenGetter() {
    return CommonFunctions.tryGetLocalStorageItem('token');
  }

  static getCookieValue(a) {
    const b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
    return (b) ? b.pop() : null;
  }

  static setCookieValue(name, value, days= 30) {
      let expires = '';
      if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '')  + expires + '; path=/';
  }

  static eraseCookie(name) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  static getEnvFromHost(targetHost: string = null): EnvFromHostname {
    let serverUrl = '';
    let envName = '';
    const protocol = window.location.protocol;
    const sourceHost = window.location.hostname.toLowerCase();
    const hostname = (!targetHost) ? sourceHost : targetHost;

    // Legacy Env names follow a convention (app-<envname>, unless prod then it is just app)
    if (hostname.startsWith(ENV_CONVENTION.NON_PROD_HOST_APPEND)) {
      envName = hostname.split('.')[0].substring(ENV_CONVENTION.NON_PROD_HOST_APPEND.length);
      if (envName == 'citi' && hostname.startsWith(ENV_CONVENTION.CITI_PROD_HOSTNAME_MATCH)) {
        envName = ENV_CONVENTION.CITI_PROD_ENV_NAME
      }
      serverUrl = protocol + '//' + hostname.replace(ENV_CONVENTION.NON_PROD_HOST_APPEND, ENV_CONVENTION.NON_PROD_API_APPEND);
      if (hostname.startsWith(ENV_CONVENTION.NON_PROD_HOST_APPEND + ENV_CONVENTION.LOCAL)) {
        serverUrl += ENV_CONVENTION.LOCAL_PORT;
      }
      // local domain: https://api-local.fincura.com:8001/

    // Legacy prod url is only app.fincura.com, this covers that case
    } else if (hostname === ENV_CONVENTION.LEGACY_PROD_HOST) {
      envName = ENV_CONVENTION.PRODUCTION;
      serverUrl = protocol + '//' + hostname.replace(ENV_CONVENTION.HOST_APPEND, ENV_CONVENTION.API_APPEND);

    // New Env names follow a  <service>.<region>.<subtype>.<envtype> convention. for example:
    //  app.us.alpha.dev.fincura.com
    //  api.eu.beta.staging.fincura.com
    //  app.us.alpha.prod.fincura.com
    } else if (hostname.endsWith(ENV_CONVENTION.FINCURA_HOSTING_SUFFIX)) {
      const envNameSplit = hostname.split('.')
      if (envNameSplit.length !== 6) {
          // something has gone wrong if we end up here
          throw new Error('Unable to determine environment from hostname: ' + hostname)
      }
      envName = envNameSplit[1] + envNameSplit[2] + envNameSplit[3]  // e.g. usalphadev
      serverUrl = protocol + '//' + hostname.replace(ENV_CONVENTION.HOST_APPEND, ENV_CONVENTION.API_APPEND);

    // if we get here, assume we've been whitelisted or something and assume production
    } else {
      envName = ENV_CONVENTION.PRODUCTION;
      serverUrl = protocol + '//' + hostname.replace(ENV_CONVENTION.HOST_APPEND, ENV_CONVENTION.API_APPEND);
    }

    return { targetHostname: hostname, sourceHostname: sourceHost, serverUrl: serverUrl, envName: envName };
  }

  static capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static getHeaders(version: string, fincuraIssuedJWT: string, isInternalAdmin?: boolean, bankId?: string): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-App-Version', version);
    if (fincuraIssuedJWT !== null) {
      headers = headers.append('X-Authorization', 'Bearer ' + fincuraIssuedJWT);
      headers = headers.append('X-Auth-Type', 'PresignedToken');
    }

    return headers;
  }

  static isNonProdEnv(hostList, hostname = location.hostname) {
    const host = hostname.toLowerCase();
    return hostList.filter(env => host.startsWith(env)).length > 0;
  }

  static isDevEnv(hostname = location.hostname) {
    const prodHostnames = ['app.fincura.com', 'app-citi.numeratedspreading.com']
    return !prodHostnames.includes(hostname)
  }

  static isLocalEnv() {
    return this.isNonProdEnv(LOCAL_ENV_MARKERS);
  }

  static currentDateToReportDateType(reportType: number): Date {
    const reportedTypeCoefficient = REPORTED_TYPE_COEFFICIENTS[reportType - 1];
    return new Date(new Date().getUTCFullYear(), Math.floor(new Date().getUTCMonth() / reportedTypeCoefficient), reportType);
  }

  /**
   *
   * @param {number} index
   * @returns {string}
   */
  static getPeriodTypeByIndex(index: number): string {
    return PERIOD_TYPES[index - 1].value;
  }

  /**
   *
   * @param {string} reportedDate
   * @returns {string}
   */
  static getPeriodTypeAsString(reportedDate: string): string {
    const reportedDateAsDate = moment.utc(reportedDate);
    return PERIOD_ARR[reportedDateAsDate.date() - 1];
  }

  /**
   * helper to create input for Cell
   * @returns {HTMLInputElement}
   */
  static createInputElement(type: string = 'text'): HTMLInputElement {
    const input = document.createElement('input');
    input.type = type;
    input.classList.add('cell-input');
    return input;
  }

  /**
   *
   * @param {string} type
   * @param {number} index
   * @returns {any}
   */
  static getPeriodMonthIndex(type: string, index: number) {
    return PERIOD_CONTENT[type][index];
  }

  /**
   *
   * @param {number} value
   * @returns {string}
   */
  static getOriginSize(value: number) {
    return (value / 1024 / 1024).toFixed(2)
  }

  static getLastAvailableDateOfPeriodType(periodType: any) {
    const currentDate = new Date();
    switch (periodType) {
      case 'MONTHLY': {
        currentDate.setDate(0);
        return currentDate;
      }

      case 'QUARTERLY': {
        let previousValue = null;
        PERIOD_CONTENT[periodType].find((element) => {
          if (element + 2 >= currentDate.getMonth()) {
            return;
          }
          previousValue = element;
        });
        currentDate.setMonth(previousValue + 2);
        currentDate.setDate(0);
        return currentDate;
      }

      case 'SEMI_ANNUALLY': {
        if (currentDate.getMonth() > 6) {
          currentDate.setMonth(6);
        } else {
          currentDate.setMonth(0);
        }
        currentDate.setDate(0);
        return currentDate;
      }

      case 'ANNUALLY': {
        currentDate.setMonth(0);
        currentDate.setDate(0);
        return currentDate;
      }
    }
  }

  static getQueryParameterByName(name: string, url: string = null) {
    if (!url) url = window.location.href;
    const regexBrackets = '/[\[\]]/g';
    name = name.replace(regexBrackets, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static addQueryString(url: string, queryString: string) {
    if (url && queryString) {
      const hasQuestion = url && url.indexOf('?') !== -1,
      separator = hasQuestion ? '&' : '?';
      url += separator + queryString;
    }
    return url;
  }

  static stringToDocumentType(documentName: string): DocumentType {
    documentName = documentName.toUpperCase();
    if (documentName === 'INCOME_STATEMENT') {
      return DocumentType.IncomeStatement;
    } else if (documentName === 'CASH_FLOW_STATEMENT') {
      return DocumentType.CashFlowStatement;
    } else if (documentName === 'BALANCE_SHEET') {
      return DocumentType.BalanceSheet;
    } else if (documentName === 'CALCULATION') {
      return DocumentType.Calculation;
    } else {
      return DocumentType.Other;
    }
  }

  // From https://stackoverflow.com/a/10834843
  static isInteger(str: string) {
    const intRe = /^\-?\d+$/;
    return intRe.test(str);
  }

  // And https://stackoverflow.com/a/46181
  static isEmail(str: string) {
    const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRe.test(str.toLowerCase());
  }

  /**
   *  Get a random number from to
   * @param from
   * @param to
   */
  static randomNumber(from: number, to: number): number {
    return Math.floor(Math.random() * to) + from;
  }

  static snakeToCamel(string: string): string {
    const lowercaseString = string.toLowerCase();
    return lowercaseString.replace(/(_\w)/g, function(m) {
        return m[1].toUpperCase();
    });
  }

  static phraseToCamel(string: string): string {
    const newString = string.toLowerCase();
    return newString.replace(/(\s\w)/g, function(m) {
      return m[1].toUpperCase();
    });
  }

  static camelToSnake(string: string): string {
    return string.replace(/[\w]([A-Z])/g, function(m) {
        return m[0] + '_' + m[1];
    }).toLowerCase();
  }

  static fillArrayXTimes(valueToFill: any, numTimesToFill: number): Array<any> {
    const newArray = [];
    for (let index = 0; index < numTimesToFill; index++) {
      newArray.push(valueToFill);
    }
    return newArray;
  }

  static isMac(): boolean {
    if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  static mostFrequentElement(arr: Array<any>): any {
    const res = [];
    for (const x of arr) {
        let count = 0;
        for (const i of arr) {
            if (i === x) {
                count++;
            }
        }
        res.push(count);
    }
    return arr[res.indexOf(Math.max(...res))];
  }

  static numDecimals(string: string): number {
    if (string === null || string === undefined) {
      return 0;
    }
    if (!string.includes('.')) {
      return 0;
    } else {
      const splitString = string.trim().split('.');
      return splitString[1].length;
    }
  }

  // used to check if an html element has a certain class in its hierarchy
  // example in financial-table.component.ts
  static hasClassNameInHierarchy(element: HTMLElement, className: string): boolean {
    if (!element) {
      return false;
    }
    if (element.classList.contains(className)) {
      return true;
    }
    return this.hasClassNameInHierarchy(element.parentElement, className);
  }
}
