import {Component, OnInit} from '@angular/core';
import {SharedDataService} from "@services/shared-data.service";
import {AutoUnsubscribe} from "@decorators/auto-unsubscribe";
import {Subscription} from "rxjs";
import {PortfolioService} from "@services/portfolio.service";
import {finalize} from "rxjs/operators";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {Portfolio} from "@components/main/portfolio-management/model";
import {
  CreateOrEditPortfolioModalComponent
} from "@components/main/portfolio-management/components/create-or-edit-portfolio-modal/create-or-edit-portfolio-modal.component";
import {AlertService} from "@services/alert.service";

@Component({
  selector: 'app-portfolio-management',
  templateUrl: './portfolio-management.component.html',
  styleUrls: ['./portfolio-management.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class PortfolioManagementComponent implements OnInit {
  subsArr$: Subscription[] = [];
  isLoading: boolean;
  portfolios: Portfolio[] = [];
  limit = 15;
  page = 1;
  total: Number;

  // empty state
  noPortfoliosIcon = 'fa-suitcase';
  noPortfoliosHeaderText = 'Add a new portfolio';
  noPortfoliosHelpText = 'No portfolios exist yet. Select "Create Portfolio" to create one.';

  constructor(
    public _sharedDataService: SharedDataService,
    public _portfolioService: PortfolioService,
    public _popupService: NgxPopupService,
    public _alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._sharedDataService.pageHeaderTitle$.next('Portfolios');
    this._loadPortfolios();
  }

  changePage(page: number) {
    this.page = page;
    this._loadPortfolios();
  }

  _loadPortfolios() {
    this.subsArr$.push(this._portfolioService.listPortfolios(this.limit, this.page)
      .pipe(finalize(() => {
        this.isLoading = false
      }))
      .subscribe(data => {
        if (!data) {
          this._alertService.error("Failed to load portfolios. Please refresh the page to try again or contact administrator.");
        } else {
          this.total = data?.meta?.total;
          this.portfolios = data.response.objects;
        }
      }, error => {
        console.error(error);
        this._alertService.error("Failed to load portfolios. Please refresh the page to try again or contact administrator.");
      }));
  }

  createPortfolio() {
    this._popupService.open({
      componentType: CreateOrEditPortfolioModalComponent,
      outputs: {
        callback: (wasModalSubmitted: boolean) => {
          if (wasModalSubmitted) {
            this._loadPortfolios();
          }
        }
      }
    })
  }
}
