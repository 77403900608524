import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from '../../../../../node_modules/rxjs';
import { UserService } from '../../../services/user.service';
import { ReviewQueueItem } from '../../../models/review-queue-item';
import { ReviewQueueService } from '../../../services/review-queue.service';
import { Router } from '../../../../../node_modules/@angular/router';
import { NgxPopupComponent, NgxPopupService } from '../ngx-popups/ngx-popups/ngx-popups';
import { KeyboardShortcutsComponent } from '../keyboard-shortcuts/keyboard-shortcuts.component';
import { DocumentFileService } from '../../../services/document-file.service';
import { DocumentFile } from '../../../models/document-file';
import { ConfirmationPopupComponent } from '../popups/confirmation/confirmation-popup.component';
import { ApiService } from '../../../services/api.service';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { TrackingService, HumanInLoopEventData } from '../../../services/tracking.service';
import { SharedDataService } from '../../../services/shared-data.service';
import { LoggingService, Logger } from '../../../services/logging.service';

import {
  TABLE_ID_VALIDATION_STEP,
  TABLE_TABULATION_VALIDATION_STEP,
  FINAL_REVIEW_STEP,
  SPREADING_STEP,
  REVIEWED_STATUS,
  ACCEPTABLE_URLS,
  PLATFORM_PERMISSIONS,
  TAX_RETURN,
} from '@utils/constants';

import {UserGuideService} from '@services/user-guide.service';
import {NavigationStep} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-stepper.component";
import {BankSettingsService} from "@services/bank-settings.service";
import {
  ContactSupportEmailModalComponent
} from "@components/shared/popups/contact-support-email-modal/contact-support-email-modal.component";

@Component({
  selector: 'app-digitization-top-nav',
  templateUrl: './digitization-top-nav.component.html',
  styleUrls: ['./digitization-top-nav.component.scss']
})

@AutoUnsubscribe('subsArr$')
export class DigitizationTopNavComponent implements OnInit, OnChanges, OnDestroy {

  @Input() document: ReviewQueueItem = null;
  @Input() documentFile: DocumentFile;
  @Output() nextMethod: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveMethod: EventEmitter<any> = new EventEmitter<any>();

  subsArr$: Subscription[] = [];
  currentStep = '';
  currentStepIndex: number;
  allowNavigationToPreviousSteps = true;
  logger: Logger;
  embedded = false;
  showDocumentStatusButton: boolean;
  companyName: string;
  documentName: string;
  contactSupportEmail: string;

  navigationSteps: NavigationStep[] = [];
  shouldShowInstructionsInDropdown: boolean;

  constructor(
    public userService: UserService,
    public reviewQueueService: ReviewQueueService,
    private _router: Router,
    private popupService: NgxPopupService,
    private _documentFileService: DocumentFileService,
    private _apiService: ApiService,
    private _trackingService: TrackingService,
    private _loggingService: LoggingService,
    public _sharedDataService: SharedDataService,
    public userGuideService: UserGuideService,
    public _bankSettingsService: BankSettingsService
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('DigitizationTopNavComponent');
  }

  ngOnInit() {
    this.showDocumentStatusButton = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.VIEW_DOCUMENT_STATUS_TRACKER)
    this._sharedDataService.embeddedWorkflow$.subscribe((flow) => {
      this.setEmbeddedWorkflow(flow);
    });

    // component must reload to properly load data
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.subsArr$.push(this._bankSettingsService.getPortalSettings().subscribe((data) => {
      this.contactSupportEmail = data['_portalSettings']['contactSupportEmail'];
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.document && this.document && changes.document.currentValue && !changes.document.previousValue) {
      this.buildNavigationStepper();
      this.parseCompanyAndDocNameFromDocObject();
    }
  }

  ngOnDestroy() {
    if (this.document && this.document.lockKey && this.document.lockKey !== '') {
      this.releaseReviewQueueItem();
    }
  }

  buildNavigationStepper() {
    this.navigationSteps = [];
    if (this.reviewQueueService.isEligibleForTableDrawing(this.document)) {
      this.navigationSteps.push(
        {
          displayText: "Table Identification",
          stepKey: 'TableIDValidation',
          action:
            (this.allowNavigationToPreviousSteps && this.reviewQueueService.isTableIdentificationAvailable(this.document))
              ? () => this.navigate('identification') : null
        },
        {
          displayText: "Column Adjustment",
          stepKey: 'TableTabulationValidation',
          action:
            (this.allowNavigationToPreviousSteps && this.reviewQueueService.isTableTabulationAvailable(this.document))
              ? () => this.navigate('tabulation') : null
        }
      );
    }
    if (!this.reviewQueueService.isValidisUpload(this.document)) {
        this.navigationSteps.push({
          displayText: "Data Review",
          stepKey: 'ManualReview',
          action:
            (this.allowNavigationToPreviousSteps && this.reviewQueueService.isFinalReviewAvailable(this.document))
              ? () => this.navigate('review') : null
        });
    }
    this.navigationSteps.push({
        displayText: "Data Categorization",
        stepKey: 'Spreading',
        action: (this.reviewQueueService.isReadyForSpreading(this.document)) ? () => this.navigate('spreading') : null
      });
    this.getCurrentStep(this._router.url);
    this.currentStepIndex = this.navigationSteps.map(e => e.stepKey).indexOf(this.currentStep);
  }

  parseCompanyAndDocNameFromDocObject() {
    this.documentName = this.document?.processingJobPayload?.documentFile?.originalDocumentName;
    this.companyName = this.document?.processingJobPayload?.company?.name;
  }

  getCurrentStep(url) {
    if (url.includes('table_identification')) {
      this.currentStep = TABLE_ID_VALIDATION_STEP;
      this.shouldShowInstructionsInDropdown = true;
    } else if (url.includes('table_tabulation')) {
      this.currentStep = TABLE_TABULATION_VALIDATION_STEP;
      this.shouldShowInstructionsInDropdown = true;
    } else if (url.includes('manual_review')) {
      this.currentStep = FINAL_REVIEW_STEP;
      this.shouldShowInstructionsInDropdown = true;
    } else if (url.includes('statements')) {
      this.currentStep = SPREADING_STEP;
      this.shouldShowInstructionsInDropdown = false;
    }
  }

  shouldDisplayManualReview(): boolean {
    return this.userService.isManualReviewer();
  }

  shouldDisplaySpreading(): boolean {
    return this.userService.isSpreader();
  }

  sendHumanInLoopEvent(type: string): void {
    let step = 'UNDEF'

    // figure out what step we are in by parsing the URL
    // it's not an ideal solution, but it's all we got for now
    if ( this.currentStep === FINAL_REVIEW_STEP ) {
      step = 'Manual Review';
    } else if ( this.currentStep === SPREADING_STEP  ) {
      step = 'Spreading';
    } else if ( this.currentStep === TABLE_ID_VALIDATION_STEP ) {
      step = 'Table Identification';
    } else if ( this.currentStep === TABLE_TABULATION_VALIDATION_STEP ) {
      step = 'Column Identification';
    }

    const eventData: HumanInLoopEventData = {
      step: step,
      type: type,
      documentFileId: this.document.fileKey.split('/')[0],
      documentCompanyId: this.document.companyId,
      documentTenantId: this.document.bankId
    }

    try {
      this._trackingService.trackHumanInLoop(eventData);
    } catch (err) {
      this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
    }

  }

  goToTableIdentification(): void {
    if (!this.document) {
      this.logger.info('Not proceeding to Table Identification, this.document is null.')
      return;
    }
    this.logger.info('Going to Table Identification from url "' + this._router.url + '"')
    this.sendHumanInLoopEvent('End');
    this._router.navigate(['/review/' + this.document.id + '/table_identification']);
  }

  goToTableTabulation(): void {
    if (!this.document) {
      return;
    }
    this.sendHumanInLoopEvent('End');
    this._router.navigate(['/review/' + this.document.id + '/table_tabulation']);
  }

  navigate(location = 'review') {
    this.popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Are you sure?',
        text: 'All unsaved changes will be lost',
        allowKeystrokeResponses: false,
      },
      outputs: {
        callback: (approved: boolean) => {
          this.handleBackBtnPopupAnswer(location, approved);
        }
      },
    }).then((popup: NgxPopupComponent) => {
      if (!popup) {
        return;
      }
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  handleBackBtnPopupAnswer(location: string, result: boolean) {
    if (result) {
      // add something related to releasing the lock
      this.releaseReviewQueueItem();
      setTimeout(() => this.goToLocation(location), 400);
    }
  }

  releaseReviewQueueItem() {
    if (!this.document) {
      return;
    }
    if (this.document.lockKey && this.document.lockKey !== '') {
      return this._apiService.send('Post', `/api/review-queue-items/${this.document.id}/release`, {
        lock_key: this.document.lockKey
      }).toPromise()
        .then(data => { })
        .catch(err => { })
    }
  }

  goToLocation(location: string): void {
    if (location === 'back') {
      this.goBack();
    } else if (location === 'identification') {
      this.goToTableIdentification();
    } else if (location === 'tabulation') {
      this.goToTableTabulation();
    } else if (location === 'review') {
      this.goToManualReview();
    } else if (location === 'spreading') {
      this.goToSpreading();
    }
    return;
  }

  goBack(): void {
    this.sendHumanInLoopEvent('End');
    this.subsArr$.push(this._sharedDataService.appReturnUrl.subscribe((location) => {
      this._router.navigate([location]);
    }));
    return;
  }

  goToReviewQueue(): void {
    this.sendHumanInLoopEvent('End');
    this._router.navigate(['review']);
    return;
  }

  goToManualReview(): void {
    if (!this.document) {
      return;
    }
    this.sendHumanInLoopEvent('End');
    this._router.navigate(['/review/' + this.document.id + '/manual_review']);
  }

  goToSpreading() {
    if (!this.document) {
      return;
    }

    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.document.documentFileId).subscribe(files => {
      if (files.length > 0) {
        const documentFile: DocumentFile = files[0];

        this.sendHumanInLoopEvent('End');
        this._router.navigate(['/spread/' + documentFile.uuid]);

      }
    }));


  }

  save(): void {
    this.sendHumanInLoopEvent('Save');
    this.saveMethod.emit();
  }

  next(): void {
    if (this.embedded) {
      ACCEPTABLE_URLS.forEach((acceptable_url) => {
        try {
          window.parent.postMessage({
            'eventName': 'stepComplete',
            'workflow': this.document.embeddedWorkflow,
            'stepName': this.currentStep,
          }, acceptable_url);
        } catch (ex) {/* pass */}
      });
    }
    this.nextMethod.emit();
  }

  openShortcutsModal() {
    this.popupService.open({
      componentType: KeyboardShortcutsComponent,
      cssClass: 'modal-cache-commander',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  downloadFile() {
    this.subsArr$.push(this._documentFileService.getDownloadUrlForOriginalDocument(this.documentFile.id).subscribe(data => {
      const url = data.originalFileUrl;
      const link = document.createElement('a');
      link.href = url;
      link.download = this.documentFile.originalDocumentName;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    }));

  }

  setEmbeddedWorkflow(flow): void {
    if (flow === null) {
      this.embedded = false;
    } else {
      this.embedded = true;
    }
  }

  openContactSupportEmailModal() {
    this.popupService.open({
      componentType: ContactSupportEmailModalComponent,
      cssClass: 'contact-support-modal',
      inputs: {
        supportEmail: this.contactSupportEmail,
      },
    })
  }

  toggleStepsToSuccess() {
    if (this._sharedDataService.shouldShowManualReviewSidebar === true) {
      this._sharedDataService.shouldShowManualReviewSidebar = false;
    } else {
      this._sharedDataService.shouldShowManualReviewSidebar = true;
    }
  }

  onTextClick(event: Event): void {
    event.stopPropagation();
    this.toggleStepsToSuccess();
  }
}
