import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DataViewColumn, DataViewRow} from '@models/dataview';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {AnalysisService} from '@services/analysis.service';
import {ProposedDebt} from '@models/proposed-debt';
import {TemplateItem} from '@models/template-item';
import {DataOverride} from '@models/data-override';
import { ProjectionHelperService } from '@services/projection-helper.service';

@Component({
  selector: 'app-debt-service-coverage-table',
  templateUrl: './debt-service-coverage-table.component.html',
  styleUrls: ['./debt-service-coverage-table.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class DebtServiceCoverageTableComponent {
  subsArr$: Subscription[] = [];

  @Input() templateItems: Array<TemplateItem>;
  @Input() columns: Array<DataViewColumn>;
  @Input() scopes: Array<{ id: number }> = [];
  @Input() proposedDebts: Array<ProposedDebt> = [];
  @Input() dataOverrides: Array<DataOverride>;
  @Input() allowCellEditing = true;
  @Input() refToOverrideIdx = {}
  @Input() templateHasSections = false;
  @Input() projectionScenarioTypeLabelOverride = 'Projection';

  @Output() doneEditing = new EventEmitter<any>();

  selectedRow = -1;
  selectedColumn = -1;
  selectedChildTemplateItemIdx = -1;
  editingRow = -1;
  editingColumn = -1;
  editingChildTemplateItemIdx = -1;
  proposedDebtEditable = false;


  constructor(
    public analysisService: AnalysisService, 
    public _projectionHelperService: ProjectionHelperService,
    ) {
  }


  // Stop the highlighting when we click outside the element
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.selectedRow = -1;
    this.selectedColumn = -1;
  }

  selectedCell(rowIdx: number, colIdx: number, childIdx: number = null): void {
    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
    this.selectedChildTemplateItemIdx = childIdx
  }

  editCell(rowIdx: number, colIdx: number, childIdx: number = null): void {
    this.editingRow = rowIdx;
    this.editingColumn = colIdx;
    this.editingChildTemplateItemIdx = childIdx
  }

  doneEditingCell(newValueObject: any): void {
    if (newValueObject.isChanged || newValueObject.newValueMatchesOriginalValue) {
      this.doneEditing.emit(newValueObject);
    }
    this.editingRow = -1;
    this.editingColumn = -1;
    this.editingChildTemplateItemIdx = -1;
  }

  isEditable(templateItem: TemplateItem): boolean {
    if (!this.allowCellEditing) {
      return false;
    }

    // below returns true if not a "can never edit this field" value
    // custom non editable values for DSCR templates
    return templateItem.ref !== 'REF_DSCR_NET_CASH_FLOW'
    && templateItem.ref !== 'REF_DSCR_COVER_PRIOR'
    && templateItem.ref !== 'REF_DSCR_TOTAL_DEBT_SERVICE'
    && templateItem.ref !== 'REF_DSCR_CASH_SURPLUS_DEFICIT'
    && templateItem.ref !== 'REF_DSCR_ACT_DEBT_SERVICE_COV';
  }

  selectCell(row: DataViewRow, column: DataViewColumn, rowIdx: number, colIdx: number) {
    if (this.selectedRow === rowIdx && this.selectedColumn === colIdx) {
      this.selectedRow = -1;
      this.selectedColumn = -1;
      return;
    }

    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
    return;
  }

  equationValueForProposedDebt(proposedDebt: ProposedDebt, monthsInInterval: number): number {
    return this.analysisService.calculateProposedDebtIntervalPayment(proposedDebt, monthsInInterval);
  }

  getOriginalValueBeforeOverrides(ref: string, colIndex: number) {
    for (const cell of this.columns[colIndex]['cells']) {
      if (cell.ref === ref) {
        return cell['calculatedValue']['value'];
      }
    }
  }
}
