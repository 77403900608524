import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from '@services/alert.service';
import { SpreadingTemplateService } from '@services/spreading-template.service';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import { AutoUnsubscribe } from '@decorators/auto-unsubscribe';

@Component({
  selector: 'app-create-sub-item',
  templateUrl: './create-sub-item.component.html',
  styleUrls: ['./create-sub-item.component.css']
})
export class CreateSubItemComponent implements OnInit {
    @Input() popup: NgxPopupComponent; // MANDATORY
    @Input() standardLineItemId: number;

    subsArr$: Subscription[] = [];
    subItemForm: FormGroup;
    submitted = false;
    submitAttempt = false;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private spreadingTemplateService: SpreadingTemplateService
  ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.subItemForm = this.formBuilder.group({
      label: ['', [Validators.required]]
    });
  }

  /**
   *
   * @param {string} field
   * @returns {boolean}
   */
  isFieldInvalid(field: string): boolean {
    return (
      (this.subItemForm.get(field).invalid && this.subItemForm.get(field).dirty) ||
      (this.subItemForm.get(field).invalid && this.submitAttempt)
    );
  }

    /**
   *
   * @param user
   * @param {boolean} isValid
   */
  save(subItem: any, isValid: boolean) {
    this.submitAttempt = true;
    if (!isValid || this.submitted) {
      return;
    }
    this.submitted = true;
    subItem.label = subItem.label.trim();

    this.subsArr$.push(this.spreadingTemplateService.generateSubItem(this.standardLineItemId, subItem)
      .subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, data);
          this.alertService.success('SubItem created successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }

}
