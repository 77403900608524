import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {BankSettingsService} from "@services/bank-settings.service";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class BorrowerListResolver implements Resolve<any> {
  constructor(private _bankSettingsService: BankSettingsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._bankSettingsService.getCustomerIdRelatedBankSettings();
  }
}
