import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {forkJoin, Observable, of, throwError} from "rxjs";
import {StatementService} from "@services/statement.service";
import {DocumentFileService} from "@services/document-file.service";
import {switchMap, catchError, map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class StatementDataResolver implements Resolve<any> {
  constructor(
    private _documentFileService: DocumentFileService,
    private _statementService: StatementService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._statementService.loadStatementByUuid(route.params.statement_uuid)
      .pipe(switchMap((statementData) => {
          return forkJoin({
            // only pass through necessary data
            statementData: of({
              id: statementData.id,
              documentFileId: statementData.documentFileId,
              columns: statementData.columns,
              rows: statementData.rows
            }),
            documentData: this._documentFileService.listDocumentFiles(statementData.companyId, statementData.documentFileId, true)
              .pipe(map(data => {
                return {pageImages: data[0].pageImages}
              }))
          })
        }),
        catchError(err => {
          console.error("failed to load data:", err)
          return throwError("Error occurred while fetching data")
        })
      )
  }
}
