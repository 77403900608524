import {NgModule} from "@angular/core";
import {BorrowerAlertComponent} from "@components/main/borrower/borrower-alert/borrower-alert.component";

@NgModule({
  declarations : [
    BorrowerAlertComponent
  ],
  exports: [
    BorrowerAlertComponent
  ]
})

export class BorrowerAlertModule {
}

