import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-minimized-side-nav',
  templateUrl: './minimized-side-nav.component.html',
  styleUrls: ['./minimized-side-nav.component.scss']
})
export class MinimizedSideNavComponent {
  @Input() shouldDisplayManualReview = false;
  @Input() isAdmin = false;
  @Input() isInternalDeveloper = false;
  @Input() bypassBackendCache;
  @Input() switchOnProfiler;
  @Input() showSpreadingTemplates;
  @Input() showReporting;
  @Input() showApiKeysOption;
  @Input() shouldShowPortfolios;
  @Input() showBorrowerOptions;
  @Output('toggleSidebar') _toggleSidebar = new EventEmitter();
  @Output('restCommander') _restCommander = new EventEmitter<any>();
  @Output('cacheCommander') _cacheCommander = new EventEmitter<any>();
  @Output('toggleBypassCache') _toggleCachingForDev = new EventEmitter<any>();
  @Output('toggleProfiler') _toggleProfilerForDev = new EventEmitter<any>();
  @Output('triggerError') _triggerError = new EventEmitter<any>();

  public toggleSidebar(): void {
    this._toggleSidebar.emit();
  }

  public restCommander(): void {
    this._restCommander.emit();
  }

  public cacheCommander(): void {
    this._cacheCommander.emit();
  }

  public toggleBypassCache(): void {
    this._toggleCachingForDev.emit()
  }

  public toggleProfiler(): void {
    this._toggleProfilerForDev.emit()
  }

  public triggerError(): void {
    this._triggerError.emit();
  }
}
