<div>
    <div id="main">
        <div class="d-flex float-end mb-3">
            <div>
                <button [routerLink]="['/api-docs/redoc']" type="button" class="btn btn-empty-primary form-control">
                    <i class="fas fa-book"></i>Documentation
                </button>
            </div>
            <div class="me-3">
                <button [routerLink]="['/api-docs/swagger-ui']" type="button"
                        class="btn btn-empty-primary form-control"><i class="fas fa-wrench"></i>Swagger-UI
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-primary form-control" (click)="addApiKey()"><i
                    class="fas fa-plus pe-2"></i>Add API Key
                </button>
            </div>
        </div>

        <div *ngIf="apiKeys.length">
            <table class="table table-hover">
                <thead>
                <tr class="sorting-header">
                    <th class="col-md-3" (click)="sortBy('firstName')" colspan="2">LABEL</th>
                    <th class="col-md-3">KEY UUID</th>
                    <th class="col-md-2">CREATED BY</th>
                    <th class="col-md-1">CREATED ON</th>
                    <!--<th class="col-md-2">EXPIRES AT</th>
                    <th class="col-md-3">ACTIONS</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let apiKey of apiKeys | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }">
                    <td class="img-cell">
                        <i class="fas fa-key"></i>
                    </td>
                    <td class="name-cell">
                        {{apiKey.label}}
                    </td>
                    <td>{{apiKey.key}}</td>
                    <td>{{apiKey.createdByUser}}</td>
                    <td>{{apiKey.createdDate | amFromUtc | amLocal | amDateFormat : 'MM/DD/YYYY'}}</td>
                    <!--<td>{{apiKey.expirationDate | amFromUtc | amLocal | amDateFormat : 'MM/DD/YYYY h:mma'}}</td>
                    <td>
                    	<div class="dropdown">
			                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
			                    <div class="avatar">
			                        <i class="fas fa-info-circle"></i>
			                    </div>
			                    <div class="arrow-down">
			                        <i class="fas fa-chevron-down"></i>
			                    </div>
			                </a>
			                <ul class="dropdown-menu pull-right api-actions">
			                    <li><a [routerLink]="['/api-docs/swagger-ui']"><i class="fas fa-wrench"></i>{{'&#160;'}}  View Swagger-UI</a></li>
			                    <li role="separator" class="divider"></li>
			                    <li><a (click)="revokeApiKey(apiKey)"><i class="fas fa-times-circle"></i>{{'&#160;'}}  Revoke Key</a></li>
			                </ul>
			            </div>
                    </td>-->
                </tr>
                </tbody>
            </table>
            <div style="text-align: center" *ngIf="total > limit">
                <pagination-template #p="paginationApi" (pageChange)="changePage($event)">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li [ngClass]="{'disabled': p.isFirstPage()}">
                                <a (click)="!p.isFirstPage() ? p.previous() : return" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            <li *ngFor="let page of p.pages" [ngClass]="{'active': p.getCurrent() === page.value}">
                                <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    {{ page.label }}
                                </a>
                                <span *ngIf="p.getCurrent() === page.value">{{ page.label }}
                                <span class="visually-hidden">(current)</span>
                            </span>
                            </li>

                            <li [ngClass]="{'disabled': p.isLastPage()}">
                                <a (click)="!p.isLastPage() ? p.next() : return" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </pagination-template>
            </div>
        </div>

        <div *ngIf="!apiKeys.length && !isLoading" class="inner-content">
            <i>There are no api keys</i>
        </div>

        <div *ngIf="isLoading" class="loader" style="margin-top: 50px"></div>
    </div>
</div>
