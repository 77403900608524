<div class="modal__header">
    <div class="modal__title reference-sheet-upload-popup-header">
        UPLOAD EXCEL EXPORT REFERENCE WORKBOOK
    </div>
</div>
<div class="modal__content reference-sheet-upload-popup-text">
    <div *ngIf="!existingFileName">
        <span>No existing excel export reference exists, please upload below</span>
    </div>
    <div *ngIf="existingFileName">
        <span class="bold">Current Reference File: </span>
        <div>
            <button class="btn delete-existing-document-button" (click)="deleteExistingReferenceSheetUpload()"><i class="fas fa-trash"></i></button><a (click)="downloadReferenceSheet()">{{existingFileName}}</a>
        </div>
    </div>
    <hr>
    <div>
        <div>
            <span class="bold">Upload a new reference file</span>
        </div>
        <input #uploadInput type="file" id="actual-btn" class="hidden" ng2FileSelect [uploader]="uploader" accept="uploadInputAccept"/>
        <button class="btn btn-primary" (click)="openFilePicker()">Choose File</button>
        <span id="file-chosen" class="file-selector-label">No file chosen</span>
        <div>
            <span class="invalid-file-type-text" *ngIf="invalidFileType">A non-excel file was chosen. Please select an excel file to upload.</span>
        </div>
    </div>
</div>

<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="close()">Close</button>
    <button class="btn btn-primary" [disabled]="!fileQueuedForUpload" (click)="uploadDocument()">Upload</button>
</div>

