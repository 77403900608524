import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Company } from '../../../../../models/company';
import { AlertService } from '../../../../../services/alert.service';
import { BorrowerService } from '../../../../../services/borrower.service';
import { PortfolioService } from '../../../../../services/portfolio.service';
import { NgxPopupComponent } from '../../../../shared/ngx-popups/ngx-popups/components/popup.component';
import {
  EMAIL_PATTERN,
  PHONE_MASK,
  PHONE_PATTERN,
  PLATFORM_PERMISSIONS,
} from '../../../../../utils/constants';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../../../../services/api.service';
import { LaunchDarklyService } from '../../../../../services/launchdarkly.service';
import { Router } from '@angular/router';
import { Borrower } from '@services/fincura-ng-client';
import { CustomAttributeDefinitionService } from '@services/custom-attribute-definition.service';
import { CustomAttributeDefinition } from '@models/custom-attribute-definition';
import {UserService} from "@services/user.service";
import { Select2OptionData } from '@components/shared/select2/select2.interface';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class CompanyFormComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company;
  @Input() formType: string;

  companyForm: FormGroup;
  submitAttempt = false;
  submitted = false;
  subsArr$: Subscription[] = [];
  portfolios: any;
  portfolioOptions: Select2OptionData[] = [];
  currentYear = new Date().getFullYear();
  years: number[] = [];
  sicArr: any[] = [];
  naicsArr: any[] = [];
  requireIndustryCodes = false;
  showSicIndustry = false;
  sicIndustries = Borrower.SicIndustryEnum;
  customAttrDefinitions: CustomAttributeDefinition[] = [];
  customAttributesValues = {};
  invalidFields = {};
  canEditCustomerId = false;
  phoneMask = PHONE_MASK;
  createAnotherBorrower = new FormControl(false);
  loading = false;

  constructor(private _formBuilder: FormBuilder,
              private _alertService: AlertService,
              public borrowerService: BorrowerService,
              private _portfolioService: PortfolioService,
              private _sanitizer: DomSanitizer,
              private _apiService: ApiService,
              private _featureFlags: LaunchDarklyService,
              private _router: Router,
              private _customAttributeDefinitionService: CustomAttributeDefinitionService,
              private _userService: UserService,
  ) {
  }

  ngOnInit() {
    this.subsArr$.push(this._portfolioService.getAllPortfolios().subscribe(portfolios => {
      this.portfolios = portfolios;

      this.portfolios.forEach(portfolio => {
        this.portfolioOptions.push({
          id: portfolio.id,
          text: portfolio.name
        })
      })
      if (this.portfolioOptions.length > 0) {
        this.portfolioOptions.unshift({
          id: '0',
          text: "Unassigned"
        })
      }
    }));

    this.subsArr$.push(this._customAttributeDefinitionService.getCustomAttributesForModel('Company').subscribe(customAttributeDefinitions => {
      this.customAttrDefinitions = customAttributeDefinitions;
    }));


    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.setFlags();
    }));

    this.setFlags();

    this.getSICData();
    this.getNAICSData();

    if (this.requireIndustryCodes) {
      this.companyForm = this._formBuilder.group({
        name: ['', [Validators.required, this.nospaceValidator, this.extraSpaceValidator, Validators.maxLength(150)]],
        sicCode: ['', [Validators.required]],
        naicsCode: ['', [Validators.required]],
        email: ['', [Validators.maxLength(100), Validators.pattern(EMAIL_PATTERN)]],
        portfolio: ['0'],
        contactPerson: ['', [Validators.maxLength(100), Validators.pattern(/^[a-zA-Z'\-\s.,:!?#]*$/)]],
        contactPhone: ['', [Validators.maxLength(100), Validators.pattern(PHONE_PATTERN)]],
        sicIndustry: [''],
        externalId: ['', Validators.maxLength(250)]
      });
    } else {
      this.companyForm = this._formBuilder.group({
        name: ['', [Validators.required, this.nospaceValidator, this.extraSpaceValidator, Validators.maxLength(150)]],
        email: ['', [Validators.maxLength(100), Validators.pattern(EMAIL_PATTERN)]],
        portfolio: ['0'],
        contactPerson: ['', [Validators.maxLength(100), Validators.pattern(/^[a-zA-Z'\-\s.,:!?#]*$/)]],
        contactPhone: ['', [Validators.maxLength(100), Validators.pattern(PHONE_PATTERN)]],
        sicIndustry: [''],
        externalId: ['', Validators.maxLength(250)]
      });
    }

    if (!this._userService.user.hasUserPermission(PLATFORM_PERMISSIONS.ADD_BORROWER_TO_PORTFOLIO)){
      this.companyForm.controls.portfolio.disable();
    }

    for (let i = this.currentYear - 10; i <= this.currentYear + 3; i++) {
      this.years.push(i);
    }
  }

  setFlags() {
    this.showSicIndustry = this._featureFlags.flags['show-industry-sic']
    this.canEditCustomerId = this._featureFlags.flags['edit-customer-id']
    this.requireIndustryCodes = false;
    this.showSicIndustry = false;
  }

  getSICData(): void {
    this.subsArr$.push(this._apiService.getFile('/assets/files/sic_codes.json').subscribe(data => {
      this.sicArr = data;
    }));
  }

  getNAICSData(): void {
    this.subsArr$.push(this._apiService.getFile('/assets/files/naics-sic-mappings.json').subscribe(data => {
      this.naicsArr = data;
    }));
  }

  nospaceValidator(control: AbstractControl): { [s: string]: boolean } {
    if (control.value && !control.value.trim()) {
      return {nospace: true};
    }
  }

  extraSpaceValidator(control: AbstractControl): { [s: string]: boolean } {
    const re = /(^\s|[\s]{2,})/;
    if (control.value && re.test(control.value)) {
      return {extraspace: true};
    }
  }

  customAttributeChange(attr, event) {
    const value = event.target.value;
    if (!!value) {
      delete this.invalidFields[attr.key];
      this.customAttributesValues[attr.key] = {
        key: attr.key,
        attribute_type: attr.attributeType,
        value
      }
    } else {
      this.invalidFields[attr.key] = true;
      delete this.customAttributesValues[attr.key];
    }
  }

  isFieldInvalid(field: string): boolean {
    return (
      (this.companyForm.get(field).invalid && this.companyForm.get(field).dirty) ||
      (this.companyForm.get(field).invalid && this.submitAttempt)
    );
  }

  updatePortfolio(portfolio) {
    this.companyForm.patchValue({portfolio: portfolio});
  }

  save(companyForm: any, isValid: boolean) {
    this.submitAttempt = true;
    if (!isValid) {
      return;
    }
    this.submitted = true;

    companyForm.customAttributes = this.customAttributesValues;

    if (+companyForm.portfolio > 0) {
      companyForm.portfolio = {id: +companyForm.portfolio};
    }
    if (companyForm.portfolio === '0') {
      companyForm.portfolio = null;
    }
    if (companyForm.email) {
      companyForm.email = companyForm.email.trim();
    }

    if (!companyForm.sicCode) {
      companyForm.sicCode = null;
    }

    if (!companyForm.naicsCode) {
      companyForm.naicsCode = null;
    }


    if (companyForm.sicIndustry === '') {
      delete(companyForm.sicIndustry);
    }

    if (!companyForm.externalId) {
      companyForm.externalId = null;
    }

    this.loading = true;
    this.subsArr$.push(this.borrowerService.companyAdd(companyForm)
      .subscribe((company: Company) => {
          if (this.createAnotherBorrower.value) {
            this.popup.close(true, {reOpenModal: true});
          } else {
            this.popup.close(true, true);
            if (company) {
              this._router.navigate(['/companies/', company.uuid, 'financials', 'analysis'])
            }
          }
          this._alertService.success('Borrower added successfully');
        },
        error => {
          this._alertService.error(error.message);
          this.submitted = false;
          this.loading = false;
        },
      ),
    );
  }
}
