import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, scan } from 'rxjs/operators';

import { ApiService } from './api.service';
import { ReviewQueueItem, DocumentParsingStep, DocumentParsingStepStatus, ReviewQueueItemInterface } from '../models/review-queue-item';
import {
  TABLE_ID_VALIDATION_STEP,
  TABLE_TABULATION_VALIDATION_STEP,
  FINAL_REVIEW_STEP,
  SPREADING_STEP,
  IMAGE_GENERATION_STEP,
  STATEMENT_CLASSIFICATION_STEP,
  STATEMENT_REVIEW_STEP,
  TABLE_AGGREGATION_STEP,
  TABLE_AGGREGATION_VALIDATION_STEP,
  TABLE_IDENTIFICATION_STEP,
  TABLE_TABULATION_STEP,
  TEXT_EXTRACTION_STEP,
  PREP_FOR_MANUAL_REVIEW_STEP,
  FILE_TYPE_IDENTIFICATION_STEP,
  COMMENTARY_FILTERING_STEP,
  COMMENTARY_VALIDATION_STEP,
  COMPLETE_STEP,

  HUMAN_INPUT_REQUIRED_STATUS,
  REVIEWED_STATUS,
  CHANGES_SAVED_STATUS,
  DOC_PROCESSED_STATUS,
  DOC_PROCESSING_STATUS,
  UPLOADED_STATUS,
  SUCCEEDED_STATUS,
  TAX_RETURN,
  COMPLETE_STATUS,
  NORMALIZED_STATUS
} from '../utils/constants';

const scanningSteps = [
  FILE_TYPE_IDENTIFICATION_STEP,
  COMMENTARY_FILTERING_STEP,
  COMMENTARY_VALIDATION_STEP,
  COMPLETE_STEP,
  IMAGE_GENERATION_STEP
];

  const extractingSteps = [
  STATEMENT_CLASSIFICATION_STEP,
  STATEMENT_REVIEW_STEP,
  TABLE_AGGREGATION_STEP,
  TABLE_AGGREGATION_VALIDATION_STEP,
  TABLE_IDENTIFICATION_STEP,
  TABLE_TABULATION_STEP,
  TEXT_EXTRACTION_STEP,
  PREP_FOR_MANUAL_REVIEW_STEP,
];
// const qualitySteps = ['ClassifyPageStatementType', 'MergePages', 'SqueezeColumns', 'DeindentLabels', 'RemoveEmpties', 'HeaderFooterFlag', 'TextPatternHeuristic', 'ParsingConfidence'];
const humanConfirmationSteps = [
  TABLE_ID_VALIDATION_STEP,
  TABLE_TABULATION_VALIDATION_STEP,
  FINAL_REVIEW_STEP
];

const spreadingSteps = [SPREADING_STEP];

@Injectable()
export class ReviewQueueService {

  constructor(
    private _apiService: ApiService,
  ) {
  }


  queueList(page: number = 1, limit: number = 10, sortByNewest = false, companyId: number = null, includeHidden = false, documentFileId = null, bankId = null): Observable<any> {
    const query = {
      offset: (page - 1) * limit,
      limit: limit,
      sort: 'created_date.asc',
      filter: {hidden_in: [false]}
    };

    if (companyId !== null) {
      // @ts-ignore
      query['filter'] = { company_id_eq: companyId };
      // honestly not sure why we overwrite the hidden_in filter
      // but there's a test around it so I didn't want to change this
    }

    if (includeHidden) {
      query['filter']['hidden_in'] = [true, false];
    }


    if (documentFileId !== null) {
      // @ts-ignore
      query['filter'] = { document_file_id_eq: documentFileId };
      // if we search by doc file id, we want that doc & don't care about hidden related filters
    }

    if (sortByNewest) {
      query.sort = 'created_date.desc';
    }

    if (bankId) {
      query['filter']['bank_id_eq'] = bankId;
    }

    return this._apiService.send('Post', '/api/review-queue-items/all', query);
  }

  deleteQueueItem(item: ReviewQueueItem): Observable<any> {
    return this._apiService.send('Delete', `/api/review-queue-items/${item.id}`);
  }

  hideQueueItem(item: ReviewQueueItem): Observable<any> {
    return this._apiService.send('Post', `/api/review-queue-items/${item.id}/hide`);
  }

  /**
   * Turns the combination of attributes on the review queue item into
   * human-readable "steps" that can be shown in the interface to convey
   * the document parsing process
   * @param documentQueueItem
   */
  calculateParsingTrackerSteps(item: ReviewQueueItem): any {
    const queuedStep = new DocumentParsingStep('Queued for processing', 'File Uploaded');
    const scanningStep = new DocumentParsingStep('Scanning document', 'Document scanned');
    const extractingStep = new DocumentParsingStep('Extracting information', 'Information extracted');
    const humanConfirmationStep = new DocumentParsingStep('Ready for Review', 'Human confirmation completed');
    const categorizeStep = new DocumentParsingStep('Spread/Categorize', 'Spreading/Categorizing Complete', true);

    // This is the value we will eventually return after we figure out the status of each individual step.
    const steps = [
      queuedStep,
      scanningStep,
      extractingStep,
      humanConfirmationStep,
      categorizeStep
    ];

    switch (item.status) {
      case REVIEWED_STATUS:
        queuedStep.status = DocumentParsingStepStatus.Done;
        scanningStep.status = DocumentParsingStepStatus.Done;
        extractingStep.status = DocumentParsingStepStatus.Done;
        humanConfirmationStep.status = DocumentParsingStepStatus.Done;
        categorizeStep.status = DocumentParsingStepStatus.Active;
        break;
      case CHANGES_SAVED_STATUS:
      case DOC_PROCESSED_STATUS:
        queuedStep.status = DocumentParsingStepStatus.Done;
        scanningStep.status = DocumentParsingStepStatus.Done;
        extractingStep.status = DocumentParsingStepStatus.Done;
        humanConfirmationStep.status = DocumentParsingStepStatus.Done;
        // Handle cases of "in review"
        if (item.lastLockedDate) {
          categorizeStep.status = DocumentParsingStepStatus.Active;
        }
        break;
      case HUMAN_INPUT_REQUIRED_STATUS:
        queuedStep.status = DocumentParsingStepStatus.Done;
        scanningStep.status = DocumentParsingStepStatus.Done;
        extractingStep.status = DocumentParsingStepStatus.Done;
        if (spreadingSteps.includes(item.step)) {
          humanConfirmationStep.status = DocumentParsingStepStatus.Done;
          categorizeStep.status = DocumentParsingStepStatus.Active;
        } else {
          humanConfirmationStep.status = DocumentParsingStepStatus.Active;
          categorizeStep.status = DocumentParsingStepStatus.Queued;
        }
        break;
      case DOC_PROCESSING_STATUS:
        queuedStep.status = DocumentParsingStepStatus.Done;
        if (scanningSteps.includes(item.step)) {
          scanningStep.status = DocumentParsingStepStatus.Active;
        } else if (extractingSteps.includes(item.step)) {
          scanningStep.status = DocumentParsingStepStatus.Done;
          extractingStep.status = DocumentParsingStepStatus.Active;
        } else if (humanConfirmationSteps.includes(item.step)) {
          scanningStep.status = DocumentParsingStepStatus.Done;
          extractingStep.status = DocumentParsingStepStatus.Done;
          humanConfirmationStep.status = DocumentParsingStepStatus.Active;
        } else {
          scanningStep.status = DocumentParsingStepStatus.Active;
        }
        break;
      case UPLOADED_STATUS:
        // Still queued, no need to update anything.
        break;

    }

    return steps;
  }

  isReadyForReview(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    return documentQueueItem.status === HUMAN_INPUT_REQUIRED_STATUS || documentQueueItem.status === CHANGES_SAVED_STATUS;
  }

  isInTableIdentification(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }
    return documentQueueItem.status === HUMAN_INPUT_REQUIRED_STATUS && documentQueueItem.step === TABLE_ID_VALIDATION_STEP;
  }

  isExcelFile(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }


    if (documentQueueItem.fileKey) {
      if (documentQueueItem.fileKey.includes('xls') ||
        documentQueueItem.fileKey.includes('xls') ||
        documentQueueItem.fileKey.includes('xls') ||
        documentQueueItem.fileKey.includes('xls')) {
        return true;
      }
    }


    return documentQueueItem.fileSuffix === 'xls' ||
      documentQueueItem.fileSuffix === 'XLS' ||
      documentQueueItem.fileSuffix === 'xlsx' ||
      documentQueueItem.fileSuffix === 'XLSX';
  }

  isTaxReturn(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    if (documentQueueItem &&
      documentQueueItem.processingJobPayload &&
      documentQueueItem.processingJobPayload.documentFile &&
      documentQueueItem.processingJobPayload.documentFile.type) {
      return documentQueueItem.processingJobPayload.documentFile.type === TAX_RETURN;
    } else {
      return true; // we can assume that if there is no processingJobPayload - this was a tax return & did not go to AWS
      // hence, it does not have the processingJobPayload that we expect from lambda processes
    }
  }

  isValidisUpload(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    if (documentQueueItem &&
      documentQueueItem.processingJobPayload &&
      documentQueueItem.processingJobPayload.documentFile &&
      documentQueueItem.processingJobPayload.documentFile.type) {
      return documentQueueItem.processingJobPayload.documentFile.type === "Validis";
    } else {
      return true; // we can assume that if there is no processingJobPayload - this was a tax return & did not go to AWS
      // hence, it does not have the processingJobPayload that we expect from lambda processes
    }
  }

  isEligibleForTableDrawing(documentQueueItem: ReviewQueueItem): boolean {
    return !this.isExcelFile(documentQueueItem) && !this.isTaxReturn(documentQueueItem) && !this.isValidisUpload(documentQueueItem);
  }

  isTableIdentificationAvailable(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    return (
      this.isInTableIdentification(documentQueueItem) ||
      this.isTableTabulationAvailable(documentQueueItem) ||
      this.isFinalReviewAvailable(documentQueueItem) ||
      this.isReadyForSpreading(documentQueueItem)
      ) && this.isEligibleForTableDrawing(documentQueueItem);
  }

  isInTableTabulation(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    return documentQueueItem.status === HUMAN_INPUT_REQUIRED_STATUS && documentQueueItem.step === TABLE_TABULATION_VALIDATION_STEP;
  }

  isTableTabulationAvailable(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    return (
      this.isInTableTabulation(documentQueueItem)
      || this.isFinalReviewAvailable(documentQueueItem)
      || this.isReadyForSpreading(documentQueueItem)
    ) &&
      this.isEligibleForTableDrawing(documentQueueItem);
  }

  isInFinalReview(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    return documentQueueItem.status === HUMAN_INPUT_REQUIRED_STATUS && documentQueueItem.step === FINAL_REVIEW_STEP;
  }

  isFinalReviewAvailable(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }

    return this.isInFinalReview(documentQueueItem) ||
      this.isReadyForSpreading(documentQueueItem);
  }


  isReadyForSpreading(documentQueueItem: ReviewQueueItem): boolean {
    if (documentQueueItem === null) {
      return false;
    }
    return documentQueueItem.status === REVIEWED_STATUS
      || documentQueueItem.status === CHANGES_SAVED_STATUS
      || documentQueueItem.status === COMPLETE_STATUS
      || documentQueueItem.step === SPREADING_STEP
      || documentQueueItem.step === SUCCEEDED_STATUS
      || documentQueueItem.status === NORMALIZED_STATUS;
  }

  downloadOriginalQueueItem(item: ReviewQueueItem): Observable<any> {
    return this._apiService.send('Post', `/api/review-queue-items/${item.id}/download-original`);
  }

  cleanCellPunctuation(originalValue: string): string {
    return originalValue.replace(/[.,$]*/g, '');
  }

  retrieveAndLock(itemId: string): Observable<ReviewQueueItem> {
    return this._apiService.send('Post', `/api/review-queue-items/${itemId}/lock`).pipe(map((data: any) => {
      return data.response.objects.map(obj => new ReviewQueueItem().deserialize(obj))[0];
    }));
  }

  // temporary bootleg check that ensures we're not overchecking
  isValidUUID(string: string): boolean {
    return string.includes('-');
  }

  createBlankTemplatePagesForItem(itemId: string): any {
    return this._apiService.send('Post', `/api/review-queue-items/${itemId}/create_template`);
  }

  isDocumentLocked(reviewQueueItem: ReviewQueueItemInterface | ReviewQueueItem): boolean {
    return !reviewQueueItem?.lockAquired && reviewQueueItem.lockKey !== '' &&  reviewQueueItem.lastLockedBy !== '';
  }

  isDigitizationPage(pageUrl: String): boolean {
    return pageUrl.includes('review') && (
      pageUrl.includes('table_identification') ||
      pageUrl.includes('table_tabulation') ||
      pageUrl.includes('manual_review')
    );
  }

  getItemStatus(itemId: string): Observable<ReviewQueueItem> {
    return this._apiService.send('Post', '/api/review-queue-items/all', {
      'filter': {
        'id_eq': itemId,
      }
    }).pipe(map((data: any) => {
      return data.response.objects.map(obj => new ReviewQueueItem().deserialize(obj))[0];
    }));
  }

}
