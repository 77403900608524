import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { ReviewQueueService } from '../../../../services/review-queue.service';
import { ReviewQueueItem, DocumentParsingStep } from '../../../../models/review-queue-item';
import { SharedDataService } from '../../../../services/shared-data.service';
import { debounceTime } from '../../../../../../node_modules/rxjs/operators';
import { Router } from '../../../../../../node_modules/@angular/router';
import { PubNubResponse } from '../../../../models/pubnub-response';
import { PubNubFileStatusResponse } from '../../../../models/pubnubFileStatusesResponse';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { UserService } from '../../../../services/user.service';
import {DOCUMENT_PASSWORD_ENTRY_REQUIRED, ERROR_STATUS, PLATFORM_PERMISSIONS, TAX_RETURN} from '@utils/constants';
import { DocumentFileService } from '@services/document-file.service';
import { DocumentFile } from '@models/document-file';

@Component({
  selector: 'app-upload-status-popover',
  templateUrl: './upload-status-popover.component.html',
  styleUrls: ['./upload-status-popover.component.scss'],
 })
 @AutoUnsubscribe('subsArr$')
 export class UploadStatusPopoverComponent implements OnInit {
  @Input() isManualReviewer = false;
  @Input() isSpreader = false;
  @Output() closePopover = new EventEmitter();

  isError = false;
  subsArr$: Subscription[] = [];
  documentQueueItems: Array<ReviewQueueItem> = [];
  mode: 'single_document'|'all_documents' = 'all_documents';
  currentDocument: ReviewQueueItem = null;
  currentStatus = null;
  steps: Array<DocumentParsingStep> = [];
  numDocuments = 0;
  documentIsEligibleForTableDrawing = false;

  constructor(
    private _reviewQueueService: ReviewQueueService,
    private _sharedDataService: SharedDataService,
    private _router: Router,
    private _userService: UserService,
    private _documentFileService: DocumentFileService,
  ) {
  }

  ngOnInit() {
    this._subscribeToDocFileStatusChanges();
  }



  openSingleDocMode(idx) {
    this.currentDocument = this.documentQueueItems[idx];
    this.documentIsEligibleForTableDrawing = this._reviewQueueService.isEligibleForTableDrawing(this.currentDocument);
    this.determineSteps();
    this.mode = 'single_document';
  }

  openAllDocMode() {
    this.currentDocument = null;
    this.currentStatus = null;
    this.steps = null;
    this.mode = 'all_documents';
  }

  isSingleDocMode(): boolean {
    return this.mode === 'single_document';
  }

  goToDocumentPasswordEntry(): void {
    if (!this.currentDocument) {
      return;
    }
    this._userService.setBankIdContext(this.currentDocument.bankId);
    this._router.navigate(['/review/' + this.currentDocument.id + '/password_entry']);
    this.closePopover.emit();
  }

  goToTableIdentification(): void {
    if (!this.currentDocument) {
      return;
    }
    this._userService.setBankIdContext(this.currentDocument.bankId);
    this._router.navigate(['/review/' + this.currentDocument.id + '/table_identification']);
    this.closePopover.emit();
  }

  goToTableTabulation(): void {
    if (!this.currentDocument) {
      return;
    }
    this._userService.setBankIdContext(this.currentDocument.bankId);
    this._router.navigate(['/review/' + this.currentDocument.id + '/table_tabulation']);
    this.closePopover.emit();
  }


  goToManualReview(): void {
    if (!this.currentDocument) {
      return;
    }
    this._userService.setBankIdContext(this.currentDocument.bankId);
    this._router.navigate(['/review/' + this.currentDocument.id + '/manual_review']);
    this.closePopover.emit();
  }

  goToSpreading() {
    if (!this.currentDocument) {
      return;
    }

    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.currentDocument.documentFileId).subscribe(files => {
      if (files.length > 0) {
        const documentFile: DocumentFile = files[0];

        this._userService.setBankIdContext(this.currentDocument.bankId);
        this._router.navigate(['/spread/' + documentFile.uuid]);
        this.closePopover.emit();
      }
    }));
  }


  isInTableIdentification(): boolean {
    if (!this.currentDocument) {
      return false;
    }

    return this._reviewQueueService.isInTableIdentification(this.currentDocument);

  }


  isInTableTabulation(): boolean {
    if (!this.currentDocument) {
      return false;
    }

    return this._reviewQueueService.isInTableTabulation(this.currentDocument);
  }



  isInReview(): boolean {
    if (!this.currentDocument) {
      return false;
    }

    return this._reviewQueueService.isInFinalReview(this.currentDocument);
  }

  isReadyForSpreading(): boolean {
    if (!this.currentDocument) {
      return false;
    }

    return this._reviewQueueService.isReadyForSpreading(this.currentDocument);
  }

  determineSteps(): void {
    if (!this.currentDocument) {
      return;
    }

    this.steps = this._reviewQueueService.calculateParsingTrackerSteps(this.currentDocument);
  }

  hasDocuments(): boolean {
    return this.numDocuments > 0;
  }

  contactSupport(e) {
    e.preventDefault();
    e.stopPropagation();

  }

  removeFromQueue(document, e) {
    e.preventDefault();
    e.stopPropagation();
  }

  _updateCurrentDocumentFromPubnub(pubnubEvent: PubNubResponse<PubNubFileStatusResponse>) {
    this.currentDocument.status = pubnubEvent.message.status;
    this.currentDocument.lastLockedDate = pubnubEvent.message.lockedAt;
    this.currentDocument.step = pubnubEvent.message.step;
    this.currentDocument.pageCount = pubnubEvent.message.pageCount;
    this.determineSteps();
  }

  _parseItem(item: ReviewQueueItem): ReviewQueueItem {
    item.fileSuffix = item.fileKey.substr(item.fileKey.lastIndexOf('.') + 1);
    return item;
  }

  _getQueueItems(): void {
    this.isError = false;
    this._reviewQueueService.queueList(1, 10, true, null, true).toPromise().then((data) => {
        this.documentQueueItems = (<ReviewQueueItem[]>data.response.objects).map(item => this._parseItem(item))
        this.numDocuments = Object.keys(this.documentQueueItems).length;
        this._sharedDataService.numParsingTrackerDocuments$.next(this.numDocuments);
    }).catch(err => {
      this.isError = true;
    });
  }

  showLinkButtons() {
    /**
     * The link buttons should only show in a non-error status or if a user has the ability to edit spreads
     * It will not show if we are in the document password step, an error step, or if the user does not have the permission
     */
    if (this.currentDocument?.step === DOCUMENT_PASSWORD_ENTRY_REQUIRED) {
      return false
    }
    return (this.currentDocument?.status !== ERROR_STATUS &&
      this._userService.user.hasUserPermission(PLATFORM_PERMISSIONS.EDIT_SPREADS_AND_RUN_ANALYSIS));
  }

  private _subscribeToDocFileStatusChanges(): void {
    this.subsArr$.push(this._sharedDataService.documentStatusChanges$.pipe(
      debounceTime(150) // debounce so we don't make api call too many times in small period of time
    ).subscribe((pubnubEvent: PubNubResponse<PubNubFileStatusResponse>) => {
      /**
       * There's an edge case we need to account for in which:
       *
       * - The user is currently looking at a single document
       * - That document's review gets completed by someone else
       * - In the subsequent call to getQueueItems, that document will
       *   *no longer appear in the list* since that call filters items
       *   that are complete
       *
       * What we need to do here is understand when the pubnub event is
       * referencing the currently open item, and update that item from
       * the pubnub event rather than the call to load all the review queue
       * items. When the user goes back to the list, that item will no
       * longer appear (which is what we want) but at least nothing funky
       * will happen if they're currently looking at it (it would have
       * disappeared or been replaced by a different item using the old
       * method)
       */
      if (this.currentDocument) {
        this._updateCurrentDocumentFromPubnub(pubnubEvent);
      }
      this._getQueueItems();
    }));
  }
}
