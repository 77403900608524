import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Box} from '../../models/box';

const defaultHeightWidthRatio = 1.3;

/**
 * This dumb component allows the user to draw boxes on an image
 * and keep track of proportions
 */
@Component({
  selector: 'app-document-thumbnail',
  templateUrl: './document-thumbnail.component.html',
  styleUrls: ['./document-thumbnail.component.scss']
})
export class DocumentThumbnailComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() boxes: Array<Box> = [];
  @Input() sourceImageUrl: string;
  @Input() page = null;

  /**
   * This defines the width of the image as we want to render it, which is how we figure out the proportions of the
   * drawn box to the original image size
   */
  @Input() imageWidth: number;

  /**
   * We pass in the show sidebar variable in places where boxdrawing is on a page with the sidebar.
   * This allows us to trigger change detection and recalculate the x offset.
   */
  @Input() sidebar;

  @Output() onImageClickEvent = new EventEmitter<number>();

  @ViewChild('canvas') canvas: ElementRef;

  loadedImage = false;

  imageHeight: number;
  private actualImageWidth: number;
  private actualImageHeight: number;
  imageStyles: any;
  initialized = false;
  imageFailedToLoad: boolean = false;

  protected readonly isNaN = isNaN;


  constructor() {
  }

  ngOnInit() {
    this.imageHeight = this.imageWidth * defaultHeightWidthRatio;
    this.setImageStyles();
  }

  ngAfterViewInit() {
    if (this.sourceImageUrl) {
      this.loadImage();
    }
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.initialized) {
      return
    }
    if (changes.hasOwnProperty('sourceImageUrl') || changes.hasOwnProperty('sidebar')) {
      this.loadImage();
    }
  }

  setImageStyles() {
    this.imageStyles = {
      'background-image': `url('${this.sourceImageUrl}')`,
      'width': `${this.imageWidth}px`,
      'height': `${this.imageHeight}px`
    }
  }

  loadImage() {
    const image = new Image();
    image.onload = () => {
      this.actualImageHeight = image.height;
      this.actualImageWidth = image.width;
      this.determineImageHeight();
      this.loadedImage = true;
      this.setImageStyles();
    }
    image.onerror = () => {
      this.imageFailedToLoad = true;
    }
    image.src = this.sourceImageUrl;
  }

  determineImageHeight() {
    const proportion = this.imageWidth / this.actualImageWidth;
    this.imageHeight = this.actualImageHeight * proportion;
  }

  onImageClick(): void {
    this.onImageClickEvent.emit(this.page.pageIndex);
  }

}
