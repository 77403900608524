import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Company } from '../../../models/company';
import { DataFrameService } from '../../../services/dataframes.service';
import { SpreadingTemplateService } from '../../../services/spreading-template.service';
import { AlertService } from '../../../services/alert.service';
import { LinkedEntitiesService } from '@services/linked-entities.service';
import { GlobalCashflowService } from '@services/global-cashflow.service';
@Component({
  selector: 'app-global-cashflow-analysis-form',
  templateUrl: './global-cashflow-analysis-form.component.html',
  styleUrls: ['./global-cashflow-analysis-form.component.scss']
})
export class GlobalCashflowAnalysisFormComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company = null;
  @Input() formType = '';

  templates = [];
  selectedTemplate;
  analysisForm: FormGroup;
  submitted = false;
  loading = false;
  expandedEntities: Array<any> = [];

  constructor(
    private _dataFrameService: DataFrameService,
    private formBuilder: FormBuilder,
    private _spreadingTemplateService: SpreadingTemplateService,
    private _alertService: AlertService,
    private _linkedEntitiesService: LinkedEntitiesService,
    private _globalCashflowService: GlobalCashflowService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.setForm();

    if (!this.company) {
      return; // somehow got into error state w/ no company id
      // todo, add logger and log error message here for Sentry/DDog
    }

    this._dataFrameService.getPeriodsWithData(this.company.id).subscribe( data => {
      const primaryEntityFormGroup = this.formBuilder.group({
        entityUuid: this.company.uuid,
        entityName: this.company.name,
        periods: new FormArray([]),
        isPrimary: true,
        isBlankEntity: false
      })

      data.forEach(period => {
        const periodGroup = this.formBuilder.group({
          isSelected: [true],
          period: period,
        });
        (primaryEntityFormGroup.get('periods') as FormArray).push(periodGroup);

      });

      (this.analysisForm.get('entities') as FormArray).push(primaryEntityFormGroup);
    });

    this.expandedEntities.push(0);
    this._linkedEntitiesService.getLinkedEntities(this.company.uuid).subscribe( (data) => {
      data.forEach( (eachLinkedEntity, index) => {
        const linkedEntityId = eachLinkedEntity.linkedEntity.id;
        const linkedEntityUuid = eachLinkedEntity.linkedEntity.uuid;
        const linkedEntityName = eachLinkedEntity.linkedEntity.name;

        this._dataFrameService.getPeriodsWithData(linkedEntityId).subscribe( data => {
          const entityFormGroup = this.formBuilder.group({
            entityUuid: linkedEntityUuid,
            entityName: linkedEntityName,
            periods: new FormArray([]),
            isPrimary: false,
            isBlankEntity: new FormControl(false)
          })

          data.forEach(period => {
            const periodGroup = this.formBuilder.group({
              isSelected: [true],
              period: period,
            });
            (entityFormGroup.get('periods') as FormArray).push(periodGroup);

          });

          (this.analysisForm.get('entities') as FormArray).push(entityFormGroup);
          this.expandedEntities.push(index + 1);
        });
      });
    });

    this._spreadingTemplateService.getTemplates('GLOBAL_CASHFLOW', true).subscribe( (data) => {
      this.templates = data;
      if (data.length > 0) {
        this.selectTemplate(data[0].id);
      }
    });

  }

  setForm(): void {
    this.analysisForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      templateId: ['', [Validators.required]],
      primaryEntityPeriods: new FormArray([], [Validators.minLength(1)]), // weirdly, this is first time we're using this pattern
      // and there's a bug with our version of angular where this validator does not work
      // an upgrade at some point in the future should help. :-\
      relatedEntityPeriods: new FormArray([]),
      entities: new FormArray([])
    });
  }


  selectTemplate(templateId: number) {
    this.analysisForm.patchValue({templateId: templateId});
  }

  toggleExpandOn(entityIdx) {
    this.expandedEntities.push(entityIdx);
  }

  toggleExpandOff(entityIdx) {
    const index = this.expandedEntities.indexOf(entityIdx);
    if (index != -1) this.expandedEntities.splice(index, 1);
  }

  entityExpanded(entityIdx) {
    return this.expandedEntities.indexOf(entityIdx) != -1;
  }

  entities(): FormArray {
    return this.analysisForm.get('entities') as FormArray;
  }

  entityPeriods(entityIndex: number): FormArray {
    return this.entities().at(entityIndex).get('periods') as FormArray
  }

  isBlankEntityChecked(entityIndex: number) {
    const entity = this.entities().at(entityIndex) as FormGroup
    const isBlankEntity = entity.get('isBlankEntity').value;
    const entity_periods = entity.get('periods') as FormArray
    for (const period_control of entity_periods.controls) {
      if (isBlankEntity) {
        period_control.disable()
      } else {
        period_control.enable()
      }
    }
  }

  save(analysis: any, isValid: boolean) {
    this.submitted = true;

    const analysisName = analysis.name.trim();

    if (isValid) {
      this.loading = true;
      this._globalCashflowService.createGlobalCashflow(this.company.id, this.analysisForm.value).subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, true);
          this._router.navigate(['companies', this.company.uuid, 'global-cashflow-analyses', data.uuid, 'new']);
        }, error => {
          this._alertService.error(error.message);
          // this._alertService.error('Problem creating analysis. One probably exists with the same name');
          this.submitted = false;
          this.loading = false;
        }
      );
    }
  }

  isFormInvalid(): boolean {
    return !(this.entities().length > 0) || this.analysisForm.get('name').invalid;
  }

  primaryEntityHasPeriods() {
    return this.entities().value[0].periods.length !== 0
  }

  noAvailablePeriodMessageToDisplay() {
    let message = 'Cannot create an analysis. '
    if (this.entities().length <= 1) {
      message += 'There are no linked entities, please link an entity and retry.'
    } else {
      message += 'There are no spread financials available to analyze.'
    }
    return message
  }

  selectOrDeselectAllPeriods() {
    const isAnyPeriodChecked = this.isAnyPeriodChecked();
    const entitiesArray = this.entities();
    for (const entity of entitiesArray.controls) {
      const periodsArray = entity.get('periods') as FormArray;
      periodsArray.controls.forEach((period) => {
        period.get('isSelected').setValue(!isAnyPeriodChecked);
      });
    }
  }

  isAnyPeriodChecked() {
    const entitiesArray = this.entities();
    for (const entity of entitiesArray.controls) {
      const periodsArray = entity.get('periods') as FormArray;
      if (periodsArray) {
        if (periodsArray.controls.some((period) => period.get('isSelected').value === true)) {
          return true;
        }
      }
    }
    return false;
  }
}
