<div class="modal__header">
    <div class="modal__title">
        Add Fiscal Year End Change
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>

<div class="modal__content">
	<p>
	  	<app-input-calendar-component fieldName="fyeDate" [initialDate]="selectedFyeDate"
	    	(dateEmitter)="updateFyeDate($event)">
		</app-input-calendar-component>
	</p>
</div>

<div class="modal__footer">
    <button type="reset" class="btn btn-default upload-footer" (click)="popup.close();">Cancel</button> {{"&nbsp;"}}
    <button type="submit" class="btn btn-primary upload-footer" (click)="addFye()">Add</button>
</div>
