import {
  Component,
  DoCheck,
  Input,
  IterableChangeRecord,
  IterableDiffers,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit
} from '@angular/core';
import { CommentService } from '@services/shared/comment/comment.service';
import { SourceComment } from '@components/main/spreading/models/source-comment';


@Component({
  selector: 'app-comment-present-indicator',
  templateUrl: './comment-present-indicator.component.html',
  styleUrls: ['./comment-present-indicator.component.scss']
})
export class CommentPresentIndicatorComponent implements DoCheck, OnInit {
  commentIsPresent = false;

  private sourceCommentDiffers: Array<KeyValueDiffer<string, any>>;
  private initialized = false;
  private differs;
  private _sourceComments: Array<SourceComment>;

  @Input()
  public set sourceComments(sourceComments: Array<SourceComment>) {
    this._sourceComments = sourceComments;
    if (!!sourceComments) {
      this.initialized = true;
      this.setupIterableDifferToDetectChangesInSourceCommentArray();
      this.determineIfSourceCommentIsPresent(sourceComments);
    }
  }
  public get sourceComments(): Array<SourceComment> {
    return this._sourceComments;
  }

  constructor(
    private commentService: CommentService,
    private iterableDiffers: IterableDiffers,
    private keyValueDiffers: KeyValueDiffers,
  ) {

  }

  ngDoCheck(): void {
    if (!this.initialized) {
      return;
    }
    const changes = this.differs.diff(this._sourceComments);
    if (changes) {
      changes.forEachAddedItem((record: IterableChangeRecord<SourceComment>) => {
        this.determineIfSourceCommentIsPresent(this._sourceComments);
      });
      changes.forEachRemovedItem((record: IterableChangeRecord<SourceComment>) => {
        this.determineIfSourceCommentIsPresent(this._sourceComments);
      });

    }
  }

  ngOnInit(): void {
    this.sourceCommentDiffers = new Array<KeyValueDiffer<string, any>>();
  }

  private setupIterableDifferToDetectChangesInSourceCommentArray(): void {
    if (this.initialized) {
      this.differs = this.iterableDiffers.find([]).create(null);
    }
  }

  private determineIfSourceCommentIsPresent(sourceComments: Array<SourceComment>) {
    if (!!sourceComments && sourceComments.length > 0) {
      this.commentIsPresent = this.commentService.commentIsPopulated(sourceComments[0]?.comment);
      return;
    }
    this.commentIsPresent = false;
  }

}
