import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-navigation-step',
  templateUrl: './navigation-step.component.html',
  styleUrls: ['./navigation-step.component.scss']
})

export class NavigationStepComponent {
  @Input() displayText: string;
  @Input() stepNumber: number;
  @Input() action?: () => void;
  @Input() isCurrentStep: boolean = false;
  @Input() isCompleted: boolean = false;

  handleStepClick() {
    if (!this.isCurrentStep && this.action) {
      this.action();
    }
  }
}
