import { DataFrame } from './dataframe';

export class DataFrameKey {

  static fromDataFrame(dataFrame: DataFrame) {
      const { reportingPeriod, preparationType } = dataFrame;
      return new this(reportingPeriod, preparationType);
  }

  static fromKeyString(flatkey: string) {
      const parts = flatkey.split('.');
      if (parts.length === 4) {
        return new this(parts.slice(0, 3).join('.'), parts[3].toUpperCase());
      } else if (parts.length === 3) {
        return new this(flatkey);
      } else {
        throw Error('Unknow dataframe key format');
      }
  }

  reportingPeriod: string;
  preparationType: string;

  constructor(reportingPeriod: string, preparationType = 'UNKNOWN') {
    this.reportingPeriod = reportingPeriod;
    this.preparationType = preparationType;
  }

  toString(): string {
    return `${this.reportingPeriod}.${this.preparationType.toLowerCase()}`;
  }
}
