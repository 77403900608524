import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class InsightsService {

    constructor(
        private _apiService: ApiService,
      ) {
      }

    getPeriodOverPeriodAnalysis(payload) {
        return this._apiService.send(
            'Post',
            `/api/analytics/period-over-period-analysis`,
            payload,
          )
    }
}
