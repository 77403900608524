import { OnInit, Component, ChangeDetectionStrategy } from '@angular/core';
import { StatementDataService } from '../data/statement-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Statement } from '../../review/human-validation/models/statement';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { LoggingService, Logger } from '../../../../services/logging.service';
import { PreviousRouteService } from '../../../../services/previous-route.service';
import { SharedDataService } from '../../../../services/shared-data.service';
import { DocumentFileService } from '@services/document-file.service';
import { DocumentFile } from '@models/document-file';

const STATEMENT_TYPE_ORDER = [
  'INCOME_STATEMENT',
  'BALANCE_SHEET',
  'CASH_FLOW_STATEMENT'
]

function getStatementTypeOrder(statement) {
  // lower idx is high priority
  const idx = STATEMENT_TYPE_ORDER.indexOf(statement.statementType)
  if (idx === -1) {
    return 1000;
  }
  return idx;
}



/**
 * This component handles a request to "spread a file" by
 * determining what is the "first statement ID" for that file and
 * then redirecting to the spreading screen for that ststement.
 *
 * I expect it will be short-lived in that we might want to move
 * the "spread" button from the "documents" list to a new
 * "statements" list.
 *
 * @author Jason Raede <jraede@fincura.com>
 */
@Component({
  selector: 'app-spread-file-redirect',
  templateUrl: './spread-file-redirect.component.html',
  styleUrls: ['./spread-file-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe('subsArr$')
export class SpreadFileRedirectComponent implements OnInit {
  logger: Logger;
  subsArr$: Subscription[] = [];
  constructor(
    private _statementDataService: StatementDataService,
    private _route: ActivatedRoute,
    private _router: Router,
    public loggingService: LoggingService,
    private _previousRouteService: PreviousRouteService,
    private _sharedDataService: SharedDataService,
    private _documentFileService: DocumentFileService,
  ) {
    this.logger = this.loggingService.rootLogger.newLogger('SpreadFileRedirectComponent');
  }

  ngOnInit() {
    this._sharedDataService.setHILExitUrl(this._previousRouteService.getPreviousUrl());

    this.subsArr$.push(this._route.paramMap.subscribe(params => {
      this.handleRedirect(params.get('file_uuid'));
    }));
  }

  handleRedirect(fileUuid: string) {
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, null, null, fileUuid).subscribe(files => {
      if (files.length > 0) {
        const documentFile: DocumentFile = files[0];

        this._statementDataService.loadStatementsForFile(documentFile.id).then((statements: Array<Statement>) => {
          if (statements === null || statements.length === 0) { // if no statements, send to 404 instead of allowing error
            this._router.navigateByUrl('/404', {
              replaceUrl: true,
            });
          }
    
          // this sorting allows us to try and open statement in this order IS, BS, CF
          statements.sort((a, b) => (getStatementTypeOrder(a) > getStatementTypeOrder(b)) ? 1 : -1)
    
          this._router.navigateByUrl(`/statements/${statements[0].uuid}/edit`, {
            replaceUrl: true,
          });
        }).catch(err => {
          this.logger.errorObj(err);
          this._router.navigateByUrl('/404', {
            replaceUrl: true,
          });
        });
      }
    }));


  }
}
