import {
  AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnChanges, OnDestroy, Output, Renderer2,
  SimpleChanges, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Select2OptionData } from './select2.interface';

declare var jQuery: any;

@Component({
  selector: 'app-select2',
  template: `<select #selector>
    <ng-content select="option, optgroup">
    </ng-content>
  </select>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Select2Component),
      multi: true
    }
  ]
})
export class Select2Component implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild('selector', { static: true }) selector: ElementRef;

  // data for select2 drop down
  @Input() data: Array<Select2OptionData>;

  // value for placeholder
  @Input() placeholder = '';

  @Input() dropdownParent = '';


  @Input() allowClear = false;

  // value for select2
  @Input() value: string | string[];

  // enable / disable default style for select2
  // @Input() cssImport = false;

  // width of select2 input
  @Input() width: string;

  // enable / disable select2
  @Input() disabled = false;

  // all additional options
  @Input() options: any;

  // emitter when value is changed
  @Output() valueChanged = new EventEmitter();

  private element: any = undefined;
  private check = false;

  constructor(private renderer: Renderer2, public zone: NgZone, public _element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!this.element) {
      return;
    }

    if (changes['data'] && JSON.stringify(changes['data'].previousValue) !== JSON.stringify(changes['data'].currentValue)) {
      this.initPlugin();
      const newValue: string | string[] = this.value;
      this.setElementValue(newValue);
      this.propagateChange(newValue);
    }

    if (changes['value'] && changes['value'].previousValue !== changes['value'].currentValue) {
      const newValue: string = changes['value'].currentValue;
      this.setElementValue(newValue);
      this.propagateChange(newValue);
    }

    if (changes['disabled'] && changes['disabled'].previousValue !== changes['disabled'].currentValue) {
      this.renderer.setProperty(this.selector.nativeElement, 'disabled', this.disabled);
    }

    if (changes['placeholder'] && changes['placeholder'].previousValue !== changes['placeholder'].currentValue) {
      this.renderer.setAttribute(this.selector.nativeElement, 'data-placeholder', this.placeholder);
    }

    if (changes['dropdownParent'] && changes['dropdownParent'].previousValue !== changes['dropdownParent'].currentValue) {
      this.renderer.setAttribute(this.selector.nativeElement, 'data-dropdownParent', this.dropdownParent);
    }

    if (changes['allowClear'] && changes['allowClear'].previousValue !== changes['allowClear'].currentValue) {
      this.renderer.setAttribute(this.selector.nativeElement, 'data-allow-clear', this.allowClear.toString());
    }
  }

  ngAfterViewInit() {
    this.element = jQuery(this.selector.nativeElement);
    this.renderer.setAttribute(this.selector.nativeElement, 'data-placeholder', this.placeholder);
    this.renderer.setAttribute(this.selector.nativeElement, 'data-dropdownParent', this.dropdownParent);
    this.renderer.setAttribute(this.selector.nativeElement, 'data-allow-clear', this.allowClear.toString());

    this.initPlugin();

    if (typeof this.value !== 'undefined') {
      this.setElementValue(this.value);
    }


    this.element.on('select2:select select2:unselect', (e: any) => {
      // const newValue: string = (e.type === 'select2:unselect') ? '' : this.element.val();
      const newValue = this.element.val();

      this.valueChanged.emit({
        value: newValue,
        data: this.element.select2('data')
      });
      this.propagateChange(newValue);
      this.setElementValue(newValue);
    });
  }

  ngOnDestroy() {
    if (this.element) {
      this.element.off('select2:select');
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.setElementValue(value);
    }
  }

  propagateChange = (value: any) => {
  };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
    this.valueChanged.subscribe(fn);
  }

  registerOnTouched() {
  }

  private initPlugin() {
    if (!this.element.select2) {
      if (!this.check) {
        this.check = true;
        console.log('Please add Select2 library (js file) to the project. ' +
          'You can download it from https://github.com/select2/select2/tree/master/dist/js.');
      }
      return;
    }

    // If select2 already initialized remove him and remove all tags inside
    if (this.element.hasClass('select2-hidden-accessible') === true) {
      this.element.select2('destroy');
      this.renderer.setProperty(this.selector.nativeElement, 'innerHTML', '');
    }

    let options: any = {
      data: this.data,
      width: (this.width) ? this.width : 'resolve',
      theme: 'bootstrap'
    };

    if (this.dropdownParent) {
      options = {
        data: this.data,
        width: (this.width) ? this.width : 'resolve',
        dropdownParent: jQuery('#' + this.dropdownParent),
        theme: 'bootstrap'
      };
    }

    Object.assign(options, this.options);

    if (options.matcher) {
      jQuery.fn.select2.amd.require(['select2/compat/matcher'], (oldMatcher: any) => {
        options.matcher = oldMatcher(options.matcher);
        this.element.select2(options);

        if (typeof this.value !== 'undefined') {
          this.setElementValue(this.value);
        }
      });
    } else {
      this.element.select2(options);
    }

    this.renderer.setProperty(this.selector.nativeElement, 'disabled', this.disabled);
  }

  private setElementValue(newValue: string | string[]) {

    if (Array.isArray(newValue)) {

      for (const option of this.selector.nativeElement.options) {
        this.renderer.setProperty(option, 'selected', (newValue.indexOf(option.value) > -1));
      }
    } else {
      this.renderer.setProperty(this.selector.nativeElement, 'value', newValue);
    }

    if (this.element) {
      this.element.trigger('change.select2');
    }
  }
}
