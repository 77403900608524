import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewQueueService } from '../../../../../services/review-queue.service';
import { NgxPopupService } from '../../../../shared/ngx-popups/ngx-popups/ngx-popups';
import { StatusModalComponent } from '../shared/status-modal/status-modal.component';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { PreviousRouteService } from '../../../../../services/previous-route.service';
import { LoggingService, Logger } from '../../../../../services/logging.service';

@Component({
  selector: 'app-wait-for-review',
  templateUrl: './wait-for-review.component.html',
  styleUrls: ['./wait-for-review.component.css']
})
@AutoUnsubscribe('subsArr$')
export class WaitForReviewComponent implements OnInit, OnDestroy {
  subsArr$: Subscription[] = [];
  isLoading = true;
  // error = '';
  reviewQueueItemId = '';
  companyUuid = null;
  embedded = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _reviewQueueService: ReviewQueueService,
    private _popupService: NgxPopupService,
    private _sharedDataService: SharedDataService,
    private _previousRouteService: PreviousRouteService,
    public loggingService: LoggingService,
) { }

  ngOnInit() {
    this._sharedDataService.hideDefaultNavigation();

    this._sharedDataService.setHILExitUrl(this._previousRouteService.getPreviousUrl());

    this._sharedDataService.embeddedWorkflow$.subscribe((flow) => {
        this.setEmbeddedWorkflow(flow);
    });

    this._route.paramMap.subscribe(params => {
      if (!this.embedded) {
        this.companyUuid = params.get('companyUuid');
      }
    });

    this.subsArr$.push(this._route.parent.paramMap.subscribe((params => {
      this.reviewQueueItemId = params.get('uuid');
      if (!this._reviewQueueService.isValidUUID(this.reviewQueueItemId)) {
        this._router.navigate(['/404']);
        return;
      }

      setTimeout(() => {
      this._popupService.open({
        componentType: StatusModalComponent,
        cssClass: 'modal-pipeline-processing',
        inputs: {
          reviewQueueItemId: this.reviewQueueItemId,
          companyUuid: this.companyUuid,
          closable: false,
        },
      });
    }, 1);
    })));
  }

  ngOnDestroy() {
    this._sharedDataService.showDefaultNavigation();
  }

  setEmbeddedWorkflow(flow): void {
    if (flow === null) {
      this.embedded = false;
    } else {
      this.embedded = true;
    }
  }

}
