<div *ngIf="loading">
	<div class="loader__container">
		<div class="loader"></div>
	</div>
</div>
<div *ngIf="!loading" >
    <div class="sidebar-wrapper col-3 col-sm-3 col-md-3 col-lg-2 float-start">
        <div class="borrower-upload-sidebar">
          <div id="portal-info" class="portal-info">
            <h3>Connect your account</h3>
            <p>You have been asked to connect to Fincura by {{bank}}.</p>

            Questions? <a id="support-link" href="mailto:{{supportEmail}}" target="_top" [style.color]="primaryColor">Contact Us</a>

            <div class="powered-by">
              POWERED BY <img class="icon" src="favicon.ico" /> NUMERATED
            </div>
          </div>
        </div>
      </div>


      <div class="borrower-documents-upload">
        <div class="header">
          <img class="customer-logo" [src]="customBankImg" alt="Logo"/>
          <p>Secure Borrower Reporting Portal</p>
          <i class="fas fa-lock"></i>
        </div>

        <div class="main-content-container">
          <div class="invitation-info" [hidden]="complete">
            <h3>Accounting Software Integration</h3>
            <p>{{bank}} uses Fincura to read Income Statement and Balance sheets.</p>
            <p class="margin-bottom">Choose your accounting software below to connect your account.</p>
          </div>


            <h2 class="choose-provider">Choose your software vendor to get started:</h2>
            <div class="row">
              <div class="col-3 offset-2" style="text-align: center;" *ngFor="let integrationVendor of vendors">
                  <a><app-accounting-integration-logo [vendor]="integrationVendor" (click)="linkVendor(integrationVendor)"></app-accounting-integration-logo></a>
              </div>
          </div>

        </div>
      </div>
</div>
