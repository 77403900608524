import { Component, OnInit } from '@angular/core';

const COLORS = [
  { key: 'text', value: '#464646'},
  { key: 'white', value: '#ffffff'},
  { key: 'gray1', value: '#f5f5f5'},
  { key: 'gray2', value: '#eeeeee'},
  { key: 'gray2Opaque', value: 'rgba(238, 238, 238, 1)'},
  { key: 'gray3', value: '#cccccc'},
  { key: 'gray4', value: '#aaaaaa'},
  { key: 'gray5', value: '#898989'},
  { key: 'gray6', value: '#676767'},
  { key: 'gray7', value: '#464646'},
  { key: 'gray8', value: '#3a3a3a'},
  { key: 'gray9', value: '#2d2d2d'},
  { key: 'gray10', value: '#202020'},
  { key: 'gray11', value: 'rgba(161, 161, 161, 0.7)'},
  { key: 'gray12', value: '#a5a5a5'},
  { key: 'gray13', value: '#f8f8f8'},
  { key: 'black', value: '#000'},
  { key: 'blackOpaque1', value: 'rgba(0, 0, 0, 1.0)'},
  { key: 'blackOpaque2', value: 'rgba(0, 0, 0, 0.2)'},
  { key: 'aliceBlue', value: '#F7F9FB'},
  { key: 'primary', value: '#83BAE0'},
  { key: 'primary1', value: '#d8dee3'},
  { key: 'primary2', value: '#c1d5e3'},
  { key: 'primary3', value: '#a2c4db'},
  { key: 'primary4', value: '#8cbdde'},
  { key: 'primary5', value: '#6db3e3'},
  { key: 'primary6', value: '#45a5e6'},
  { key: 'primary7', value: '#2196f3'},
  { key: 'primary8', value: '#0476d1'},
  { key: 'success', value: '#2196f3'},
  { key: 'success1', value: '#c9e7ff'},
  { key: 'success2', value: '#b0dcff'},
  { key: 'success3', value: '#93c9f5'},
  { key: 'success4', value: '#73b6eb'},
  { key: 'success5', value: '#55adf2'},
  { key: 'success6', value: '#2196f3'},
  { key: 'success7', value: '#0e86e6'},
  { key: 'success8', value: '#046cd4'},
  { key: 'success9', value: '#2196f3'},
  { key: 'info', value: '#5bc0be'},
  { key: 'info1', value: '#f0f9f9'},
  { key: 'info2', value: '#d2eded'},
  { key: 'info3', value: '#b4e2e1'},
  { key: 'info4', value: '#96d6d5'},
  { key: 'info5', value: '#78cbc9'},
  { key: 'info6', value: '#5bc0be'},
  { key: 'info7', value: '#4b9e9c'},
  { key: 'info8', value: '#3a7b79'},
  { key: 'info9', value: '#2a5857'},
  { key: 'warning', value: '#f2bb05'},
  { key: 'warning1', value: '#fdf8e8'},
  { key: 'warning2', value: '#fbecba'},
  { key: 'warning3', value: '#f9e08d'},
  { key: 'warning4', value: '#f6d35f'},
  { key: 'warning5', value: '#f4c732'},
  { key: 'warning6', value: '#f2bb05'},
  { key: 'warning7', value: '#c79a05'},
  { key: 'warning8', value: '#9b7704'},
  { key: 'warning9', value: '#6e5503'},
  { key: 'danger', value: '#d74e09'},
  { key: 'danger1', value: '#fbeee8'},
  { key: 'danger2', value: '#f4cebb'},
  { key: 'danger3', value: '#ecae8f'},
  { key: 'danger4', value: '#e58e62'},
  { key: 'danger5', value: '#de6e35'},
  { key: 'danger6', value: '#d74e09'},
  { key: 'danger7', value: '#b04008'},
  { key: 'danger8', value: '#893206'},
  { key: 'danger9', value: '#622405'},
  { key: 'orange_1', value: '#f7cfb5'},
  { key: 'orange_2', value: '#ff8818'},
  { key: 'is', value: '#ff6c43'},
  { key: 'bs', value: '#ad3387'},
  { key: 'cf', value: '#e73e6b'},
  { key: 'cv', value: '#5d388c'},
  { key: 'other', value: '#ffa600'},
  { key: 'defaultBackground', value: '#fafafa'},
  { key: 'defaultBoxShadow', value: '#a1a1a1'},
  { key: 'defaultBorderColor', value: '$gray-3'},
  { key: 'mainBgColor', value: '$white'},
  { key: 'inputBorderFocus', value: '$primary'},
  { key: 'grayFont', value: '$gray-5'},
  { key: 'primaryButtonBlue', value: '#2196f3'},
  { key: 'popupTransitionTime', value: '250ms'},
  { key: 'inputLabelColor', value: '$gray-5'},
  { key: 'inputTextColor', value: '$black'},
  { key: 'modalTitleColor', value: '$gray-5'},
  { key: 'textWhite', value: '$white'},
  { key: 'textBlack', value: '$black'},
  { key: 'modalLineColor', value: '$gray-2'},
  { key: 'errorColor', value: '$danger'},
  { key: 'enableColor', value: '$success'},
  { key: 'highlightRed', value: '#e85151'},
  { key: 'highlightBlue', value: '#1a6ce6'},
  { key: 'highlightLavender', value: '#ca6bf9'},
  { key: 'highlightOrange', value: '#dfa000'},
  { key: 'highlightDarkGreen', value: '#538c5f'},
  { key: 'highlightPink', value: '#e63a76'},
  { key: 'highlightLightGreen', value: '#10d01d'},
  { key: 'highlight1', value: '$highlight-red'},
  { key: 'highlight2', value: '$highlight-blue'},
  { key: 'highlight3', value: '$highlight-lavender'},
  { key: 'highlight4', value: '$highlight-orange'},
  { key: 'highlight5', value: '$highlight-dark-green'},
  { key: 'highlight6', value: '$highlight-pink'},
  { key: 'highlight7', value: '$highlight-light-green'},
  { key: 'categoryYellow', value: '#ffcd50'},
  { key: 'categoryOrange', value: '#fc9d71'},
  { key: 'categorySalmon', value: '#ff7699'},
  { key: 'categoryPink', value: '#ff74dd'},
  { key: 'categoryPurple', value: '#b07ffb'},
  { key: 'categoryBlue', value: '#699bff'},
  { key: 'categoryLightBlue', value: '#6ed1f9'},
  { key: 'categoryBlueGreen', value: '#6ae7c9'},
  { key: 'categoryGreen', value: '#8beb6a'},
  { key: 'categoryTextColor', value: '$text'},
  { key: 'lightTeal', value: '#A3C9CB'},
  { key: 'portfolio0', value: '$info-5'},
  { key: 'portfolio1', value: '$category-green'},
  { key: 'portfolio2', value: '$category-blue-green'},
  { key: 'portfolio3', value: '$category-light-blue'},
  { key: 'portfolio4', value: '$category-blue'},
  { key: 'portfolio5', value: '$category-purple'},
  { key: 'portfolio6', value: '$category-pink'},
  { key: 'portfolio7', value: '$category-salmon'},
  { key: 'portfolio8', value: '$category-orange'},
  { key: 'portfolio9', value: '$category-yellow'},
  { key: 'cellInsufficientRed', value: '#dfb2b2'},
  { key: 'cellSufficientGreen', value: '#d0ece6'},
  { key: 'portfolios', value: '$portfolio-0, $portfolio-1, $portfolio-2, $portfolio-3, $portfolio-4, $portfolio-5, $portfolio-6, $portfolio-7, $portfolio-8, $portfolio-9'},
  { key: 'graphColorsActiveBlue', value: '#3275C2'},
  { key: 'graphColorsInactiveBlue', value: '#97B9E0'},
  { key: 'graphColorsActiveRed', value: '#D08F95'},
  { key: 'graphColorsInactiveRed', value: '#E7C6C9'},
  { key: 'templateItemWarning', value: '#8C5F00'},
]


@Component({
  selector: 'app-color-pallette',
  templateUrl: './color-pallette.component.html',
  styleUrls: ['./color-pallette.component.scss']
})
export class ColorPalletteComponent implements OnInit {

  chunks: any[] = [];

  constructor() { }

  ngOnInit() {
    let i, j, temparray;
    const chunk = 10;
    for (i = 0, j = COLORS.length; i < j; i += chunk) {
        temparray = COLORS.slice(i, i + chunk);
        this.chunks.push(temparray);
    }
  }

}
