import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { SignedUrlService } from '../../../../services/signed-url.service';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-api-key-revoke',
  templateUrl: './api-key-revoke.component.html',
  styleUrls: ['./api-key-revoke.component.css']
})
@AutoUnsubscribe('subsArr$')
export class ApiKeyRevokeComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY

  submitted = false;
  subsArr$: Subscription[] = [];

  constructor(
    private signedUrlService: SignedUrlService,
    private alertService: AlertService) { }

  ngOnInit() {
  }

  revoke() {
    this.submitted = true;

    this.subsArr$.push(this.signedUrlService.createApiKey('')
      .subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, true);
          // this.alertService.success('Api Key created successfully');
        },
        error => {
          this.submitted = false;
          this.alertService.error(error.message);
        },
      ));
  }

}
