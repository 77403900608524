import { FileItem, FileUploader } from 'ng2-file-upload';
import { DocumentFileCreateRequestState, DocumentType } from '../utils/enums';

export class DocumentFileCreateRequest {
  state: DocumentFileCreateRequestState = DocumentFileCreateRequestState.Init;
  companyId: number;
  s3FileKey: string;
  originalDocumentName: string;
  documentType: string;
  taxFormType: string;
  periodType: string;
  statementDate: string;
  reportedDate: string;
  roundedToThousands: boolean;
  currency: string;
  uploadProgress?: number;
  uploader?: FileUploader;
  fileItem?: FileItem;
  additionalInformation?: string;
  requestedPeriods?: string;
  customAttributes?: object = {};
}
