import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthMiddleware } from '../../middlewares/auth.middleware';
import { PopupsModule } from '../shared/popups/popups.module';
import { HttpClientModule } from '@angular/common/http';

import { DEVELOPERROUTES } from './developer.route';
import { DeveloperService } from './developer.service';
import { CacheComponent } from './command/cache/cache.component';
import { RestComponent } from './command/rest/rest.component';
import { DeployApiKeyButtonComponent } from './deploy-api-key-button/deploy-api-key-button.component';
import { AppStyleGuideComponent } from './app-style-guide/app-style-guide.component';
import { ColorPalletteComponent } from './app-style-guide/color-pallette/color-pallette.component';
import { ButtonsComponent } from './app-style-guide/buttons/buttons.component';
import { PanelsComponent } from './app-style-guide/panels/panels.component';
import { LoadersComponent } from './app-style-guide/loaders/loaders.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PopupsModule,
    DEVELOPERROUTES,
  ],
  exports: [
    DeployApiKeyButtonComponent
  ],
  declarations: [
    RestComponent,
    CacheComponent,
    DeployApiKeyButtonComponent,
    ColorPalletteComponent,
    ButtonsComponent,
    AppStyleGuideComponent,
    PanelsComponent,
    LoadersComponent,
  ],
  providers: [
    AuthMiddleware,
    DeveloperService
  ],
  entryComponents: [
    RestComponent,
  ]
})
export class DeveloperModule { }
