import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutoUnsubscribe} from "@decorators/auto-unsubscribe";
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import { ChangeLogService } from '@services/change-log.service';
import { CallTypeCategory } from '@utils/enums';

@AutoUnsubscribe('subsArr$')
@Component({
  selector: 'app-change-log-modal',
  templateUrl: './change-log-modal.component.html',
  styleUrls: ['./change-log-modal.component.scss']
})
export class ChangeLogModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() companyId: number;
  @Input() analysisId: number | null;
  @Input() callTypeCategory: CallTypeCategory;

  @Output() callback = new EventEmitter<boolean>();
  subsArr$: Subscription[] = [];
  showSpinner: boolean = false;
  changeLogs = [];
  helperText = '';
  
  HEADER_TEXT = 'CHANGE LOG';

  constructor(
    public changeLogService: ChangeLogService,
  ) {
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.helperText = this.analysisId === null ? 'History of any changes that altered spread data.' : 'History of any changes to analysis.';
    
    if (this.callTypeCategory) {
      this.retrieveLogData()
      .pipe(
        finalize(() => {
          this.showSpinner = false;
        }))
      .subscribe(results => {
        this.changeLogs = results;
      }, error => {
        console.error('Failed to load change log for company.', error);
        this.closeModal();
      });
    }
  }

  closeModal() {
    this.callback.emit(false);
    this.popup.close();
  }

  retrieveLogData() {
    if (this.callTypeCategory === CallTypeCategory.Spread) {
      return this.changeLogService.loadSpreadDataChangeLogForCompany(this.companyId);
    } else if (this.callTypeCategory === CallTypeCategory.SingleEntityAnalysis) {
      return this.changeLogService.loadSingleEntityAnalysisChangeLogForCompany(this.companyId, this.analysisId);
    } else if (this.callTypeCategory === CallTypeCategory.MultiEntityAnalysis) {
      return this.changeLogService.loadMultiEntityAnalysisChangeLogForCompany(this.companyId, this.analysisId);
    }
  }
}
