<div class="modal__header">
    <div class="modal__title">
        REVOKE API KEY
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form (submit)="revoke()">
    <div class="modal__content">
        <div class="form-header">
            Are you sure you want to revoke this API key? This cannot be undone.
        </div>
    </div>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-danger" [disabled]="submitted">Revoke API Key</button>
    </div>
</form>