<div class="modal__header">
    <div class="modal__title">
        ADD API KEY
    </div>
    <div class="modal__close" (click)="popup.close(created, created);">
        <i class="fas fa-times"></i>
    </div>
</div>
<form *ngIf="!created" [formGroup]="apiKeyForm" (submit)="save(apiKeyForm.value, apiKeyForm.valid)">
    <div class="modal__content">
        <div class="form-header">
            Please give this key a label
        </div>

        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="control-label" for="label">Label</label>
                    <input [ngClass]="{'is-invalid': isFieldInvalid('label')}" type="text" class="form-control"
                           id="label" placeholder="e.g. XYZ Server API Key" formControlName="label" maxlength="25">
                    <span class="invalid-feedback"
                          *ngIf="isFieldInvalid('label') && apiKeyForm.get('label').hasError('required')">
                        Label is required
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal__footer d-flex justify-content-end">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="submitted">Create API Key</button>
    </div>
</form>
<form *ngIf="created" [formGroup]="apiKeyForm" (submit)="save(apiKeyForm.value, apiKeyForm.valid)">
    <div class="modal__content">
        <div class="form-header">
            Copy your api key details below. You will not be able to see this information again.
            <br />
            <br />
        </div>

        <div class="mb-3">
            <label for="api-key-auth-token">Auth Token</label>
            <div class="input-group">
              <input id="api-key-auth-token" type="text" class="form-control" readonly="readonly" value="{{createdApiKey?.jwt}}">
              <button class="btn btn-outline-secondary group-append-button" type="button" title="Copy auth token to clipboard" (click)="copyToClipboard('api-key-auth-token', 'API Auth Token')"><i class="fas fa-clipboard"></i></button>
            </div>
        </div>
        <div class="mb-3">
            <label for="api-key-refresh-token">Refresh Token</label>
            <div class="input-group">
              <input id="api-key-refresh-token" type="text" class="form-control" readonly="readonly" value="{{createdApiKey?.refreshToken}}">
              <button class="btn btn-outline-secondary group-append-button" type="button" title="Copy refresh token to clipboard" (click)="copyToClipboard('api-key-refresh-token', 'API Refresh Token')"><i class="fas fa-clipboard"></i></button>
          </div>
        </div>
        <a class="nav-link" (click)="useInSwagger()">Use in Swagger</a>
        <div *ngIf="showDeployButton">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-12">
                <label for="basic-url">Fincura Demo App</label>
            </div>
            <div class="col-7">
                <div class="mb-3">
                  <div class="col-sm-6 col-md-4">
                    <app-select2
                        [value]="'getting_started_python'"
                        placeholder="Choose..."
                        [data]="demoAppOptions"
                        [width]="250"
                    ></app-select2>
                  </div>
                </div>
              </div><!-- /.col-lg-6 -->
              <div class="col-5">
                <app-deploy-api-key-button *ngIf="showDeployButton" [apiKey]="createdApiKey"></app-deploy-api-key-button>
              </div>
        </div>
    </div>

    <div class="modal__footer d-flex justify-content-end">
        <button type="reset" class="btn btn-link" (click)="popup.close(created, created);">Close</button>
    </div>
</form>
