<div class="main-content">
    <div class="toolbar">
        <div class="hidden-flex-item"></div>
        <app-pdf-zoom class="center-flex-item" [zoomElementContainerId]="'page-image'"></app-pdf-zoom>
        <div class="right-flex-item">
            <button class="btn btn-empty-primary" (click)="exitSplitscreen()"><i class="fas fa-xmark"></i>Close
            </button>
        </div>
    </div>

    <div *ngIf="!this.errorGettingPageImg; else errorMessage" class="doc-container">
        <div class="fs-block" id="page-image">
            <app-image-highlighter
                *ngIf="activePageUrl"
                [cropper]="readOnlyCropper"
                [pageImgUrl]="activePageUrl"
            >
            </app-image-highlighter>
        </div>
    </div>
    <ng-template #errorMessage>
        <div class="error-message">
            <h5>Error retrieving original document for display.</h5>
        </div>
    </ng-template>
</div>
