<div class="modal__header">
  <div class="modal__title">
    SEND REST COMMANDS TO THE API PROVIDER
  </div>
  <div class="modal__close" (click)="popup.close();">
    <i class="fas fa-times"></i>
  </div>
</div>
<form [formGroup]="restForm" (submit)="send(restForm.value, restForm.valid)">
  <div class="modal__content">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="control-label" for="apiUrl">Api Endpoint</label>
          <select class="form-select" formControlName="apiUrl" (change)="updateValues()">
            <option *ngFor="let url of apiUrl" [value]="url">
              {{url}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="control-label" for="verb">Verb</label>
      <select class="form-select" formControlName="verb" name="verb" (change)="updateValues()">
        <option value="Get">Get</option>
        <option value="Put">Put</option>
        <option value="Patch">Patch</option>
        <option value="Post">Post</option>
        <option value="Delete">Delete</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="control-label" for="endpointurl">Url</label>
      <input type="text" class="form-control" formControlName="endpointurl" name="endpointurl">
    </div>
    <div [hidden]="!showProfiling()" class="mb-3">
      <label class="choice-label" for="profileTime">Profile by time:</label>
      <input class="choice" type="checkbox" formControlName="profileTime" name="profileTime">
      <label class="choice-label" for="profileCaller">caller-callee:</label>
      <input class="choice" type="checkbox" formControlName="profileCaller" name="profileCaller">
      <label class="choice-label" for="namespaceFilter">Namespace filter:</label>
      <input class="choice" type="text" maxlength="20" formControlName="namespaceFilter" name="namespaceFilter">
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="control-label" for="body">Body (Typically json. Use double quotes instead of single.)</label>
          <textarea id="body" class="form-control" name="body" rows="4" formControlName="body">
            </textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label class="control-label" for="response">Response</label>
          <textarea id="body" [attr.readonly]="true" class="form-control" name="response" rows="4" formControlName="response">
            </textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="modal__footer d-flex justify-content-end">
    <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
    <button type="submit" class="btn btn-primary">Submit</button>
    <app-deploy-api-key-button *ngIf="apiKey" [apiKey]="apiKey"></app-deploy-api-key-button>
  </div>
  <div *ngIf="loading && loadMessage" class="alert alert-failure box-msg" role="alert">
    <strong><span>{{loadMessage}}</span></strong>
  </div>
</form>
