<ng-container *ngIf="pageViewMode === COMPANY_PAGE_VIEW_MODE.FULL; else NoAccessToStatementBuilderTool">
  <div *ngIf="loading && !showCalculating && !showCallToAction">
    <div class="loader__container">
        <div class="loader"></div>
    </div>
    <div class="center-div">{{loadingMessage}}</div>
  </div>
  <!-- <div class="calculating-loader-container" *ngIf="showCalculating">
    <app-calculating
        [finish]="finishedCalculating"
        (finishedLoading)="hideCalculatingLoader()"
    ></app-calculating>
  </div> -->
  <div *ngIf="didErrorOccur">
    <div class="loader__container">
        <div class="col-6 offset-3">
            <i class="fas fa-exclamation-triangle icon"></i>
            <h3>An error occurred and our team has been notified.</h3>
            <h3>Please reach out to support via our in app chat if you have further questions.</h3>
        </div>
    </div>
  </div>
  <ng-container *ngIf="!loading && !didErrorOccur">
      <div class="row">
          <div class="col-sm-5 builder-header">
              <h5 class="float-start">
                  Statement Builder
              </h5>
          </div>
          <div class="col-sm-10">

          </div>
      </div>
      <div class="row">
          <div class="col-8">
              <div class="parent-financials scroll financials-table" [ngClass]="isGraphShown ? 'extra-space' : ''">
                  <div *ngIf="!activeDataView || !activeDataView.rows.length">
                      <div class="text-center empty-message">
                          There is no data!
                      </div>
                  </div>
                  <div *ngIf="activeDataView && activeDataView.rows.length">
                      <app-builder-financials-table
                          [rows]="activeDataView.rows"
                          [hiddenRowIndices]="hiddenRows"
                          [columns]="activeDataView.columns"
                          [hiddenColumnIndices]="hiddenColumns"
                          [includedFrames]="includedFrames"
                          [includedColumns]="includedColumns"
                          [statementType]="formatDataViewName(activeDataView.title)"
                          [projectionScenarioTypeLabelOverride]="projectionScenarioTypeLabelOverride"
                          (includeColumn)="includeColumn($event)"
                      >
                      </app-builder-financials-table>
                  </div>
              </div>
          </div>
          <div class="col-4 working_container">
              <div class="row">
                  <div class="col-10"><h4 class="my-2">Working Statement</h4></div>
                  <div class="col-1 close-button-col clickable"><i class="fas fa-times" (click)="closeBuilder()" title="Close Statement Builder"></i></div>
              </div>
              <div class="row">
                  <div class="col-11">
                      <p>
                      <select class="inline-edit right form-select" (change)="dataChangeOccurred()" [(ngModel)]="reportingInterval">
                          <option selected disabled value="">Choose Interval</option>
                          <option value="MONTHLY">Monthly</option>
                          <option value="QUARTERLY">Quarterly</option>
                          <option value="SEMI_ANNUALLY">Semi-annual</option>
                          <option value="ANNUALLY">Annual</option>
                          <option value="FISCAL_YTD">Fiscal YTD</option>
                          <option value="TTM">TTM</option>
                        </select>
                      </p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-11">
                      <p>
                      <app-input-calendar-component [initialDate]="statementDate"
                          (dateEmitter)="setStatementDate($event)">
                      </app-input-calendar-component>
                      </p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-11 working-note"><p>1. Use the buttons at the top of each column to to select which periods to use to create your new statement</p></div>
              </div>
              <div class="row">
                  <div class="col-11 working-note"><p>2. Click the (+) button for any periods you want to subtract out of the new statement</p></div>
              </div>
              <div class="row">
                  <div class="col-11 working-note"><p>3. Select period to be used for Balance Sheet in the new statement</p></div>
              </div>
              <ng-container *ngFor="let builderFrame of includedFrames; let idx=index">
                  <div *ngIf="!!includedColumns[builderFrame.column.toKeyString()]" class="row included_col_row">
                      <div class="col-1 center-text">{{getSelectLabel(builderFrame.column)}}</div>
                      <div class="col-1 center-text"><input title="Use for Balance Sheet" type="radio" name="bsFrame" [value]="builderFrame.column.toKeyString()" [checked]="isBalanceSheerColumn(builderFrame.column)" (change)="setBalanceSheetColumn(builderFrame.column)" /></div>
                      <div class="col-8 included-col-label">{{ getIncludedLabel(builderFrame.column)}}</div>
                      <div class="col-1 exclude-column-btn" (click)="excludeColumn(builderFrame.column)">X</div>
                  </div>
              </ng-container>
              <ng-container *ngIf="isBeginningCashInTable">
                  <div class="row">
                      <div class="col-11 working-note"><p>4. Select period to be used for beginning cash on the statement of cash flows in the new statement</p></div>
                  </div>
                  <ng-container *ngFor="let builderFrame of includedFrames; let idx=index">
                      <div *ngIf="!!includedColumns[builderFrame.column.toKeyString()]" class="row included_col_row">
                          <div class="col-1 center-text">{{getSelectLabel(builderFrame.column)}}</div>
                          <div class="col-1 center-text"><input title="Use for Beginning Cash" type="radio" name="beginningCashFrame" [value]="builderFrame.column.toKeyString()" [checked]="isBeginningCashColumn(builderFrame.column)" (change)="setBeginningCashColumn(builderFrame.column)" /></div>
                          <div class="col-8 included-col-label">{{ getIncludedLabel(builderFrame.column)}}</div>
                          <div class="col-1 exclude-column-btn" (click)="excludeColumn(builderFrame.column)">X</div>
                      </div>
                  </ng-container>
              </ng-container>
              <ng-container *ngIf="isEndingCashInTable">
                  <div class="row">
                      <div class="col-11 working-note"><p>{{!isBeginningCashInTable ? '4' : '5'}}. Select period to be used for end cash on the statement of cash flows in the new statement</p></div>
                  </div>
                  <ng-container *ngFor="let builderFrame of includedFrames; let idx=index">
                      <div *ngIf="!!includedColumns[builderFrame.column.toKeyString()]" class="row included_col_row">
                          <div class="col-1 center-text">{{getSelectLabel(builderFrame.column)}}</div>
                          <div class="col-1 center-text"><input title="Use for Ending Cash" type="radio" name="endingCashFrame" [value]="builderFrame.column.toKeyString()" [checked]="isEndingCashColumn(builderFrame.column)" (change)="setEndingCashColumn(builderFrame.column)" /></div>
                          <div class="col-8 included-col-label">{{ getIncludedLabel(builderFrame.column)}}</div>
                          <div class="col-1 exclude-column-btn" (click)="excludeColumn(builderFrame.column)">X</div>
                      </div>
                  </ng-container>
              </ng-container>
              <div class="row">
                  <div class="col-11 py-2"><button class="btn btn-primary btn-rounded"
                      [ngClass]="{
                          'disabled': !isFormComplete
                      }"
                      style="float:right;"
                      [disabled]="!isFormComplete"
                      (click)="createStatement()">Create</button></div>
              </div>
          </div>
      </div>
  </ng-container>
</ng-container>
<ng-template #NoAccessToStatementBuilderTool>
  <borrower-alert [alertText]="noAccessErrorText"></borrower-alert>
</ng-template>
