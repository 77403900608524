import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {Subscription} from "rxjs";
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {PortfoliosService} from "@services/fincura-ng-client/api/portfolios.service";
import {AutoUnsubscribe} from "app/decorators/auto-unsubscribe";
import {finalize} from "rxjs/operators";
import {AlertService} from "@services/alert.service";
import {TEXT_PATTERN} from "@utils/constants";

@AutoUnsubscribe('subsArr$')
@Component({
  selector: 'create-or-edit-portfolio-modal',
  templateUrl: './create-or-edit-portfolio-modal.component.html',
  styleUrls: ['./create-or-edit-portfolio-modal.component.scss']
})
export class CreateOrEditPortfolioModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() portfolio?: any;
  @Output() callback = new EventEmitter<boolean>();

  mode: 'edit' | 'create';
  portfolioForm: FormGroup;
  subsArr$: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private portfoliosApiService: PortfoliosService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    this.mode = this.portfolio ? 'edit' : 'create';
    this.portfolioForm = this.formBuilder.group({
      name: [this.portfolio?.name, [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(TEXT_PATTERN),
      ]]
    })
  }

  closeModal() {
    this.callback.emit(false);
    this.popup.close();
  }

  submit() {
    if (!this.portfolioForm.valid) {
      this.portfolioForm.markAsTouched();
      return
    }
    if (this.mode === 'edit') {
      this.submitEditForm();
    } else {
      this.submitCreateForm();
    }
  }

  submitEditForm() {
    if (!this._formValuesHaveChanged()) {
      this.portfolioForm.markAsTouched();
      this.portfolioForm.setErrors({formIsUnchanged: "No changes have been made"})
      return
    }

    this.subsArr$.push(this.portfoliosApiService.partialUpdatePortfolio(this.portfolio.uuid, null, this.portfolioForm.value)
      .pipe(
        finalize(() => {
          this.callback.emit(true);
          this.popup.close();
        })).subscribe(res => {
        this.alertService.success("Portfolio updated")
      }, error => {
        this._handleApiError(error);
      }));
  }

  submitCreateForm() {
    this.subsArr$.push(this.portfoliosApiService.createPortfolio(this.portfolioForm.value).pipe(
      finalize(() => {
        this.callback.emit(true);
        this.popup.close();
      })).subscribe(res => {
      this.alertService.success("Portfolio created")
    }, error => {
      this._handleApiError(error);
    }));
  }

  _handleApiError(error) {
    console.error(error)
    let alertErrorMsg = `Failed to ${this.mode} portfolio.`;
    if (['duplicate key value violates unique constraint', 'portfolio_name_key', 'already exists'].every(substr => error?.error?.message?.includes(substr))) {
      alertErrorMsg += " You already have a portfolio with this name."
    } else {
      alertErrorMsg += " Please try again or contact administrator."
    }
    this.alertService.error(alertErrorMsg);
  }


  _formValuesHaveChanged() {
    for (let key of Object.keys(this.portfolioForm.value)) {
      if (this.portfolioForm.get(key)?.value !== this.portfolio[key]) {
        return true
      }
    }
    return false
  }
}
