<div class="stepper-container">
    <ng-container *ngFor="let step of steps; let i = index; last as isLast">
        <app-navigation-step
            [displayText]="step.displayText"
            [action]="(!enforceLinearProgression || i < currentStepIndex) ? step.action : null"
            [isCurrentStep]="i == currentStepIndex"
            [isCompleted]="i<currentStepIndex"
            [stepNumber]="i+1"
        ></app-navigation-step>
        <ng-container *ngIf="!isLast">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="2" viewBox="0 0 40 2" fill="none">
                <path d="M0 1H40" stroke="#CCCCCC" stroke-width="2"/>
            </svg>
        </ng-container>
    </ng-container>

</div>
