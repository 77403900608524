import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../../../services/shared-data.service';
import { NgxPopupService } from '../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { ApiKeysAddComponent } from '../api-keys-add/api-keys-add.component';
import { ApiKeyRevokeComponent } from '../api-key-revoke/api-key-revoke.component';
import { SignedUrlService } from '../../../../services/signed-url.service';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { SignedUrl } from '../../../../models/signed-url';
import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { isBoolean } from 'util';

@Component({
  selector: 'app-api-keys-list',
  templateUrl: './api-keys-list.component.html',
  styleUrls: ['./api-keys-list.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ApiKeysListComponent implements OnInit {
  isLoading = false;
  subsArr$: Subscription[] = [];
  apiKeys: SignedUrl[] = [];
  redocUrl: string;
  swaggerUrl: string;
  constructor(private _sharedDataService: SharedDataService,
              private signedUrlService: SignedUrlService,
              private popupService: NgxPopupService) { }

  ngOnInit() {
    this._getApiKeys();
    this._sharedDataService.pageHeaderTitle$.next('API Keys');
  }

  addApiKey() {
    this.popupService.open({
      componentType: ApiKeysAddComponent,
      cssClass: 'modal-add-api-key',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (typeof(data.detail) === 'boolean' && data.detail) {
          this._getApiKeys();
        }
      }, {once: true});
    });
  }

  revokeApiKey(apiKey: SignedUrl) {
    this.popupService.open({
      componentType: ApiKeyRevokeComponent,
      cssClass: 'modal-revoke-api-key',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (typeof(data.detail) === 'boolean' && data.detail) {
          this._getApiKeys();
        }
      }, {once: true});
    });
  }

  private _getApiKeys() {
    this.isLoading = true;
    this.subsArr$.push(
      this.signedUrlService.getAll(1, 50, {'scope_eq': 'api-key-v1'}, 'asc')
        .subscribe(
          signedUrls => {
            this.apiKeys = signedUrls;
            // this.total = data.meta.total;
            this.isLoading = false;
          },
        ),
    );
  }

}
