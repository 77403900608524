import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loan-commentary-tile',
  templateUrl: './loan-commentary-tile.component.html',
  styleUrls: ['./loan-commentary-tile.component.css']
})
export class LoanCommentaryTileComponent implements OnInit {
  @Input() commentary;
  @Output() commentaryChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  updateCommentary(value) {
    this.commentary = value;
    this.commentaryChange.emit(this.commentary);
  }
}
