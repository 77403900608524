import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import { NgxPopupService } from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { UcaService } from '@services/uca.service';
import { AlertService } from '@services/alert.service';
import { TAXONOMY_ITEM_IDS } from '@utils/constants';
import { NormalBalance } from '@utils/enums';
import { NormalBalanceSelectorComponent } from './normal-balance-selector/normal-balance-selector.component';

@Component({
  selector: 'app-build-uca-equation',
  templateUrl: './build-uca-equation.component.html',
  styleUrls: ['./build-uca-equation.component.scss']
})
export class BuildUcaEquationComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() node;
  @Input() workingNodes = [];
  @Input() directMethod = false;

  @Output() generatedUcaEquation = new EventEmitter<string>();

  ucaEquationPreview = '';
  flattenedTemplateItems = [];
  incomeStatementAndBalanceSheetNodes = [];
  highLevelFinancialStatementElements = [];
  ucaItems = [];
  equationIsValid = false;

  BUILD_UCA_EQUATION_MODAL_HEADER = 'BUILD UCA FORMULA';
  BUILD_UCA_EQUATION_MODAL_HELPER_TEXT = 'Select template items to use in the formula for ';
  AVAILABLE_TEMPLATE_ITEMS_HEADER = 'Available Template Items';
  EQUATION_PREVIEW_BLANK_TEXT = 'As you select line items below the formula will be built and shown here.';
  FORMULA_PREVIEW_HEADER_TEXT = 'Formula Preview';
  SELECT_TEXT = 'Generate Formula';
  CANCEL_TEXT = 'Cancel';

  constructor(
    public ucaService: UcaService,
    public alertService: AlertService,
    public popupService: NgxPopupService,
  ) { }

  ngOnInit(): void {
    this.incomeStatementAndBalanceSheetNodes = this.filterOutNonIncomeStatementAndBalanceSheetNodes();
    this.highLevelFinancialStatementElements = this.ucaService.retrieveHighestLevelFinancialStatementElementsFromWorkingNodes(this.incomeStatementAndBalanceSheetNodes);
    this.ucaItems = this.getUcaItems();
    if (this.node.data.hasOwnProperty('equationOverride') && this.node.data['equationOverride'] !== '') {
      if (this.ucaService.retrieveUcaEquationWarningIfAnyExist(this.node.data['equationOverride']) === '') {
        this.ucaEquationPreview = this.node.data['equationOverride'];
        this.equationIsValid = true;
      }
    } else if (this.node.data.hasOwnProperty('originalEquation') && this.node.data['originalEquation'] !== '' && this.ucaService.retrieveUcaEquationWarningIfAnyExist(this.node.data['originalEquation']) === '') {
      this.ucaEquationPreview = this.node.data['originalEquation'];
      this.equationIsValid = true;
    } else {
      this.ucaEquationPreview = '';
    }
    if (this.ucaEquationPreview === '=') {
      this.ucaEquationPreview = '';
    }

    this.flattenedTemplateItems = this.flattenTemplateItems(this.incomeStatementAndBalanceSheetNodes, 0);
  }

  saveGeneratedUcaEquation() {
    this.generatedUcaEquation.emit(this.ucaEquationPreview);
    this.popup.close();
  }

  filterOutNonIncomeStatementAndBalanceSheetNodes() {
    const incomeStatementAndBalanceSheetNodes = [];
    for (const node of Object.values(this.workingNodes)) {
      if ([TAXONOMY_ITEM_IDS.INCOME_STATEMENT, TAXONOMY_ITEM_IDS.BALANCE_SHEET].includes(node['standardLineItemId'])) {
        incomeStatementAndBalanceSheetNodes.push(node);
      }
    }
    return incomeStatementAndBalanceSheetNodes;
  }

  flattenTemplateItems(templateItems, indentLevel: number, parentStandardLineItemId = -1) {
    let flattenedTemplateItems = [];
    for (const item of templateItems) {
      const tempItem = structuredClone(item);
      const regEx = new RegExp(tempItem['ref'] + '[^A-Z_]?');
      tempItem.indentLevel = indentLevel;
      tempItem.isIncludedInUcaEquation = regEx.test(this.ucaEquationPreview);
      tempItem.parentStandardLineItemId = parentStandardLineItemId;
      tempItem.closedDrawerState = false;
      tempItem.matchingUcaItemLabel = this.ucaService.attemptToFindTaxonomyItemRefAmongstUcaItemEquations(tempItem['ref'], this.ucaItems, this.node.data);
      flattenedTemplateItems.push(tempItem);
      if (item.children && item.children.length > 0) {
        flattenedTemplateItems = flattenedTemplateItems.concat(this.flattenTemplateItems(item.children, indentLevel + 1, item.standardLineItemId));
      }
    }
    return flattenedTemplateItems;
  }


  // if directMethod is true, get Uca Cashflow - Direct section , otherwise get the Uca Cashflow - Indirect section
  getUcaItems() {
    for (const node of Object.values(this.workingNodes)) {
      if ((this.directMethod && node['standardLineItemId'] === TAXONOMY_ITEM_IDS.UCA_CASHFLOW_DIRECT) || (!this.directMethod && node['standardLineItemId'] === TAXONOMY_ITEM_IDS.UCA_CASHFLOW_INDIRECT)) {
        return node['children'];
      }
    }
    return [];
  }

  toggleIsItemInUcaEquation(templateItem) {
    let updatedUcaEquation = templateItem.isIncludedInUcaEquation ?
      this.ucaService.removeTaxonomyItemFromUcaEquationString(templateItem.ref, this.ucaEquationPreview) :
      this.ucaService.appendTaxonomyItemToUcaEquationString(templateItem, this.ucaEquationPreview, this.highLevelFinancialStatementElements, null, this.directMethod);

    if (updatedUcaEquation.warningMessage === '') {
      templateItem.isIncludedInUcaEquation = !templateItem.isIncludedInUcaEquation;
      this.ucaEquationPreview = updatedUcaEquation.equation;
    } else if (updatedUcaEquation.warningMessage === this.ucaService.TAXONOMY_ITEM_NOT_FOUND) {
      templateItem.isIncludedInUcaEquation = !templateItem.isIncludedInUcaEquation;
      this.popupService.open({
        componentType: NormalBalanceSelectorComponent,
        cssClass: 'modal-confirmation',
        outputs: {
          normalBalance: (normalBalance: NormalBalance) => {
            if (normalBalance) {
              updatedUcaEquation = this.ucaService.appendTaxonomyItemToUcaEquationString(templateItem, this.ucaEquationPreview, this.highLevelFinancialStatementElements, normalBalance, this.directMethod);
              if (updatedUcaEquation.warningMessage === '') {
                this.ucaEquationPreview = updatedUcaEquation.equation;
              } else {
                templateItem.isIncludedInUcaEquation = !templateItem.isIncludedInUcaEquation;
                this.alertService.warning(updatedUcaEquation.warningMessage);
              }
            } else {
              templateItem.isIncludedInUcaEquation = !templateItem.isIncludedInUcaEquation;
            }
          }
        },
      });
    } else {
      templateItem.isIncludedInUcaEquation = !templateItem.isIncludedInUcaEquation;
      this.alertService.warning(updatedUcaEquation.warningMessage);
    }

    this.setEquationIsValid();
  }

  setEquationIsValid() {
    this.equationIsValid = this.ucaService.validateUcaEquationStringFormat(this.ucaEquationPreview);
  }

  shouldDisplayItem(templateItem) {
    if ([TAXONOMY_ITEM_IDS.INCOME_STATEMENT, TAXONOMY_ITEM_IDS.BALANCE_SHEET].includes(templateItem.standardLineItemId)) {
      return true;
    }
    return !this.parentItemIsClosed(templateItem.parentStandardLineItemId) && ['StandardItem', 'SubItem', 'GenericStandardItem'].includes(templateItem.className);
  }

  parentItemIsClosed(parentStandardLineItemId) {
    for (const templateItem of Object.values(this.flattenedTemplateItems)) {
      if (templateItem.standardLineItemId === parentStandardLineItemId) {
        if (templateItem.closedDrawerState) {
          return true;
        } else if (templateItem.parentStandardLineItemId === -1) {
          return false;
        } else {
          return this.parentItemIsClosed(templateItem.parentStandardLineItemId);
        }
      }
    }
  }

  toggleDrawerState(item) {
    item.closedDrawerState = !item.closedDrawerState;
  }
}
