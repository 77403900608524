<div class="modal__header">
    <i class="fa fa-building-user header-icon"></i>
    <span>ADD NEW BORROWER</span>
</div>

<div class="modal__content">
    <form [formGroup]="companyForm" (submit)="save(companyForm.value, companyForm.valid)">
        <div class="mb-3">
            <label class="control-label" for="name">Borrower Name</label>
            <input [ngClass]="{'is-invalid': isFieldInvalid('name')}" type="text" class="form-control"
                   formControlName="name" id="name" placeholder="Borrower Name">
            <span
                class="invalid-feedback"
                *ngIf="(companyForm.get('name').hasError('required') || companyForm.get('name').hasError('nospace'))
            && isFieldInvalid('name')">
            Name cannot be empty
        </span>
            <span
                class="invalid-feedback"
                *ngIf="companyForm.get('name').hasError('extraspace') && isFieldInvalid('name')">
            No extra whitespaces are allowed
        </span>
            <span class="invalid-feedback"
                  *ngIf="isFieldInvalid('name') && companyForm.get('name').hasError('maxlength')">
            Maximum of 150 characters
        </span>
        </div>
        <div class="row" *ngIf="requireIndustryCodes">
            <div class="col-6 mb-3">
                <label class="control-label" for="sicCodeSelect">SIC Code</label>
                <input [ngClass]="{'is-invalid': isFieldInvalid('sicCode')}" list="id-sic-code" id="sicCodeSelect"
                       class="form-control" formControlName="sicCode" placeholder="SIC">
                <datalist id="id-sic-code">
                    <option *ngFor="let eachSic of sicArr" [value]="eachSic.code">{{eachSic.title}}
                        - {{eachSic.code}}</option>
                </datalist>
                <span class="invalid-feedback"
                      *ngIf="(companyForm.get('sicCode').hasError('required') && isFieldInvalid('sicCode'))">
                SIC code required
            </span>
            </div>
            <div class="col-6 mb-3">
                <label class="control-label" for="naicsCodeSelect">NAICS Code</label>
                <input [ngClass]="{'is-invalid': isFieldInvalid('naicsCode')}" list="id-naics-code" id="naicsCodeSelect"
                       class="form-control" formControlName="naicsCode" placeholder="NAICS Code">
                <datalist id="id-naics-code">
                    <option *ngFor="let eachNaics of naicsArr" [value]="eachNaics['NAICS']">{{eachNaics['NAICS Title']}}
                        - {{eachNaics['NAICS']}}</option>
                </datalist>
                <span class="invalid-feedback"
                      *ngIf="(companyForm.get('naicsCode').hasError('required') && isFieldInvalid('naicsCode'))">
                NAICS Code required
            </span>
            </div>
        </div>

        <div *ngIf="showSicIndustry" class="mb-3">
            <label class="control-label" for="sicIndustrySelect">Industry Code</label>
            <select class="form-select" id="sicIndustrySelect" formControlName="sicIndustry">
                <option
                    *ngFor="let sicIndustry of sicIndustries | enumToArray; let i = index"
                    [ngValue]="sicIndustry"
                >{{borrowerService.displayFriendlyName(sicIndustry)}}</option>
            </select>
        </div>

        <div *ngIf="portfolios && portfolios.length" class="mb-3">
            <label class="control-label" for="portfolio">Select Portfolio</label>
            <app-select2
                id="portfolio"
                placeholder="Select"
                [data]="portfolioOptions"
                [options]="{minimumResultsForSearch: -1}"
                [value]="companyForm.get('portfolio').value"
                (valueChanged)="updatePortfolio($event.value)"
                [width]="'100%'"
            >
            </app-select2>
        </div>

        <div *ngIf="canEditCustomerId" class="mb-3">
            <label class="control-label" for="name">Customer ID</label>
            <input [ngClass]="{'is-invalid': isFieldInvalid('externalId')}" type="text" class="form-control"
                   formControlName="externalId" id="externalId">
            <span
                class="invalid-feedback"
                *ngIf="companyForm.get('externalId').hasError('extraspace') && isFieldInvalid('externalId')">
            No extra whitespaces are allowed
        </span>
            <span class="invalid-feedback"
                  *ngIf="isFieldInvalid('externalId') && companyForm.get('externalId').hasError('maxlength')">
            Maximum of 250 characters
        </span>
        </div>

        <div class="main-contact row">
            <div class="col-12 text-container">
                Main Contact
            </div>
        </div>

        <div class="row">
            <div class="mb-3 col-6">
                <label class="control-label" for="contactPerson">Name</label>
                <input type="text" class="form-control" id="contactPerson" formControlName="contactPerson"
                       placeholder="Contact Name" [ngClass]="{'is-invalid': isFieldInvalid('contactPerson')}">
                <span class="invalid-feedback" *ngIf="isFieldInvalid('contactPerson') &&
                companyForm.get('contactPerson').hasError('maxlength')">
                    Maximum of 100 characters
                </span>
                <span class="invalid-feedback"
                      *ngIf="isFieldInvalid('contactPerson') && companyForm.get('contactPerson').hasError('pattern')">
                    Name contains incorrect characters
                </span>
            </div>
            <div class="mb-3 col-6">
                <label class="control-label" for="contactPhone">Phone Number</label>
                <input [ngClass]="{'is-invalid': isFieldInvalid('contactPhone')}"
                       type="text" class="form-control" id="contactPhone" formControlName="contactPhone"
                       [textMask]="{mask: phoneMask, showMask: false}" placeholder="Phone Number">
                <span class="invalid-feedback"
                      *ngIf="isFieldInvalid('contactPhone') && companyForm.get('contactPhone').hasError('maxlength')">
                    Maximum of 100 characters
                </span>
                <span class="invalid-feedback"
                      *ngIf="isFieldInvalid('contactPhone') &&companyForm.get('contactPhone').hasError('pattern')">
                    Phone number is incorrect
                </span>
            </div>
        </div>

        <div class="mb-3">
            <label class="control-label" for="email">Email Address</label>
            <input [ngClass]="{'is-invalid': isFieldInvalid('email')}" type="text" class="form-control" id="email"
                   formControlName="email" placeholder="Email">
            <span class="invalid-feedback"
                  *ngIf="isFieldInvalid('email') && companyForm.get('email').hasError('maxlength')">
            Maximum of 100 characters
        </span>
            <span class="invalid-feedback"
                  *ngIf="isFieldInvalid('email') && companyForm.get('email').hasError('pattern')">
            Email is not valid
        </span>
        </div>

        <div *ngFor="let customAttrDefinition of customAttrDefinitions" class="mb-3">
            <div class="mb-3">
                <label class="control-label" for="{{customAttrDefinition.key}}">{{customAttrDefinition.name}}</label>
                <input type="text" name="{{customAttrDefinition.key}}" class="form-control"
                       (change)="customAttributeChange(customAttrDefinition, $event)"/>
            </div>
        </div>
        <!--
        <div class="another-borrower-checkbox">
          <input type="checkbox" [formControl]="createAnotherBorrower"/>
          <label for="createAnotherBorrower" class="create-another-borrower-label">Create another borrower?</label>
        </div>
        -->

        <div class="modal__footer">
            <div class="footer-action-buttons">
                <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]="submitted">
                    <span class="create-borrower-btn-label">Add Borrower</span>
                    <div *ngIf="loading" class="loader button-loader build-statement-btn-icon"></div>
                </button>
            </div>
        </div>
    </form>
</div>
