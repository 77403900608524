<div class="row">
	<div class="col-sm-12">
		<h2>Buttons, buttons whos got the buttons?</h2>
	</div>
	<div class="col-sm-12">
		<div class="row btn-row">
			<div class="col-sm-3">
				<button type="button" class="btn btn-primary btn-rounded"><i class="fas fa-plus"></i> Create New Loan</button>
				<p>Create Loans/Analysis/Template</p>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default form-control">Add a new borrower</button>
				<p>As seen on the companies page</p>
			</div>
			<div class="col-sm-3">
				<div id="add-statement-dropdown" class="dropdown">
				    <button class="btn btn-success btn-success-green2 btn-default dropdown-toggle btn-margin" type="button" data-bs-toggle="dropdown">
				        <span>Add New Statement </span>
				        <span class="caret"></span>
				    </button>
				    <ul class="dropdown-menu dropdown-menu-end">
				        <li><a>Select File</a></li>
				        <li><a>Request Statements</a></li>
				    </ul>
				</div>
				<p>Upload statements button.</p>
			</div>
		</div>
		<div class="row btn-row">
			<div class="col-sm-3">
				<button type="submit" class="btn btn-success">Save</button>
				<button type="submit" class="btn btn-success" disabled="true">Disabled Save</button>
				<p>Primary Modal - Boostrap "success"</p>
			</div>
			<div class="col-sm-3">
				<button type="reset" class="btn btn-link">Plain Button</button>
				<p>Non-primary button on modals. Boostrap "link"</p>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<h3>Bootstrap Buttons</h3>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default">Default Button</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-primary btn-rounded">Primary Button</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-success">Success Button</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-info">Info Button</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-warning">Warning Button</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-danger">Danger Button</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-link">Link Button</button>
			</div>
		</div>
	</div>
	<div class="col-sm-12">
		<hr />
	</div>
</div>
