import {Injectable} from "@angular/core";
import {EnvironmentService} from "@services/environment.service";
import Handsontable from "handsontable";
import {HotTableRegisterer} from "@handsontable/angular";
import {MANUAL_REVIEW_HEADERS} from "@utils/constants";
import {CommonFunctions} from "@utils/common-functions";
import {mergeSelections, selectionsOverlap} from "@components/main/review/review-editor/validation";
import {BehaviorSubject} from "rxjs";

const blankCellMetadataObject = {
  text: '',
  raw_text: '',
  translated_raw_text: '',
  sourceBox: null
};

@Injectable()
export class HandsontableDataService {
  handsOnTableLicense = '';
  handsOnTableInstance: Handsontable = null;
  private hotRegisterer = new HotTableRegisterer();
  hotId = 'tableMerging';
  metadataNumItems: number = -1;
  fullSpreadColumnsLength: number = -1;
  translationDict: any = {};
  cellMetadata: Array<any> = [];

  private pageDataSource = new BehaviorSubject<any>([]);
  public pageData$ = this.pageDataSource.asObservable();

  public set pageData(value){
    this.pageDataSource.next(value);
  }

  public get pageData(){
    return this.pageDataSource.getValue();
  }

  contextMenu = {
    items: {
      'undo': {},
      'redo': {},
      'separator1': '---------',
      'remove_rows': {
        'name': 'Remove rows',
        callback: (key, selection, clickEvent) => {
          this.removeRows(selection);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }
          const selectedRange = this.handsOnTableInstance.getSelected();
          if (selectedRange.length > 0) {
            const rowNum = selectedRange[0][0];

            if (rowNum < this.metadataNumItems) {
              return true;
            }
          }
          return false;
        }
      },
      'remove_cols': {
        'name': 'Remove columns',
        callback: (key, selection, clickEvent) => {
          this.removeColumns(selection);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }
          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const colNum = selectedRange[0][1];
            if (colNum <= this.fullSpreadColumnsLength) {
              return true;
            }
          }
          return false;
        }
      },
      'separator2': '---------',
      'insert_row_above': {
        'name': 'Insert row above',
        callback: (key, selection, clickEvent) => {
          this.insertRow(selection, true);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }

          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const startingRowIndex = selectedRange[0][0];

            if (startingRowIndex < this.metadataNumItems) {
              return true;
            }

          }
          return false;
        }
      },
      'insert_row_below': {
        'name': 'Insert row below',
        callback: (key, selection, clickEvent) => {
          this.insertRow(selection, false);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }

          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const endingRowIndex = selectedRange[0][2];

            if (endingRowIndex < this.metadataNumItems - 1) {
              return true;
            }
          }
          return false;
        }
      },
      'insert_5_rows_above': {
        'name': 'Insert 5 rows above',
        callback: (key, selection, clickEvent) => {
          for (let i = 0; i < 5; i++) {
            this.insertRow(selection, true);
          }
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }

          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const startingRowIndex = selectedRange[0][0];

            if (startingRowIndex < this.metadataNumItems) {
              return true;
            }

          }
          return false;
        }
      },
      'insert_5_rows_below': {
        'name': 'Insert 5 rows below',
        callback: (key, selection, clickEvent) => {
          for (let i = 0; i < 5; i++) {
            this.insertRow(selection, false);
          }
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }

          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const endingRowIndex = selectedRange[0][2];

            if (endingRowIndex < this.metadataNumItems - 1) {
              return true;
            }
          }
          return false;
        }
      },
      'separator3': '---------',
      'insert_col_left': {
        'name': 'Insert column left ',
        callback: (key, selection, clickEvent) => {
          this.insertColumn(selection, true);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }
          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const colNum = selectedRange[0][1];
            if (colNum <= this.fullSpreadColumnsLength) {
              return true;
            }
          }
          return false;
        }
      },
      'insert_col_right': {
        'name': 'Insert column right ',
        callback: (key, selection, clickEvent) => {
          this.insertColumn(selection, false);
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }
          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const rightMostColNum = selectedRange[0][3];
            if (rightMostColNum < this.fullSpreadColumnsLength) {
              return true;
            }
          }
          return false;
        }
      },
      'insert_5_col_left': {
        'name': 'Insert 5 columns left ',
        callback: (key, selection, clickEvent) => {
          for (let i = 0; i < 5; i++) {
            this.insertColumn(selection, true);
          }
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }
          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const colNum = selectedRange[0][1];
            if (colNum <= this.fullSpreadColumnsLength) {
              return true;
            }
          }
          return false;
        }
      },
      'insert_5_col_right': {
        'name': 'Insert 5 columns right ',
        callback: (key, selection, clickEvent) => {
          for (let i = 0; i < 5; i++) {
            this.insertColumn(selection, false);
          }
        },
        disabled: () => {
          if (!this.handsOnTableInstance) {
            return false; // extra safe precaution
          }
          const selectedRange = this.handsOnTableInstance.getSelected();

          if (selectedRange.length > 0) {
            const rightMostColNum = selectedRange[0][3];
            if (rightMostColNum < this.fullSpreadColumnsLength) {
              return true;
            }
          }
          return false;
        }
      },
      'separator4': '---------',
    }
  };


  constructor(
    private _environmentService: EnvironmentService,
  ) {
    this.handsOnTableLicense = this._environmentService.getHandsOnTableLicenseKey();
  }

  afterTableInit = () => {
    const instance = this.hotRegisterer.getInstance(this.hotId);
    if (instance) {
      this.handsOnTableInstance = instance;
    }
  }

  isCorrectNumberOfColumns(expectedNumColumns): boolean {
    if (!this.handsOnTableInstance) {
      return false; // extra safe precaution
    }
    const currentNumColumns = this.handsOnTableInstance.getData()[0].length;

    return currentNumColumns === expectedNumColumns;
  }

  allRowsHaveLineItemLabels(): boolean {
    try {
      for (let row of this.pageData) {
        if (!row[0]) {
          return false
        }
      }
      return true
    } catch (e) {
      return false
    }
  }

  getOldPageFormat(): { cells: any } {
    const cells = [];
    for (let rowIndex = 0; rowIndex < this.pageData.length; rowIndex++) {
      const row = this.pageData[rowIndex].map((cell, colIndex) => {
        if (cell === null) {
          cell = '';
        }
        return {
          text: cell,
          raw_text: cell,
          translated_raw_text: this.translationDict[cell] || cell,
        }
      });

      if (!MANUAL_REVIEW_HEADERS.includes(row[0].text)) { // don't push cell into main data if its a header
        cells.push(row);
      }
    }

    cells.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        cells[rowIndex][colIndex].sourceBox = this.cellMetadata[rowIndex][colIndex]?.sourceBox || null;
      });
    });

    return {cells: cells};
  }


  /**
   * Context menu utils
   */
  insertRow(selection, insertAbove: boolean) {
    let selectedRowIndex = 0;
    if (insertAbove) {
      selectedRowIndex = selection[0].start.row;
    } else {
      selectedRowIndex = selection[0].end.row + 1;
    }
    const numCol = this.pageData[0].length;

    const arrayToInsert = []
    for (let i = 1; i <= numCol; i++) {
      arrayToInsert.push('');
    }

    const metadataArrayToInsert = CommonFunctions.fillArrayXTimes(blankCellMetadataObject, numCol);
    const nextPageDataVal = this.pageData;
    nextPageDataVal.splice(selectedRowIndex, 0, arrayToInsert);
    this.pageData = nextPageDataVal.slice(0);
    this.cellMetadata.splice(selectedRowIndex - this.metadataNumItems, 0, metadataArrayToInsert)
  }

  removeRows(selection) {
    selection.sort((a, b) => (a.start.row > b.start.row) ? -1 : 1)

    const mergedSelections = [];
    selection.forEach((s, idx: number) => {
      if (idx === 0) {
        mergedSelections.push(s);
      } else if (selectionsOverlap(mergedSelections[mergedSelections.length - 1], s)) {
        mergedSelections[mergedSelections.length - 1] = mergeSelections(mergedSelections[mergedSelections.length - 1], s);
      } else {
        mergedSelections.push(s);
      }
    });
    mergedSelections.forEach(el => {
      // If it's the same, we still want to remove 1.
      const rowsToRemove = (el.end.row - el.start.row) + 1;
      const nextPageDataVal = this.pageData;
      nextPageDataVal.splice(el.start.row, rowsToRemove);
      this.pageData = nextPageDataVal.slice(0);
      this.cellMetadata.splice(el.start.row - this.metadataNumItems, rowsToRemove);
    });
  }

  insertColumn(selection, insertLeft) {
    let selectedRowIndex = 0;
    if (insertLeft) {
      selectedRowIndex = selection[0].start.col;
    } else {
      selectedRowIndex = selection[0].end.col + 1;
    }

    const grid = [];
    this.pageData.forEach(row => {
      row.splice(selectedRowIndex, 0, '')
      grid.push(row.slice(0));
    });
    this.pageData = grid;
  }

  removeColumns(selection) {
    selection.sort((a, b) => (a.start.col > b.start.col) ? -1 : 1);

    const mergedSelections = [];
    selection.forEach((s, idx: number) => {
      if (idx === 0) {
        mergedSelections.push(s);
      } else if (selectionsOverlap(mergedSelections[mergedSelections.length - 1], s)) {
        mergedSelections[mergedSelections.length - 1] = mergeSelections(mergedSelections[mergedSelections.length - 1], s);
      } else {
        mergedSelections.push(s);
      }
    });

    mergedSelections.forEach(el => {

      const grid = [];
      // If it's the same, we still want to remove 1.
      const columnsToRemove = (el.end.col - el.start.col) + 1;
      this.pageData.forEach(row => {
        row.splice(el.start.col, columnsToRemove);
        grid.push(row.slice(0));
      });
      this.pageData = grid;

      const cells = [];
      this.cellMetadata.forEach(row => {
        row.splice(el.start.col, columnsToRemove);
        cells.push(row.slice(0));
      });
      this.cellMetadata = cells;
    });
  }
}
