<div class="table-wrapper">
<div class="debt-service-coverage-table">
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Statement Date</strong></div>
        <div *ngFor="let column of columns; let idx=index" class="metadata-row-cell row-value-cell">
            <strong>{{column?.statementDate}}</strong>
        </div>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Scenario</strong></div>
        <div *ngFor="let column of columns; let idx=index" class="metadata-row-cell row-value-cell">
            <strong>{{ column?.scenario !== '' ? column.scenario : 'HISTORICAL'}}</strong>
        </div>
    </div>
    <ng-container *ngIf="_projectionHelperService.atLeastOneProjectionColumnExists(columns)">
      <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>{{ (projectionScenarioTypeLabelOverride + ' Name') }}</strong></div>
        <div *ngFor="let column of columns; let idx=index" class="metadata-row-cell row-value-cell" title="{{column?.projectionName}}">
            <strong>{{_projectionHelperService.getCondensedProjectionName(column?.projectionName)}}</strong>
        </div>
      </div>
    </ng-container>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Months Covered</strong></div>
        <div *ngFor="let column of columns; let idx=index" class="metadata-row-cell row-value-cell">
            <strong>{{column?.monthsInInterval}}</strong>
        </div>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell"><strong>Prep Type</strong></div>
        <div *ngFor="let column of columns; let idx=index" class="metadata-row-cell row-value-cell">
            <strong>{{column?.preparationType}}</strong>
        </div>
    </div>
    <div class="metadata-row">
        <div class="header-row-cell metadata-row-cell show-metadata-bottom-border"><strong>Statement Type</strong></div>
        <div *ngFor="let column of columns; let idx=index" class="metadata-row-cell row-value-cell show-metadata-bottom-border">
            <strong>{{ column.reportingInterval | reportingPeriod}}</strong>
        </div>
    </div>
    <ng-container *ngIf="templateHasSections; else templateItemsAreFlatList">
        <ng-container *ngFor="let parentTemplateItem of templateItems; let parentTemplateIdx = index">
            <div class="body-row">
                <div class="header-row-cell">
                    <strong><span>{{parentTemplateItem?.label}}</span></strong>
                </div>
            </div>
            <ng-container *ngFor="let childElement of parentTemplateItem.children; let childIdx = index">
                <ng-container *ngIf="childElement.ref === 'REF_DSCR_USER_ENTERED_PROP_DEBT'; else notUserEnteredDebt">
                    <div class="body-row" *ngFor="let proposedDebt of proposedDebts">
                        <div class="header-row-cell">{{proposedDebt.name}}</div>
                        <ng-container *ngFor="let column of columns; let colIdx = index">
                            <div class="row-value-cell" app-debt-service-coverage-table-cell
                                [hasScopes]="scopes.length > 0"
                                [rowIdx]="parentTemplateIdx"
                                [colIdx]="colIdx"
                                [overrideValue]="dataOverrides[colIdx][refToOverrideIdx[childElement.ref]]"
                                [originalValueBeforeOverrides]="getOriginalValueBeforeOverrides(childElement.ref, colIdx)"
                                [allowEdit]="proposedDebtEditable"
                                [ref]="childElement.ref"
                                [isEditing]="editingRow == parentTemplateIdx && editingColumn == colIdx && editingChildTemplateItemIdx == childIdx"
                                [equationValue]="equationValueForProposedDebt(proposedDebt, column.monthsInInterval)"
                                [isHighlighted]="selectedRow == parentTemplateIdx && selectedColumn == colIdx && selectedChildTemplateItemIdx == childIdx"
                                [textFormat]="childElement.textFormat"
                                (highlightCell)="selectedCell(parentTemplateIdx, colIdx, childIdx)"
                                (startedEditMode)="editCell(parentTemplateIdx, colIdx, childIdx)"
                                (doneEditing)="doneEditingCell($event)"
                            >
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #notUserEnteredDebt>
                    <div class="body-row">
                        <div class="header-row-cell" [ngClass]="{'bold': parentTemplateItem.ref === childElement.ref }">
                            {{childElement.label}}
                        </div>
                        <ng-container *ngFor="let column of columns; let colIdx = index">
                            <div class="row-value-cell" app-debt-service-coverage-table-cell
                                [hasScopes]="scopes.length > 0"
                                [rowIdx]="parentTemplateIdx"
                                [colIdx]="colIdx"
                                [overrideValue]="dataOverrides[colIdx][refToOverrideIdx[childElement.ref]]"
                                [originalValueBeforeOverrides]="getOriginalValueBeforeOverrides(childElement.ref, colIdx)"
                                [allowEdit]="isEditable(childElement)"
                                [ref]="childElement.ref"
                                [validator]="childElement.validator"
                                [isEditing]="editingRow == parentTemplateIdx && editingColumn == colIdx && editingChildTemplateItemIdx == childIdx"
                                [equationValue]="analysisService.evaluateFormula(!!childElement.equationOverride ? childElement.equationOverride : childElement.originalEquation, scopes[colIdx])"
                                [scope]="scopes[colIdx]"
                                [isHighlighted]="selectedRow == parentTemplateIdx && selectedColumn == colIdx && selectedChildTemplateItemIdx == childIdx"
                                [textFormat]="childElement.textFormat"
                                (highlightCell)="selectedCell(parentTemplateIdx, colIdx, childIdx)"
                                (startedEditMode)="editCell(parentTemplateIdx, colIdx, childIdx)"
                                (doneEditing)="doneEditingCell($event)"
                            >
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #templateItemsAreFlatList>
        <ng-container *ngFor="let row of templateItems; let idx = index">
            <ng-container *ngIf="row.ref === 'REF_DSCR_USER_ENTERED_PROP_DEBT'; else notUserEnteredDebt">
                <div class="body-row" *ngFor="let proposedDebt of proposedDebts">
                    <div class="header-row-cell">{{proposedDebt.name}}</div>
                    <ng-container *ngFor="let column of columns; let colIdx = index">
                        <div class="row-value-cell" app-debt-service-coverage-table-cell
                            [hasScopes]="scopes.length > 0"
                            [rowIdx]="idx"
                            [colIdx]="colIdx"
                            [overrideValue]="dataOverrides[colIdx][idx]"
                            [originalValueBeforeOverrides]="getOriginalValueBeforeOverrides(row.ref, colIdx)"
                            [allowEdit]="proposedDebtEditable"
                            [ref]="row.ref"
                            [isEditing]="editingRow == idx && editingColumn == colIdx"
                            [equationValue]="equationValueForProposedDebt(proposedDebt, column.monthsInInterval)"
                            [isHighlighted]="selectedRow == idx && selectedColumn == colIdx"
                            [textFormat]="row.textFormat"
                            (highlightCell)="selectedCell(idx, colIdx)"
                            (startedEditMode)="editCell(idx, colIdx)"
                            (doneEditing)="doneEditingCell($event)"
                        >
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #notUserEnteredDebt>
                <div class="body-row">
                    <div class="header-row-cell" [ngClass]="{'bold': row.displayBold }">
                        {{row.label}}
                    </div>
                    <ng-container *ngFor="let column of columns; let colIdx = index">
                        <div class="row-value-cell" app-debt-service-coverage-table-cell
                            [hasScopes]="scopes.length > 0"
                            [rowIdx]="idx"
                            [colIdx]="colIdx"
                            [overrideValue]="dataOverrides[colIdx][idx]"
                            [originalValueBeforeOverrides]="getOriginalValueBeforeOverrides(row.ref, colIdx)"
                            [allowEdit]="isEditable(row)"
                            [ref]="row.ref"
                            [validator]="row.validator"
                            [isEditing]="editingRow == idx && editingColumn == colIdx"
                            [equationValue]="analysisService.evaluateFormula(!!row.equationOverride ? row.equationOverride : row.originalEquation, scopes[colIdx])"
                            [scope]="scopes[colIdx]"
                            [isHighlighted]="selectedRow == idx && selectedColumn == colIdx"
                            [textFormat]="row.textFormat"
                            (highlightCell)="selectedCell(idx, colIdx)"
                            (startedEditMode)="editCell(idx, colIdx)"
                            (doneEditing)="doneEditingCell($event)"
                        >
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>
    </ng-template>
</div>
</div>