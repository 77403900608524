import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT } from '../utils/constants';

@Pipe({name: 'formatDate'})
export class FormatDatePipe implements PipeTransform {
  /**
   *
   * @param {string} value
   * @param {boolean} toLocale
   * @returns {string}
   */
  transform(value: string, toLocale = false): string {
    if (!value) {
      return '';
    }
    const targetDate = toLocale ? new Date(value) : value;

    return moment(targetDate).format(DATE_FORMAT);
  }
}
