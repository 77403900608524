<ng-container *ngIf="canViewOverview; else NoAccessOverviewPage">
  <div class="analysis-container">
      <div class="col-2 offset-1">
          <div class="row pt-4">
              <div class="input-group">
                  <select
                      (change)="updatePeriod($event.target.value)"
                      class="form-select select-analytics-interval"
                  >
                      <option disabled="true" value=""></option>
                      <option
                          *ngFor="let period of periodTypes"
                          [value]="period.key"
                          [disabled]="!isPeriodAvailable(period.key)"
                          [selected]="period.key == periodType"
                      >
                          {{period.value}}
                      </option>
                  </select>
              </div>
          </div>
      </div>

      <div
          *ngIf="isLoading"
          class="inner-content table_container financials-table empty-state-single-view"
      >
          <div class="loader__container">
              <div class="loader"></div>
          </div>
      </div>

      <div class="row no-data-message" *ngIf="!isDataAvailable() && !isLoading">
          <div class="col-6 offset-3 text-center">
              <h3>Upload financials to enable dashboards for this company.</h3>
              <h4>There are no financial statements available for this company. Upload the financial statements to view dashboards.</h4>
          </div>
      </div>


      <div class="row" *ngIf="isDataAvailable()">
          <div class="col-10 offset-1">
              <div class="row profit-loss-margin">
                  <div class="col-12">
                      <h3>Profit and Loss</h3>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3">
                      <app-line-item-tile
                          title="Revenue (net)"
                          [analyticsItemValues]="analyticsDataForNetSales"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Gross Margin"
                          [analyticsItemValues]="analyticsDataForGrossMargin"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="EBITDA Margin"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_EBITDA_MARGIN', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Net Income"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_NET_INCOME', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
              </div>
              <div class="row">
                  <div class="col-5">
                      <app-income-statement-table-tile
                          [analyticsData]="analyticsData"
                          [mostRecentStatementDate]="mostRecentStatementDate"
                          [currencySymbol]="currencySymbol"
                          [analyticsDataForNetSales]="analyticsDataForNetSales"
                          [analyticsDataForCogs]="analyticsDataForCogs"
                          [analyticsDataForGrossProfit]="analyticsDataForGrossProfit"
                      >
                      </app-income-statement-table-tile>
                  </div>
                  <div class="col-7">
                      <div class="row">
                          <div class="col-12">
                              <app-ebitda-graph-tile
                                  [mostRecentStatementDate]="mostRecentStatementDate"
                                  [analyticsData]="analyticsData"
                                  [periodType]="periodType"
                                  [currencySymbol]="currencySymbol"
                              >
                              </app-ebitda-graph-tile>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-10 offset-1">
              <div class="row">
                  <div class="col-12">
                      <h3>Working Capital Analysis</h3>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3">
                      <app-line-item-tile
                          title="Cash Balance"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_CASH', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Working Capital"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_WORKING_CAPITAL', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Quick Ratio"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_QUICK_RATIO', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Current Ratio"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_CURRENT_RATIO', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
              </div>
              <div class="row">
                  <div class="col-5">
                      <app-current-working-capital-table-tile
                          [analyticsData]="analyticsData"
                          [mostRecentStatementDate]="mostRecentStatementDate"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-current-working-capital-table-tile>
                  </div>
                  <div class="col-7">
                      <div class="row">
                          <div class="col-12">

                              <app-ccc-graph-tile
                                  [mostRecentStatementDate]="mostRecentStatementDate"
                                  [analyticsData]="analyticsData"
                                  [periodType]="periodType"
                              >
                              </app-ccc-graph-tile>

                          </div>
                      </div>
                      <div class="row">
                          <div class="col-6">
                              <app-gauge-tile
                                  title="Days Sales Outstanding"
                                  [analyticsItemValues]="analyticsService.getDataByLineItem('REF_NET_ACCOUNTS_RECEIVABLE_DAYS', analyticsData)"
                                  [statementDate]="mostRecentStatementDate"
                                  [periodType]="periodType"
                              >

                              </app-gauge-tile>
                          </div>
                          <div class="col-6">
                              <app-gauge-tile
                                  title="Days Inventory Outstanding"
                                  [analyticsItemValues]="analyticsService.getDataByLineItem('REF_INVENTORY_DAYS_ON_HAND', analyticsData)"
                                  [statementDate]="mostRecentStatementDate"
                                  [periodType]="periodType"
                              >
                              </app-gauge-tile>
                          </div>
                          <div class="col-6">
                              <app-gauge-tile
                                  title="Days Payable Outstanding"
                                  [analyticsItemValues]="analyticsService.getDataByLineItem('REF_ACCOUNTS_PAYABLE_DAYS', analyticsData)"
                                  [statementDate]="mostRecentStatementDate"
                                  [periodType]="periodType"
                              >
                              </app-gauge-tile>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-10 offset-1">
              <div class="row">
                  <div class="col-12">
                      <h3>Leverage Analysis</h3>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3">
                      <app-line-item-tile
                          title="Net Worth"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_NET_WORTH_ACTUAL', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Debt / Worth"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_DEBT_TO_WORTH', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Total Liabilities / Total Assets"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_TOTAL_LIABILITIES_TO_TOTAL_ASSETS', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
                  <div class="col-3">
                      <app-line-item-tile
                          title="Funded Debt/EBITDA"
                          [analyticsItemValues]="analyticsService.getDataByLineItem('REF_FUNDED_DEBT_TO_EBITDA', analyticsData)"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-line-item-tile>
                  </div>
              </div>
              <div class="row">
                  <div class="col-5">
                      <app-net-worth-table-tile
                          [mostRecentStatementDate]="mostRecentStatementDate"
                          [analyticsData]="analyticsData"
                          [currencySymbol]="currencySymbol"
                      >
                      </app-net-worth-table-tile>
                  </div>
                  <div class="col-7">
                      <div class="row">
                          <div class="col-12">
                              <app-net-worth-graph-tile
                                  [mostRecentStatementDate]="mostRecentStatementDate"
                                  [analyticsData]="analyticsData"
                                  [periodType]="periodType"
                                  [currencySymbol]="currencySymbol"
                              >
                              </app-net-worth-graph-tile>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  </div>
</ng-container>

<ng-template #NoAccessOverviewPage>
  <borrower-alert [alertText]="noAccessErrorText"></borrower-alert>
</ng-template>
