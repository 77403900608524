<div class="sidebar-wrapper col-xs-3 col-sm-3 col-md-3 col-lg-2 pull-left">
  <div class="borrower-upload-sidebar">
    <div id="portal-info" class="portal-info">
      <h3>Document Request</h3>
      <p>You have a new document request. Upload the requested document(s) below through our secure borrower portal.</p>

      Questions? <a id="support-link" href="mailto:{{supportEmail}}" target="_top" [style.color]="primaryColor">Contact Us</a>

      <p class="requested-documents-title">REQUESTED DOCUMENTS</p>
      <div class="requested-documents-box">
        <p class="bold">{{reportingPeriod}}</p>
        <p class="bold">Period: {{dates}}</p>
        <ul>
          <li *ngFor="let statementType of statementTypes">{{statementType}}</li>
        </ul>
      </div>

      <div class="powered-by">
        POWERED BY <img class="icon" src="favicon.ico" /> NUMERATED
      </div>
    </div>
  </div>
</div>
