import { Injectable } from '@angular/core';
import { NormalBalance } from '../utils/enums';
import { STD_ID_MAPPER, PARENT_REF_TO_NORMAL_BALANCE_MAPPINGS } from '../utils/constants';
import { UcaEquation } from '@models/uca-equation';

@Injectable({
  providedIn: 'root'
})
export class UcaService {
  INCOME_STATEMENT_TAXONOMY_PARENT_ITEM_IDS = [STD_ID_MAPPER.REF_GROSS_PROFIT, STD_ID_MAPPER.REF_TOTAL_OPERATING_EXPENSES,  STD_ID_MAPPER.REF_TOTAL_OTHER_INCOME_EXPENSE, STD_ID_MAPPER.REF_OTHER_COMPREHENSIVE_INCOME];
  BALANCE_SHEET_TAXONOMY_PARENT_ITEMS = [STD_ID_MAPPER.REF_TOTAL_ASSETS, STD_ID_MAPPER.REF_TOTAL_LIABILITIES, STD_ID_MAPPER.REF_TOTAL_EQUITY];

  // warning messages
  EXISTING_EQUATION_IS_IN_INVALID_FORMAT = 'Existing equation contains invalid formatting. Please alter equation and try again.';
  EXISTING_EQUATION_HAS_DUPLICATE_EQUATION_TERMS = 'Existing equation contains duplicate refs in different sections of the equation. This violates the UCA equation rule set.';
  TAXONOMY_ITEM_IS_NOT_STANDARD_ITEM = 'The provided taxonomy item is not a standard item, we cannot use it in the UCA equation.';
  TAXONOMY_ITEM_IS_NOT_LEAF_NODE = 'The provided taxonomy item is not a leaf taxonomy item (it has children), we cannot use it in the UCA equation.';
  TAXONOMY_ITEM_NOT_FOUND = 'The provided taxonomy item was not found in high level financial statement element';

  UCA_EQUATION_MATCHES = [
    // =(-(PRIOR(<REF>) - <REF>))
    /=\(-\(PRIOR\(REF_[A-Z_]+\) - REF_[A-Z_]+\)\)(?![A-Z_])/g,
    // - (PRIOR(<REF>) - <REF>)
    /(?<!=) - \(PRIOR\(REF_[A-Z_]+\) - REF_[A-Z_]+\)(?![A-Z_])/g,
    // + (PRIOR(<REF>) - <REF>)
    /(?<!=) \+ \(PRIOR\(REF_[A-Z_]+\) - REF_[A-Z_]+\)(?![A-Z_])/g,
    // =(PRIOR(<REF>) - <REF>)
    /=\(PRIOR\(REF_[A-Z_]+\) - REF_[A-Z_]+\)(?![A-Z_])/g,
    // - <REF>
    /(?<!=) - REF_[A-Z_]+(?![A-Z_])/g,
    // + <REF>
    /(?<!=) \+ REF_[A-Z_]+(?![A-Z_])/g,
    // =(-<REF>)
    /=\(-REF_[A-Z_]+\)(?![A-Z_])/g,
    // =<REF>
    /=REF_[A-Z_]+(?![A-Z_])/g,
    /=/,
  ];

  /*
    this method takes in a taxonomy item, an equation string and a list of working template nodes.
    if will first validate that the existing equation is in the expected format for UCA equations and that duplicate REFs are not found.
    it will also validate that the provided taxonomy item is a StandardItem with no children.
    if any of the validations fail, it will return the equation back unchanged, and provide a warning message to the user (a potential enhancement would be to provide more details about what may be wrong with the equation exactly)

    if validation passes, it will do the following:
    figure out what the "highest level financial statement element is" for the taxonomy item. This will be one of the following: asset, liabilility, equity, revenue, operating expense, other income, OCI
    if wecannot find the taxonomy item amongst any of these accounting parent elements, we will return back the equation unchanged and tell the user we couldnt find the taxonomy item.

    once we know what the parent element is we can figure out what the "normal balance" of the taxonomy item is based off the normal balance mappings above (or if the selectedNormalBalance contains a non null value, we will simply use that)
    if the account has negative rollup behavior this tells us that it is a "contra-account" which means it has the opposite balance of the parent element
    once we know the normal balance, and which financial statement the taxonomy item belongs to (IS or BS), we can determine the "equation snippet" for the taxonomy item using the etreiveUcaEquationSnippetToAddForTaxonomyItem method.
    finally we can return the original equation with the new equation snippet appended to the end.
  */
  appendTaxonomyItemToUcaEquationString(taxonomyItem, equation: string, highLevelFinanialStatementElements, selectedNormalBalance: NormalBalance = null, directMethod = true) {
    const warningMessage = this.retrieveUcaEquationWarningIfAnyExist(equation, taxonomyItem);
    if (warningMessage !== '') {
      return {
        equation: equation,
        warningMessage: warningMessage,
      }
    }

    const isFirstRefInEquation = equation === '';

    let hasNormalDebitBalance: boolean;
    if (selectedNormalBalance === null) {
      const highLevelFinancialStatementElement = this.getParentTaxonomyItem(taxonomyItem['standardLineItemId'], highLevelFinanialStatementElements);
      if (!highLevelFinancialStatementElement) {
        return {
          equation: equation,
          warningMessage: this.TAXONOMY_ITEM_NOT_FOUND,
        };
      }
      hasNormalDebitBalance = PARENT_REF_TO_NORMAL_BALANCE_MAPPINGS[highLevelFinancialStatementElement['ref']] === NormalBalance.Debit;
    } else {
      hasNormalDebitBalance = selectedNormalBalance === NormalBalance.Debit;
    }

    const isBalanceSheetItem = taxonomyItem['documentType'] === 'BALANCE_SHEET';
    if (taxonomyItem.hasOwnProperty('rollupBehavior') && taxonomyItem['rollupBehavior'] === '-') {
      hasNormalDebitBalance = !hasNormalDebitBalance;
    }

    const ucaEquationSnippet = this.retreiveUcaEquationSnippetToAddForTaxonomyItem(taxonomyItem['ref'], isBalanceSheetItem, hasNormalDebitBalance, isFirstRefInEquation, directMethod);
    return {
      equation: `${isFirstRefInEquation ? '=' : ''}${equation}${ucaEquationSnippet}`,
      warningMessage: '',
    };
  }

  /*
    when we are removing an item from the equation string, we dont care about what statement it belongs to, or what its normal balance is
    we know that if it exists in the uca equation string that it will be formatted in one of these 8 ways, so we can simply search for the ref this way
    notice how the longest strings are in the front of this list. This is intentional because the shorter ones can be within the longer ones, so we want to perform the
    string replacement on the longer ones first.
  */
  removeTaxonomyItemFromUcaEquationString(taxonomyItemRef: string, equation: string): UcaEquation {
    const warningMessage = this.retrieveUcaEquationWarningIfAnyExist(equation);
    if (warningMessage !== '') {
      return {
        equation: equation,
        warningMessage: warningMessage,
      }
    }

    const potentialStringMatches = [
      `(-(PRIOR(${taxonomyItemRef}) - ${taxonomyItemRef}))`,
      ` - (PRIOR(${taxonomyItemRef}) - ${taxonomyItemRef})`,
      ` + (PRIOR(${taxonomyItemRef}) - ${taxonomyItemRef})`,
      `(PRIOR(${taxonomyItemRef}) - ${taxonomyItemRef})`,
      ` - ${taxonomyItemRef}`,
      ` + ${taxonomyItemRef}`,
      `(-${taxonomyItemRef})`,
      `${taxonomyItemRef}`,
    ];

    for (const potentialStringMatch of potentialStringMatches) {
      const stringIndex = equation.indexOf(potentialStringMatch);
      // if the string match is found and it is not preceded by another alphabetic character or underscore, then remove it
      if (stringIndex !== -1 && (stringIndex+potentialStringMatch.length === equation.length || !/[A-Z_]/.test(equation.slice(stringIndex+potentialStringMatch.length, stringIndex+potentialStringMatch.length+1)))) {
        equation = `${equation.slice(0, stringIndex)}${equation.slice(stringIndex+potentialStringMatch.length,)}`;
        break;
      }
    }

    // this block will update the first ref in the equation to be in correct format in the case that the first ref was removed
    if (['= + ', '= - '].includes(equation.slice(0, 4))) {
      if (equation.slice(0, 4) === '= + ') {
        equation = `=${equation.slice(4,)}`;
      } else {
        let indexOfNextAddOperation = equation.indexOf(' +', 4);
        let indexOfNextSubtractOperation: number;
        if (equation.slice(0, 10) === '= - (PRIOR') {
          let firstIndexOfNextSubtractOperation = equation.indexOf(' -', 4);
          indexOfNextSubtractOperation = equation.indexOf(' -', firstIndexOfNextSubtractOperation+1);
        } else {
          indexOfNextSubtractOperation = equation.indexOf(' -', 4);
        }
        let indexOfNextOperation: number;
        if (indexOfNextAddOperation < 0 && indexOfNextSubtractOperation > 0) {
          indexOfNextOperation = indexOfNextSubtractOperation;
        } else if (indexOfNextSubtractOperation < 0 && indexOfNextAddOperation > 0) {
          indexOfNextOperation = indexOfNextAddOperation;
        } else {
          indexOfNextOperation = indexOfNextAddOperation <= indexOfNextSubtractOperation ? indexOfNextAddOperation : indexOfNextSubtractOperation;
        }
        if (indexOfNextOperation === -1) {
          indexOfNextOperation = equation.length;
        }
        equation = `=(-${equation.slice(4, indexOfNextOperation)})${equation.slice(indexOfNextOperation,)}`;
      }
    }
    return {
      equation: equation !== '=' ? equation : '',
      warningMessage: '',
    };
  }


  /*
    confirm that the equation starts with an equals sign or is empty string.
    iterate over the string and remove any instances of those expressions.
    at the end, if we are left with just an empty string that tells us the equation is valid.
  */
  validateUcaEquationStringFormat(equation: string): boolean {
    if (equation.length > 1 && equation.slice(0, 1) !== '=') {
      return false;
    }
    for (const regexString of this.UCA_EQUATION_MATCHES) {
      equation = equation.replace(regexString, '');
    }
    return equation === '';
  }

  /*
    this method will check for any duplicate refs found in different "snippets" within the equation.
    for example:
    "=REF_SALES + REF_SALES" would be invalid.
    "=(PRIOR(REF_AR) - REF_AR) would be okay because both the REF_AR's are in the same "snippet".
    "=REF_AR + (PRIOR(REF_AR) - REF_AR)" would be invalid because REF_AR is in more than one "snippet" now
  */
  validateUcaEquationDoesNotContainDuplicateRefTerms(equation: string): boolean {
    const refReg = /REF_[A-Z_]+/g;
    let refsInDifferentTerms = [];
    for (const regexString of this.UCA_EQUATION_MATCHES) {
      const matches = equation.match(regexString);
      if (matches !== null) {
        equation = equation.replace(regexString, '');
        for (const match of matches) {
          const refMatches = match.match(refReg);
          if (refMatches !== null) {
            if (refsInDifferentTerms.includes(refMatches[0])) {
              return false;
            } else {
              refsInDifferentTerms.push(refMatches[0]);
            }
          }
        }
      }
    }
    return true;
  }

  retrieveUcaEquationWarningIfAnyExist(equation: string, taxonomyItem = null): string {
    if (!this.validateUcaEquationStringFormat(equation)) {
      return this.EXISTING_EQUATION_IS_IN_INVALID_FORMAT;
    }
    if (!this.validateUcaEquationDoesNotContainDuplicateRefTerms(equation)) {
      return this.EXISTING_EQUATION_HAS_DUPLICATE_EQUATION_TERMS;
    }
    if (taxonomyItem !== null && !(taxonomyItem.hasOwnProperty('className') && ['StandardItem', 'SubItem', 'GenericStandardItem'].includes(taxonomyItem['className']))) {
      return this.TAXONOMY_ITEM_IS_NOT_STANDARD_ITEM;
    }
    if (taxonomyItem !== null && taxonomyItem.hasOwnProperty('children') && taxonomyItem['children'].length > 0) {
      return this.TAXONOMY_ITEM_IS_NOT_LEAF_NODE;
    }
    return '';
  }

  /*
    this method contains the most important business logic for UCA equation generation.
    here is how the logic works:

    if the item is a balance sheet item we should be calculated the different between the prior period and the current (the change over the period).
    whether or not we add or subtract the change over the period depends on normal balance. If it has a normal debit balance, we will add the change, if it has a normal credit balance we will subtract.

    if the item is an income item we can simply use the current periods value.
    whether or not we add or subtract the current period value depends on normal balance and whether we are using the direct or indirect method.
    if we are using the direct method, we want to add credits and subtract debits. if we are using indirect method, we want to subtract credits and add debits.

    finally, if the "snippet" is the first ref in the equation, we will want to update it to be wrapped in parantheses. We need to do this for the values to be calculated correctly on backend.
    for example we should change "= - REF_SALES" to "=(-REF_SALES)" or "= + (PRIOR(REF_AR) - REF_AR)" to just "=(PRIOR(REF_AR) - REF_AR)".
  */
  private retreiveUcaEquationSnippetToAddForTaxonomyItem(ref, isBalanceSheetItem, hasNormalDebitBalance, isFirstRefInEquation, directMethod) {
    let ucaEquationSnippet = isBalanceSheetItem ? ` ${(hasNormalDebitBalance ? '+' : '-')} (PRIOR(${ref}) - ${ref})` : ` ${(((hasNormalDebitBalance && directMethod) || (!hasNormalDebitBalance && !directMethod)) ? '-' : '+')} ${ref}`;

    if (isFirstRefInEquation) {
      if (ucaEquationSnippet.slice(0, 3) === ' - ') {
        ucaEquationSnippet = `(-${ucaEquationSnippet.slice(3,)})`;
      } else {
        ucaEquationSnippet = ucaEquationSnippet.slice(3,);
      }
    }
    return ucaEquationSnippet;
  }

  /*
    this method will identify what financial statement "element" the taxonomy item is (asset, liability, equity, revenue, operating_expense, other_income, oci)
    we iterate through the parent elements and recursively search for the provided taxonomy item.
  */
  private getParentTaxonomyItem(taxonomyItemId, highLevelFinanialStatementElements) {
    for (const element of Object.values(highLevelFinanialStatementElements)) {
      if (this.searchForTaxonomyItemIdInElement(element, taxonomyItemId) === true) {
        return element;
      }
    }
  }

  private searchForTaxonomyItemIdInElement(element, taxonomyItemId) {
    if (!element.hasOwnProperty('children') || element['children'].length === 0) {
      return null;
    }
    for (const child of Object.values(element['children'])) {
      if (child['standardLineItemId'] === taxonomyItemId) {
        return true;
      }
      if (this.searchForTaxonomyItemIdInElement(child, taxonomyItemId)) {
        return true;
      }
    }
    return false;
  }

  retrieveHighestLevelFinancialStatementElementsFromWorkingNodes(workingNodes) {
    const highestLevelFinancialStatementElements = [];
    for (const node of Object.values(workingNodes)) {
      for (const childNode of Object.values(node['children'])) {
        if (this.INCOME_STATEMENT_TAXONOMY_PARENT_ITEM_IDS.includes(childNode['standardLineItemId']) || this.BALANCE_SHEET_TAXONOMY_PARENT_ITEMS.includes(childNode['standardLineItemId'])) {
          highestLevelFinancialStatementElements.push(childNode);
        }
      }
    }
    return highestLevelFinancialStatementElements;
  }

  attemptToFindTaxonomyItemRefAmongstUcaItemEquations(taxonomyItemRef: string, ucaItems, currUcaItem) {
    const regEx = new RegExp(taxonomyItemRef + '[^A-Z_]?');
    for (const ucaItem of Object.values(ucaItems)) {
      if (ucaItem['ref'] !== currUcaItem['ref']) {
        if (ucaItem.hasOwnProperty('equationOverride') && ucaItem['equationOverride'] !== '') {
          if (regEx.test(ucaItem['equationOverride'])) {
            return ucaItem['label'];
          }
        } else if (ucaItem.hasOwnProperty('originalEquation') && regEx.test(ucaItem['originalEquation'])) {
          return ucaItem['label'];
        }
      }
    }
  }
}
