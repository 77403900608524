import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../../services/api.service';
import { AlertService } from '../../../../../services/alert.service';

import { AdjustingValue } from '../../../../../models/adjusting-value';
import { Document } from '../../../../../models/document';
import { PeriodOperation } from '../../../../../models/period-operation';
import { DocumentResolve } from '../../../../../models/document-resolve';
import { DocumentFilter } from '../../../../../models/document-filter';
import { DocumentDate } from '../../../../../models/document-date';

import CriteriaBuilder from '../../../../../utils/query/criteria';
import { DocumentType, ResponseTypes } from '../../../../../utils/enums';

@Injectable()
export class DataService {

  constructor(
    private _apiService: ApiService,
    private _alertService: AlertService,
  ) {
  }

  // TODO most of the functions in this class are unused.

  /**
   *
   * @param {number} company_id
   * @param {number} page
   * @param {number} limit
   * @param {DocumentFilter} filter
   * @returns {Observable<any>}
   */
  documentList(company_id: number, page: number, limit: number = 10, filter: DocumentFilter): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('company.id', company_id));
    if (filter.type.trim()) {
      query.add(CriteriaBuilder.equal('type', filter.type));
    }
    if (filter.startDate.trim()) {
      query.add(CriteriaBuilder.notLess('reportedDate', filter.startDate));
    }
    if (filter.endDate.trim()) {
      query.add(CriteriaBuilder.notMore('reportedDate', filter.endDate));
    }
    if (filter.search.value) {
      query.add(CriteriaBuilder.like('file.originalDocumentName', filter.search.value));
    }
    const field = Object.keys(filter.sortFields).find(item => filter.sortFields[item].dir);
    const sort = ['status'];
    if (field) {
      const dir = filter.sortFields[field].dir > 0;
      sort.push(`${filter.sortFields[field].field}.${dir ? 'asc' : 'desc'}`);
    }

    query.setSort(sort.join(','));
    query.setPage(page);
    query.setLimit(limit);
    return this._apiService.send('Post', '/api/documents/all', query.generate());
  }

  /**
   *
   * @param {number[]} ids
   * @returns {Observable<any>}
   */
  deleteDocuments(ids: number[]): Observable<any> {
    return this._apiService.send('Post', `/api/documents/remove`, {ids: ids});
  }

  /**
   *
   * @param {number[]} ids
   * @returns {Observable<any>}
   */
  filesDownload(ids: number[]) {
    return this._apiService.send('Post', `/api/document-files/download`, {fileIds: ids}, ResponseTypes.Blob);
  }

  /**
   *
   * @param data
   * @returns {Observable<any>}
   */
  documentsDataDownload(data: any) {
    return this._apiService.send('Post', `/api/documents/download`, data, ResponseTypes.Blob);
  }

  /**
   *
   * @param data
   * @returns {Observable<any>}
   */
  documentsDownload(data: any) {
    return this._apiService.send('Post', `/api/algorithms/download-period`, data, ResponseTypes.Blob);
  }

  /*dateList(companyId: number, docType: string = null) {
    const payload = {
      companyId: companyId,
      documentType: docType
    };
    return this._apiService.send('Post', `/api/documents/dates`, payload)
      .map(data => data.response.objects);
  }
*/
  /**
   *
   * @param {number} companyId
   * @param {string} docType
   * @returns {Observable<any>}
   */
  getLastDocument(companyId: number, docType: string) {
    const data = {
      companyId: companyId,
      documentType: docType,
    };
    return this._apiService.send('Post', `/api/documents/last`, data).pipe(
      map(data => data.response.objects));
  }

  /**
   *
   * @param {number} companyId
   * @param {string} type
   * @param {DocumentDate} preference
   * @param {number} limit
   * @returns {Observable<any>}
   */
  getDocument(companyId: number, type: string, preference: DocumentDate = null, limit = 1) {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('type', type));
    query.add(CriteriaBuilder.equal('company.id', companyId));

    if (preference) {
      query.add(CriteriaBuilder.equal('reportedDate', preference.reportedDate));
    }

    query.setLimit(limit);
    query.setSort('reportedDate.desc');
    return this._apiService.send('Post', `/api/documents/all`, query.generate()).pipe(
      map((data: any) => data.response.objects));
  }

  /**
   *
   * @param {number} companyId
   * @param {DocumentType} docType
   * @param {DocumentDate} preference
   * @returns {Observable<any>}
   */
  getSelectedDocument(companyId: number, docType: DocumentType, preference: DocumentDate): Observable<Document> {
    const payload = {
      documentType: docType,
      companyId: companyId,
      ...preference,
    };
    return this._apiService.send('Post', `/api/documents/selected-date`, payload).pipe(
      map((data: any) => data.response.objects.pop()));
  }

  /**
   *
   * @param {number} documentId
   * @returns {Observable<any>}
   */
  getDocumentData(documentId: number) {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('document.id', documentId));
    query.setSort('id.asc');
    return this._apiService.send('Post', `/api/document-items/all`, query.generate()).pipe(
      map(data => data.response.objects));
  }

  /**
   * handler to get Document Items difference for last document compared to document for prev. month
   * @param companyId
   * @param docId
   * @returns {Observable<any>}
   */
  getDocumentDifference(companyId: number, docId: number) {
    const data = {
      companyId: companyId,
      documentId: docId,
    };
    return this._apiService.send('Post', `/api/documents/check`, data).pipe(map(data => data.response.objects));
  }

  /**
   * handler to approve document when user wants to
   * @param document
   * @returns {Observable<any>}
   */
  approveDocumentFile(document: Document): Observable<any> {
    return this._apiService.send('Patch', '/api/document-files/' + document.file.id, {approved: true})
      .pipe(map(data => data.response.objects.pop()));
  }

  /**
   *
   * @param {number} companyId
   * @returns {Observable<any>}
   */
  getPendingDocuments(companyId: number): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('status', 'PENDING'));
    query.add(CriteriaBuilder.equal('company.id', companyId));
    return this._apiService.send('Post', `/api/documents/all`, query.generate()).pipe(map(data => data.response.objects));
  }

    /**
   *
   * @param {number} companyId
   * @returns {Observable<any>}
   */
  getPendingFiles(companyId: number): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('status', 'PENDING'));
    query.add(CriteriaBuilder.equal('company.id', companyId));
    return this._apiService.send('Post', `/api/document-files/all`, query.generate()).pipe(map(data => data.response.objects));
  }

  /**
   *
   * @param {number} fileId
   * @returns {Observable<any>}
   */
  removeFailedDocumentFile(fileId: number) {
    return this._apiService.send('Post', `/api/document-files/${fileId}/remove-failed`).pipe(map(data => data.response.objects));
  }

  /**
   *
   * @param {number} fileId
   * @returns {Observable<any>}
   */
  resetFailedDocumentFile(fileId: number) {
    return this._apiService.send('Post', `/api/document-files/${fileId}/reset-failed`).pipe(map(data => data.response.objects));
  }

  /**
   *
   * @param {number} documentId
   * @param {DocumentResolve} documentDate
   * @returns {Observable<any>}
   */
  resolveDocumentError(documentId: number, documentResolve: DocumentResolve = null) {
    return this._apiService.send('Post', `/api/documents/${documentId}/resolve`, documentResolve).pipe(
      map(data => {
          if (!documentResolve.skip) {
            this._alertService.success('Month was overridden successfully');
          } else {
            this._alertService.info('Month was skipped');
          }
          return data.response.objects;

      }));
  }

  /**
   *
   * @param {number} id
   * @param data
   * @returns {Observable<any>}
   */
  updateDocument(id: number, data: any) {
    return this._apiService.send('Patch', `/api/documents/${id}`, data);
  }

  /**
   *
   * @param {number} id
   * @param data
   * @returns {Observable<any>}
   */
  updateDocumentStatementDate(id: number, data: any) {
    return this._apiService.send('Post', `/api/documents/${id}/statement-date`, data);
  }

  /**
   *
   * @param {number} periodOperationId
   * @param {AdjustingValue} adjustingValue
   * @returns {Observable<any>}
   */
  updatePeriodOperation(periodOperationId: number, adjustingValue: AdjustingValue): Observable<any> {
    return this._apiService.send('Patch', '/api/period-operations/' + periodOperationId, adjustingValue).pipe(
      map(data => data.response.objects.pop()));
  }

  /**
   *
   * @param {PeriodOperation} periodOperation
   * @returns {Observable<any>}
   */
  createPeriodOperation(periodOperation: PeriodOperation): Observable<any> {
    return this._apiService.send('Post', '/api/period-operations', periodOperation).pipe(
      map(data => data.response.objects.pop()));
  }
}
