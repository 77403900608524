import * as math from 'mathjs'

import { Deserializable } from './deserializable';
import { LineItemObject } from './line-item';

const mathJSFormatOptions = {parenthesis: 'auto', implicit: 'show', notation: 'fixed'};

export class DataFrameFormula implements Deserializable {
  lineItem: LineItemObject;
  equation: string;
  unitType?: string;
  precision?: number;
  mismatch?: boolean;
  paired?: boolean;
  requiredValue?: number;
  visible?: boolean;
  categorized?: boolean;
  duplicated?: boolean;
  orderNumber?: number;
  matched?: boolean;
  isLocked?: boolean;

  // Added by a separate call for now
  value?: number;

  loading: boolean;
  editing: boolean;

  // @ts-ignore: not calling super() in constructor on purpose
  constructor() {}

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.lineItem) {
      this.lineItem = new LineItemObject().deserialize(this.lineItem);
    }
    return this;
  }

  displayEquation(): string {
    const noEqualSignEquation = this.equation.replace('=', '');
    const node = math.parse(noEqualSignEquation);
    const formatted = node.toString(mathJSFormatOptions);
    if (formatted === 'undefined') {
      return '=';
    }

    const fullExpression = '=' + formatted;
    const noWhiteSpaceExpression = fullExpression.replace(/ /g, '');
    return noWhiteSpaceExpression;
  }

  toString(variableMappingObject: any = {}): string {
    if (!variableMappingObject || Object.keys(variableMappingObject).length === 0) {
      return this.displayEquation();
    }
    const variableNamesToReplace = {};
    const noEqualSignEquation = this.equation.replace('=', '');
    const node = math.parse(noEqualSignEquation);
    node.traverse(function (node, path, parent) {
      if (node.type === 'SymbolNode') {
        if (variableMappingObject.hasOwnProperty(node.name)) {
          variableNamesToReplace[node.name] = variableMappingObject[node.name];
        }
      }
    });

    const fullExpression = '=' + node.toString(mathJSFormatOptions);
    let stringExpression = fullExpression.replace(/ /g, '');

    for (const machineVariableName in variableNamesToReplace) {
      if (variableNamesToReplace.hasOwnProperty(machineVariableName)) {
        stringExpression = stringExpression.split(machineVariableName).join(variableMappingObject[machineVariableName]);
      }
    }

    return stringExpression;
  }

  toHtml(variableMappingObject: any = {}): any {
    const prependHtmlItem = '<span class="human-readable-item" contenteditable=false aria-valuetext="';
    const closePrependHtmlItem = '">';
    const appendHtmlItem = '</span>';
    if (!variableMappingObject || Object.keys(variableMappingObject).length === 0) {
      // TODO: it will be better to raise error here than to display old style equation
      return this.displayEquation();
    }
    const noEqualSignEquation = this.equation.replace('=', '');
    let node: any;
    try  {
      node = math.parse(noEqualSignEquation);
    } catch (e) {
      // If this is an error from mathjs - it means the equation
      // was incorrect and rejected by the backend but is still
      // lingering in the client, so we clear the equation.
      if (e instanceof SyntaxError) {
        return '';
      } else {
        throw(e);
      }
    }
    node.traverse(function (node, path, parent) {
      if (node.type === 'SymbolNode') {
        if (variableMappingObject.hasOwnProperty(node.name)) {
          const humanReadableName = variableMappingObject[node.name];
          node.name = `${prependHtmlItem}${node.name}${closePrependHtmlItem}${humanReadableName}${appendHtmlItem}`;
        }
      }
    });

    const formatted = node.toString(mathJSFormatOptions);
    if (node.toString(mathJSFormatOptions) === 'undefined') {
      return '';
    }
    const html = '=' + node.toString(mathJSFormatOptions) + ' ';
    // const noWhiteSpaceExpression = fullExpression.replace(/ /g, '');
    return html;

  }

  variableKeys(): Array<string> {
    const variableKeys = [];
    if (!this.isEquation() || this.equation === '') {
      return variableKeys;
    }
    const noEqualSignEquation = this.equation.replace('=', '');
    const node = math.parse(noEqualSignEquation);
    node.traverse(function (node, path, parent) {
      if (node.type === 'SymbolNode') {
        variableKeys.push(node.name);
      }
    });

    return variableKeys;
  }

  allVariableIds(): Array<number> {
    const allVariableIds = [];
    if (!this.isEquation() || this.equation === '') {
      return allVariableIds;
    }
    const variableNames = this.variableKeys();
    variableNames.forEach((variableName) => {
      allVariableIds.push(parseInt(this.idFromVariableName(variableName), 10));
    });
    return allVariableIds;
  }

  lineItemIds(): Array<number> {
    const lineItemIds = [];
    if (!this.isEquation() || this.equation === '') {
      return lineItemIds;
    }
    const variableNames = this.variableKeys();
    variableNames.forEach((variableName) => {
      if (this._isLineItemFromVariableName(variableName)) {
        lineItemIds.push(parseInt(this.idFromVariableName(variableName), 10));
      }
    });
    return lineItemIds;
  }

  idFromVariableName(variableName: string): string {
    const arrayOfVariableParts = variableName.split('_');
    if (arrayOfVariableParts.length !== 4) {
      return '';
    }

    return arrayOfVariableParts[2];
  }

  isEquation(): boolean {
    return true;
  }

  // False if not a mathjs parseable equation
  isValidEquation(): boolean {
    if (!this.isEquation()) {
      return false;
    }

    try { // if math.eval throws error, it is not valid
      const parseableEquation = this._mathJsParseableEquation();
      math.evaluate(parseableEquation);
    } catch (e) {
      return false;
    }
    return true;
  }

  _isLineItemFromVariableName(variableName: string): boolean {
    const arrayOfVariableParts = variableName.split('_');
    if (arrayOfVariableParts.length !== 4) {
      return false;
    }

    return arrayOfVariableParts[0] === 'RAW';
  }

  /*
    This method makees our machine equation and returns an equation math.js can parse
    Some of our expected syntax for the backend does not play nice
    with math.js. For example, our variables aren't seen by mathjs and don't parse.
    Also - methods like SUM() are expected to be all lowercase in math.js
  */
  private _mathJsParseableEquation(): string {
    const noEqualSignEquation = this.equation.replace('=', '').toLowerCase(); // potential workaround since Sum fails and sum passes in mathjs
    const node = math.parse(noEqualSignEquation);
    node.traverse(function (node) {
      if (node.type === 'SymbolNode') {
        if (node.name.search('raw_') !== -1 || node.name.search('norm_') !== -1) { // workaround for now as our variables fail the parser - replacing as ConstantNode of  1
          node.name = 1; // workaround for now as our variables fail the parser - replacing as ConstantNode of  1
          node.type = 'ConstantNode'; // workaround for now as our variables fail the parser - replacing as ConstantNode of  1
        }
      }
    });
    const options = {parenthesis: 'auto', implicit: 'show'}
    return node.toString(options);
  }


}
