import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrepType'
})
export class FormatPrepTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }
    switch (value) {
      case 'COMBINED':
        return 'CALCULATED';
      case 'UNKNOWN':
        return ' '
      default:
        return value;
    }
  }

}
