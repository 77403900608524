<div class="modal__header mb-0">
    <div class="modal__title">
        <i class="fa-solid fa-arrow-up-from-bracket"></i>
        UPLOAD DOCUMENTS
    </div>
    <div class="modal__close" (click)="close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<div class="modal__content">
    <input #uploadInput style="display: none" type="file" ng2FileSelect multiple
           [uploader]="uploader"
           [accept]="uploadInputAccept"/>
    <div class="file-drop-area">
        <div
            ng2FileDrop
            [ngClass]="{'drop-zone-div': true, 'file-over': fileDragOver, 'disabled-drop-zone': uploadedFiles.length === FILE_UPLOAD_LIMIT}"
            (fileOver)="fileOverDropZone($event)"
            [uploader]="uploader"
        >
            <div class="file-drop-area-text-container">
                <ng-container *ngIf="uploadedFiles.length < FILE_UPLOAD_LIMIT; else disabledDropZone">
                    <i class="fa fa-file-arrow-up upload-file-icon"></i>
                    <div class="dropzone-text-line-1"><a class="text-primary" (click)="openFilePicker()">Select</a> or drag and drop up to 10 files.</div>
                    <div>Maximum file size is 5 GB.</div>
                    <div *ngIf="fileNotSelectedError" class="error-text">
                        Please select a file
                    </div>
                </ng-container>
                <ng-template #disabledDropZone>
                    <div class="disabled-drop-zone-text">
                        The maximum amount of documents that can be uploaded at once has been reached.
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="selected-doc-table">
        <table class="table">
            <thead>
                <tr>
                    <th class="col-md-5">Document Name</th>
                    <th class="col-md-6">Type of Statement</th>
                    <th class="col-md-1 right-align-label">{{fileUploadProcessBegun ? 'Status' : 'Actions'}}</th>
                </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let uploadedFile of uploadedFiles; let i=index">
                <tr #uploadedDocumentRows
                    file-item-tr
                    [uploadedFile]="uploadedFile"
                    [fileUploadProcessBegun]="fileUploadProcessBegun"
                    [taxFormOptions]="taxFormOptions"
                    [showPuertoRicoCorporate]="showPuertoRicoCorporate"
                    [showPuertoRicoIndividual]="showPuertoRicoIndividual"
                    [customAttrDefinitions]="customAttrDefinitions"
                    (removeFile)="removeFile(i, uploadedFile.fileItem)"
                ></tr>
            </ng-container>
            <tr *ngIf="!uploadedFiles?.length">
                <td class="empty-state-row" colspan="3">
                    No documents selected
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</div>
<div class="modal__footer">
    <div class="footer-action-container">
        <button type="reset" class="btn btn-empty-primary upload-footer"
                (click)="close();">{{fileUploadProcessBegun ? 'Close' : 'Cancel'}}</button>
        <button type="submit" class="btn btn-primary upload-footer" [disabled]="fileUploadProcessBegun"
                (click)="submitUploadedFiles()">
            Upload
            <div *ngIf="fileUploadProcessBegun" class="loader button-loader"></div>
        </button>
    </div>
</div>
