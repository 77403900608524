<div class="modal__header">
    <div class="modal__title">
        Import Statements
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>

<div class="modal__content">
    <div class="row">
        <div class="col-12" style="text-align: center;padding-bottom: 15px;">
            <app-accounting-integration-logo [vendor]="vendor"></app-accounting-integration-logo>
        </div>
    </div>
    <div *ngIf="!submitAttempt && !submissionComplete" class="row">
        <div class="col-12">
            <div class="mb-3">
                <label class="control-label" for="label">Include:</label>
                <div class="checkbox statement-checkbox">
                    <label>
                    <input type="checkbox" value="is_checkbox" [checked]="includeIncomeStatement == true" (change)="includeIncomeStatement = !includeIncomeStatement">
                    Income Statement
                    </label>
                </div>
                <div class="checkbox statement-checkbox">
                    <label>
                    <input type="checkbox" value="bs_checkbox" [checked]="includeBalanceSheet == true" (change)="includeBalanceSheet = !includeBalanceSheet">
                    Balance Sheet
                    </label>
                </div>
                <div class="checkbox statement-checkbox">
                    <label>
                    <input type="checkbox" value="cf_checkbox" [checked]="includeCashFlow == true" (change)="includeCashFlow = !includeCashFlow">
                    Cash Flow Statement
                    </label>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="mb-3">
                <label class="control-label" for="label">Start Date:</label>
                <app-input-calendar-component
                    [initialDate]="startDate"
                    (dateEmitter)="setStartDate($event)">
                </app-input-calendar-component>
            </div>
        </div>
        <div class="col-6">
            <div class="mb-3">
                <label class="control-label" for="label">End Date:</label>
                <app-input-calendar-component
                    [initialDate]="endDate"
                    (dateEmitter)="setEndDate($event)">
                </app-input-calendar-component>
            </div>
        </div>
        <div class="col-6" *ngIf="showAccountingMethod">
            <div class="mb-3">
                <label class="control-label" for="label">Method:</label>
                <select class="form-select" (change)="accountingMethod = $event.target.value">
                    <option value="cash" [selected]="accountingMethod == 'cash'">Cash</option>
                    <option value="accrual" [selected]="accountingMethod == 'accrual'">Accrual</option>
                </select>
            </div>
        </div>
        <div class="col-6">
            <div class="mb-3">
                <label class="control-label" for="label">Group By:</label>
                <select class="form-select" (change)="reportingInterval = $event.target.value">
                    <option value="MONTHLY" [selected]="reportingInterval == 'MONTHLY'">Month</option>
                    <option value="QUARTERLY" [selected]="reportingInterval == 'QUARTERLY'">Quarter</option>
                    <option value="ANNUALLY" [selected]="reportingInterval == 'ANNUALLY'">Year</option>
                </select>
            </div>
        </div>
    </div>
    <div *ngIf="submitAttempt && !submissionComplete" class="row status-row">
        <div class="col-12">
            <p>Importing Data</p>
        </div>
        <div class="col-12" style="padding-top: 20px;">
            <div class="loader"></div>
        </div>
    </div>
    <div *ngIf="submissionComplete" class="row status-row">
        <div class="col-12">
            <p>Data import complete</p>
        </div>
    </div>
</div>

<div class="modal__footer">
    <ng-container *ngIf="!submissionComplete">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="startImport()" [disabled]="submitted">Import Statements</button>
    </ng-container>
    <ng-container *ngIf="submissionComplete">
        <ng-container *ngIf="reviewQueueItemId !== null">
            <a class="btn btn-primary upload-footer" (click)="enterDigitization()">
                Enter Categorization
            </a>
        </ng-container>
    </ng-container>
</div>
