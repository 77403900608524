<div class="top-header">
    <app-navigation-stepper
        [steps]="navigationSteps"
        [currentStepIndex]="1"
        [enforceLinearProgression]="true"
    ></app-navigation-stepper>

    <div class="action-container">
        <button class="btn btn-outline-secondary" type="button" (click)="exitClicked.emit()">
            <span class="size">Cancel</span>
        </button>
        <div class="btn-separator">|</div>
        <button class="btn btn-light-primary" type="button" (click)="saveClicked.emit()">
            <span class="size">Save</span>
        </button>
        <button class="btn btn-primary" type="button" (click)="nextClicked.emit()">
            <span class="size">Next</span>
        </button>
    </div>
</div>
<div class="content-container row mx-0">
    <div class="col-4 scrollable-column fixed-document-width-col" id="box-drawer-container">
        <div class="section-header">
            <span class="panel-label">Select page</span>
        </div>
        <app-box-drawer
            [allowEdit]="true"
            [inputImageWidth]="600"
            [boxes]="columnStructure"
            [sourceImageUrl]="pageImage?.normalRes?.signedUrl"
            scrollingContainerElementId="box-drawer-container"
        ></app-box-drawer>
    </div>
    <div class="col scrollable-column ps-4">
        <div class="section-header">
            <span class="panel-label">Selected columns</span>
        </div>
        <table class="tabulation">
            <tbody>
            <tr *ngFor="let row of cells">
                <td class="ellipses"
                    [ngClass]="{
                      'fs-exclude': cell.columnNumber == 0,
                      'fs-block': cell.columnNumber == 0
                  }"
                    *ngFor="let cell of row"
                    title="{{row.label}}"
                >{{ cell.rawText }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
