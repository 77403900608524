import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-navigation-stepper',
  templateUrl: "./navigation-stepper.component.html",
  styleUrls: ['./navigation-stepper.component.scss']
})

export class NavigationStepperComponent {
  @Input() steps: NavigationStep[];
  @Input() currentStepIndex: number;
  @Input() enforceLinearProgression?: boolean = false;
}


export interface NavigationStep {
  stepKey: string;
  displayText: string;
  action?: () => void;
  helpDescription?: string;
}
