import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ReviewQueueItem } from '../../../../../../models/review-queue-item';
import { AutoUnsubscribe } from '../../../../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ReviewQueueService } from '../../../../../../services/review-queue.service';
import { NgxPopupComponent } from '../../../../../shared/ngx-popups/ngx-popups/ngx-popups';
import { Router } from '@angular/router';
import {
  HUMAN_INPUT_REQUIRED_STATUS,
  REVIEWED_STATUS,
  ERROR_STATUS,
  FINAL_REVIEW_STEP,
  TABLE_ID_VALIDATION_STEP,
  TABLE_TABULATION_VALIDATION_STEP,
  SPREADING_STEP,
  ADDITIONAL_DETAILS_NEEDED_STEP,
  DOCUMENT_PASSWORD_ENTRY_REQUIRED
} from '../../../../../../utils/constants';
import { DocumentFileService } from '@services/document-file.service';
import { DocumentFile } from '@models/document-file';

@Component({
  selector: 'app-review-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class StatusModalComponent implements OnInit, OnDestroy {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() reviewQueueItemId: string;
  @Input() companyUuid: string = null;
  loading = true;
  reviewQueueItem: ReviewQueueItem = null;
  error: string;
  isLongDocument = false;
  isTakingAWhile = false;
  filePasswordError = false;
  filePassword = '';
  timesChecked = 0;
  intervalCheckSeconds = 5;
  subsArr$: Subscription[] = [];

  private interval: NodeJS.Timer;
  constructor(
    private _documentFileService: DocumentFileService,
    private _reviewQueueService: ReviewQueueService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.loadStatus();
    this.setInterval(); // must only be called once.
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  goToLink(link: Array<string|number>) {
    clearInterval(this.interval);
    this.popup.closable = true;
    this.popup.close();
    this._router.navigate(link);
  }

  setInterval() {
    const numMilliSecondsInASecond = 1000;
    const intervalTime = this.intervalCheckSeconds * numMilliSecondsInASecond;
    this.interval = setInterval(() => {
      this.loadStatus();
      this.timesChecked++;
    }, intervalTime);
  }

  loadStatus() {
    this._reviewQueueService.getItemStatus(this.reviewQueueItemId).toPromise().then(rqi => {
      this.loading = true;
      this.reviewQueueItem = rqi;
      if (this.timesChecked != 0) { // don't check for redirect first time around (when key is still 0)
        this.checkForRedirect();
      }
    }).catch(error => {
      this.loading = false;
      this.error = error;
    })
  }

  isLoading(): boolean {
    const numTimesCheckedInTenMinutes = 600 / this.intervalCheckSeconds;
    const numTimesCheckedInThirtySeconds = 30 / this.intervalCheckSeconds;
    if (this.timesChecked > numTimesCheckedInThirtySeconds) {
      this.isTakingAWhile = true;
    }

    if (this.timesChecked > numTimesCheckedInTenMinutes) {
      clearInterval(this.interval);
    }

    if (!this.reviewQueueItem) {
      return true;
    }

    return ![HUMAN_INPUT_REQUIRED_STATUS, REVIEWED_STATUS, ERROR_STATUS].includes(this.reviewQueueItem.status);
  }

  checkForRedirect() {
    if (!this.isLoading()) {
      this.loading = false;

      if (this.reviewQueueItem.status === ERROR_STATUS) {
        if (this.reviewQueueItem.userError) {
          this.error = this.reviewQueueItem.exceptionMessage;
          this.filePasswordError = this.reviewQueueItem.exceptionClass === 'InvalidPdfPassword';
        } else {
          this.error = 'There was an error trying to process your document'
        }
        clearInterval(this.interval);
        return
      }

      if (this.reviewQueueItem.status === HUMAN_INPUT_REQUIRED_STATUS && [ADDITIONAL_DETAILS_NEEDED_STEP].includes(this.reviewQueueItem.step)) {
        this.goToLink(['review', this.reviewQueueItem.id, 'confirm_details']);
      } else if (this.reviewQueueItem.status === HUMAN_INPUT_REQUIRED_STATUS && [DOCUMENT_PASSWORD_ENTRY_REQUIRED].includes(this.reviewQueueItem.step)) {
        this.goToLink(['review', this.reviewQueueItem.id, 'password_entry']);
      } else {
        switch (this.reviewQueueItem.step) {
          case TABLE_ID_VALIDATION_STEP:
            if (this.timesChecked > 10) { // had at least ten secs to make sure this isn't a cold start scenario
              // this shouldn't affect initial upload because it will probably take longer than ten seconds for
              // the entire document to get into the table identification validation step anyway.
              this.goToLink(['review', this.reviewQueueItem.id, 'table_identification']);
              return;
            }
            return;
          case TABLE_TABULATION_VALIDATION_STEP:
            this.goToLink(['review', this.reviewQueueItem.id, 'table_tabulation']);
            return;
          case FINAL_REVIEW_STEP:
            this.goToLink(['review', this.reviewQueueItem.id, 'manual_review']);
            return;
          case REVIEWED_STATUS:
          case SPREADING_STEP:
            this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.reviewQueueItem.documentFileId).subscribe(files => {
              const documentFile: DocumentFile = files[0];
              this.goToLink(['spread', documentFile.uuid]);
              return;
            }));
        }

      }
    }
  }

  redirectToCompanyPage() {
    this.goToLink(['/companies', this.companyUuid]);
    return;
  }
}
