import { Deserializable } from './deserializable';

export class SpreadingMapItem implements Deserializable {
  sourceLineItemId: number;
  standardLineItemId: number;
  isValidation: boolean;
  invertValue: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class SpreadingMap implements Deserializable {
  id: number;
  documentFileId: number;
  companyId: number;
  statementType: string;
  complete: boolean;
  items: Array<SpreadingMapItem> = [];

  deserialize(input: any): this {
    Object.assign(this, input);

    if (this.items) {
      this.items = this.items.map(item => new SpreadingMapItem().deserialize(item));
    }
    return this;
  }
}
