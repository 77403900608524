import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover.component';
import { PopoverDirective } from './popover.directive';
import { PopoverService } from './popover.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PopoverComponent, PopoverDirective],
  exports: [PopoverComponent, PopoverDirective],
  providers: [PopoverService],
  entryComponents: [PopoverComponent]
})
export class PopoverModule { }
