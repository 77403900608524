import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EnvironmentService } from '@services/environment.service';
import { UserService } from '@services/user.service';
import {PLATFORM_PERMISSIONS} from "@utils/constants";

@Injectable()
export class ManageUserMiddleware implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private environmentService: EnvironmentService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      this.environmentService.loadEnvConfig().subscribe(() => {
        const hasPermission = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.MANAGE_USER);
        if (!hasPermission) {
          this.router.navigate(['/denied']);
        }
        resolve(hasPermission)
      });
    });
  }
}
