import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { isEnvironmentHost } from '../../../utils/functions';
import { CommonFunctions } from '../../../utils/common-functions';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import {PLATFORM_PERMISSIONS} from "@utils/constants";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})

@AutoUnsubscribe('_subsArr$')
export class NotFoundComponent {
  isEnviroment: Boolean = isEnvironmentHost(CommonFunctions.getEnvFromHost().serverUrl);
  private _subsArr$: Subscription[] = [];

  constructor(public userService: UserService) {
  }

  userIsAdmin(): boolean {
    return this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.MANAGE_USER);
  }

  userIsDeveloper(): boolean {
    return this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.ACCESS_DEVELOPER_TOOLS);
  }

  shouldDisplayManualReview() {
    return this.userService.isManualReviewer();
  }
}
