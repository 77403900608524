<div class="thumbnail-container">
    <ng-container *ngIf="loadedImage; else imagePlaceholder">
        <span class="footnote-count-badge" *ngIf="boxes?.length > 0">{{ boxes.length }}</span>
        <div
            #canvas
            class="canvas fs-block fs-exclude no-edit"
            [ngStyle]="imageStyles"
            (click)="onImageClick()"
        >
            <div
                class="box"
                *ngFor="let box of boxes; let idx = index"
                [ngStyle]="{
            'width': (box.x2 * (imageWidth / box.pageWidth)) - (box.x1 * (imageWidth / box.pageWidth)) + 'px',
            'height': (box.y2 * (imageHeight / box.pageHeight)) - (box.y1 * (imageHeight / box.pageHeight)) + 'px',
            'top': (box.y1 * (imageHeight / box.pageHeight)) + 'px',
            'left': (box.x1 * (imageWidth / box.pageWidth)) + 'px'
        }"
                [ngClass]="{
          'active': box.active,
          'selected': box.selected
        }"
            >
            </div>
        </div>
        <div class="page-number">Page {{ !isNaN(page?.pageIndex) ? page.pageIndex + 1 : '--' }}</div>
    </ng-container>
    <ng-template #imagePlaceholder>
        <div class="image-placeholder">
            <ng-container *ngIf="!imageFailedToLoad; else imageLoadError">
                <div class="loader"></div>
            </ng-container>
            <ng-template #imageLoadError>
                <i class="fa fa-circle-exclamation"></i>
            </ng-template>
        </div>
    </ng-template>
</div>
