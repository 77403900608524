import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AnalyticsItem } from '../../../../../../models/analytics-item';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-line-item-tile',
  templateUrl: './line-item-tile.component.html',
  styleUrls: ['./line-item-tile.component.css']
})
export class LineItemTileComponent implements OnInit {
  @Input() analyticsItemValues: Array<AnalyticsItem> = [];
  @Input() title = 'Title Not Entered';
  @Input() currencySymbol = '';
  graphData: Array<number> = [];
  isHover = false;
  isExtraLongFormattedValue = false;

  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.getGraphData();
    this.calculateIsExtraLongFormattedValue();
  }

  onHover(): void {
    this.isHover = true;
  }

  onHoverEnd(): void {
    this.isHover = false;
  }

  getGraphData(): void {
    const arrayOfCalculatedValues = this.analyticsItemValues;
    this.graphData = arrayOfCalculatedValues.map( (calculatedValue) => calculatedValue.value);
    return;
  }

  calculateIsExtraLongFormattedValue(): void {
    const formattedValue = this.analyticsService.formattedRecentValue(this.analyticsItemValues);
    const numCharsForLineBreak = 10;

    this.isExtraLongFormattedValue = (formattedValue.trim().length > numCharsForLineBreak);
    return;
  }

}
