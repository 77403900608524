import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentParsingStep } from '../../../../../models/review-queue-item';

@Component({
  selector: 'app-parsing-tracker-status',
  templateUrl: './parsing-tracker-status.component.html',
  styleUrls: ['./parsing-tracker-status.component.scss'],
})
export class ParsingTrackerStatusComponent {
  @Input() steps: Array<DocumentParsingStep> = [];
  @Input() fileType = '';
  @Input() error = false;
  @Input() errorType = '';
  @Input() errorDescription = '';
  @Input() fileName = '';
  @Input() borrowerName = '';
  @Input() userError = false;
  @Input() step = '';
  @Output('goToDocumentPasswordEntry') _goToDocumentPasswordEntry: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  goToDocumentPasswordEntry(): void {
    this._goToDocumentPasswordEntry.emit(true);
  }
}
