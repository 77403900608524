import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { ProposedDebt } from '@models/proposed-debt';
import { DataOverride } from '@models/data-override';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalCashflowService {

  constructor(
    private _apiService: ApiService,
  ) { }

  createGlobalCashflow(companyId: number, form) {
    return this._apiService.send('Post', '/api/global-cashflow-analyses', {
      companyId,
      name: form.name,
      analysisTemplateId: form.templateId,
      entities: form.entities,
      // TODO - create a method to roll up only periods from related entities
      // that have periods thaat we consider to be checked via the form where isSelected
      // is set to true
    }).pipe(map(data => data.response.objects.pop()));
  }

  getGlobalCashflowAnalyses(companyId: number): Observable<any> {
    const filter = { company_id_eq: companyId }
    return this._apiService.send('Post', '/api/global-cashflow-analyses/all', { filter: filter }).pipe(
    map(data => data.response.objects));
  }

  getGlobalCashflowAnalysis(analysisId: number): Observable<any> {
    const filter = { id_eq: analysisId }
    return this._apiService.send('Post', '/api/global-cashflow-analyses/all', { filter: filter }).pipe(
    map(data => data.response.objects));
  };

  getGlobalCashflowAnalysisByUuid(analysisUuid: string): Observable<any> {
    const filter = { uuid_eq: analysisUuid }
    return this._apiService.send('Post', '/api/global-cashflow-analyses/all', { filter: filter }).pipe(
    map(data => data.response.objects));
  };

  saveGlobalCashflowAnalysis(analysisId: number, proposedDebts: Array<ProposedDebt> = [], commentary: string, dataOverrides: any, tradelineDataOverrides: any) {
    const payload = {
      proposedDebts: proposedDebts,
      commentary: commentary,
      dataOverrides: dataOverrides,
      tradelineDataOverrides: tradelineDataOverrides,
    };
    return this._apiService.send('Patch', `/api/global-cashflow-analyses/${analysisId}`, payload).pipe(
      map(data => {
        if (data.response.objects.length > 0) {
          return  data.response.objects[0];
        }
        return null;
      })
    );
  }

  deleteGlobalCashflowAnalysis(gcfAnalysisId: number): Observable<any> {
    return this._apiService.send('Delete', `/api/global-cashflow-analyses/${gcfAnalysisId}`);
  };
}
