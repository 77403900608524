<div class="modal__header">
    <i class="fa fa-chart-line"></i>
    <span>Projection Name</span>
</div>
<div class="modal__content">
    <div class="form-group">
        <label for="prompt-for-projection-name-label">Please enter the unique Projection Name:</label>
        <div class="input-group">
            <input
                type="text"
                id="prompt-for-projection-name-label"
                class="form-control"
                placeholder="Unique Projection Name"
                [formControl]="projectionNameControl"
                [ngClass]="{'is-invalid': (hasError || (projectionNameControl.invalid && (projectionNameControl.dirty || submitAttempt)))}"
            >
            <div *ngIf="projectionNameControl.hasError('required')" class="invalid-feedback">
                Please enter a value
            </div>
            <div *ngIf="projectionNameControl.hasError('maxlength')" class="invalid-feedback">
                Maximum of 200 characters
            </div>
        </div>
    </div>
    <div *ngIf="hasError" class="prompt-for-projection-name-error-message-text">
        {{ errorMessage }}
    </div>
</div>
<div class="modal__footer d-flex justify-content-end">
    <button type="reset" class="btn btn-link" (click)="this.popup.close(true, false)">Cancel</button>
    <button
        type="submit"
        class="btn btn-primary"
        (click)="save()"
    >
        Save
    </button>
</div>
