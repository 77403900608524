import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

export interface ContextMenuItem {
  title: string;
  subtitle?: string;
  faIconClass: string;
  action: () => any;
  disabled: boolean;
  visible: boolean;
}

export interface Divider {
  divider: true;
}

export type MenuItem = Divider | ContextMenuItem

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PopoverMenuComponent {
  @Input() menuItems: MenuItem[];
  @Input() buttonText: string;
  @Input() buttonClass?: string = 'btn-primary';
  @Input() dropdownDirection?: 'right' | 'left' = 'right';

}
