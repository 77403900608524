import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {
  PdfToolbarService
} from "@components/shared/document-viewer/pdf-toolbar/pdf-toolbar.service";

@Component({
  selector: 'app-pdf-zoom',
  templateUrl: './pdf-zoom.component.html',
  styleUrls: ['./pdf-zoom.component.scss'],
})
export class PdfZoomComponent implements OnInit {
  @Input() zoomElementContainerId: string;
  protected imageScale$: Observable<number>;
  MATH = Math;

  constructor(
    public pdfToolbarService: PdfToolbarService
  ) {
    this.imageScale$ = pdfToolbarService.imageScale$;
  }

  ngOnInit() {
    this.pdfToolbarService.imageScale$.subscribe((imageScale) => {
      const zoomTargetEl = document.getElementById(this.zoomElementContainerId);
      if (isNaN(imageScale) || !zoomTargetEl) {
        return
      }
      zoomTargetEl.style.transform = 'scale(' + imageScale + ')';
    })
  }

  zoomIn() {
    this.pdfToolbarService.zoomIn();
  }

  zoomOut() {
    this.pdfToolbarService.zoomOut();
  }

}
