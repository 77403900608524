import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RestComponent } from './command/rest/rest.component';
import { CacheComponent } from './command/cache/cache.component';

const routes: Routes = [
  {
    path: 'developer/rest',
    component: RestComponent,
  },
  {
    path: 'developer/cache',
    component: CacheComponent,
  },
];

export const DEVELOPERROUTES: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
