import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiService } from './api.service';
import { SharedDataService } from './shared-data.service';
import { UserService } from './user.service';
import { AlgorithmsService } from './algorithms.service';

@NgModule({})
export class SharedDataModule {
  static forRoot(): ModuleWithProviders<SharedDataModule> {
    return {
      ngModule: SharedDataModule,
      providers: [SharedDataService, ApiService, UserService, AlgorithmsService],
    }
  }
}
