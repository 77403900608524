import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { DebtServiceNewValue } from '@models/debt-service-new-value';
import { LineItemValidator } from '@models/template-item';
import { AnalysisService } from '@services/analysis.service';
import { NumberFormattingService } from '@services/number-formatting.service';


@Component({
  selector: '[app-debt-service-coverage-table-cell]',
  templateUrl: './debt-service-coverage-table-cell.component.html',
  styleUrls: ['./debt-service-coverage-table-cell.component.scss']
})
export class DebtServiceCoverageTableCellComponent implements OnInit, OnChanges {
  @Input() rowIdx = -1;
  @Input() colIdx = -1;
  @Input() childTemplateItemIdx = -1;
  @Input() isPrimaryEntity = false;
  @Input() linkedEntityId = -1;
  @Input() overrideValue = null;
  @Input() isHighlighted = false;
  @Input() equationValue = 0;
  @Input() isEditing = false;
  @Input() textFormat = '';
  @Input() ref = null;
  @Input() hasScopes = false;
  @Input() scope: { id: number };
  // Defines whether double-clicking allows the user to edit the contents of this cell
  @Input() allowEdit = true;
  @Input() validator: LineItemValidator = null;
  @Input() displayNumForZero = false;
  @Input() tradelineIdx = -1;
  @Input() originalValueBeforeOverrides = null;

  @Output() highlightCell = new EventEmitter<any>();

  @Output() startedEditMode = new EventEmitter<boolean>();
  @Output() doneEditing = new EventEmitter<any>();

  newValue = null;
  valueOnPageLoad = null;
  isChanged = false;
  newValueMatchesOriginalValue = false;
  showFormattedValue;

  isDiv0 = false;

  @ViewChild('editCellTextbox') editCellTextbox: ElementRef;

  @HostBinding('class.insufficientValue') insufficientValue = false;
  @HostBinding('class.sufficientValue') sufficientValue = false;

  constructor(
    private decimalPipe: DecimalPipe,
    private analysisService: AnalysisService,
    private _numberFormattingService: NumberFormattingService,
  ) { }

  ngOnInit(): void {
    this.newValue = this.overrideValue;

    if (this.overrideValue !== null) {
      this.newValue = this.templateObjectToValue(this.newValue);
      if (this.overrideValue && this.overrideValue.equation && this.overrideValue.equation !== null) {
        this.newValue = (this.overrideValue.equation.startsWith('=')) ? this.overrideValue.equation.substr(1) : this.overrideValue.equation;
      } else {
        this.newValue = null;
      }
    }
    this.valueOnPageLoad = String(this.equationValue);
    this.setValueStateFlags();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('isEditing') && this.isEditing) {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.focusOnTextbox();
      }, 0);
    }

    if (changes.hasOwnProperty('value')) {
      if (this.overrideValue !== null) {
        if (typeof this.overrideValue === 'object' && this.overrideValue.equation && this.overrideValue.equation !== null && this.overrideValue.equation !== '=null') {
          this.newValue = (this.overrideValue.equation.startsWith('=')) ? this.overrideValue.equation.substr(1) : this.overrideValue.equation;
        } else {
          this.newValue = this.overrideValue;
        }
      }

    }
    this.setValueStateFlags();
  }

  stopPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  templateObjectToValue(templateObject: any): any {
    if (!templateObject) {
      return 0;
    }
    return templateObject.equation.startsWith('=') ? templateObject.equation.substr(1) : templateObject.equation;
  }

  startHighlightMode(evt = null): void {
    if (evt) {
      this.stopPropagation(evt);
    }

    this.highlightCell.emit();
    this.isEditing = false;
  }

  startEditMode(evt = null): void {
    if (evt) {
      this.stopPropagation(evt);
    }

    if (!this.allowEdit) {
      return;
    }
    // this being set here makes it so the cell value appears in box when you click into edit mode
    if ((this.newValue === null || this.newValue === '') && this.equationValue !== null) {
      this.newValue = String(this.equationValue);
    }
    this.startedEditMode.emit(true);
  }

  focusOnTextbox(): void {
    if (this.editCellTextbox) {
      this.editCellTextbox.nativeElement.focus();
    }
  }

  editComplete(): void {
    if (isNaN(this.newValue)) {
      alert('Change not saved. We only accept numerical values.');
      this.newValue = this.overrideValue;
      return;
    }
    // if newValue is emptied out, revert it to original
    if (this.newValue === '' || this.newValue === null) {
      if (this.originalValueBeforeOverrides === null || this.originalValueBeforeOverrides === undefined){
        this.newValue = null;
      } else {
        this.newValue = String(this.originalValueBeforeOverrides);
      }
    }
    this.removeTrailingPeriod();
    this.removePrependedZeros();
    this.setValueStateFlags();
    this.doneEditing.emit({
      'newValue': this.newValue,
      'rowIndex': this.rowIdx,
      'colIndex': this.colIdx,
      'ref': this.ref,
      'comment': '',
      'childTemplateItemIdx': this.childTemplateItemIdx,
      'isPrimaryEntity': this.isPrimaryEntity,
      'linkedEntityId': this.linkedEntityId,
      'tradelineIdx': this.tradelineIdx,
      'isChanged': this.isChanged,
      'newValueMatchesOriginalValue': this.newValueMatchesOriginalValue,
    });
  }

  setValueStateFlags(): void {
    // this flag indicates whether the value has changed from the value that was set when the page loaded.
    // if it is true when passed into doneEditing emitter, then a new override will be created
    this.isChanged = this.overrideValue !== null || (this.newValue !== null && this.newValue !== '' && this.valueOnPageLoad !== this.newValue);

    // this flag simply checks whether or not the newValue matches the exact original value before any overrides existed
    // if it is true when passed into the doneEditing emitter, then it will delete any overrides and revert back to original value
    this.newValueMatchesOriginalValue = this.originalValueBeforeOverrides === Number(this.newValue);
  }

  formatItem(valueParam: any, textFormat = ''): string {
    if (!isFinite(valueParam)) {
      this.isDiv0 = true;
      return '#DIV0';
    }
    this.isDiv0 = false;
    let value = valueParam;
    if (typeof value === 'string') {
      // currently, overrides are coming in as strings
      value = parseFloat(valueParam);
    }

    const options = { showDollarSign: false, isRoundedToThousands: false };
    return this._numberFormattingService.formatNumber(value, textFormat, null, options);
  }

  checkValidationFlags(value) {
    // if we have a validator for this row (and comparer cant be empty string)
    if (this.validator && !!this.validator.comparer) {
        const result = this.analysisService.checkValidation(this.validator, value, this.scope);
        if (typeof result === 'boolean') {
          switch (this.validator.mark) {
            case 'INSUFFICIENT':
              this.insufficientValue = result;
              break;
            case 'SUFFICIENT':
              this.sufficientValue = result;
              break;
          }
        }
    }
  }

  removeTrailingPeriod() {
    if (this.newValue !== null && typeof this.newValue == 'string') {
      this.newValue = this.newValue.endsWith('.') ? this.newValue.substr(0, this.newValue.length - 1) : this.newValue;
    }
  }

  removePrependedZeros() {
    if (this.newValue !== null) {
      this.newValue = parseFloat(this.newValue).toString();
    }
  }
}

