<div class="modal__header">
    <div class="modal__title">
        REDIS CACHE COMMANDS
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form [formGroup]="restForm" (submit)="send()">
    <div class="modal__content">
        <div class="form-header">
            Send a Redis cache command
        </div>

        <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <a href="https://redis.io/commands" target="_blank">Command reference</a>
                    </div>
                </div>
            </div>

        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="control-label" for="body">Redis command</label>
                    <input type="text" id="body" class="form-control" name="body" formControlName="body" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="control-label" for="response">Response</label>
                    <textarea id="body" [attr.readonly]="true" class="form-control" name="response" rows="4"
                        formControlName="response">
            </textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary">Submit</button>
    </div>
    <div *ngIf="loading && loadMessage" class="alert alert-failure box-msg" role="alert">
        <strong><span>{{loadMessage}}</span></strong>
    </div>
</form>
