import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class DataViewGraphComponent implements OnInit {
  /* charting variables start here until we put them in its own component */
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Period';
  showYAxisLabel = true;
  viewDimensions: number[] = null;  // todo.  use inputs for these
  timeline = true;

  @Output() closeGraph = new EventEmitter<boolean>();

  @Input() height = 300;
  @Input() width = 1000;
  @Input() yAxisLabel = 'CURRENCY';
  @Input() graphItems: any[] = [
    {
      name: '',
      series: []
    }
  ]

  ngOnInit() {
    this.viewDimensions = [this.width, this.height];
  }
}
