import { Component, Input, OnInit } from '@angular/core';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';

@Component({
  selector: 'app-instructions-gif-popup',
  templateUrl: './instructions-gif-popup.component.html',
  styleUrls: ['./instructions-gif-popup.component.scss']
})

export class InstructionsGifPopupComponent implements OnInit {
  @Input() popup: NgxPopupComponent;
  @Input() title: string;
  @Input() description: string;
  @Input() gif: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
