<div class="wrapper"
  [ngClass]="{
    'align-right': alignRight,
    'has-padding': hasPadding
  }"
>
  <div class="display" *ngIf="!editing || isEditingDisabled" (dblclick)="startEditing($event)">
    <app-comment-present-indicator [sourceComments]="sourceComments"
    ></app-comment-present-indicator>
    <span *ngIf="formatAsCurrency">{{value | number:'1.2-2'}}</span>
    <span *ngIf="!formatAsCurrency">{{value}}</span>
      <span class="icon-wrapper" [ngClass]="{'d-none': !invertValue && !footnotePresent}">
          <span [ngClass]="{'d-none': !invertValue }">
              <i class="fas fa-circle-minus" data-bs-toggle="tooltip" data-bs-placement="top"
                 title="This line item is inverted and subtracting from the total"></i>
          </span>
          <span [ngClass]="{'d-none': !footnotePresent, 'ps-2': invertValue }">
              <i class="fas fa-file-lines" data-bs-toggle="tooltip" data-bs-placement="top"
                 title="This line item is associated with a footnote"></i>
          </span>
      </span>
  </div>
  <div class="edit" *ngIf="editing">
    <input #inputField type="text" (keydown)="checkForEnter($event)" [(ngModel)]="currentValue" (click)="stopPropagation($event)"/>
  </div>
</div>
