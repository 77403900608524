import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {
  HUMAN_INPUT_REQUIRED_STATUS,
  ERROR_STATUS,
  DOC_PROCESSING_STATUS,
  UPLOADED_STATUS,
  REVIEWED_STATUS,
  DOCUMENT_PASSWORD_ENTRY_REQUIRED
} from '../../../../../utils/constants';

@Component({
    selector: 'app-parsing-tracker-item',
    templateUrl: './parsing-tracker-item.component.html',
    styleUrls: ['./parsing-tracker-item.component.scss'],
})
export class ParsingTrackerItemComponent implements OnChanges {
  @Input() isError = false;
  @Input() fileName = '';
  @Input() fileSuffix = '';
  @Input() step = '';
  @Input() status = '';
  @Input() borrowerName = '';
  @Input() documentId: string = null;
  formattedStatus: string;

  @Output('openSingleDocMode') _openSingleDocMode: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('status')) {
      this._formatStatus();
    }
  }

  openSingleDocMode(): void {
    this._openSingleDocMode.emit(this.documentId);
  }

  _formatStatus() {
    switch (this.status) {
      case UPLOADED_STATUS:
        this.formattedStatus = 'Queued for Processing';
        break;
      case DOC_PROCESSING_STATUS:
        this.formattedStatus = 'Processing';
        break;
      case HUMAN_INPUT_REQUIRED_STATUS:
        this.formattedStatus = (this.step !== DOCUMENT_PASSWORD_ENTRY_REQUIRED) ? 'Awaiting Review' : 'Document Password Entry Required';
        break;
      case ERROR_STATUS:
        this.formattedStatus = 'Error';
        break;
      case REVIEWED_STATUS:
        this.formattedStatus = 'Awaiting Categorization';
        break;
      default:
        this.formattedStatus = '';
        break;
    }
  }
}
