import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

const number = new DecimalPipe('en-US');

@Pipe({name: 'formatDataFrameValue'})
export class DataFrameValueFormatterPipe implements PipeTransform {
  /**
   *
   * @param {string} value
   * @returns {string}
   */
  transform(value: string): string {
    try {
      const v =  number.transform(value, '1.2-2');
      return v
    } catch (ex) {
      return value;
    }
  }
}
