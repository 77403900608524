import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class DownloadService {

  constructor(private _apiService: ApiService) {
  }

  dictToQueryString(dict) {
    return Object.keys(dict).map(k => `${k}=${encodeURIComponent(dict[k])}`).join('&');
  }

  downloadFromBackend(path: string, otherParams: object = null) {
    if (!otherParams) {
      otherParams = {};
    }

    const queryParams = Object.assign({}, otherParams);

    // Need to add the bank ID to the request if we're a global admin.
    const bankID = localStorage.getItem('bank_id')
    if (bankID) {
      queryParams['bank_id'] = bankID;
    }

    const url = this._apiService._getApiUrl() + `${path}?${this.dictToQueryString(queryParams)}`;

    window.open(url, '_blank');
  }

  downloadPDFFromBackEnd(dataViewId: number, otherParams: object = null) {
    this.downloadFromBackend(`/api/v2/dataviews/${dataViewId}/pdf`, otherParams);
  }

}
