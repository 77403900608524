<div *ngIf="loading">
	<div class="loader__container">
		<div class="loader"></div>
	</div>
</div>
<div *ngIf="!loading" >
	<app-portal-sidebar 
		[supportEmail]="supportEmail"	
		[reportingPeriod]="periodDisplayName"
		[statementTypes]="documentRequestOptions['selected_types']"
		[dates]="requestedStatementDates"	
		[primaryColor]="primaryColor"
		[bank]="title"
	>
	</app-portal-sidebar>

	<app-main-content
		[customBankImg]="customBankImg"
		[primaryColor]="primaryColor"
		[complete]="complete"
		[documentSubmissions]="documentSubmissions"
		(onFileChoosen)="onFileChoosen($event)"
		(cancelSubmission)="cancelSubmission($event)"
		(submitDocuments)="submitDocuments($event)"
	></app-main-content>
</div>