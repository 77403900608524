import {MultiSelectItem} from "@components/shared/popover-menu-multi-select/popover-menu-multi-select.component";

export class DataViewConfig {
  id: number;
  dataViewId: number;
  companyId: number;
  numbers: boolean;
  roundToThousands: boolean;
  showAll: boolean;
  trend: boolean;
  commonSize: boolean;
  hiddenRows: Array<number>;
  hiddenColumns: Array<string>;
  dollarSign: boolean;
  title: string;
  summaryFinancialsFilter: {[key: string]: MultiSelectItem[]};


  constructor(id?: number, companyId?: number,
              dataViewId?: number, numbers?: boolean,
              roundToThousands?: boolean, showAll?: boolean,
              trend?: boolean, commonSize?: boolean,
              hiddenRows?: Array<number>, hiddenColumns?: Array<string>,
              dollarSign?: boolean, title?: string, summaryFinancialsFilter?: {[key: string]: MultiSelectItem[]}) {
    this.id = id;
    this.companyId = companyId;
    this.dataViewId = dataViewId;
    this.numbers = numbers;
    this.roundToThousands = roundToThousands;
    this.showAll = showAll;
    this.trend = trend;
    this.commonSize = commonSize;
    this.hiddenRows = hiddenRows;
    this.hiddenColumns = hiddenColumns;
    this.dollarSign = dollarSign;
    this.title = title ? title : ' ';
    this.summaryFinancialsFilter = summaryFinancialsFilter
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
