<div *ngIf="shouldBeDisplayed | async" class="comment-container">
    <div class="comment-operation">
        <div class="comment-identifier">
            <h3>Comment</h3>
        </div>
        <div class="comment-operators">
            <button
                class="btn btn-link"
                (click)="cancel()"
            >
                Cancel
            </button>
            <button
                class="btn btn-primary"
                (click)="saveSpreadingComment()"
            >
                Save
            </button>
        </div>
    </div>
    <div class="comment-operation-label">
        {{ rowWithCell.label }}
    </div>
    <textarea
        class="comment-area"
        placeholder="Type spread comment here..."
        maxlength="1000"
        [(ngModel)]="cellWithComment.sourceComments[0].comment"
    ></textarea>
</div>
