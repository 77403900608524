<div class="pagination-controls">
    <i class="fas fa-circle-arrow-down"
       [ngClass]="{'disabled': (visiblePageNum$ | async) == pageCount}"
       (click)="pageIncrease()"></i>
    <i class="fas fa-circle-arrow-up"
       [ngClass]="{'disabled': (visiblePageNum$ | async) <= 1}"
       (click)="pageDecrease()"></i>
    <div class="page-number">
        <input class="page-number-input form-control" [value]='visiblePageNum$ | async'
               (change)="pageNumberChanged($event)">
        <span> / {{pageCount || '-'}}</span>
    </div>
</div>
