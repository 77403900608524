<div class="modal__content">
  <div class="confirmation-popup-header">
      <i class="fas fa-list"></i>
      <span>{{SELECT_SPREADING_TEMPLATE_MODAL_HEADER}}</span>
  </div>
  <div class="confirmation-popup-text">
      <span>{{SELECT_SPREADING_TEMPLATE_MODAL_HELPER_TEXT}}</span>
  </div>
  <div class="select-template-toggle-container">
    <div class="header-text">Template</div>
    <app-select2
        [data]="availableTemplates"
        [options]="{minimumResultsForSearch: -1}"
        (valueChanged)="chooseTemplate($event.value)"
        [value]="selectedTemplate"
        [width]="'100%'"
        [placeholder]="'Select Template'"
        [disabled]="false"
        class="select-template-toggle"
    >
    </app-select2>
  </div>

  <div class="template-preview-container">
    <div class="header-text">Template Structure</div>
    <div class="template-preview">
      <ng-container *ngFor="let item of templatePreviewItems">
        <div
          [ngClass]="{
            'indent-0': item.indentLevel === 0,
            'indent-1': item.indentLevel === 1,
            'indent-2': item.indentLevel === 2,
            'indent-3': item.indentLevel === 3,
            'indent-4': item.indentLevel === 4,
            'indent-5': ![0, 1, 2, 3, 4].includes(item.indentLevel)
          }"
        >
          {{item.label}}
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal__footer">
  <button type="reset" class="btn btn-link" (click)="answer(false);">{{initialTemplateSelection ? BACK_TO_DATA_REVIEW_TEXT : CANCEL_TEXT}}</button>
  <button type="button" class="btn btn-primary"
   (click)="answer(true)" [disabled]="!initialTemplateSelection && defaultTemplateId === selectedTemplate">{{SELECT_TEXT}}</button>
</div>
