import { AutocompleteOption, Categorization, Cell, Column, Row, Suggestion } from '../models/models';
import * as math from 'mathjs';
import { RowSelectionActionType } from './actions';
import {
  BALANCE_SHEET,
  DYNAMIC_ADJUSTMENT_STATEMENT, DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES,
  INCOME_STATEMENT,
  STANDARD_ITEM_IDS,
  UNLABELED_ROW_PREFIX
} from '../../../../utils/constants';
import { ReviewQueueItem } from '../../../../models/review-queue-item';
import { DocumentFile } from '../../../../models/document-file';
import { Statement } from '../../review/human-validation/models/statement';
import * as uuid from 'uuid/v4'

function extractRollups(item: Row): Array<AutocompleteOption> {
  let options: Array<AutocompleteOption> = [];

  if (item.rollupName) {
      options.push({
          'id': item.lineItemId,
          'value': item.rollupName
      });
  } else if (item.calculated) {
    options.push({
      'id': item.lineItemId,
      'value': item.label,
    });
  }

  if (item.children) {
      options = options.concat.apply(options, (item.children.map(c => extractRollups(c))));
  }

  return options;
}

function extractAutocompleteOptions(item: Row): Array<AutocompleteOption> {
  let options: Array<AutocompleteOption> = [];

  if (item.isMappable && !item.calculated) {
      options.push({
          'id': item.lineItemId,
          'value': item.label,
      });
  }

  if (item.children) {
      options = options.concat.apply(options, item.children.map(c => extractAutocompleteOptions(c)));
  }
  return options;
}

function getAutocompleteOptions(rows: Array<Row>): Array<AutocompleteOption> {
  return [].concat.apply([], rows.map(r => extractAutocompleteOptions(r)));
}

function getRollupOptions(rows: Array<Row>): Array<AutocompleteOption> {
  return [].concat.apply([], rows.map(r => extractRollups(r)));
}

/**
 * Recursively remove the source row from the any descendant of
 * the template row.
 * @param templateRow
 * @param sourceRow
 */
function searchAndDestroy(templateRow: Row, sourceRow: Row) {
  if (templateRow.validationRow && templateRow.validationRow.uuid === sourceRow.uuid) {
    templateRow.validationRow = null;
    return;
  }

  if (templateRow.children) {
    if (!templateRow.header) {
      const idx = templateRow.children.findIndex(child => child.uuid === sourceRow.uuid);
      if (idx >= 0) {
        templateRow.children.splice(idx, 1);
        return;
      }
    }
    for (let i = 0; i < templateRow.children.length; i++) {
      searchAndDestroy(templateRow.children[i], sourceRow);
    }
  }
}

/**
 * Search the tree for the line item with the given ID and add the
 * source row to that template row if found.
 *
 * @param templateRow The row to search
 * @param normalizedItemId The lineItemId of the template item you want to add to
 * @param isValidation Whether the item should be added as a validation for (true)
 * or component of (false) the template row
 * @param sourceRow The source row you want to categorize
 */
function searchAndAdd(templateRow: Row, normalizedItemId: number, isValidation = false, sourceRow: Row, path: Array<Row>) {
  if (templateRow.lineItemId === normalizedItemId) {
    const subItemIdx = -1;
    if (isValidation) {
      templateRow.validationRow = sourceRow;
    } else {
      if (!templateRow.children) {
        templateRow.children = [];
      }

      templateRow.children.push(sourceRow);
    }
    return [true, path];
  }

  if (templateRow.children) {
    for (let i = 0; i < templateRow.children.length; i++) {
      const res = searchAndAdd(templateRow.children[i], normalizedItemId, isValidation, sourceRow, path.concat([templateRow.children[i]]));
      if (res[0]) {
        return res;
      }
    }
  }
  return [false, []];
}

function getMapByID(rows: Array<Row>): Map<number, Row> {
  const map = new Map<number, Row>();

  rows.forEach(r => {
    map.set(r.lineItemId, r);
    if (r.children) {
      const nested = getMapByID(r.children);
      nested.forEach((value: Row, key: number) => {
        map.set(key, value);
      });
    }
  });
  return map;
}


/**
 * This class is the datastore for the spreading screen. It is separated
 * from the component for the purposes of testing and separation of concerns.
 */
export class SpreadingState {

  static newFromStatement(statement: Statement, template: Array<Row>): SpreadingState {
    const state = new SpreadingState();
    state.initializeTemplate(template);
    state.setStatement(statement);
    state.calculate();
    return state;
  }

  private static createCellFromValue(cellValue: number): Cell {
    return {
      // commentFromSpreading: '',
      // Value is read-only and figured out from rawText
      rawText: (cellValue * -1).toString(),
      // For latency compensation
      value: (cellValue * -1),
    }
  }

  templateMap: Map<number, Row>;
  dasRefSnapshot: any = {};
  // These are the dynamically calculated attributes that drive the interface.
  rows: Array<Row> = [];   // These are template rows
  previewRows: Array<Row> = [];
  sourceRows: Array<Row> = [];  // Need this
  columns: Array<Column> = [];
  autocompleteOptions: Array<AutocompleteOption> = [];
  rollupOptions: Array<AutocompleteOption> = [];
  referenceRows: Array<Row>;
  shiftDirection: 'up'|'down'|'neutral' = 'neutral';

  // Store the tree traversal path for the last categorization
  lastCategorizationPath: Array<Row> = [];

  lastSelectionAction: RowSelectionActionType;

  statementType: string = INCOME_STATEMENT;
  _calculatorRef = {};


  // Just storage for additional data
  documentFile: DocumentFile = null;
  reviewQueueItem: ReviewQueueItem = null;

  spreadingTemplateId: number = null;

  statement: Statement = null;

  // Calculated in background on init and used for goal seek calculations
  goalSeekRollupMap: Map<number, Map<number, number>>;
  // at least this % of source rows must be categorized to trigger goal seek
  goalSeekRowCategorizationPercent = 0.6;
  clipboard: Array<any> = [];

  goalSeekCount: number = 0;

  setStatement(statement: Statement) {
    this.statement = statement;
    this.columns = statement.columns;
    this.sourceRows = statement.rows;
    this.spreadingTemplateId = statement.spreadingTemplateId;
    this.statementType = statement.statementType;
    this.resetRows(this.rows);
    const categorizations = [];
    this.sourceRows.forEach(r => {
      if (r?.weightDecimal) {
        r.weightPercentage = r.weightDecimal * 100;
      }

      r.rollupCoefficient = 1;
      if (r.categorizedTo > 0) {
        const match = this.templateMap.get(r.categorizedTo);
        if (!match) {
          // this thing does not exist in the template
          return;
        }
        let label = '';
        if (match.rollupName && r.categorizationType === 'validation') {
          label = match.rollupName;
        } else {
          label = match.label;
        }
        r.categorizationLabel = label;

        const categorization: Categorization = {
          'sourceRow': r,
          'templateLineItemId': r.categorizedTo,
          'templateLineItemLabel': r.categorizationLabel,
          'isValidation': r.categorizationType === 'validation'
        }
        categorizations.push(categorization);
      }
    });

    this.categorizeRows(categorizations);

    this.deselectAll('column');
    if (this.columns.length) {
      this.columns[this.columns.length - 1].selected = true;
    }

    this.lastCategorizationPath = [];
    this.setReferenceRows();
    this.calculate();
  }
  initializeTemplate(template: Array<Row>) {
    // If we share the template amongst different statements within the same context (same cache), we would have
    // rows categorized from "Elsewhere" that make it into the autocomplete options. When we initialize the template
    // we need to totally clear out any non-template items, and then the next step is to categorize those items
    // according to whatever already exists on the statement.
    this.resetRows(template);
    this.rows = template;
    this.templateMap = getMapByID(template);
    this.autocompleteOptions = getAutocompleteOptions(template);
    this.rollupOptions = getRollupOptions(template);
    this.populateGoalSeekRollupMap();
  }
  /**
   * Categorize all selected rows to the given template item
   * @param args
   */
  categorizeAllSelectedRows(args: Categorization) {
    const index = this.getLastSelectedItemIndex('row');
    this.categorizeRows(this.getAllSelectedRows().map(r => {
      return {
        'templateLineItemId': args.templateLineItemId,
        'isValidation': args.isValidation,
        'templateLineItemLabel': args.templateLineItemLabel,
        'sourceRow': r
      }
    }));

    // Select the one after the last one we mapped
    if (index < this.sourceRows.length) {
      this.rowSelectionAction(RowSelectionActionType.SELECT_NEXT);
    } else {
      this.deselectAll();
    }

    // Set the reference rows
  }

  setReferenceRows() {
    let refs = [];
    const alwaysOnTop = [];
    if (this.statementType === INCOME_STATEMENT) {
      // Net Income
      alwaysOnTop.push(STANDARD_ITEM_IDS.NET_INCOME);
      // EBITDA
      alwaysOnTop.push(STANDARD_ITEM_IDS.EBITDA);
    } else {
      // Balance
      alwaysOnTop.push(STANDARD_ITEM_IDS.BALANCE_CHECK);
    }

    refs = this.rows.filter((r) => alwaysOnTop.indexOf(r.lineItemId) > -1);

    // Add the tree traversal for the last categorization
    this.lastCategorizationPath.forEach(r => {
      refs.push(r);
    });
    this.referenceRows = refs;
  }

  getSelectedRowSum(): number {
    const selectedColumnIdx = this.getLastSelectedItemIndex('column');
    if (selectedColumnIdx < 0) {
      return 0.0;
    }

    const rows = this.getAllSelectedRows();

    return rows.reduce((sum, row) => {
      return sum + this.getCellValueForCalculations(row, row.cells[selectedColumnIdx]);
    }, 0);
  }

  addRowsAtIndex(index: number, numberOfItemsToAdd = 1): void {
    const itemsToAdd = Array
      .from({length: numberOfItemsToAdd})
      .map(_ => this.emptyRow());

    if (this.sourceRows.length >= index) {
      this.sourceRows.splice.apply(
        this.sourceRows,
        [index, 0, ...itemsToAdd]
      );
    } else {
      this.sourceRows = this.sourceRows.concat(itemsToAdd);
    }
  }

  addAdjustmentForRow(rowIdx: number, adjustmentLabel: string): void {
    const row = this.sourceRows[rowIdx];
    const newRow: Row = {
      label: adjustmentLabel,
      rollupCoefficient: 1,
      footnotes: [],
      uuid: uuid(),
      cells: row.cells.map(c => {
        const cellValue = this.getCellValueForCalculations(row, c);
        return SpreadingState.createCellFromValue(cellValue);
      })
    };

    this.sourceRows.splice(rowIdx + 1, 0, newRow);
  }

  replaceRowAtIndex(row: Row, index) {
    const newRow = JSON.parse(JSON.stringify(row))

    // if we are replacing a row that is categorized we need to uncategorize it before replacing
    if (this.sourceRows[index]) {
      this.categorizeRows([{
        sourceRow: this.sourceRows[index],
        isValidation: false,
        templateLineItemId: 0,
        templateLineItemLabel: '',
      }]);
    }

    this.sourceRows.splice(index, 1, newRow)
  }

  replaceColumnAtIndex(col, colIndex, clipboardIndex, clipboard) {
    const newCol = JSON.parse(JSON.stringify(col['column']));
    this.columns.splice(colIndex, 1, newCol);

    this.sourceRows.forEach((row, idx) => {
      const newRow = JSON.parse(JSON.stringify(clipboard[clipboardIndex]['rows'][idx]));
      row.cells.splice(colIndex, 1, newRow);
    });
  }

  deleteRowAtIndex(idx: number) {
    this.categorizeRows([{
      sourceRow: this.sourceRows[idx],
      isValidation: false,
      templateLineItemId: 0,
      templateLineItemLabel: '',
    }]);
    this.sourceRows.splice(idx, 1);
  }

  deleteSelectedRows() {
    // Clear the categorizations first so we don't have any orphans.
    this.categorizeRows(this.sourceRows.filter(r => r.selected).map(r => {
      return {
        sourceRow: r,
        isValidation: false,
        templateLineItemId: 0,
        templateLineItemLabel: '',
      };
    }));

    this.sourceRows = this.sourceRows.filter(r => !r.selected);
  }

  emptyRow(): Row {
    return {
      invertValue: false,
      rollupCoefficient: 1.0,
      label: '',
      uuid: uuid(),
      footnotes: [],
      cells: this.columns.map(_ => this.emptyCell()),
    };
  }

  emptyColumn() {
    // We need a fallback if there are no columns left
    let fye = '12/31/2000';
    if (this.columns.length) {
      fye = this.columns[0].fiscalYearEnd;
    }
    return {
      'statementDate': '',
      'preparationType': 'UNKNOWN',
      'reportingInterval': 'MONTHLY',
      'scenario': '',
      'fiscalYearEnd': fye,
    };
  }

  emptyCell(): Cell {
    return {
      rawText: '0.0',
      value: 0.0,
    };
  }

  addColumnsAtIndex(idx: number, n = 1) {
    const columnsToAdd = Array.from({length: n}).map(_ => this.emptyColumn());
    if (this.columns.length >= idx) {
      // @ts-ignore
      const args = [idx, 0].concat(columnsToAdd);
      this.columns.splice.apply(this.columns, args);
    } else {
      this.columns = this.columns.concat(columnsToAdd);
    }

    this.statement.columns = this.columns;

    this.sourceRows.forEach(r => {
      const cellsToAdd = Array.from({length: n}).map(_ => this.emptyCell());
      // @ts-ignore
      r.cells.splice.apply(r.cells, [idx, 0].concat(cellsToAdd));
    });

    // Now recalculate. calculate calls initializeRow() for each row which adds the proper number of cells based on the columns.
    this.calculate();
  }

  deleteColumnAtIndex(idx: number) {
    this.columns.splice(idx, 1);
    this.sourceRows.forEach(r => {
      r.cells.splice(idx, 1);
    });
    this.statement.columns = this.columns;
    this.calculate();
  }

  deleteSelectedColumns() {
    // Need this in descending order so when we splice it doesn't affect the other indices to remove
    const selectedColumnIndices = this.getAllSelectedColumnIndices().reverse();
    // Remove the cells
    this.sourceRows.forEach(r => {
      selectedColumnIndices.forEach(idx => {
        r.cells.splice(idx, 1);
      });
    });
    this.columns = this.columns.filter(c => !c.selected);
    this.deselectAll('column');

    // Always have at least one column
    if (this.columns.length === 0) {
      this.addColumnsAtIndex(0);
    }

    this.columns[this.columns.length - 1].selected = true;
    this.statement.columns = this.columns;
    this.calculate();
  }

  categorizeRows(categorizations: Array<Categorization>) {
    categorizations.forEach(c => {
      this.categorizeRow(c);
    });

    this.calculate();
    this.setReferenceRows();
  }

  clearCategorization(row: Row) {
    this.categorizeRows([{
      sourceRow: row,
      isValidation: false,
      templateLineItemId: 0,
      templateLineItemLabel: '',
    }]);
  }

  // must make sure there is a selection when using this method
  endingSelectedRow(): Row {
    const selectedRows = this.getAllSelectedRows();
    if (!selectedRows) {
      return;
    }
    const length = selectedRows.length;
    return selectedRows[length - 1];
  }

  isLastRowSelected(): boolean {
    if (!this.sourceRows) {
      return false;
    }
    const length = this.sourceRows.length;
    const finalRow = this.sourceRows[length - 1];
    return finalRow.selected; // boolean isSelected
  }

  isRowSelected(index): boolean {
    if (!this.sourceRows) {
      return false;
    }

    if (this.sourceRows[index]) {
      return !!this.sourceRows[index].selected; // will return true/false even if null/undefined
    }

    return false;
  }

  isColumnSelected(index): boolean {
    if (!this.columns) {
      return false;
    }

    if (this.columns[index]) {
      return !!this.columns[index].selected; // will return true/false even if null/undefined
    }

    return false;
  }

  isFirstRowSelected(): boolean {
    if (!this.sourceRows) {
      return false;
    }
    const firstRow = this.sourceRows[0];
    return firstRow.selected; // boolean isSelected
  }

  endingSelectedRowIndex(): number {
    if (!this.sourceRows) {
      return;
    }
    const length = this.sourceRows.length;
    const lastKey = length - 1;
    for (let i = lastKey; i >= 0; i--) {
      if (this.sourceRows[i].selected) {
        return i;
      }
    }
    // should have returned before this. If not, nothing is selected.
    return 0;
  }

  beginningSelectedRowIndex(): number {
    if (!this.sourceRows) {
      return;
    }
    const length = this.sourceRows.length;
    const lastKey = length - 1;
    for (let i = 0; i <= lastKey; i++) {
      if (this.sourceRows[i].selected) {
        return i;
      }
    }
    // should have returned before this. If not, nothing is selected.
    return 0;
  }

  getFirstNonHeaderRowIndex(): number {
    if (!this.sourceRows) {
      return;
    }

    const length = this.sourceRows.length;
    const lastKey = length - 1;
    for (let i = 0; i <= lastKey; i++) {
      if (!this.sourceRows[i].header) {
        return i;
      }
    }
    // should have returned before this. If not, nothing is selected.
    return 0;
  }

  getLastNonHeaderRowIndex(): number {
    if (!this.sourceRows) {
      return;
    }
    const length = this.sourceRows.length;
    const lastKey = length - 1;
    for (let i = lastKey; i >= 0; i--) {
      if (!this.sourceRows[i].header) {
        return i;
      }
    }
    // should have returned before this. If not, nothing is selected.
    return 0;
  }

  getNumSelectedRows(): number {
    return this.getAllSelectedRows().length;
  }

  areRowsSelected(): boolean {
    return this.getNumSelectedRows() > 0;
  }

  /**
   * Clear the categorizations for all provided rows
   */
  clearAllCategorizations(rows: Array<Row>) {
    this.categorizeRows(rows.map(r => {
      return {
        sourceRow: r,
        isValidation: false,
        templateLineItemId: 0,
        templateLineItemLabel: '',
        suggestedChange: null
      };
    }));

    this.lastCategorizationPath = [];
    this.setReferenceRows();
  }

  reset() {
    this.resetRows(this.rows);
  }

  /***
   * Recursive function for removing all categorizations from a template
   */
  resetRows(rows: Array<Row>) {
    rows.forEach(r => {
      if (r.template && r.children && r.children.length) {
        if (!r.children[0].template) {
          r.children = [];
        } else {
          this.resetRows(r.children);
        }
      }
    });
  }

  /**
   * Calculate the template rows via rollups and formulas
   */
  calculate() {
    if (this.statementType == DYNAMIC_ADJUSTMENT_STATEMENT){
      this.calculateDASItems();
      return
    }
    this._calculatorRef = this.dasRefSnapshot ? this.dasRefSnapshot : {};

    // For each normalized cell, set the value to the rollup of its children.
    // Calculate all normalized rollups
    this.rows.forEach(r => {
      this.calculateRow(r);
    });

    this.columns.forEach(c => {
      if (c.columnCheckOk == null) {
        c.columnCheckOk = false;
      }
    });

    return;
  }

  calculateDASItems(){
    // For DAS statements, we need to dynamically calculate values for rows in the statement, instead of template rows
    this._calculatorRef = {}
    this.sourceRows.forEach(row => {
      if (row?.ref) {
        if (['AdjustedStandardItem', 'NonAdjustableStandardItem'].includes(row.itemClass)) {
          // sum the set of add-back items
          if (this._calculatorRef[row.ref]) {
            const nextCalcRefValue = [];
            const newRowValues = row.cells.map(c => this.getAddBackCellValueForCalculations(row, c));
            const existingValues = this._calculatorRef[row.ref];
            for (let i= 0; i<existingValues.length; i++){
              nextCalcRefValue.push(existingValues[i] + newRowValues[i]);
            }
            this._calculatorRef[row.ref] = nextCalcRefValue
          } else {
            this._calculatorRef[row.ref] = row.cells.map(c => this.getAddBackCellValueForCalculations(row, c));
          }
        } else {
          this._calculatorRef[row.ref] = row.cells.map(c => row.rollupCoefficient === 0 ? c.value : this.getCellValueForCalculations(row, c));
        }
      }
    });
    this.statement.rows.forEach(r => {
      if (r?.dynamicAdjustmentStatementItemType==DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.CUSTOM_CALCULATION && r.formula) {
        try {
          this.evaluateRowFormula(r, true)
        } catch(e){
          console.log('failed to eval row formula: ', e)
        }
      }
    });
  }

  getAddBackCellValueForCalculations(row, c) {
    if (!row.include) {
      return 0
    }
    let value = row.rollupCoefficient === 0 ? c.value : this.getCellValueForCalculations(row, c)
    if (row.weightPercentage) {
      try {
        const floatWeight = parseFloat(row.weightPercentage);
        const weightAsDecimal = floatWeight/100;
        return value * weightAsDecimal
      } catch (e) {
        console.error(`Failed to calculate raw add-back item. Invalid percentage provided. Item ref: ${row.ref}, weight value: ${row.weightDecimal}`)
        return 0
      }
    }
    return value
  }

  calculateRow(row: Row) {
    if (!row.template) {
      return;
    }
    this.initializeRow(row);

    // Calculate all children recursively
    if (row.children) {
      row.children.forEach(child => {
        this.calculateRow(child);
      });

      // Now calculate rollups after we've looped through the children
      this.calculateRollupsForRow(row);
    } else if (row.formula) {
      this.evaluateRowFormula(row);
    }

    if (row.ref) {
      // Use 0.0 instead of 'undefined' here so that we can use it in calculations even if we haven't mapped anything.
      // This is a symptom of not using classes w/ defaults but OK for this prototype.
      this._calculatorRef[row.ref] = row.cells.map(c => row.rollupCoefficient === 0 ? c.value : this.getCellValueForCalculations(row, c));
    }

    // Check validation
    if (row.validationRow) {
      this.validateRow(row);
    } else if (row.lineItemId === STANDARD_ITEM_IDS.BALANCE_CHECK) {
      // need special logic to check the "Check" item to confirm balance sheet balances
      this.validateCheckRow(row);
    }

    // Mark overall column if row IS - Net Income checks
    if (this.statementType === INCOME_STATEMENT && row.lineItemId === STANDARD_ITEM_IDS.NET_INCOME ) {
        this.columns.forEach((c, i) => {
          c.columnCheckOk = row.validationRow && !row.cells[i].flag;
        });
    }

    // Mark overall column if row BS - check is OK (BS Check never uses validation row)
    if (this.statementType === BALANCE_SHEET && row.lineItemId === STANDARD_ITEM_IDS.BALANCE_CHECK) {
        this.columns.forEach((c, i) => {
          c.columnCheckOk = !row.cells[i].flag;
        });
    }
  }

  getAllSelectedRows(): Array<Row> {
    return this.sourceRows.filter(r => r.selected);
  }

  getAllSelectedColumns(): Array<Column> {
    return this.columns.filter(c => c.selected);
  }

  getAllSelectedColumnIndices(): Array<number> {
    return this.columns.map((c, i) => {
      return {
        c: c,
        i: i
      };
    }).filter(c => c.c.selected).map(c => c.i);
  }

  /**
   * Multiply the value of all cells in the selected rows by -1
   * (flip the sign)
   */
  invertSelectedRows() {
    this.getAllSelectedRows().forEach(r => {
      r.invertValue = !r.invertValue;
      // If we suggested a change to the row and a user has inverted it, remove suggestion flag
      if (r.suggestedChange) {
        this.clearGoalSeekSuggestion(r);
      }
    });
    this.calculate();
  }

  getFirstSelectedRow(): Row {
    return this.getFirstSelectedItem('row') as Row;
  }

  getFirstSelectedItem(type: 'row' | 'column'): Row | Column {
    return this.getRowOrColumnEntitiesByType(type).find(r => r.selected);
  }

  getFirstSelectedRowIndex(): number {
    return this.getFirstSelectedItemIndex('row');
  }

  getFirstSelectedItemIndex(type: 'row' | 'column'): number {
    return this.getRowOrColumnEntitiesByType(type).findIndex(r => r.selected);
  }

  rowSelectionAction(action: RowSelectionActionType, itemIdx: number = 0) {
    return this.rowOrColumnSelectionAction('row', action, itemIdx);
  }

  columnSelectionAction(action: RowSelectionActionType, itemIdx: number = 0) {
    return this.rowOrColumnSelectionAction('column', action, itemIdx);
  }

  rowOrColumnSelectionAction(type: 'row' | 'column', action: RowSelectionActionType, itemIdx: number = 0) {
    const sourceEntities = this.getRowOrColumnEntitiesByType(type);

    let lastSelectedIndex, firstSelectedIndex;
    switch (action) {
      // Change selection to the designated row
      case RowSelectionActionType.SELECT_SINGLE:
        this.deselectAll(type);
        if (!sourceEntities[itemIdx]) {
          return;
        }
        sourceEntities[itemIdx].selected = true;
        break;
      // Deselect the designated row
      case RowSelectionActionType.DESELECT_SINGLE:
        if (!sourceEntities[itemIdx]) {
          return;
        }
        sourceEntities[itemIdx].selected = false;
        break;
      case RowSelectionActionType.DESELECT_LAST:
        lastSelectedIndex = this.getLastSelectedItemIndex(type);
        if (!sourceEntities[lastSelectedIndex]) {
          return;
        }
        sourceEntities[lastSelectedIndex].selected = false;
        break;
      case RowSelectionActionType.DESELECT_FIRST:
        firstSelectedIndex = sourceEntities.findIndex(r => r.selected);
        if (!sourceEntities[firstSelectedIndex]) {
          return;
        }
        sourceEntities[firstSelectedIndex].selected = false;

        break;
      // Change selection to the row after the currently selected row
      case RowSelectionActionType.SELECT_NEXT:
        lastSelectedIndex = this.getLastSelectedItemIndex(type);
        if (sourceEntities.length > (lastSelectedIndex + 1)) {
          this.deselectAll(type);
          sourceEntities[lastSelectedIndex + 1].selected = true;
        }
        break;
      // Change selection to the row before the first selected row
      case RowSelectionActionType.SELECT_PREVIOUS:
        firstSelectedIndex = sourceEntities.findIndex(r => r.selected);

        if (firstSelectedIndex > 0) {
          this.deselectAll(type);
          sourceEntities[firstSelectedIndex - 1].selected = true;
        }
        break;
      // Add the row immediately before the first selected row to the selection
      case RowSelectionActionType.INCLUDE_PREVIOUS:
        firstSelectedIndex = sourceEntities.findIndex(r => r.selected);
        if (firstSelectedIndex > 0) {
          sourceEntities[firstSelectedIndex - 1].selected = true;
        }
        this.shiftDirection = 'up';
        break;
      // Add the designated row to the selection
      case RowSelectionActionType.INCLUDE_ADDITIONAL:
        if (!sourceEntities[itemIdx]) {
          return;
        }
        sourceEntities[itemIdx].selected = true;
        break;
      // Add the row immediately after the last selected row to the selection
      case RowSelectionActionType.INCLUDE_NEXT:
        lastSelectedIndex = this.getLastSelectedItemIndex(type);
        if (sourceEntities.length > (lastSelectedIndex + 1)) {
          sourceEntities[lastSelectedIndex + 1].selected = true;
        }
        this.shiftDirection = 'down';
        break;
      // Add all rows between the current selection and the designated row to the selection
      case RowSelectionActionType.INCLUDE_UNTIL:
        lastSelectedIndex = this.getLastSelectedItemIndex(type);
        firstSelectedIndex = sourceEntities.findIndex(r => r.selected);
        if (itemIdx > lastSelectedIndex) {
          for (let i = lastSelectedIndex + 1; i <= itemIdx; i++) {
            sourceEntities[i].selected = true;
          }
        } else if (itemIdx < firstSelectedIndex) {
          for (let i = itemIdx; i < firstSelectedIndex; i++) {
            sourceEntities[i].selected = true;
          }
        }
        break;
      case RowSelectionActionType.DESELECT_ALL:
        this.deselectAll(type);
        break;
    }

    if ( (action !== RowSelectionActionType.INCLUDE_PREVIOUS &&
      action !== RowSelectionActionType.INCLUDE_NEXT) && this.getNumSelectedRows() <= 1) {
        this.shiftDirection = 'neutral';
      }

    this.lastSelectionAction = action;
  }

  /**
   * Flatten the rows so we can render the table in a real table rather
   * than a list.
   */
  getPreviewRows(): Array<Row> {
    return [].concat.apply([], this.rows.map(r => this.flattenRow(r)));
  }


  /**
   * Allows us to invert by flipping the rollup coefficient and uses that
   * to return the value for a cell that we should be using for any calc
   */
  getCellValueForCalculations(row: Row, cell: Cell): number {
    if (cell && cell.value) {
      // @ts-ignore
      const value = parseFloat(cell.value);
      return row.invertValue ?  value * -1 : value;
    } else {
      return 0.0;
    }
  }

  deselectAll(type = 'row') {
    this.getRowOrColumnEntitiesByType(type).forEach(e => {
      e.selected = false;
    });
  }

  getLastSelectedItemIndex(type: 'row' | 'column'): number {
    const filtered = this.getRowOrColumnEntitiesByType(type).map((r, i) => {
      return {
        r: r,
        i: i
      };
    }).filter(r => r.r.selected)

    if (!filtered.length) {
      return -1;
    }

    return filtered.slice(-1)[0].i;
  }

  enableGoalSeekForIncomeStatement() {
    return this.enoughSourceRowsCategorizedForGoalSeek() && !this.netIncomeValidates()
  }

  enoughSourceRowsCategorizedForGoalSeek() {
    const nonHeaderRows = this.sourceRows.filter(sr => !sr.header);
    if (nonHeaderRows.length === 0) {
      return false;
    }
    const categorizedRows = nonHeaderRows.filter(sr => sr.categorizedTo > 0);
    return (categorizedRows.length / nonHeaderRows.length) > this.goalSeekRowCategorizationPercent;
  }

  netIncomeValidates() {
    let valid = false;
    this.rows.forEach(row => {
      // id 62400 is Net Income and is not expected to change
      if (row.lineItemId === 62400 && row.validationRow) {
        valid = row.cells.every(function(c) {
          return !c.flag;
        })
      }
    })
    return valid;
  }

  clearGoalSeekSuggestion(row: Row) {
    this.goalSeekCount--;
    row.suggestedChange = null;
  }

  updateFromGoalSeekSuggestions(suggestions: Array<Suggestion>, originLineItemId: number) {
    this.goalSeekCount = 0;
    this.sourceRows.forEach(r => {
      suggestions.forEach(s => {
        if (r.lineItemId === s.lineItemId) {
          r.suggestionOrigin = originLineItemId;
          this.goalSeekCount++;
          switch (s.operation) {
            case 'invert':
              r.suggestedChange = 'Try inverting this line item';
              break;
            case 'uncategorize':
              r.suggestedChange = 'Try uncategorizing this line item'
              break;
            case 'categorize':
              r.suggestedChange = 'Try categorizing this line item'
              break;
            case 'categorize_invert':
              r.suggestedChange = 'Try categorizing this line item'
              break;
          }
        }
      })
    })
    this.calculate();
  }

  getGoalSeekSourceRows(row: Row, columnIdx: number) {
    // get a list of source rows eligible to be changed
    const sourceRows = this.sourceRows.filter(sr => (sr.categorizationType !== 'validation') && (!sr.header));

    // from this list, filter out anything that is already part of a correctly validated item
    this.rows.forEach(r => {
      if (r.cells[columnIdx].offBy === 0) {
        // recursively remove sourceRows that are part of validated items
        this.removeValidatedRows(sourceRows, r);
      }
    });
    this.populateGoalSeekRollupCoefficients(sourceRows, row.lineItemId);
    return sourceRows;
  }

  populateGoalSeekRollupCoefficients(rows: Array<Row>, goalSeekLineItemId: number) {
    rows.forEach(r => {
      if (r.categorizedTo === 0) {
        // if something hasnt been categorized yet, it doesnt matter what we assume here, as we will
        // check both positive and negative rollups, and the user will need to confirm
        r.goalSeekCoefficient = 1;
      } else if (this.goalSeekRollupMap.has(goalSeekLineItemId) && this.goalSeekRollupMap.get(goalSeekLineItemId).has(r.categorizedTo)) {
        r.goalSeekCoefficient = this.goalSeekRollupMap.get(goalSeekLineItemId).get(r.categorizedTo);
      } else {
        // this line items categorization is not relevant to the goal seek row
        r.goalSeekCoefficient = 0;
      }
    })
  }

  populateGoalSeekRollupMap() {
    this.goalSeekRollupMap = new Map<number, Map<number, number>>();
    // iterate over highest level template rows and populate rollup maps
    this.rows.forEach(async row => {
      const rollupMap = new Map<number, number>();
      this.getRollupMapForRow(row, 1, rollupMap);
      this.goalSeekRollupMap.set(row.lineItemId, rollupMap);
    })
  }

  /**
   * Cleans a formula to by transforming it into REF components and +,- operators
   * separated by spaces
   * @param formula
   */
  cleanFormula(formula: string): string {
    formula = formula.replace('=', '');
    formula = formula.replace('+', ' + ').replace('-', ' - ');
    // replace double (or more) spaces with single
    formula = formula.replace(/\s{2,}/g, ' ').trim();
    return formula;
  }

  private getRollupMapForRow(row: Row, rollupCoefficient: number, rollupMap: Map<number, number>) {
    rollupMap.set(row.lineItemId, rollupCoefficient);
    if (row.hasOwnProperty('children')) {
      this.getRollupMapFromChildren(row, rollupCoefficient, rollupMap);
    } else if (row.hasOwnProperty('formula')) {
      this.getRollupMapFromFormula(row, rollupCoefficient, rollupMap);
    }
  }

  private getRollupMapFromChildren(row: Row, rollupCoefficient: number, rollupMap: Map<number, number>) {
    row.children.forEach(child => {
      // make sure the child rows are not source row items that have been categorized
      if (child.template) {
        rollupMap.set(child.lineItemId, child.rollupCoefficient * rollupCoefficient);
        this.getRollupMapForRow(child, child.rollupCoefficient * rollupCoefficient, rollupMap)
      }
    });
  }

  private getRollupMapFromFormula(row: Row, rollupCoefficient: number, rollupMap: Map<number, number>) {
    let formulaCoefficient = rollupCoefficient;
    const cleanFormula = this.cleanFormula(row.formula)
    cleanFormula.split(' ').forEach(component => {
      // if we come across addition / substraction, note it for the next item
      if (component === '+') {
        formulaCoefficient = rollupCoefficient;
      } else if (component === '-') {
        formulaCoefficient = -1 * rollupCoefficient;
      } else {
        this.rows.forEach(r => {
          if (r.ref === component) {
            this.getRollupMapForRow(r, formulaCoefficient, rollupMap);
          }
        })
      }
    })
  }

  /**
   * Given a list of statement source rows and a validated total row, recursively
   * remove all child rows of validated row
   *
   * @param rows
   * @param validatedRow
   */
  private removeValidatedRows(sourceRows: Array<Row>, validatedRow: Row) {
    if (validatedRow.children) {
      validatedRow.children.forEach(child => {
        this.removeValidatedRows(sourceRows, child);
        const sourceRowIndex = sourceRows.indexOf(child);
        if (sourceRowIndex !== -1) {
          sourceRows.splice(sourceRowIndex, 1);
        }
      });
    }
  }

  private getRowOrColumnEntitiesByType(type = 'row'): Array<Row | Column> {
    if (type === 'row') {
      return this.sourceRows;
    }
    return this.columns;
  }

  private flattenRow(row: Row, level = 0): Array<Row> {
    row.previewIndentation = level;
    let rv = [row];

    if (row.children) {
      rv = rv.concat.apply(rv, row.children.map(c => this.flattenRow(c, level + 1)));
    }

    if (row.rollupName) {
      rv.push({
        'label': row.rollupName,
        'cells': row.cells,
        'lineItemId': row.lineItemId,
        'invertValue': row.invertValue,
        'calculated': true,
        'previewIndentation': level,
      });
    }
    return rv;
  }

  /**
   * Validates the "Check" row on balance sheet
   * This row should always have values equal to zero, but never has a
   * "validation" row assigned to it
   */
  private validateCheckRow(row: Row) {
    row.cells.forEach(cell => {
      if (Math.abs(cell.value) > 1.0) {
        cell.flag = true;
        cell.flagReason = `Off by ${cell.value.toFixed(2)}`;
        cell.offBy = -cell.value;
      } else {
        cell.flag = false;
        cell.flagReason = '';
        cell.offBy = 0.0;
      }
    });
  }

  /**
   * Validates a row that is expected to match 1:1 with a designated
   * other row. Sets the cell flag + offBy values for each cell that is
   * different from the expected value.
   *
   * @param row
   */
  private validateRow(row: Row) {
    row.cells.forEach((cell, idx) => {
      const expectedValue = this.getCellValueForCalculations(row.validationRow, row.validationRow.cells[idx])

      if (!cell.value) {
        cell.value = 0.0;
      }
      const cv = row.rollupCoefficient === 0 ? cell.value : this.getCellValueForCalculations(row, cell);

      if (typeof expectedValue !== 'number' || typeof cv !== 'number' ) {
        // should log this in sentry or something else here.
        console.log('Found a non number in validateRow');
        console.log('expectedValue', expectedValue);
        console.log('cv', cv);
        cell.flag = true; // flag since something is wrong, but don't know what
        cell.flagReason = 'Validation or value is not a number';
        cell.offBy = 0.0;
      } else {
        if (expectedValue.toFixed(2) !== cv.toFixed(2)) {
          const offBy = cv - expectedValue;
          cell.flag = true;
          cell.flagReason = `Off by ${offBy.toFixed(2)}`;
          cell.offBy = offBy;
        } else {
          cell.flag = false;
          cell.flagReason = '';
          cell.offBy = 0.0;
        }
      }
    })
  }

  /**
   * Initializes a row with the correct number of cells based on the
   * current column count
   * @param row
   */
  private initializeRow(row: Row) {
    row.cells = new Array<Cell>();
    this.columns.forEach(_ => {
      const cell: Cell = {
        value: null
      };
      row.cells.push(cell);
    });
  }

  /**
   * Evaluates a row's formula given other calculated values
   * @param row
   * @param isDASRow
   */
  private evaluateRowFormula(row: Row, isDASRow=false) {
    row.cells.forEach((c, idx) => {
      let value = 0;
      const scope = this.equationScopeForColumn(idx);
      const equation = (row.formula.startsWith('=')) ? row.formula.substr(1) : row.formula;
      if(!equation.toLowerCase().includes('PRIOR('.toLowerCase())) { // if PRIOR not in equation
        try {
          value = math.evaluate(equation, scope);
        } catch (e){
          console.error('failed to eval equation: ', equation, scope)
        }
      }
      c.itemRowValue = value;
      c.value = value;
      if (isDASRow) {
        c.rawText = `${value}`
      }
    });
  }

  private calculateRollupsForRow(row: Row) {
    // If "validation only", don't roll this up to calculate the actual cell value
    const rollupCoefficients = row.children.map(c => c.rollupCoefficient);
    row.cells.forEach((cell, idx) => {
      const sourceCells = [];
      const childCells = row.children.map(c => {
        const cs = Object.assign({}, c.cells[idx]);
        if (c.invertValue === true) {
          cs.value = (cs.value ? cs.value : 0.0) * -1;
        }
        if (!c.template) {
          sourceCells.push(cs);
        }
        return cs;
      });
      // const
      cell.itemRowValue = this.rollupSum(rollupCoefficients, sourceCells);
      cell.value = this.rollupSum(rollupCoefficients, childCells);
    });
  }

  private equationScopeForColumn(idx: number) {
    const scope = {};
    Object.keys(this._calculatorRef).forEach(k => {
      scope[k] = this._calculatorRef[k][idx];
    });

    return scope;
  }

  private rollupSum(rowRollupCoefficients: Array<number>, cells: Array<Cell>): number {
    if (cells.length === 0) {
      return 0.0;
    }
    return cells.map((cell, idx) => ((cell && cell.value) ? cell.value : 0.0) * rowRollupCoefficients[idx]).reduce((a, b) => a + b);
  }

  private categorizeRow(categorization: Categorization) {
    if (categorization.templateLineItemId === undefined) {
      return;
    }

    // Set the properties on the row so we can display its categorization information correctly
    categorization.sourceRow.categorizedTo = categorization.templateLineItemId;
    if (categorization.isValidation) {
      categorization.sourceRow.categorizationType = 'validation';
      // if unlabeled - take label from validation item
      if (categorization.sourceRow.label === UNLABELED_ROW_PREFIX) {
        categorization.sourceRow.label = categorization.templateLineItemLabel;
      }
      // go remove any other rows validated to the same item
      this.sourceRows.forEach(sourceRow => {
        if (categorization.templateLineItemId === sourceRow.categorizedTo && sourceRow.categorizationType === 'validation' && sourceRow.uuid !== categorization.sourceRow.uuid) {
          this.clearCategorization(sourceRow);
        }
      })
    } else {
      if (categorization.templateLineItemId > 0) {
        categorization.sourceRow.categorizationType = 'component';
      } else {
        categorization.sourceRow.categorizationType = null;
      }
    }

    categorization.sourceRow.categorizationLabel = categorization.templateLineItemLabel;

    // If we had suggested changes to this row and the user updated it, remove suggestion flag
    if (categorization.sourceRow.suggestedChange) {
      this.clearGoalSeekSuggestion(categorization.sourceRow);
    }

    // Row has been (re) categorized - remove all flags
    categorization.sourceRow.cells.forEach(c => {
      c.flag = false;
      c.flagReason = '';
    });

    this.rows.forEach(templateRow => {
      searchAndDestroy(templateRow, categorization.sourceRow);
    });

    // Now add it to the right spot

    this.rows.some(templateRow => {
      const res = searchAndAdd(templateRow, categorization.templateLineItemId, categorization.isValidation, categorization.sourceRow, [templateRow]);
      if (res[0]) {
        this.lastCategorizationPath = res[1];
        return true;
      }
      return false;
    });
  }
}
