import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Digitization } from '@components/main/spreading/models/digitization';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DigitizationService {

  private _digitization$: BehaviorSubject<Digitization> = new BehaviorSubject<Digitization>(null);
  private _digitization: Digitization;

  constructor(
    private apiService: ApiService
  ) {

  }

  get digitization$(): Observable<Digitization> {
    return this._digitization$.asObservable();
  }

  get digitization(): Digitization {
    return this._digitization;
  }

  get(
    documentFileId: number,
    reviewQueueItemId: string
  ): Observable<Digitization> {
    return this.apiService
      .send('GET', `/api/digitization/${documentFileId}/${reviewQueueItemId}`)
      .pipe(
        tap(digitization => {
          this._digitization = digitization.response.objects[0];
          this._digitization$.next(this._digitization);
        }),
        map(_ => this._digitization)
      );
  }

  updateProjectionName(
    id: number,
    projectionName: string
  ): Observable<Digitization> {
    return this.apiService
      .send('PUT', `/api/digitization/${id}/update-projection-name`,  {projectionName})
      .pipe(
        tap(_ => {
          this._digitization.projectionName = projectionName;
          this._digitization$.next(this._digitization);
        }),
        map(_ => this._digitization)
      );
  }
}
