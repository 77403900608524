import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { catchError, map } from 'rxjs/operators';
import { DataViewRuleType, ResponseTypes } from '../utils/enums';
import { DataView } from '../models/dataview';
import { saveAs as importedSaveAs } from 'file-saver';
import { DataViewConfig } from '../models/dataviewconfig';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable()
export class DataViewService {

  requestForDataViewToBeSaved: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService) {
  }

  /**
   * @deprecated
   */
  getDataView(companyId: number, doc: string, isRaw: boolean = false, isCompliance: boolean = false) {
    const data = {
      filter: {
        company_id_eq: companyId,
        generator__document_type_eq: doc.toUpperCase(),
        generator__raw_eq: isRaw,
      }

    };

    return this.apiService.send('Post', `/api/dataviews/all`, data).pipe(
      map(data => {
        if (data.response.objects.length > 0) {
          return data.response.objects[0]
        }
        return null;
      })
    );
  }

  updateDataViewRule(id: number, ruleType: DataViewRuleType, reportingPeriod: string, lineItemId: number, equation: string) {
    const data = {
      equation,
      reportingPeriod,
      lineItemId,
      ruleType
    };

    return this.apiService.send('Patch', `/api/dataviews/${id}/rules`, data).pipe(
      map(data => data.response.objects));
  }

  /**
   * Retrieve a single calculated data view by ID.
   *
   * Relative data is calculated according to the specified
   * original reporting period
   */
  readDataView(
    dataViewId: number,
    companyId: number,
    originalReportingPeriod: string|null = null,
    showSourceItems: boolean = false,
    dataViewConfig: DataViewConfig|null = null,
    forStatementBuilder: boolean = false,
  ) {
    return this.apiService.send(
      'Post',
      `/api/v2/dataviews/${dataViewId}/view`,
      {
        'reporting_period': originalReportingPeriod,
        'company_id': companyId,
        'show_source_items': showSourceItems,
        'data_view_config': dataViewConfig,
        'for_statement_builder': forStatementBuilder
      }
    ).pipe(
      map(data =>
        new DataView().deserialize(data.response.objects[0]))
    );
  }


  readDataViewByUuid(dataViewUuid:string) {
    const payload = {
      filter: {
        uuid_eq: dataViewUuid
      }
    };

    const data = this.apiService.send('POST', `api/v2/dataviews/uuid/${dataViewUuid}/view`, payload).pipe(map(data => data.response.objects.map(obj => new DataView().deserialize(obj))));
    return data;
  }


  requestDataViewSave(): void {
    this.requestForDataViewToBeSaved.next(true);
  }

  save(dataView: DataView) {
    this.requestForDataViewToBeSaved.next(false);
    const dataViewId = dataView.id;
    return this.apiService
      .send('Patch', `/api/v2/dataviews/${dataViewId}`, dataView)
      .pipe(
        map(data => data.response.objects)
      )
  }

  readDataViewConfig(data_view_id: number, company_id: number) {
    const data = {
      filter: {
        data_view_id_eq: data_view_id,
        company_id_eq: company_id,
      }
    };
    return this.apiService.send('Post', `/api/v2/dataviewconfig`, data)
                          .pipe(map(data => data.response.objects[0][0]));
  }

  updateDataViewConfig(id: number, dvc: DataViewConfig) {
    return this.apiService.send('Patch', `/api/v2/dataviewconfig/${id}`, dvc)
      .pipe(map(data => data.response.objects));
  }

  readDataViewAsSpreadsheet(dataViewId: number, companyId: number,
    originalReportingPeriod: string|null = null, docName: string|null = 'statement.xlsx') {
    return this.apiService.send('Post', `/api/v2/dataviews/${dataViewId}/spreadsheet`, {
      'reporting_period': originalReportingPeriod,
      'company_id': companyId,
    }).pipe(map(data => {
      const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      importedSaveAs(blob, docName);
    }));
  }

  readDataViewAsPDF(dataViewId: number, companyId: number,
    originalReportingPeriod: string|null = null, docName: string|null = 'document.pdf') {
    return this.apiService.send('Post', `/api/v2/dataviews/${dataViewId}/pdf`, {
      'reporting_period': originalReportingPeriod,
      'company_id': companyId,
    }, ResponseTypes.Blob ).pipe(map(data => {
      const blob = new Blob([data.body], { type: 'application/pdf' });
      importedSaveAs(blob, docName);
    }), catchError(error => {
      return throwError(() => new Error('Error in readDataViewAsPDF: ' + error.message));
    }));
  }

   exportExcelReferenceSheet(dataViewId: number, companyId: number, docName: string|null = 'statement.xlsx') {
    return this.apiService.send('Post', `/api/v2/dataviews/${dataViewId}/spreading-template-associated-excel-export`, {
      'company_id': companyId,
    }).pipe(map(data => {
      const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      importedSaveAs(blob, docName);
    }));
  }

  /**
   * Fetches a list of (uncalculated) dataviews for the given company
   */
  listDataViews(companyId: number = null, spreadingTemplateId: number = null, chainFilterParameters: boolean = false) {
    const payload = {
      'chain_filter_parameters': chainFilterParameters,
      'filter': {}
    };

    if (spreadingTemplateId) {
      payload['filter']['spreading_template_id_eq'] = spreadingTemplateId;
    } else {
      payload['filter']['templated_eq'] = true;
    }

    if (companyId) {
      payload['filter']['company_id_eq'] = companyId;
    }

    return this.apiService.send('POST', '/api/v2/dataviews/all', payload).pipe(map(data => data.response.objects.map(obj => new DataView().deserialize(obj))));
  }
}
