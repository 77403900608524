import { Injectable } from '@angular/core';
import { SegmentService } from 'ngx-segment-analytics';
import { LoggingService, Logger } from './logging.service';

// this interface specifies the data required to track
// a human-in-loop event
export interface HumanInLoopEventData {
  step: string,
  type: string,
  documentFileId: string,
  documentCompanyId: number,
  documentTenantId: string,
  footnoteId?: string
}

// this interface specifies the data required to track
// a background process event. for example: when a document is uploaded
export interface BackgroundEvent {
  eventName: string,
  type: string,
  tenantId: string,
  companyId?: number,
  additionalTags?: object,
  additionalFields?: object
}

@Injectable()
export class TrackingService {
  logger: Logger;

  constructor(
    private _segmentService: SegmentService,
    private _loggingService: LoggingService
    ) {
    this.logger = this._loggingService.rootLogger.newLogger('TrackingService');
  }

  identify(userId: string, traits: any): void {
    this._segmentService.identify(userId, traits);
  }

  track(eventName: string, eventDetails: object): void {
    this._segmentService.track(eventName, eventDetails);
  }

  // track a human going through the human-in-loop steps of the document
  // parsing process.
  trackHumanInLoop(eventData: HumanInLoopEventData): void {
    try {
      if (! ['Start', 'Save', 'End'].includes(eventData.type)) {
        throw new Error('Incorrect type "' + eventData.type + '" specified for human-in-loop event')
      }
      if (! ['Manual Review', 'Footnote', 'Spreading', 'Table Identification', 'Column Identification'].includes(eventData.step)) {
        throw new Error('Incorrect step "' + eventData.step + '" specified for human-in-loop event')
      }

      this.logger.info('Sending Human In Loop Event', {eventData: eventData})
      this.track('HumanInLoop', {
        type: eventData.type,
        step: eventData.step,
        metadata: {
          documentFileId: eventData.documentFileId,
          documentCompanyId: eventData.documentCompanyId,
          documentTenantId: eventData.documentTenantId,
          footnoteId: eventData.footnoteId
        }});
    } catch (error) {
      this.logger.error('Error creating/sending a HumanInLoop event: ' + error.message, {error_obj: error});
    }
  }

// log start and stop (or just an event) of some background process/task
  trackBackgroundEvent(backgroundEvent: BackgroundEvent): void {
    try {
      if (! ['Event',   'Start',   'End'].includes(backgroundEvent.type)) {
        throw new Error('Incorrect type "' + backgroundEvent.type + '" specified for background event tracking')
      }
      if (! ['Document Upload'].includes(backgroundEvent.eventName)) {
        throw new Error('Incorrect step "' + backgroundEvent.eventName + '" specified for background event tracking')
      }

      if (backgroundEvent.eventName === 'Document Upload') {
        if (backgroundEvent.companyId === undefined || backgroundEvent.companyId === null) {
          throw new Error('The "' + backgroundEvent.eventName + '" background event must specify the companyId. Got "' + backgroundEvent.companyId + '"') };
        if (! (backgroundEvent.additionalTags.hasOwnProperty('documentId'))) {
          throw new Error('The Document Upload event must have a "documentId" tag specified. Got tags: ' + JSON.stringify(backgroundEvent.additionalTags)) };
      }

      this.track('BackgroundEvent', {
        type: backgroundEvent.type,
        eventName: backgroundEvent.eventName,
        metadata: {
          documentCompanyId: backgroundEvent.companyId,
          documentTenantId: backgroundEvent.tenantId,
        },
        additionalTags: backgroundEvent.additionalTags,
        additionalFields: backgroundEvent.additionalFields
        });

    } catch (error) {
      this.logger.error('Error creating/sending a HumanInLoop event', {'errorObject': error});
    }
  }

}
