<div class="page-content-container">
    <div class="top-header">
        <app-navigation-stepper
            [steps]="navigationSteps"
            [currentStepIndex]="0"
            [enforceLinearProgression]="true"
        ></app-navigation-stepper>
        <div class="action-container">
            <button class="btn btn-outline-secondary" type="button" (click)="exitClicked.emit()">
                <span class="size">Cancel</span>
            </button>
            <div class="btn-separator">|</div>
            <button class="btn btn-primary" type="button" (click)="nextClicked.emit()">
                <span class="size">Next</span>
            </button>
        </div>

    </div>
    <app-table-drawing-page
        [allowOnlyOneBox]="true"
        [pageImages]="documentFile?.pageImages"
        [currentBoxesByPage]="currentBoxesByPage"
        (boxesChanged)="boxesChanged($event)"
    ></app-table-drawing-page>
</div>
