import { Injectable } from '@angular/core';
import { Row, Suggestion } from '../components/main/spreading/models/models';

function k_combinations(set, k) {
    let i, j, combs, head, tailcombs;

    // There is no way to take e.g. sets of 5 elements from
    // a set of 4.
    if (k > set.length || k <= 0) {
        return [];
    }

    // K-sized set has only one K-sized subset.
    if (k === set.length) {
        return [set];
    }

    // There is N 1-sized subsets in a N-sized set.
    if (k === 1) {
        combs = [];
        for (i = 0; i < set.length; i++) {
            combs.push([set[i]]);
        }
        return combs;
    }

    // Assert {1 < k < set.length}

    // Algorithm description:
    // To get k-combinations of a set, we want to join each element
    // with all (k-1)-combinations of the other elements. The set of
    // these k-sized sets would be the desired result. However, as we
    // represent sets with lists, we need to take duplicates into
    // account. To avoid producing duplicates and also unnecessary
    // computing, we use the following approach: each element i
    // divides the list into three: the preceding elements, the
    // current element i, and the subsequent elements. For the first
    // element, the list of preceding elements is empty. For element i,
    // we compute the (k-1)-computations of the subsequent elements,
    // join each with the element i, and store the joined to the set of
    // computed k-combinations. We do not need to take the preceding
    // elements into account, because they have already been the i:th
    // element so they are already computed and stored. When the length
    // of the subsequent list drops below (k-1), we cannot find any
    // (k-1)-combs, hence the upper limit for the iteration:
    combs = [];
    for (i = 0; i < set.length - k + 1; i++) {
        // head is a list that includes only our current element.
        head = set.slice(i, i + 1);
        // We take smaller combinations from the subsequent elements
        tailcombs = k_combinations(set.slice(i + 1), k - 1);
        // For each (k-1)-combination we join it with the current
        // and store it to the set of k-combinations.
        for (j = 0; j < tailcombs.length; j++) {
            combs.push(head.concat(tailcombs[j]));
        }
    }
    return combs;
}

function nChooseK(n, k) {
    let result = 1;
    for (let i = 1; i <= k; i++) {
        result *= (n + 1 - i) / i;
    }
    return result;
}

function getCombinationLimit(numberOfOperations: number, strictLimitTotalCombinations: number, strictLimit: number) {
    let totalCombinations = 0;
    let i = 1;
    for (i = 1; i < strictLimit; i++) {
        totalCombinations = totalCombinations + nChooseK(numberOfOperations, i);
        if (totalCombinations > strictLimitTotalCombinations) {
            return i;
        }
    }
    return i;
}

@Injectable()
export class GoalSeekService {

    // how close do we need to get before the difference is "solved"
    sumTolerance = 1.0;
    // strict limit on the number of calculations we can check
    strictLimitTotalCombinations = 5000000;
    // strict limit on the number of line items we can change
    strictMaxOperationLength = 6;

    solveGoalSeek(rows: Array<Row>, offBy: number, columnIdx: number) {
        const suggestions = this.generateSuggestions(rows, columnIdx);
        const maxOperations = getCombinationLimit(suggestions.length, this.strictLimitTotalCombinations, this.strictMaxOperationLength);
        const solution = this.subsetSumSolver(suggestions, offBy, maxOperations);
        return solution;
    }

    subsetSumSolver(suggestions: Array<Suggestion>, targetSum: number, maxOperations: number) {
        for (let i = 1; i < maxOperations; i++) {
            const combinations = k_combinations(suggestions, i)
            for (let j = 0; j < combinations.length; j++) {
                const combo = combinations[j]
                const comboSums = combo.map(c => c.effect).reduce((prev, next) => prev + next);
                if (Math.abs(comboSums - targetSum) <= this.sumTolerance && this.isValidCombination(combo)) {
                    return combo;
                };
            }
        }
        return false;
    }

    isValidCombination(combination: Array<Suggestion>) {
        let valid = true;
        combination.forEach(c => {
            // cant uncategorize and invert something
            if (c.operation === 'uncategorize') {
                combination.forEach(c2 => {
                    if (c2.operation === 'invert' && c.lineItemId === c2.lineItemId) {
                        valid = false;
                    }
                })
            }
        })
        return valid;
    }

    generateSuggestions(rows: Array<Row>, columnIdx: number) {
        const suggestions = new Array<Suggestion>();
        rows.forEach(r => {
            if (!isNaN(r.cells[columnIdx].value) && r.goalSeekCoefficient !== 0) {
                // if a row is categorized already, we can either categorize it or invert it
                if (r.categorizedTo > 0) {
                    const invertCoefficient = r.invertValue ? -1 : 1;
                    if (r.categorizedTo > 0) {
                        const invertSuggestion: Suggestion = {
                            lineItemId: r.lineItemId,
                            operation: 'invert',
                            effect: r.cells[columnIdx].value * -2 * r.goalSeekCoefficient * invertCoefficient
                        };
                        suggestions.push(invertSuggestion);
                        const uncategorizeSuggestion: Suggestion = {
                            lineItemId: r.lineItemId,
                            operation: 'uncategorize',
                            effect: r.cells[columnIdx].value * -1 * r.goalSeekCoefficient * invertCoefficient
                        };
                        suggestions.push(uncategorizeSuggestion);
                    }
                } else if (r.categorizedTo === 0) {
                    // if a row hasnt been categorized yet, we can categorize it or categorize + invert it
                    const categorizeSuggestion: Suggestion = {
                        lineItemId: r.lineItemId,
                        operation: 'categorize',
                        effect: r.cells[columnIdx].value * r.goalSeekCoefficient
                    };
                    suggestions.push(categorizeSuggestion);
                    const categorizeInvertSuggestion: Suggestion = {
                        lineItemId: r.lineItemId,
                        operation: 'categorize_invert',
                        effect: r.cells[columnIdx].value * -1 * r.goalSeekCoefficient
                    };
                    suggestions.push(categorizeInvertSuggestion);
                }
            }});
        return suggestions;
    }
}
