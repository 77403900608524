import { Pipe, PipeTransform } from '@angular/core';
import { EXT_TO_ICON } from '../utils/constants';

@Pipe({name: 'docIcon'})
export class DocIconPipe implements PipeTransform {
  /**
   *
   * @param {string} value
   * @returns {string}
   */
  transform(value: string): string {
    return value ? EXT_TO_ICON[value.split('.').pop().toLowerCase()] : '';
  }
}
