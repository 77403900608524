import { Component, OnInit, Input } from '@angular/core';
import { Router } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-locked-takeover',
  templateUrl: './locked-takeover.component.html',
  styleUrls: ['./locked-takeover.component.scss']
})
export class LockedTakeoverComponent implements OnInit {
  @Input() lastLockedBy = '';

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
  }

}
