import { Component, OnInit, Input } from '@angular/core';
import { GraphService } from '../../../../../../services/graph.service';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-net-worth-graph-tile',
  templateUrl: './net-worth-graph-tile.component.html',
  styleUrls: ['./net-worth-graph-tile.component.scss']
})
export class NetWorthGraphTileComponent implements OnInit {
  percentChange = '';
  value = '';
  netWorthGraphData = [];

  @Input() mostRecentStatementDate = '';
  @Input() analyticsData;
  @Input() periodType = '';
  @Input() currencySymbol = '';

  constructor(
    private _graphService: GraphService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit() {
    this.netWorthGraphData = this._graphService.barChartDataStructure(
      this.analyticsService.getDataByLineItem('REF_NET_WORTH_ACTUAL', this.analyticsData),
      this.analyticsData['periods'],
    );

    this.value = this.analyticsService.formattedValue('REF_NET_WORTH_ACTUAL', this.analyticsData);
  }

}
