import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { STD_ID_MAPPER, EMPTY_ANALYTICS_ITEM } from '../utils/constants';
import { FinancialsService } from './financials.service';
import { AnalyticsItem } from '../models/analytics-item';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private apiService: ApiService,
    private financialsService: FinancialsService,
  ) { }

  getAvailableIntervals(companyId: number): Observable<any> {
    const url = `/api/companies/${companyId}/analytics/reporting_intervals`;
    return this.apiService.send('Get', url).pipe(map(data => data.response.objects));
  }

  getAnalyticsData(companyId: number, reportingInterval: string): Observable<any> {
    // reporing interview: 'MONTHLY', 'ANNUALLY', etc...
    const url = `/api/companies/${companyId}/analytics/${reportingInterval}`;
    return this.apiService.send('Get', url).pipe(map(data => data.response.objects));
  }

  formatAnalyticsData(response: any): any {
    const statementDates = response.map( (column) => column.statementDate );
    const periods = response.map( (column) => column.statementDate );
    const values = {};

    response.forEach(periodData => {
      // periodData = each specific object
      periodData.items.forEach(periodSpecificItem => {
        const lineItemId = periodSpecificItem.lineItem.id;
        const valueType = periodSpecificItem.lineItem.valueType;

        if (lineItemId in values) { // key exists, need to add
          values[lineItemId].push( {
            value: periodSpecificItem.value,
            formattedValue: periodSpecificItem.formattedValue,
            reportingPeriod: periodData.reportingPeriod,
            valueType: valueType,
          } );
        } else { // key doesn't exist yet, need to add it for this 1st time through
          values[lineItemId] = [ {
            value: periodSpecificItem.value,
            formattedValue: periodSpecificItem.formattedValue,
            reportingPeriod: periodData.reportingPeriod,
            valueType: valueType,
          } ];
        }
      });
    });

    return {
      periods: periods,
      statementDates: statementDates,
      values: values,
    };
  }

  getDataByLineItem(lineItemRef: string, analyticsData: {periods: Array<string>, statementDates: Array<string>, values: any }): Array<AnalyticsItem> {
    if (!analyticsData) {
      return [EMPTY_ANALYTICS_ITEM];
    }

    if (! (lineItemRef in STD_ID_MAPPER)) {
      return analyticsData.periods.map( (p) => EMPTY_ANALYTICS_ITEM ); // return empty item for each period
    }

    const lineItemId = STD_ID_MAPPER[lineItemRef];
    if (! (lineItemId in analyticsData.values)) {
      return analyticsData.periods.map( (p) => EMPTY_ANALYTICS_ITEM ); // empty item for each period
    }

    return analyticsData.values[lineItemId];
  }

  arrayOfValues(lineItemRef: string, analyticsData: any): any {
    const array = this.getDataByLineItem(lineItemRef, analyticsData);
    const arrayOfValues = array.map(dataFrameValue => dataFrameValue.value);
    return arrayOfValues;
  }

  // needs a better name // maybe like, latestValueFormatted
  formattedValue(lineItemRef = '', analyticsData: any) {
    if (analyticsData === null || analyticsData === undefined || lineItemRef === '' || lineItemRef === null || lineItemRef === undefined) {
      return '#ERR';
    }
    const arrayOfCalculatedValues = this.getDataByLineItem(lineItemRef, analyticsData);

    const lastItemKey = arrayOfCalculatedValues.length - 1;

    const value = arrayOfCalculatedValues[lastItemKey].value;
    const format = arrayOfCalculatedValues[lastItemKey].valueType;

    // @ts-ignore will need to update types in near futureff
    return this.financialsService.formatValue(value, format, { showDollarSign: false });
  }

  mostRecentValue(analyticsItemValues: Array<AnalyticsItem>): number {
    if (!this.hasData(analyticsItemValues)) {
      return -1;
    }

    const lastItemKey = analyticsItemValues.length - 1;

    if (analyticsItemValues[lastItemKey] === null) {
      return -1;
    }

    if (! ('value' in analyticsItemValues[lastItemKey])) {
      return -1;
    }
    return analyticsItemValues[lastItemKey]['value'];
  }

  hasMostRecentPeriodItem(analyticsItemValues: Array<AnalyticsItem>): boolean {
    const mostRecentItem = this.mostRecentValue(analyticsItemValues);
    if (mostRecentItem === null || mostRecentItem === undefined || mostRecentItem === -1) {
      return false;
    }
    return true;
  }

  mostRecentAnalyticsItem(analyticsItemValues: Array<AnalyticsItem>): AnalyticsItem {
    if (!this.hasData(analyticsItemValues)) {
      return EMPTY_ANALYTICS_ITEM;
    }
    const arrayOfCalculatedValues = analyticsItemValues;
    const lastItemKey = arrayOfCalculatedValues.length - 1;
    return arrayOfCalculatedValues[lastItemKey];
  }

  formattedRecentValue(analyticsItemValues: Array<AnalyticsItem>): string {
    if (!this.hasData(analyticsItemValues)) {
      return '';
    }
    const mostRecentAnalyticsItem = this.mostRecentAnalyticsItem(analyticsItemValues);

    // @ts-ignore will need to update types in near future
    return this.financialsService.formatValue(mostRecentAnalyticsItem.value, mostRecentAnalyticsItem.valueType);
  }

  penultimateValue(analyticsItemValues: Array<AnalyticsItem>): number {
    if (!this.hasTwoPeriodsOfData(analyticsItemValues)) {
      return -1; // not sure how to handle this scenario yet
    }

    const secondToLastItemKey = analyticsItemValues.length - 2;

    if (analyticsItemValues[secondToLastItemKey] === null) {
      return -1;
    }

    if (! ('value' in analyticsItemValues[secondToLastItemKey])) {
      return -1;
    }

    return analyticsItemValues[secondToLastItemKey]['value'];
  }

  formattedPopChange(analyticsItemValues: Array<AnalyticsItem>): string {
    if (!this.hasTwoPeriodsOfData(analyticsItemValues)) {
      return '';
    }

    const mostRecent = this.mostRecentValue(analyticsItemValues);
    const penultimate = this.penultimateValue(analyticsItemValues);

    if (mostRecent === -1 || penultimate === -1 || penultimate === 0 || penultimate == null) {
      return '';
    }


    const unformattedValue = ( (mostRecent - penultimate) / penultimate ) * 100;
    return `${unformattedValue.toFixed(2)}% PoP`;
  }

  hasTwoPeriodsOfData(analyticsItemValues: Array<AnalyticsItem>): boolean {
    if (analyticsItemValues === null) {
      return false;
    }

    if (analyticsItemValues[0] && 'value' in analyticsItemValues[0]) { // small check to make sure not a random array value
      return analyticsItemValues.length > 1;
    }
    return false;
  }

  hasData(analyticsItemValues: Array<AnalyticsItem>): boolean {
    if (analyticsItemValues === null) {
      return false;
    }
    if (analyticsItemValues[0] && 'value' in analyticsItemValues[0]) { // small check to make sure not a random array value
      return analyticsItemValues.length > 0;
    }
    return false;
  }
}
