import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Company } from '../../../../../models/company';
import { LinkedEntityCompany } from '../../../../../models/linked-entity-company';
import { FiscalYearEndChange } from '../../../../../models/fiscal-year-end-change';
import { NgxPopupService } from '../../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { ConfirmationPopupComponent } from '../../../../shared/popups/confirmation/confirmation-popup.component';
import { AddFyeChangeComponent } from '../../../../shared/popups/add-fye-change/add-fye-change.component';
import { AlertService } from '../../../../../services/alert.service';
import { ApiService } from '../../../../../services/api.service';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { BorrowerService } from '../../../../../services/borrower.service';
import {
  COMPANY_PAGE_VIEW_MODE,
  DIGIT_PATTERN,
  EMAIL_PATTERN,
  PHONE_MASK,
  PHONE_PATTERN,
  TEXT_PATTERN,
  COMPANY_TABS,
  COMPANY_ENTITLEMENT_DATA,
  SUPPORTED_CURRENCIES,
} from '../../../../../utils/constants';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { PortfolioService } from '../../../../../services/portfolio.service';
import { Borrower } from '@services/fincura-ng-client';
import { LaunchDarklyService } from '@services/launchdarkly.service';
import { CustomAttributeDefinition } from '@models/custom-attribute-definition';
import { CustomAttributeDefinitionService } from '@services/custom-attribute-definition.service';
import { CommonFunctions } from '@utils/common-functions';
import { CreateInviteLinkComponent } from '@components/main/borrower/accounting-integration/create-invite-link/create-invite-link.component';
import { LinkedEntitiesService } from '@services/linked-entities.service';
import {UserService} from "@services/user.service";
import {BankSettingsService} from "@services/bank-settings.service";

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class CompanyProfileComponent implements OnInit {
  company: Company;
  fiscalYearEndChanges: FiscalYearEndChange[] = [];
  subsArr$: Subscription[] = [];
  isEditing = false;
  sicArr: any[] = [];
  countryCodeArr: any[] = [];
  naicsArr: any[] = [];
  portfoliosArr: any[] = [];
  companyForm: FormGroup;
  submitted = false;
  submitAttempt = false;
  phoneMask = PHONE_MASK;
  roundToThousandsDisplayName: String;
  currencyDisplayName: String;
  showSicIndustry = false;
  showAccountingIntegrations = true;
  sicIndustries = Borrower.SicIndustryEnum;
  linkedEntities: Array<LinkedEntityCompany> = [];
  beginningLinkedEntities: Array<LinkedEntityCompany> = [];
  linkedEntitiesStringDisplay = '';
  areLinkedEntitiesShown = false;
  showNewRelatedEntitySearch = false;

  customAttrDefinitions: CustomAttributeDefinition[] = [];
  customAttributesValues = {};
  invalidFields = {};
  showLinkedEntitiesFlag = false;

  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS;
  canEditPage = false;
  canViewPage = false;
  noAccessErrorText = "You don't have enough permissions to view this page.";
  routeData: Data;

  customerIdLabel: string = "Customer Id";
  canEditCustomerId = false;
  supportedCurrencies = SUPPORTED_CURRENCIES;

  constructor(private _sharedDataService: SharedDataService,
              public borrowerService: BorrowerService,
              private _formBuilder: FormBuilder,
              private _alertService: AlertService,
              private _apiService: ApiService,
              private _sanitizer: DomSanitizer,
              private _popupService: NgxPopupService,
              private _portfolioService: PortfolioService,
              private _featureFlags: LaunchDarklyService,
              private _customAttributeDefinitionService: CustomAttributeDefinitionService,
              private _linkedEntitiesService: LinkedEntitiesService,
              private _route: ActivatedRoute,
              private _userService: UserService,
              private _bankSettingsService: BankSettingsService
  ) {
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.pageViewMode = this.routeData.companyEntitlement[COMPANY_TABS.PROFILE][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];
    this.canViewPage = [COMPANY_PAGE_VIEW_MODE.FULL, COMPANY_PAGE_VIEW_MODE.VIEW_ONLY].includes(this.pageViewMode);
    if (this.canViewPage) {
      this.subsArr$.push(this._bankSettingsService.getCustomerIdRelatedBankSettings().subscribe(data => {
        if (data.customerIdLabelOverride) {
          this.customerIdLabel = data.customerIdLabelOverride
        }
      }))
      this.subsArr$.push(this._sharedDataService.company$.subscribe((company: Company) => {
        this.company = company
        if (company) {
          this.getCountryData();
          this.getSICData();
          this.getNAICSData();
          this.getFYEData();
          this.getPortfolioData();
          this.setRoundToThousandsDisplayName(this.company.settings['roundToThousands']);
          this.setCurrencyDisplayName(this.company.settings['currency']);
          this.subsArr$.push(this._customAttributeDefinitionService.getCustomAttributesForModel('Company').subscribe(customAttributeDefinitions => {
            this.customAttrDefinitions = customAttributeDefinitions;
            this.customAttrDefinitions.forEach( (definition) => {
              // @ts-ignore
              this.customAttributesValues[CommonFunctions.camelToSnake(definition.key)] = company._customAttributes[CommonFunctions.snakeToCamel(definition.key)];
            });
          }));

          this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
            this.setFlags();
          }));
          this.setFlags();
          if (this.showLinkedEntitiesFlag) {
            this.getLinkedEntities();
          }
          this.canEditPage = this.isPageEditable();
        }
      }));
    }
  }

  getSICData(): void {
    this.subsArr$.push(this._apiService.getFile('/assets/files/sic_codes.json').subscribe(data => {
      this.sicArr = data;
      const sicIndex = this.sicArr.findIndex(s => s['code'] === this.company.sicCode);
      this.company.sicName = sicIndex > -1 ? this.sicArr[sicIndex].title.toUpperCase() : '';
      this.initForm();
    }));
  }

  getCountryData(): void {
    this.subsArr$.push(this._apiService.getFile('/assets/files/country_codes.json').subscribe(data => {
      this.countryCodeArr = data;
      this.company.countryName = this.getCountryNameFromCode(this.company.country, this.countryCodeArr);
    }));
  };

  getCountryNameFromCode(countryCode, countryArr){
    if (!countryCode){
      return null
    }
    const companyObj = countryArr.find(object => object.code == countryCode);
    return companyObj?.name
  }

  getNAICSData(): void {
    if (!this.company) {
      return;
    }

    this.subsArr$.push(this._apiService.getFile('/assets/files/naics-sic-mappings.json').subscribe(data => {
      this.naicsArr = data;
      const naicsIndex = this.naicsArr.findIndex(s => s['NAICS'] === this.company.naicsCode);
      this.company.naicsName = naicsIndex > -1 ? this.naicsArr[naicsIndex]['NAICS Title'].toUpperCase() : '';
      this.initForm();
    }));
  }

  getFYEData(): void {
    this.subsArr$.push(this.borrowerService.getCompanyFYEChanges(this.company).subscribe(data => {
      this.fiscalYearEndChanges = data;
    }));
  }

  getPortfolioData() {
    this.subsArr$.push(this._portfolioService.getAllPortfolios().subscribe(data => {
      this.portfoliosArr = data;
    }));
  }

  getLinkedEntities(): void {
    this.subsArr$.push(this._linkedEntitiesService.getLinkedEntities(this.company.uuid).subscribe(linkedEntities => {
      this.linkedEntities = [];
      this.beginningLinkedEntities = [];

      linkedEntities.forEach(value => {
        this.linkedEntities.push({uuid: value.linkedEntity.uuid, name: value.linkedEntity.name});
        this.beginningLinkedEntities.push({uuid: value.linkedEntity.uuid, name: value.linkedEntity.name});
      });

      this.areLinkedEntitiesShown = !!this.linkedEntities.length;

      this.linkedEntitiesStringDisplay = this.generateLinkedEntitiesStringDisplay();
    }, error => {
      this._alertService.error(error.message);
    }));
  }

  setRoundToThousandsDisplayName(dbval) {
    if (dbval === true) {
      this.roundToThousandsDisplayName = 'Yes';
    } else if (dbval === false) {
      this.roundToThousandsDisplayName = 'No';
    }
  }

  setCurrencyDisplayName(dbval) {
    let currency = SUPPORTED_CURRENCIES.find(currency => currency.key === dbval)
    this.currencyDisplayName = currency['name'] + ' (' + currency['symbol'] + ')';
  }

  /**
   * initing form
   */
  initForm(): void {
    this.companyForm = this._formBuilder.group({
      name: [this.company.name,
        [Validators.required, this.nospaceValidator, this.extraSpaceValidator, Validators.maxLength(150)]],
      email: [this.company.email,
        [Validators.maxLength(100), Validators.pattern(EMAIL_PATTERN)]],
      businessType: [this.company.businessType,
        [Validators.maxLength(100), Validators.pattern(TEXT_PATTERN)]],
      sicCode: [this.company.sicCode],
      naicsCode: [this.company.naicsCode],
      size: [this.company.size,
        [Validators.max(10000), Validators.min(1), Validators.pattern(DIGIT_PATTERN)]],
      portfolio: [this.company.portfolio ? this.company.portfolio.id : 0],
      webSite: [this.company.webSite,
        [Validators.maxLength(100)]],
      externalId: [this.company.externalId,
        [Validators.maxLength(100)]],
      address: [this.company.address,
        [Validators.maxLength(100)]],
      contactPerson: [this.company.contactPerson,
        [Validators.maxLength(100), Validators.pattern(/^[a-zA-Z'\-\s,.:;#@$%&'!*+—/=?^`{|}~]*$/)]],
      contactPhone: [this.company.contactPhone,
        [Validators.maxLength(100), Validators.pattern(PHONE_PATTERN)]],
      settings: this._formBuilder.group({
        roundToThousands: [this.company.settings['roundToThousands']],
        currency: [this.company.settings['currency']]
      }),
      sicIndustry: [this.company.sicIndustry],
      region: [this.company.region,
        [Validators.maxLength(75), Validators.pattern(TEXT_PATTERN)]],
      country: [this.company.country, [Validators.maxLength(75)]],
    });

    if (!this.canAssignBorrowerToPortfolio()){
      this.companyForm.controls.portfolio.disable()
    }
  }

  /**
   *
   * @param {boolean} reset
   */
  toggleEditing(reset: boolean = false): void {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.showNewRelatedEntitySearchAction();
    } else if (reset) {
      this.companyForm.reset({
          name: this.company.name,
          email: this.company.email,
          businessType: this.company.businessType,
          sicCode: this.company.sicCode,
          size: this.company.size,
          portfolio: this.company.portfolio ? this.company.portfolio.id : 0,
          webSite: this.company.webSite,
          address: this.company.address,
          contactPerson: this.company.contactPerson,
          contactPhone: this.company.contactPhone,
          settings: {
            roundToThousands: this.roundToThousandsDisplayName,
            currency: this.currencyDisplayName
          }
        },
      );
      this.resetLinkedEntities();
    }
  }

  resetLinkedEntities(): void {
    this.linkedEntities = this.beginningLinkedEntities;
    this.hideNewRelatedEntitySearchAction();
  }

  /**
   *
   * @param {string} field
   * @returns {boolean}
   */
  isFieldInvalid(field: string): boolean {
    return (
      (this.companyForm.get(field).invalid && this.companyForm.get(field).dirty) ||
      (this.companyForm.get(field).invalid && this.submitAttempt)
    );
  }

  /**
   *
   * @param {AbstractControl} control
   * @returns {{[p: string]: boolean}}
   */
  nospaceValidator(control: AbstractControl): { [s: string]: boolean } {
    if (control.value && !control.value.trim()) {
      return {nospace: true};
    }
  }

  /**
   *
   * @param {AbstractControl} control
   * @returns {{[p: string]: boolean}}
   */
  extraSpaceValidator(control: AbstractControl): { [s: string]: boolean } {
    const re = /(^\s|[\s]{2,})/;
    if (control.value && re.test(control.value)) {
      return {extraspace: true};
    }
  }

  customAttributeChange(attr, event) {
    const value = event.target.value;
    if (!!value) {
      delete this.invalidFields[attr.key];
      this.customAttributesValues[attr.key] = {
        key: attr.key,
        attribute_type: attr.attributeType,
        value
      }
    } else {
      this.invalidFields[attr.key] = true;
      delete this.customAttributesValues[attr.key];
    }
  }

  removeLinkedEntity(entityUuid: string): void {
    this.linkedEntities = this.linkedEntities.filter(prop => prop.uuid !== entityUuid);
  }

  chooseLinkedEntity(selectedEntity: { name: string, uuid: string }): void {
    if (selectedEntity && selectedEntity.uuid && selectedEntity.name) {
      this.linkedEntities.push({ uuid: selectedEntity.uuid, name: selectedEntity.name });
    }
  }

  generateLinkedEntitiesStringDisplay() {
    if (this.linkedEntities.length === 0) {
      return '';
    }

    const entityCompanyNamesOnly = this.linkedEntities.map(linkedEntity => linkedEntity.name);

    return entityCompanyNamesOnly.join(', ');
  }

  changeIfHaveLinkedEntities(value: string): void {
    if (value === '1') {
      this.areLinkedEntitiesShown = true;
    } else {
      this.areLinkedEntitiesShown = false;
    }
  }

  showNewRelatedEntitySearchAction() {
    this.showNewRelatedEntitySearch = true;
  }

  hideNewRelatedEntitySearchAction() {
    this.showNewRelatedEntitySearch = false;
  }

  /**
   *
   * @param companyForm
   * @param {boolean} isValid
   */
  save(companyForm: any, isValid: boolean) {
    this.submitAttempt = true;

    this.saveLinkedEntities();
    this.linkedEntitiesStringDisplay = this.generateLinkedEntitiesStringDisplay();

    if (!isValid) {
      return;
    }

    this.submitted = true;

    companyForm.customAttributes = this.customAttributesValues;

    if (companyForm.portfolio !== '-1') {
      companyForm.portfolio_id = +companyForm.portfolio;
    }

    if (companyForm.portfolio === '0' || companyForm.portfolio === 0) {
      companyForm.portfolio_id = null;
    }

    if (companyForm.email) {
      companyForm.email = companyForm.email.trim();
    }

    if (!companyForm.sicCode) {
      companyForm.sicCode = null;
    }

    if (!companyForm.naicsCode) {
      companyForm.naicsCode = null;
    }

    if (companyForm.settings.roundToThousands === 'true') {
      companyForm.settings['roundToThousands'] = true;
    } else {
      companyForm.settings['roundToThousands'] = false;
    }

    if (companyForm.sicIndustry === '') {
      delete(companyForm.sicIndustry);
    }

    this.subsArr$.push(
      this.borrowerService.companyUpdate(companyForm, this.company.id)
        .subscribe((company: Company) => {
            this._sharedDataService.company$.next(this._resetCompanyPortfolio(company));
            this._alertService.success('Borrower updated successfully');
            this.toggleEditing();
            this.submitted = false;
          },
          error => {
            this._alertService.error(error.message);
            this.submitted = false;
          },
        ),
    );
  }

  saveLinkedEntities(): void {
    if (!this.company) {
      return;
    }

    const beginningIds = this.beginningLinkedEntities.map( array => array.uuid);

    let currentIds = [];
    if (this.areLinkedEntitiesShown) {
      currentIds = this.linkedEntities.map( array => array.uuid);
    }

    const idsToDelete = beginningIds.filter(x => !currentIds.includes(x));
    const idsToAdd = currentIds.filter(x => !beginningIds.includes(x));

    idsToDelete.forEach(id => {
      this._linkedEntitiesService.deleteLinkedEntity(this.company.uuid, id).subscribe(x => {
      }, error => {
        this._alertService.error(error.message);
      });
    });

    idsToAdd.forEach(id => {
      this._linkedEntitiesService.createLinkedEntity(this.company.uuid, id).subscribe(x => {
      }, error => {
        this._alertService.error(error.message);
      });
    });


    this.beginningLinkedEntities = this.linkedEntities;
  }

  deleteFiscalYearEndChange(fye: FiscalYearEndChange, evt: any) {
    evt.preventDefault();

    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Are you sure you want to delete this fiscal year end change?',
        text: 'This will effect your financial reports',
        danger: true
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.subsArr$.push(this.borrowerService.deleteFiscalYearEndChange(fye.id).subscribe(() => {
              this._alertService.success('FYE deleted successfully');
              this.getFYEData();
            }, (err) => {
              this._alertService.error('Oops! Failed to delete fiscal year end');
            }));
          }
        }
      },
    });
  }

  addFiscalYearEndChange() {
       this._popupService.open({
        componentType: AddFyeChangeComponent,
        cssClass: 'modal-add-fye-change',
        inputs: {
          company: this.company
        },
        outputs: {
        callback: (fiscalYearEndChange: string) => {
          this.subsArr$.push(this.borrowerService.addFiscalYearEndChange(this.company, fiscalYearEndChange).subscribe( data => {
            this.getFYEData();
          }, (err) => {
              this._alertService.error('Error creating fiscal year end change');
          }));
        }
      },
      });
  }

  addAccountingIntegration() {
    this._popupService.open({
      componentType: CreateInviteLinkComponent,
      cssClass: 'modal-import-statements',
      inputs: {
        company: this.company
      }
    });
  }

  setFlags() {
    this.showSicIndustry = this._featureFlags.flags['show-industry-sic']
    this.showLinkedEntitiesFlag = this._featureFlags.flags['linked-entities'];
    this.canEditCustomerId = this._featureFlags.flags['edit-customer-id'];
  }

  isPageEditable() {
    /* user can only edit company if they have full page_view_mode and the external id of the company is not set */
    return this.pageViewMode === COMPANY_PAGE_VIEW_MODE.FULL && this.company && ((typeof this.company.externalId !== 'string' || this.company.externalId.trim().length === 0) || this.canEditCustomerId);
  }

  canAssignBorrowerToPortfolio() {
    return this.routeData.companyEntitlement[COMPANY_TABS.PROFILE][COMPANY_ENTITLEMENT_DATA.CAN_ASSIGN_BORROWER_TO_PORTFOLIO];
  }

  /**
   * set company portfolio in case when it updated
   * @param {Company} company
   * @returns {Company}
   * @private
   */
  private _resetCompanyPortfolio(company: Company): Company {
    if (company.portfolio !== null && company.portfolio.name === null) {
      company.portfolio = this.portfoliosArr.find(portfolio => portfolio.id === company.portfolio.id);
    }
    return company;
  }

  borrowerSearchResultsFilter: (el: any) => boolean = element => element.id !== this.company.id;
}
