import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '@decorators/auto-unsubscribe';
import { UserService } from '@services/user.service';
import { ApiService } from '@services/api.service';
import { AppConfigService } from '@services/app-config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'app-switch-tenant',
  templateUrl: 'switch-tenant.component.html',
  styleUrls: ['switch-tenant.component.scss'],
})
@AutoUnsubscribe('subsArr$')
export class SwitchTenantComponent implements OnInit  {

  returnUrl: string;
  selectedBankId = '';
  subsArr$: Subscription[] = [];

  tenants = [];

  constructor(
    private appConfigService: AppConfigService,
    private apiService: ApiService,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.populateTenantNames();
  }

  inputCustomId(): void {
    const tenantName = prompt('Please type in the tenant name you want to access.');
    if (tenantName) {
      this.apiService.setTenantName(tenantName);
      this.navigateToApp();
    }
  }

  setTenantName(tenantName: string): void {
    if (tenantName) {
      this.subsArr$.push(
        this.userService
          .updateUserActiveTenant(tenantName)
          .subscribe(() => {
            this.subsArr$.push(this.userService.getCurrentUser(true).subscribe(() => {
              this.apiService.setTenantName(tenantName);
              this.appConfigService.setPublicApiHeaders();
              this.navigateToApp();
            }));
          })
      );
    }
  }

  private navigateToApp() {
    this.router.navigate((!!this.returnUrl) ? [this.returnUrl]  : ['/']);
  }

  private populateTenantNames(): void {
    const currentTenants = Object.keys(this.userService.user.user_tenants).sort();
    this.tenants = this.removeCurrentTenantFromNames(currentTenants);
  }

  private removeCurrentTenantFromNames(currentTenants: Array<string>): Array<string> {
    const userCurrentTenant = this.userService.user.tenant_name
    return currentTenants.filter(tenant => tenant !== userCurrentTenant);
  }
}
