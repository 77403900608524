import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { initialize, LDClient, LDFlagSet } from 'ldclient-js';

import { AppConfigService } from '../services/app-config.service';
import { LD_IDS } from '../utils/constants';
import { CommonFunctions } from '../utils/common-functions';
import { User } from '@models/user';

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<Object> = new Subject<Object>();

  constructor(private _appConfigService: AppConfigService) {
    // defaults
    this.flags = {};

    this.ldClient = initialize(LD_IDS[CommonFunctions.getEnvFromHost().envName],
      { key: 'SAMPLE-USER-KEY', anonymous: true },
      { bootstrap: 'localStorage', fetchGoals: false }
    );

    this.ldClient.on('change', (flags) => {
      for (const key in flags) {
          // skip loop if the property is from prototype
          if (!flags.hasOwnProperty(key)) continue;

          this.flags[key] = flags[key].current;
      }
      this.flagChange.next(this.flags);
   });

   this.ldClient.on('ready', () => {
     this.setFlags();
   })
  }

  setFlags() {
    this.flags = this.ldClient.allFlags();
    this.flagChange.next(this.flags);
  }

  setUser(user) {
    this.ldClient.identify(this._claimsToLdUser(user));
  }

  _claimsToLdUser(userClaims: User) {
    const userIdentification = {
      key: userClaims.user_sub,
      anonymous: false,
      email: userClaims.user_email
    }
    if (!!userClaims.bank_id) {
      userIdentification['custom'] = { 'bank_id' : userClaims.bank_id };
    }
    return userIdentification;
  }

  setAnonymous() {
    this.ldClient.identify({key: 'anon', anonymous: true});
  }
}
