<div class="modal__header">
    <div class="modal__title">
        Link Accounting Integration
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>

<div class="modal__content">
    <div *ngIf="!submitAttempt && !submissionComplete" class="row">
        <div class="col-12">
            <p>The following accounting software can be imported directly into Fincura.</p>
            <ul>
                <li>Quickbooks Online</li>
                <li>Xero</li>
            </ul>
            <p>Create an invite link below to share with invidual who manages the companies accounting software to begin the linking process.</p>
        </div>
    </div>
    <div *ngIf="submitAttempt && !submissionComplete" class="row status-row">
        <div class="col-12">
            <p>Creating Link</p>
        </div>
        <div class="col-12" style="padding-top: 20px;">
            <div class="loader"></div>
        </div>
    </div>
    <div *ngIf="submissionComplete" class="row status-row">
        <div class="col-12">
            <label class="control-label">Accounting Integration invite link:</label>
            <div class="input-group">
                <input id="inviteLink" type="text" [value]="inviteLink" class="form-control" readonly>
                <div class="input-group-btn">
                    <button class="btn btn-default" type="button" (click)="copyToClipboard()">Copy</button>
                </div>
            </div>
        </div>
        <div class="col-12 ">
            <hr />
            <!-- <p style="text-align:center;">OR</p> -->
        </div>
        <div class="col-12">
            <!-- <p class="mail-text" (click)='sendEmail()'>Send By Email<i class="fas fa-envelope mail-icon"></i></p> -->
        </div>
        <div class="col-12">
            <p class="note">NOTE: For security purposes this link expires after 14 days</p>
        </div>
    </div>
</div>

<div class="modal__footer">
    <ng-container *ngIf="!submissionComplete">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="createLink()" [disabled]="submitted">Create Invite Link</button>
    </ng-container>
    <ng-container *ngIf="submissionComplete">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
    </ng-container>
</div>
