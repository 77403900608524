import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/components/app.module';

import * as $ from 'jquery';

const hostname = window.location.hostname;

if (hostname && hostname !== 'app-local.fincura.com') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
