<div class="modal__header">
    <div class="modal__title">
        IMPORT/EXPORT TEMPLATE
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>
<form>
    <div class="modal__content">

        <div class="row">
            <div class="col-lg-12">
            	<p>If you have an exported template, paste the data below and click the import button.</p>
                <label for="basic-url">Import:</label>
                <div class="input-group">
                  <input id="export-input" type="text" class="form-control" (change)="importJson = $event.target.value">
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" title="Import the template" (click)="importTemplate()" [disabled]="templateId===null"><i class="fas fa-file-import"></i></button>
                  </span>
                </div>
                <p *ngIf="templateId===null" style="color:red;">Please save template once in order to import an exported template</p>
              </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <hr />
            </div>
        </div>

		<div class="row" style="margin-bottom: 15px">
            <div class="col-lg-12">
            	<p>Copy the data below to store this template.</p>
                <label for="basic-url">Export:</label>
                <div class="input-group">
                  <input id="export-template-text" type="text" class="form-control" readonly="readonly" value="{{exportJson}}">
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" title="Copy template to clipboard" (click)="copyExportJsonToClipboard()"><i class="fas fa-clipboard"></i></button>
                  </span>
                </div>
              </div>
        </div>
    </div>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Close</button>
    </div>
</form>
