<div class="container-fluid edit-template-mappings-container">
    <div class="row edit-template-mappings-header">
        <div class="col-6 edit-template-mappings-header-label">
            <h3>Edit Tax Form Mappings For: {{ templateName }}</h3>
        </div>
        <div class="col-6 save-template-mappings-button-container">
            <button (click)="saveTaxFormMappingOverrides()" [disabled]="saveButtonDisabled"
                    class="btn btn-rounded save-mappings-button"
                    [ngClass]="{'btn-primary': !saveButtonDisabled, 'btn-secondary': saveButtonDisabled}">
                Save Mappings
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-6 tax-form-type-selector">
            <p><strong>Tax Form Type</strong></p>
            <select (change)="changeTaxForm($event.target.value)">
                <option *ngFor="let taxFormMapping of savedTaxFormMappings | keyvalue" [value]="taxFormMapping.key"
                        [selected]="taxFormMapping.key === selectedTaxFormType">
                    {{ taxFormMapping.key.charAt(0).toUpperCase() + taxFormMapping.key.slice(1) }}
                </option>
            </select>
        </div>
        <div class="col-6 edit-mappings-action-buttons-container">
            <div class="edit-mappings-action-buttons">
                <a (click)="downloadTaxFormMappingsToExcel()"><i class="fa fa-file-arrow-down"></i> Download Excel</a>
                <a (click)="launchRestoreAllMappingsModal()" class="restore-all-defaults-button"><i class="fa fa-clock-rotate-left"></i> Restore All Defaults</a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mappings-table-container">
            <table class="table">
                <thead>
                <tr>
                    <th class="col-sm-2 line-item-cell">LINE ITEM<span class="fa-stack info-icon"><i
                        class="far fa-circle fa-stack-2x"></i><i class="fa fa-info fa-stack-1x"></i></span></th>
                    <th class="col-sm-1 fit-to-content-cell"><i class="fa-solid fa-right-long arrow-img-header"></i>
                    </th>
                    <th class="col-sm-2 mapping-cell">MAPPING<span class="fa-stack info-icon"><i
                        class="far fa-circle fa-stack-2x"></i><i class="fa fa-info fa-stack-1x"></i></span></th>
                    <th class="col-sm-2 restore-default-cell"></th>
                    <th class="col-sm-2 border-left-cell fit-to-content-cell">DATE CHANGED<span
                        class="fa-stack info-icon"><i class="far fa-circle fa-stack-2x"></i><i
                        class="fa fa-info fa-stack-1x"></i></span></th>
                    <th class="col-sm-2 border-left-cell fit-to-content-cell">STATEMENT TYPE<span
                        class="fa-stack info-icon"><i class="far fa-circle fa-stack-2x"></i><i
                        class="fa fa-info fa-stack-1x"></i></span></th>
                    <th class="col-sm-3 note-cell border-left-cell">IMPLEMENTATION NOTES<span
                        class="fa-stack info-icon"><i class="far fa-circle fa-stack-2x"></i><i
                        class="fa fa-info fa-stack-1x"></i></span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let lineItem of selectedTaxFormItems">
                    <td class="col-sm-2 line-item-cell">{{ lineItem.lineItemName }}</td>
                    <td class="col-sm-1 fit-to-content-cell"><i class="fa-solid fa-right-long arrow-img"></i></td>
                    <td class="col-sm-2 mapping-cell">
                        <select (change)="setOverrideForTaxLineItem($event.target.value, lineItem)">
                            <option
                                *ngFor="let taxonomyItem of setMappingOptionsForLineItem(lineItem)"
                                [value]="taxonomyItem.standardLineItemId"
                                [selected]="lineItem.currentStateOfOverrideIdToBeSaved === null ? lineItem.defaultTaxonomyItemId === taxonomyItem.standardLineItemId : lineItem.currentStateOfOverrideIdToBeSaved === taxonomyItem.standardLineItemId">
                                {{ taxonomyItem.label }}
                            </option>
                        </select>
                    </td>
                    <td class="col-sm-2 restore-default-cell">
                        <ng-container *ngIf="lineItem.currentStateOfOverrideIdToBeSaved !== null">
                            <a (click)="setMappingOptionsForLineItem(lineItem); setOverrideForTaxLineItem(null, lineItem)"><i
                                class="restore-default-circle fas fa-circle fa-xs"></i><strong>RESTORE DEFAULT</strong></a>
                        </ng-container>
                    </td>
                    <td class="col-sm-2 border-left-cell fit-to-content-cell">{{ lineItem.overrideCreationDt | formatDate }}</td>
                    <td class="col-sm-2 border-left-cell fit-to-content-cell">{{ lineItem.statementType === INCOME_STATEMENT ? 'Income Statement' : 'Balance Sheet' }}</td>
                    <td class="col-sm-3 border-left-cell note-cell" *ngIf="lineItem.descriptionEditMode === true">
                        <div>
                            <textarea [(ngModel)]="lineItem.note"
                                      [ngModelOptions]="{ updateOn: 'blur' }"
                                      (ngModelChange)="setNewNoteTaxLineItem(lineItem)"
                                      class="note-textarea"
                                      cdkTextareaAutosize
                                      #autosize="cdkTextareaAutosize"
                                      cdkAutosizeMinRows="1"
                                      placeholder="Add Description"
                            ></textarea>
                            <button class="note-save-button" (click)="switchLineItemNoteEditMode(lineItem)">
                                <i class="fas fa-check"></i>
                            </button>
                        </div>
                    </td>
                    <td class="col-sm-3 border-left-cell edit-cell" *ngIf="lineItem.descriptionEditMode === false"
                        (click)="switchLineItemNoteEditMode(lineItem)">
                        <i *ngIf="lineItem.note === null" class="far fa-message description-icon"></i>
                        {{lineItem.note !== null ? lineItem.note : 'Add Description'}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

