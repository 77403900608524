<div *ngIf="hasScopes" class="table-cell-container">
    <div *ngIf="!isEditing"
        [ngClass]="{
            'isHighlighted': isHighlighted,
            'notHighlighted': !isHighlighted,
            'changed': isChanged && !newValueMatchesOriginalValue,
            'isDiv0': isDiv0
        }"
        (click)="startHighlightMode($event)"
        (dblclick)="startEditMode($event)"
        class="value-container"
    >

        <ng-container *ngIf="newValue !== '' && newValue !== null; else showCalculatedValue">
            <span>{{formatItem(newValue, textFormat)}}</span>
        </ng-container>
        <ng-template #showCalculatedValue>
            <span *ngIf="equationValue !== 0 || displayNumForZero" class="calculatedValue">{{formatItem(equationValue, textFormat)}}</span>
            <span *ngIf="equationValue === 0 && !displayNumForZero">-</span>
        </ng-template>
    </div>

    <input *ngIf="isEditing"
        type="text"
        #editCellTextbox
        [(ngModel)]="newValue"
        (focusout)="editComplete()"
        (keyup.enter)="editComplete()"
        (click)="stopPropagation($event)"
    />

</div>
