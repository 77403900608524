import { Component, OnInit } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileUploader } from 'ng2-file-upload';
import { SharedDataService } from '../../../services/shared-data.service';
import { LaunchDarklyService } from '../../../services/launchdarkly.service';
import { DocumentFileService } from '../../../services/document-file.service';
import { UserService } from '../../../services/user.service';
import { DocumentFileCreateRequest } from '../../../models/document-file-create-request';
import { DocumentFileCreateRequestState } from '../../../utils/enums';
import { LD_IDS } from '../../../utils/constants';
import { CommonFunctions } from '../../../utils/common-functions';

// eslint-disable-next-line
const TEST_DATA = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEABAMAAACuXLVVAAAAG1BMVEVHcEw3a6b///94ywDX4u6KqMtxwhBWmlVCfIl/POyrAAAAAXRSTlMAQObYZgAAAT5JREFUeNrt2TFuwjAUgGGukKHqXvUGkTgAqg+A5BtU6s6WG+TaparSxBSnQ5VniXwfm5f3gy2SwOEAAAAAAAAAAAB/6v5BgAABAgQIECBAgICHDHg6rThHBLysECBAgAAB+wh4W3F2PyBAgAABe/gqvl6MTtWXy7EAATsMeG0dkHLbgNR1uWVA+lrI7QLS90puFZCmpdwmIM1ruUVAWi7m+IBUrubogHR785VjA37mj0NREBUwz38/FgVBAYv5fV8UxAQU88uCkICb+UVB6C8kYz+ZCyKfjKb3v1qwYcByfr1gu4ByfrVgs4Db+bWCrQJ+z68UbBRwb/7VEBUw9hVDTEB1/p2CLQKe+xUXAXsPONoCZ8AWOAO2wBnYxyfwseIS+WzobzsBAgQIECBAgAAB+wkAAAAAAAAAAODBfQIAvq47rVAWowAAAABJRU5ErkJggg==';

function base64URLEncode(s) {
  return (
    btoa(s)
      // eslint-disable-next-line
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
  );
}

@Component({
  selector: 'app-status',
  templateUrl: './app-status.component.html',
  styleUrls: ['./app-status.component.scss']
})
export class AppStatusComponent implements OnInit {

  public stages;
  public uploader: FileUploader;

  constructor(
    private _userService: UserService,
    private _featureFlags: LaunchDarklyService,
    private _sharedDataService: SharedDataService,
    private _documentFileService: DocumentFileService
  ) { }

  public dataUrlToFile = (dataurl: string, fileName: string): File => {
        const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const theBlob: Blob = new Blob([u8arr], {type: mime});
        const b: any = theBlob;
        // A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;
        // Cast to a File() type
        return <File>theBlob;
    }

  ngOnInit() {

    this._sharedDataService.pageHeaderTitle$.next('Status');

    this.stages = [
        {
            label: 'Application Loaded',
            complete: false,
            passing: null,
            checkHandler: () => this.checkAppLoaded()
        },
        {
            label: 'Feature Service',
            complete: false,
            passing: null,
            checkHandler: () => this.checkFeatureFlags()
        },
        {
            label: 'Upload Connected',
            complete: false,
            passing: null,
            checkHandler: () => this.checkUploadStatus()
        }

    ];


    this.stages.forEach((stage) => {
        stage.checkHandler().subscribe((passing) => {
            stage.complete = true;
            stage.passing = passing;
        },
        (err) => {
            // uncaught exception
            stage.complete = true;
            stage.passing = false;
        });
    })
  }

  checkAppLoaded(): any {
    return of(true);
  }

  checkFeatureFlags(): any {
    const clientKey = LD_IDS[CommonFunctions.getEnvFromHost().envName];
    const ldUser = this._featureFlags._claimsToLdUser(this._userService.user);
    const userStr = base64URLEncode(JSON.stringify(ldUser));
    const url = `https://app.launchdarkly.com/sdk/evalx/${clientKey}/users/${userStr}`;

    return new Observable(observer => {
      fetch(url, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *client
        })
        .then(response => {
            if (response.status !== 200) {
              throw Error('Status: ' + response.status);
            }
            return response.json();
        })
        .then(data => {
          observer.next(true);
          observer.complete();
        })
        .catch(err => {
          observer.error(err);
          observer.next(false)
        });
    });
  }

  checkUploadStatus(): any {
        this.uploader = new FileUploader({
          autoUpload: false,
          method: 'PUT',
          queueLimit: 1,
          disableMultipart: true,
          isHTML5: true,
        });
        const test_filename = 'test_file.png';
        const docFileCreateRequest = new DocumentFileCreateRequest();
        docFileCreateRequest.state = DocumentFileCreateRequestState.FileChoosen;
        docFileCreateRequest.companyId = 1;
        docFileCreateRequest.originalDocumentName = test_filename;
        docFileCreateRequest.documentType = 'INCOME_STATEMENT';
        docFileCreateRequest.uploader = this.uploader;
        this.uploader.addToQueue(new Array<File>(this.dataUrlToFile(TEST_DATA, test_filename)));
        docFileCreateRequest.fileItem = this.uploader.queue[0];

        return this._documentFileService.uploadDocumentFile(docFileCreateRequest).pipe(
            map((resp) => {
                return of(resp);
            }),
            catchError((err) => {
                // error attemtping to upload file to s3
                return of(false);
            })
        );
  }

}
