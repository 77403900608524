/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Webhook { 
    readonly uuid?: string;
    eventType: Webhook.EventTypeEnum;
    webhookUrl: string;
    webhookMethod?: Webhook.WebhookMethodEnum;
    /**
     * External ID is used to uniquely identify a record from your system in our system. Must be unique across records.
     */
    externalId?: string;
    readonly signingKey?: string;
}
export namespace Webhook {
    export type EventTypeEnum = 'DocumentFile.Received' | 'DocumentFile.Processing' | 'DocumentFile.HumanRequired' | 'DocumentFile.SpreadComplete' | '*';
    export const EventTypeEnum = {
        DocumentFileReceived: 'DocumentFile.Received' as EventTypeEnum,
        DocumentFileProcessing: 'DocumentFile.Processing' as EventTypeEnum,
        DocumentFileHumanRequired: 'DocumentFile.HumanRequired' as EventTypeEnum,
        DocumentFileSpreadComplete: 'DocumentFile.SpreadComplete' as EventTypeEnum,
        Star: '*' as EventTypeEnum
    };
    export type WebhookMethodEnum = 'GET' | 'POST';
    export const WebhookMethodEnum = {
        Get: 'GET' as WebhookMethodEnum,
        Post: 'POST' as WebhookMethodEnum
    };
}


