<td class="no-border-td">{{portfolio.name}}</td>
<td class="no-border-td">{{portfolio.changedDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
<td class="no-border-td">{{portfolio.createdDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
<td class="no-border-td">
    <div class="action-menu-wrapper">
        <div (click)="toggleActionMenu($event)">
            <i class="action-menu-icon fas fa-ellipsis"></i>
        </div>
        <div class="dropdown-menu" *ngIf="actionMenuIsOpen">
            <span class="dropdown-item p-1" (click)="actionItemClick(portfolio, $event, PortfolioActionMenuItems.EDIT)"><i
                class="menu-item-icon fas fa-pencil-alt"></i>Edit</span>
            <!--          <li><span (click)="actionItemClick(portfolio, $event, 'addBorrower')"><i class="menu-item-icon fas fa-folder"></i>Add Borrowers</span></li>-->
            <!--          <li><span (click)="actionItemClick(portfolio, $event, 'exportData')"><i class="menu-item-icon fas fa-download"></i>Export Data</span></li>-->
            <span class="dropdown-item p-1" (click)="actionItemClick(portfolio, $event, PortfolioActionMenuItems.DELETE)"><i
                class="menu-item-icon fas fa-trash"></i>Delete</span>
        </div>
    </div>
</td>
