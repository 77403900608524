<div (click)="clickedInside($event)">
    <div class="input-group">
        <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search Borrower Name"
            [formControl]="searchControl"
            autocomplete="off"
            (keydown)="keydownHandler($event)"
        />
        <ng-container *ngIf="searchInProgress; else staticSearchIcon">
            <span class="input-group-text">
                <i class="search-icon">
                    <div class="loader search-loading-spinner"></div>
                </i>
            </span>
        </ng-container>
        <ng-template #staticSearchIcon>
            <span class="input-group-text">
                <i class="fas fa-search search-icon"></i>
            </span>
        </ng-template>
        <ul *ngIf="!!borrowerSearchResults?.length" class="dropdown">
            <li *ngFor="let item of borrowerSearchResults; let idx = index;"
                (click)="select(item)"
                [ngClass]="{active: idx == arrowkeyLocation}">{{item.name}}</li>
        </ul>
        <ul *ngIf="noResultsMessageIsVisible" class="dropdown no-results-msg">
            <li>{{noResultsMessage}}</li>
        </ul>
    </div>
</div>
