/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoanPeriods } from './loanPeriods';
import { LoanFinancials } from './loanFinancials';
import { LoanBorrowerInfo } from './loanBorrowerInfo';
import { LoanComplianceInfo } from './loanComplianceInfo';
import { LoanDocuments } from './loanDocuments';
import { LoanPriorYearFinancials } from './loanPriorYearFinancials';
import { LoanGuarantors } from './loanGuarantors';


export interface Loan { 
    readonly uuid?: string;
    /**
     * Title of the Loan. Must be unique for the Borrower
     */
    title: string;
    description?: string | null;
    readonly createdDate?: string;
    /**
     * External ID is used to uniquely identify a record from your system in our system. Must be unique across records.
     */
    externalId?: string | null;
    loanId?: string | null;
    contractType?: Loan.ContractTypeEnum;
    /**
     * UUID of the borrower for this loan. (see [Borrowers](#tag/Borrowers))
     */
    borrowerUuid: string;
    borrowerInfo: LoanBorrowerInfo;
    /**
     * Original principal value
     */
    principal: number;
    interestRate?: number;
    interestType?: Loan.InterestTypeEnum;
    termMonths: number;
    paymentType?: Loan.PaymentTypeEnum;
    state?: Loan.StateEnum;
    paymentStatus?: Loan.PaymentStatusEnum;
    startDate?: string | null;
    /**
     * Loan end date. Calculated field
     */
    readonly endDate?: string;
    /**
     * calculated field
     */
    readonly monthlyPayment?: number;
    /**
     * Calculated field
     */
    readonly paymentsRemaining?: number;
    /**
     * calculated field
     */
    readonly outstandingPrincipal?: number;
    /**
     * calculated field
     */
    readonly interestAccrued?: number;
    /**
     * calculated field
     */
    readonly loanToValue?: number;
    scorecard?: Loan.ScorecardEnum | null;
    collateralType?: Loan.CollateralTypeEnum;
    collateralValue?: number | null;
    /**
     * The life of the collateral in months
     */
    collateralLifeMonths?: number;
    residualValue?: number | null;
    balloonPayment?: number | null;
    lastPaymentDate?: string | null;
    complianceInfo?: LoanComplianceInfo;
    /**
     * DSRC template to use for finanical information
     */
    templateUuid?: string;
    /**
     * ReportingPeriod\'s to include in the loan financial information
     */
    periods?: Array<LoanPeriods>;
    /**
     * Additional DocumentFile\'s to be included with loan information
     */
    documents?: Array<LoanDocuments>;
    financials?: LoanFinancials;
    analysisCommentary?: string | null;
    guarantors?: Array<LoanGuarantors>;
    priorYearFinancials?: LoanPriorYearFinancials;
    customAttributes?: object;
}
export namespace Loan {
    export type ContractTypeEnum = 'LEASE' | 'LOAN' | 'NOT_APPLICABLE';
    export const ContractTypeEnum = {
        Lease: 'LEASE' as ContractTypeEnum,
        Loan: 'LOAN' as ContractTypeEnum,
        NotApplicable: 'NOT_APPLICABLE' as ContractTypeEnum
    };
    export type InterestTypeEnum = 'FIXED' | 'ADJUSTABLE';
    export const InterestTypeEnum = {
        Fixed: 'FIXED' as InterestTypeEnum,
        Adjustable: 'ADJUSTABLE' as InterestTypeEnum
    };
    export type PaymentTypeEnum = 'PRINCIPAL_AND_INTEREST' | 'PRINCIPAL_PLUS_INTEREST' | 'IO_PERIOD' | 'BALLON';
    export const PaymentTypeEnum = {
        PrincipalAndInterest: 'PRINCIPAL_AND_INTEREST' as PaymentTypeEnum,
        PrincipalPlusInterest: 'PRINCIPAL_PLUS_INTEREST' as PaymentTypeEnum,
        IoPeriod: 'IO_PERIOD' as PaymentTypeEnum,
        // Ballon: 'BALLON' as PaymentTypeEnum
    };
    export type StateEnum = 'PROPOSED' | 'APPROVED' | 'DECLINED' | 'ACTIVE' | 'PAID_OFF' | 'DESISTED';
    export const StateEnum = {
        Proposed: 'PROPOSED' as StateEnum,
        Approved: 'APPROVED' as StateEnum,
        Declined: 'DECLINED' as StateEnum,
        Active: 'ACTIVE' as StateEnum,
        PaidOff: 'PAID_OFF' as StateEnum,
        Desisted: 'DESISTED' as StateEnum
    };
    export type PaymentStatusEnum = 'CURRENT' | 'GRACE_PERIOD' | 'DELINQUENT';
    export const PaymentStatusEnum = {
        Current: 'CURRENT' as PaymentStatusEnum,
        GracePeriod: 'GRACE_PERIOD' as PaymentStatusEnum,
        Delinquent: 'DELINQUENT' as PaymentStatusEnum
    };
    export type ScorecardEnum = 'PASSED' | 'FAILED' | 'NOT_APPLICABLE';
    export const ScorecardEnum = {
        Passed: 'PASSED' as ScorecardEnum,
        Failed: 'FAILED' as ScorecardEnum,
        NotApplicable: 'NOT_APPLICABLE' as ScorecardEnum
    };
    export type CollateralTypeEnum = 'NONE' | 'CASH' | 'AR' | 'IP' | 'REAL_ESTATE' | 'EQUIPMENT';
    export const CollateralTypeEnum = {
        None: 'NONE' as CollateralTypeEnum,
        Cash: 'CASH' as CollateralTypeEnum,
        Ar: 'AR' as CollateralTypeEnum,
        Ip: 'IP' as CollateralTypeEnum,
        RealEstate: 'REAL_ESTATE' as CollateralTypeEnum,
        Equipment: 'EQUIPMENT' as CollateralTypeEnum
    };
}


