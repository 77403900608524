<div class="borrower-documents-upload">
  <div class="header">
    <img class="customer-logo" [src]="customBankImg" alt="Logo"/>
    <p>Secure Borrower Reporting Portal</p>
    <i class="fas fa-lock"></i>
  </div>

  <div class="main-content-container">
    <div class="upload-required-docs" [hidden]="complete">
      <h3>Upload Required Documents</h3>
      <p class="margin-bottom">Please upload <strong>ALL</strong> required documents using the button below.</p>

      <div *ngIf="documentSubmissions.length===0">
        <app-document-file-selector-button
          text="UPLOAD FILE"
          [buttonColor]="primaryColor"
          btnBorderRadius="20px"
          (fileChoosen)="emitfileChoosen($event)">
        </app-document-file-selector-button>
      </div>

      <app-portal-borrower-document-submission [hidden]="complete"
        *ngFor="let submission of documentSubmissions" 
        [submission]="submission"
        (onCancelSubmission)="emitCancelSubmission($event)">
      </app-portal-borrower-document-submission>

      <div class="doc-upload-buttons" *ngIf="documentSubmissions.length>0" [hidden]="complete">

        <app-document-file-selector-button
          style="default"
          btnBorderRadius="20px"
          [buttonColor]="'#ffffff'"
          [textColor]="primaryColor"
          text="Add file"
          (fileChoosen)="emitfileChoosen($event)">
        </app-document-file-selector-button>

        &nbsp;&nbsp;or&nbsp;&nbsp;

        <button class="btn btn-primary send-btn" (click)="emitSubmitDocuments()" [disabled]="submitting" [ngStyle]="{'background-color': primaryColor,'border-color': primaryColor}">
          Send
        </button>

      </div> 
    </div>

    <div [hidden]="!complete" class="borrower-upload-complete">
      <i class="fas fa-check-circle"></i>
      <h3>Sent!</h3>
      <p>Thank you for uploading your documents.</p>
      <p>We will review the information and let you know if we have any questions.</p>
    </div>

  </div>
</div>
