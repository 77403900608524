<div class="insights-div">
  <ng-container *ngFor="let item of insights.components; let itemIdx = index">
    <div  *ngIf="!item.isSymbol" class="insight-component">
      <app-period-over-period
        [ngClass]="{
          'selected-insight': selectedItem === itemIdx,
          'deselected-insight': selectedItem !== itemIdx && selectedItem !== -1 && itemIdx > 0,
          'calculated-insight': selectedItem === -1 && !item.isRaw && itemIdx > 0,
          'raw-insight': item.isRaw || itemIdx === 0
        }"
        style="display: inline-block" 
        [label]="item.label"
        [value]="item.value"
        [prevValue]="item.prevValue"
        [rawItem]="item.isRaw"
        [ref]="item.ref"
        [footnotes]="item.footnotes"
        [prevSourceBox]="item.prevSourceBox"
        [sourceBox]="item.sourceBox"
        [textFormat]="item?.textFormat"
        (openDetail)="insightDetailSelected($event, itemIdx)"
        (openFootnotes)=lineItemFootnoteSelected($event)
        (openSourceBox)="lineItemSourceBoxSelected($event)"
      ></app-period-over-period>
    </div>
    <div *ngIf="item.isSymbol" class="insight-component">
      <div class="equation-symbol-span">{{ item.label }}</div>
    </div>
  </ng-container>
</div>

