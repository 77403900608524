<button class="btn btn-close close" (click)="closeWindow()"></button>
<div class="main-content">

    <ng-container *ngIf="isLoading; else errorMsgOrPageContent">
        <div class="col-12 loader-wrapper">
            <div class="loader"></div>
        </div>
    </ng-container>
    <ng-template #errorMsgOrPageContent>
        <div *ngIf="errorGettingPageImg; else pageContent" class="error-message">
            <h4 class="mb-5">Error retrieving original document for display.
                <span *ngIf="model?.originalFileUrl">Try downloading the document directly.</span>
            </h4>
            <a *ngIf="model?.originalFileUrl" class="btn btn-empty-primary" target="_blank"
               href="{{model.originalFileUrl}}">
                <i class="fas fa-download"></i> Download Document
            </a>
        </div>
    </ng-template>
    <ng-template #pageContent>
        <div class="toolbar row">
            <div class="left-content col">
                <h5 class="float-start mb-0">
                    <strong class="fs-block">{{ model.jobPayload.company.name }}</strong>
                    <span class="text-muted"> | {{ model.jobPayload.documentFile.originalDocumentName }}</span>
                </h5>
            </div>
            <div class="center-content col">
                <app-pdf-paginator [pageCount]="pageCount"></app-pdf-paginator>
                <div class="btn-separator">|</div>
                <app-pdf-zoom [zoomElementContainerId]="showTable ? 'page-table-list' : 'page-img-list'"></app-pdf-zoom>
            </div>
            <div class="right-content col">
                <a [ngClass]="{'disabled': isLoading}" class="btn btn-empty-primary float-end" target="_blank"
                   href="{{model.originalFileUrl}}"><i class="fas fa-download"></i>Download Document</a>
            </div>
        </div>
        <div class="page-content-container fs-block" #docContainer (scroll)="handleScroll()">
            <div class="doc-container">
                <ul *ngIf="!showTable" id="page-img-list" class="page-images">
                    <li class="page-img-list-item" #pageImgListItem
                        *ngFor="let pageUrl of safePageImgUrls; let i = index;">
                        <img [src]="pageUrl">
                    </li>
                </ul>
                <div *ngIf="showTable" id="page-table-list">
                    <table class="excel-table" *ngFor="let page of model.document.pages"
                           #pageImgListItem>
                        <tr *ngFor='let row of page.cells'>
                            <td *ngFor='let col of row'>{{ col.text }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>

</div>
