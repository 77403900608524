<div class="ch-popover" #popover (click)="onPopoverClick()">
    <div class="ch-popover__header">
        <span>{{data.header.title}}</span>
    </div>
    <div class="ch-popover__content">
        <span>{{data.content}}</span>
    </div>
    <div class="ch-popover__footer">
        <ng-container *ngFor="let item of data.footer" [ngSwitch]="item.type">
            <button *ngSwitchCase="'button'"
                    (click)="item.event.action()"
                    [ngClass]="item.classNames"
            >
                {{item.content}}
            </button>
        </ng-container>
    </div>
</div>
