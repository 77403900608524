import { DocumentFile } from '../../../../models/document-file';
import { SpreadingMapTemplates } from '../../../../models/standard-item-templates';
import { Statement } from '../../review/human-validation/models/statement';

export class StatementDataCache {
  documentFilesById: Map<number, DocumentFile> = null;
  reviewQueueItemIDsByFileId: Map<number, string> = null;
  spreadingTemplatesBySpreadingTemplateId: Map<number, SpreadingMapTemplates> = null;
  relatedStatementsByFileId: Map<number, Array<Statement>> = null;

  constructor() {
    this.documentFilesById = new Map<number, DocumentFile>();
    this.reviewQueueItemIDsByFileId = new Map<number, string>();
    this.spreadingTemplatesBySpreadingTemplateId = new Map<number, SpreadingMapTemplates>();
    this.relatedStatementsByFileId = new Map<number, Array<Statement>>();
  }
}
