import { Injectable } from '@angular/core';
import { IMyDate, IMyDateRange, IMyDayLabels, IMyMarkedDate, IMyMarkedDates, IMyMonthLabels, IMyOptions } from '../interfaces';
import { Year } from '../enums';

/**
 * Configuration service for the NgxMyDatePickerComponent component.
 * You can inject this service, typically in your root component, and customize the values of its properties in
 * order to provide default values for all the datepickers used in the application.
 */

/* eslint-disable */
@Injectable()
export class NgxMyDatePickerConfig implements IMyOptions {
  dayLabels: IMyDayLabels = {su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat'};
  monthLabels: IMyMonthLabels = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug',
    9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };
  monthFullLabels: IMyMonthLabels = {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August',
    9: 'September', 10: 'October', 11: 'November', 12: 'December'};
  dateFormat = 'yyyy-mm-dd';
  showTodayBtn = true;
  todayBtnTxt = 'Today';
  firstDayOfWeek = 'mo';
  satHighlight = false;
  sunHighlight = true;
  highlightDates = <Array<IMyDate>> [];
  markCurrentDay = true;
  markCurrentMonth = true;
  markCurrentYear = true;
  monthSelector = true;
  yearSelector = true;
  disableHeaderButtons = true;
  showWeekNumbers = false;
  selectorHeight = '232px';
  selectorWidth = '252px';
  disableUntil = <IMyDate> {year: 0, month: 0, day: 0};
  disableSince = <IMyDate> {year: 0, month: 0, day: 0};
  disableDates = <Array<IMyDate>> [];
  enableDates = <Array<IMyDate>> [];
  markDates = <Array<IMyMarkedDates>> [];
  markWeekends = <IMyMarkedDate> {};
  disableDateRanges = <Array<IMyDateRange>> [];
  disableWeekends = false;
  alignSelectorRight = false;
  openSelectorTopOfInput = false;
  closeSelectorOnDateSelect = true;
  closeSelectorOnDocumentClick = true;
  minYear = <number> Year.min;
  maxYear = <number> Year.max;
  showSelectorArrow = true;
  allowSelectionOnlyInCurrentMonth = true;
  appendSelectorToBody = false;
  ariaLabelPrevMonth = 'Previous Month';
  ariaLabelNextMonth = 'Next Month';
  ariaLabelPrevYear = 'Previous Year';
  ariaLabelNextYear = 'Next Year';
}

/* eslint-enable */
