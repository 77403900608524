import {Component, EventEmitter, Input, NgModule, OnInit, Output} from '@angular/core';
import {AutoUnsubscribe} from "@decorators/auto-unsubscribe";
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {Company} from '@models/company';
import {Subscription} from "rxjs";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  Document,
  EntityWithDownloadableDocs,
  PdfDownloadModalService
} from "@components/shared/pdf-download-modal/pdf-download-modal.service";
import {finalize} from "rxjs/operators";
import {FormControl, Validators} from "@angular/forms";
import {AlertService} from "@services/alert.service";

@AutoUnsubscribe('subsArr$')
@Component({
  selector: 'app-pdf-download-modal',
  templateUrl: './pdf-download-modal.component.html',
  styleUrls: ['./pdf-download-modal.component.scss']
})
export class PdfDownloadModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company;
  @Output() callback = new EventEmitter<boolean>();
  subsArr$: Subscription[] = [];
  fileDownloadProcessBegun = false;
  entitiesWithDownloadableDocs: EntityWithDownloadableDocs[] = []
  showSpinner: boolean = false;
  documentsToBeDownloaded: Document[] = [];
  downloadFileName: FormControl = new FormControl<string>("", Validators.required);

  constructor(
    public pdfDownloadModalService: PdfDownloadModalService,
    private _alertService: AlertService,
  ) {
  }

ngOnInit(): void {
    this.showSpinner = true;
    this.pdfDownloadModalService.loadAvailableDocumentsForCompanyAndLinkedEntities(this.company)
      .pipe(
        finalize(() => {
          this.showSpinner = false;
        }))
      .subscribe(results => {
        this.entitiesWithDownloadableDocs = results
      }, error => {
        console.error('Failed to load downloadable documents and related entities', error);
        this.closeModal();
      });
    this.setInitialDocName();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.documentsToBeDownloaded, event.previousIndex, event.currentIndex);
  }

  selectDoc(document) {
    if (document?.selected) {
      this._removeDocumentToDownloadList(document);
    } else {
      this._addDocumentToDownloadList(document)
    }
  }

  setInitialDocName() {
    // strip invalid chars from company name
    const companyName = (this.company.name || '').replace(/[^A-Za-z\d()_,.]/g, '');
    const initialDocName = companyName ? companyName + '-combined' : 'combined';
    this.downloadFileName.setValue(initialDocName);
  }

  _removeDocumentToDownloadList(document) {
    this.fileDownloadProcessBegun = false;
    document.selected = false;
    this.documentsToBeDownloaded = this.documentsToBeDownloaded.filter(doc => doc != document)
  }

  _addDocumentToDownloadList(document) {
    document.selected = true;
    this.documentsToBeDownloaded.push(document);
  }

  closeModal() {
    this.callback.emit(false);
    this.popup.close();
    this.fileDownloadProcessBegun = false;
  }

  download() {
    this.fileDownloadProcessBegun = true;
      this.pdfDownloadModalService.downloadCombinedPdf(this.documentsToBeDownloaded, this.downloadFileName.value)
        .subscribe(
          () => {
            this.fileDownloadProcessBegun = false;
            this.closeModal();
          },
          (error) => {
            this._alertService.error('Unable to download document');
            this.fileDownloadProcessBegun = false;
            this.closeModal();
          },
      );
    this.callback.emit(true);
  }

}
