import { fromEvent as observableFromEvent, Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ConfirmationPopupComponent implements OnInit, OnDestroy {
  @Input() popup: NgxPopupComponent;
  @Input() question = 'Are you sure to delete?';
  @Input() text: string;
  @Input() danger: boolean;
  @Input() allowKeystrokeResponses: boolean;
  @Input() confirmButtonText = 'OK';
  @Input() cancelButtonText = 'Cancel';

  @Output() callback = new EventEmitter<boolean>();
  enterPressObs$ = observableFromEvent(document, 'keydown').pipe(
    filter((keyEv: KeyboardEvent) => keyEv.keyCode === 13 || keyEv.keyCode === 27));
  subsArr$: Subscription[] = [];
  emitted = false;

  ngOnInit() {
    // this allows us to default to allowing keystrokes, if you dont want to allow them
    // you have to explicitly add allowKeystrokeResponses: false in your popup instance
    // hopefully, we'll be able to get rid of the exception in digitization-top-nav.component
    // soon
    if (this.allowKeystrokeResponses || this.allowKeystrokeResponses === undefined) {
      this.subsArr$.push(
        this.enterPressObs$
          .subscribe((data: KeyboardEvent) => {
            if (this.emitted) {
              return;
            }
            if (data.keyCode === 13) {
              this.answer(true);
            } else {
              this.answer(false);
            }
            this.emitted = true;
          })
      );
    }
  }

  ngOnDestroy() {
  }
  /**
   * helper to close popup by emitting answer to parent component
   */
  answer(a: boolean) {
    this.callback.emit(a);
    this.popup.close();
  }
}
