import { NgModule } from '@angular/core';

import { NgxPopupComponent } from './components/popup.component';
import { NgxPopupsManagerComponent } from './components/popups-manager.component';
import { NgxPopupService } from './services/ngx-popup.service';
import { NgxDOMComponentModule } from '../ngx-dom-component/ngx-dom-component.module';

@NgModule({
  imports: [NgxDOMComponentModule],
  declarations: [
    NgxPopupComponent, NgxPopupsManagerComponent
  ],
  providers: [
    NgxPopupService
  ],
  exports: [
    NgxPopupComponent, NgxPopupsManagerComponent
  ],
  entryComponents: [
    NgxPopupComponent
  ]
})
export class NgxPopupModule {
}
