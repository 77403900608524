/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentFileCreate } from '../model/models';
import { DocumentFileRead } from '../model/models';
import { InlineResponse200 } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


// custom fincura functions

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const toSnake = (s) => {
   return s.replace(/[\w]([A-Z])/g, function(m) {
       return m[0] + "_" + m[1];
   }).toLowerCase();
}

const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

const keysToSnake = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toSnake(k)] = keysToSnake(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i);
    });
  }

  return o;
};
// end custom fincura functions



@Injectable({
  providedIn: 'root'
})
export class FilesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Submit a new DocumentFile
     * Add a new file to Fincura for processing.  The minimal document creation request requires just a borrower_uuid and the media_type of the file to process.       POST /v1/document-file HTTP/1.1     Host: api-local.fincura.com:8001     Accept: application/json     Authorization: Bearer eyJ0e...nFTRKTbcg     Content-Type: application/json      {         \&quot;borrower_uuid\&quot;: \&quot;6e64724a-25fc-4e1a-8071-1ef29845ab1d\&quot;,         \&quot;media_type\&quot;: \&quot;application/pdf\&quot;\&quot;     }       The above request will create a new document record in Fincura and return a url to send the file to, as seen below.      {         \&quot;uuid\&quot;: \&quot;5df0d3b2-b1f6-42aa-816b-7a83b4447a78\&quot;,         \&quot;upload_url\&quot;: \&quot;https://upload.fincura.com/5df0d3b2-b1f6-42aa-816b-7a83b4447a78/document.pdf?signature&#x3D;mWFg4xsgm4EtFEt\&quot;     }  In a subsequent request, the file can be sent to the &#x60;upload_url&#x60; via [HTTP PUT](https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT). The &#x60;Content-Length&#x60; and &#x60;Content-Type&#x60; headers are required. The &#x60;Content-Type&#x60; should match the &#x60;media_type&#x60; from the previous request.      PUT /5df0d3b2-b1f6-42aa-816b-7a83b4447a78/document.pdf?signature&#x3D;mWFg4xsgm4EtFEt HTTP/1.1     Host: upload.fincura.com     Content-Length: 18828     Content-Type: application/pdf          {FILE  DATA}  Fincura will begin processing the file once it has been completely uploaded.  Additional fields described below:
     * @param documentFileCreate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentFile(documentFileCreate?: DocumentFileCreate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DocumentFileCreate>;
    public createDocumentFile(documentFileCreate?: DocumentFileCreate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DocumentFileCreate>>;
    public createDocumentFile(documentFileCreate?: DocumentFileCreate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DocumentFileCreate>>;
    public createDocumentFile(documentFileCreate?: DocumentFileCreate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (API_Key) required
        credential = this.configuration.lookupCredential('API_Key');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        const snakeObj = keysToSnake(documentFileCreate);
        return this.httpClient.post<DocumentFileCreate>(`${this.configuration.basePath}/v1/document-file`,
            snakeObj,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((data) => {
                const camelObj = keysToCamel(data);
                return <DocumentFileCreate> camelObj;
            })
        );
    }

    /**
     * Delete a DocumentFile
     * Delete a file from Fincura.
     * @param uuid
     * @param borrowerUuid Borrower to filter results for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public destroyDocumentFile(uuid: string, borrowerUuid?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public destroyDocumentFile(uuid: string, borrowerUuid?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public destroyDocumentFile(uuid: string, borrowerUuid?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public destroyDocumentFile(uuid: string, borrowerUuid?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling destroyDocumentFile.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (borrowerUuid !== undefined && borrowerUuid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>borrowerUuid, 'borrower_uuid');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (API_Key) required
        credential = this.configuration.lookupCredential('API_Key');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }


        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/document-file/${encodeURIComponent(String(uuid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((data) => {
                const camelObj = keysToCamel(data);
                return <any> camelObj;
            })
        );
    }

    /**
     * List DocumentFile records
     * List files submitted to Fincura
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @param borrowerUuid Borrower to filter results for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listDocumentFiles(limit?: number, offset?: number, borrowerUuid?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public listDocumentFiles(limit?: number, offset?: number, borrowerUuid?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public listDocumentFiles(limit?: number, offset?: number, borrowerUuid?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public listDocumentFiles(limit?: number, offset?: number, borrowerUuid?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (borrowerUuid !== undefined && borrowerUuid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>borrowerUuid, 'borrower_uuid');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (API_Key) required
        credential = this.configuration.lookupCredential('API_Key');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }


        return this.httpClient.get<InlineResponse200>(`${this.configuration.basePath}/v1/document-file`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((data) => {
                const camelObj = keysToCamel(data);
                return <InlineResponse200> camelObj;
            })
        );
    }

    /**
     * Get the status of a submitted DocumentFile
     * Retrieve a file submitted to Fincura
     * @param uuid
     * @param borrowerUuid Borrower to filter results for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveDocumentFile(uuid: string, borrowerUuid?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DocumentFileRead>;
    public retrieveDocumentFile(uuid: string, borrowerUuid?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DocumentFileRead>>;
    public retrieveDocumentFile(uuid: string, borrowerUuid?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DocumentFileRead>>;
    public retrieveDocumentFile(uuid: string, borrowerUuid?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling retrieveDocumentFile.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (borrowerUuid !== undefined && borrowerUuid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>borrowerUuid, 'borrower_uuid');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (API_Key) required
        credential = this.configuration.lookupCredential('API_Key');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }


        return this.httpClient.get<DocumentFileRead>(`${this.configuration.basePath}/v1/document-file/${encodeURIComponent(String(uuid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((data) => {
                const camelObj = keysToCamel(data);
                return <DocumentFileRead> camelObj;
            })
        );
    }

}
