import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgxPopupService, NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/ngx-popups';
import { InstructionsGifPopupComponent } from '../../../shared/popups/instructions-gif-popup/instructions-gif-popup.component';
import { UserService } from '../../../../services/user.service';
import { InstructionsStep } from '../../../../models/instructions-step';

@Component({
  selector: 'app-maximized-sidebar',
  templateUrl: './maximized-sidebar.component.html',
  styleUrls: ['./maximized-sidebar.component.scss']
})
export class MaximizedSidebarComponent {
  @Input() model;
  @Input() stepHeader = '';
  @Input() steps: InstructionsStep[] = [];
  @Input() tipsBody;
  @Input() fullGif;
  @Input() title;
  @Output('toggleSidebar') _toggleSidebar = new EventEmitter();

  constructor(private _popupService: NgxPopupService,
    private _userService: UserService
              ) { }

  showExample(gifName = '', title = '') {
    this._popupService.open({
      componentType: InstructionsGifPopupComponent,
      cssClass: 'modal-instructions-gif',
      inputs: {
        title: title,
        gif: gifName
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, { once: true });
    });
  }

  public toggleSidebar(): void {
    this._toggleSidebar.emit();
  }
}
