<div class="card mb-3">
    <div class="card-header" (click)="navigateToTemplateEditor()"
         [ngClass]="{
            'clickable': active,
            'template-disable-header-click': !active
         }">
        <div class="row">
            <div class="col-10">
                <h5 class="card-title mb-0">{{name}}</h5>
            </div>
            <div class="col-2" *ngIf="active">
                <i class="fas fa-pencil-alt"></i>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-7">
                Created: {{createdAt | formatDate}}<br>
                Num Borrowers: {{numBorrowers}}
            </div>
            <div class="col-5 default-info">
                <ng-container *ngIf="isDefault"><i class="fas fa-star-of-life"></i> Default</ng-container>
            </div>
        </div>
        <div class="action-row template-action-row" *ngIf="this.active">
            <div>
                <button class="btn btn-empty-primary" (click)="downloadCsv()">
                    <span>Download <i class="fas fa-file-csv"></i></span>
                </button>
            </div>
            <div>
                <button class="btn btn-empty-primary duplicate-link" (click)="duplicateTemplateUsingTemplateId()">
                    Duplicate <i class="far fa-copy"></i>
                </button>
            </div>
            <div>
                <button class="btn btn-empty-primary duplicate-link" (click)="disableOrRestoreAnalysisTemplate()">
                    Disable <i class="fas fa-undo"></i>
                </button>
            </div>
        </div>
        <div class="row action-row" *ngIf="!this.active">
            <div class="col-5">
                <a (click)="disableOrRestoreAnalysisTemplate()">
                    <span>Restore <i class="fas fa-redo"></i></span>
                </a>
            </div>
        </div>
    </div>
</div>
