import { Pipe, PipeTransform } from '@angular/core';
import { DOC_TYPES } from '../utils/constants';
import { DocumentTypeLabel, FormulaOptionTypeLabel } from '../utils/enums';

@Pipe({name: 'docType'})
export class DocTypePipe implements PipeTransform {
  /**
   *
   * @param {string} value
   * @returns {string}
   */
  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }
}


@Pipe({name: 'docTypeLabel'})
export class DocTypeLabelPipe implements PipeTransform {
  transform(item: any): string {
    return DocumentTypeLabel[item.documentType];
  }
}

@Pipe({name: 'formualOptionTypeLabel'})
export class FormulaOptionTypeLabelPipe implements PipeTransform {
  transform(item: any): string {
    if (item.className === 'FormulaItem')  {
      return (item.subFormula == null) ? 'Raw' : 'Norm';
    }
    return FormulaOptionTypeLabel[item.className];
  }
}
