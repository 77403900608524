import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { InputCalendarComponent } from './input-calendar.component';
import { FileUploadModule } from 'ng2-file-upload';
import { PipesModule } from '../../../../pipes/pipes.module';
import { TextMaskModule } from 'angular2-text-mask'
import { NgxMyDatePickerModule } from '../../ngx-my-date-picker';

@NgModule({
    declarations: [InputCalendarComponent],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        PipesModule,
        FormsModule,
        FileUploadModule,
        ReactiveFormsModule,
        TextMaskModule,
        NgxMyDatePickerModule
    ],
    exports: [InputCalendarComponent],
    providers: [],
})

export class InputCalendarModule {
}
