import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxPopupComponent, NgxPopupService} from '../ngx-popups/ngx-popups/ngx-popups';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {Company} from '@models/company';
import {
  AccountingIntegrationImportComponent
} from '@components/main/borrower/accounting-integration/accounting-integration-import/accounting-integration-import.component';
import {COMPANY_ENTITLEMENT_DATA} from "@utils/constants";
import {
  UploadDocumentModalComponent
} from "@components/shared/popups/upload-document-modal/upload-document-modal.component";


@Component({
  selector: 'app-upload-statement',
  templateUrl: './upload-statement.component.html',
  styleUrls: ['./upload-statement.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class UploadStatementButtonComponent implements OnInit {
  @Input() company: Company;
  @Input() entitlementData;

  subsArr$: Subscription[] = [];
  popup: NgxPopupComponent;
  hasManageDocumentPermission: boolean;

  constructor(
    private router: Router,
    private popupService: NgxPopupService,
  ) { }

  ngOnInit() {
    let currentRoute = this.router.routerState.root.firstChild;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    this.hasManageDocumentPermission = this.entitlementData[COMPANY_ENTITLEMENT_DATA.SHOW_REQUEST_STATEMENTS_BUTTON];

  }

  openUploadDocumentModal() {
    this.popupService.open({
      componentType: UploadDocumentModalComponent,
      cssClass: 'doc-upload-modal',
      inputs: {
        company: this.company
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (typeof(data.detail) === 'object' && data.detail?.reOpenModal){
          this.openUploadDocumentModal();
        }
      }, {once: true});
    });
  }

  hasIntegration(accounting_integration) {
    let result = false;
    if (this.company) {
      if (this.company.accountingIntegrations.indexOf(accounting_integration) > -1) {
        result = true;
      }
    }
    return result;
  }

  startIntegrationImport(accounting_integration) {
    this.popupService.open({
      componentType: AccountingIntegrationImportComponent,
      cssClass: 'modal-import-statements',
      inputs: {
        vendor: accounting_integration,
        company: this.company
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }
}
