<div class="row" *ngFor="let stage of stages">
	<div class="offset-1 col-12">
		<ng-container *ngIf="stage.complete">
			<h3><i *ngIf="!stage.passing" class="far fa-times-circle failure"></i><i *ngIf="stage.passing" class="far fa-check-circle success"></i>&#160; {{stage.label}}</h3>
		</ng-container>
		<ng-container *ngIf="!stage.complete">
			<h3><i class="fas fa-hourglass-half"></i>&#160; {{stage.label}}</h3>
		</ng-container>
	</div>
</div>








