<div
    (mouseenter) ="onHover()"
    (mouseleave) ="onHoverEnd()"
    class="tile-container line-item-tile-container"
>
    <div class="row">
        <div class="col-md-7 line-item-name">
            {{title}}
        </div>
        <div class="col-md-5 percent-change align-right">
            {{analyticsService.formattedPopChange(analyticsItemValues)}}
        </div>
    </div>
    <div class="row bottom-row">
        <div class="current-value" [ngClass]="isExtraLongFormattedValue ? 'col-md-12' : 'col-md-6'">
            {{analyticsService.formattedRecentValue(analyticsItemValues)}}
        </div>
        <div
            class="graph-container"
            [ngClass]="{
                'col-md-12': isExtraLongFormattedValue,
                'full-width-graph-padding': isExtraLongFormattedValue,
                'col-md-6': !isExtraLongFormattedValue,
                'no-padding': !isExtraLongFormattedValue
        }">
            <app-no-axis-bar-chart
                [isShown]="isHover"
                [dataToGraph]="graphData"
                [isActive]="isHover"
            >
            </app-no-axis-bar-chart>
        </div>
    </div>
</div>
