import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, Renderer2} from "@angular/core";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {AlertService} from "@services/alert.service";
import {User} from "@models/user";
import {ConfirmationPopupComponent} from "@components/shared/popups/confirmation/confirmation-popup.component";
import {UserService} from "@services/user.service";
import {AutoUnsubscribe} from "@decorators/auto-unsubscribe";
import {Subscription} from "rxjs";
import {deepCopy} from "@utils/functions";


@Component({
  selector: "[user-list-tr]",
  styleUrls: ["./list.component.scss"],
  template: `
    <td>{{user.email}}</td>
    <td>{{user.name}}</td>
    <td>
      <div class="role-container">
        <span *ngFor="let role of user.roles; last as isLast">{{role}}{{isLast ? "" : ","}}</span>
      </div>
    </td>
    <td>{{user.createdDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
    <td>
      <p *ngIf="user.active else inactiveBadge" class="status-badge active-badge"><i class="far fa-circle-check"></i>Active
      </p>
      <ng-template #inactiveBadge><p class="status-badge"><i class="fa fa-user-slash"></i>Inactive</p></ng-template>
    </td>
    <td>
      <div class="action-menu-wrapper">
        <div (click)="toggleActionMenu($event)">
          <i class="action-menu-icon fas fa-ellipsis"></i>
        </div>
        <ul class="dropdown-menu" *ngIf="actionMenuIsOpen">
          <li
            *ngIf="user.thirdPartyStatus == 'FORCE_PASSWORD_RESET' || user.thirdPartyStatus == 'FORCE_CHANGE_PASSWORD'"
            (click)="forcePasswordReset(user, $event)">
            <span>Force Password Reset</span>
          </li>
          <li *ngIf="user.active; else enableAction" (click)="toggleUserState(user)"><span>Disable</span></li>
          <ng-template #enableAction>
            <li (click)="toggleUserState(user)"><span>Enable</span></li>
          </ng-template>
        </ul>
      </div>
    </td>
  `,
})
@AutoUnsubscribe('subsArr$')
export class ListTableRowComponent implements OnDestroy {
  @Input() user: User;
  @Output() refreshUsers = new EventEmitter<boolean>()

  subsArr$: Subscription[] = [];

  actionMenuIsOpen = false;

  clickEventUnlisten: () => void;
  buttonClickEventUnlisten: () => void;

  constructor(
    private _popupService: NgxPopupService,
    private alertService: AlertService,
    private userService: UserService,
    private _renderer: Renderer2,
    private _changeDetector: ChangeDetectorRef,
  ) {
  }

  toggleActionMenu($event: Event) {
    if (!this.actionMenuIsOpen) {
      this.actionMenuIsOpen = true;
      setTimeout(() => {
        // Listen for click events anywhere in the document (including inside other row action menu buttons) then close
        // the current menu. The timeout ensures that this listener will not be established until the current event
        // that triggered the menu-open has finished propagating.
        // The listen() function returns an 'unlisten' function to destroy the handler. This is called on menu close
        // and onDestroy to clean up and avoid memory leaks.
        this.clickEventUnlisten = this._renderer.listen("document", "click", event => {
          this.closeActionMenu();
        });
      });
    } else {
      this.closeActionMenu();
    }
  }

  closeActionMenu() {
    this.actionMenuIsOpen = false;
    this.clickEventUnlisten ? this.clickEventUnlisten() : null;
  }

  forcePasswordReset(user: User, evt: any) {
    evt.preventDefault();
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Are you sure you want to force reset this user\'s password?',
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.subsArr$.push(this.userService.forcePasswordReset(user)
              .subscribe(
                data => {
                  this.alertService.success('Successfully reset user password locking. Please let them know that they are now able to go through the process to reset their password.');
                  this.refreshUsers.emit(true);
                },
                error => {
                  this.alertService.error(error.message);
                }
              ));
          }
        }
      },
    });
  }

  // Clone the user object to defer updating the status display
  // till successful status toggle
  toggleUserState(user: User) {
    const userCopy = deepCopy(user);
    userCopy.active = !userCopy.active;
    this.subsArr$.push(this.userService.setUserStatus(userCopy)
      .subscribe(
        data => {
          const userResponse = data.pop();
          // Though the backend api reported success at this point,
          // check the response object has its 'active' flag
          // toggled just to be sure.
          if (userResponse.active === user.active) {
            this.alertService.error('Error: Unable to change user status for ' + user.name);
          } else {
            user.active = !user.active;
            this.alertService.success('User ' + user.name +
              '\'s status is now ' + (user.active ? 'Active' : 'Inactive'));
          }
        },
        error => {
          this.alertService.error(error.message);
        }
      )
    );
  }

  ngOnDestroy() {
    this.clickEventUnlisten ? this.clickEventUnlisten() : null;
  }
}
