import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  HostListener,
  Input,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {DataViewColumn, DataViewRow, DataViewRowFormattingOptions} from '../../../../../../../../models/dataview';
import {ContextMenuDocument} from './cell/context-menu';
import {Footnote} from '../../../../../../../../models/footnote';
import {DocumentFileService} from '../../../../../../../../services/document-file.service';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '../../../../../../../../decorators/auto-unsubscribe';
import {Router} from '@angular/router';
import {LaunchDarklyService} from '@services/launchdarkly.service';
import {NumberFormattingService} from '@services/number-formatting.service';
import {SCENARIO_TYPE_PROJECTION} from '@utils/constants';
import {CommonFunctions} from '@utils/common-functions';
import {StatementService} from '@services/statement.service';
import {ProjectionHelperService} from '@services/projection-helper.service';

declare var bootstrap: any;

@Component({
  selector: 'app-financials-table',
  templateUrl: './financials-table.component.html',
  styleUrls: ['./financials-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe('subsArr$')
export class FinancialsTableComponent implements OnChanges, DoCheck, OnInit, AfterViewInit {
  subsArr$: Subscription[] = [];
  @Input() roundToThousands: boolean;
  @Input() rows: Array<DataViewRow> = [];
  @Input() columns: Array<DataViewColumn> = [];
  @Input() hiddenRowIndices: Set<number>;
  @Input() hiddenColumnIndices: Set<string>;
  @Input() showNumbers: boolean;
  @Input() showCommonSize: boolean;
  @Input() showTrend: boolean;
  @Input() showSourceItems: boolean;
  @Output() rowToggled = new EventEmitter<number>();
  @Output() toggleRowVisibility = new EventEmitter<number>();
  @Output() columnHidden = new EventEmitter<string>();
  @Input() documentMenuItems: Array<ContextMenuDocument> = [];
  @Input() allowEditCells: boolean;
  @Input() showSpreadingInContextMenu = true;
  @Input() showHidden: boolean;
  @Input() dollarSign: boolean;
  @Input() statementType: string;
  @Input() companyId: number;
  @Input() templated: boolean;
  @Input() projectionScenarioTypeLabelOverride = 'Projection';
  @Input() viewOnlyMode: boolean;
  @Input() embeddedMode = false;
  @Input() commonSizeDecimalPrecision = 1;
  @Output() openFootnotes = new EventEmitter<Footnote>();
  @Output() openInsights = new EventEmitter<any>();
  @Output() beginDeleteColumn = new EventEmitter<DataViewColumn>();
  @Output() beginEditColumn = new EventEmitter<DataViewColumn>();

  selectedRow = -1;
  selectedColumn = -1;
  displayRows: Array<DataViewRow> = [];
  iterableDiffer: any;
  showToolTip;
  toolTipPixelOffset = 120;
  isSourceStatement = false;
  allowHideZeros = false;
  sectionDrawerState: Map<number, boolean> = new Map<number, boolean>();
  lineItemIdToParentSectionIdMapping: Map<number, number> = new Map<number, number>();
  contextMenuOpenColumnIdx: number = -1;

  constructor(
    private _documentFileService: DocumentFileService,
    private _iterableDiffers: IterableDiffers,
    private _router: Router,
    private _changeDetector: ChangeDetectorRef,
    private _featureFlags: LaunchDarklyService,
    private _numberFormattingService: NumberFormattingService,
    private _statementService: StatementService,
    public _projectionHelperService: ProjectionHelperService,
  ) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  // Stop the highlighting when we click outside the element
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.selectedRow = -1;
    this.selectedColumn = -1;

    // unless the click event was on a cell-menu-toggle-icon, close column-level context menu
    if (!CommonFunctions.hasClassNameInHierarchy(event.target as HTMLElement, 'column-menu-toggle-icon')) {
      this.contextMenuOpenColumnIdx = -1;
    }
  }

  ngOnInit(): void {
    this.isSourceStatement = this.isASourceStatement();

    this.subsArr$.push(this._featureFlags.flagChange.subscribe(() => {
      this.setFlags();
    }));

    this.setFlags();

    this.initializeSectionDrawerStatesAndLineItemIdToParentSectionIdMappings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('rows') || changes.hasOwnProperty('columns') ||
      changes.hasOwnProperty('roundToThousands') || changes.hasOwnProperty('documentMenuItems') ||
      changes.hasOwnProperty('showNumbers') || changes.hasOwnProperty('showCommonSize') ||
      changes.hasOwnProperty('showTrend') || changes.hasOwnProperty('dollarSign') ||
      changes.hasOwnProperty('showSourceItems')) {
      this.prerenderData();
    }
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.hiddenColumnIndices);
    if (changes) {
      this.setHiddenColumns();
    }
  }

  ngAfterViewInit() {
    setTimeout(this.enableTooltips, 2000);
  }

  enableTooltips() {
    // Bootstrap tooltip initialization
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  showTip(index) {
    this.showToolTip = true;
    this._changeDetector.detectChanges();
    const icon = document.getElementById('tipIcon' + index);
    const coord = icon.getBoundingClientRect();
    const tip = document.getElementById('tip');
    tip.setAttribute('style', 'left:' + (coord.left - this.toolTipPixelOffset) + 'px;');
  }

  hideTip() {
    this.showToolTip = false;
  }

  setHiddenColumns() {
    for (let j = 0; j < this.columns.length; j++) {
      const keyString = this.columns[j].toKeyString();
      if (this.hiddenColumnIndices.has(keyString)) {
        this.columns[j].isHidden = true;
      } else {
        this.columns[j].isHidden = false;
      }
    }
  }

  /**
   * Add attributes the cells, rows, columns, etc so we don't have to figure them out every time we render the view
   */
  prerenderData() {
    if (this.allowHideZeros) {
      this.runHiddenRowRules();
    }
    this.columns.forEach((col, colIdx) => {
      col.documentOptions = this.documentOptionsForColumn(col);
      if ((col.cellFormat.textFormat === 'PERCENT') ||
        (this.hiddenColumnIndices.has(col.toKeyString()))) {
        col.isHidden = true;
      } else {
        col.isHidden = false;
      }
      col.trendIsHidden = !this.showTrend;
      col.commonSizeIsHidden = !this.showCommonSize;

      col.cells.forEach((cell, cellIdx) => {
        const row = this.rows[cellIdx];
        cell.formattedValue = this.formatItem(row, col, cellIdx, true);

        if ((col.cellFormat.textFormat === 'PERCENT') ||
          (this.hiddenColumnIndices.has(col.toKeyString()))) {
          cell.isHidden = true;
        } else {
          cell.isHidden = false;
        }
        cell.documentOptions = this.documentOptionsForCell(cell, col);
      })
    });
  }

  initializeSectionDrawerStatesAndLineItemIdToParentSectionIdMappings() {
    let currentSectionLineItemId = -1;
    this.rows.forEach(row => {
      if (row.rowFormat && row.rowFormat.header) {
        this.sectionDrawerState[row.lineItem.id] = true;
        currentSectionLineItemId = row.lineItem.id;
        row.indentation = -1;
      }
      this.lineItemIdToParentSectionIdMapping[row.lineItem.id] = currentSectionLineItemId;
    })
  }

  formatItem(row: DataViewRow, column: DataViewColumn, idx: number, showOriginal = false): string {
    const value = column.cells[idx].calculatedValue;
    if (row.rowFormat.header) {
      return '';
    }
    if (value) {

      if (!!value.error) {
        switch (value.error) {
          case '#REF':
            if (row.cellFormat.replaceRef) {
              return row.cellFormat.replaceRef;
            } else if (column.cellFormat.replaceRef) {
              return column.cellFormat.replaceRef;
            }
            return '#REF';
          case '#DIV/0':
            if (row.cellFormat.replaceDivzero) {
              return row.cellFormat.replaceDivzero;
            } else if (column.cellFormat.replaceDivzero) {
              return column.cellFormat.replaceDivzero;
            }
            return '#DIV/0';
          default:
            return '#ERR';
        }
      }

      let textFormat = column.cellFormat.textFormat;
      const numDecimals = row.rowFormat.numDecimals;

      if (!textFormat) {
        textFormat = row.cellFormat.textFormat;
      }

      const options = { showDollarSign: this.dollarSign, currency: column.currencyFormat, isRoundedToThousands: this.roundToThousands, rows: this.rows };
      return this._numberFormattingService.formatNumber(value.value, textFormat, numDecimals, options);
    }
    return '-';
  }

  getPercent(column: DataViewColumn, idx: number, isHeader: boolean, cellPropertyName: string, decimalPrecision = 1) {
    if (isHeader) {
      return '';
    }
    const cell = column.cells[idx];
    if (cell[cellPropertyName] && cell[cellPropertyName].value !== null && cell[cellPropertyName].value !== undefined) {
      return (cell[cellPropertyName].value * 100).toFixed(decimalPrecision).toString() + '%';
    } else {
      return '-';
    }
  }

  getTrend(column: DataViewColumn, idx: number, isHeader: boolean) {
    return this.getPercent(column, idx, isHeader, 'trendValue');
  }

  getCommonSize(column: DataViewColumn, idx: number, isHeader: boolean) {
    return this.getPercent(column, idx, isHeader, 'commonSizedValue', this.commonSizeDecimalPrecision);
  }

  runHiddenRowRules(): void {
    this.rows.forEach((row, rowIdx) => {
      let wasNonZeroFound = false;
      this.columns.forEach((col) => {
        if (col.cells[rowIdx].calculatedValue && col.cells[rowIdx].calculatedValue.value !== 0) {
          wasNonZeroFound = true;
        }
      });

      if (
        !wasNonZeroFound &&
        row.rowFormat &&
        !row.rowFormat.header &&
        (row.rowFormat.valueSource !== 'CalculatedItem') &&
        !row.rowFormat?.isParent
      ) { // if all zeros and not bold and not a calculation, hide due to zeros
        this.rows[rowIdx].rowFormat['isHiddenDueToZeros'] = true;
      } else {
        this.rows[rowIdx].rowFormat['isHiddenDueToZeros'] = false;
      }
    });
  }

  // making sure item has expected value to not blow up
  isItemAvailable(column: DataViewColumn, idx: number): boolean {
    if (idx >= 0 && column && column.cells &&
      column.cells[idx] && column.cells[idx].calculatedValue) {
      return true;
    }
    return false;
  }

  itemFootnote(column: DataViewColumn, idx: number) {
    let value = null;
    if (this.isItemAvailable(column, idx)) {
      const possibleValue = column.cells[idx].calculatedValue.footnotes
      if (possibleValue && possibleValue.length > 0) {
        value = possibleValue;
      }
    }

    return value;
  }

  itemValue(column: DataViewColumn, idx: number) {
    let value;
    if (this.isItemAvailable(column, idx)) {
      value = column.cells[idx].calculatedValue.value;
    } else {
      value = 0;
    }
    return value;
  }

  originalItemValue(column: DataViewColumn, idx: number) {
    let value;
    if (this.isItemAvailable(column, idx)) {
      value = column.cells[idx].calculatedValue.originalValue;
    } else {
      value = 0;
    }
    return value;
  }

  selectedCell(rowIdx: number, colIdx: number) {
    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
    this.contextMenuOpenColumnIdx = -1;
  }

  toggleSectionDrawerState(lineItemId: number) {
    this.sectionDrawerState[lineItemId] = !this.sectionDrawerState[lineItemId];
  }

  getClassForSectionToggle(lineItemId: number) {
    if (this.isSourceStatement) {
      return '';
    }
    for (const childId in this.lineItemIdToParentSectionIdMapping) {
      if (this.lineItemIdToParentSectionIdMapping[childId] === lineItemId && Number(childId) !== lineItemId) {
        return this.sectionDrawerState[lineItemId] ? 'fas fa-chevron-down' : 'fas fa-chevron-right';
      }
    }
    return '';
  }

  getRowFormatClass(rowFormat: DataViewRowFormattingOptions, idx: number): string {
    if (rowFormat.isHiddenDueToZeros) {
      return 'hidden';
    }


    if (this.hiddenRowIndices.has(idx)) {
      if (this.showHidden) {
        return 'grayed';
      } else {
        return 'hidden';
      }
    }

    if (!rowFormat) {
      return '';
    }

    if (rowFormat.header && rowFormat.bold) {
      return 'header bold';
    }

    if (rowFormat.header) {
      return 'header';
    }

    if (rowFormat.bold) {
      return 'bold'
    }

    return '';
  }

  toggleRow(evt = null, idx: number) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.rowToggled.emit(idx);
  }

  hideRow(evt = null, idx: number) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.toggleRowVisibility.emit(idx);
  }

  hideColumn(evt = null, idx: number) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
      this.contextMenuOpenColumnIdx = -1;
    }
    const idxStr = this.columns[idx].toKeyString();
    this.columnHidden.emit(idxStr);
  }

  selectCell(row: DataViewRow, column: DataViewColumn, rowIdx: number, colIdx: number) {
    if (this.selectedRow === rowIdx && this.selectedColumn === colIdx) {
      this.selectedRow = -1;
      this.selectedColumn = -1;
      return;
    }

    this.selectedRow = rowIdx;
    this.selectedColumn = colIdx;
    this.contextMenuOpenColumnIdx = -1;
    return;
  }

  insightsSelected(evt) {
    const payload = {
      reportingInterval: this.columns[this.selectedColumn].reportingInterval,
      statementDate: this.columns[this.selectedColumn].statementDate,
      preparationType: this.columns[this.selectedColumn].preparationType,
      lineItemRef: 'REF_' + this.rows[this.selectedRow].ref,
      companyId: this.companyId,
      scenario: this.columns[this.selectedColumn].scenario,
      projectionName: this.columns[this.selectedColumn].projectionName,
      currencyFormat: this.columns[this.selectedColumn].currencyFormat
    }

    this.openInsights.emit(payload);
  }

  deletePeriodColumn(column: DataViewColumn) {
    this.beginDeleteColumn.emit(column);
  }

  editPeriodColumn(column: DataViewColumn){
    this.beginEditColumn.emit(column)
  }

  // filters the documentOptions for a particular column
  // a column could in theory have up to 3 docs associates with it (1 per statement type)
  documentOptionsForColumn(column: DataViewColumn) {
    return this.documentMenuItems.filter(i => column.fileIds.includes(i.id));
  }

  /*
    filters the documentOptions for a cell (in the context menu at the cell level, we only want docs from which that cell was derived from to appear)
    to do this we first get the sourceStatementId for the cell
    then, we iterate through the documentOptions search for ones that that have associated statements that match the cell source statement
    if we do not find any documentOptions with matching sourceStatementIds, we will just return all the documentOptions for the column
  */
  documentOptionsForCell(cell, col) {
    if (this.isSourceStatement) {
      return col.documentOptions;
    }
    const fileIds = [];
    const calculatedValue = cell.calculatedValue;
    if (calculatedValue !== null && calculatedValue.sourceStatementId !== 0) {
      const sourceStatementId = calculatedValue.sourceStatementId;
      for (let statementOption of col.documentOptions) {
        if (statementOption.associatedStatements.includes(sourceStatementId)) {
          fileIds.push(statementOption.id);
        }
      }
    }
    const filteredOptions = this.documentMenuItems.filter(i => fileIds.includes(i.id));
    return filteredOptions.length < 1 ? [] : filteredOptions;
  }

  columnLevelContextMenuDocumentClicked(doc: ContextMenuDocument, evt, action) {
    doc.action = action;
    evt.preventDefault();
    this.contextMenuOpenColumnIdx = -1;
    this.contextMenuItemSelected(doc);
  }

  contextMenuItemSelected(doc: ContextMenuDocument) {
    // Open the download link in a new window
    const docFileId = doc.id; // statement.id is a weirdly named variable (and incorrect).
    if (doc.action === 'download') {
      this.subsArr$.push(this._documentFileService.getDownloadUrlForOriginalDocument(docFileId).subscribe(data => {
        const url = data.originalFileUrl;
        const link = document.createElement('a');
        link.href = url;
        link.download = doc.label;
        link.target = '_blank';
        link.dispatchEvent(new MouseEvent('click'));
      }));
    }
    // Bring to spreading screen for this statement
    if (doc.action === 'spread') {
      if (
        this.selectedColumn === -1 ||
        this.selectedRow === -1 ||
        this.columns[this.selectedColumn].cells[this.selectedRow].calculatedValue === null ||
        this.columns[this.selectedColumn].cells[this.selectedRow].calculatedValue.sourceStatementId === 0
      ) {
        this._statementService.loadStatementsByDocFileId(docFileId).subscribe(returnedStatement => {
          this._router.navigate(['statements', returnedStatement.uuid, 'edit']);
        })
      } else {
        const statementId = this.columns[this.selectedColumn].cells[this.selectedRow].calculatedValue.sourceStatementId;
        this._statementService.loadStatement(statementId).subscribe(returnedStatement => {
          this._router.navigateByUrl('/statements/' + returnedStatement.uuid + '/edit', {
            replaceUrl: true,
          });
        });
      }
    }
  }

  isASourceStatement(): boolean {
    return this.statementType === 'Source Income Statement' || this.statementType === 'Source Balance Sheet' || this.statementType === 'Source Cash Flow Statement';
  }

  enterFootnotesMode(event: Footnote): void {
    this.openFootnotes.emit(event);
  }

  setFlags() {
    this.allowHideZeros = this._featureFlags.flags['hide-zeros-in-financials'];
    this.prerenderData();
  }

  trackColumn(index, column) {
    return index;
  }

  columnIsProjection(column: DataViewColumn): boolean {
    return column.scenario === SCENARIO_TYPE_PROJECTION;
  }

  parentItemIsHidden(row: DataViewRow) {
    let aboveRow = false;
    for (let i = this.rows.length - 1; i >= 0; i--) {
      if (!aboveRow && this.rows[i].lineItem.id === row.lineItem.id) {
        aboveRow = true;
      } else if (aboveRow && this.rows[i].indentation < row.indentation) {
        return this.hiddenRowIndices.has(i);
      }
    }
    return false;
  }

  toggleColumnContextMenu(idx) {
    if (this.contextMenuOpenColumnIdx !== idx) {
      this.contextMenuOpenColumnIdx = idx;
    } else {
      this.contextMenuOpenColumnIdx = -1;
      this.selectedColumn = -1;
    }
  }
}
