<div>
    <div class="modal__header">
        <div class="modal__title">
            <i class="fa fa-clock"></i>
            Session Expiring
        </div>
    </div>
    <div class="modal__content">
        <div class="warning-message">
            Your session will expire in {{ timeRemaining$ | async | date:'mm:ss' }}<br>
            Select 'Continue Session' to stay logged in.
        </div>
    </div>
    <div class="modal__footer">
        <button class="btn btn-empty-primary" (click)="logout()">Logout</button>
        <button class="btn btn-primary" (click)="continueSession()">Continue Session</button>
    </div>
</div>
