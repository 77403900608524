import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Algorithm } from '../models/algorithm';
import { BulkOperation } from '../models/bulk-operation';
import { Company } from '../models/company';
import { Operation } from '../models/operation';
import { PeriodOperation } from '../models/period-operation';
import { DocumentDate } from '../models/document-date';
import { Template } from '../models/template';
import { ApiService } from './api.service';
import { LocalStorageService } from '../components/shared/local-storage/local-storage.service';
import CriteriaBuilder from '../utils/query/criteria';
import { DocumentType, SpreadingState, ResponseTypes } from '../utils/enums';

@Injectable()
export class AlgorithmsService {
  editSectionState$: BehaviorSubject<string> = new BehaviorSubject('closed');
  validationState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedOperation$: BehaviorSubject<Operation> = new BehaviorSubject(null);
  currentRawDataReadyAndExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  restoreIncorporatedElements$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataTransferDragEvent$: BehaviorSubject<DragEvent> = new BehaviorSubject(null);
  restoreFromMatching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unitTypeClick$: BehaviorSubject<MouseEvent> = new BehaviorSubject(null);
  calcIsIncome$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedUnitOperation$: BehaviorSubject<Operation> = new BehaviorSubject<Operation>(null);
  isOperationDifference$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  dates$: BehaviorSubject<DocumentDate[]> = new BehaviorSubject<DocumentDate[]>(null);
  preference$: BehaviorSubject<DocumentDate> = new BehaviorSubject<DocumentDate>(null);

  updateFormulas$: Subject<boolean> = new Subject();
  highlightIncorporatedItems$: Subject<any> = new Subject();
  highlightVariableKeysInEquations$: Subject<any> = new Subject();
  highlightMatchingRaw$: Subject<string | number> = new Subject();
  highlightMatchingOperation$: Subject<number> = new Subject();
  unitTypeSignalToClose$: Subject<boolean> = new Subject();
  categorizeRawDataTrigger$: Subject<boolean> = new Subject();
  restrictingDraggableIndex: Subject<number> = new Subject<number>();
  unitTypeIsOpen$: Subject<String> = new Subject<String>();
  createValidation$: Subject<void> = new Subject();
  resetValidationFormula$: Subject<void> = new Subject();
  docType: Subject<DocumentType> = new Subject();

  selectedMatchingRaw = null;
  selectedMatchingOperation = null;
  documentType: DocumentType;
  spreadingState$: Subject<SpreadingState> = new Subject<SpreadingState>();
  newOperationItems$: Subject<any[]> = new Subject<any[]>();
  ignoreUnmatchedData$: Subject<void> = new Subject<void>();
  rawDataIsExist$: Subject<boolean> = new Subject<boolean>();

  constructor(private _apiService: ApiService,
              private _localStorage: LocalStorageService,
  ) {
  }

  getTemplates(documentType: string): Observable<any> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('documentType', documentType));
    return this._apiService.send('Post', '/api/algorithm-templates/all', query.generate()).pipe(map(data => data.response.objects));
  }

  saveTemplate(request): Observable<any> {
    return this._apiService.send('Post', '/api/operations/save-template', request).pipe(map(data => data.response.objects));
  }

  getAllFormulaVariables(): Observable<any> {
    const payload = { };
    return this._apiService.send('Post', `/api/formulas/options`, payload).pipe(map((data: any) => data.response.objects));
  }

  getFormulaOptions(companyId: number, docType: DocumentType): Observable<any> {
    const payload = { companyId, docType };
    return this._apiService.send('Post', `/api/formulas/options`, payload).pipe(map((data: any) => data.response.objects));
  }

  /**
   *
   * @param {Algorithm} algorithm
   * @param {DocumentDate} preference
   * @returns {Observable<any>}
   */
  getValidations(algorithm: Algorithm, preference: DocumentDate): Observable<any> {
    const payload = {
      algorithmId: algorithm.id,
      ...preference,
    }

    return this._apiService.send('Post', `/api/algorithm-matchers/selected-date`, payload).pipe(map(data => data.response.objects));
  }

  /**
   *
   * @param {number} validationID
   * @returns {Observable<any>}
   */
  deleteValidation(validationID: number) {
    return this._apiService.send('Delete', '/api/algorithm-matchers/' + validationID);
  }

  /**
   * methods to work with Operations or it's operands
   * @param id
   * @returns {Observable<any>}
   */
  deleteOperationItem(id): Observable<any> {
    return this._apiService.send('Delete', '/api/operation-items/' + id);
  }

  /**
   *
   * @param object
   * @returns {Observable<any>}
   */
  saveOperation(object: any) {
    return this._apiService.send(object.id ? 'Put' : 'Post', '/api/operations' + (object.id ? ('/' + object.id) : ''), object).pipe(
      map(data => data.response.objects));
  }

  saveFormula(object: any, company: Company) {
    object['algorithm']['company'] = company;
    return this._apiService.send(object.id ? 'Put' : 'Post', '/api/formulas' + (object.id ? ('/' + object.id) : ''), object).pipe(
      map(data => data.response.objects));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<any>}
   */
  deleteOperation(id: number): Observable<any> {
    return this._apiService.send('Delete', '/api/operations/' + id);
  }

  /**
   * returns user pref or null
   * @param {number} companyId
   * @param {number} userId
   * @returns {Observable<any>}
   */
  getUserPreference(companyId: number, userId: number): Observable<DocumentDate> {
    const key = `userPreference-${companyId}-${userId}`;
    return this._localStorage.get(key);
  }

  /**
   * sets user pref
   * @param {number} companyId
   * @param {number} userId
   * @param data
   * @returns {Observable<void>}
   */
  setUserPreference(companyId: number, userId: string, data: DocumentDate): Observable<void> {
    const key = `userPreference-${companyId}-${userId}`;
    return this._localStorage.set(key, data);
  }

  buildTemplateObject(alg: Algorithm, template: Template): BulkOperation {
    const bulkOperation: BulkOperation = new BulkOperation(alg, alg.documentType);
    template.operations.forEach(operation => {
      bulkOperation.lineItems.push(operation);
    })
    if ('lockedOperations' in template) {
      template.lockedOperations.forEach(operation => {
        bulkOperation.lockedEquations.push(operation);
      });
    }
    return bulkOperation;
  }

  applyTemplate(alg: Algorithm, template: Template): Observable<any> {
    const bulkOperation = this.buildTemplateObject(alg, template)
    return this._apiService.send('Post', '/api/templates/apply', bulkOperation).pipe(
      mergeMap(data => observableOf(data.response.objects.pop())));
  }

  /**
   *
   * @param {number} companyId
   * @returns {Observable<Algorithm[]>}
   */
  companyGetAllAlgorithms(companyId: number): Observable<Algorithm[]> {
    const query = CriteriaBuilder.newQuery();
    query.add(CriteriaBuilder.equal('company.id', companyId));
    return this._apiService.send('Post', '/api/algorithms/all', query.generate()).pipe(
      map(data => data.response.objects));
  }

  /**
   *
   * @param {Operation[]} operations
   * @returns {Observable<any>}
   */
  saveOperations(operations: Operation[]) {
    return this._apiService.send('Post', '/api/operations/save-list', operations).pipe(
      map(data => data.response.objects));
  }
}
