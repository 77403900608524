<div class="container">
  <div class="row">
      <div class="col-sm-12">
      <div class="access_expired">
      <span class="ban"><i class="far fa-hourglass"></i></span>
      <h1>Session Expired</h1>
      <p>The session you are using has expired. Please refresh and try again.</p>
      <br /><br />
      <a href="/login">Login</a>
      </div>
    </div>
  </div>
  </div>
  