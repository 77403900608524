import { Injectable } from '@angular/core';
import { DataViewColumn } from '@models/dataview';

@Injectable({
  providedIn: 'root'
})
export class ProjectionHelperService {
  
  CHARS_IN_CONDENSED_PROJECTION_NAME = 11;

  constructor() { }

  atLeastOneProjectionColumnExists(columns: DataViewColumn[]) {
    for (const column of columns) {
      if (typeof column.projectionName === 'string' && column.projectionName !== '') {
        return true;
      }
    }
    return false;
  }

  getCondensedProjectionName(projectionName: string) {
    if (typeof projectionName === 'string') {
      return projectionName.length > this.CHARS_IN_CONDENSED_PROJECTION_NAME ? projectionName.slice(0, this.CHARS_IN_CONDENSED_PROJECTION_NAME) + '...' : projectionName;
    }
    return '';
  }
}
