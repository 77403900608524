import { Component, EventEmitter, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import { NgxPopupService } from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { LoanFormComponent } from '@components/shared/loan-form/loan-form.component';
import { Subscription } from 'rxjs';
import { LoanService } from '@services/loan.service';
import { AutoUnsubscribe } from 'app/decorators/auto-unsubscribe';
import { EnumToArrayPipe } from '../../../../../../../pipes/enum-to-array-pipe';
import { Loan } from '@services/fincura-ng-client/model/loan';
import { SharedDataService } from '@services/shared-data.service';


@Component({
  selector: 'app-proposed-debt-tile',
  templateUrl: './proposed-debt-tile.component.html',
  styleUrls: ['./proposed-debt-tile.component.css']
})
@AutoUnsubscribe('subsArr$')
export class ProposedDebtTileComponent implements OnInit, OnChanges {
  subsArr$: Subscription[] = [];

  @Input() proposedDebtId = -1;
  @Input() name = '';
  @Input() principal = -1;
  @Input() interestRate = .00;
  @Input() termMonths = 1;
  @Input() amortizationMethod = 'P&I';
  @Input() company = null;
  @Output() principalChange = new EventEmitter<any>();
  @Output() interestRateChange = new EventEmitter<any>();
  @Output() termMonthsChange = new EventEmitter<any>();
  @Output() amortizationMethodChange = new EventEmitter<any>();
  @Output() onRemoveProposedDebt = new EventEmitter<any>();
  @Output() nameChange = new EventEmitter<any>();
  @Output() numericalValuesChanged = new EventEmitter<any>();

  displayInterest: number = null;
  isLoanObjectTurnedOn = false;
  embeddedMode = false;

  paymentTypes = {
      PrincipalAndInterest: 'PRINCIPAL_AND_INTEREST',
      IoPeriod: 'IO_PERIOD',
  };
  // temporarily while we don't want PRINCIPAL_PLUS_INTEREST here
  // will be   paymentTypes = Loan.PaymentTypeEnum; when we support P+I as well

  constructor(
    private _popupService: NgxPopupService,
    private sharedData: SharedDataService,
    public loanService: LoanService,
  ) { }

  ngOnInit() {
    this.embeddedMode = this.sharedData.embeddedMode$.value;
    this.displayInterest = this.interestRate;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayInterest = this.interestRate;
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
  }

  bookLoan(): void {
    this._popupService.open({
      componentType: LoanFormComponent,
      cssClass: 'modal-add-loan',
      inputs: {
        loanNameValue: this.name,
        principalValue: this.principal,
        interestRateValue: this.displayInterest,
        termMonthsValue: this.termMonths,
        paymentTypeValue: this.loanService.enumPaymentTypeFromFriendlyName(this.amortizationMethod),
        company: this.company,
        formType: 'ADD',
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (data.detail) {
          // perform action if created new loan successfully here
        }
      }, {once: true});
    });
  }

  changePrincipal(value) {
    this.principal = parseInt(value, 10);
    this.principalChange.emit(this.principal);
    this.numericalValuesChanged.emit();
  }

  changeAmortizationMethod(value) {
    this.amortizationMethod = value;
    this.amortizationMethodChange.emit(this.amortizationMethod);
    this.numericalValuesChanged.emit();
  }


  changeName(value) {
    this.name = value;
    this.nameChange.emit(this.name);
  }

  changeInterest(value) {
    this.interestRate = value;
    this.interestRateChange.emit(this.interestRate);
    this.numericalValuesChanged.emit();
  }

  changeTerm(value) {
    this.termMonths = value;
    this.termMonthsChange.emit(this.termMonths);
    this.numericalValuesChanged.emit();
  }

  removeProposedDebt(): void {
    this.onRemoveProposedDebt.emit(this.proposedDebtId);
  }

  isTermIntAndGreaterThan0(): boolean {
    return Number(this.termMonths) > 0;
  }

}

