<!DOCTYPE html>
<div class="statement-builder-flyout" [ngClass]="{'standalone-mode': !embeddedMode, 'embedded-mode': embeddedMode}">
    <p class="statement-builder-header"><i class="fa-solid fa-wrench"></i>Financial Insights</p>
  <div class="row">
    <div class="col col-12">
      <p class="consolidate-periods-form-header">Select periods to generate insights for</p>
    </div>
  </div>
  <div class="row">
    <div class="col col-12">
      <app-popover-menu-multi-select
        [multiSelectOptions]="multiSelectPeriodOptions"
        [buttonText]="multiSelectButtonText"
        (selectOrDeselectAll)="selectOrDeselectAll()"
      ></app-popover-menu-multi-select>
    </div>
  </div>
  <div *ngIf="!isLoading; else loadingPanel" class="container period-selection-container ">
      <div class="row insights-markdown-box">
    <div class="col col-12 ">
        <div *ngFor="let prompt of promptsForDisplay">
            <hr>
            <markdown [data]="prompt.content"></markdown>
<!--            <p>{{x}}</p>-->
        </div>
    </div>
  </div>

  </div>
    <div *ngIf="showPromptBox">
        <div class="row">
            <div class="col col-12">
                <hr>
                <label for="promptText" class="consolidate-periods-form-header">What would you like to know?</label>
                <input [value]="promptText" type="text" id="promptText" class="form-control" placeholder="Enter Question" (input)="updatePromptText($event.target.value)">
            </div>
        </div>
    </div>

  <div class="footer text-end">
      <div class="row">
          <div class="col col-6">
              <p>There can be mistakes in the results. Please double check for accuracy.</p>
          </div>
          <div class="col col-6">
              <button class="btn btn-link" (click)="close()">Close</button>
            <button *ngIf="!firstBatchResultsReceived" class="btn btn-primary" (click)="generateInsight()" [disabled]="!isFormComplete">
                <span class="build-statement-btn-label">Generate Insights</span>
                <div *ngIf="isSubmitting" class="loader button-loader build-statement-btn-icon"></div>
            </button>
            <button *ngIf="firstBatchResultsReceived" class="btn btn-primary" (click)="generateMoreInsights()" [disabled]="!isFormComplete">
                <span class="build-statement-btn-label">Generate More Insights</span>
                <div *ngIf="isSubmitting" class="loader button-loader build-statement-btn-icon"></div>
            </button>
          </div>
      </div>

  </div>
</div>

<ng-template #loadingPanel>
    <div class="loading-panel-container loader__container">
        <div class="loader"></div>
    </div>
</ng-template>
