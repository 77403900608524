<div id="sidebar-wrapper">
    <div id="extended-sidebar">
        <div class="sidebar-col">

            <div class="fincura-logo">
                <img src="../../../../assets/images/Numerated-2023-logo.svg" />
            </div>

            <div class="has-feedback">
                <app-dynamic-search
                    *ngIf="showBorrowerOptions"
                    (whenSelected)="chooseBorrower($event)"
                ></app-dynamic-search>
            </div>

            <div class="sidebar-row" [routerLink]="['/companies']" *ngIf="showBorrowerOptions">
                <a class="nav-link" title="Home">
                    <i class="fas fa-home nav-icon"></i>
                </a>
                <span>Borrowers</span>
            </div>
            <ul class="portfolio-list" *ngIf="showBorrowerOptions">
                <li (click)="choosePortfolio(null)"
                    class="clickable"
                >
                    <span class="dot"></span><span class="portfolio-name">All</span>
                </li>
                <li *ngFor="let portfolio of portfolios"
                    class="clickable"
                    (click)="choosePortfolio(portfolio)"
                >
                    <span class="dot"></span>
                    <span class="portfolio-name">{{portfolio.name}}</span>
                </li>
                <li class="expand-toggle" *ngIf="!portfolioListIsExpanded && totalPortfolioCount > 10"
                    (click)="expandPortfolioList()">
                    <span>Expand </span>
                    <i class="fas fa-caret-down"></i>
                </li>
                <li class="expand-toggle" *ngIf="portfolioListIsExpanded && totalPortfolioCount > 10"
                    (click)="collapsePortfolioList()">
                    <span>Collapse </span>
                    <i class="fas fa-caret-up"></i>
                </li>
            </ul>
            <div *ngIf="shouldShowPortfolios" class="sidebar-row" [routerLink]="['/portfolios']">
                <a class="nav-link" title="Portfolios">
                    <i class="fas fa-briefcase nav-icon"></i>
                </a>
                <span>Portfolios</span>
            </div>
            <div *ngIf="isAdmin" class="sidebar-row" [routerLink]="['/users']">
                <a class="nav-link" title="Users">
                    <i class="fas fa-users nav-icon"></i>
                </a>
                <span>Users</span>
            </div>
            <div *ngIf="shouldDisplayManualReview" class="sidebar-row" [routerLink]="['/review']">
                <a class="nav-link" title="Manual Review">
                    <i class="fas fa-file nav-icon"></i>
                </a>
                <span>Document Review</span>
            </div>
            <div *ngIf="showSpreadingTemplates" class="sidebar-row" [routerLink]="['/spreading-templates']">
                <a class="nav-link" title="Spreading Templates">
                    <i class="fas fa-th-list nav-icon"></i>
                </a>
                <span>Spreading Templates</span>
            </div>
            <div class="sidebar-row settings-row" *ngIf="isInternalDeveloper">
                <div class="dropdown">
                    <div class="nav-link" data-bs-toggle="dropdown" title="Dev Toolbar">
                        <i class="fas fa-wrench nav-icon"></i>
                    </div>
                    <span data-bs-toggle="dropdown">Settings</span>
                    <div class="dropdown-menu">
                        <a (click)='restCommander()' class="rest-commander dropdown-item px-2">Send REST request to API
                            provider</a>
                        <a (click)='cacheCommander()' class="cache-commander dropdown-item px-2">Redis cache
                            commander</a>
                        <a (click)='triggerError()' class="trigger-error dropdown-item px-2">Trigger error message</a>

                        <a class="dropdown-item px-2">
                            <div (click)='toggleBypassCache()' class="form-check">
                                <input [checked]='bypassBackendCache' type="checkbox" class="form-check-input"
                                       id="bypassCacheCheckbox">
                                <label class="ps-1 form-check-label" for="bypassCacheCheckbox">Bypass backend
                                    cache</label>
                            </div>
                        </a>
                        <a class="dropdown-item px-2">
                            <div (click)='toggleProfiler()' class="form-check">
                                <input [checked]='switchOnProfiler' type="checkbox" class="form-check-input"
                                       id="backendProfilerCheckbox">
                                <label class="ps-1 form-check-label" for="backendProfilerCheckbox">Backend profiler for
                                    all
                                    calls</label>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div *ngIf="showReporting" class="sidebar-row" [routerLink]="['/reporting']">
                <a class="nav-link" title="Reporting">
                    <i class="fas fa-chart-bar nav-icon"></i>
                </a>
                <span>Reporting</span>
            </div>
            <div *ngIf="showApiKeysOption" class="sidebar-row" [routerLink]="['/api-keys']">
                <a class="nav-link" title="API Keys">
                    <i class="fas fa-key nav-icon"></i>
                </a>
                <span>API Keys</span>
            </div>

            <div class="sidebar-row nav-toggle" (click)="toggleSidebar()">
                <a class="nav-link" title="Open Sidebar">
                    <i class="fas fa-bars"></i>
                </a>
                <span>Minimize Sidebar</span>
            </div>
        </div>
    </div>
</div>
