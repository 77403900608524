import { Injectable } from '@angular/core';
import { SharedDataService } from './shared-data.service';
import { AlgorithmsService } from './algorithms.service';
import { DataFrameService } from './dataframes.service';

@Injectable()
export class FormulaService {

  formulaMappingObject = {}; // in form of {'RAW_IS_23_0' => 'Total Revenue'}

  constructor(
    private _sharedDataService: SharedDataService,
    private _dataFrameService: DataFrameService,
  ) {
    this._subscribeForSharedData();
  }

  public getAllFormulaVariables(companyId: number): void {
    this._dataFrameService.getAllFormulaVariables(companyId).subscribe((formulaOptions: any[]) => {
      this._sharedDataService.formulaOptions$.next(formulaOptions);
    });
  }

  public rawOrNormalizedFromEquationKey(equationKey: string): string {
    if (equationKey.indexOf('RAW') > -1) {
      return 'RAW';
    } else if (equationKey.indexOf('NORM') > -1) {
      return 'NORM';
    } else {
      return '';
    }
  }

  public documentTypeFromEquationKey(equationKey: string): string {
    if (equationKey.indexOf('CF') > -1) {
      return 'CF';
    } else if (equationKey.indexOf('IS') > -1) {
      return 'IS';
    } else if (equationKey.indexOf('BS') > -1) {
      return 'BS';
    } else if (equationKey.indexOf('CV') > -1) {
      return 'CV';
    } else {
      return '';
    }
  }


  public htmlToStringEquation(htmlString: any): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    let machineReadableEquation = '';
    const equationElements = Array.from(doc.body.childNodes);
    equationElements.forEach(element => {
      if (element.nodeName === 'SPAN') {
        // @ts-ignore uses getAttribute which only exists on element, not node, but it works. :shrug:
        machineReadableEquation += element.getAttribute('aria-valuetext');
      } else {
        machineReadableEquation += element.textContent;
      }
    });

    const removedWhiteSpaces = machineReadableEquation.replace(/\s/g, '');
    return removedWhiteSpaces;
  }

  private _subscribeForSharedData() {
    this._sharedDataService.formulaOptions$.subscribe((formulaOptions: any[]) => {
      formulaOptions.map( (object) => {
        this.formulaMappingObject[object['key']] = object['name'];
      });
    });
  }
}
