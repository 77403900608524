import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Company} from '@models/company';
import {TextInsightsService} from "@services/text-insights.service";
import {Select2OptionData} from "@components/shared/select2/select2.interface";
import {MultiSelectItem} from "@components/shared/popover-menu-multi-select/popover-menu-multi-select.component";
import {DataViewColumn} from "@models/dataview";
import {AlertService} from "@services/alert.service";


@Component({
  selector: 'text-insights',
  templateUrl: './text-insights.component.html',
  styleUrls: ['./text-insights.component.scss']
})
export class TextInsightsComponent implements OnInit {
  text = ""
  isLoading = true
  isSubmitting = false
  isFormComplete = false
  firstBatchResultsReceived = false
  showPromptBox = false
  promptText = ""
  promptHistory = []
  promptsForDisplay = []

  periodOptions: Select2OptionData[] = [];
  multiSelectPeriodOptions = [];
  multiSelectButtonText = "Select";

  @Input() company: Company;
  @Input() columns: DataViewColumn[];
  @Input() embeddedMode = false;
  @Output() closeTextInsightsComponent = new EventEmitter<any>();

  constructor(
    private router: Router,
    private textInsightsService: TextInsightsService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.columns.forEach((column, colIdx) => {
      const periodLabel = (column.statementDate + " - "  + column.preparationType + " - " + column.reportingInterval + (column.projectionName ? " - " + column.projectionName : ""));
      this.periodOptions.push({
        id: String(colIdx),
        text: periodLabel,
      })
      this.multiSelectPeriodOptions.push({
        id: String(colIdx),
        title: periodLabel,
        action: () => this.includeOrExcludeColumn(colIdx),
        checked: false,
        reportingPeriod: column.reportingPeriod,
        preparationType: column.preparationType,
        scenario: column.scenario,
        projectionName: column.projectionName,
        interval: column.reportingInterval
      })
    })


    // this.text = ["asfsdaf", "sadfsda", "sadfsdaf"]
    this.isLoading = false



  }

    selectOrDeselectAll() {
    if (this.multiSelectPeriodOptions.some(option => option.checked === true)) {
      this.multiSelectPeriodOptions.forEach(option => option.checked = false);
    } else {
      this.multiSelectPeriodOptions.forEach(option => option.checked = true);
    }
    // this.updateAutofilledNewPeriodData();
    this.updateMultiSelectButtonText();
    this.setIsFormComplete();
  }
  includeOrExcludeColumn(colIdx: number) {
    this.multiSelectPeriodOptions[colIdx].checked = !this.multiSelectPeriodOptions[colIdx].checked;
    // this.updateAutofilledNewPeriodData();
    this.setIsFormComplete();
    this.updateMultiSelectButtonText();

    // console.log(this.multiSelectPeriodOptions)
  }

  updateMultiSelectButtonText() {
  const countSelectedPeriods = this.multiSelectPeriodOptions.reduce((count, option) => count + (option.checked === true ? 1 : 0), 0);
  if (countSelectedPeriods < 1) {
    this.multiSelectButtonText = 'Select Periods'
  } else if (countSelectedPeriods === 1) {
    this.multiSelectButtonText = "1 period selected";
  } else {
    this.multiSelectButtonText = String(countSelectedPeriods) + " periods selected";
  }
}

generateInsight() {
    this.isSubmitting = true
    this.isLoading = true
    const periodsToUse = this.multiSelectPeriodOptions.filter(period => period.checked === true)
    console.log(periodsToUse)
    this.textInsightsService.getFinancialTextInsights(this.company.uuid, periodsToUse, "", []).subscribe(d => {
      console.log(d)
      this.promptHistory = d.promptHistory
      this.promptsForDisplay = this.promptHistory.filter(prompt => prompt.role !== 'system')
      // this.text = d.text
      this.isLoading = false
      this.isSubmitting = false
      this.firstBatchResultsReceived = true
    }, error => {
      this.alertService.error(error.message)
    })

}

generateMoreInsights() {
    if (this.firstBatchResultsReceived && !this.showPromptBox) {
      this.showPromptBox = true
      this.isFormComplete = false
    } else if (this.isFormComplete) {
          this.isSubmitting = true
          this.isLoading = true
          const periodsToUse = []

          this.textInsightsService.getFinancialTextInsights(this.company.uuid, periodsToUse, this.promptText, this.promptHistory).subscribe(d => {

            this.promptHistory = d.promptHistory
            this.promptsForDisplay = this.promptHistory.filter(prompt => prompt.role !== 'system')
            // this.text = d.text
            this.isLoading = false
            this.isSubmitting = false
            this.promptText = ""
          })
    }
}

setIsFormComplete(): void {
    this.isFormComplete = this.multiSelectPeriodOptions.some(option => option.checked === true);
  }

  updatePromptText(promptText: string) {
    this.promptText = promptText;
    this.setIsFormComplete();
  }

  close() {
    this.closeTextInsightsComponent.emit();
  }
}
