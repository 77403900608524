<div class="modal__content">
  <div class="confirmation-popup-header">
      <i class="fas fa-sitemap"></i>
      <span> {{TITLE}}</span>
  </div>
  
    <div class="confirmation-popup-text">
      <ng-container *ngIf="selectedSpreadingTemplateId !== ''; else noSpreadingTemplateOptions">
        <span>{{DESCRIPTION}}</span><br><br>
        <span class="input-label">{{INPUT_LABEL}}</span><br>
        <app-select2
          id="standardTemplateSelect"
          [data]="standardTemplateOptions"
          [options]="{minimumResultsForSearch: -1}"
          [value]="selectedSpreadingTemplate"
          (valueChanged)="updateSelectedSpreadingTemplate($event)"
          [width]="'250px'"
        ></app-select2>
      </ng-container>
      <ng-template #noSpreadingTemplateOptions>
        <span>{{NO_SPREADING_TEMPLATE_OPTIONS_TEXT }}</span>
      </ng-template>
    </div>
</div>
<div class="modal__footer">
  <button type="reset" class="btn btn-link" (click)="popup.close();">{{CANCEL_BUTTON_TEXT}}</button>
  <button type="button" class="btn btn-primary"
   (click)="createSectionItem()" [disabled]="!selectedSpreadingTemplateId || submitted">{{CONFIRM_BUTTON_TEXT}}</button>
</div>
