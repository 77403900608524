<div>
    <div id="main">
        <div class="header-row-container">
            <div class="row justify-content-between">
                <div class="col-md-3 col-sm-5 email-input-container">
                    <label for="emailSearchInput">Email Address</label>
                    <div class="input-group has-feedback">
                        <input id="emailSearchInput" type="text" class="form-control" [formControl]="searchInput"
                               placeholder="Enter a value"/>
                        <ng-container *ngIf="!searchInput.value; else clearIcon">
                            <span class="input-group-text">
                                <i class="fas fa-search form-control-feedback" style="color: #898989"></i>
                            </span>
                        </ng-container>
                        <ng-template #clearIcon>
                            <span class="input-group-text">
                                <i (click)="searchInput.setValue('');"
                                   class="fas fa-times form-control-feedback clear-field-icon"
                                   style="color: #898989"></i>
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="col-md-8 col-7 button-container">
                    <label>&nbsp;</label>
                    <button type="button" class="btn btn-primary form-control" (click)="addUser()">
                        <i class="fa-solid fa-plus button-icon"></i>Add New User
                    </button>
                </div>
            </div>
        </div>

        <div>
            <table class="table table-hover">
                <thead>
                  <tr class="sorting-header">
                      <th class="col-md-3" (click)="sortBy('email')">Email
                          <i class="fas fa-{{sort.field !== 'email' ? 'sort' : sort.dir ? 'sort-down' : 'sort-up'}}"></i>
                      </th>
                      <th class="col-md-3" (click)="sortBy('name')">Name
                          <i class="fas fa-{{sort.field !== 'name' ? 'sort' : sort.dir ? 'sort-down' : 'sort-up'}}"></i>
                      </th>
                      <th class="col-md-3">Role
                      </th>
                      <th class="col-md-1" (click)="sortBy('createdDate')">Date Added
                          <i class="fas fa-{{sort.field !== 'createdDate' ? 'sort' : sort.dir ? 'sort-down' : 'sort-up'}}"></i>
                      </th>
                      <th class="col-md-1" (click)="sortBy('active')">Status
                          <i class="fas fa-{{sort.field !== 'active' ? 'sort' : sort.dir ? 'sort-down' : 'sort-up'}}"></i>
                      </th>
                      <th class="col-md-1">Actions
                      </th>
                  </tr>
                </thead>
                <tbody *ngIf="users.length; else noUsers">
                <tr *ngFor="let user of users | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }"
                     user-list-tr [user]="user" (refreshUsers)="getUsers()" [ngClass]="{'disabled': !user.active}"
                ></tr>
                </tbody>
                <ng-template #noUsers>
                  <tr>
                    <td colspan="6" class="no-users-container">
                        <div *ngIf="!users.length && !isLoading && searchObject.email">
                            <app-table-empty-state
                              [emptyStateIcon]="emptyStateIcon"
                              [headerText]="emptyStateHeaderText"
                              [helpText]="emptyStateHelpText"
                            ></app-table-empty-state>
                      </div>
                        <div *ngIf="!users.length && !isLoading && !searchObject.email">
                            <app-table-empty-state
                              [emptyStateIcon]="noUsersExistIcon"
                              [headerText]="noUsersExistHeaderText"
                              [helpText]="noUsersExistHelpText"
                            ></app-table-empty-state>
                        </div>
                      <div *ngIf="isLoading" class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                    </td>
                  </tr>
                </ng-template>
            </table>
            <div *ngIf="total > limit">
                <pagination-template #p="paginationApi" (pageChange)="changePage($event)">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [ngClass]="{'disabled': p.isFirstPage()}">
                                <a class="page-link" (click)="!p.isFirstPage() ? p.previous() : return"
                                   aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            <li class="page-item" *ngFor="let page of p.pages"
                                [ngClass]="{'active': p.getCurrent() === page.value}">
                                <a class="page-link" (click)="p.setCurrent(page.value)"
                                   *ngIf="p.getCurrent() !== page.value">
                                    {{ page.label }}
                                </a>
                                <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}
                                    <span class="visually-hidden">(current)</span>
                            </span>
                            </li>

                            <li class="page-item" [ngClass]="{'disabled': p.isLastPage()}">
                                <a class="page-link" (click)="!p.isLastPage() ? p.next() : return" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </pagination-template>
            </div>
        </div>
    </div>
</div>
