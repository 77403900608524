<div *ngIf="!isLoading; else loadingPanel" class="container period-selection-container">
  <div class="row">
    <div class="col col-12">
      <p class="consolidate-periods-form-header">Select which periods to consolidate</p>
    </div>
  </div>
  <div class="row">
    <div class="col col-12">
      <app-popover-menu-multi-select
        [multiSelectOptions]="multiSelectPeriodOptions"
        [buttonText]="multiSelectButtonText"
        (selectOrDeselectAll)="selectOrDeselectAll()"
      ></app-popover-menu-multi-select>
    </div>
  </div>
  <div class="row">
    <div class="col col-12">
      <p class="add-consolidated-period-information-text">Add consolidated period information</p>
    </div>
  </div>
  <div class="row">
    <div class="col col-6 reporting-interval-select">
      <label for="reportingInterval">Select Reporting Interval</label>
      <app-select2
        id="reportingIntervalSelect"
        placeholder="Select"
        [data]="reportingIntervalOptions"
        [options]="{minimumResultsForSearch: -1}"
        [value]="reportingInterval"
        (valueChanged)="updateReportingInterval($event.value)"
        [width]="'100%'"
      >
      </app-select2>
    </div>
    <div class="col col-6 statement-date-select">
      <label for="statementDate">Select Statement Date</label>
      <app-input-calendar-component
        [initialDate]="statementDate"
        (dateEmitter)="updateStatementDate($event)"
      ></app-input-calendar-component>
    </div>
  </div>
  <div class="row">
    <div class="col col-6 balance-sheet-select">
      <label for="balanceSheetFrameSelect">Select Balance Sheet Period</label>
      <app-select2
        id="balanceSheetFrameSelect"
        placeholder="Select"
        [data]="periodOptions"
        [options]="{minimumResultsForSearch: -1}"
        [value]="selectedBalanceSheetFrameIndex"
        (valueChanged)="updateSelectedBalanceSheetFrameIndex($event.value)"
        [width]="'100%'"
      >
      </app-select2>
    </div>
    <div class= "col col-6 currency-select">
      <label for="currencySelect">Select Currency</label>
      <span class="ps-2">
            <i class="fas fa-circle-question" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Use this to select the currency for this built statement. Note: Currencies will not be converted. If consolidating
            statements in different currencies but choosing one currency (e.g., USD), amounts will be totaled without conversion."></i>
      </span>
      <select class="form-select" (change)="changeCurrency($event.target.value)">
          <option *ngFor="let currencyOption of supportedCurrencies" [value]="currencyOption.key"
                  [selected]="currencyOption.key === currency">
              {{currencyOption.key}} ({{currencyOption.symbol}})
          </option>
      </select>
    </div>
  </div>
  <div class="row" *ngIf="isBeginningCashInTable">
    <div class="col col-12">
      <label for="beginningCashFrameSelect">Select Beginning Cash Period</label>
      <app-select2
        id="beginningCashFrameSelect"
        placeholder="Select"
        [data]="periodOptions"
        [options]="{minimumResultsForSearch: -1}"
        [value]="selectedBeginningCashFrameIndex"
        (valueChanged)="updateSelectedBeginningCashFrameIndex($event.value)"
        [width]="'100%'"
      >
      </app-select2>
    </div>
  </div>
  <div class="row">
    <div class="col col-12" *ngIf="isEndingCashInTable">
      <label for="endingCashFrameSelect">Select Ending Cash Period</label>
      <app-select2
        id="endingCashFrameSelect"
        placeholder="Select"
        [data]="periodOptions"
        [options]="{minimumResultsForSearch: -1}"
        [value]="selectedEndingCashFrameIndex"
        (valueChanged)="updateSelectedEndingCashFrameIndex($event.value)"
        [width]="'100%'"
      >
      </app-select2>
    </div>
  </div>
  <div class="row footer">
    <div class="col col-12 footer-col text-end">
      <button class="btn btn-link" (click)="triggerCloseConsolidatePeriods()">Cancel</button>
      <button class="btn btn-primary" (click)="generateConsolidatedStatement()" [disabled]="!isFormComplete">
        <span class="build-statement-btn-label">Build Statement</span>
        <div *ngIf="isSubmitting" class="loader button-loader build-statement-btn-icon"></div>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingPanel>
    <div class="loading-panel-container loader__container">
        <div class="loader"></div>
    </div>
</ng-template>
