import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { Company } from '@models/company';
import { Subscription} from 'rxjs/Subscription';
import { NgxPopupComponent } from '@components/shared/ngx-popups/ngx-popups/components/popup.component';
import { TaxFormTypeAndYearConfirmationComponent } from '@components/shared/popups/tax-form-type-and-year-confirmation/tax-form-type-and-year-confirmation.component';
import { NgxPopupService } from '@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { ApiService } from '@services/api.service';
import { TAX_RETURN } from '@utils/constants';
import { ReviewQueueItem } from '@models/review-queue-item';
import { ReviewQueueService } from '@services/review-queue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from '@services/shared-data.service';
import { TrackingService } from '@services/tracking.service';
import { AlertService } from '@services/alert.service';
import { Logger, LoggingService } from '@services/logging.service';
import { DocumentFileService } from '@services/document-file.service';
import { DocumentFile } from '@models/document-file';
import { StatusModalComponent } from '@components/main/review/human-validation/shared/status-modal/status-modal.component';


@Component({
  selector: 'app-confirm-details',
  templateUrl: './confirm-details.component.html',
  styleUrls: ['./confirm-details.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ConfirmDetailsComponent implements OnInit, OnDestroy {
  subsArr$: Subscription[] = [];
  @Input() company: Company;
  @Input() file: FileItem;

  isLoading = true;
  isEmbedded = true; // currently only reachable in embeddedMode
  reviewQueueItemId = '';
  reviewQueueItem: ReviewQueueItem = null;
  logger: Logger;
  TAX_RETURN = TAX_RETURN;
  documentFile: DocumentFile = null;
  documentFileId: string;


  constructor(
    private _apiService: ApiService,
    private _popupService: NgxPopupService,
    private _changeDetector: ChangeDetectorRef,
    private _reviewQueueService: ReviewQueueService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _trackingService: TrackingService,
    private _alertService: AlertService,
    private _loggingService: LoggingService,
    private _documentFileService: DocumentFileService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('UserService');
  }

  ngOnInit() {

    this.subsArr$.push(this._route.parent.paramMap.subscribe((params => {
      this.reviewQueueItemId = params.get('uuid');
      if (!this._reviewQueueService.isValidUUID(this.reviewQueueItemId)) {
        this._router.navigate(['/404']);
        return;
      }
      this.loadData();
    })));

    if (this.isEmbedded) {
      this._sharedDataService.hideDefaultNavigation();
    }

    // quick-fix for FINC-4579, could be better at opening the popup on page load
    setTimeout(() => {
      this.openTaxFormTypeAndYearConfirmationPopup();
    }, 2000);
  }

  openTaxFormTypeAndYearConfirmationPopup() {
    this._popupService.open({
      componentType: TaxFormTypeAndYearConfirmationComponent,
      cssClass: 'modal-add-client',
      inputs: {
        company: this.company,
        file: this.documentFile,
        reviewQueueItem: this.reviewQueueItem,
        reviewQueueItemId: this.reviewQueueItemId,
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        this.showStatusPopup();
      }, {once: true});
    });
  }

  ngOnDestroy() {
    this._changeDetector.detach();
  }

  loadData() {
    this.retrieveReviewQueueItem();
  }

  retrieveReviewQueueItem(): void {
    this.subsArr$.push(this._reviewQueueService.retrieveAndLock(this.reviewQueueItemId).subscribe((item: ReviewQueueItem) => {
      this.reviewQueueItem = item;

      this.retrieveDocumentFile();
      try {
        this._trackingService.trackHumanInLoop({
          type: 'Start',
          step: 'Table Identification',
          documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
          documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
          documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
        });

        this.company = this.reviewQueueItem.processingJobPayload.company;

      } catch (err) {
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
      }

    }, err => {
      this._alertService.error(err);
    }));

  }

  showStatusPopup() {
    try {
      this._trackingService.trackHumanInLoop({
        type: 'End',
        step: 'Table Identification',
        documentFileId: this.reviewQueueItem.fileKey.split('/')[0],
        documentCompanyId: this.reviewQueueItem.processingJobPayload.company.id,
        documentTenantId: this.reviewQueueItem.processingJobPayload.context.bankId
      });
    } catch (err) {
      this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
    }

    this._popupService.open({
      componentType: StatusModalComponent,
      cssClass: 'modal-pipeline-processing',
      inputs: {
        reviewQueueItemId: this.reviewQueueItemId,
        closable: false,
      },
    });
  }

  private retrieveDocumentFile() {
    // Set the bank ID to the one from the queue item
    this._apiService.setTenantName(this.reviewQueueItem.bankId);
    this.subsArr$.push(this._documentFileService.listDocumentFiles(null, this.reviewQueueItem.documentFileId, true).subscribe(docs => {
      this.documentFile = docs[0];
    }, err => {
      this._alertService.error(err);
    }));
  }
}
