import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppConfigService } from '../services/app-config.service';
import { UserService } from '@services/user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { EnvFromHostname } from '@models/env-from-host';
import { WindowService } from '@services/window.service';

@Injectable()
export class AuthMiddleware implements CanActivate {

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private appConfigService: AppConfigService,
    private userService: UserService,
    private windowService: WindowService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    this.appConfigService.envFromHostname.subscribe((envFromHost: EnvFromHostname) => {
      const allowed = envFromHost.targetHostname === this.windowService.getWindow().location.hostname;

      if (!allowed) {
        this._router.navigate(['/denied']);
      }
    });

    const isLoggedInUser = this.userService.getCurrentUser()
      .pipe(
        map(user => {
          return !!user;
        })
      );

    const isSignedUrl = this._authService.isAuthenticatedWithSignedUrl();

    return combineLatest([isLoggedInUser, isSignedUrl]).pipe(
      map(([userIsLoggedIn, urlIsSigned]: [boolean, boolean]) => userIsLoggedIn || urlIsSigned)
    );
  }
}
