import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-net-worth-table-tile',
  templateUrl: './net-worth-table-tile.component.html',
  styleUrls: ['./net-worth-table-tile.component.scss']
})
export class NetWorthTableTileComponent implements OnInit {
  @Input() analyticsData = [];
  @Input() mostRecentStatementDate = '';
  @Input() currencySymbol = '';
  netWorthGraphData: Array<number> = [];

  constructor(
    public analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.getGraphData();
  }

  getGraphData(): void {
    this.netWorthGraphData = this.analyticsService.arrayOfValues('REF_NET_WORTH_ACTUAL', this.analyticsData);
  }

}
