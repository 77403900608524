<div class="modal__header">
    <div class="modal__title">
        <h4>Add Non-Financial Covenant</h4>
    </div>
    <div class="modal__close" (click)="popup.close();">
        <i class="fas fa-times"></i>
    </div>
</div>

<form
[formGroup]="nonFinancialCovenantForm"
(submit)="save(nonFinancialCovenantForm.value)">
    <div class="modal__content" *ngIf="loans.length > 0; else noLoans">
        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="control-label" for="loan">Loan</label>
                    <select class="form-select"
                        formControlName="loan">
                        <option [ngValue]="null" disabled>Choose the Loan</option>
                        <option
                            *ngFor="let loan of loans; let i = index"
                            [ngValue]="loan.id"
                        >{{loan.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                Covenant
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="mb-3">
                    <input type="text" class="form-control" formControlName="name" id="name">
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <select class="form-select"
                    formControlName="rule">
                        <option [ngValue]="null" disabled>Select Rule</option>
                        <option
                            *ngFor="let rule of rules; let i = index"
                            [ngValue]="rule.id"
                        >{{rule.label}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                Frequency
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="mb-3">
                    <select class="form-select"
                        formControlName="frequency">
                        <option disabled>Select Frequency</option>
                        <option
                            *ngFor="let frequencyOption of frequencyOptions; let i = index"
                            [selected]="i == 0"
                            [value]="frequencyOption.id"
                        >{{frequencyOption.label}}</option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <input type="number" formControlName="daysToReport" class="form-control" placeholder="Days to Report">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                Timeline
            </div>
        </div>


        <div class="row">
            <div class="col-6">
                <label>
                    <input type="radio" name="periodsIngestedAllOrOther" value="all"
                        (change)="showOtherPeriodsTextbox(false)">
                    <span class="form-label"></span>
                    Life of Loan
                </label>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label>
                        <input type="radio" name="periodsIngestedAllOrOther" value="all"
                            (change)="showOtherPeriodsTextbox(true)">
                        <span class="form-label"></span>
                        Other
                    </label>
                    <br />
                    <div [ngClass]="{
                        'hidden': !isOtherPeriodsDatepickerShown
                    }">
                        <app-input-calendar-component
                        fieldName="timeline"
                        [initialDate]="todaysDate"
                        formControlName="timeline"
                        >
                        </app-input-calendar-component>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-12">
                Covenant Description
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <textarea id="description" class="form-control" name="description" rows="4" formControlName="description">
                </textarea>
            </div>
        </div>


    </div>

    <ng-template #noLoans>
        <div class="modal__content">
            <p><strong>Cannot create a Covenant. No loans exist for this borrower</strong></p>
        </div>
    </ng-template>

    <div class="modal__footer">
        <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="submitted">Submit</button>
    </div>
</form>

