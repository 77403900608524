import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-portal-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() supportEmail: string;
  @Input() dates: string;
  @Input() reportingPeriod: string;
  @Input() statementTypes: Array<string>;
  @Input() bank: string;
  @Input() primaryColor: string;

  constructor() { }

  ngOnInit() {
  }

}
