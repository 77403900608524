import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Box} from "@components/main/review/human-validation/models/box";
import {PageImages} from "@models/document-file";
import {NavigationStep} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-stepper.component";

@Component({
  selector: 'app-table-tabulation',
  templateUrl: './table-tabulation.component.html',
  styleUrls: ['./table-tabulation.component.scss', '../shared-table-merging-header-styles.scss']
})
export class TableTabulationComponent {
  @Input() cells: Array<any> = null;
  @Input() columnStructure: Array<Box> = [];
  @Input() pageImage: PageImages;
  @Input() navigationSteps: NavigationStep[];
  @Output() exitClicked = new EventEmitter<void>();
  @Output() saveClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();

}
