<div class="statement-builder-flyout" [ngClass]="{'standalone-mode': !embeddedMode, 'embedded-mode': embeddedMode}">
  <p class="statement-builder-header"><i *ngIf="!frameToBeEditedId" class="fa-solid" [ngClass]="{'fa-wrench': mode === 'options', 'fa-arrow-left': mode !== 'options', 'clickable': mode !== 'options'}" (click)="selectStatementBuilderOption('options')"></i>{{statementBuilderHeaderText}}</p>

  <div *ngIf="mode === 'options'">
    <p class="statement-builder-options-header">Select what you would like to accomplish:</p>
    <div class="row statement-builder-option-tile" *ngFor="let statementBuilderOption of statementBuilderOptions" [ngClass]="{'is-disabled': statementBuilderOption.disabled, 'clickable': !statementBuilderOption.disabled}" title="{{statementBuilderOption.disabled ? 'No data exists to build statement from' : ''}}" (click)="selectStatementBuilderOption(statementBuilderOption.mode)">
      <div class="col col-11">
        <p class="statement-builder-option-title">{{statementBuilderOption.title}}</p>
        <p class="statement-builder-option-sub-title">{{statementBuilderOption.subtitle}}</p>
      </div>
      <div class="col col-1"><i class="fa-solid fa-arrow-right fa-xl statement-builder-option-tile-icon"></i></div>
    </div>
  </div>
  <div *ngIf="mode === 'consolidate'">
    <app-consolidate-periods-form
    [company]="company"
    [columns]="columnsForConsolidationAndMissingPeriodCalcs"
    [rows]="rows"
    [frameToBeEditedId]="frameToBeEditedId"
    (closeConsolidatedPeriods)="close($event)"
    ></app-consolidate-periods-form>
  </div>
  <div *ngIf="mode === 'calc-missing-period'">
    <app-calculate-missing-period-form
    [company]="company"
    [columns]="columnsForConsolidationAndMissingPeriodCalcs"
    [rows]="rows"
    [frameToBeEditedId]="frameToBeEditedId"
    (closeMissingStatementForm)="close($event)"
    ></app-calculate-missing-period-form>
  </div>
  <div *ngIf="mode === 'generate-forecast'">
    <app-generate-forecast-form
    [company]="company"
    [columns]="columns"
    [rows]="rows"
    [spreadingTemplateItems]="spreadingTemplateItems"
    [frameToBeEditedId]="frameToBeEditedId"
    (closeGeneratePeriods)="close($event)"
    ></app-generate-forecast-form>
  </div>
  <div class="footer text-end" *ngIf="mode === 'options'">
    <button class="btn btn-link" (click)="close()">Cancel</button>
  </div>
</div>
