<div
    *ngIf="showTile"
    class="tile-container gauge-tile-container"
>
    <div class="row">
        <div class="col-9 line-item-name">
            <h4>{{title}}</h4>
        </div>
        <div
            class="col-3 small-gray align-right"
        >
            {{analyticsService.formattedPopChange(analyticsItemValues)}}
        </div>
    </div>
    <div class="row">
        <div class="col-12 small-gray current-value">
            {{periodType}} | {{statementDate}}
        </div>
    </div>
    <div class="row">
        <div class="col-12 graph-container">
            <app-gauge-chart [value]="analyticsService.mostRecentValue(analyticsItemValues)">
            </app-gauge-chart>
        </div>
    </div>
</div>
