import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embedded-component-container',
  templateUrl: './embedded-component-container.component.html',
  styleUrls: ['./embedded-component-container.component.scss']
})
export class EmbeddedComponentContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
