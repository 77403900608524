import { Subscription } from 'rxjs';

import {Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgxPopupComponent } from '../../ngx-popups/ngx-popups/components/popup.component';
import { AutoUnsubscribe } from '@decorators/auto-unsubscribe';
import {DocumentFileService} from '@services/document-file.service';
import {Router} from '@angular/router';
import {ReviewQueueItem} from '@models/review-queue-item';
import {ERROR_STATUS} from '@utils/constants';

@Component({
  selector: 'app-document-password-entry-modal',
  templateUrl: './document-password-entry-modal.component.html',
  styleUrls: ['./document-password-entry-modal.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class DocumentPasswordEntryModalComponent implements OnInit, OnDestroy {
  @Input() popup: NgxPopupComponent;
  @Input() companyUuid: string;
  @Input() reviewQueueItem: ReviewQueueItem = null;
  @Output() callback = new EventEmitter<boolean>();
  subsArr$: Subscription[] = [];
  inputtedPassword: string;
  attemptsRemaining = 5;
  showAttemptsRemainingMessage = false;
  documentFileId: number;
  isDocumentLocked = false;
  documentName: string;

  constructor(
    private _documentFileService: DocumentFileService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this.documentFileId = this.reviewQueueItem.documentFileId
    this.isDocumentLocked = this.reviewQueueItem.status === ERROR_STATUS
    this.documentName = this.reviewQueueItem.processingJobPayload.documentFile.originalDocumentName
  }

  ngOnDestroy() {
  }

  tryInputtedPassword() {
    if (this.attemptsRemaining <= 1) {
      this.isDocumentLocked = true
    }
    this._documentFileService.validateHumanEnteredDocumentPassword(this.documentFileId, this.inputtedPassword, this.isDocumentLocked).subscribe((data) => {
          if (data.validPassword) {
            this.popup.close();
            this.callback.emit(true);
          } else {
            this.attemptsRemaining -= 1;
            this.showAttemptsRemainingMessage = true;
            this.inputtedPassword = null
          }
    })
  }

  navigateBackToDocumentsPage() {
    this.callback.emit(false);
    this._router.navigate(['companies', this.companyUuid, 'data']);
    this.popup.close();
  }
}
