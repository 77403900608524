import {Injectable} from "@angular/core";
import {ApiService} from "@services/api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class TextInsightsService {
  constructor(private apiService: ApiService) {
  }

  getFinancialTextInsights(company_uuid, periodsToUse, newUserPrompt, previousRunData): Observable<any> {
    const payload = {
      company_uuid: company_uuid,
      periods: periodsToUse,
      newUserPrompt: newUserPrompt,
      previousRunData: previousRunData
    }
    return this.apiService.send('Post', `/api/ai/generate-insights`, payload).pipe(
      map(data => {

        return data.response.objects[0];
      })
    );
  }
}
