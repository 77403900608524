import { Component, Input, OnInit } from '@angular/core';

const colors = {
  'green': '#68BA61',
  'red': '#CC6335',
  'grey': '#686868'
}

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent implements OnInit {
  @Input() size = '15px';
  @Input() color: string = colors['grey'];
  @Input() contentType = 'unknown';
  @Input() fileSuffix = 'unknown';

  faIconClass = 'fa-file-alt';

  constructor() {}

  ngOnInit() {
    if (this.contentType) {
      switch (this.contentType) {
        case 'application/pdf':
          this.color = colors['red']
          this.faIconClass = 'fa-file-pdf';
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          this.color = colors['green']
          this.faIconClass = 'fa-file-excel';
          break;
        default:
          break;
      }
    }
    if (this.fileSuffix) {
      switch (this.fileSuffix) {
        case 'pdf':
          this.color = colors['red'];
          this.faIconClass = 'fa-file-pdf';
          break;
        case 'xlsx':
        case 'xls':
          this.color = colors['green'];
          this.faIconClass = 'fa-file-excel';
          break;
      }
    }
  }
}
