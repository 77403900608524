import {ChangeDetectorRef, ElementRef, Injectable, OnDestroy, QueryList} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";

const zoomIncrement = 0.2;
const minimumImgScale = 0.3;

@Injectable()
export class PdfToolbarService implements OnDestroy {
  private unsubscribe$ = new Subject();
  scrollableDocContainer: ElementRef;
  docPageElements: QueryList<ElementRef>

  private visiblePageNumberSource = new BehaviorSubject<number>(1);
  public visiblePageNumber$ = this.visiblePageNumberSource.asObservable();
  scrollSubject$ = new Subject<void>();

  private imageScaleSource = new BehaviorSubject<number>(1);
  public imageScale$ = this.imageScaleSource.asObservable();

  constructor(private _cdr: ChangeDetectorRef) {
    this.scrollSubject$
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(200)).subscribe(() => this.handleScroll());
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /***** zoom utils *****/

  private set imageScale(nextValue) {
    this.imageScaleSource.next(nextValue);
  }

  public get imageScale() {
    return this.imageScaleSource.getValue();
  }

  public zoomIn() {
    this.imageScale = this.imageScale + zoomIncrement;
    this.scrollPageIntoView('auto');
  }

  public zoomOut() {
    if (this.imageScale > minimumImgScale) {
      this.imageScale = this.imageScale - zoomIncrement;
      this.scrollPageIntoView('auto');
    }
  }

  /***** pagination utils *****/

  public set visiblePageNumber(value) {
    this.visiblePageNumberSource.next(value);
  }

  public get visiblePageNumber() {
    return this.visiblePageNumberSource.getValue();
  }

  public incrementVisiblePageNumber() {
    this.visiblePageNumberSource.next(this.visiblePageNumberSource.value + 1);
  }

  public decrementVisiblePageNumber() {
    this.visiblePageNumberSource.next(this.visiblePageNumberSource.value - 1);
  }


  scrollPageIntoView(scrollBehavior='smooth') {
    const targetPageIndex = this.visiblePageNumberSource.value - 1;
    if (this.docPageElements) {
      const el = this.docPageElements.toArray()[targetPageIndex]
      el.nativeElement.scrollIntoView({behavior: scrollBehavior, block: "center"});
    }
  }

  validateNextPageValue(nextVal, pageCount) {
    if (isNaN(nextVal) || nextVal < 1) {
      return 1;
    } else if (nextVal > pageCount) {
      return pageCount
    } else {
      return nextVal;
    }
  }

  public handleScroll() {
    const scrollableContainerRect = this.scrollableDocContainer.nativeElement.getBoundingClientRect();
    const viewPortCenterPoint = scrollableContainerRect.top + ((scrollableContainerRect.bottom - scrollableContainerRect.top) / 2);
    let currentPageIndex;
    let currentPageIndexDistanceFromViewportCenter = Number.POSITIVE_INFINITY;
    const imgElsArr = this.docPageElements.toArray();
    for (let i = 0; i < imgElsArr.length; i++) {
      const imgEl = imgElsArr[i];
      const boundingRect = imgEl.nativeElement.getBoundingClientRect();
      const pageHeight = boundingRect.bottom - boundingRect.top;
      const pageCenterPoint = boundingRect.top + (pageHeight / 2);
      const pageCenterDistanceFromViewportCenter = Math.abs(viewPortCenterPoint - pageCenterPoint);
      if (pageCenterDistanceFromViewportCenter < currentPageIndexDistanceFromViewportCenter) {
        currentPageIndex = i;
        currentPageIndexDistanceFromViewportCenter = pageCenterDistanceFromViewportCenter;
      }
    }
    this.visiblePageNumberSource.next(currentPageIndex + 1);
    this._cdr.detectChanges();
  }
}
