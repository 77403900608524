<div class="top-header">
    <app-navigation-stepper
        [steps]="navigationSteps"
        [currentStepIndex]="2"
        [enforceLinearProgression]="true"
    ></app-navigation-stepper>
    <div class="action-container">
        <button class="btn btn-outline-secondary" type="button" (click)="exitClicked.emit()">
            <span class="size">Cancel</span>
        </button>
        <div class="btn-separator">|</div>
        <button class="btn btn-primary" type="button" (click)="nextClicked.emit()">
            <span class="size">Merge Into Spread</span>
        </button>
    </div>
</div>
<div class="content-container row mx-0">
    <div class="col-4 scrollable-column fixed-document-width-col" id="box-drawer-container">
        <div class="section-header">
            <span class="panel-label">Selected page</span>
        </div>
        <app-box-drawer
            [allowEdit]="false"
            [inputImageWidth]="600"
            [boxes]="columnStructure"
            [sourceImageUrl]="pageImage?.normalRes?.signedUrl"
            scrollingContainerElementId="box-drawer-container"
        ></app-box-drawer>
    </div>
    <div class="col no-x-overflow">
        <div class="section-header">
            <span class="panel-label">Review Data</span>
        </div>
        <div class="table-container">
            <hot-table
                [hotId]="htDataService.hotId"
                [afterInit]="htDataService.afterTableInit"
                [colHeaders]="true"
                [rowHeaders]="true"
                [data]="htDataService.pageData$ | async"
                [settings]="{renderer: renderCell, contextMenu: htDataService.contextMenu}"
                [cells]="cellsFunction"
                [stretchH]="'all'"
                [licenseKey]="htDataService.handsOnTableLicense"
            >
            </hot-table>
        </div>
    </div>
</div>
