<ng-container *ngIf="canViewPage; else NoAccessToCompanyProfile">
    <div class="profile-container">
        <div class="row" *ngIf="!isEditing">
            <div class="col-sm-12 col-md-8 offset-md-2">
                <div class="company-profile">
                    <div class="row company-profile__header align-items-center">
                        <div class="col-sm-10">
                            <div class="title fs-block">
                                {{company?.name}}
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-outline-secondary float-end" (click)="toggleEditing()" *ngIf="canEditPage">Edit</button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="cham-panel">
                        <div class="cham-panel__header">
                            Borrower Management
                        </div>
                        <div class="cham-panel__content">
                            <div class="row">
                                <div class="col-8">
                                    <i class="fas fa-suitcase"></i><span class="text"> Portfolio:</span>
                                    <span>{{company?.portfolio?.name || 'Unassigned'}}</span>
                                </div>
                            </div>
                            <div class="row" *ngIf="showLinkedEntitiesFlag">
                                <div class="col-12">
                                    <i class="fas fa-link"></i><span class="text"> Related Entities:</span>
                                    <span>{{ linkedEntitiesStringDisplay || 'Not Linked'}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="cham-panel">
                        <div class="cham-panel__header">
                            Contact Information
                        </div>
                        <div class="cham-panel__content">
                            <div class="row">
                                <div class="col-8">
                                    <i class="fas fa-user"></i><span class="text">{{company?.contactPerson}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <i class="fas fa-envelope"></i><span class="text fs-block">{{company?.email}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <i class="fas fa-phone"></i><span class="text fs-block">{{company?.contactPhone}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cham-panel">
                        <div class="cham-panel__header">
                            Settings
                        </div>
                        <div class="cham-panel__content">
                            <div class="row">
                                <div class="col-3 title">
                                    Round to Thousands:
                                </div>
                                <div class="col-6 text">
                                    {{roundToThousandsDisplayName}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    Currency:
                                </div>
                                <div class="col-6 text">
                                    {{currencyDisplayName}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cham-panel">
                        <div class="cham-panel__header">
                            General Information
                        </div>
                        <div class="cham-panel__content">
                            <div
                            *ngIf="showSicIndustry"
                            class="row">
                                <div class="col-3 title">
                                    SIC Industry
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.sicIndustry}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    SIC
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.sicCode ? company?.sicCode + ' - ' + company?.sicName : ''}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    NAICS
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.naicsCode ? company?.naicsCode + ' - ' + company?.naicsName : ''}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    Type of Business
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.businessType}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    Company Size
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.size}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    Web Site
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.webSite}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    Address
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.address}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 title">
                                    {{customerIdLabel}}
                                </div>
                                <div class="col-6 text fs-block">
                                    {{company?.externalId}}
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-3 title">
                                  Country
                              </div>
                              <div class="col-6 text fs-block">
                                  {{company?.country}} {{company?.country && company?.countryName ? "-" : ""}} {{company?.countryName}}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-3 title">
                                  Region
                              </div>
                              <div class="col-6 text fs-block">
                                  {{company?.region}}
                              </div>
                            </div>


                            <div *ngFor="let customAttrDefinition of customAttrDefinitions" class="row">
                                <ng-container *ngIf="customAttributesValues[customAttrDefinition.key]">
                                    <div class="col-3 title">
                                        {{customAttrDefinition.name}}
                                    </div>
                                    <div class="col-6 text fs-block">
                                        {{customAttributesValues[customAttrDefinition.key].value}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="cham-panel">
                        <div class="cham-panel__header">
                            Fiscal Year End <button class="btn btn-sm btn-light float-end" (click)="addFiscalYearEndChange()" *ngIf="canEditPage">Add Fiscal Year End Change <i class="fas fa-plus"></i></button>
                        </div>
                        <div class="cham-panel__content">
                            <div class="row fye-row" *ngFor="let fye of fiscalYearEndChanges">
                                <div class="col-12">
                                    <i class="fas fa-calendar-alt"></i> {{"&nbsp;&nbsp;"}} {{fye.fiscalYearEndChange}} <button class="btn btn-sm btn-light delete-fye float-end" (click)="deleteFiscalYearEndChange(fye, $event)">Delete <i class="fas fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showAccountingIntegrations" class="cham-panel">
                        <div class="cham-panel__header">
                            Accounting Integrations <button class="btn btn-sm btn-light float-end" (click)="addAccountingIntegration()" *ngIf="canEditPage">Add Integration <i class="fas fa-plus"></i></button>
                        </div>
                        <div class="cham-panel__content">
                            <div class="row" *ngFor="let integrationVendor of company?.accountingIntegrations">
                                <div class="col-6" style="text-align: center;">
                                    <app-accounting-integration-logo [vendor]="integrationVendor"></app-accounting-integration-logo>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="isEditing">
            <div class="col-8 offset-2">
                <div class="company-profile">
                    <form class="form-horizontal" [formGroup]="companyForm"
                        (submit)="save(companyForm.value, companyForm.valid)">
                        <div class="company-profile__header">
                            <div class="mb-3 row">
                                <div class="col-6">
                                    <input [ngClass]="{'is-invalid': isFieldInvalid('name')}" class="form-control fs-block" formControlName="name" type="text">
                                    <span class="invalid-feedback"
                                        *ngIf="(companyForm.get('name').hasError('required') || companyForm.get('name').hasError('nospace'))
                                        && isFieldInvalid('name')">
                                        Name is required
                                    </span>
                                    <span class="invalid-feedback"
                                        *ngIf="companyForm.get('name').hasError('extraspace') && isFieldInvalid('name')">
                                        No extra whitespaces are allowed
                                    </span>
                                    <span class="invalid-feedback"
                                        *ngIf="isFieldInvalid('name') && companyForm.get('name').hasError('maxlength')">
                                        Maximum of 150 characters
                                    </span>
                                </div>
                                <div class="col-6">
                                    <div class="float-end">
                                        <button class="btn btn-link" type="reset" (click)="toggleEditing(true)">Cancel</button>
                                        <button class="btn btn-primary" type="submit" [disabled]="submitted">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cham-panel">
                            <div class="cham-panel__header">
                                Borrower Management
                            </div>
                            <div class="cham-panel__content">
                                <div class="mb-3">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <span class="input-group-text ms-0"><i class="fas fa-suitcase"></i></span>
                                            <select class="form-select" formControlName="portfolio">
                                            <option [value]="0">Unassigned</option>
                                                <option disabled>&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;</option>
                                                <option *ngFor="let portfolio of portfoliosArr" [value]="portfolio.id">
                                                    {{portfolio.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3" *ngIf="showLinkedEntitiesFlag">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <span class="input-group-text ms-0"><i class="fas fa-link"></i></span>
                                            <select class="form-select"
                                                    (change)="changeIfHaveLinkedEntities($event.target.value)">
                                                <option [selected]="linkedEntities.length == 0" [value]="0">Not Linked</option>
                                                <option [selected]="linkedEntities.length > 0" [value]="1">Linked to Related Entities</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="areLinkedEntitiesShown">
                                    <div class="mb-3">
                                        <ng-container *ngIf="linkedEntities.length > 0" class="mb-3">
                                            <div *ngFor="let linkedEntity of linkedEntities; let i = index" class="linked-entity-row">
                                                <div>
                                                    Entity:
                                                </div>
                                                <div class="row-main-content">
                                                    {{linkedEntity.name}}
                                                </div>
                                                <div class="cancel-icon-container">
                                                    <div class="chart-close" (click)="removeLinkedEntity(linkedEntity.uuid)">
                                                        <i class="fas fa-times clickable"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="showNewRelatedEntitySearch" class="linked-entity-row">
                                            <div class="autocomplete-row-spacers">
                                                Entity:
                                            </div>
                                            <div class="row-main-content">
                                                <app-dynamic-search
                                                    (whenSelected)="chooseLinkedEntity($event)"
                                                    [searchResultsFilter]="borrowerSearchResultsFilter"
                                                ></app-dynamic-search>
                                            </div>
                                            <div class="cancel-icon-container">
                                                <div class="chart-close autocomplete-row-spacers" (click)="hideNewRelatedEntitySearchAction()">
                                                    <i class="fas fa-times clickable"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="!showNewRelatedEntitySearch" class="mb-3">
                                        <div class="col-4 offset-1">
                                            <button class="btn btn-link" type="reset" (click)="showNewRelatedEntitySearchAction()">Add Related Entity</button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="cham-panel">
                            <div class="cham-panel__header">
                                Contact Information
                            </div>
                            <div class="cham-panel__content">
                                <div class="mb-3">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <span class="input-group-text ms-0"><i class="fas fa-user"></i></span>
                                            <input class="form-control fs-block" type="text" formControlName="contactPerson" [ngClass]="{'is-invalid': isFieldInvalid('contactPerson')}">
                                            <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('contactPerson') && companyForm.get('contactPerson').hasError('maxlength')">
                                                Maximum of 100 characters
                                            </span>
                                            <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('contactPerson') && companyForm.get('contactPerson').hasError('pattern')">
                                                Name contains incorrect characters
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <span class="input-group-text ms-0"><i class="fas fa-envelope"></i></span>
                                            <input [ngClass]="{'is-invalid': isFieldInvalid('email')}" class="form-control fs-block" type="text" formControlName="email">
                                            <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('email') && companyForm.get('email').hasError('maxlength')">
                                            Maximum of 100 characters
                                            </span>
                                            <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('email') && companyForm.get('email').hasError('pattern')">
                                                Email is not valid
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <span class="input-group-text ms-0"><i class="fas fa-phone"></i></span>
                                            <input [ngClass]="{'is-invalid': isFieldInvalid('contactPhone')}" class="form-control fs-block" type="text" formControlName="contactPhone"
                                                [textMask]="{mask: phoneMask, showMask: false}">
                                            <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('contactPhone') && companyForm.get('contactPhone').hasError('maxlength')">
                                                Maximum of 100 characters
                                            </span>
                                            <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('contactPhone') && companyForm.get('contactPhone').hasError('pattern')">
                                                Contact Phone is incorrect
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cham-panel">
                            <div class="cham-panel__header">
                                Settings
                            </div>
                            <div class="cham-panel__content" formGroupName="settings">
                                <div class="mb-3">
                                    <div class="col-6">
                                        <label>Round to thousands?</label>
                                        <div class="input-group">
                                            <select class="form-select" formControlName="roundToThousands">
                                                <option [value]=false>No</option>
                                                <option [value]=true>Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-6">
                                        <label>Set Currency</label>
                                        <div class="input-group">
                                            <select class="form-select" formControlName="currency">
                                                <option *ngFor="let currency of supportedCurrencies" [value]="currency.key">
                                                    {{currency.key}} ({{currency.symbol}})
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cham-panel">
                            <div class="cham-panel__header">
                                General Information
                            </div>
                            <div class="cham-panel__content">
                                <div
                                *ngIf="showSicIndustry"
                                class="mb-3 row col-12">
                                    <label for="sicIndustry" class="col-sm-3 control-label">SIC Industry</label>
                                    <div class="col-sm-6">
                                        <select class="form-select"
                                                id="sicIndustry"
                                                formControlName="sicIndustry"
                                                [ngClass]="{'is-invalid': isFieldInvalid('sicCode')}">
                                            <option
                                                *ngFor="let sicIndustry of sicIndustries | enumToArray; let i = index"
                                                [ngValue]="sicIndustry"
                                            >{{borrowerService.displayFriendlyName(sicIndustry)}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="mb-3 row col-12">
                                    <label for="sic-code" class="col-sm-3 control-label">SIC</label>
                                    <div class="col-sm-6">
                                        <input list="id-sic-code" id="sic-code" class="form-control"
                                               formControlName="sicCode" placeholder="Enter code or title"
                                               [ngClass]="{'is-invalid': isFieldInvalid('sicCode')}"
                                        >
                                         <datalist id="id-sic-code">
                                           <option *ngFor="let eachSic of sicArr" [value]="eachSic.code">{{eachSic.title}} - {{eachSic.code}}</option>
                                         </datalist>

                                         <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('sicCode') && companyForm.get('sicCode').hasError('maxlength')">
                                            Max length 4
                                        </span>
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('sicCode') && companyForm.get('sicCode').hasError('pattern')">
                                            SIC contains incorrect characters
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3 row col-12">
                                    <label for="naics" class="col-sm-3 control-label">NAICS</label>
                                    <div class="col-sm-6">
                                        <input list="id-naics-code" id="naics" class="form-control"
                                               formControlName="naicsCode" placeholder="Enter code or title"
                                                [ngClass]="{'is-invalid': isFieldInvalid('naicsCode')}"
                                        >
                                        <datalist id="id-naics-code">
                                          <option *ngFor="let eachNaics of naicsArr" [value]="eachNaics['NAICS']">{{eachNaics['NAICS Title']}} - {{eachNaics['NAICS']}}</option>
                                        </datalist>

                                        <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('naicsCode') && companyForm.get('naicsCode').hasError('maxlength')">
                                            Max length 6
                                        </span>
                                        <span class="invalid-feedback"
                                                *ngIf="isFieldInvalid('naicsCode') && companyForm.get('naicsCode').hasError('pattern')">
                                            NAICS contains incorrect characters
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3 row col-12">
                                    <label for="businessType" class="col-sm-3 control-label">Type of Business</label>
                                    <div class="col-sm-6">
                                        <input id="businessType" class="form-control fs-block" formControlName="businessType"
                                            type="text" [ngClass]="{'is-invalid': isFieldInvalid('businessType')}">
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('businessType') && companyForm.get('businessType').hasError('maxlength')">
                                            Maximum of 100 characters
                                        </span>
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('businessType') && companyForm.get('businessType').hasError('pattern')">
                                            Type of Business contains incorrect characters
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3 row col-12">
                                    <label for="size" class="col-sm-3 control-label">Company Size</label>
                                    <div class="col-sm-6">
                                        <input [ngClass]="{'is-invalid': isFieldInvalid('size')}" id="size" class="form-control fs-block" formControlName="size" type="text">
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('size') && (companyForm.get('size').hasError('min') ||
                                            companyForm.get('size').hasError('max'))">
                                            It must be a number, between 1 and 10000
                                        </span>
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('size') && companyForm.get('size').hasError('isInteger')">
                                            It must be an integer
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3 row col-12">
                                    <label for="webSite" class="col-sm-3 control-label">Web Site</label>
                                    <div class="col-sm-6">
                                        <input [ngClass]="{'is-invalid': isFieldInvalid('webSite')}" id="webSite" class="form-control fs-block" formControlName="webSite" type="text">
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('webSite') && companyForm.get('webSite').hasError('maxlength')">
                                            Maximum of 100 characters
                                        </span>
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('webSite') && companyForm.get('webSite').hasError('pattern')">
                                            Url is not valid
                                        </span>
                                    </div>
                                </div>
                                <!-- edit customer id is controlled with feature flag -->
                                <ng-container *ngIf="canEditCustomerId; else customerIdReadOnly">
                                  <div *ngIf="canEditCustomerId" class="mb-3 row col-12">
                                    <label for="externalId" class="col-sm-3 control-label">{{customerIdLabel}}</label>
                                    <div class="col-sm-6">
                                        <input [ngClass]="{'is-invalid': isFieldInvalid('externalId')}" id="externalId" class="form-control fs-block" formControlName="externalId" type="text">
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('externalId') && companyForm.get('externalId').hasError('maxlength')">
                                            Maximum of 100 characters
                                        </span>
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('externalId') && companyForm.get('externalId').hasError('pattern')">
                                            Customer ID is not valid
                                        </span>
                                    </div>
                                </div>
                                </ng-container>
                                <ng-template #customerIdReadOnly>
                                  <div class="mb-3 row col-12">
                                    <label class="col-sm-3 control-label">{{customerIdLabel}}</label>
                                    <div class="col-sm-6">
                                      <p>{{company?.externalId}}</p>
                                    </div>
                                  </div>
                                </ng-template>
                                <div class="mb-3 row col-12">
                                    <label for="address" class="col-sm-3 control-label">Address</label>
                                    <div class="col-sm-6">
                                        <input [ngClass]="{'is-invalid': isFieldInvalid('address')}" id="address" class="form-control fs-block" formControlName="address" type="text">
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('address') && companyForm.get('address').hasError('maxlength')">
                                            Maximum of 100 characters
                                        </span>
                                        <span class="invalid-feedback"
                                            *ngIf="isFieldInvalid('address') && companyForm.get('address').hasError('pattern')">
                                            Address contains incorrect characters
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row col-12">
                                    <label for="country" class="col-sm-3 control-label">Country</label>
                                    <div class="col-sm-6">
                                        <input [ngClass]="{'is-invalid': companyForm.get('country').invalid}" list="country" class="form-control"
                                               formControlName="country" placeholder="Enter Country"
                                        >
                                        <datalist id="country">
                                            <option *ngFor="let country of countryCodeArr" [value]="country.code">{{country.name}}</option>
                                        </datalist>
                                    </div>
                                </div>
                              <div class="mb-3 row col-12">
                                <label for="region" class="col-sm-3 control-label">Region</label>
                                <div class="col-sm-6">
                                    <input [ngClass]="{'is-invalid': isFieldInvalid('region')}" id="region" class="form-control fs-block" formControlName="region" type="text">
                                    <span class="invalid-feedback"
                                        *ngIf="isFieldInvalid('region') && companyForm.get('region').hasError('maxlength')">
                                        Maximum of 75 characters
                                    </span>
                                    <span class="invalid-feedback"
                                        *ngIf="isFieldInvalid('region') && companyForm.get('region').hasError('pattern')">
                                        Region contains incorrect characters
                                    </span>
                                </div>
                              </div>
                              <div *ngFor="let customAttrDefinition of customAttrDefinitions" class="mb-3 row col-12">
                                  <ng-container *ngIf="customAttributesValues[customAttrDefinition.key]">
                                      <label for="{{customAttrDefinition.name}}" class="col-sm-3 control-label">{{customAttrDefinition.name}}</label>
                                      <div class="col-sm-6">
                                          <input type="text"
                                          name="{{customAttrDefinition.key}}"
                                          class="form-control"
                                          value="{{customAttributesValues[customAttrDefinition.key].value}}"
                                          (change)="customAttributeChange(customAttrDefinition, $event)" />
                                      </div>
                                  </ng-container>
                              </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #NoAccessToCompanyProfile>
    <borrower-alert [alertText]="noAccessErrorText"></borrower-alert>
</ng-template>
