import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy'
import { CommonFunctions } from '../utils/common-functions';

// @ts-ignore
import versionJson from '../../assets/files/version.json';
import { SharedDataService } from './shared-data.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyErrorHandlerService extends ErrorHandler {
  sentry = Sentry;
  commonFunctions = CommonFunctions;

  constructor(
    public sharedDataService: SharedDataService,
    private _appConfigService: AppConfigService,
  ) {
    super();
  }

  load(secret_key: string): void {
    this._appConfigService.envFromHostname.subscribe( (envFromHost) => {
      this.sentry.init({
        dsn: secret_key,
        environment: envFromHost.envName,
        release: versionJson.version,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          Sentry.browserTracingIntegration(),
          // Registers the Replay integration,
          // which automatically captures Session Replays
          Sentry.replayIntegration()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", "/fincura.com\/", "/ngtdevel.com\/", "/ngtqa.com\/"],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });

      this.sharedDataService.isSentryInitialized = true;
    });
  }

  handleError(error) {
      if (error['ignoreError']) {
        return;
      }
      delete error['ignoreError'];

    if (!this.sharedDataService.isSentryInitialized) {
      super.handleError(error); // error before sentry is initialized, fallback to default error handler
    } else {
      const eventId = this.sentry.captureException(error.originalException || error.originalError || error);
      console.error(error);
    }
  }
}
