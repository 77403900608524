import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {AutoUnsubscribe} from '@decorators/auto-unsubscribe';
import {Observable} from 'rxjs';
import * as math from 'mathjs';
import {ProposedDebt} from '@models/proposed-debt';
import {DataOverride} from '@models/data-override';
import {Logger, LoggingService} from './logging.service';
import {saveAs as importedSaveAs} from 'file-saver';
import {ResponseTypes} from '@utils/enums';
import {Analysis} from '@models/analysis';
import {LineItemValidator, TemplateItem} from '@models/template-item';
import {Loan} from './fincura-ng-client';
import {CommonFunctions} from '@utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {
  logger: Logger;

  constructor(
    private _apiService: ApiService,
    private _loggingService: LoggingService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('AnalysisService');
  }

  createAnalysis(companyId, name, periods, selectedTemplate): Observable<any>  {
    return this._apiService.send('Post', '/api/analyses', {
      companyId,
      periods,
      name,
      analysisTemplateId: selectedTemplate,
    }).pipe(map(data => data.response.objects.pop()));
  }

  getAnalyses(companyId: number): Observable<any> {
    const filter = { company_id_eq: companyId }
    return this._apiService.send('Post', '/api/analyses/all', { filter: filter }).pipe(
    map(data => data.response.objects));
  }

  getAnalysis(analysisId: number): Observable<any> {
    const filter = { id_eq: analysisId }
    return this._apiService.send('Post', '/api/analyses/all', { filter: filter }).pipe(
    map(data => data.response.objects));
  };


  getAnalysisByUuid(analysisUuid: string): Observable<any> {
    const filter = { uuid_eq: analysisUuid }
    return this._apiService.send('Post', '/api/analyses/all', { filter: filter }).pipe(
    map(data => data.response.objects));
  };



  saveAnalysis(analysisId: number, proposedDebts: Array<ProposedDebt> = [], commentary: string, dataOverrides: Array<DataOverride>) {
    const payload = {
      proposedDebts: proposedDebts,
      commentary: commentary,
      dataOverrides: dataOverrides,
    };
    return this._apiService.send('Patch', `/api/analyses/${analysisId}`, payload).pipe(
      map(data => {
        if (data.response.objects.length > 0) {
          return  data.response.objects[0];
        }
        return null;
      })
    );
  }

  deleteAnalysis(analysisId: number): Observable<any> {
    return this._apiService.send('Delete', `/api/analyses/${analysisId}`);
  }

  evaluateFormulaWithStandardItemExistenceCheck(refId: string, equation: string, scope): number {
    const actualEquation = (equation.startsWith('=')) ? equation.substr(1) : equation;
    // if the analysis template has a standard line item that does not exist within the scopes for a company
    // it can be set to 0 safely
    if (refId === actualEquation && scope[refId] === undefined) {
      return 0;
    }
    return this.evaluateFormula(actualEquation, scope)
  }

  evaluateFormula(equation: string, scope): number {
    if (equation === 'None') {
      return 0;
    } else {
      const actualEquation = (equation.startsWith('=')) ? equation.substr(1) : equation;
      try {
        return math.evaluate(actualEquation, scope);
      } catch (err) {
        this.logger.error('Error in evaluateFormula ' + err.message, {'errorObject': err});
        return 0
      }
    }
  }

  calculateScopes(templateItems, columns, proposedDebts: Array<ProposedDebt> = [],
    dataOverrides, refToOverrideIdx = {}) {
    const scopes = [];

    const refIdsWithRollups = []
    templateItems.forEach((item) => {
      item.children.forEach((childItem) => {
        if (childItem.ref === item.ref) {
          refIdsWithRollups.push(childItem.ref)
        }
      })
    })

    columns.forEach( (column, colIndex) => {
      scopes[colIndex] = {
        'CONST_MONTHS_IN_INTERVAL': column.monthsInInterval
      };

      column.cells.forEach( (cell) => {
        scopes[colIndex][cell.ref] = cell.calculatedValue.value;
      });

      templateItems.forEach( (templateItem, rowIndex) => {
        if (templateItem.ref === 'REF_DSCR_USER_ENTERED_PROP_DEBT') {
          let totalUserEnteredProposedDebt = 0;
          proposedDebts.forEach(proposedDebt => {
            totalUserEnteredProposedDebt += this.calculateProposedDebtIntervalPayment(proposedDebt, column.monthsInInterval);
          });
          scopes[colIndex][templateItem.ref] = totalUserEnteredProposedDebt;
        } else if (!refIdsWithRollups.includes(templateItem.ref)) {
          if (dataOverrides[colIndex][rowIndex] === null || dataOverrides[colIndex][rowIndex] === undefined) {
            if (!!templateItem.equationOverride) {
              scopes[colIndex][templateItem.ref] = this.evaluateFormula(templateItem.equationOverride, scopes[colIndex]);
            } else {
              scopes[colIndex][templateItem.ref] = this.evaluateFormula(templateItem.originalEquation, scopes[colIndex]);
            }
          } else {
            if (dataOverrides[colIndex] && dataOverrides[colIndex][rowIndex] && dataOverrides[colIndex][rowIndex].equation) {
              scopes[colIndex][templateItem.ref] = this.evaluateFormula(dataOverrides[colIndex][rowIndex].equation, scopes[colIndex]);
            }
          }
        }

        // required duplication to be able to calculate scopes for legacy templates and those with sections
        if (templateItem.children.length > 0) {
          templateItem.children.forEach((childItem) => {
            const overrideIdx = refToOverrideIdx[childItem.ref]
            if (childItem.ref === 'REF_DSCR_USER_ENTERED_PROP_DEBT') {
              let totalUserEnteredProposedDebt = 0;
              proposedDebts.forEach(proposedDebt => {
                totalUserEnteredProposedDebt += this.calculateProposedDebtIntervalPayment(proposedDebt, column.monthsInInterval);
              });
              scopes[colIndex][childItem.ref] = totalUserEnteredProposedDebt;
            } else {
              if (dataOverrides[colIndex][overrideIdx] === null || dataOverrides[colIndex][overrideIdx] === undefined) {
                if (!!childItem.equationOverride) {
                  scopes[colIndex][childItem.ref] = this.evaluateFormulaWithStandardItemExistenceCheck(childItem.ref, childItem.equationOverride, scopes[colIndex]);
                } else {
                  scopes[colIndex][childItem.ref] = this.evaluateFormulaWithStandardItemExistenceCheck(childItem.ref, childItem.originalEquation, scopes[colIndex]);
                }
              } else {
                if (dataOverrides[colIndex] && dataOverrides[colIndex][overrideIdx] && dataOverrides[colIndex][overrideIdx].equation) {
                  scopes[colIndex][childItem.ref] = this.evaluateFormula(dataOverrides[colIndex][overrideIdx].equation, scopes[colIndex]);
                }
              }
            }
          })
        }
      });
    });

    return scopes;
  }

  calculateGlobalCashflowScopes(templateItems, columns, proposedDebts: Array<ProposedDebt> = [],
    entitySeperatedOpenTradelines = [], dataOverrides, entityStandardSpreadingTemplateId, linkedEntityUuid = null, tradelineDataOverrides = null) {
    const scopes = [];
    const refIdsWithRollups = []
    templateItems.forEach((item) => {
      item.children.forEach((childItem) => {
        if (childItem.ref === item.ref) {
          refIdsWithRollups.push(childItem.ref)
        }
      })
    })


    columns.forEach( (column, colIndex) => {
      scopes[colIndex] = {
        'CONST_MONTHS_IN_INTERVAL': column.monthsInInterval
      };

      column.cells.forEach( (cell) => {
        scopes[colIndex][cell.ref] = cell.calculatedValue.value;
      });

      let keyFormattedRef;
      templateItems.forEach( (templateItem, rowIndex) => {
        if (dataOverrides[colIndex].hasOwnProperty(templateItem.ref)) {
          scopes[colIndex][templateItem.ref] = this.evaluateFormula(dataOverrides[colIndex][templateItem.ref].equation, scopes[colIndex]);
        } else if (!refIdsWithRollups.includes(templateItem.ref)) {
          if (!!templateItem.equationOverride) {
            scopes[colIndex][templateItem.ref] = this.evaluateFormula(templateItem.equationOverride, scopes[colIndex]);
          } else {
            scopes[colIndex][templateItem.ref] = this.evaluateFormula(templateItem.originalEquation, scopes[colIndex]);
          }
        }
        if (templateItem.children.length > 0) {
          templateItem.children.forEach(childTemplateItem => {
            if (!linkedEntityUuid && childTemplateItem.ref === 'REF_DSCR_USER_ENTERED_PROP_DEBT') {
              let totalUserEnteredProposedDebt = 0;
              proposedDebts.forEach(proposedDebt => {
                totalUserEnteredProposedDebt += this.calculateProposedDebtIntervalPayment(proposedDebt, column.monthsInInterval);
              });
              scopes[colIndex][childTemplateItem.ref] = totalUserEnteredProposedDebt;
            } else if (linkedEntityUuid && childTemplateItem.ref === 'REF_GCF_OPEN_TRADELINES') {
              let totalTradelinesForLinkedEntity = 0;
              entitySeperatedOpenTradelines.forEach(entity => {
                if (entity.entityUuid === linkedEntityUuid) {
                  entity.openTradelines.forEach( (openTradeline, tradelineIdx) => {
                    if (tradelineDataOverrides !== null && tradelineDataOverrides[colIndex][tradelineIdx] !== null) {
                      totalTradelinesForLinkedEntity +=  this.evaluateFormula(tradelineDataOverrides[colIndex][tradelineIdx].equation, scopes[colIndex]);
                    } else if (openTradeline.revolvingOrInstallment === 'revolving' && openTradeline.tradelineRevolvingAmount) {
                      totalTradelinesForLinkedEntity += openTradeline.tradelineRevolvingAmount;
                    } else if (openTradeline.revolvingOrInstallment === 'installment' && openTradeline.tradelineInstallmentMonthlyAmount) {
                      totalTradelinesForLinkedEntity += openTradeline.tradelineInstallmentMonthlyAmount * column.monthsInInterval;
                    }
                  })
                }
              })
              scopes[colIndex][childTemplateItem.ref] = totalTradelinesForLinkedEntity;
            } else {
              keyFormattedRef = CommonFunctions.snakeToCamel(childTemplateItem.ref);
              if (dataOverrides[colIndex].hasOwnProperty(childTemplateItem.ref)) {
                scopes[colIndex][childTemplateItem.ref] = this.evaluateFormula(dataOverrides[colIndex][childTemplateItem.ref].equation, scopes[colIndex]);
              } else if (dataOverrides[colIndex].hasOwnProperty(keyFormattedRef)) {
                scopes[colIndex][childTemplateItem.ref] = this.evaluateFormula(dataOverrides[colIndex][keyFormattedRef].equation, scopes[colIndex]);
              } else {
                if (entityStandardSpreadingTemplateId !== undefined && childTemplateItem.templateSpecificEquations.hasOwnProperty(entityStandardSpreadingTemplateId)) {
                  scopes[colIndex][childTemplateItem.ref] = this.evaluateFormulaWithStandardItemExistenceCheck(childTemplateItem.ref,  childTemplateItem.templateSpecificEquations[entityStandardSpreadingTemplateId], scopes[colIndex]);
                } else if (!!childTemplateItem.equationOverride) {
                  scopes[colIndex][childTemplateItem.ref] = this.evaluateFormulaWithStandardItemExistenceCheck(childTemplateItem.ref, childTemplateItem.equationOverride, scopes[colIndex]);
                } else {
                  scopes[colIndex][childTemplateItem.ref] = this.evaluateFormulaWithStandardItemExistenceCheck(childTemplateItem.ref, childTemplateItem.originalEquation, scopes[colIndex]);
                }
              }
            }
          });
        }
      });
    });

    return scopes;
  }


  calculateProposedDebtIntervalPayment(proposedDebt: ProposedDebt, monthsInInterval: number): number {
    if (proposedDebt.termMonths === '0' || proposedDebt.termMonths === '') {
      return 0;
    }
    if (proposedDebt.amortizationMethod === Loan.PaymentTypeEnum.PrincipalAndInterest) {
      if (![undefined, null].includes(proposedDebt.amortizationOverrides)) {
        if (![undefined, null].includes(proposedDebt.amortizationOverrides.monthlyPayment)) {
          return proposedDebt.amortizationOverrides.monthlyPayment * monthsInInterval;
        }
      }

      const monthlyInterestRate = (proposedDebt.interestRate / 100) / 12;
      const monthlyPayment = (monthlyInterestRate * proposedDebt.principal) / (1 - (1 + monthlyInterestRate) ** -proposedDebt.termMonths);
      return monthsInInterval * monthlyPayment;
    } else if (proposedDebt.amortizationMethod === Loan.PaymentTypeEnum.IoPeriod) {
      const interestRate = proposedDebt.interestRate / 100;
      const payment = proposedDebt.principal * interestRate / 12 * monthsInInterval;
      return payment;
    } else { // somehow got to condition w/ unsupported amortization method
      return 0;
    }
  }

  downloadAnalysisAsFile(
    analysisId: number,
    proposedDebts: Array<ProposedDebt> = [],
    dataOverrides: Array<DataOverride>,
    format: string,
    commentary = ''
  ): Observable<any> {
    const payload = {
      proposedDebts: proposedDebts,
      dataOverrides: dataOverrides,
      commentary: commentary,
    };
    const type = (format === 'excel') ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
    const filename = `analysis.${(format === 'excel') ? 'xlsx' : 'pdf'}`;
    return this._apiService
      .send('Post', `/api/analyses/${analysisId}/download/${format}`, payload, ResponseTypes.ArrayBuffer)
      .pipe(map(data => {
        const blob = new Blob([data.body], { type });
        importedSaveAs(blob, filename);
      }));
  }

  downloadGlobalCashflowAsFile(
    analysisId: number,
    proposedDebts: Array<ProposedDebt> = [],
    dataOverrides: Array<DataOverride>,
    format: string,
    commentary = ''
  ): Observable<any> {
    const payload = {
      proposedDebts: proposedDebts,
      dataOverrides: dataOverrides,
      commentary: commentary,
    };
    const type = (format === 'excel') ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
    const filename = `analysis.${(format === 'excel') ? 'xlsx' : 'pdf'}`;
    return this._apiService
      .send('Post', `/api/global-cashflow-analyses/${analysisId}/download/${format}`, payload, ResponseTypes.ArrayBuffer)
      .pipe(map(data => {
        const blob = new Blob([data.body], { type });
        importedSaveAs(blob, filename);
      }));
  }

  numProposedDebts(analysis: Analysis): number {
    if (analysis && analysis.proposedDebts) {
      return analysis.proposedDebts.length;
    }
    return 0; // if none found, assume 0
  }

  totalProposedDebt(analysis: Analysis): number {
    if (analysis && analysis.proposedDebts) {
      //  below adds up all principals in the array.
      //  a is ongoing sum and is what ultimately gets returned
      //  0 is setting the initial value of a
      return analysis.proposedDebts.reduce(function(a, b) {
        return a + b.principal;
        }, 0);
    }
    return 0; // if none found, assume 0
  }

  checkValidation(validator: LineItemValidator, currentValue: number, scope) {
    const threshold = this.evaluateFormula(validator.thresholdEquation, scope);
    // use of eval here to compare currentValue to threshold value;
    // eslint-disable-next-line
    return eval(`${currentValue} ${validator.comparer} ${threshold}`);
  }

  calculateAllEntityScopes(primaryEntityScopes: Array<{ id: number }>,
                           linkedEntityScopes: Array<Array<{ id: number }>>,
                           sectionToEntitySubsectionRefMap: {[key: string]: string},
                           nonEntitySubsectionItemsForAllEntityScopeCalculations: Array<TemplateItem>
  ): Array<{ id: number}> {
    /*
    * This function calculates the analysis level scopes to be used for section rollups and analysis calculations
    * Additionally it takes into account a legacy format of analysis template within its calculations
    * */

    // Legacy analyses has a separate section header ref and rollup ref, while newer templates
    // have the same ref for both the header and rollup item, requiring the usage of the mapper to ensure
    // the correct values are calculated for a given ref
    const legacyHeaderRefToTotalRef = {
      'REF_GCF_AVAILABLE_AMOUNT_FOR_DEBT_SERVICE': 'REF_GCF_TOTAL_AVAILABLE_FOR_DEBT_SERVICE',
      'REF_GCF_ANNUAL_DEBT_SERVICE_REQUIREMENTS': 'REF_GCF_TOTAL_REQUIRED_DEBT_PAYMENTS'
    }
    const allEntityScopes = [];

    primaryEntityScopes.forEach((primaryEntityScopeForPeriodColumn: {id: number}, colIdx) => {
      for (const [sectionHeaderRef, entitySubsectionRef] of Object.entries(sectionToEntitySubsectionRefMap)) {
        // ensure the ref section header ref is accurate and taking account the legacy refs
        const sectionHeaderRefToUse = legacyHeaderRefToTotalRef[sectionHeaderRef] ? legacyHeaderRefToTotalRef[sectionHeaderRef] : sectionHeaderRef
        if (allEntityScopes[colIdx] === undefined) {
          allEntityScopes.push({})
          allEntityScopes[colIdx][sectionHeaderRefToUse] = 0
        } else {
          allEntityScopes[colIdx][sectionHeaderRefToUse] = 0
        }

        if (Object.keys(primaryEntityScopeForPeriodColumn).includes(entitySubsectionRef)) {
            allEntityScopes[colIdx][sectionHeaderRefToUse] += primaryEntityScopeForPeriodColumn[entitySubsectionRef]
        }

        linkedEntityScopes.forEach( (scopesForLinkedEntityInIteration) => {
          if (Object.keys(scopesForLinkedEntityInIteration[colIdx]).includes(entitySubsectionRef)) {
              allEntityScopes[colIdx][sectionHeaderRefToUse] += scopesForLinkedEntityInIteration[colIdx][entitySubsectionRef]
          }
        })
      }
    })

    // calculate the scopes for analysis calculations across all sections
    for (let idx = 0; idx < allEntityScopes.length; idx++) {
      nonEntitySubsectionItemsForAllEntityScopeCalculations.forEach((item) => {
        allEntityScopes[idx][item.ref] = this.evaluateFormula(!!item.equationOverride ? item.equationOverride : item.originalEquation, allEntityScopes[idx])
      })
    }
    return allEntityScopes;
  }
}
