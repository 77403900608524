<div class="tile-container extra-padding">
    <div class="row header tile-header">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <h3>Current Working Capital</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 gray-text">
                    {{mostRecentStatementDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="graph-container">
                        <app-no-axis-bar-chart
                            [dataToGraph]="graphData"
                            barColor="gray"
                            size="medium"
                        >
                        </app-no-axis-bar-chart>
                    </div>
                </div>
                <div class="col-md-6">
                    <span class="gray-text">Working Capital</span> <br />
                    <h3>{{currencySymbol}}&nbsp;{{analyticsService.formattedValue('REF_WORKING_CAPITAL', analyticsData)}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row line-item bold">
        <div class="col-7">
            <span class="indent-1">
                Current Assets
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_TOTAL_CURRENT_ASSETS', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Cash
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_CASH', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Restricted Cash
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_RESTRICTED_CASH', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Accounts Receivable - Trade
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_ACCT_REC_TRADE', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Bad Debt Reserve
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_BAD_DEBT_RESERVE', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Accounts Receivable - Other
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_ACCT_REC_OTHER', analyticsData)}}
        </div>
    </div>
    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Total Inventory
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_INVENTORY', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Prepaid/Deferred
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_PREPAID_DEFERRED', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Work in Progress
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_WORK_IN_PROGRESS', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Due from Related Party (CP)
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_DUE_FROM_CP', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Notes Receivable
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_NOTES_RECEIVABLE', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Other Current Assets
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_OTH_CURRENT_ASSETS', analyticsData)}}
        </div>
    </div>


    <div class="row line-item bold">
        <div class="col-7">
            <span class="indent-1">
                Current Liabilities
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_TOTAL_CURRENT_LIABILITIES', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Accounts Payable - Trade
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_ACCT_PAYABLE_TRADE', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Bank Credit Line
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_BANK_CREDIT_LINE', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Short Term Notes Payable
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_SHORT_TERM_NOTES_PAYABLE', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                CPLTD - Capital Leases
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_LONG_TERM_DEBT_CAP_LEASE_CP', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                CPLTD - Loans
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_LONG_TERM_DEBT_LOANS_CP', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Deferred Revenue
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_DEFERRED_REVENUE', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Sales Tax Payable
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_SALES_TAX_PAYABLE', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Accrued Liabilities
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_ACCRUED_LIABILITIES', analyticsData)}}
        </div>
    </div>

    <div class="row line-item">
        <div class="col-7">
            <span class="indent-1">
                Other Current Liabilities
            </span>
        </div>
        <div class="col-5 align-right">
            <span class="currency-sign">
                {{currencySymbol}}
            </span>
            {{analyticsService.formattedValue('REF_OTHER_CURRENT_LIABILITIES', analyticsData)}}
        </div>
    </div>

    <div class="full-width-line"></div>
    <div class="final-row">
        <div class="final-row-side">
            Current Ratio
            <h3>{{analyticsService.formattedValue('REF_CURRENT_RATIO', analyticsData)}}</h3>
        </div>
        <div class="final-row-side">
            Quick Ratio
            <h3>{{analyticsService.formattedValue('REF_QUICK_RATIO', analyticsData)}}</h3>
        </div>
    </div>
</div>
