import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../../../services/analytics.service';

@Component({
  selector: 'app-income-statement-table-tile',
  templateUrl: './income-statement-table-tile.component.html',
  styleUrls: ['./income-statement-table-tile.component.scss']
})
export class IncomeStatementTableTileComponent implements OnInit {
  @Input() analyticsData = {};
  @Input() mostRecentStatementDate = '';
  @Input() currencySymbol = '';
  @Input() analyticsDataForNetSales = [];
  @Input() analyticsDataForCogs = [];
  @Input() analyticsDataForGrossProfit = [];

  graphData: Array<number> = [];
  formattedNetSales = '';
  formattedCogs = '';
  formattedGrossProfit = '';

  constructor(
    public analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.setGraphData();
    this.setNetSalesForMostRecentPeriod();
    this.setCogsForMostRecentPeriod();
    this.setGrossProfitForMostRecentPeriod();
  }

  setGraphData(): void {
    if (this.analyticsDataForNetSales) {
      const tempGraphData = [];
      this.analyticsDataForNetSales.forEach((data) => {
        tempGraphData.push(data.value);
      })
      this.graphData = tempGraphData;
    }
  }

  setNetSalesForMostRecentPeriod() {
    this.formattedNetSales = (this.analyticsDataForNetSales && this.analyticsDataForNetSales.length > 0) ?
      this.analyticsDataForNetSales[this.analyticsDataForNetSales.length - 1].formattedValue : '';
  }

  setCogsForMostRecentPeriod() {
    this.formattedCogs = (this.analyticsDataForCogs && this.analyticsDataForCogs.length > 0) ?
      this.analyticsDataForCogs[this.analyticsDataForCogs.length - 1].formattedValue : '';
  }

  setGrossProfitForMostRecentPeriod() {
    this.formattedGrossProfit = (this.analyticsDataForGrossProfit && this.analyticsDataForGrossProfit.length > 0) ?
      this.analyticsDataForGrossProfit[this.analyticsDataForGrossProfit.length - 1].formattedValue : '';
  }
}
