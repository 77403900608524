<div class="modal_header">
    <i class="fas fa-question-circle"></i> CONTACT SUPPORT
</div>
<div class="modal__content">
    <p>For any questions or issues, please email:</p>

    <div class="input-group">
        <input type="text" class="form-control" readonly disabled value="{{supportEmail}}">
        <button class="btn btn-outline-secondary group-append-button"
                type="button" title="Copy refresh token to clipboard"
                (click)="copyToClipboard()">
            <i class="fas fa-clipboard"></i>
        </button>
    </div>
</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="popup.close();">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="copyEmail()">Send Email</button>
</div>

