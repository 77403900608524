import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EnvironmentService } from '@services/environment.service';
import { UserService } from '@services/user.service';

@Injectable()
export class EnterHILStepMiddleware implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private environmentService: EnvironmentService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      this.environmentService.loadEnvConfig().subscribe(() => {
        if (this.userService.user.scope.includes('embedded')) {
          resolve(true)
        } else {
          const rqiUuid = route.params.uuid;
          const statementUuid = route.params.statement_uuid;
          this.userService.getEntitlementDataBasedOnReviewQueueItem(rqiUuid, statementUuid).subscribe(entitlementData => {
            const hasPermission = entitlementData['canViewReviewQueueItem'];
            if (!hasPermission) {
              this.router.navigate(['/denied']);
            }
            resolve(hasPermission)
          });
        }
      });
    });
  }
}
