import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Company } from '../../../models/company';
import { DataFrameService } from '../../../services/dataframes.service';
import { AnalysisService } from '../../../services/analysis.service';
import { SpreadingTemplateService } from '../../../services/spreading-template.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-analysis-form',
  templateUrl: './analysis-form.component.html',
  styleUrls: ['./analysis-form.component.scss']
})
export class AnalysisFormComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company = null;
  @Input() formType = '';

  templates = [];
  selectedTemplate;
  analysisForm: FormGroup;
  availablePeriods: Array<any> = [];
  submitted = false;
  loading = false;

  constructor(
    private _dataFrameService: DataFrameService,
    private formBuilder: FormBuilder,
    private _analysisService: AnalysisService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private _alertService: AlertService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.setForm();

    if (!this.company) {
      return; // somehow got into error state w/ no company id
      // todo, add logger and log error message here for Sentry/DDog
    }

    this._dataFrameService.getPeriodsWithData(this.company.id).subscribe( data => {
      data.forEach(period => {
        this.availablePeriods.push(period);
      });

      this.addCheckboxes();
    });

    this._spreadingTemplateService.getTemplates('DSCR', true).subscribe( (data) => {
      this.templates = data;
      if (data.length > 0) {
        this.selectTemplate(data[0].id);
      }
    });

  }

  setForm(): void {
    this.analysisForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      periods: new FormArray([], [Validators.minLength(1)]), // weirdly, this is first time we're using this pattern
      // and there's a bug with our version of angular where this validator does not work
      // an upgrade at some point in the future should help. :-\
    });
  }

  selectTemplate(templateId: number) {
    this.selectedTemplate = templateId;
  }

  save(analysis: any) {
    this.submitted = true;
    const analysisName = analysis.name.trim();
    
    const isValid = !this.isFormInvalid();
    if (isValid) {
      this.loading = true;
      this._analysisService.createAnalysis(this.company.id, analysisName, this.checkedPeriods(), this.selectedTemplate).subscribe(
        data => {
          this.submitted = false;
          this.popup.close(true, true);
          this._router.navigate(['companies', this.company.uuid, 'analyses', data.uuid, 'new']);
        }, error => {
          this._alertService.error('Problem creating analysis. One probably exists with the same name');
          this.submitted = false;
          this.loading = false;
        }
      );
    }
  }

  isFormInvalid(): boolean {
    return !this.arePeriodsValid() || this.analysisForm.get('name').invalid;
  }

  arePeriodsValid(): boolean {
    return this.checkedPeriods().length !== 0;
  }

  private checkedPeriods(): Array<string> {
    return this.analysisForm.value.periods
      .map((v, i) => v ? this.availablePeriods[i] : null)
      .filter(v => v !== null);
  }

  private addCheckboxes() {
    this.availablePeriods.forEach((o, i) => {
      const control = new FormControl(true); // sets all checked to true
      (this.analysisForm.controls.periods as FormArray).push(control);
    });
  }

  selectOrDeselectAllPeriods() {
    const isAnyPeriodChecked = this.isAnyPeriodChecked();
    const periodsArray = this.analysisForm.get('periods') as FormArray;
    for (const period of periodsArray.controls) {
      period.setValue(!isAnyPeriodChecked);
    }
  }

  isAnyPeriodChecked() {
    return this.analysisForm.value.periods.some(checked => checked === true);
  }
}
