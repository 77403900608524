export class SignedUrl {
  scope: string;
  key: string;
  url: string;
  jwt?: string;
  jwtPayload?: object;
  expirationDate: string;
  label?: string;
  refreshToken?: string;
}
