import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxPopupComponent } from '../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { CommonFunctions } from '../../../../utils/common-functions';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { ApiService } from '../../../../services/api.service';
import { DeveloperService } from '../../developer.service';

@Component({
    selector: 'app-cache-component',
    templateUrl: './cache.component.html',
    styleUrls: ['./cache.component.css']
})
@AutoUnsubscribe('subsArr$')
export class CacheComponent implements OnInit {
    @Input() popup: NgxPopupComponent;

    restForm: FormGroup;
    subsArr$: Subscription[] = [];
    public loading = false;
    public loadMessage = '';

    constructor(private formBuilder: FormBuilder,
        private apiService: ApiService
    ) {

    }

    ngOnInit(): void {
        this.setInitialForm();
    }

    /**
     * Construct form
     */
    setInitialForm(): void {
        this.restForm = this.formBuilder.group({
            progress: [''],
            response: [''],
            body: ['']
        });

        this.loading = false;
    }

    /**
     * Send the http request based on user choice
     * Delegate to apiService to get headers and so on for free
     *
     * @param devactors
     * @param {boolean} isValid
     */
    send() {
        this.loading = true;

        const body = `{"command": "${this.restForm.get('body').value.trim()}"}`;
        const url = '/api/developer/cache';

        this.subsArr$.push.apply(this.apiService.send('POST', url, body)
            .subscribe(
                data => {
                    const response = data.response.objects[0];
                    this.restForm.get('response').setValue(response);
                    this.loadMessage = '';
                },
                error => {
                    this.loadMessage = JSON.stringify(error);
                },
                () => {
                }
            ));
    }
}
