<ng-container *ngIf="imageLoaded; else imagePlaceholder">
    <div
        class="fs-block fs-exclude background-image"
        #highlightedImgId
        [ngStyle]="imageStyles"
    ></div>

    <div class="cropper"
         #cropper
         *ngIf="cropper"
         [style.left.px]="xcoord"
         [style.top.px]="ycoord"
         [style.width.px]="width"
         [style.height.px]="height"
    >
    </div>
</ng-container>
<ng-template #imagePlaceholder>
    <div class="image-placeholder" [style.height.px]="imagePlaceholderHeight">
        <ng-container *ngIf="!imageFailedToLoad; else imageLoadError">
            <div class="loader"></div>
        </ng-container>
        <ng-template #imageLoadError>
            <i class="fa fa-circle-exclamation"></i>
        </ng-template>
    </div>
</ng-template>
