import { DocumentType } from '../utils/enums';

export interface ShortenedObject {
  id: number;
  documentType?: DocumentType;
}

export class ClientSearch {
  private _reportingCompliance: string;
  private _covenantCompliance: string;
  private _portfolio: string;
  private _search: string;
  private _customerIDSearch: string;
  private _sicCode: string;
  private _region: string;

  constructor() {
    this._reportingCompliance = ' ';
    this._covenantCompliance = ' ';
    this._portfolio = ' ';
    this._search = '';
    this._customerIDSearch = '';
    this._sicCode = '';
    this._region = '';
  }

  get reportingCompliance(): string {
    return this._reportingCompliance;
  }

  set reportingCompliance(value: string) {
    this._reportingCompliance = value;
  }

  get covenantCompliance(): string {
    return this._covenantCompliance;
  }

  set covenantCompliance(value: string) {
    this._covenantCompliance = value;
  }

  get portfolio(): string {
    return this._portfolio;
  }

  set portfolio(value: string) {
    this._portfolio = value;
  }

  get search(): string {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
  }

  get customerIDSearch(): string {
    return this._customerIDSearch;
  }

  set customerIDSearch(value: string) {
    this._customerIDSearch = value;
  }

  get sicCode(): string {
    return this._sicCode;
  }

  set sicCode(value: string) {
    this._sicCode = value;
  }

  get region(): string {
    return this._region;
  }

  set region(value: string) {
    this._region = value;
  }

  isEmptySearch(): boolean {
    return Object.keys(this).every(attrKey => !this[attrKey] || this[attrKey] === " ");
  }
}
