import { Component, OnInit, Input } from '@angular/core';
import { ACCOUNTING_INTEGRATION } from '@utils/constants';

@Component({
  selector: 'app-accounting-integration-logo',
  templateUrl: './accounting-integration-logo.component.html',
  styleUrls: ['./accounting-integration-logo.component.scss']
})
export class AccountingIntegrationLogoComponent implements OnInit {
  @Input() vendor: string;

  imgSrc: string;
  logoWidth = '100%';
  constructor() { }

  ngOnInit(): void {
    switch (this.vendor) {
      case ACCOUNTING_INTEGRATION.QBO:
        this.imgSrc = '/assets/images/vendor/qb-logo-horizontal-preferred.svg';
        break;
      case ACCOUNTING_INTEGRATION.XERO:
        this.logoWidth = '25%';
        this.imgSrc = '/assets/images/vendor/Xero_software_logo.svg';
        break;
      default:
        this.imgSrc = '';
    }
  }

}
