<div class="container">
<div class="row">
    <div class="col-sm-12">
		<div class="access_denied">
		<span class="ban"><i class="fas fa-ban"></i></span>
		<h1>Access Denied</h1>
		</div>
	</div>
</div>
</div>
