import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements OnChanges, OnInit {
  @Input() legendOptions = ['EBITDA']
  @Input() unitsType: 'days'|'currency' = 'currency';
  @Input() data = [];
  @Input() currencySymbol = '';

  minimumSpaceForNumberOfGraphs = 6;

  @ViewChild('legend', { static: true }) legendContainer: ElementRef;
  @ViewChild('chart', { static: true })
  private chartContainer: ElementRef;

  constructor() { }

  ngOnChanges(): void {
    if (!this.data) { return; }
    this.createChart();
  }

  ngOnInit(): void {
    this.createChart();
  }

  areAllValuesPositive(): boolean {
    const values = this.data.map( item => item.value )
    const minValue = Math.min(...values);
    return minValue >= 0;
  }

  areAllValuesNegative(): boolean {
    const values = this.data.map( item => item.value )
    const maxValue = Math.max(...values);
    return maxValue < 0;
  }

  originalLength(): number {
    return this.data.length;
  }

  dataToGraph() {
    const graphLengthToForce = this.minimumSpaceForNumberOfGraphs;
    if (this.data === null || this.data === undefined ) {
      return [];
    }

    if (this.data.length === 0) {
      return [];
    }

    const data = [...this.data];

    while (data.length <= graphLengthToForce) {
      data.push({label: data.length.toString(), value: (data.length * .01)});
    }

    return data;
  }

  calculateXTickValues(dataset): Array<string> {
    let xTickValues = [];
    if (this.originalLength() > this.minimumSpaceForNumberOfGraphs) {
      xTickValues = [
        dataset[0].label,
        dataset[dataset.length - 1].label
      ]
    } else if (dataset.length > 1) {
      xTickValues = this.data.map( (item) => item.label);
    } else if (dataset.length === 1) {
      xTickValues = [dataset[0].label];
    }

    return xTickValues;
  }

  private createChart(): void {
    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll('svg').remove();

    const dataset = this.dataToGraph();

    const leftAlignAllowSpaceForAxisLabels = 125;
    const rightAlignAllowSpaceForAxisLabels = 60;
    const standardMargin = 30;
    const margin = {
      top: standardMargin,
      right: rightAlignAllowSpaceForAxisLabels,
      bottom: standardMargin,
      left: leftAlignAllowSpaceForAxisLabels
    };

    const highestAreaTooltipCanStart = 280; // for negative bars, 280 is equivalent to the start of hte top of the tooltip box from top down coordinates

    const tooltipWidth = 200;
    const tooltipHeight = 100;

    const width = 860;
    const height = 350;

    const xScale = d3.scaleBand()
                  .rangeRound([0, width])
                  .padding(0.1)
                  // @ts-ignore
                  .domain(dataset.map(function(d) {
                    return d.label;
                  }));

    const yScaleDomainMin = () => {
      if (this.areAllValuesPositive()) {
        return 0
      } else {
        return d3.min(dataset, ( (d) => d.value ))
      }
    }

    const yScaleDomainMax = () => {
      if (this.areAllValuesNegative()) {
        return 0
      } else {
        return d3.max(dataset, ((d) => d.value ))
      }
    }

    const yScale = d3.scaleLinear()
                  .rangeRound([height, 0])
                  // @ts-ignore
                  .domain([
                    yScaleDomainMin(),
                    yScaleDomainMax(),
                  ]);

    const widthWithMargins = width + margin.left + margin.right;
    const heightWithMargins = height + margin.top + margin.bottom;

    const svg = d3.select(element)
              .append('svg')
              .attr('width', '100%')
              // .attr('height', heightWithMargins)
              .attr('preserveAspectRatio', 'xMinYMin meet')
              .attr('viewBox', `0 0 ${widthWithMargins} ${heightWithMargins}`)
              .classed('svg-content-responsive', true);

    const g = svg.append('g')
              .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const xTickValues = this.calculateXTickValues(dataset);

    // axis-x
    g.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(xScale)
          .tickValues(xTickValues)
        )

    const formatTick = (d) => {
      if (d === null) {
        return '';
      }
      if (this.unitsType === 'currency') {
        const thousandsCommaSeparated = d.toLocaleString();
        return `${this.currencySymbol} ${thousandsCommaSeparated}`;
      } else if (this.unitsType === 'days') {
        return `${d} days`;
      }
    }

    // axis-y
    g.append('g')
        .attr('class', 'axis axis--y')
        .classed('axisTeal', true)
        .call(d3.axisLeft(yScale)
          .ticks(5)
          .tickFormat(formatTick)
        );

    const isTooltipHovered = () => {
      const allHovered = d3.select(element).selectAll('.tooltip-related:hover');
      const all = d3.select(element).selectAll('.tooltip-related');

      return allHovered.size() > 0;
    }

    const bar = g.selectAll('rect')
      .data(dataset)
      .enter().append('g');

    const graphOnMouseOver = (_, i) => {
      d3.select(element).select('.tooltip-' + i).attr('display', 'block');
      d3.select(element).select('.tooltip-text-' + i).attr('display', 'block');

      d3.select(element).selectAll('.bar').classed('bar-in-the-background', (backgroundData, backgroundIndex, nodes) => {
        return i !== backgroundIndex; // turn on class for every .bar except hovered bar
      });
    }

    const graphOnMouseOut = (_, i) => {
      if (isTooltipHovered()) { // don't hide the graph if hoving over tooltip elements
        return;
      }

      d3.select(element).select('.tooltip-' + i).attr('display', 'none');
      d3.select(element).select('.tooltip-text-' + i).attr('display', 'none');

      d3.select(element).selectAll('.bar').classed('bar-in-the-background', (backgroundData, backgroundIndex, nodes) => {
        return false; // make every .bar not have this bar-in-the-background class
      });
    }

    const tooltipOnMouseOut = (_, i) => {
      if (isTooltipHovered()) { // don't hide elements if hovering over tooltip elements themselves
        return;
      }

      d3.select(element).select('.tooltip-' + i).attr('display', 'none');
      d3.select(element).select('.tooltip-text-' + i).attr('display', 'none');

      d3.select(element).selectAll('.bar').classed('bar-in-the-background', (backgroundData, backgroundIndex, nodes) => {
        return false; // make every .bar not have this bar-in-the-background class
      });
    }

    bar.append('rect')
      .attr('x', function(d) { return xScale(d.label); })
      .attr('y', function(d) {
        if (d.value < 0) {
          return yScale(0);
        }
        return yScale(d.value);
      })
      .attr('width', xScale.bandwidth())
      .attr('height', function(d) {
        if (d.value > 0) {
          d3.min(dataset, ( (d) => d.value ))
          return yScale(0) - yScale(d.value);
        } else {
          return yScale(d.value) - yScale(0);
        }
      })
      .classed('bar', true)
      .on('mouseover', graphOnMouseOver)
      .on('mouseout', graphOnMouseOut);

    const tooltipGroup = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .selectAll('rect')
      .data(dataset)
      .enter()
      .append('g');

    const tooltipXAttribute = (d, i) => {
      const endingRightSideOfTooltip = xScale(d.label) + tooltipWidth;
      if (endingRightSideOfTooltip > width) { // if tooltip is going off the svg
        return width - tooltipWidth; // make tip start where it will not end off the svg;
      } else {
        return xScale(d.label);
      }
    }


    const tooltipYAttribute = (d, i) => {
      if (d.value > 0) {
        return 0; // if positive, display at the top.
      } else {
        if (yScale(d.value) > highestAreaTooltipCanStart) {
          return highestAreaTooltipCanStart; // if very negative, don't go off screen.
        }
        return yScale(d.value); // if negative, display at bottom.
      }
    }

    tooltipGroup.append('rect')
      .attr('display', 'none')
      .attr('class', (_, i) => 'tooltip-' + i )
      .classed('svg-tooltip', true)
      .classed('tooltip-related', true)
      .attr('x', tooltipXAttribute)
      .attr('y', tooltipYAttribute)
      .attr('width', tooltipWidth)
      .attr('height', tooltipHeight)
      .on('mouseout', tooltipOnMouseOut);

    const tooltipElement = tooltipGroup.append('text')
      .attr('display', 'none')
      .attr('class', (_, i) => 'tooltip-text-' + i )
      .classed('svg-tooltip-text', true)
      .classed('tooltip-related', true)
      .attr('x', tooltipXAttribute)
      .attr('y', (d, i) => tooltipYAttribute(d, i) + 20)
      .attr('width', tooltipWidth)
      .attr('height', tooltipHeight)
      .on('mouseout', tooltipOnMouseOut);

    tooltipElement.append('tspan')
      .attr('x', tooltipXAttribute)
      .attr('y', (d, i) => tooltipYAttribute(d, i) + 30)
      .attr('dx', 10)
      .classed('svg-tspan', true)
      .classed('tooltip-related', true)
      .text( (d) => {
        let roundedDollarAmount = '';
        if (d.value === null) {
          roundedDollarAmount = '';
        } else {
          roundedDollarAmount = `${this.currencySymbol} ${d.value.toLocaleString()}`;
        }

        return roundedDollarAmount;
      });

      tooltipElement.append('tspan')
        .attr('x', tooltipXAttribute)
        .attr('y', (d, i) => tooltipYAttribute(d, i) + 55)
        .attr('dx', 10)
        .classed('svg-tooltip-subtext', true)
        .classed('tooltip-related', true)
        .text( (d) => {
          return d.label;
        });

    this.drawLegend();
  }

  private drawLegend(): void {
    const element = this.legendContainer.nativeElement;
    d3.select(element).selectAll('.svg-legend').remove();

    const margin = {top: 20, right: 20, bottom: 30, left: 40},
        width = 400,
        height = 15;

    const widthWithMargins = width + margin.left + margin.right;
    const heightWithMargins = height + margin.top + margin.bottom;

    const svg = d3.select(element)
              .append('svg')
              .attr('width', width)
              .attr('height', height)
              .attr('preserveAspectRatio', 'xMinYMin meet')
              .attr('viewBox', `0 0 ${width} ${height}`)
              .classed('svg-content-responsive', true)
              .classed('svg-legend', true);

    const legendRectSize = 18;
    const legendSpacing = 4;

    const legend = svg.selectAll('.bar-chart-legend')
                  .data(this.legendOptions)
                  .enter()
                  .append('g')
                  .attr('class', 'bar-chart-legend')
                  .attr('transform', function(d, i) {
                    const vert = 0;
                    const horz = margin.left + 100 * (i);
                    return 'translate(' + horz + ',' + vert + ')';
                  });

    legend.append('rect')
      .attr('width', legendRectSize)
      .attr('height', legendRectSize)
      .style('fill', function(d, i) {
        if (i === 0) {
          return '#30B2B9';
        } else {
          return 'royalblue';
        }
      })
      .style('stroke', function(d, i) {
        if (i === 0) {
          return '#30B2B9';
        } else {
          return 'royalblue';
        }
      });

    legend.append('text')
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize - legendSpacing)
      .text(function(d) { return d; });

  }
}
