<div class="page-content-container">
    <div class="top-header">
        <div class="page-title">Manage Footnotes</div>
        <div class="action-container">
            <button class="btn btn-outline-secondary" type="button" (click)="handleExit()">
                <span class="size">Cancel</span>
            </button>
            <div class="btn-separator">|</div>
            <button class="btn btn-primary" type="button" (click)="handleSave()">
                <span class="size">Save Footnotes</span>
            </button>
        </div>
    </div>
    <app-table-drawing-page
        [allowOnlyOneBox]="false"
        [pageImages]="documentFile?.pageImages"
        [currentBoxesByPage]="currentBoxesByPage"
        (boxesChanged)="boxesChanged($event)"
    ></app-table-drawing-page>


    <ng-container *ngIf="isLoading">
        <div class="loader__overlay">
            <div class="loader"></div>
        </div>
    </ng-container>
</div>
