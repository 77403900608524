import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-no-axis-bar-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './no-axis-bar-chart.component.html',
  styleUrls: ['./no-axis-bar-chart.component.scss']
})
export class NoAxisBarChartComponent implements OnInit, OnChanges {
  @Input() isShown = true;
  @Input() dataToGraph = [];
  @Input() size: 'small'|'medium' = 'small';
  @Input() isActive = false;

  @ViewChild('chart', { static: true }) chartContainer: ElementRef;
  multiplier = 1;
  divisor = 1;

  data = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.dataToGraph) { return; }

    this.fillDataArray();
    this.calculateMultipliers();
    this.createChart();
  }

  ngOnInit(): void {
    this.createChart();
  }

  fillDataArray(): void {
    const graphLengthToForce = 6;
    if (this.dataToGraph === null || this.dataToGraph === undefined ) {
      return;
    }

    if (this.dataToGraph.length === 0) {
      return;
    }

    this.data = [...this.dataToGraph];

    while (this.data.length <= graphLengthToForce) {
      this.data.push(0);
    }
  }

  calculateMultipliers(): void {
    if (this.data === null || this.data === undefined ) return;

    const highestNumberToAllowInGraph = 25;
    const smallMaximumNumberThreashold = 5; // make sure we don't graph a bunch of hyphens
    const largestNumber = Math.max(...this.data);

    if (largestNumber > highestNumberToAllowInGraph) {
      this.divisor = largestNumber / highestNumberToAllowInGraph;
    } else if (largestNumber < smallMaximumNumberThreashold) {
      this.multiplier = highestNumberToAllowInGraph /  largestNumber;
    }
  }

  onResize() {
    this.createChart();
  }

  areAllValuesPositive(): boolean {
    const minValue = Math.min(...this.data);
    return minValue >= 0;
  }


  private createChart(): void {
    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll('.no-axis-bar-chart').remove();

    let eachBarWidth = -1;
    let marginRight = -1;
    let height = -1;
    if (this.size === 'medium') {
      // eachBarWidth = 12;
      eachBarWidth = 30;
      marginRight = 4;
      height = 40;
    } else if (this.size === 'small') {
      eachBarWidth = 12;
      marginRight = 2;
      height = 25;
    }
    const margin = {top: 0, right: 30, bottom: 0, left: 0};

    let widthLength = this.data.length;
    if (widthLength < 8) {
      widthLength = 8;
    }

    const width = widthLength * (eachBarWidth + marginRight) - margin.left - margin.right;
    const heightWithMargins = height + margin.top + margin.bottom;

    let padding = 0;
    if (this.size === 'small') {
      padding = 0.2;
    } else if (this.size === 'medium') {
      padding = 0.3;
    }

    // set the ranges
    const x = d3.scaleBand()
              .range([0, width])
              .padding(padding)
              // @ts-ignore
              .domain( this.data.map( (_, index) => index  ))

    const yScaleDomainMin = () => {
      if (this.areAllValuesPositive()) {
        return 0
      } else {
        return d3.min(this.data)
      }
    }


    const y = d3.scaleLinear()
      .rangeRound([height, 0])
      // @ts-ignore
      .domain([yScaleDomainMin(), d3.max(this.data)]);

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    const svg = d3.select(element)
      .append('svg')
      .attr('width', '100%')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${width} ${heightWithMargins}`)
      .classed('svg-content-responsive', true)
        .classed('no-axis-bar-chart', true)
        .append('g')
        .attr('transform',
              'translate(' + margin.left + ',' + margin.top + ')');


  // append the rectangles for the bar chart
  svg.selectAll('.bar')
      .data(this.data)
      .enter().append('rect')
      .attr('class', () => {
        if (this.size === 'small') {
          return 'small-bar';
        } else if (this.size === 'medium') {
          return 'medium-bar';
        }
      })
      .classed('inactive', (d) => ( !this.isActive ))
      .attr('x', function(d, i) {
        // @ts-ignore
        return x(i);
      })
      .attr('width', x.bandwidth())
      // @ts-ignore
      .attr('y', function(d) {
        if (d < 0) {
          return y(0);
        }
        return y(d);
      })
      .attr('height', (d) => {
        if (d > 0) {
          d3.min(this.data, ( (d) => d ))
          return y(0) - y(d);
        } else {
          return y(d) - y(0);
        }
      })
  }
}
