import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {DataView, DataViewColumn} from '@models/dataview';
import {SharedDataService} from '@services/shared-data.service';
import {DataViewService} from '@services/data-view.service';
import {DataFrameService} from '@services/dataframes.service';
import {DownloadService} from '@services/download.service';
import {forkJoin, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AutoUnsubscribe} from '../../../../../../decorators/auto-unsubscribe';
import {Company} from '@models/company';
import {Period} from '@models/period';
import {Select2OptionData} from '@components/shared/select2/select2.interface';
import {DocumentFileService} from '@services/document-file.service';
import {ContextMenuDocument} from './view-templated/table/cell/context-menu';
import {DataViewConfig} from '@models/dataviewconfig';
import {Footnote} from '@models/footnote';
import {HideRowColStack} from '@models/hide-row-col';
import {COMPANY_ENTITLEMENT_DATA, COMPANY_PAGE_VIEW_MODE, COMPANY_TABS, USER_GUIDES} from '@utils/constants';
import {InsightsService} from '@services/insights.service';
import {PeriodOverPeriodAnalysis} from '@models/period-over-period-analysis'
import {UserGuideService} from '@services/user-guide.service';
import {AlertService} from '@services/alert.service';
import {Logger, LoggingService} from '@services/logging.service';
import {NgxPopupComponent, NgxPopupService} from '@components/shared/ngx-popups/ngx-popups/ngx-popups';
import {ConfirmationPopupComponent} from '@components/shared/popups/confirmation/confirmation-popup.component';
import {UserService} from '@services/user.service';
import {CellInformationService} from '@services/shared/cell-information/cell-information.service';
import {BankSettingsService} from '@services/bank-settings.service';
import {PdfDownloadModalComponent} from "@components/shared/pdf-download-modal/pdf-download-modal.component";
import {ChangeLogModalComponent} from '@components/shared/change-log-modal/change-log-modal.component';
import {CallTypeCategory, StatementBuilderMode} from '@utils/enums';
import {LaunchDarklyService} from '@services/launchdarkly.service';
import {SpreadingTemplateService} from "@services/spreading-template.service";
import {MenuItem} from "@components/shared/popover-menu/popover-menu.component";
import {
  UploadDocumentModalComponent
} from "@components/shared/popups/upload-document-modal/upload-document-modal.component";
import {FilterStatementsComponent} from '@components/shared/filter-statements/filter-statements.component';
import {BorrowerService} from '@services/borrower.service';
import {MultiSelectItem} from "@components/shared/popover-menu-multi-select/popover-menu-multi-select.component";


@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class AnalysisComponent implements OnInit, OnDestroy {

  logger: Logger;
  subsArr$: Subscription[] = [];
  dataViewId: number = null;
  loadingMessage = '';
  loading = true;
  didErrorOccur = false;
  activeDataView: DataView = null;
  periodOptions: Array<Period>;
  periodSelectOptions: Array<Select2OptionData>;
  selectedPeriodOption: Period = new Period();
  roundToThousands = false;
  roundToThousandsDisabled = false;
  dollarSign = false;
  dollarSignDisabled = false;
  numbers = true;
  numbersDisabled = false;
  commonSize = false;
  commonSizeDisabled = true;
  trendDisabled = false;
  trend = false;
  sourceItems = false;
  sourceItemsDisabled = true;
  removeHiddenDisabled = true;
  company: Company = null;
  dataViewsList: Array<DataView>;
  showBenchmarking = false;
  isGraphShown = false;
  graphItems: any[] = [];
  graphYAxisLabel = '';
  graphItemLabels = '';
  hiddenRows: Set<number> = new Set();
  hiddenColumns: Set<string> = new Set();
  dataViewConfigId: number;
  showHidden = false;
  showHiddenDisabled = true;
  isFootnotesViewOn = false;
  isStatementBuilderFlyoutOpen = false;
  statementBuilderEditMode: StatementBuilderMode;
  frameToBeEditedId: number;
  footnote: Footnote = null;
  activeFootnotePage = 0;
  docFileId = 0;
  cropper = null;
  gotCompany = false;
  showSpreadingInContextMenu = true;
  showCalculating = false;
  retries = 0;
  finishedCalculating = false;
  showCallToAction = false;
  timeout;
  hideRowColUndoStack: HideRowColStack = new HideRowColStack();
  clearUndoDisabled = true;
  embeddedMode = false;
  projectionScenarioTypeLabelOverride = 'Projection';
  showDownloadWorkbookButton = false;
  referenceSheetExistsForCompanyDefaultTemplate = false;
  referenceSheetKey = ''
  commonSizeDecimalPrecision = 1;
  spreadingTemplateItems: Array<any> = [];

  // Keeps track of the items that have been selected for graphing (or other purposes)
  selectedRows: Array<number> = [];

  documentMenuItems: Array<ContextMenuDocument> = [];

  // Determines whether the user can see dataviews generated directly from the spread rather than just through a template (to support Dan from Capx for now)
  showNormalizedDataViews = false;

  // insights related properties
  showInsights = false;
  insights: Array<PeriodOverPeriodAnalysis> = [];
  insightsPayload = null;
  currentInsightLabel = '';

  // View mode
  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS;
  readonly COMPANY_PAGE_VIEW_MODE = COMPANY_PAGE_VIEW_MODE;
  noAccessErrorText = "You don't have enough permissions to view this page.";
  routeData: Data;
  hasEditSpreadPermission: boolean;
  canViewFinancials: boolean;

  actionMenuItems: MenuItem[];

  //empty state
  noFinancialsExistIcon = 'fa-magnifying-glass-chart';
  noFinancialsExistHeaderText = 'No Financials Added';
  noFinancialsExistHelpText = "Get started by uploading and spreading a document.";
  showEmptyStateUploadButton = false
  shouldShowEmptyState = false
  noSourceDataExistsIcon = 'fa-magnifying-glass-chart';
  noSourceDataExistsHeaderText = 'No Source Data';
  noSourceDataExistsHelpText = "No source data exists for this statement.";

  reportingIntervalFilter: MultiSelectItem[] = [];
  yearsFilter: MultiSelectItem[] = [];
  scenarioFilter: MultiSelectItem[] = [];
  shouldResetEverything: boolean = false;
  financialSummaryFilter: {[key: string]: MultiSelectItem[]}

  isTextInsightsComponentOpen = false


  constructor(
    private _route: ActivatedRoute,
    private _popupService: NgxPopupService,
    private _sharedData: SharedDataService,
    private dataViewService: DataViewService,
    private _dataFrameService: DataFrameService,
    private _downloadService: DownloadService,
    private _documentFileService: DocumentFileService,
    private _changeDetector: ChangeDetectorRef,
    public userGuideService: UserGuideService,
    private _insightsService: InsightsService,
    private alertService: AlertService,
    private _loggingService: LoggingService,
    private userService: UserService,
    private router: Router,
    private cellInformationService: CellInformationService,
    private bankSettingsService: BankSettingsService,
    private launchDarklyService: LaunchDarklyService,
    private spreadingTemplateService: SpreadingTemplateService,
    private popupService: NgxPopupService,
    private _borrowerService: BorrowerService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('UserService');
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.getFeatureFlags();
    this.embeddedMode = this._sharedData.embeddedMode$.value;
    this.parseEntitlementData();
    if ([COMPANY_PAGE_VIEW_MODE.FULL, COMPANY_PAGE_VIEW_MODE.VIEW_ONLY].includes(this.pageViewMode)) {
      this._route.paramMap.subscribe(params => {
        this.cellInformationService.hideCellInformation();
        const dvid = params.get('id');
        if (dvid) {
          this.dataViewId = parseInt(dvid, 10);
          this.loadData(false);
        }
      });

      this.subsArr$.push(this._sharedData.company$.pipe(
        filter(newCompany => this._shouldReloadCompanyData(newCompany)))
        .subscribe((company: Company) => {
          this.gotCompany = true;
          this.company = company;
          this.shouldShowEmptyState = !!company.defaultSpreadingTemplate
          this.loadData(true);
          this.loadFiles();
        }),
      );
      this.showSpreadingInContextMenu = !this.embeddedMode;
      this.subsArr$.push(
        this.dataViewService.requestForDataViewToBeSaved.subscribe(requested => {
          if (requested) {
            this.dataViewService.save(this.activeDataView).subscribe(_ => {
              this.alertService.success('Success!');
              this.cellInformationService.hideCellInformation();
            });
          }
        })
      );
    } else {
      this.loading = false;
    }
    this.buildActionMenuItems();
    this.shouldResetEverything = false;

  }

  ngOnDestroy() {
    this.stopPolling();
    this.userGuideService.remove(USER_GUIDES.VIEWING_DATA);
    this.userGuideService.remove(USER_GUIDES.REMOVING_DATA);
    this.userGuideService.remove(USER_GUIDES.BUILD_STATEMENTS_FROM_EXISTING_DATA);
  }

  buildActionMenuItems() {
    this.actionMenuItems = [
      {
        title: 'Download PDF',
        subtitle: 'Download financial summary to pdf',
        faIconClass: 'fa-file-pdf',
        action: this.onClickPdfDownload.bind(this),
        disabled: false,
        visible: true
      },
      {
        title: 'Download Excel',
        subtitle: 'Download financial summary to excel',
        faIconClass: 'fa-file-excel',
        action: this.downloadAsExcel.bind(this),
        disabled: false,
        visible: true
      },
      {
        title: 'Download Workbook Export',
        subtitle: 'Download a workbook file specific to your bank/business',
        faIconClass: 'fa-file-excel',
        action: this.downloadAsExcelWorkbook.bind(this),
        disabled: false,
        visible: (this.showDownloadWorkbookButton && this.activeDataView?.templated && this.referenceSheetExistsForCompanyDefaultTemplate)
      },
      {
        divider: true
      },
      // {
      //   title: 'Filter Statements',
      //   subtitle: 'Select which you want to view in the financial summary',
      //   faIconClass: 'fa-filter',
      //   action: this.openFilterStatementFlyout.bind(this),
      //   disabled: false,
      //   visible: true
      // },
      {
        title: 'Build New Statement',
        subtitle: 'Use this tool to consolidate periods or fill in missing periods',
        faIconClass: 'fa-wrench',
        action: this.openStatementBuilderFlyout.bind(this),
        disabled: false,
        visible: this.hasEditSpreadPermission
      },
      {
        title: 'View Financial Insights',
        subtitle: 'Use this to view narratives about financial data',
        faIconClass: 'fa-wrench',
        action: this.openTextInsightsComponent.bind(this),
        disabled: false,
        visible: true
      },
      {
        title: 'View Change Log',
        subtitle: 'View the history of changes to this financial summary',
        faIconClass: 'fa-book',
        action: this.openSpreadChangeHistory.bind(this),
        disabled: !this.company,
        visible: true
      }
    ];
  }

  getFeatureFlags() {
    this.showDownloadWorkbookButton = this.launchDarklyService.flags['download-excel-workbook'];
    this.subsArr$.push(this.launchDarklyService.flagChange.subscribe((flags) => {
      this.showDownloadWorkbookButton = flags['download-excel-workbook'];
      this.buildActionMenuItems();
    }));
  }

  _shouldReloadCompanyData(newCompany) {
    return newCompany && newCompany?.id && newCompany?.id !== this.company?.id
  }

  closeFootnotes(): void {
    this.isFootnotesViewOn = false;
  }

  openFootnotesOrSourceBox(footnote: Footnote): void {
    if (this.isStatementBuilderFlyoutOpen) {
      this.alertService.warning('Please close statement builder tool before viewing footnotes.');
      return;
    }
    this.closeFootnotes();
    this._changeDetector.detectChanges();
    this.footnote = footnote;
    this.isFootnotesViewOn = true;
    if (this.footnote) {
      this.cropper = {
        'x1': this.footnote.notesX1,
        'y1': this.footnote.notesY1,
        'x2': this.footnote.notesX2,
        'y2': this.footnote.notesY2,
        'width': this.footnote.imageWidth,
        'height': this.footnote.imageHeight
      };
    }
    this.activeFootnotePage = this.footnote.pageId;
    this.docFileId = this.footnote.docFileId;
  }

  openStatementBuilderFlyout(): void {
    if (this.isFootnotesViewOn) {
      this.alertService.warning('Please close footnotes before using statement builder tool.');
      return;
    }
    this.isStatementBuilderFlyoutOpen = true;
  }

  openTextInsightsComponent(): void {
    this.isTextInsightsComponentOpen = true;
  }

  closeStatementBuilderFlyout(newStatementCreated = false): void {
    this.isStatementBuilderFlyoutOpen = false;
    this.statementBuilderEditMode = null;
    this.frameToBeEditedId = null;
    if (newStatementCreated) {
      this.loadData(true);
    }
  }

    closeTextInsightsComponent(): void {
    this.isTextInsightsComponentOpen = false;
  }

  /**
   * While we figure out how to manage showing raw/normalized/calc, hardcode
   * here that we're not showing normalized unless the flag is on
   */
  shouldShowDataView(dv): boolean {
    if (this.showNormalizedDataViews) {
      return true;
    }

    const hideDataViews = ['Normalized IS', 'Normalized BS', 'Normalized CF', 'Normalized CV', 'Normalized Cash Flow Statement', 'Normalized Balance Sheet', 'Normalized Income Statement', 'Calculations & Ratios', 'Benchmarks'];

    if (hideDataViews.indexOf(dv.title) >= 0) {
      return false;
    }
    return true;
  }

  formatDataViewName(name: string): string {
    const nameTranslate = {
      'Raw IS': 'Source IS',
      'Raw BS': 'Source BS',
      'Raw CF': 'Source CF',
      'Raw Income Statement': 'Source IS',
      'Raw Balance Sheet': 'Source BS',
      'Raw Cash Flow Statement': 'Source CF',
      'Normalized Balance Sheet': 'Norm. BS',
      'Normalized Income Statement': 'Norm. IS',
      'Normalized Cash Flow Statement': 'Norm. CF',
    };

    if (nameTranslate.hasOwnProperty(name)) {
      return nameTranslate[name];
    }
    return name;
  }

  dataViewRouterLink(viewId) {
    return ['/companies', this.company.uuid, 'financials', 'analysis', viewId];
  }

  /**
   * Load the data we need for this view:
   *
   * 1) The specified dataview-v2. The first load will implicitly
   *    use the most recent reporting period
   * 2) All available reporting periods for this borrower, so we
   *    can change the root period to recalculate the data in the
   *    table.
   */
  loadData(loadPeriods: boolean = true, loadCompanyData: boolean = false): void {
    if (!this.company) {
      return;
    }

    this.loading = true;
    this.didErrorOccur = false;

    if (this.dataViewId) {
      this.getDataView();
    }

    if (loadPeriods) {
      this.subsArr$.push(forkJoin(
        this.dataViewService.listDataViews(this.company.id, this.company.defaultSpreadingTemplate, true),
        this._dataFrameService.getPeriodsWithData(this.company.id)
      ).subscribe(results => {
        this.dataViewsList = results[0].filter(dv => this.shouldShowDataView(dv))
        // If there's no DVID specified, use the first non-raw one in this list.
        if (!this.dataViewId) {
          this.dataViewId = this.dataViewsList.find(dv => !dv.raw).id;
          this.getDataView();
        }
        this.periodOptions = results[1];
        this.periodSelectOptions = this.getPeriodSelectionOptions();
      }));
    }

    if (loadCompanyData) {
      this._borrowerService.getCompanyById(this.company.id).subscribe((company) => {
        this.gotCompany = true;
        this.company = company;
        this.shouldShowEmptyState = !!company.defaultSpreadingTemplate;
      })
    }

    if (this.company.defaultSpreadingTemplate) {
      // check to see if the template associated with the borrower has an excel export reference workbook
      this.spreadingTemplateService.getTemplate(this.company.defaultSpreadingTemplate).subscribe(spreadingTemplate => {
        this.spreadingTemplateItems = spreadingTemplate.items;
        this.referenceSheetExistsForCompanyDefaultTemplate = spreadingTemplate.referenceSheetKey
        this.referenceSheetKey = spreadingTemplate.referenceSheetKey
        this.buildActionMenuItems();
      })
    }
  }

  getDataView(): void {
    this.subsArr$.push(forkJoin(
      this.dataViewService.readDataView(this.dataViewId, this.company.id, this.selectedPeriodOption.value, this.sourceItems),
      this.dataViewService.readDataViewConfig(this.dataViewId, this.company.id)
    ).subscribe(result => {
        this.activeDataView = result[0];
        this.buildActionMenuItems();

        if (this.activeDataView.rows.length > 0 && this.activeDataView.columns.length > 0) {
          this.userGuideService.add(USER_GUIDES.VIEWING_DATA);
          this.userGuideService.add(USER_GUIDES.REMOVING_DATA);
          if (this.activeDataView.columns.length >= 2) { // if 2+ statements
            this.userGuideService.add(USER_GUIDES.BUILD_STATEMENTS_FROM_EXISTING_DATA);
          }
        }

        this.bankSettingsService.getAllBankSettings().subscribe(settings => {
          this.projectionScenarioTypeLabelOverride = settings.projectionScenarioTypeLabelOverride || this.projectionScenarioTypeLabelOverride;
          this.commonSizeDecimalPrecision = settings.commonSizeDecimalPrecision;
        });

        if (this.retries > 25) {
          this.showCallToAction = true;
          this.showCalculating = false;
          this.didErrorOccur = false;
          this.stopPolling();
          const user = this.userService.user;
          this.logger.info(
            'Coffee cup error.',
            {
              'userTenantName': user.tenant_name,
              'userId': user.id,
              'url': this.router.url
            }
          );
        } else {
          if (this.activeDataView.calculating) {
            this.showCalculating = true;
            this.poll();
            this.retries++;
          } else {
            this.stopPolling();
            this.finishedCalculating = true;
            this.handleDataViewConfigResponse(result[1][0]);
            this.didErrorOccur = false;
            this.loading = false;
          }
        }

      }, error => {
        this.loading = false;
        this.didErrorOccur = true;
      }
    ));
  }

  poll(): void {
    this.timeout = setTimeout(() => {
      this.getDataView()
    }, 1000);
  }

  stopPolling() {
    clearTimeout(this.timeout)
  }

  hideCalculatingLoader() {
    this.showCalculating = false;
  }

  handleDataViewConfigResponse(dvc: any): void {
    if (dvc !== undefined) {
      this.roundToThousands = dvc.round_to_thousands;
      this.dollarSign = dvc.dollar_sign;
      this.numbers = dvc.numbers;
      this.commonSize = dvc.common_size;
      this.trend = dvc.trend;
      this.dataViewConfigId = dvc.id;
      this.hiddenColumns = new Set(dvc.hidden_columns);
      this.financialSummaryFilter = dvc.summary_financials_filter;
      if (!this.financialSummaryFilter) {
        this.financialSummaryFilter = {};
      }
      this.reportingIntervalFilter = this.financialSummaryFilter['reporting_interval_filter'];
      this.yearsFilter = this.financialSummaryFilter["years_filter"]
      this.scenarioFilter = this.financialSummaryFilter["scenario_filter"]
      this.mapHiddenLineItemIdsToRowIds(new Set(dvc.hidden_rows));
    }
    this.handleNumbersAndPercent();
    this.showHiddenDisplay();
  }

  mapHiddenLineItemIdsToRowIds(lineItemIds: Set<number>): void {
    this.hiddenRows.clear();
    if (lineItemIds) {
      this.activeDataView.rows.filter((r, idx) => {
        if (lineItemIds.has(r.lineItem.id)) {
          if (!this.hiddenRows.has(idx)) {
            this.hiddenRows.add(idx);
          }
        }
      });
    }
  }

  mapHiddenRowIdsToLineItemIds(): Array<number> {
    const lineItemIds: Array<number> = [];
    this.hiddenRows.forEach((r, idx) => {
      lineItemIds.push(this.activeDataView.rows[idx].lineItem.id);
    });

    return lineItemIds;
  }

  /**
   * Get a list of all the document files for the company so we can show them in the table
   */
  loadFiles() {
    this.subsArr$.push(this._documentFileService.listDocumentFiles(this.company.id, null, false, null, true).subscribe(files => {
      this.documentMenuItems = files.map(file => {
        return new ContextMenuDocument(file.originalDocumentName, file.id, file.associatedStatements);
      });
    }));
  }

  toggleRoundToThousands(): void {
    this.roundToThousands = !this.roundToThousands;
    this.updateView();
  }

  toggleDollarSign(): void {
    this.dollarSign = !this.dollarSign;
    this.updateView();
  }

  toggleNumbers(): void {
    this.numbers = !this.numbers;
    this.handleNumbersAndPercent();
    this.updateView();
  }

  toggleCommonSize(): void {
    if (!this.commonSize && this.trend) {
      this.trend = false;
    }
    this.commonSize = !this.commonSize;
    this.handleNumbersAndPercent();
    this.updateView();
  }

  toggleTrend(): void {
    if (this.commonSize && !this.trend) {
      this.commonSize = false;
    }
    this.trend = !this.trend;
    this.handleNumbersAndPercent();
    this.updateView();
  }

  toggleSourceItems(): void {
    this.sourceItems = !this.sourceItems;
    this.handleNumbersAndPercent();
    this.loadData(false, false);
  }

  removeShowAll(): void {
    this.shouldResetEverything = true;
    this.hiddenRows.clear();
    this.hiddenColumns.clear();
    this.showHidden = false;
    this.showHiddenDisplay();
    this.hideRowColUndoStack.clear();
    this.updateView();
  }

  toggleHidden(): void {
    this.showHidden = !this.showHidden;
  }


  handleNumbersAndPercent(): void {
    // 1. Either one of numbers, commonsize or trend must be selected.
    // 2. CommonSize and trend are mutually exclusive
    // either one can be selected or both can be deselected,
    // but both cannot be selected at the same time.
    // This code could be made more company, setting each flag explicity for clarity
    if (this.numbers && (!this.commonSize && !this.trend)) {
      // Only numbers is chosen, and common size and trend are unchecked
      this.numbersDisabled = true;
      this.commonSizeDisabled = false;
      this.trendDisabled = false;
      this.roundToThousandsDisabled = false;
      this.dollarSignDisabled = false;
      this.sourceItemsDisabled = false;
    } else if (this.commonSize && (!this.numbers && !this.trend)) {
      // Only common size is chosen
      this.commonSizeDisabled = true;
      this.numbersDisabled = false;
      this.trendDisabled = false;
      this.roundToThousands = false;
      this.roundToThousandsDisabled = true;
      this.dollarSignDisabled = true;
      this.sourceItemsDisabled = true;
    } else if (this.trend && (!this.numbers && !this.commonSize)) {
      // Only trend is chosen
      this.trendDisabled = true;
      this.numbersDisabled = false;
      this.commonSizeDisabled = false;
      this.roundToThousands = false;
      this.roundToThousandsDisabled = true;
      this.dollarSignDisabled = true;
      this.sourceItemsDisabled = true;
    } else {
      // numbers + one of trend or column are chosen
      this.numbersDisabled = false;
      this.commonSizeDisabled = false;
      this.trendDisabled = false;
      this.roundToThousandsDisabled = false;
      this.dollarSignDisabled = false;
      this.sourceItemsDisabled = false;
    }
  }


  updateView(): void {
    // For any edge case reason if dataviewconfig id is a non-zero falsey value
    // we return without updating the backend. This should not effect the user,
    // since we have already updated the browser display.
    // When the page is loaded again the settings and page will be in sync.
    if (!this.dataViewId || (!this.dataViewConfigId && this.dataViewConfigId !== 0)) {
      return;
    }

    this.setFilters()

    const dvc: DataViewConfig = new DataViewConfig(this.dataViewConfigId, this.company.id,
      this.dataViewId, this.numbers,
      this.roundToThousands,
      !this.removeHiddenDisabled, this.trend,
      this.commonSize, this.mapHiddenRowIdsToLineItemIds(),
      Array.from(this.hiddenColumns), this.dollarSign, this.activeDataView.title, this.financialSummaryFilter)

    this.subsArr$.push(this.dataViewService.updateDataViewConfig(this.dataViewConfigId, dvc).subscribe(data => {
    }));
  }

  setFilters() {
    this.financialSummaryFilter = {'reporting_interval_filter': this.reportingIntervalFilter, 'years_filter': this.yearsFilter, 'scenario_filter': this.scenarioFilter}
  }

  getPeriodSelectionOptions(): Array<Select2OptionData> {
    return this.periodOptions.map(po => {
      return {
        'id': po.value,
        'text': po.label,
        'additional': po,
      }
    })
  }

  selectPeriod(evt): void {
    this.selectedPeriodOption = evt.data[0].additional as Period;
    this.loadData(false);
  }

  downloadWithLoading(downloadingMessage: string, errorMessage: string, func: (string) => void) {
    this.loading = true;
    this.didErrorOccur = false;
    this.loadingMessage = downloadingMessage;
    func(errorMessage);
  }

  downloadAsExcel(): void {
    const docName = this.getDownloadDocName('xlsx');
    this.downloadWithLoading('Generating Excel file...', 'Could not download excel', (errMesg) => {
      this.subsArr$.push(this.dataViewService.readDataViewAsSpreadsheet(this.dataViewId, this.company.id,
        this.selectedPeriodOption.value, docName).subscribe(
        () => {
        },
        () => {
          console.error(errMesg);
          this.alertService.error('Unable to download excel file, please try again or contact administrator.')
          this.loading = false;
          this.loadingMessage = '';
        },
        () => {
          this.loading = false;
          this.loadingMessage = '';
        }
      ));
    });
  }

  downloadAsExcelWorkbook(): void {
    const key_partition = this.referenceSheetKey.split('.')
    const suffix = key_partition[key_partition.length-1]
    const docName = this.getDownloadDocName(suffix);
    this.downloadWithLoading('Generating Excel file...', 'Could not download excel', (errMesg) => {
      this.subsArr$.push(this.dataViewService.exportExcelReferenceSheet(this.dataViewId, this.company.id, docName).subscribe(
        (data) => {
          console.log(data)
        },
        () => {
          console.error(errMesg);
          this.alertService.error('Unable to download excel file, please try again or contact administrator.')
          this.loading = false;
          this.loadingMessage = '';
        },
        () => {
          this.loading = false;
          this.loadingMessage = '';
        }
      ));
    });
  }

  onClickPdfDownload() {
    if (this.embeddedMode) {
      this.downloadPdf();
    } else {
      this.openPdfDownloadModal();
    }
  }

  downloadPdf(): void {
    const docName = this.getDownloadDocName('pdf');
    this.downloadWithLoading('Generating PDF file...', 'Could not download pdf', (errMesg) => {
      this.subsArr$.push(this.dataViewService.readDataViewAsPDF(this.dataViewId, this.company.id,
        this.selectedPeriodOption.value, docName).subscribe(
        (data) => {
          console.log(data)
        },
        () => {
          console.error(errMesg);
          this.alertService.error('Unable to download PDF file, please try again or contact administrator.')
          this.loading = false;
          this.loadingMessage = '';
        },
        () => {
          this.loading = false;
          this.loadingMessage = '';
        }
      ));
    });
  }

  openPdfDownloadModal() {
    this._popupService.open({
      componentType: PdfDownloadModalComponent,
      cssClass: 'pdf-download-modal-class',
      inputs: {
        company: this.company
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  getDownloadDocName(suffix = 'xlsx'): string {
    let docName = '';
    if (this.activeDataView) {
      if (this.activeDataView.title) {
        docName = this.activeDataView.title;
      }
      if (this.activeDataView.columns.length) {
        if (this.selectedPeriodOption.value) {
          docName += '-' + this.selectedPeriodOption.value.split('.')[0];
        } else {
          docName += '-' + this.activeDataView.columns[this.activeDataView.columns.length - 1].header;
        }
      }
    }
    docName = (docName || 'statement') + '.' + suffix;
    return docName;
  }

  rowToggled(idx: number): void {
    this.activeDataView.rows[idx].selected = !this.activeDataView.rows[idx].selected;

    this.calculateGraphItems();

    // Show the graph if we have at least one item. Hide the graph if we have no items.
    if (this.graphItems.length > 0) {
      this.closeInsights();
      this.isGraphShown = true;
    } else {
      this.isGraphShown = false;
    }
  }

  toggleRowVisibility(idx: number): void {
    const lastHiddenOrShown = new Array<number>();
    let isHide = false;
    if (this.hiddenRows.has(idx)) {
      this.hiddenRows.delete(idx);
      lastHiddenOrShown.push(idx);
      isHide = false;

      let unHideIdx = idx + 1;
      while (this.activeDataView.rows.length > unHideIdx &&
      this.activeDataView.rows[unHideIdx].indentation > this.activeDataView.rows[idx].indentation) {
        if (this.hiddenRows.has(unHideIdx)) {
          this.hiddenRows.delete(unHideIdx);
          lastHiddenOrShown.push(unHideIdx);
        }
        unHideIdx++;
      }
    } else {
      // Remove row from graph if needed
      if (this.activeDataView.rows[idx].selected) {
        this.rowToggled(idx);
      }
      // Hide row and its children
      if (!this.hiddenRows.has(idx)) {
        this.hiddenRows.add(idx);
        lastHiddenOrShown.push(idx);
        isHide = true;
      }
      let hideIdx = idx + 1;
      while (this.activeDataView.rows.length > hideIdx &&
      this.activeDataView.rows[hideIdx].indentation > this.activeDataView.rows[idx].indentation) {
        if (!this.hiddenRows.has(hideIdx)) {
          this.hiddenRows.add(hideIdx);
          lastHiddenOrShown.push(hideIdx);
        }
        hideIdx++;
      }
    }
    if (lastHiddenOrShown.length > 0) {
      this.hideRowColUndoStack.pushRows(isHide, lastHiddenOrShown);
      this.clearUndoDisabled = false;
    }
    this.showHiddenDisplay();
    this.updateView();
  }

  columnHidden(idx: string): void {
    let lastHiddenOrShown = '';
    let isHide = false;
    if (this.hiddenColumns.has(idx)) {
      this.hiddenColumns.delete(idx);
      lastHiddenOrShown = idx;
      isHide = false;
    } else {
      if (!this.hiddenColumns.has(idx)) {
        this.hiddenColumns.add(idx);
        lastHiddenOrShown = idx;
        isHide = true;
      }
    }
    if (lastHiddenOrShown !== '') {
      this.hideRowColUndoStack.pushColumn(isHide, lastHiddenOrShown);
      this.clearUndoDisabled = false;
    }
    this.showHiddenDisplay();
    this.updateView();
  }

  undoLastHidden(): void {
    if (!this.hideRowColUndoStack.isEmpty()) {
      const op = this.hideRowColUndoStack.pop();
      if (op.isRow) {
        const that = this;
        op.rows().forEach(function (value) {
          if (op.isHide) {
            that.hiddenRows.delete(value);
          } else {
            that.hiddenRows.add(value);
          }
        });
      } else {
        if (op.isHide) {
          this.hiddenColumns.delete(op.column());
        } else {
          this.hiddenColumns.add(op.column());
        }
      }

      this.showHiddenDisplay();
      this.updateView();
    }

    this.clearUndoDisabled = this.hideRowColUndoStack.isEmpty();
  }

  showHiddenDisplay(): void {
    if (this.hiddenRows.size === 0 && this.hiddenColumns.size === 0) {
      this.showHidden = false;
      this.showHiddenDisabled = true;
      this.removeHiddenDisabled = true;
    } else {
      this.showHiddenDisabled = false;
      this.removeHiddenDisabled = false;
    }
  }

  closeGraph(): void {
    this.graphItems = [];
    this.activeDataView.rows.forEach(row => {
      row.selected = false;
    });
    this.isGraphShown = false;
  }

  calculateGraphItems(): void {
    // Get only selected rows, and store index so we can use it later to get the correct cell.
    const rowsToShow = this.activeDataView.rows.map((row, idx) => {
      return {
        row: row,
        idx: idx
      };
    }).filter(row => row.row.selected);

    const columnsToShow = this.activeDataView.columns.filter(col => col.showInGraph);
    this.graphItems = rowsToShow.map(row => {
      const seriesValues = columnsToShow.map(col => {
        return {
          name: col.header,
          value: col.cells[row.idx].calculatedValue ? col.cells[row.idx].calculatedValue.value : 0.0,
        }
      });

      return {
        name: row.row.label,
        series: seriesValues,
        idx: row.idx,
        format: row.row.cellFormat.textFormat
      }
    });

    this.generateGraphLabels();
  }

  generateGraphLabels(): void {
    const formats = [];
    if (this.graphItems && this.graphItems.length > 0) {
      this.graphItemLabels = this.graphItems.map(e => e.name).join(', ');

      for (let i = 0; i < this.graphItems.length; i++) {
        let format = this.graphItems[i].format;
        if (!format) {
          format = 'CURRENCY';
        }
        if (!formats.includes(format)) {
          formats.push(format);
        }
      }

      this.graphYAxisLabel = formats.join(', ')
    }
  }

  openInsightsDetail(itemRef: string, insertIdx: number = -1) {
    const updatedPayload = {...this.insightsPayload};
    updatedPayload.lineItemRef = itemRef;
    this.openInsights(updatedPayload, insertIdx);
  }

  openInsights(payload, insertIdx: number = -1) {
    this.closeGraph();
    this.insightsPayload = payload;
    this.subsArr$.push(this._insightsService.getPeriodOverPeriodAnalysis(payload).pipe(map(data => data.response.objects[0]))
      .subscribe(
        response => {
          this.insights.length = insertIdx + 1;
          this.insights.push(response);
          this.currentInsightLabel = response.item.label
          this.showInsights = true;
        },
        error => {
          if (error.error_type === 'ObjectDoesNotExist') {
            this.alertService.error('Source statement not found. It may have been deleted.')
          } else {
            this.logger.error('Error in retreiving insights: ' + error.message, {'errorObject': error});
            this.alertService.error('Something went wrong when retreiving detailed data. Our team has been notified and is looking into it')
          }
        }
      ),
    );
  }

  beginDeleteColumn(column: DataViewColumn) {
    if (!column.isDeletable()) {
      this.alertService.error('Column can not be deleted');
      return;
    }

    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Are you sure you want to delete this period of data?',
        text: '',
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            if (column.preparationType === 'COMBINED') {
              this._dataFrameService.deleteCombinedFrame(column.combinedFrameId).subscribe(() => {
                this.alertService.success('Statement Deleted');
                this.loadData(true, true)
              }, (err) => {
                this.alertService.error('Failed to delete period');
              })
            } else if (column.preparationType === 'FORECASTED') {
              this._dataFrameService.deleteForecastedFrame(column.forecastedFrameId).subscribe(() => {
                this.alertService.success('Statement Deleted');
                this.loadData(true, true)
              }, (err) => {
                this.alertService.error('Failed to delete period');
              })
            } else {
              this._dataFrameService.deleteImportedFrame(column.importedFrameId).subscribe(() => {
                this.alertService.success('Statement Deleted');
                this.loadData(true, true)
              }, (err) => {
                this.alertService.error('Failed to delete period');
              })
            }
          }
        }
      },
    });
  }

  beginEditColumn(column: DataViewColumn) {
    if (column.forecastedFrameId) {
      this.statementBuilderEditMode = StatementBuilderMode.GenerateForecast;
      this.frameToBeEditedId = column.forecastedFrameId;
    } else if (column.combinedFrameId) {
      if (column.combinedColumnStatementBuilderOriginType === "CONSOLIDATED") {
        this.statementBuilderEditMode = StatementBuilderMode.Consolidate;
        this.frameToBeEditedId = column.combinedFrameId;
      } else if (column.combinedColumnStatementBuilderOriginType === "CALCULATED") {
        this.statementBuilderEditMode = StatementBuilderMode.CalcMissingPeriod;
        this.frameToBeEditedId = column.combinedFrameId;
      }
    }
    this.isStatementBuilderFlyoutOpen = true;
  }

  closeInsightsDetail(evt, closeIdx) {
    this.insights.length = closeIdx + 1;
  }

  closeInsights() {
    this.showInsights = false;
    this.insights = [];
    this.currentInsightLabel = '';
  }

  parseEntitlementData() {
    this.hasEditSpreadPermission = this.embeddedMode ? true : this.routeData.companyEntitlement[COMPANY_TABS.BORROWER_NAV][COMPANY_ENTITLEMENT_DATA.SHOW_BUILD_NEW_STATEMENT_BUTTON];
    this.pageViewMode = this.embeddedMode ? COMPANY_PAGE_VIEW_MODE.FULL : this.routeData.companyEntitlement[COMPANY_TABS.FINANCIALS][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];
    this.canViewFinancials = [COMPANY_PAGE_VIEW_MODE.VIEW_ONLY, COMPANY_PAGE_VIEW_MODE.FULL].includes(this.pageViewMode);
    this.showEmptyStateUploadButton = this.embeddedMode ? false : this.routeData.companyEntitlement[COMPANY_TABS.BORROWER_NAV][COMPANY_ENTITLEMENT_DATA.SHOW_REQUEST_STATEMENTS_BUTTON]
  }


  openSpreadChangeHistory() {
    this._popupService.open({
      componentType: ChangeLogModalComponent,
      cssClass: 'change-log-modal-class',
      inputs: {
        companyId: this.company.id,
        analysisId: null,
        callTypeCategory: CallTypeCategory.Spread,
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

    openUploadDocumentModal() {
    this.popupService.open({
      componentType: UploadDocumentModalComponent,
      cssClass: 'doc-upload-modal',
      inputs: {
        company: this.company
      }
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        if (typeof(data.detail) === 'object' && data.detail?.reOpenModal){
          this.openUploadDocumentModal();
        }
      }, {once: true});
    });
  }

  openFilterStatementFlyout() {
    this._popupService.open({
        componentType: FilterStatementsComponent,
        cssClass: 'filter-statements',
        inputs: {
          columns: this.activeDataView.columns,
          hiddenColumnIndices: this.hiddenColumns,
          showHiddenDisabled: this.showHiddenDisabled,
          removeHiddenDisabled: this.removeHiddenDisabled,
          clearUndoDisabled: this.clearUndoDisabled,
          dataViewService: this.dataViewService,
          multiSelectOptionsForReportingInterval: this.reportingIntervalFilter,
          multiSelectOptionsForYears: this.yearsFilter,
          multiSelectOptionsForScenario: this.scenarioFilter,
          hideRowColUndoStack: this.hideRowColUndoStack,
          shouldResetEverything: this.shouldResetEverything
        },
        outputs: {
          callback: (data) => {
            if (data.hiddenColumnsPassedIn) {
              this.hiddenColumns = data.hiddenColumnsPassedIn
            }
            this.yearsFilter = data.yearsFilter
            this.scenarioFilter = data.scenarioFilter
            this.reportingIntervalFilter = data.reportingIntervalFilters
            this.clearUndoDisabled = data.clearUndoDisabled
            this.showHiddenDisabled = data.showHiddenDisabled
            this.removeHiddenDisabled = data.removeHiddenDisabled
            this.hideRowColUndoStack = data.hideRowColUndoStack
            this.updateView()
          }
        }
      }
    )
    this.shouldResetEverything = false
  }
}
