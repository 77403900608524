import * as _ from 'lodash';

export function buildAndValidateUpdatePayload(initialPageData: TemplatePageData, submissionData: TemplatePageData) {
  const metaDataHadChanged = !_.isEqual(initialPageData?.metaData, submissionData.metaData);
  const templateDataHasChanged = !_.isEqual(initialPageData?.templateData, submissionData.templateData);
  let payload = {};
  if (metaDataHadChanged) {
    payload = {...submissionData.metaData}
  }
  if (templateDataHasChanged) {
    payload = {...payload, ...submissionData.templateData}
  }
  return {templateDataHasChanged, payload}

}

export interface TemplatePageData {
  metaData: {
    name: string,
    isDefault: boolean,
  },
  templateData: {
    items: any,
    additionalAttributes?: any
    commonSizeDenominatorOverrides?: any
  }
}
