import {
  ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnDestroy,
  Output, Renderer2, SimpleChanges, ViewContainerRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

import { IMyCalendarViewChanged, IMyDate, IMyDateModel, IMyInputFieldChanged, IMyOptions, IMySelectorPosition } from './interfaces';
import { NgxMyDatePickerComponent } from './ngx-my-date-picker.component';
import { UtilService } from './services/ngx-my-date-picker.util.service';
import { NgxMyDatePickerConfig } from './services/ngx-my-date-picker.config';
import { CalToggle, KeyCode, Year } from './enums';

const NGX_DP_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgxMyDatePickerDirective),
  multi: true,
};

const NGX_DP_VALIDATORS = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NgxMyDatePickerDirective),
  multi: true,
};

@Directive({
  selector: '[appNgxMyDatePicker]',
  exportAs: 'appNgxMyDatePicker',
  providers: [UtilService, NGX_DP_VALUE_ACCESSOR, NGX_DP_VALIDATORS],
})
export class NgxMyDatePickerDirective implements OnDestroy, OnChanges, ControlValueAccessor, Validator {
  @Input() options: IMyOptions;
  @Input() defaultMonth: string;

  @Output() dateChanged: EventEmitter<IMyDateModel> = new EventEmitter<IMyDateModel>();
  @Output() inputFieldChanged: EventEmitter<IMyInputFieldChanged> = new EventEmitter<IMyInputFieldChanged>();
  @Output() calendarViewChanged: EventEmitter<IMyCalendarViewChanged> = new EventEmitter<IMyCalendarViewChanged>();
  @Output() calendarToggle: EventEmitter<number> = new EventEmitter<number>();

  private cRef: ComponentRef<NgxMyDatePickerComponent> = null;
  private inputText = '';
  private preventClose = false;
  private disabled = false;
  private opts: IMyOptions;

  constructor(private _utilService: UtilService,
              private _vcRef: ViewContainerRef,
              private _cfr: ComponentFactoryResolver,
              private _renderer: Renderer2,
              private _cdr: ChangeDetectorRef,
              private _elem: ElementRef,
              config: NgxMyDatePickerConfig,
  ) {
    this.opts = Object.assign({}, config);
    this.parseOptions(config);
  }

  @HostListener('keyup', ['$event']) onKeyUp(evt: KeyboardEvent) {
    if (this.ignoreKeyPress(evt.keyCode)) {
      return;
    } else if (evt.keyCode === KeyCode.esc) {
      this.closeSelector(CalToggle.CloseByEsc);
    } else {
      const date: IMyDate = this._utilService.isDateValid(this._elem.nativeElement.value, this.opts.dateFormat,
        this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince,
        this.opts.disableWeekends, this.opts.disableDates, this.opts.disableDateRanges, this.opts.monthLabels,
        this.opts.enableDates);
      if (this._utilService.isInitializedDate(date)) {
        const dateModel: IMyDateModel = this._utilService.getDateModel(date, this.opts.dateFormat,
          this.opts.monthLabels, this._elem.nativeElement.value);
        this.emitDateChanged(dateModel);
        this.updateModel(dateModel);
        this.emitInputFieldChanged(dateModel.formatted, true);
        if (this.opts.closeSelectorOnDateSelect) {
          this.closeSelector(CalToggle.CloseByDateSel);
        } else if (this.cRef !== null) {
          this.cRef.instance.setCalendarView(date);
        }
      } else {
        if (this.inputText !== this._elem.nativeElement.value) {
          if (this._elem.nativeElement.value === '') {
            this.clearDate();
          } else {
            this.onChangeCb(null);
            this.emitInputFieldChanged(this._elem.nativeElement.value, false);
          }
        }
      }
      this.inputText = this._elem.nativeElement.value;
    }
  }

  @HostListener('blur') onBlur() {
    this.onTouchedCb();
  }

  @HostListener('document:click', ['$event']) onClick(evt: MouseEvent) {
    if (this.opts.closeSelectorOnDocumentClick && !this.preventClose && evt.target && this.cRef !== null &&
      this._elem.nativeElement !== evt.target && !this.cRef.location.nativeElement.contains(evt.target)
      && !this.disabled) {
      this.closeSelector(CalToggle.CloseByOutClick);
    }
  }

  onChangeCb: (_: any) => void = () => {
  };
  onTouchedCb: () => void = () => {
  };

  public ngOnDestroy(): void {
    this.closeSelector(CalToggle.CloseByOutClick);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('options')) {
      this.parseOptions(changes['options'].currentValue);
    }

    if (changes.hasOwnProperty('defaultMonth')) {
      let dm: any = changes['defaultMonth'].currentValue;
      if (typeof dm === 'object') {
        dm = dm.defMonth;
      }
      this.defaultMonth = dm;
    }
  }

  public parseOptions(opts: IMyOptions): void {
    if (opts !== undefined) {
      Object.keys(opts).forEach((k) => {
        (<IMyOptions>this.opts)[k] = opts[k];
      });
    }
    if (this.opts.minYear < Year.min) {
      this.opts.minYear = Year.min;
    }
    if (this.opts.maxYear > Year.max) {
      this.opts.maxYear = Year.max;
    }
  }

  public writeValue(value: any): void {
    if (!this.disabled) {
      if (value && (value['date'] || value['jsdate'])) {
        const formatted: string = this._utilService.formatDate(value['date'] ? value['date'] :
          this.jsDateToMyDate(value['jsdate']), this.opts.dateFormat, this.opts.monthLabels);
        const date: IMyDate = this._utilService.isDateValid(formatted, this.opts.dateFormat, this.opts.minYear,
          this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince, this.opts.disableWeekends,
          this.opts.disableDates, this.opts.disableDateRanges, this.opts.monthLabels, this.opts.enableDates);
        if (!this._utilService.isInitializedDate(date)) {
          throw new Error(`The value you want to set is invalid: ${JSON.stringify(value)}`);
        } else {
          this.setInputValue(formatted);
          this.emitInputFieldChanged(formatted, this._utilService.isInitializedDate(date));
        }

      } else if (value === null || value === '') {
        this.setInputValue('');
        this.emitInputFieldChanged('', false);
      }
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._renderer.setProperty(this._elem.nativeElement, 'disabled', isDisabled);

    if (isDisabled) {
      this.closeCalendar();
    }
  }

  public validate(c: AbstractControl): { [p: string]: any } {
    if (this._elem.nativeElement.value === null || this._elem.nativeElement.value === '') {
      return null;
    }
    const date: IMyDate = this._utilService.isDateValid(this._elem.nativeElement.value, this.opts.dateFormat,
      this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince,
      this.opts.disableWeekends, this.opts.disableDates, this.opts.disableDateRanges, this.opts.monthLabels,
      this.opts.enableDates);
    if (!this._utilService.isInitializedDate(date)) {
      return {invalidDateFormat: true};
    }
    return null;
  }

  public openCalendar(): void {
    if (this.disabled) {
      return;
    }
    this.preventClose = true;
    this._cdr.detectChanges();
    if (this.cRef === null) {
      this.cRef = this._vcRef.createComponent(this._cfr.resolveComponentFactory(NgxMyDatePickerComponent));
      this.appendSelector(this.cRef.location.nativeElement);
      this.cRef.instance.initialize(
        this.opts,
        this.defaultMonth,
        this.getSelectorPosition(this._elem.nativeElement),
        this._elem.nativeElement.value,
        (dm: IMyDateModel, close: boolean) => {
          this.focusToInput();
          this.emitDateChanged(dm);
          this.emitInputFieldChanged(dm.formatted, true);
          this.updateModel(dm);
          if (close) {
            this.closeSelector(CalToggle.CloseByDateSel);
          }
        },
        (cvc: IMyCalendarViewChanged) => {
          this.emitCalendarChanged(cvc);
        },
        () => {
          this.closeSelector(CalToggle.CloseByEsc);
        },
      );
      this.emitCalendarToggle(CalToggle.Open);
    }
    setTimeout(() => {
      this.preventClose = false;
    }, 50);
  }

  public closeCalendar(): void {
    this.closeSelector(CalToggle.CloseByCalBtn);
  }

  public toggleCalendar(): void {
    if (this.disabled) {
      return;
    }
    if (this.cRef === null) {
      this.openCalendar();
    } else {
      this.closeSelector(CalToggle.CloseByCalBtn);
    }
  }

  public clearDate(): void {
    if (this.disabled) {
      return;
    }
    this.emitDateChanged({date: {year: 0, month: 0, day: 0}, jsdate: null, formatted: '', epoc: 0});
    this.emitInputFieldChanged('', false);
    this.onChangeCb(null);
    this.onTouchedCb();
    this.setInputValue('');
    this.closeSelector(CalToggle.CloseByCalBtn);
  }

  public isDateValid(): boolean {
    if (this._elem.nativeElement.value !== '') {
      const date: IMyDate = this._utilService.isDateValid(this._elem.nativeElement.value, this.opts.dateFormat,
        this.opts.minYear, this.opts.maxYear, this.opts.disableUntil, this.opts.disableSince,
        this.opts.disableWeekends, this.opts.disableDates, this.opts.disableDateRanges, this.opts.monthLabels,
        this.opts.enableDates);
      if (this._utilService.isInitializedDate(date)) {
        this.emitInputFieldChanged(this._elem.nativeElement.value, true);
        return true;
      }
    }
    this.emitInputFieldChanged(this._elem.nativeElement.value, false);
    return false;
  }

  private ignoreKeyPress(keyCode: number): boolean {
    return keyCode === KeyCode.leftArrow || keyCode === KeyCode.rightArrow || keyCode === KeyCode.upArrow ||
      keyCode === KeyCode.downArrow || keyCode === KeyCode.tab || keyCode === KeyCode.shift;
  }

  private closeSelector(reason: number): void {
    if (this.cRef !== null) {
      this._vcRef.remove(this._vcRef.indexOf(this.cRef.hostView));
      this.cRef = null;
      this.emitCalendarToggle(reason);
    }
  }

  private updateModel(model: IMyDateModel): void {
    this.onChangeCb(model);
    this.onTouchedCb();
    this.setInputValue(model.formatted);
  }

  private setInputValue(value: string): void {
    this.inputText = value;
    this._renderer.setProperty(this._elem.nativeElement, 'value', value);
  }

  private focusToInput(): void {
    setTimeout(() => {
      this._elem.nativeElement.focus();
    });
  }

  private emitDateChanged(dateModel: IMyDateModel): void {
    this.dateChanged.emit(dateModel);
  }

  private emitInputFieldChanged(value: string, valid: boolean): void {
    this.inputFieldChanged.emit({value: value, dateFormat: this.opts.dateFormat, valid: valid});
  }

  private emitCalendarChanged(cvc: IMyCalendarViewChanged) {
    this.calendarViewChanged.emit(cvc);
  }

  private emitCalendarToggle(reason: number): void {
    this.calendarToggle.emit(reason);
  }

  private jsDateToMyDate(date: Date): IMyDate {
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  private appendSelector(elem: any): void {
    if (this.opts.appendSelectorToBody) {
      document.querySelector('body').appendChild(elem);
    }
  }

  private getSelectorPosition(elem: any): IMySelectorPosition {
    let top: any = 0;
    let left: any = 0;

    if (this.opts.appendSelectorToBody) {
      const b: any = document.body.getBoundingClientRect();
      const e: any = elem.getBoundingClientRect();
      top = e.top - b.top;
      left = e.left - b.left;
    }

    if (this.opts.openSelectorTopOfInput) {
      top = top - this.getSelectorDimension(this.opts.selectorHeight) - 2;
    } else {
      top = top + elem.offsetHeight + (this.opts.showSelectorArrow ? 12 : 2);
    }

    if (this.opts.alignSelectorRight) {
      left = left + elem.offsetWidth - this.getSelectorDimension(this.opts.selectorWidth);
    }

    // if (this.opts.alignSelectorRight0) {
    //   return {top: 'inherit', right: '0px'};
    // }

    top += 'px';
    left += 'px';

    if (this.opts.alignSelectorInherit) {
      left = 'inherit';
      top = 'inherit';
    }

    return {top: top, left: left};
  }

  private getSelectorDimension(value: string): number {
    return Number(value.replace('px', ''));
  }
}
