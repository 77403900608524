<div class="modal__content">
    <div class="confirmation-popup-header">
        <i class="fas fa-sitemap"></i>
        <span> {{title}}</span>
    </div>
    <div class="confirmation-popup-text">
        <span>{{description}}</span><br><br>
        <span class="input-label">{{inputBoxLabel}}</span><br>
        <input type="text" class="name-input-box" id="section-name-input" placeholder="Name" autocomplete="false" [(ngModel)]="sectionName">
    </div>

</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="popup.close();">{{cancelButtonText}}</button>
    <button type="button" class="btn btn-primary"
     (click)="createSectionItem()" [disabled]="!sectionName || submitted">{{confirmButtonText}}</button>
</div>
