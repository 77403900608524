import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { Box } from '../../models/box';

export interface CurrentAction {
  box: Box;
  moveType: string;
  anchor: string;
  boxAtActionStart: Box;
  clientPosition: Point;
  isNewBox: boolean;
}

interface Point {
  x: number;
  y: number;
}

const minWidth = 25;
const minHeight = 25;
const defaultHeightWidthRatio = 1.3

/**
 * This dumb component allows the user to draw boxes on an image
 * and keep track of proportions
 */
@Component({
  selector: 'app-box-drawer',
  templateUrl: './box-drawer.component.html',
  styleUrls: ['./box-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxDrawerComponent implements OnInit, OnChanges {
  @Input() boxes: Array<Box> = [];
  @Input() sourceImageUrl: string;

  /**
   * Hacky way of accounting for a parent container that scrolls, so we can properly figure
   * out the actual offset.
   */
  @Input() scrollingContainerElementId: string;

  // Whether boxes can be added/changed/removed (true) or just statically displayed (false)
  @Input() allowEdit = true;

  /**
   * This defines the width of the image as we want to render it, which is how we figure out the proportions of the
   * drawn box to the original image size
   */
  @Input() inputImageWidth: number = null;

  /**
   * This defines the height of the image as we want to render it, which is how we figure out the proportions of the
   * drawn box to the original image sizes
   * We are generally expecting only one hsere.
   * We will use height over width when we get both
   */
  @Input() inputImageHeight: number = null;

  @Input() onlyAllowOneBox = false;

  @Input() showActiveBorder?: boolean = false;

  @Output() imageOrientation = new EventEmitter<string>();

  imageWidth: number;
  imageHeight: number;

  /**
   * We pass in the show sidebar variable in places where boxdrawing is on a page with the sidebar.
   * This allows us to trigger change detection and recalculate the x offset.
   */
  @Input() sidebar;

  /**
   * Any time the boxes change (added, removed, resized) an event will be emitted with all boxes
   */
  @Output() boxesChanged = new EventEmitter<Array<Box>>();

  /**
   * When allowEdit is FALSE, this will emit whenever a box is clicked on
   * (will emit the index of the clicked-on box)
   */
  @Output() boxClicked = new EventEmitter<number>();

  @ViewChild('canvas') canvas: ElementRef;

  @Input() boxColorOverride?: string

  selectedWidth = 500;

  loadedImage = false;

  // We need to keep track of where this component is on the page
  // because the coordinates we get back are all absolute
  pageOffsetX: number;
  pageOffsetY: number;

  currentAction: CurrentAction;

  actualImageWidth: number;
  actualImageHeight: number;

  defaultHeightWidthRatio = defaultHeightWidthRatio;
  imageFailedToLoad: boolean = false;

  constructor(
    private _crd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.updateHeightAndWidth();
    if (this.sourceImageUrl) {
      this.loadImage();
    }

    if (this.onlyAllowOneBox && this.boxes?.length >= 1) {
      this.boxes = [this.boxes[0]]; // assign boxes array to 1 value of first box
    }
  }

  updateHeightAndWidth(): void {
    this.imageHeight = this.inputImageHeight;
    this.imageWidth = this.inputImageWidth;
  }

  changeSize() {
    this.imageWidth = this.selectedWidth;
    this.determineOtherHeightOrWidth();
    this.resizeBoxes();
  }

  resizeBoxes(): void {
    this.boxes.forEach(b => b.resize(this.imageWidth, this.imageHeight));
    this._crd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('sourceImageUrl') || changes.hasOwnProperty('sidebar')) {
      this.loadImage();
    }

    if (changes.hasOwnProperty('inputImageHeight') || changes.hasOwnProperty('inputImageWidth') ) {
        this.updateHeightAndWidth();
        if (this.sourceImageUrl) {
          this.loadImage();
        }
    }
  }

  canvasStyle() {
    return {
      'background-image': `url('${this.sourceImageUrl}')`,
      'width': `${this.imageWidth}px`,
      'height': `${this.imageHeight}px`
    }
  }

  trackMovement(evt) {
    if (!!this.currentAction) {
      switch (this.currentAction.moveType) {
        case 'resize':
          this.handleResize(evt);
          break;
        case 'move':
          this.handleMove(evt);
          break;
      }
    }
  }

  removeBox(idx: number) {
    this.boxes.splice(idx, 1);
    this._crd.detectChanges();
  }

  loadImage() {
    const image = new Image();
    this.imageFailedToLoad = false;
    image.onload = () => {
      this.actualImageHeight = image.height;
      this.actualImageWidth = image.width;

      this.determineOrientation();

      this.determineOtherHeightOrWidth();
      this.resizeBoxes();
      this.loadedImage = true;
      this._crd.detectChanges();
      setTimeout(() => {
        this.determineOffset();
      });
    }
    image.onerror = (e) => {
      console.log('Failed to load image: ', e)
      this.imageFailedToLoad = true;
      this._crd.detectChanges();
    }
    image.src = this.sourceImageUrl;
  }

  determineOtherHeightOrWidth(): void {
    if (this.inputImageHeight !== null) {
      const proportion = this.imageHeight / this.actualImageHeight;
      this.imageWidth = this.actualImageWidth * proportion;
    } else {
      const proportion = this.imageWidth / this.actualImageWidth;
      this.imageHeight = this.actualImageHeight * proportion;
    }
  }

  boxStyle(box: Box, resize = true) {
    if (resize) {
      box.resize(this.imageWidth, this.imageHeight);
    }

    if (this.boxColorOverride) {
      return {
        'background-color': this.boxColorOverride,
        'width': `${box.width() + 8}px`,
        'height': `${box.height() + 8}px`,
        'top': `${box.y1 - 5}px`,
        'left': `${box.x1 - 5}px`
      }
    }
    return {
      'width': `${box.width()}px`,
      'height': `${box.height()}px`,
      'top': `${box.y1}px`,
      'left': `${box.x1}px`
    };
  }

  determineOffset() {
    if (this.canvas) {
      const {offsetY, offsetX} = this.getScrollTop();
      const rect = this.canvas.nativeElement.getBoundingClientRect();
      this.pageOffsetX = rect.x + offsetX;
      this.pageOffsetY = rect.y + offsetY; // scrollPixels needs to be here
      // since when you change images, you need offset to account for current scroll position
    }
  }

  /**
   * Need to do it this way because JS executes events in this order:
   * 1. mousedown
   * 2. mouseup
   * 3. click
   */
  checkForClick(evt, idx: number) {
    if (evt.target.className.indexOf('remove-box') >= 0) {
      this.removeBox(idx);
    } else if (!this.allowEdit) {
      this.boxClicked.emit(idx);
    }
  }

  startAction(evt, box: Box, moveType: string, anchor: string = '') {
    if (!this.allowEdit) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    const currentPosition = this.getClientPosition(evt);

    switch (moveType) {
      case 'addNew':
      // Add a new box at the current point with 0x0 dimensions, then immediately switch to "resize from top-left anchor" mode
        const newBox = new Box().deserialize({
          'x1': currentPosition.x,
          'y1': currentPosition.y,
          'x2': currentPosition.x + minWidth,
          'y2': currentPosition.y + minHeight,
          'pageHeight': this.imageHeight,
          'pageWidth': this.imageWidth,
        });

        newBox.active = true;
        this.boxes.push(newBox);
        this.currentAction = {
          isNewBox: true,
          box: newBox,
          moveType: 'resize',
          anchor: 'nw',
          boxAtActionStart: newBox.copy(),
          clientPosition: currentPosition,
        };
        this._crd.detectChanges();
        break;
      case 'resize':
      case 'move':
        box.active = true;
        this.currentAction = {
          isNewBox: false,
          boxAtActionStart: box.copy(),
          box: box,
          moveType: moveType,
          anchor: anchor,
          clientPosition: currentPosition,
        };
        break;
    }
  }

  stopAction(evt): void {
    if (!this.allowEdit || !this.currentAction) {
      return;
    }

    if (this.currentAction.isNewBox) {
      // If we didn't move enough in either direction then consider it a "click" and don't do anything
      const currentPosition = this.getClientPosition(evt);
      const xdiff = Math.abs(currentPosition.x - this.currentAction.clientPosition.x);
      const ydiff = Math.abs(currentPosition.y - this.currentAction.clientPosition.y);
      if (xdiff < minWidth && ydiff < minHeight) {
        this.removeBox(this.boxes.length - 1);
        return;
      } else if (this.onlyAllowOneBox && this.boxes.length >= 2) {
        // clear existing box and replace with new one if onlyAllowOneBox enabled
        while (this.boxes.length > 1) {
          this.removeBox(0);
        }
      }
    }
    this.currentAction.box.active = false;
    this.currentAction = null;
    this.boxesChanged.emit(this.boxes);
  }

  boxMoved(evt) {
    if (this.currentAction) {
      evt.stopPropagation();
      evt.preventDefault();
      if (this.currentAction.moveType === 'move') {
        this.handleMove(evt);
      }
    }
  }

  ensureWithinLimits(currentAction: CurrentAction) {
    if (currentAction.box.x1 < 0 ) {
      currentAction.box.x1 = 0;
    }

    if (currentAction.box.y1 < 0) {
      currentAction.box.y1 = 0;
    }

    if (currentAction.box.x2 > currentAction.box.pageWidth) {
      currentAction.box.x2 = currentAction.box.pageWidth;
    }

    if (currentAction.box.y2 > currentAction.box.pageHeight) {
      currentAction.box.y2 = currentAction.box.pageHeight;
    }

    return currentAction;
  }

  handleMove(evt) {
    const currentPosition = this.getClientPosition(evt);
    const diffx = currentPosition.x - this.currentAction.clientPosition.x;
    const diffy = currentPosition.y - this.currentAction.clientPosition.y;
    this.currentAction.box.x1 = this.currentAction.boxAtActionStart.x1 + diffx;
    this.currentAction.box.x2 = this.currentAction.boxAtActionStart.x2 + diffx;
    this.currentAction.box.y1 = this.currentAction.boxAtActionStart.y1 + diffy;
    this.currentAction.box.y2 = this.currentAction.boxAtActionStart.y2 + diffy;

    this.currentAction = this.ensureWithinLimits(this.currentAction);
    this._crd.detectChanges();
  }

  handleResize(evt) {
    const currentPosition = this.getClientPosition(evt);
    const boxAtActionStart = this.currentAction.boxAtActionStart;

    const diffx = currentPosition.x - this.currentAction.clientPosition.x;
    const diffy = currentPosition.y - this.currentAction.clientPosition.y;

    switch (this.currentAction.anchor) {
      case 'nw':
        let positionx = boxAtActionStart.x2 + diffx;
        let positiony = boxAtActionStart.y2 + diffy;
        if (this.currentAction.isNewBox) {
          // Use the exact mouse position instead since that feels more natural
          positionx = currentPosition.x;
          positiony = currentPosition.y;
        }
        this.currentAction.box.x2 = Math.max(positionx, boxAtActionStart.x1 + minWidth);
        this.currentAction.box.y2 = Math.max(positiony, boxAtActionStart.y1 + minHeight);
        break;
      case 'se':
        this.currentAction.box.x1 = Math.min(boxAtActionStart.x1 + diffx, boxAtActionStart.x2 - minWidth);
        this.currentAction.box.y1 = Math.min(boxAtActionStart.y1 + diffy, boxAtActionStart.y2 - minHeight);
        break;
      case 'ne':
        this.currentAction.box.x1 = Math.min(boxAtActionStart.x1 + diffx, boxAtActionStart.x2 - minWidth);
        this.currentAction.box.y2 = Math.max(boxAtActionStart.y2 + diffy, boxAtActionStart.y1 + minHeight);
        break;
      case 'sw':
        this.currentAction.box.x2 = Math.max(boxAtActionStart.x2 + diffx, boxAtActionStart.x1 + minWidth);
        this.currentAction.box.y1 = Math.min(boxAtActionStart.y1 + diffy, boxAtActionStart.y2 - minHeight);
        break;
    }

    this.currentAction = this.ensureWithinLimits(this.currentAction);
    this._crd.detectChanges();
  }

  determineOrientation(): void {
    if (this.actualImageHeight > this.actualImageWidth) {
      this.imageOrientation.emit('Portrait');
    } else {
      this.imageOrientation.emit('Landscape');
    }
  }

  getClientPosition(evt): Point {
    return {
      'x': this.normalizeWithOffset(evt.clientX as number, 'x'),
      'y': this.normalizeWithOffset(evt.clientY as number, 'y'),
    };
  }

  private getScrollTop(): { offsetY: number, offsetX: number } {
    let offsetY = 0;
    let offsetX = 0;
    if (this.scrollingContainerElementId) {
      const element = document.getElementById(this.scrollingContainerElementId);
      if (element) {
        offsetY = element.scrollTop
        offsetX = element.scrollLeft
      }
    }
    return {offsetY, offsetX};
  }

  private normalizeWithOffset(value: number, axis = 'x'): number {
    const {offsetY, offsetX} = this.getScrollTop();
    switch (axis) {
      case 'x':
        return value - this.pageOffsetX + offsetX;
      case 'y':
        return value - this.pageOffsetY + offsetY; // take scroll position into account
    }
  }
}
