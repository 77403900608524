<table class="table table-sticky">
    <thead>
        <tr>
            <th></th>
            <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
                <th class="select-th">
                    <button class="btn btn-sm btn-light select-btn" (click)="onColumnSelectClick(column)">
                        {{getSelectLabel(column)}}
                    </button>
                </th>
            </ng-container>
        </tr>
        <tr>
          <th class="type-label">Statement Date</th>
          <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
              <th
                  class="column-header statement-header">
                  <div class="inline">
                      <i class="fas hide-column" [ngClass]="((column.isHidden || !showNumbers)) ? 'fa-eye' : 'fa-eye-slash'" (click)="hideColumn($event, idx)"></i>
                      <i  attr.id="tipIcon{{idx}}" (mouseenter)="showTip(idx)" (mouseleave)="hideTip()" class="fas fa-info-circle info" *ngIf="column.interpolated"></i>
                      <p>{{column.statementDate}}</p>
                  </div>
              </th>
          </ng-container>
      </tr>
      <tr>
        <th class="type-label">Scenario</th>
        <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
            <th
                class="column-header statement-header">
                <div class="inline">
                    <i class="fas hide-column" [ngClass]="((column.isHidden || !showNumbers)) ? 'fa-eye' : 'fa-eye-slash'" (click)="hideColumn($event, idx)"></i>
                    <i  attr.id="tipIcon{{idx}}" (mouseenter)="showTip(idx)" (mouseleave)="hideTip()" class="fas fa-info-circle info" *ngIf="column.interpolated"></i>
                    <p>{{column.scenario}}</p>
                </div>
            </th>
        </ng-container>
      </tr>
      <ng-container *ngIf="_projectionHelperService.atLeastOneProjectionColumnExists(columns)">
        <tr>
          <th class="type-label">{{ (projectionScenarioTypeLabelOverride + ' Name') }}</th>
          <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
              <th
                class="column-header statement-header"
                title="{{column.projectionName}}"
              >
                <div class="inline">
                    <i class="fas hide-column" [ngClass]="((column.isHidden || !showNumbers)) ? 'fa-eye' : 'fa-eye-slash'" (click)="hideColumn($event, idx)"></i>
                    <i  attr.id="tipIcon{{idx}}" (mouseenter)="showTip(idx)" (mouseleave)="hideTip()" class="fas fa-info-circle info" *ngIf="column.interpolated"></i>
                    <p>{{_projectionHelperService.getCondensedProjectionName(column.projectionName)}}</p>
                </div>
              </th>
          </ng-container>
        </tr>
      </ng-container>
      <tr>
          <th class="type-label">Months Covered</th>
          <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
              <th>
                  {{column.monthsInInterval}}
              </th>
          </ng-container>
      </tr>
      <tr>
          <th class="type-label">Prep Type</th>
          <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
              <th>
                  {{ column.preparationType | formatPrepType }}
              </th>
          </ng-container>
      </tr>
      <tr>
          <th class="type-label show-bottom-border">Statement Type</th>
          <ng-container *ngFor="let column of visibleColumns; let idx=index;trackBy: trackColumn">
              <th
                  class="show-bottom-border"
                  [ngClass]="{'hidden': column.isHidden, 'grayed': column.isHidden }">
                  {{ column.reportingInterval | reportingPeriod}}
              </th>
          </ng-container>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let row of rows; let idx = index" [className]="getRowFormatClass(row.rowFormat, idx)">
          <td *ngIf="row.rowFormat && row.rowFormat.header" class="line-item">
          <span [className]="'indent indent-' + row.indentation.toString()"></span>
              {{row.label}}
          </td>
          <td *ngIf="row.rowFormat && !row.rowFormat.header" class="line-item">
              <i class="graph-check" (click)="toggleRow($event, idx)" [ngClass]="{
                  'fas fa-chart-line deselected': !row.selected,
                  'far fa-check-square selected': row.selected
              }"></i>
              <i class="fas hide-row"
                  [ngClass]="(hiddenRowIndices.has(idx)) ? 'fa-eye' : 'fa-eye-slash'"
                  (click)="hideRow($event, idx)"></i>
              <span [className]="'indent indent-' + row.indentation.toString()"></span>
              <span class="line-item-toggle" title="{{row.label}}"
                  [ngClass]="{
                      'fs-block fs-exclude': isSourceStatement
                  }"
              >
                  {{row.label}}
              </span>
          </td>
          <ng-container *ngFor="let column of visibleColumns; let colIdx = index;trackBy: trackColumn">
              <td *ngIf="!column.isHidden"
                  class="value-cell no-padding" [ngClass]="{'hidden': column.isHidden, 'grayed' : column.isHidden }">
                  <ng-container *ngIf="!row.rowFormat.header">
                    {{column?.cells[idx]?.formattedValue ? column.cells[idx].formattedValue : 0}}
                  </ng-container>
              </td>
            </ng-container>
      </tr>
</table>
