import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { EnvironmentService } from '../../../../../services/environment.service';

@Component({
  selector: 'app-api-redoc',
  templateUrl: './api-redoc.component.html',
  styleUrls: ['./api-redoc.component.css']
})
export class ApiRedocComponent implements OnInit {
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer,
    private _sharedDataService: SharedDataService,
    private _environmentService: EnvironmentService, ) { }

  ngOnInit() {
    const open_api = this._environmentService.getOpenApiSchemaUrl();
    const url = `/assets/html/redoc.html?openapi=${encodeURIComponent(open_api)}`
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this._sharedDataService.pageHeaderTitle$.next('API - Documentation');
  }

}
