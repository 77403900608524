/**
 * Class for Query - generate query for back end (aggregate Criteria, sort and so on)
 */

// TODO deprecate?
export default class Query {
  criteria: any;
  ignoreLoading: boolean;
  searchId: number;
  sort: string;
  limit: number | string;
  page: number | string;
  distinct: boolean;

  constructor() {
    /**
     * Criteria list
     * @type {Array<Criteria>}
     */
    this.criteria = [];
    /**
     * Loading screen
     * @type {boolean}
     */
    this.ignoreLoading = false;
  }

  /**
   * Add Criteria to Query
   * @param criteria {Criteria}
   * @returns {Query}
   */
  add(criteria) {
    this.criteria.push(criteria);
    return this;
  }


  /**
   * Set Sort
   * @param sort {String}
   * @return {Query}
   */
  setSort(sort) {
    this.sort = sort;
    return this;
  }

  /**
   * Set Limit
   * @param limit {String|Number}
   * @return {Query}
   */
  setLimit(limit) {
    this.limit = limit;
    return this;
  }

  /**
   * Set Page
   * @param page {String|Number}
   * @return {Query}
   */
  setPage(page) {
    this.page = page;
    return this;
  }

  /**
   * Generate query hash for back end
   * @return {{criteria: (Array.<Criteria>|*), page: (Number|*), limit: (String|*), sort: (String|*),
     * searchId: (Number|*), ignoreLoading: (boolean|Boolean|*)}}
   */
  generate() {
    return {
      criteria: this.criteria,
      page: this.page,
      distinct: this.distinct,
      limit: this.limit,
      sort: this.sort,
      searchId: this.searchId,
      ignoreLoading: this.ignoreLoading
    };
  }

}
