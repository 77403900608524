<div class="row">
	<div class="col-sm-12">
		<h2>Loaders</h2>
	</div>
	<div class="col-sm-6">
		<div class="preloader-1">
		    <div class="preloader__title-1">Loading</div>
		    <div class="preloader__content-1">
		        <div></div>
		        <div></div>
		        <div></div>
		        <div></div>
		    </div>
		</div>
	</div>
	<div class="col-sm-3">
		<div class="loader__container">
	        <div class="loader"></div>
	    </div>
	</div>
	<div class="col-sm-3">
		<div class="loader__container">
	        <div class="loader"></div>
	    </div>
	</div>	
	<div class="col-sm-12">
		<hr />
	</div>
</div>




