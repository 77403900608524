import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CurrencyFormatSwitcherComponent } from './currency-format-switcher.component';
import { HttpClientModule } from '@angular/common/http';
import { Select2Module } from '../select2/select2.module';

@NgModule({
  declarations: [
    CurrencyFormatSwitcherComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    HttpClientModule,
    PipesModule,
    Select2Module
  ],
  exports: [CurrencyFormatSwitcherComponent],
  providers: [],
})
export class CurrencyFormatSwitcherModule {
}

