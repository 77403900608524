import {NgModule} from '@angular/core';
import {EqualValidatorDirective} from './equal-validator.directive';
import {HighlightDirective} from './highlight.directive';
import {SortableListDirective} from './sortable-list/sortable-list';
import {FocusDirective} from './focus.directive';
import {
  IntersectionObserverDirective,
  IntersectionObserverListDirective
} from "@components/shared/directives/img-intersection-obx.directive";

@NgModule({
  declarations: [
    HighlightDirective,
    EqualValidatorDirective,
    SortableListDirective,
    FocusDirective,
    IntersectionObserverDirective,
    IntersectionObserverListDirective
  ],
  imports: [],
  exports: [
    HighlightDirective,
    EqualValidatorDirective,
    SortableListDirective,
    FocusDirective,
    IntersectionObserverDirective,
    IntersectionObserverListDirective
  ]
})
export class DirectiveModule {
}
