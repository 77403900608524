
import {interval as observableInterval,  Observable, Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { AutoUnsubscribe } from '../../../../../../../decorators/auto-unsubscribe';

@Component({
  selector: 'app-calculating',
  templateUrl: './calculating.component.html',
  styleUrls: ['./calculating.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class CalculatingComponent implements OnInit, OnDestroy {
  @Input() finish = false;
  @Output() finishedLoading = new EventEmitter();
  progressWidth = '0%';
  timeout;
  numericalProgress;
  subsArr$: Subscription[] = [];

  constructor() { }

  ngOnInit() {
    this.subsArr$.push(observableInterval(300).subscribe((val) => {
      this.incrementProgress();
    }));
  }

  ngOnDestroy() {
    this.clearTimeout();
  }

  incrementProgress() {
    if (this.finish) {
      this.progressWidth = '100%';
      // Wait before going away so user sees loader complete
      this.startTimeout();
    } else {
      let int = parseInt(this.progressWidth, 10);
      if (int < 90) {
        int += 2;
        this.progressWidth = int + '%';
      } else if (int >= 90 && int < 99) {
        int += 1;
        this.progressWidth = int + '%';
      }
    }
    this.setNumericalProgress();
  }

  setNumericalProgress() {
    this.numericalProgress = parseInt(this.progressWidth, 10);
  }

  done() {
    this.finishedLoading.emit();
    this.clearTimeout();
  }

  startTimeout() {
    this.timeout = setTimeout(() => {
      this.done();
    }, 1000);
  }

  clearTimeout() {
    clearTimeout(this.timeout);
  }

}
