<div class="main-content">
    <ng-container *ngIf="!errorGettingPageImg; else errorMessage">
        <div class="toolbar row">
            <div class="col-6 offset-xxl-3 centered-content">
                <app-pdf-paginator [pageCount]="pageCount"></app-pdf-paginator>
                <div class="btn-separator">|</div>
                <app-pdf-zoom [zoomElementContainerId]="showTable ? 'page-table-list' : 'page-img-list'"></app-pdf-zoom>
            </div>
            <div class="col-3 right-aligned-content">
                <a class="btn btn-empty-primary pe-1" *ngIf="originalFileUrl"
                   target="_blank" href="{{originalFileUrl}}">
                    <i class="fas fa-download"></i>Download
                </a>
                <button class="btn btn-empty-primary" (click)="exitSplitscreen()"><i class="fas fa-xmark"></i>Close
                </button>
            </div>
        </div>
        <div class="row doc-container px-0 fs-block" #docContainer (scroll)="handleScroll()">
            <div *ngIf="!showTable">
                <div id="page-img-list" class="page-images"
                     appImgIntersectionObxList [forceChangeDetection]="true"
                >
                    <div class="page-img-list-item index-{{i}}"
                        [attr.id]="'index-'+i"
                        #pageImgListItem
                        appImgIntersectionObx
                        #intersection="intersection"
                        *ngFor="let pageUrl of pageImgUrls; let i = index;">
                        <app-image-highlighter
                            *ngIf="intersection.hasIntersected; else imagePlaceholder"
                            [cropper]="i===viewSourcePage ? cropper: null"
                            [pageImgUrl]="pageUrl"
                            [imagePlaceholderHeight]="imgPlaceholderHeight"
                            (rerenderEvent)="rerenderEvent.emit()"
                        ></app-image-highlighter>
                        <ng-template #imagePlaceholder>
                            <div class="image-placeholder"
                                 [ngStyle]="{'height.px': imgPlaceholderHeight}">
                                ...
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="showTable">
                <div id="page-table-list">
                    <table class="excel-table fs-block" *ngFor="let page of documentPages" #pageImgListItem>
                        <tr *ngFor='let row of page.cells'>
                            <td *ngFor='let col of row'>{{ col.text }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #errorMessage>
        <div class="error-message">
            <h5 class="mb-5">Error retrieving original document for display.
                <span *ngIf="originalFileUrl">Try downloading the document directly.</span>
            </h5>
            <a *ngIf="originalFileUrl" class="btn btn-empty-primary" target="_blank"
               href="{{originalFileUrl}}">
                <i class="fas fa-download"></i> Download Document
            </a>
        </div>
    </ng-template>
</div>
<div *ngIf="showLoadingOverlay" class="loading-overlay">
    <div class="loader"></div>
</div>
