<div id="main">
    <div class="inner-content">
        <div class="d-flex float-end action-button-row">
            <button (click)="createPortfolio()" type="button" class="btn btn-primary form-control">
                Create Portfolio
            </button>
        </div>

        <div>
            <table class="table table-hover">
                <thead>
                <tr>
                    <th class="col-md-2">Name</th>
                    <th class="col-md-2">Last Updated</th>
                    <th class="col-md-2">Created</th>
                    <th class="col-md-1">Actions
                    </th>
                </tr>
                </thead>
                <tbody *ngIf="portfolios?.length; else noPortfolios">
                <tr *ngFor="let portfolio of portfolios | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }"
                    portfolio-tr [portfolio]="portfolio"
                    (refreshPortfolioList)="_loadPortfolios()"></tr>
                </tbody>
                <ng-template #noPortfolios>
                  <tr>
                    <td colspan="4" class="no-portfolios-container">
                      <div *ngIf="!isLoading">
                        <app-table-empty-state
                          [emptyStateIcon]="noPortfoliosIcon"
                          [headerText]="noPortfoliosHeaderText"
                          [helpText]="noPortfoliosHelpText"
                        ></app-table-empty-state>
                      </div>
                      <ng-container *ngIf="isLoading">
                        <div class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
            </table>
            <div style="text-align: center">
                <pagination-template #p="paginationApi" (pageChange)="changePage($event)">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [ngClass]="{'disabled': p.isFirstPage()}">
                                <a class="page-link" (click)="!p.isFirstPage() ? p.previous() : null" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            <li class="page-item" *ngFor="let page of p.pages" [ngClass]="{'active': p.getCurrent() === page.value}">
                                <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    {{ page.label }}
                                </a>
                                <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}
                                    <span class="visually-hidden">(current)</span>
                            </span>
                            </li>

                            <li class="page-item" [ngClass]="{'disabled': p.isLastPage()}">
                                <a class="page-link" (click)="!p.isLastPage() ? p.next() : null" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </pagination-template>
            </div>
        </div>
    </div>
</div>

