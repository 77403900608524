import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvFromHostname } from '../models/env-from-host';
import { CommonFunctions } from '../utils/common-functions';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';
import { ENV_CONVENTION } from '../utils/constants';
import { mergeMap } from 'rxjs/operators';
// @ts-ignore
import packageJson from '../../../package.json';
import {
  Configuration,
  ApiKeyService,
  BorrowersService,
  FilesService,
  LoansService,
  PortfoliosService,
  WebhooksService } from '@services/fincura-ng-client';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  // fincura-ng-client setup
  static apiConfig: Configuration = new Configuration();
  static getApiConfiguration(): Configuration {
    return AppConfigService.apiConfig;
  }

  public envFromHostname: ReplaySubject<EnvFromHostname> = new ReplaySubject<EnvFromHostname>(1);

  private _loading = false;
  private _loaded = false;
  private readonly _maxRetries = 2;
  private readonly _backOffMs = 1000;

  constructor(private _http: HttpClient,
    private _apiKeyService: ApiKeyService,
    private _borrowersService: BorrowersService,
    private _filesService: FilesService,
    private _loanService: LoansService,
    private _portfolioService: PortfoliosService,
    private _webhooksService: WebhooksService,
    ) {
  }

  // returns the target host for the app
  loadApplicationHost(): Observable<string> {
        const observable = new Subject<string>();
        const appHost = `${window.location.hostname}`;
        const hostWithPort = `${appHost}${location.port ? ':' + location.port : ''}`
        const host_base_domain = `${window.location.protocol}//${hostWithPort}`;
        if (!appHost.endsWith('fincura.com')) {
          const self = this;
          const request = new XMLHttpRequest();
          request.onreadystatechange = function () {
              if (request.readyState === XMLHttpRequest.DONE) {
                  const headerArr = request.getAllResponseHeaders().split('\r\n');
                  const headers = headerArr.reduce(function (acc, current, i) {
                        const parts = current.split(': ');
                        acc[parts[0]] = parts[1];
                        return acc;
                  }, {});
                  if (headers.hasOwnProperty(ENV_CONVENTION.WHITELABEL_TARGET_HEADER)) {
                    const targetHost = headers[ENV_CONVENTION.WHITELABEL_TARGET_HEADER];
                    observable.next(targetHost);
                  } else {
                    observable.next(appHost);
                  }
              }
          };
          request.open('GET', host_base_domain, true);
          request.send(null);
        } else {
          setTimeout(() => observable.next(appHost), 1);
        }
        return observable;
  }


  loadAppconfig(retryAttempt: number = 0): Observable<any> {
    if (this._loading) {
      return of([]);
    }

    this._loading = true;
    return this.loadApplicationHost().pipe(mergeMap((appHost) => {
      const envFromHost: EnvFromHostname = CommonFunctions.getEnvFromHost(appHost);
      this.setupPublicApiConfig(envFromHost);
      this.envFromHostname.next(envFromHost);
      this._loaded = true;
      return of([]);
    }));
  }

  retry(error: any, retryAttempt: number) {
    if (retryAttempt <= this._maxRetries) {
      setTimeout(() => {
        this.loadAppconfig(retryAttempt);
      }, this._backOffMs);
    } else {
      throw new Error('Could not load app config');
    }
  }

  public setPublicApiHeaders() {
    let headers = CommonFunctions.getHeaders(packageJson.version, null, false, CommonFunctions.tryGetLocalStorageItem('bank_id'));
    // dont use bearer for api (rely on cookie)
    headers = headers.delete('x-authorization');
    this._apiKeyService.defaultHeaders = headers;
    this._borrowersService.defaultHeaders = headers;
    this._filesService.defaultHeaders = headers;
    this._portfolioService.defaultHeaders = headers;
    this._loanService.defaultHeaders = headers;
    this._webhooksService.defaultHeaders = headers;
  }

  private setupPublicApiConfig(envFromHost: EnvFromHostname) {
    // fincura-ng-client setup
    this.setPublicApiHeaders();
    AppConfigService.apiConfig.withCredentials = true;
    // set the base url on the fincura-ng-client Configuration
    AppConfigService.apiConfig.basePath = envFromHost.serverUrl;
  }
}
