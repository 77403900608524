import { SpreadingActionsUnion, SpreadingActionTypes } from '../actions/spreading.actions';

export interface State {
  dragging: boolean;
}

const initialState: State = {
  dragging: false,
};

export function reducer(state: State = initialState,
                        action: SpreadingActionsUnion,
): State {
  switch (action.type) {
    case SpreadingActionTypes.DragEnded:
      return {
        dragging: false,
      };

    case SpreadingActionTypes.DragStarted:
      return {
        dragging: true,
      };

    default:
      return state;
  }
}

export const getDraggingState = (state: State) => state.dragging;
