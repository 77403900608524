/**
 * Fincura Integration API
 * This [REST API](https://en.wikipedia.org/wiki/Representational_state_transfer) allows you to interact with the Fincura processing and insights engine.   # Authentication  This API uses API keys generated from a Fincura User account. To get access to your User account, speak with you Fincura account manager.  # Accepted Media Types  | File&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; | Extension(s)     | Content-Type(s) | | -------------------------------- |-------------- | ------------ | | PDF File | .pdf | `application/pdf` , `application/x-pdf` | | Excel File | .xls | `application/vnd.ms-excel`  | | Excel File | .xlsx | `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`  | | PNG Image | .png | `image/png`  | | GIF Image | .gif | `image/gif`  | | JPG Image | .jpg, .jpeg | `image/jpeg`  | | GIF Image | .gif | `image/gif`  |   # Getting Started  1. [Create a Borrower](#operation/createBorrower)  2. [Add a file](#operation/createDocumentFile) for that Borrower. 3. Analyze in the Fincura App  ****
 *
 * The version of the OpenAPI document: 1.2.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Borrower information specific to this loan (does not update the underlying Borrower)
 */
export interface LoanBorrowerInfo { 
    name: string;
    industry?: LoanBorrowerInfo.IndustryEnum;
    naicsCode?: number | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    zipcode?: string | null;
    country?: string | null;
    email?: string | null;
    companyNotes?: string | null;
    dunBradstreetScore?: number | null;
    paynetScore?: string | null;
}
export namespace LoanBorrowerInfo {
    export type IndustryEnum = 'UNKNOWN' | 'AGRICULTURE_FORESTRY_FISHING' | 'CONSTRUCTION' | 'MINING' | 'TRANSPORTATION_AND_PUBLIC_UTILITIES' | 'WHOLESALE_TRADE' | 'RETAIL_TRADE' | 'MANUFACTURING' | 'FINANCE_INSURANCE_REAL_ESTATE' | 'SERVICES' | 'PUBLIC_ADMINISTRATION';
    export const IndustryEnum = {
        Unknown: 'UNKNOWN' as IndustryEnum,
        AgricultureForestryFishing: 'AGRICULTURE_FORESTRY_FISHING' as IndustryEnum,
        Construction: 'CONSTRUCTION' as IndustryEnum,
        Mining: 'MINING' as IndustryEnum,
        TransportationAndPublicUtilities: 'TRANSPORTATION_AND_PUBLIC_UTILITIES' as IndustryEnum,
        WholesaleTrade: 'WHOLESALE_TRADE' as IndustryEnum,
        RetailTrade: 'RETAIL_TRADE' as IndustryEnum,
        Manufacturing: 'MANUFACTURING' as IndustryEnum,
        FinanceInsuranceRealEstate: 'FINANCE_INSURANCE_REAL_ESTATE' as IndustryEnum,
        Services: 'SERVICES' as IndustryEnum,
        PublicAdministration: 'PUBLIC_ADMINISTRATION' as IndustryEnum
    };
}


