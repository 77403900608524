<div class="full-container" *ngIf="!isLoading">
    <app-locked-takeover
        *ngIf="isDocumentLocked()"
        [lastLockedBy]="model.lastLockedBy"
    ></app-locked-takeover>
    <app-digitization-top-nav
        [document]="reviewQueueItem"
        (saveMethod)="saveAndStay()"
        (nextMethod)="next()"
    >
        <ng-container *ngIf="activePage != null">
            <div>
                <div class="toggler">
                    <label>Show Document</label>
                    <i class="fas" [ngClass]="{'fa-toggle-off': !splitscreenOpen, 'fa-toggle-on': splitscreenOpen}"
                       (click)="toggleSplitScreen()"></i>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center flex-wrap">
                <label class="d-flex align-items-center">Set Currency </label>
                <select class="form-select w-auto" (change)="changeCurrency($event.target.value)">
                    <option *ngFor="let currency of supportedCurrencies" [value]="currency.key"
                            [selected]="currency.key === this.model.currency">
                        {{currency.key}} ({{currency.symbol}})
                    </option>
                </select>
            </div>

            <div class="d-flex flex-row align-items-center flex-wrap">
                <label class="d-flex align-items-center">Statement Type
                    <i class="fa fa-question-circle ms-2" aria-hidden="true">
                        <div class="statementTypeToolTip">The statement type for this specific page</div>
                    </i>
                </label>

                <select [(ngModel)]="model.document.pages[activePage].statementType"
                        (change)="handleStatementTypeSelectorChange()"
                        [ngClass]="{'is-invalid': !_pageHasValidStatementTypeSelected(activePage)}"
                        class="form-select w-auto" id="inputGroupSelect01">
                    <option value="">Select Statement Type</option>
                    <option value="INCOME_STATEMENT">Income Statement</option>
                    <option value="BALANCE_SHEET">Balance Sheet</option>
                    <option value="CASH_FLOW_STATEMENT">Cash Flow Statement</option>
                </select>
            </div>

            <div *ngIf="!(splitscreenOpen && _sharedDataService.shouldShowManualReviewSidebar)" class="d-flex flex-row align-items-center flex-wrap">

                <label class="d-flex align-items-center">Multiply By
                    <i class="fa fa-question-circle ms-2" aria-hidden="true">
                        <div class="multiplyByToolTip">Multiplies values from financial statement that were reported as rounded. Changes will take effect in the next step</div>
                    </i>
                </label>


                <select [(ngModel)]="roundingSelection" class="form-select w-auto" id="roundingInput" [disabled]="isRoundingDropdownDisabled">
                    <option value="none">None</option>
                    <option value="thousands">Thousands</option>
                    <option value="millions">Millions</option>
                </select>
            </div>

            <div [ngClass]="{'none': splitscreenOpen}"
                 *ngIf="isAdditionalInformationShown()">
                <label>Additional Information</label>
                <br/>
                <textarea
                    disabled>{{model.jobPayload.documentFile.additionalInformation}}</textarea>
            </div>

            <div
                [ngClass]="{ 'none': (splitscreenOpen && _sharedDataService.shouldShowManualReviewSidebar )}">
                <div>
                    <a [ngClass]="{'disabled': isLoading}" class="btn btn-outline-danger"
                       href="javascript:void(0);" (click)="deletePage(activePage)">Delete Statement</a>
                </div>
            </div>
        </ng-container>

    </app-digitization-top-nav>

    <div class="non-header-content-container">
        <div class="left-content" [ngClass]="{'split-screen-left': splitscreenOpen}">
            <ng-container *ngIf="_sharedDataService.shouldShowManualReviewSidebar">
                <app-maximized-sidebar
                    [model]="model"
                    [stepHeader]="stepHeader"
                    [steps]="steps"
                    [tipsBody]="tipsBody"
                    (toggleSidebar)="toggleStepsToSuccess()">
                </app-maximized-sidebar>
            </ng-container>

            <div class="splitscreen-wrapper" *ngIf="splitscreenOpen">
                <app-document-view-split-screen
                    [documentFileType]="modelCopy.document.type"
                    [originalFileUrl]="modelCopy?.originalFileUrl"
                    [pageImgUrls]="modelCopy?.pageImgUrls"
                    [documentPages]="modelCopy.document?.pages"
                    [viewSourcePage]="viewSource ? viewSourcePage : null"
                    [viewSourceBox]="viewSource ? viewSourceBox : null"
                    (closeSplitscreen)="closeSplitscreen()">
                </app-document-view-split-screen>
            </div>
        </div>

        <div class="right-content" [ngClass]="{'split-screen-right': splitscreenOpen}">

            <div *ngIf="!isError" class="main-column">
                <div class="cell-validation-tooltip" [ngStyle]="cellValidationStyle"
                     [ngClass]="{'shown': cellValidationMessage}">
                    {{cellValidationMessage}}</div>

                <div class="table-container fixedTable">
                    <div class="table-wrap">
                        <hot-table [afterInit]="afterTableInit" [colHeaders]="true" [rowHeaders]="true"
                                   [hotId]="hotId"
                                   [data]="pageData" [settings]="{renderer: renderCell, contextMenu: contextMenu}"
                                   [licenseKey]="handsOnTableLicense"
                        ></hot-table>
                    </div>
                </div>
            </div>

            <div *ngIf="isError">
                <h1>Something went wrong.</h1>
                <h2>We've been alerted and are already looking into it.</h2>
            </div>
            <div *ngIf="!isError" class="page-nav">
                <nav class="navbar navbar-expand p-0 navbar-financials">
                    <ul class="navbar-nav me-auto horizontal-scroll-wrapper">
                        <li *ngFor="let page of model.document.pages; index as i"
                            [ngClass]="{
                                'active': activePage == i,
                                'error-tab': pageStatuses[i] == ERROR_STATUS,
                                'warning-tab': pageStatuses[i] == WARNING_STATUS,
                                'success-tab': !pageStatuses[i]
                            }"
                        >
                            <a href="javascript:void(0);" class="nav-link" (click)="showPage(i)">
                                <span class="list-group-item-text">
                                    <i class="fas fa-solid fa-circle-check pe-2" [ngClass]="{
                                        'fa-circle-xmark': pageStatuses[i] == ERROR_STATUS,
                                        'fa-triangle-exclamation': pageStatuses[i] == WARNING_STATUS,
                                        'fa-circle-check': !pageStatuses[i]
                                    }"></i>
                                    <span *ngIf="model.document.pages[i].statementType">
                                        {{model.document.pages[i].statementType | docType | titlecase}}
                                    </span>
                                    <i class="fas fa-times delete-page" (click)="deletePage(i)"></i>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a class="list-group-item add-page page nav-link" href="javascript:void(0);" (click)="addPage()">
                                <span class="list-group-item-text"><i class="fas fa-plus"></i></span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="col-12 review-editor-loader-wrapper">
    <div class="loader"></div>
</div>
