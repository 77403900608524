import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Company, CompanyPatch } from '../../../../../models/company';
import { AlertService } from '../../../../../services/alert.service';
import { PortfolioService } from '../../../../../services/portfolio.service';
import { BorrowerService } from '../../../../../services/borrower.service';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import {UserService} from "@services/user.service";
import {PLATFORM_PERMISSIONS} from "@utils/constants";

@Component({
  selector: 'app-portfolio-tag',
  templateUrl: './portfolio-tag.component.html',
  styleUrls: ['./portfolio-tag.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class PortfolioTagComponent implements OnInit, OnChanges {
  subsArr$: Subscription[] = []
  @Input() portfolios = [];
  @Input() company: Company;

  portfolioOptions: any [];
  saving = false;
  canAddBorrowerToPortfolio = this._userService.user.hasUserPermission(PLATFORM_PERMISSIONS.ADD_BORROWER_TO_PORTFOLIO)

  constructor(public portfolioService: PortfolioService,
              private alertService: AlertService,
              private borrowerService: BorrowerService,
              private _userService: UserService
  ) {
  }

  ngOnInit() {
    this.setPortFolioOptions();
  }

  ngOnChanges() {
    this.setPortFolioOptions();
  }

  setPortFolioOptions(): void {
    const options = [];
    this.portfolios.forEach((option, idx) => {
        if (!this.company.portfolio || option.id !== this.company.portfolio.id) {
            options.push(option);
        }
    });
    if (!!this.company.portfolio && this.company.portfolio.id != null) {
        options.push({id: null, name: 'Unassigned'});
    }
    this.portfolioOptions = options;
  }

  setPortfolio(portfolio: any): void {
    const companyPatch: any = {};
    companyPatch.portfolio_id = portfolio.id;
    this.saving = true;
    this.subsArr$.push(this.borrowerService.companyUpdate(companyPatch, this.company.id)
    .subscribe((company: Company) => {
          this.company.portfolio = portfolio;
          this.setPortFolioOptions();
          this.saving = false;
        },
        error => {
          this.saving = false;
          this.alertService.error('Error saving portfolio');
        },
      ))
  }

}
