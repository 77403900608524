import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { DataViewColumn, DataView } from '@models/dataview';
import { SharedDataService } from '@services/shared-data.service';
import { DataViewService } from '@services/data-view.service';
import { DataFrameService } from '@services/dataframes.service';
import { Subscription, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NgxPopupService } from '../../../shared/ngx-popups/ngx-popups/ngx-popups';
import { SavingPopupComponent } from '../../../shared/popups/saving/saving-popup.component';
import { AutoUnsubscribe } from '../../../../decorators/auto-unsubscribe';
import { Company } from '@models/company';
import { Period } from '@models/period';
import { Select2OptionData } from '@components//shared/select2/select2.interface';
import { Footnote } from '@models/footnote';
import { BuilderFrame, STATEMENT_BUILDER_OPERATORS } from './model';
import { COMPANY_PAGE_VIEW_MODE, DATE_FORMAT, SERVER_DATE_FORMAT, TAXONOMY_ITEM_IDS, COMPANY_TABS, COMPANY_ENTITLEMENT_DATA } from '@utils/constants';
import * as moment from 'moment';
import { AlertService } from '@services/alert.service';
import { BankSettingsService } from '@services/bank-settings.service';
import {MultiSelectItem} from "@components/shared/popover-menu-multi-select/popover-menu-multi-select.component";

@Component({
  selector: 'app-statement-builder',
  templateUrl: './statement-builder.component.html',
  styleUrls: ['./statement-builder.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class StatementBuilderComponent implements OnInit, OnDestroy {
  subsArr$: Subscription[] = [];
  dataViewId: number = null;
  loadingMessage = '';
  loading = true;
  didErrorOccur = false;
  activeDataView: DataView = null;
  periodOptions: Array<Period>;
  periodSelectOptions: Array<Select2OptionData>;
  selectedPeriodOption: Period = new Period();
  roundToThousands = false;
  roundToThousandsDisabled = false;
  dollarSign = false;
  dollarSignDisabled = false;
  numbers = true;
  numbersDisabled = false;
  commonSize = false;
  commonSizeDisabled = true;
  trendDisabled = false;
  trend = false;
  removeHiddenDisabled = true;
  company: Company = null;
  dataViewsList: Array<DataView>;
  showBenchmarking = false;
  isGraphShown = false;
  graphItems: any[] = [];
  graphYAxisLabel = '';
  graphItemLabels = '';
  hiddenRows: Set<number> = new Set();
  hiddenColumns: Set<string> = new Set();
  dataViewConfigId: number;
  showHidden = false;
  showHiddenDisabled = true;
  isFootnotesViewOn = false;
  footnote: Footnote = null;
  footnoteModel = {};
  activeFootnotePage = 0;
  docFileId = '';
  cropper = null;
  gotCompany = false;
  showSpreadingInContextMenu = true;
  showCalculating = false;
  retries = 0;
  finishedCalculating = false;
  showCallToAction = false;
  timeout;
  isFormComplete = false;
  projectionScenarioTypeLabelOverride = 'Projection';

  includedFrames: Array<BuilderFrame> = [];
  balanceSheetColumn: DataViewColumn;
  beginningCashColumn: DataViewColumn;
  endingCashColumn: DataViewColumn;
  statementDate: string = null;
  reportingInterval = '';

  hasBeginningCashflowBeenChangedByUser = false;
  hasEndingCashflowBeenChangedByUser = false;
  hasBalanceSheetBeenChangedByUser = false;
  isBeginningCashInTable = false;
  isEndingCashInTable = false;

  // for table
  includedColumns: {[key: string]: string} = {};

  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS;
  noAccessErrorText = "You don't have enough permissions to view this page.";
  routeData: Data;
  readonly COMPANY_PAGE_VIEW_MODE = COMPANY_PAGE_VIEW_MODE;

  constructor(
    private _route: ActivatedRoute,
    private _sharedData: SharedDataService,
    private _alertService: AlertService,
    private _dataViewService: DataViewService,
    private _dataFrameService: DataFrameService,
    private _popupService: NgxPopupService,
    private _router: Router,
    private _bankSettingsService: BankSettingsService,
  ) {
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.pageViewMode = this.routeData.companyEntitlement[COMPANY_TABS.FINANCIALS][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];
    if (this.pageViewMode === COMPANY_PAGE_VIEW_MODE.FULL) {
      this._route.paramMap.subscribe(params => {
        const dvid = params.get('id');
        if (dvid) {
          this.dataViewId = parseInt(dvid, 10);
          this.loadData(false);
        }
      });

      this.subsArr$.push(this._sharedData.company$.pipe(
        first(company => company !== null))
        .subscribe((company: Company) => {
            this.gotCompany = true;
            this.company = company;
            this.loadData(true);
        }),
      );

      this._bankSettingsService.getProjectionScenarioTypeLabelOverride().subscribe(projectionScenarioTypeLabelOverride => {
        this.projectionScenarioTypeLabelOverride = projectionScenarioTypeLabelOverride || this.projectionScenarioTypeLabelOverride;
      });
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.stopPolling();
  }

  dataChangeOccurred(): void {
    if (this.includedFrames.length > 0 && this.reportingInterval && this.statementDate) {
      this.isFormComplete = true;
    } else {
      this.isFormComplete = false;
    }
  }

  /**
   * While we figure out how to manage showing raw/normalized/calc, hardcode
   * here that we're not showing normalized unless the flag is on
   */
  shouldShowDataView(dv): boolean {
    return true;
  }

  formatDataViewName(name: string): string {
    const nameTranslate = {
      'Raw IS': 'Source IS',
      'Raw BS': 'Source BS',
      'Raw CF': 'Source CF',
      'Raw Income Statement': 'Source IS',
      'Raw Balance Sheet': 'Source BS',
      'Raw Cash Flow Statement': 'Source CF',
      'Normalized Balance Sheet': 'Norm. BS',
      'Normalized Income Statement': 'Norm. IS',
      'Normalized Cash Flow Statement': 'Norm. CF',
    };

    if (nameTranslate.hasOwnProperty(name)) {
      return nameTranslate[name];
    }
    return name;
  }

  dataViewRouterLink(viewId) {
    return ['/companies', this.company.uuid, 'financials', 'analysis', viewId];
  }

  getBuilderFrameForColum(column: DataViewColumn) {
    const column_key = column.toKeyString();
    return this.includedFrames.find((bf) => bf.column.toKeyString() === column_key);
  }

  includeColumn(column: DataViewColumn) {
    const column_key = column.toKeyString();
    const currentBuilderFrame = this.getBuilderFrameForColum(column);
    if (currentBuilderFrame) {
      switch (currentBuilderFrame.operator) {
          case STATEMENT_BUILDER_OPERATORS.add:
            currentBuilderFrame.operator = STATEMENT_BUILDER_OPERATORS.subtract;
            break;
          case STATEMENT_BUILDER_OPERATORS.subtract:
            this.excludeColumn(column);
            break;
      }
    } else {
      const newFrame = new BuilderFrame();
      newFrame.column = column;
      newFrame.operator = STATEMENT_BUILDER_OPERATORS.add;
      this.includedFrames.push(newFrame);

      if (!this.statementDate) {
        this.statementDate = column.statementDate;
      }

      // if user hasnt selected a column yet, we should initialize it to the either the earliest or latest column
      // for the balance sheet and ending cash, in most cases the user will choose latest period, so initialize the column to that
      // for beginning cash, in most cases the user will choose beginning period, so initialize the column to that
      if (!this.hasBalanceSheetBeenChangedByUser) {
        this.balanceSheetColumn = this.retreiveEarliestOrLatestIncludedColumn(false);
      }

      if (this.isBeginningCashInTable && !this.hasBeginningCashflowBeenChangedByUser) {
        this.beginningCashColumn = this.retreiveEarliestOrLatestIncludedColumn();
      }
      if (this.isEndingCashInTable && !this.hasEndingCashflowBeenChangedByUser) {
        this.endingCashColumn = this.retreiveEarliestOrLatestIncludedColumn(false);
      }
    }

    const cols = {}
    this.includedFrames.forEach((bf) => {
      cols[bf.column.toKeyString()] = bf.operator;
    })
    this.includedColumns = cols;
    this.dataChangeOccurred();
  }

  retreiveEarliestOrLatestIncludedColumn(getEarliest = true) {
    let column = null;
    this.includedFrames.forEach((includedFrame) => {
      if (column === null ||
        (getEarliest && new Date(includedFrame.column.statementDate) < new Date(column.statementDate)) ||
        (!getEarliest && new Date(includedFrame.column.statementDate) > new Date(column.statementDate))) {
        column = includedFrame.column;
      }
    })
    return column;
  }

  excludeColumn(column: DataViewColumn) {
    const column_key = column.toKeyString()
    const removeIndex = this.includedFrames.map((f) => f.column.toKeyString()).indexOf(column_key);
    if (removeIndex > -1) {
      this.includedFrames.splice(removeIndex, 1);
    }
    const cols = {}
    this.includedFrames.forEach((bf) => {
      cols[bf.column.toKeyString()] = bf.operator;
    })
    this.includedColumns = cols;

    if (this.isBalanceSheerColumn(column)) {
      this.hasBalanceSheetBeenChangedByUser = false;
      this.balanceSheetColumn = this.retreiveEarliestOrLatestIncludedColumn(false);
    }

    if (this.isBeginningCashInTable && this.isBeginningCashColumn(column)) {
      this.hasBeginningCashflowBeenChangedByUser = false;
      this.beginningCashColumn = this.retreiveEarliestOrLatestIncludedColumn();
    }

    if (this.isEndingCashInTable && this.isEndingCashColumn(column)) {
      this.hasEndingCashflowBeenChangedByUser = false;
      this.endingCashColumn = this.retreiveEarliestOrLatestIncludedColumn(false);
    }
  }

  setBalanceSheetColumn(column: DataViewColumn) {
    this.hasBalanceSheetBeenChangedByUser = true;
    this.balanceSheetColumn = column;
  }

  isBalanceSheerColumn(column: DataViewColumn) {
    const result = column === this.balanceSheetColumn;
    return result;
  }

  setBeginningCashColumn(column: DataViewColumn) {
    this.hasBeginningCashflowBeenChangedByUser = true;
    this.beginningCashColumn = column;
  }

  isBeginningCashColumn(column: DataViewColumn) {
    const result = column === this.beginningCashColumn;
    return result;
  }

  setEndingCashColumn(column: DataViewColumn) {
    this.hasEndingCashflowBeenChangedByUser = true;
    this.endingCashColumn = column;
  }

  isEndingCashColumn(column: DataViewColumn) {
    const result = column === this.endingCashColumn;
    return result;
  }

  setStatementDate(event): void {
    this.statementDate = event.dateObject.formatted;
    this.dataChangeOccurred();
  }

  /**
   * Load the data we need for this view:
   *
   * 1) The specified dataview-v2. The first load will implicitly
   *    use the most recent reporting period
   * 2) All available reporting periods for this borrower, so we
   *    can change the root period to recalculate the data in the
   *    table.
   */
  loadData(loadPeriods: boolean = true): void {
    if (!this.company) {
      return;
    }

    this.loading = true;
    this.didErrorOccur = false;

    if (this.dataViewId) {
      this.getDataView();
    }

    if (loadPeriods) {
      this.subsArr$.push(forkJoin(
        this._dataViewService.listDataViews(this.company.id, this.company.defaultSpreadingTemplate, true),
        this._dataFrameService.getPeriodsWithData(this.company.id)
      ).subscribe(results => {
        this.dataViewsList = results[0].filter(dv => this.shouldShowDataView(dv));
        // If there's no DVID specified, use the first non-raw one in this list.
        if (!this.dataViewId) {
          this.dataViewId = this.dataViewsList.find(dv => !dv.raw).id;
          this.getDataView();
        }
        this.periodOptions = results[1];
        this.periodSelectOptions = this.getPeriodSelectionOptions();
      }));
    }
  }

  getDataView(): void {
    this.subsArr$.push(forkJoin(
      this._dataViewService.readDataView(this.dataViewId, this.company.id, this.selectedPeriodOption.value, false, null, true),
      this._dataViewService.readDataViewConfig(this.dataViewId, this.company.id)
    ).subscribe(result => {

      // need to filter out prep types we dont wanna see in the statement builder - in the future would be nice to send filter to the api but thats a bigger change for now
      const rawDV = result[0];
      if (rawDV.columns) {
        this.activeDataView = rawDV;
        this.setCashFlowStatementFlags();
      }

      if (this.retries > 25) {
        this.showCallToAction = true;
        this.showCalculating = false;
        this.didErrorOccur = false;
        this.stopPolling();
      } else {
        if (this.activeDataView && this.activeDataView.calculating) {
          this.showCalculating = true;
          this.poll();
          this.retries++;
        } else {
          this.stopPolling();
          this.finishedCalculating = true;
          this.handleDataViewConfigResponse(result[1][0]);
          this.didErrorOccur = false;
          this.loading = false;
          console.log('data loaded');
        }
      }

    }, error => {
      this.loading = false;
      this.didErrorOccur = true;
    }
    ));
  }

  poll(): void {
    this.timeout = setTimeout(() => {
      this.getDataView()
    }, 1000);
  }

  stopPolling() {
    clearTimeout(this.timeout)
  }

  setCashFlowStatementFlags() {
    this.activeDataView.rows.forEach((row) => {
      if (row.lineItem.id === TAXONOMY_ITEM_IDS.TOTAL_BEGINNING_CASH) {
        this.isBeginningCashInTable = true;
      } else if (row.lineItem.id === TAXONOMY_ITEM_IDS.TOTAL_ENDING_CASH) {
        this.isEndingCashInTable = true;
      }
    })
  }

  hideCalculatingLoader() {
    this.showCalculating = false;
  }

  handleDataViewConfigResponse(dvc: any): void {
    if (dvc !== undefined) {
      this.roundToThousands = dvc.round_to_thousands;
      this.dollarSign = dvc.dollar_sign;
      this.numbers = dvc.numbers;
      this.commonSize = dvc.common_size;
      this.trend = dvc.trend;
      this.dataViewConfigId = dvc.id;
      this.hiddenColumns = new Set(dvc.hidden_columns);
      this.mapHiddenLineItemIdsToRowIds(new Set(dvc.hidden_rows));
    }
    this.handleNumbersAndPercent();
  }

  mapHiddenLineItemIdsToRowIds(lineItemIds: Set<number>): void {
    if (!this.activeDataView) {
      return;
    }

    this.hiddenRows.clear();
    if (lineItemIds) {
      this.activeDataView.rows.filter((r, idx) => {
        if (lineItemIds.has(r.lineItem.id)) {
          if (!this.hiddenRows.has(idx)) {
            this.hiddenRows.add(idx);
          }
        }
      });
    }
  }

  mapHiddenRowIdsToLineItemIds(): Array<number> {
    const lineItemIds: Array<number> = [];
    if (this.activeDataView && !this.activeDataView.rows) {
      return lineItemIds;
    }
    this.hiddenRows.forEach((r, idx) => {
      lineItemIds.push(this.activeDataView.rows[idx].lineItem.id);
    });

    return lineItemIds;
  }


  handleNumbersAndPercent(): void {
    // 1. Either one of numbers, commonsize or trend must be selected.
    // 2. CommonSize and trend are mutually exclusive
    // either one can be selected or both can be deselected,
    // but both cannot be selected at the same time.
    // This code could be made more company, setting each flag explicity for clarity
    if (this.numbers && (!this.commonSize && !this.trend)) {
      // Only numbers is chosen, and common size and trend are unchecked
      this.numbersDisabled = true;
      this.commonSizeDisabled = false;
      this.trendDisabled = false;
      this.roundToThousandsDisabled = false;
      this.dollarSignDisabled = false;
    } else if (this.commonSize && (!this.numbers && !this.trend)) {
      // Only common size is chosen
      this.commonSizeDisabled = true;
      this.numbersDisabled = false;
      this.trendDisabled = false;
      this.roundToThousands = false;
      this.roundToThousandsDisabled = true;
      this.dollarSignDisabled = true;
    } else if (this.trend && (!this.numbers && !this.commonSize)) {
      // Only trend is chosen
      this.trendDisabled = true;
      this.numbersDisabled = false;
      this.commonSizeDisabled = false;
      this.roundToThousands = false;
      this.roundToThousandsDisabled = true;
      this.dollarSignDisabled = true;
    } else {
      // numbers + one of trend or column are chosen
      this.numbersDisabled = false;
      this.commonSizeDisabled = false;
      this.trendDisabled = false;
      this.roundToThousandsDisabled = false;
      this.dollarSignDisabled = false;
    }
  }

  getPeriodSelectionOptions(): Array<Select2OptionData> {
    return this.periodOptions.map(po => {
      return {
        'id': po.value,
        'text': po.label,
        'additional': po,
      }
    })
  }

  selectPeriod(evt): void {
    this.selectedPeriodOption = evt.data[0].additional as Period;
    this.loadData(false);
  }


  getSpreadsheetDocName(): string {
    let docName = '';
    if (this.activeDataView) {
      if (this.activeDataView.title) {
        docName = this.activeDataView.title;
      }
      if (this.activeDataView.columns.length) {
        if (this.selectedPeriodOption.value) {
          docName += '-' + this.selectedPeriodOption.value.split('.')[0];
        } else {
          docName += '-' + this.activeDataView.columns[this.activeDataView.columns.length - 1].header;
        }
      }
    }
    docName = (docName || 'statement') + '.xlsx';
    return docName;
  }

  getSelectLabel(column: DataViewColumn) {
    const currentBuilderFrame = this.getBuilderFrameForColum(column);
    switch (currentBuilderFrame.operator) {
      case STATEMENT_BUILDER_OPERATORS.add:
        return '(+)'
      case STATEMENT_BUILDER_OPERATORS.subtract:
        return '(-)'
      default:
        return ''
    }
  }

  getIncludedLabel(column: DataViewColumn) {
    return `${column.statementDate} - ${column.preparationType} - ${column.reportingInterval}${column.projectionName === '' ? '' : (' - ' + column.projectionName)}`;
  }

  colToServerFrameKey(column: DataViewColumn) {
    return {
      scenario: column.scenario,
      statement_date: moment(column.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      reporting_interval: column.reportingInterval,
      preparation_type: column.preparationType,
      projection_name: column.projectionName,
    }
  }

  closeBuilder() {
    this._router.navigate(['/companies/' + this.company.uuid, 'financials', 'analysis']);
  }

  createStatement() {
    const isFrames = this.includedFrames.map((bf) => {
      return {
        frame_key: this.colToServerFrameKey(bf.column),
        operator: bf.operator
      }
    });

    const statementDate = moment(this.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT);

    this._popupService.open({
      componentType: SavingPopupComponent,
      cssClass: 'modal-confirmation'
    }).then(() => {
      return this._dataFrameService.createCombinedFrame(
        this.company.id,
        statementDate, this.reportingInterval,
        isFrames,
        this.colToServerFrameKey(this.balanceSheetColumn),
        this.beginningCashColumn === undefined ? undefined : this.colToServerFrameKey(this.beginningCashColumn),
        this.endingCashColumn === undefined ? undefined : this.colToServerFrameKey(this.endingCashColumn),
        // The currency changing capabilities will never be added to this component because we are planning to deprecate original statement builder.
        // However, this needed to be added to the statement builder TS file in order to prevent file crashes.
        this.company.settings.currency
      ).subscribe((data) => {
        this._popupService.closeAll();
        this._alertService.success('Statement Built!');
        this._router.navigate(['/companies/' + this.company.uuid, 'financials', 'analysis']);
      }, (err) => {
        this._popupService.closeAll();
        this._alertService.error(`${err.message}`);
      })
    }).catch(err => {
      this._alertService.error(`${err.message}`);
    });
  }
}
