import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EnvironmentService } from '@services/environment.service';
import { UserService } from '@services/user.service';
import { COMPANY_PAGE_VIEW_MODE } from '@utils/constants';

@Injectable()
export class ViewOrEditSpreadAndAnalysisMiddleware implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private environmentService: EnvironmentService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve: any, reject: any) => {
      this.environmentService.loadEnvConfig().subscribe(() => {
        // check that user is entitled to either full or view_only access to analyses pages
        const companyUuid = route.parent.params.uuid;
        this.userService.getUserEntitlementForCompany(companyUuid).subscribe(entitlementData => {
          const hasPermission = entitlementData['pageViewMode'] !== COMPANY_PAGE_VIEW_MODE.NO_ACCESS;
          if (!hasPermission) {
            this.router.navigate(['/denied']);
          }
          resolve(hasPermission)
        });
      });
    });
  }
}
