<div>
    <div class="row file-info-header">
        <div class="col-1">
            <app-file-icon class="file-type-icon" size="30px" fileSuffix="{{fileType}}"></app-file-icon>
        </div>
        <div class="col-9">
            <p class="borrower-name fs-block fs-exclude">{{borrowerName}}</p>
            <p class="filename">{{fileName}}</p>
        </div>
    </div>

    <div *ngIf="error">
        <p class="alert alert-danger">
            <strong>Oops!</strong>
            Something went wrong while processing this document!
            <br/><br/>
            <code *ngIf="userError">
                <strong>{{errorType}}</strong>
                <br/>
                {{errorDescription}}
            </code>
            <span *ngIf="!userError">
                Our team is looking into the issue now! We apologize for any inconvenience.
            </span>
        </p>
    </div>
    <div *ngIf="!error">
        <p *ngIf="step === 'DocumentPasswordEntry'" class="alert alert-info">
            <strong>Document Password Entry Required</strong><br/>
            To spread this document, please enter the password
            <a (click)="goToDocumentPasswordEntry()"> here </a>
            or upload a new document without password protection.</p>

        <ul *ngIf="step !== 'DocumentPasswordEntry'" class="steps">
            <li [className]="step.status" *ngFor="let step of steps">
                <div class="before">{{step.beforeLabel}}</div>
                <div class="after">{{step.afterLabel}}</div>
            </li>
        </ul>
    </div>
</div>

