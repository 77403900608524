<div class="input-group">
    <span class="input-group-text"><i class="fa fa-calendar-alt"></i></span>
    <input class="form-control" placeholder="Select a date"
           appNgxMyDatePicker [name]="fieldName"
           [options]="myOptions"
           #dp="appNgxMyDatePicker"
           (dateChanged)="onDateChanged($event)"
           [value]="initialDate"
           [textMask]="{mask: dateMask, showMask: true, guide: true, keepCharPositions: true, pipe: autoCorrectedDatePipe}"
           (click)="dp.toggleCalendar();"
           (keyup)="sendDate($event)"
           (blur)="checkCalendar()"
    />
</div>
